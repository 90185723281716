import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Spin, Tooltip } from "antd";
import React, { memo } from "react";

import { Operator, operatorLabels } from "../../models/Operator";
import { OperatorAvatar } from "../OperatorIcon";

interface XmlExportProps {
  operator: Operator;
  loading: boolean;
  onClick: () => void;
}

const XmlExport = memo(({ operator, loading, onClick }: XmlExportProps) => {
  const { i18n } = useLingui();

  return (
    <Tooltip
      title={i18n._(
        t`Download het XML-bestand geleverd aan ${operatorLabels[operator]}`
      )}
    >
      <Button
        shape="circle"
        onClick={onClick}
        disabled={loading}
        type="default"
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
        className="disable-hover"
      >
        {loading ? (
          <Spin size="small" />
        ) : (
          <OperatorAvatar operator={operator} invert disableTooltip />
        )}
      </Button>
    </Tooltip>
  );
});

export default XmlExport;
