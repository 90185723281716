import { Typography } from "antd";
import React, { ReactNode, memo } from "react";

import { Package } from "../../../../models/packages";

const PackageOptionView = memo(
  ({ pkg, remarks }: { pkg: Package; remarks?: ReactNode }) => (
    <>
      <div className="package-description">{pkg.description}</div>
      {remarks ? (
        <div className="package-remarks">{remarks}</div>
      ) : (
        pkg.details?.remarks && (
          <div className="package-remarks">
            <Typography.Text type="secondary">
              {pkg.details.remarks}
            </Typography.Text>
          </div>
        )
      )}
    </>
  )
);

export default PackageOptionView;
