import React, { ReactNode, memo } from "react";
import { Link } from "react-router-dom";

import screenforceLogo from "../../assets/screenforce-logo.svg";
import tipLogo from "../../assets/TIP-logo.svg";

interface TipHeaderProps {
  menu?: ReactNode;
  userMenu?: ReactNode;
  notificationButton?: ReactNode;
}

const TipHeader = memo(
  ({ menu, userMenu, notificationButton }: TipHeaderProps) => (
    <div className="flex h-24 items-center">
      <div className="flex-grow-0 flex justify-between">
        <Link to="/" className="px-2 block">
          <img src={tipLogo} alt="TIP" height="45" />
        </Link>
        <a
          href="https://screenforce.nl/"
          rel="noopener noreferrer"
          target="_blank"
          className="px-2 block"
        >
          <img src={screenforceLogo} alt="Screenforce" height="45" />
        </a>
      </div>
      <div className="flex-grow flex justify-end">{menu}</div>
      <div className="flex-grow-0 flex">
        <div className="px-2">{notificationButton}</div>
        {userMenu && <div className="tip-usermenu px-2">{userMenu}</div>}
      </div>
    </div>
  )
);

export default TipHeader;
