import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Select } from "antd";
import React, { memo } from "react";

import Organisation from "../../../models/Organisation";

const { Option } = Select;

export const allOrganisationsKey = "___all";

export interface OrganisationSelectProps {
  onChange(value: Organisation | null): void;
  options: Organisation[];
  value: Organisation | null;
}

const OrganisationSelect = ({
  onChange,
  options,
  value: selectedValue,
}: OrganisationSelectProps) => {
  const { i18n } = useLingui();

  const handleChange = (value: string) => {
    onChange(options.find(({ id }) => id === value) || null);
  };

  return (
    <div className="tip-organisation-select">
      <div className="tip-organisation-select-label">{i18n._(t`Filter`)}</div>
      <Select
        defaultValue={allOrganisationsKey}
        onChange={handleChange}
        optionFilterProp="title"
        showSearch
        style={{ width: 200 }}
        value={selectedValue === null ? allOrganisationsKey : selectedValue.id}
      >
        <Option
          key={allOrganisationsKey}
          title={i18n._(t`Alle organisaties`)}
          value={allOrganisationsKey}
        >
          {i18n._(t`Alle organisaties`)}
        </Option>
        {options.map(({ id, name }: Organisation) => (
          <Option key={id} title={name} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default memo(OrganisationSelect);
