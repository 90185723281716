import classNames from "classnames";
import React, { PropsWithChildren, memo } from "react";

interface StepContentProps {
  step: number;
  currentStep: number;
}

const StepContent = memo(
  ({ children, currentStep, step }: PropsWithChildren<StepContentProps>) => (
    <section
      className={classNames(
        "tip-steppers-stepContent",
        step > currentStep ? "tip-steppers-stepContent-hidden" : undefined
      )}
    >
      {children}
    </section>
  )
);

export default StepContent;
