import moment from "moment";
import { Action, combineReducers } from "redux";

import { DashboardFilter } from "../../models/Dashboard";
import {
  commercialMatchCell,
  dashboardDataCell,
  dashboardDataWithExtrasCell,
  dashboardNotUsedOrdersCell,
  dashboardOrderListCell,
  dashboardTilesCell,
  spotsCell,
} from "./cell";

const today = moment();
const defaultFilter: DashboardFilter = {
  period: [today.clone().startOf("month"), today.clone().endOf("month")],
};

const filterReducer = (
  state: DashboardFilter | undefined,
  action: Action<string>
): DashboardFilter => {
  switch (action.type) {
    case "DASHBOARD_FILTER":
      return (action as unknown as { payload: DashboardFilter }).payload;
    default:
      return state ?? defaultFilter;
  }
};

export default combineReducers({
  filter: filterReducer,
  list: dashboardOrderListCell.reducer,
  tiles: dashboardTilesCell.reducer,
  data: dashboardDataCell.reducer,
  all: dashboardDataWithExtrasCell.reducer,
  match: commercialMatchCell.reducer,
  spots: spotsCell.reducer,
  notused: dashboardNotUsedOrdersCell.reducer,
});
