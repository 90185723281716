import { t } from "@lingui/macro";

import { DayOfWeek } from "./DayOfWeek";
import { Product } from "./products";
import { Commercial } from "./Spot";

export interface Channel {
  description: string;
  intomartCode?: string;
}

export type AlternationType = "Percentage" | "Channel";
export const alternationTypeLabel: { [type in AlternationType]: string } = {
  Percentage: t`Procentueel`,
  Channel: t`Op zender`,
};

export const AlternationTypePercentage: AlternationType = "Percentage";
export const AlternationTypeChannel: AlternationType = "Channel";

export const AlternationTypeAll: AlternationType[] = [
  AlternationTypePercentage,
  AlternationTypeChannel,
];

export type CommercialInstructionStatus =
  | "Concept"
  | "Submitted"
  | "InProgress"
  | "Processed"
  | "Incorrect";

export const instructionStatusLabel: {
  [status in CommercialInstructionStatus]: string;
} = {
  Concept: t`Concept`,
  Submitted: t`Ingediend`,
  InProgress: t`In behandeling`,
  Incorrect: t`Incorrect`,
  Processed: t`Behandeld`,
};

export interface CommercialInstructionRotationTagon {
  tagonId?: string;
  sequenceNumber: number;
  commercial?: Commercial;
}

export interface CommercialInstructionRotation {
  rotationId?: string;
  startDate: Date;
  endDate: Date;
  sequenceNumber: number;
  commercial?: Commercial;
  frequency: number;
  daysOfWeek: DayOfWeek[];
  channel?: Channel;
  startTime?: Date;
  endTime?: Date;
  tagons: CommercialInstructionRotationTagon[];
}

export interface CommercialInstruction {
  id?: string;
  instructionId?: string;
  orderId?: string;
  subOrderId?: string;
  product?: Product;
  creationDate?: Date;
  startDate: Date;
  endDate: Date;
  alternationType: AlternationType;
  status: CommercialInstructionStatus;
  info?: string;
  contactPersonAgencyEmail?: string;
  rotations: CommercialInstructionRotation[];
}

export const emptyInstruction = {} as CommercialInstruction;
export const emptyInstructions: CommercialInstruction[] = [];

export const emptyRotation = {} as CommercialInstructionRotation;
export const emptyRotations: CommercialInstructionRotation[] = [];

export const emptyTagon = {} as CommercialInstructionRotationTagon;
export const emptyTagons: CommercialInstructionRotationTagon[] = [];
