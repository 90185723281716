import moment, { Moment } from "moment";

export default interface SelectedFilterModel {
  dateFrom: Moment;
  dateTo: Moment;
  advertiserIds: string[];
  orderIds: string[];
  skoTargetGroupId?: number;
}

export const emptyFilter: SelectedFilterModel = {
  advertiserIds: [],
  dateFrom: moment().startOf("month"),
  dateTo: moment().endOf("month"),
  orderIds: [],
  skoTargetGroupId: undefined,
};
