import { combineReducers } from "redux";

import {
  changePasswordCell,
  confirmResetPasswordCell,
} from "../authentication/cells";
import { asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";

export default combineReducers({
  resetPassword: asyncReducer<unknown, string>(sagaTypes.global.resetrequest),
  confirmPassword: confirmResetPasswordCell.reducer,
  changePassword: changePasswordCell.reducer,
});
