/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import { Spin } from "antd";
import React, { memo } from "react";

interface ImageProps {
  alt: string;
  dataUri: string;
  onClick?: () => void;
  mode?: "normal" | "play";
  width: number;
  height: number;
}

export const emptyImage =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

const Image = memo(
  ({ alt, dataUri, onClick, mode = "normal", width, height }: ImageProps) => (
    <div onClick={onClick} className={`tip-image-${mode}`}>
      {dataUri ? (
        [
          <img
            key="image"
            src={dataUri}
            alt={alt}
            style={{ maxWidth: "100%", height: "auto" }}
            width={width}
            height={height}
          />,
          <div className="shadow" key="play">
            <div className="play" />
          </div>,
        ]
      ) : (
        <Spin spinning>
          <img
            src={emptyImage}
            width="16"
            height="9"
            alt={alt}
            style={{
              width: "100%",
              display: "block",
              paddingBottom: "56.25%", // ratio 16:9
            }}
          />
        </Spin>
      )}
    </div>
  )
);

export default Image;
