import { useLingui } from "@lingui/react";
import { Badge, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import moment from "moment";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CommercialDeliveryStatus } from "../../models/CommercialDeliveryStatus";
import { OpenOrderRequests } from "../../store/campaigns/requests/models";
import { StoreModel } from "../../store/models";
import navigationPaths, { navigationHeaders } from "../../utils/navigation";
import WarnBadge from "../../views/navigation/WarnBadge";
import { lastCheck } from "../commercials/DeliveryStatusContainer";
import { initialHealth } from "../health/constants";

interface MainMenuProps {
  pathname: string;
  isAdmin: boolean;
  hasDashboard: boolean;
  isPlanner: boolean;
  isReader: boolean;
  isContributor: boolean;
  openOrderRequests?: OpenOrderRequests;
  deliveryStatus?: CommercialDeliveryStatus[];
}

const MainMenu = memo(
  ({
    pathname,
    isAdmin,
    hasDashboard,
    isPlanner,
    isReader,
    isContributor,
    openOrderRequests,
    deliveryStatus,
  }: MainMenuProps) => {
    const { i18n } = useLingui();

    const {
      showTermsOfUse,
      tutorialsUrl,
      releaseNotesUrl,
      enableHealth,
      healthStatus,
      enableCalendar,
    } = useSelector(
      ({
        application: {
          options: {
            showTermsOfUse: showTermsOfUseFromStore,
            tutorialsUrl: tutorialsUrlFromStore,
            releaseNotesUrl: releaseNotesUrlFromStore,
            enableHealth: enableHealthFromStore,
            enableCalendar: enableCalendarFromStore = false,
          },
        },
        health: { value: { status: healthStatusFromStore } = initialHealth },
      }: StoreModel) => ({
        showTermsOfUse: showTermsOfUseFromStore,
        tutorialsUrl: tutorialsUrlFromStore,
        releaseNotesUrl: releaseNotesUrlFromStore,
        enableHealth: enableHealthFromStore,
        healthStatus: healthStatusFromStore,
        enableCalendar: enableCalendarFromStore,
      })
    );

    const lastCheckDate = lastCheck();
    const newStatus = useMemo(
      () =>
        deliveryStatus?.filter((d) =>
          moment(d.modified).isSameOrAfter(lastCheckDate)
        ),
      [deliveryStatus, lastCheckDate]
    );

    return (
      <Menu theme="light" mode="horizontal" selectedKeys={[pathname]}>
        {hasDashboard && (
          <Menu.Item key={navigationPaths.Dashboard}>
            <Link to={navigationPaths.Dashboard}>
              {i18n._(navigationHeaders.Dashboard)}
            </Link>
          </Menu.Item>
        )}
        {isReader && (
          <Menu.Item key={navigationPaths.Campaigns}>
            <Link to={navigationPaths.Campaigns}>
              {i18n._(navigationHeaders.Campaigns)}
            </Link>
          </Menu.Item>
        )}
        {isPlanner && (
          <SubMenu
            key={navigationPaths.CampaignsRequests}
            title={
              <Badge dot={(openOrderRequests?.numberOfRequests ?? 0) > 0}>
                {i18n._(navigationHeaders.CampaignsRequests)}
              </Badge>
            }
          >
            <Menu.Item key={navigationPaths.CampaignsRequestsSearch}>
              <Link to={navigationPaths.CampaignsRequestsSearch}>
                {i18n._(navigationHeaders.CampaignsRequestsSearch)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.CampaignsRequestsConcept}>
              <Link to={navigationPaths.CampaignsRequestsConcept}>
                <Badge count={openOrderRequests?.numberOfRequests ?? 0}>
                  {i18n._(navigationHeaders.CampaignsRequestsConcept)}
                </Badge>
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.CampaignsRequestsCreateNew}>
              <Link to={navigationPaths.CampaignsRequestsCreateNew}>
                {i18n._(navigationHeaders.CampaignsRequestsCreateNew)}
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        {enableCalendar && (
          <Menu.Item key={navigationPaths.Calendar}>
            <Link to={navigationPaths.Calendar}>
              {i18n._(navigationHeaders.Calendar)}
            </Link>
          </Menu.Item>
        )}
        {isContributor && (
          <SubMenu
            key={navigationPaths.Commercials}
            title={
              <Badge dot={(newStatus?.length ?? 0) > 0}>
                {i18n._(navigationHeaders.Commercials)}
              </Badge>
            }
          >
            <Menu.Item key={navigationPaths.CommercialLibrary}>
              <Link to={navigationPaths.CommercialLibrary}>
                {i18n._(navigationHeaders.CommercialLibrary)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.CommercialDelivery}>
              <Link to={navigationPaths.CommercialDelivery}>
                {i18n._(navigationHeaders.CommercialDelivery)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.DeliveryStatus}>
              <Link to={navigationPaths.DeliveryStatus}>
                <Badge count={newStatus?.length ?? 0}>
                  {i18n._(navigationHeaders.DeliveryStatus)}
                </Badge>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        {isReader && (
          <Menu.Item key={navigationPaths.Settings}>
            <Link to={navigationPaths.Settings}>
              {i18n._(navigationHeaders.Settings)}
            </Link>
          </Menu.Item>
        )}
        {isAdmin && (
          <SubMenu
            key={navigationPaths.Management}
            title={i18n._(navigationHeaders.Management)}
          >
            <Menu.Item key={navigationPaths.Organisations}>
              <Link to={navigationPaths.Organisations}>
                {i18n._(navigationHeaders.Organisations)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.Users}>
              <Link to={navigationPaths.Users}>
                {i18n._(navigationHeaders.Users)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.StatisticsByOrganisation}>
              <Link to={navigationPaths.StatisticsByOrganisation}>
                {i18n._(navigationHeaders.StatisticsByOrganisation)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.Notifications}>
              <Link to={navigationPaths.Notifications}>
                {i18n._(navigationHeaders.Notifications)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.SkoImportReport}>
              <Link to={navigationPaths.SkoImportReport}>
                {i18n._(navigationHeaders.SkoImportReport)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.OrnImportReport}>
              <Link to={navigationPaths.OrnImportReport}>
                {i18n._(navigationHeaders.OrnImportReport)}
              </Link>
            </Menu.Item>
            <Menu.Item key={navigationPaths.CalendarManagement}>
              <Link to={navigationPaths.CalendarManagement}>
                {i18n._(navigationHeaders.CalendarManagement)}
              </Link>
            </Menu.Item>
          </SubMenu>
        )}

        {(showTermsOfUse ||
          tutorialsUrl ||
          releaseNotesUrl ||
          enableHealth) && (
          <SubMenu
            key={navigationPaths.About}
            title={
              <WarnBadge warn={healthStatus && healthStatus !== "Healthy"}>
                {i18n._(navigationHeaders.About)}
              </WarnBadge>
            }
          >
            {showTermsOfUse && (
              <Menu.Item key={navigationPaths.TermsOfUse}>
                <Link to={navigationPaths.TermsOfUse}>
                  {i18n._(navigationHeaders.TermsOfUse)}
                </Link>
              </Menu.Item>
            )}
            {showTermsOfUse && (
              <Menu.Item key={navigationPaths.TermsOfUseDashboard}>
                <Link to={navigationPaths.TermsOfUseDashboard}>
                  {i18n._(navigationHeaders.TermsOfUseDashboard)}
                </Link>
              </Menu.Item>
            )}
            {tutorialsUrl && (
              <Menu.Item key={navigationPaths.Tutorials}>
                <a
                  href={tutorialsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n._(navigationHeaders.Tutorials)}
                </a>
              </Menu.Item>
            )}
            {releaseNotesUrl && (
              <Menu.Item key={navigationPaths.ReleaseNotes}>
                <a
                  href={releaseNotesUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n._(navigationHeaders.ReleaseNotes)}
                </a>
              </Menu.Item>
            )}
            {enableHealth && (
              <Menu.Item key={navigationPaths.Status}>
                <Link to={navigationPaths.Status}>
                  <WarnBadge warn={healthStatus && healthStatus !== "Healthy"}>
                    {i18n._(navigationHeaders.Status)}
                  </WarnBadge>
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
      </Menu>
    );
  }
);

export default MainMenu;
