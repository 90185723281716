/* eslint-disable react/jsx-props-no-spreading */
import Icon from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import React, { CSSProperties } from "react";

import { Operator, operatorLabels } from "../models/Operator";

const AdAlliance = () => (
  <svg width="100%" height="100%" viewBox="0 0 500 400">
    <path
      fill="currentColor"
      d="M467.64,170.8C474.53,152.29,464,141,450.54,141a32.14,32.14,0,0,0-12.76,2.7l-110.27,44,22.84,34.73L414,197.05,385,275l21.72,33c3.47,5.39,4.73,13.63,2.41,19.79ZM311.52,238l-22.84-34.73L104.89,276.56l-58,23.15c-5.78,2.31-9.53,5.06-11.84,8.52-9.64,14.64-.48,31.57,15.71,31.57a30.29,30.29,0,0,0,11.15-2.24Z"
    />
    <path
      fill="currentColor"
      fillOpacity="0.5"
      d="M390.44,339.8c8.48,0,15.58-3.82,18.64-11.92,2.34-6.16,1.12-14.44-2.41-19.79L385,275l-34.6-52.59-22.84-34.73L241.28,56.62c-10.4-15.79-30.78-15.36-40.8.05L35,308.23c2.29-3.47,6.06-6.21,11.84-8.52l58-23.15,116-176.39,67.78,103.05L311.52,238l59.72,90.8C376.25,336.46,382.74,339.8,390.44,339.8Z"
    />
  </svg>
);

const Orn = () => (
  <svg width="100%" height="100%" viewBox="0 0 500 400">
    <defs>
      <radialGradient id="radial-gradient" cx="430.1" cy="199.64" r="227.69">
        <stop offset="0" stopColor="currentColor" stopOpacity="0" />
        <stop offset="1" stopColor="currentColor" />
      </radialGradient>
      <radialGradient id="radial-gradient-2" cx="191.74" cy="366.28" r="273.95">
        <stop offset="0.01" stopColor="currentColor" stopOpacity="0" />
        <stop offset="1" stopColor="currentColor" />
      </radialGradient>
      <radialGradient id="radial-gradient-3" cx="140.64" cy="44.67" r="246.96">
        <stop offset="0" stopColor="currentColor" stopOpacity="0" />
        <stop offset="1" stopColor="currentColor" />
      </radialGradient>
    </defs>
    <path
      fill="url(#radial-gradient)"
      fillOpacity="0.75"
      d="M183.94,181.52V210c0,5.88-4.27,9.49-13.95,9.49s-14-3.61-14-9.49V181.52c0-5.88,4.28-9.48,14-9.48S183.94,175.64,183.94,181.52Zm64.15-8.37H232.71v22.19h15.38c8.26,0,10.33-2.62,10.33-8.27v-6C258.42,175.44,256,173.15,248.09,173.15Zm44.39,100.48c-92.18,28.9-184,25.27-205.09-8S111,154.87,193.61,115.47C280.29,74.16,400.06,127.68,421.15,161S384.65,244.74,292.48,273.63Zm-89.17-93.56c0-11.44-9.05-23.07-33.32-23.07s-33.32,11.63-33.32,23.07v31.44c0,11.42,9,23.07,33.32,23.07s33.32-11.65,33.32-23.07Zm82.76,53.38-20.8-25.77c8.65-3.19,12.54-9.53,12.54-18.91v-9.28c0-14.14-7.77-21.37-28.68-21.37H213.35v75.33h19.36V210h11.38l19,23.41Zm73.75-75.33H341.36V210l-28.28-51.92H289.31v75.33h18.48V183.91l28.13,49.54h23.9Z"
    />
    <path
      fill="url(#radial-gradient-2)"
      fillOpacity="0.75"
      d="M258.42,181.07v6c0,5.65-2.07,8.27-10.33,8.27H232.71V173.15h15.38C256,173.15,258.42,175.44,258.42,181.07ZM170,172c-9.67,0-14,3.6-14,9.48V210c0,5.88,4.28,9.49,14,9.49s13.95-3.61,13.95-9.49V181.52C183.94,175.64,179.67,172,170,172Zm190.52,71.82c-39.78,78.89-90.71,138.92-156.66,110.26-46.12-20-87.19-113.43-64.25-196.35,24.06-86.94,161.57-107.3,208.32-89.3S400.28,165,360.51,243.86Zm-157.2-63.79c0-11.44-9.05-23.07-33.32-23.07s-33.32,11.63-33.32,23.07v31.44c0,11.42,9,23.07,33.32,23.07s33.32-11.65,33.32-23.07Zm82.76,53.38-20.8-25.77c8.65-3.19,12.54-9.53,12.54-18.91v-9.28c0-14.14-7.77-21.37-28.68-21.37H213.35v75.33h19.36V210h11.38l19,23.41Zm73.75-75.33H341.36V210l-28.28-51.92H289.31v75.33h18.48V183.91l28.13,49.54h23.9Z"
    />
    <path
      fill="url(#radial-gradient-3)"
      fillOpacity="0.75"
      d="M183.94,181.52V210c0,5.88-4.27,9.49-13.95,9.49s-14-3.61-14-9.49V181.52c0-5.88,4.28-9.48,14-9.48S183.94,175.64,183.94,181.52Zm64.15-8.37H232.71v22.19h15.38c8.26,0,10.33-2.62,10.33-8.27v-6C258.42,175.44,256,173.15,248.09,173.15ZM370.52,289.87c-41.35,31.9-182.41,53.38-229.17-25.17C96.77,189.81,111.44,83.36,152.79,51.47S276.42,47.08,336.56,113,411.89,258,370.52,289.87ZM203.31,180.07c0-11.44-9.05-23.07-33.32-23.07s-33.32,11.63-33.32,23.07v31.44c0,11.42,9,23.07,33.32,23.07s33.32-11.65,33.32-23.07Zm82.76,53.38-20.8-25.77c8.65-3.19,12.54-9.53,12.54-18.91v-9.28c0-14.14-7.77-21.37-28.68-21.37H213.35v75.33h19.36V210h11.38l19,23.41Zm73.75-75.33H341.36V210l-28.28-51.92H289.31v75.33h18.48V183.91l28.13,49.54h23.9Z"
    />
  </svg>
);

const Ster = () => (
  <svg width="100%" height="100%" viewBox="0 0 500 400">
    <path
      fill="currentColor"
      d="M38,199.07,250.32,39.2,462.55,199.07,250.32,358.2,38,199.07ZM250.39,335.53l182-136.5L250.39,61.89,68.27,199l182.12,136.5Z"
    />
    <path
      fill="currentColor"
      d="M179.43,187.13c-9.16-7.79-17.06-14.51-17.06-23.53,0-7.59,3.81-11.94,10.47-11.94,2.34,0,9.94,1,11.08,12.94l18.34-2c-2-17.31-12.25-27.64-27.47-27.64-19.55,0-30.77,10.5-30.77,28.82s12.3,28.41,23.14,37.45C176.31,208.84,185,216,185,226.77c0,7-4.52,11.14-12.11,11.14-7,0-11.5-6.44-12.37-17.65l-18.36,2.64c.86,15.85,10.36,31.72,29.76,31.72s31.42-11.36,31.42-29.64c0-17.57-12.68-28.34-23.86-37.85Z"
    />
    <path
      fill="currentColor"
      d="M208.23,181V166h10.06V142.48h18.2V166h12.18V181H236.49v50.35c0,4.55,1.94,7.14,6.16,7.14h6v15.6a31.73,31.73,0,0,1-9.1,1c-13.64,0-21.28-6.33-21.28-19.65V181Z"
    />
    <path
      fill="currentColor"
      d="M328.5,253.41V166h17.38v10.23h.32a23.6,23.6,0,0,1,20.63-11.85h3.73v19a41.2,41.2,0,0,0-11.69-1.79c-6.17,0-12.18,3.9-12.18,11.7v60.09Z"
    />
    <path
      fill="currentColor"
      d="M296.9,227.64c-.3,9.14-3.83,13-10,13-6.49,0-10.07-4.22-10.07-14.29V213.69h38.33V197.45c0-19.16-7.47-32.48-28.26-32.48s-28.26,13.32-28.26,32.48v25.66c0,19.17,7.47,32.49,28.26,32.49,19.1,0,27-11.22,28.12-28ZM276.79,194.2c0-10.07,3.58-14.29,10.07-14.29s10.07,4.22,10.07,14.29v4.55H276.79V194.2Z"
    />
  </svg>
);

const Talpa = () => (
  <svg width="100%" height="100%" viewBox="0 0 500 400">
    <circle fill="currentColor" cx="167.97" cy="63.94" r="34.42" />
    <circle fill="currentColor" cx="348.82" cy="63.94" r="34.42" />
    <circle fill="currentColor" cx="258.4" cy="63.94" r="34.42" />
    <circle fill="currentColor" cx="167.97" cy="154.36" r="34.42" />
    <circle fill="currentColor" cx="348.82" cy="154.36" r="34.42" />
    <circle fill="currentColor" cx="258.4" cy="154.36" r="34.42" />
    <circle fill="currentColor" cx="167.97" cy="244.78" r="34.42" />
    <circle fill="currentColor" cx="348.82" cy="244.78" r="34.42" />
    <circle fill="currentColor" cx="258.4" cy="244.78" r="34.42" />
    <circle fill="currentColor" cx="258.4" cy="335.2" r="34.42" />
  </svg>
);

export interface OperatorIconProps {
  operator: Operator;
  style?: CSSProperties;
}

export const OperatorIcon = ({
  operator,
  style,
}: OperatorIconProps): JSX.Element | null => {
  switch (operator) {
    case "Orn":
      return <Icon component={Orn} style={style} />;
    case "Rtl":
      return <Icon component={AdAlliance} style={style} />;
    case "Ster":
      return <Icon component={Ster} style={style} />;
    case "Talpa":
      return <Icon component={Talpa} style={style} />;
    case "Tip":
    default:
      return null;
  }
};

export interface OperatorAvatarProps {
  operator: Operator;
  invert?: boolean;
  disableTooltip?: boolean;
  style?: CSSProperties;
}

export const OperatorAvatar = ({
  operator,
  invert,
  disableTooltip = false,
  style,
}: OperatorAvatarProps): JSX.Element => {
  const avatar = (
    <Avatar
      alt={operatorLabels[operator]}
      icon={
        <OperatorIcon
          operator={operator}
          style={{ color: invert ? "#000000" : undefined }}
        />
      }
      style={{
        backgroundColor: "transparent",
        ...style,
      }}
      shape="square"
      size="small"
    />
  );

  if (disableTooltip) {
    return avatar;
  }

  return <Tooltip title={operatorLabels[operator]}>{avatar}</Tooltip>;
};
