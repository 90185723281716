export const propertyOf = <TModel>(name: keyof TModel): string =>
  name as string;

export const getValue = <TObject extends Record<string, unknown>, TValue>(
  object: TObject,
  path: string
): TValue =>
  path
    .replace(/\[/g, ".")
    .replace(/]/g, "")
    .split(".")
    .reduce((o, k) => (o || {})[k] as TObject, object) as unknown as TValue;

export default propertyOf;
