import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input } from "antd";
import React, { ReactElement } from "react";

import User from "../../../models/User";
import propertyOf from "../../../utils/properties";

interface UserFormEmailItemProps {
  initialValue?: string;
}

const emailField = propertyOf<User>("emailAddress");

const UserFormItemEmail = ({
  initialValue,
}: UserFormEmailItemProps): ReactElement => {
  const { i18n } = useLingui();

  return (
    <Form.Item
      initialValue={initialValue}
      label={i18n._(t`E-mail adres`)}
      name={emailField}
      rules={[
        {
          message: i18n._(t`E-mail adres is verplicht!`),
          required: true,
        },
        {
          message: i18n._(t`E-mail adres is niet geldig!`),
          type: "email",
        },
      ]}
    >
      <Input disabled={Boolean(initialValue)} />
    </Form.Item>
  );
};

export default UserFormItemEmail;
