import {
  AsyncListValue,
  AsyncSingleValue,
  AsyncSparseValue,
} from "async-lifecycle-saga";
import { Action } from "redux";
import {
  CallEffectDescriptor,
  PutEffectDescriptor,
  SelectEffectDescriptor,
  SimpleEffect,
} from "redux-saga/effects";

import { CommercialDeliveryView } from "../components/commercials/models";
import Advertiser from "../models/Advertiser";
import { CalendarEvent, CalendarEventFilter } from "../models/Calendar";
import { CommercialDeliveryStatus } from "../models/CommercialDeliveryStatus";
import {
  DashboardCommercial,
  DashboardFilter,
  DashboardOrder,
  DashboardOrderList,
  DashboardTile,
  DashboardTileData,
  SpotWithTargetGroup,
} from "../models/Dashboard";
import { NotificationResponse } from "../models/notifications";
import { CommercialBlobResponse } from "../models/OnBlobDownloadFunc";
import { Operator } from "../models/Operator";
import Organisation from "../models/Organisation";
import { OrnImportReport } from "../models/OrnImportReport";
import { PackagesResponse } from "../models/packages";
import { Period } from "../models/periods";
import { ProductsResponse } from "../models/products";
import { SkoTargetGroup } from "../models/SkoTargetGroup";
import SpotLengthIndex from "../models/SpotLengthIndex";
import User from "../models/User";
import { Culture } from "../utils/hooks";
import propertyOf from "../utils/properties";
import { AdvertisersResponse } from "./advertisers/models";
import { AuthenticationResponse } from "./authentication/models";
import { AvailableBreaksResponse } from "./bookspot/models";
import { AnalyzedOrder } from "./campaigns/analyzed/models";
import FetchOrdersFilteredResponse from "./campaigns/FetchOrdersFilteredResponse";
import FilterModel from "./campaigns/FilterModel";
import OrderWithSpotsResponse from "./campaigns/OrderWithSpotsResponse";
import {
  Break,
  CopyOrderRequestResponse,
  OpenOrderRequests,
  OrderRequestCreateResponse,
  OrderRequestDeleteResponse,
  OrderRequestGetResponse,
  OrderRequestsByAdvertiserGetResponse,
} from "./campaigns/requests/models";
import SelectedFilterModel from "./campaigns/SelectedFilterModel";
import SelectOrderRequest from "./campaigns/SelectOrderRequest";
import { ExportResponse } from "./exports/models";
import { GetExternalApiClientsResponse } from "./externalApis/models";
import { HttpStatusCode, ProblemDetails, Response } from "./fetch";
import { HealthCheckReponse } from "./health/models";
import {
  Channels,
  CommercialInstructions,
  Commercials,
} from "./instructions/models";
import { PageResponse } from "./pages/models";
import { SkoImportReport } from "./skoImport/models";
import {
  CommercialDeliveriesStatisticsForYearResponse,
  OrganisationLoginStatisticsForYearResponse,
  OrganisationRequestStatisticsForYearResponse,
  UserLoginByOrganisationStatisticsForYearResponse,
} from "./statistics/models";
import { GetAllUsersResponse, UserDeleteResponse } from "./users/models";

type ResponseStatus = "Success" | "Failed";

export interface TokenModel {
  token: string;
  result: ResponseStatus;
  expires: Date;
}

export interface RefreshTokenModel {
  refreshToken: string;
  result: ResponseStatus;
  expires: string;
}

export interface AccessTokenModel {
  accessToken: string;
  result: ResponseStatus;
  expires: string;
}

export interface AsyncStatus {
  loading: boolean;
}

export interface AsyncValue<T> {
  status: AsyncStatus;
  expiry?: number;
  value?: T;
}

export interface AsyncIdValue<T> {
  [id: string]: AsyncValue<T>;
}

export type AsyncRefresh = "clear" | "invalidate" | "keep" | "skip";

export interface AsyncOptions<TRequest, TResponse> {
  disableTokenCheck?: true;
  select?: (store: StoreModel) => AsyncValue<TResponse>;
  onRefresh?: (
    action: RequestAction<TRequest, TResponse>,
    state: AsyncValue<TResponse>
  ) => AsyncRefresh;
  onSuccess?: (
    action: RequestAction<TRequest, TResponse>,
    payload: TResponse
  ) => void;
  invalidate?: AsyncLifecycle[];
}

export interface AsyncLifecycle {
  prefix: string;
  skip: string;
  start: string;
  success: string;
  error: string;
  update: string;
  invalidate: string;
  clear: string;
  request: string;
}

export type AsyncEvent = keyof AsyncLifecycle;

export interface ResolutionSuggestionResponse {
  message: string;
}

export type RequestActionFailCallback<TResponse> = (
  exception?: string,
  statusCode?: HttpStatusCode,
  response?: TResponse,
  problem?: ProblemDetails
) => void;

export type RequestActionSuccessCallback<TResponse> = (
  statusCode?: HttpStatusCode,
  response?: TResponse
) => void;

export type RequestAction<TRequest, TResponse> = Action<string> & {
  id?: string;
  payload: TRequest;

  /**
   * A callback that is fired if the action has failed.
   * @param exception An optional exception message.
   * @param statusCode An optional HTTP status code.
   */
  onFail?: RequestActionFailCallback<TResponse>;

  /**
   * A callback that is fired if the action has succeeded.
   * @param statusCode An optional HTTP status code.
   */
  onSuccess?: RequestActionSuccessCallback<TResponse>;
};

export type AsyncLifecycleResponse<TRequest, TResponse> = (
  action: RequestAction<TRequest, TResponse>
) => IterableIterator<ApiRequestEffectDescriptor<TRequest, TResponse>>;

export type ApiPromise<TResponse> = Promise<Response<TResponse>>;
export type TokenApiPromise<TResponse> = (
  token?: string
) => ApiPromise<TResponse>;

export interface ReturnValue<TRequest, TResponse> {
  type: string;
  payload?: TRequest | TResponse;
  error?: Error | string;
}

export type ApiRequestEffectDescriptor<TRequest, TResponse> =
  | SimpleEffect<"CALL", CallEffectDescriptor<Response<TResponse>>>
  | SimpleEffect<"PUT", PutEffectDescriptor<ReturnValue<TRequest, TResponse>>>
  | SimpleEffect<"SELECT", SelectEffectDescriptor>;

export type TokenEffectDescriptor =
  | SimpleEffect<"PUT", PutEffectDescriptor<ReturnValue<unknown, TokenModel>>>
  | SimpleEffect<"CALL", CallEffectDescriptor<TokenApiPromise<TokenModel>>>;

export type OrderRequestLinks = {
  [operator in Operator]: string | undefined;
};

/**
 * General application options for the TIP application.
 */
export interface ApplicationOptions {
  baseUrl: string;
  applicationName: string;
  instrumentationKey?: string;
  enableBookSpot?: boolean;
  enableAdBlockerCheck?: boolean;
  showTermsOfUse?: boolean;
  showTitles?: boolean;
  websiteUrl?: string;
  tutorialsUrl?: string;
  releaseNotesUrl?: string;
  enableSkoGrps?: boolean;
  enableHealth?: boolean;
  links?: OrderRequestLinks;
  enableInstructions?: boolean;
  enableCalendar?: boolean;
  enableImpressions?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const defaultApplicationOptions: ApplicationOptions = {
  baseUrl: "http://localhost:5000",
  applicationName: "Screenforce TIP (DEVELOPMENT)",
  enableBookSpot: true,
  enableAdBlockerCheck: true,
  showTermsOfUse: true,
  showTitles: true,
  websiteUrl: "https://www.screenforce.nl",
  tutorialsUrl: "https://screenforce.nl/tip-tutorials/",
  releaseNotesUrl: "https://screenforce.nl/uitbreiding-tip-release-note/",
  enableSkoGrps: true,
  enableHealth: true,
  links: {
    Tip: undefined,
    Orn: "https://www.orn.nl/adverteren/adverteren-op-televisie/",
    Rtl: "https://www.adalliance.nl/inkoopinformatie/",
    Ster: "https://www.ster.nl/inkoopinformatie/",
    Talpa: "https://talpanetwork.com/media-solutions/tv",
  },
  enableInstructions: true,
  enableCalendar: true,
};

declare global {
  interface Window {
    tipApplicationOptions?: ApplicationOptions;
    tipBrowserCulture?: Culture;
  }
}

export interface ActivateRequest {
  emailAddress: string;
  password: string;
  twoFactorSecret: string;
  twoFactorTotp: string;
  activateToken: string;
}

export interface ActivateVerifyRequest {
  emailAddress: string;
  token: string;
}

export interface ActivateResponse {
  user: User;
  activationResult: ResponseStatus;
  possession: TokenModel;
  refresh: TokenModel;
}

export interface AuthenticationRequest {
  username: string;
  password: string;
}

export const usernameField = propertyOf<AuthenticationRequest>("username");
export const passwordField = propertyOf<AuthenticationRequest>("password");

export interface AuthenticateTwoFactorRequest {
  username: string;
  knowledgeToken: string;
  totp: string;
}

export interface RefreshRequest {
  username: string;
  knowledgeToken: string;
  possessionToken: string;
}

export interface AccessRequest {
  username: string;
  refreshToken: string;
}

export interface RequestResetUserPassword {
  username: string;
}

export interface ResetVerifyRequest {
  emailAddress: string;
  token: string;
}

export interface ProductRequest {
  advertiserId: string;
  advertiserName: string;
  description: string;
  branch: string;
  remarks?: string;
}

export interface ProductRequestBody {
  advertiser: Advertiser;
  description: string;
  branch: string;
  remarks?: string;
}

export interface AdBlockCheck {
  isBlocked: boolean;
}

export interface StoreModel {
  advertisers: {
    all: AsyncSingleValue<AdvertisersResponse>;
    byOrganisation: AsyncSingleValue<AdvertisersResponse>;
  };
  application: {
    options: ApplicationOptions;
  };
  bookSpot: {
    availableBreaks: AsyncValue<AvailableBreaksResponse>;
    bookSpot: AsyncValue<unknown>;
  };
  calendar: {
    filter: CalendarEventFilter;
    events: AsyncSingleValue<CalendarEvent[]>;
    create: AsyncSingleValue<CalendarEvent>;
    update: AsyncSingleValue<CalendarEvent>;
    delete: AsyncSingleValue<void>;
  };
  campaigns: {
    details: AsyncIdValue<OrderWithSpotsResponse>;
    filters: AsyncValue<FilterModel>;
    orders: AsyncValue<FetchOrdersFilteredResponse>;
    selectedFilter: SelectedFilterModel;
    analyzed: AsyncSingleValue<AnalyzedOrder[]>;
  };
  commercials: {
    overview: AsyncValue<CommercialDeliveryView[]>;
    advertisers: AsyncValue<Advertiser[]>;
    delivery: AsyncValue<unknown>;
    operators: AsyncValue<Operator[]>;
    download: AsyncIdValue<CommercialBlobResponse>;
    deleter: AsyncValue<unknown>;
  };
  copyRequest: AsyncSingleValue<CopyOrderRequestResponse>;
  dashboard: {
    filter: DashboardFilter;
    list: AsyncSingleValue<DashboardOrderList[]>;
    tiles: AsyncListValue<DashboardTile>;
    data: AsyncListValue<DashboardTileData>;
    all: AsyncListValue<DashboardTileData>;
    match: AsyncSingleValue<DashboardCommercial[]>;
    spots: AsyncSingleValue<SpotWithTargetGroup[]>;
    notused: AsyncSingleValue<DashboardOrder[]>;
  };
  deliveryStatus: AsyncListValue<CommercialDeliveryStatus>;
  exports: {
    byOrderExcel: AsyncIdValue<ExportResponse>;
    byOrderPdf: AsyncIdValue<ExportResponse>;
    byAdvertiserExcel: AsyncIdValue<ExportResponse>;
    byAdvertiserPdf: AsyncIdValue<ExportResponse>;
    byFilterExcel: AsyncValue<ExportResponse>;
    byFilterPdf: AsyncValue<ExportResponse>;
    organisationLoginsForYear: AsyncValue<ExportResponse>;
    organisationRequestsForYear: AsyncValue<ExportResponse>;
    commercialDeliveriesForYear: AsyncValue<ExportResponse>;
    userLoginsByOrganisationForYear: AsyncValue<ExportResponse>;
    byConceptExcel: AsyncValue<ExportResponse>;
    usersExcel: AsyncValue<ExportResponse>;
  };
  externalApi: {
    authorizationAdd: AsyncValue<unknown>;
    authorizationDelete: AsyncValue<unknown>;
    clients: AsyncValue<GetExternalApiClientsResponse>;
  };
  global: {
    resetPassword: AsyncValue<string>;
    confirmPassword: AsyncValue<never>;
    changePassword: AsyncSingleValue<never>;
  };
  health: AsyncValue<HealthCheckReponse>;
  instructions: {
    instructions: AsyncSparseValue<CommercialInstructions>;
    previous: AsyncSparseValue<CommercialInstructions>;
    commercials: AsyncSparseValue<Commercials>;
    channels: AsyncSparseValue<Channels>;
    submit: AsyncValue<CommercialInstructions>;
  };
  invoice: {
    download: AsyncIdValue<Blob>;
  };
  localization: {
    set: AsyncValue<unknown>;
  };
  normalisation: {
    saveResult: AsyncValue<string>;
    deleteResult: AsyncValue<string>;
  };
  notifications: {
    read: AsyncSingleValue<NotificationResponse>;
    edit: AsyncSingleValue<NotificationResponse>;
  };
  openRequests: AsyncSingleValue<OpenOrderRequests>;
  organisations: AsyncListValue<Organisation>;
  organisationSetPassword: AsyncSingleValue<never>;
  ornImportReport: AsyncListValue<OrnImportReport>;
  pages: AsyncIdValue<PageResponse>;
  product: AsyncValue<string>;
  requests: {
    advertisers: AsyncValue<AdvertisersResponse>;
    advertisersForFilter: AsyncValue<Advertiser[]>;
    create: AsyncValue<OrderRequestCreateResponse>;
    deleteOrderRequest: AsyncValue<OrderRequestDeleteResponse>;
    orderRequest: AsyncValue<OrderRequestGetResponse>;
    orderRequestsByAdvertiser: AsyncValue<OrderRequestsByAdvertiserGetResponse>;
    packages: AsyncValue<PackagesResponse>;
    periods: AsyncValue<Period[]>;
    products: AsyncValue<ProductsResponse>;
    spotLengthIndices: AsyncValue<SpotLengthIndex[]>;
    submit: AsyncValue<unknown>;
    update: AsyncValue<unknown>;
    availableBreaks: AsyncValue<Break[]>;
  };
  skoImportReport: AsyncListValue<SkoImportReport>;
  skoTargetGroup: AsyncListValue<SkoTargetGroup>;
  statistics: {
    organisationLoginsForYear: AsyncValue<OrganisationLoginStatisticsForYearResponse>;
    organisationRequestsForYear: AsyncValue<OrganisationRequestStatisticsForYearResponse>;
    commercialDeliveriesForYear: AsyncValue<CommercialDeliveriesStatisticsForYearResponse>;
    userLoginsByOrganisationForYear: AsyncValue<UserLoginByOrganisationStatisticsForYearResponse>;
  };
  tokens: {
    authenticate: AsyncValue<AuthenticationResponse>;
    authenticateTwoFactor: AsyncValue<TokenModel>;
    refresh: AsyncValue<TokenModel>;
    access: AsyncValue<TokenModel>;
  };
  ui: {
    campaigns: {
      selectOrder: SelectOrderRequest | null;
    };
    adBlocker: AsyncValue<AdBlockCheck>;
  };
  users: {
    current: AsyncValue<User>;
    all: AsyncValue<GetAllUsersResponse>;
    register: AsyncValue<unknown>;
    reset: AsyncValue<unknown>;
    delete: AsyncValue<UserDeleteResponse>;
    profile: AsyncSingleValue<undefined>;
  };
}
