import moment from "moment";
import { call, put } from "redux-saga/effects";

import { history } from "../App";
import { resetZendeskProps } from "../utils/zendesk";
import { accessPoster } from "./api";
import { httpUnauthorized } from "./fetch";
import {
  AccessRequest,
  StoreModel,
  TokenEffectDescriptor,
  TokenModel,
} from "./models";
import sagaTypes from "./sagaTypes";

export type TokenKey =
  | "tip-knowledge-token"
  | "tip-possession-token"
  | "tip-refresh-token"
  | "tip-access-token";

export const knowledgeTokenKey: TokenKey = "tip-knowledge-token";
export const possessionTokenKey: TokenKey = "tip-possession-token";
export const refreshTokenKey: TokenKey = "tip-refresh-token";
export const accessTokenKey: TokenKey = "tip-access-token";

/**
 * Send data to the website
 */
const sendData = (
  token: TokenModel | undefined = undefined,
  username: string | undefined | null = undefined
): void => {
  const iframe = document.getElementById("website_iframe") as HTMLIFrameElement;
  iframe?.contentWindow?.postMessage({ token, username }, iframe.src);
};

/**
 * Adds a token into the localStorage
 * @param key
 * @param token
 * @param volatile
 */
export const setToken = (
  key: TokenKey,
  token: TokenModel,
  volatile = false
): void => {
  if (volatile) {
    sessionStorage.setItem(key, JSON.stringify(token));
  } else {
    localStorage.setItem(key, JSON.stringify(token));
    if (key === refreshTokenKey) {
      sendData(token, localStorage.getItem(usernameKey));
    }
  }
};

/**
 * Gets a token from the localStorage. When the token is expired `undefined` will be returned
 * @param key
 * @param volatile
 */
export const getToken = (
  key: TokenKey,
  volatile = false
): TokenModel | undefined => {
  const tokenStr = volatile
    ? sessionStorage.getItem(key)
    : localStorage.getItem(key);
  if (!tokenStr) {
    return undefined;
  }

  const token = JSON.parse(tokenStr) as TokenModel;
  if (moment.utc().isAfter(moment.utc(token.expires))) {
    return undefined;
  }

  return token;
};

/**
 * Removes the token
 * @param key
 * @param volatile
 */
export const removeToken = (key: TokenKey, volatile = false): void => {
  if (volatile) {
    sessionStorage.removeItem(key);
  } else {
    localStorage.removeItem(key);
    if (key === refreshTokenKey) {
      sendData();
    }
  }
};

/**
 * Clear session tokens.
 * @param includePossession Indicates whether the possession token should be cleared as well.
 */
export const clearTokens = (includePossession = false): void => {
  removeToken(knowledgeTokenKey, true);
  if (includePossession) {
    removeToken(possessionTokenKey);
  }
  removeToken(refreshTokenKey);
  removeToken(accessTokenKey);
  resetZendeskProps();
};

const usernameKey = "tip-username";

/**
 * Sets the user's user name in the local storage.
 * @param username The user's user name.
 */
export const setUsername = (username: string): void => {
  localStorage.setItem(usernameKey, username.toLowerCase());
};

/**
 * Gets the user's user name from the local storage.
 */
export const getUsername = (): string | null =>
  localStorage.getItem(usernameKey);

/**
 * Removes the user's user name from the local storage.
 */
export const clearUsername = (): void => {
  localStorage.removeItem(usernameKey);
};

/**
 * Checks if you have a valid refresh token
 */
export function* checkRefresh(): IterableIterator<TokenEffectDescriptor> {
  const refreshToken = getToken(refreshTokenKey);
  if (!refreshToken || !refreshToken.token) {
    yield put({
      type: sagaTypes.tokens.refresh.error,
    });
    yield put({
      type: sagaTypes.users.current.clear,
    });
    return;
  }

  yield put({
    payload: refreshToken,
    type: sagaTypes.tokens.refresh.success,
  });
}

/**
 * Checks the access token
 */
export function* checkAccess(): IterableIterator<TokenEffectDescriptor> {
  let accessToken = getToken(accessTokenKey);
  if (!accessToken) {
    const refreshToken = getToken(refreshTokenKey);
    if (!refreshToken || !refreshToken.token) {
      yield put({
        type: sagaTypes.tokens.access.error,
      });
      return;
    }

    const { token } = refreshToken;
    const accessRequest: AccessRequest = {
      username: getUsername() || "",
      refreshToken: token,
    };

    const accessFunction = yield call(accessPoster, accessRequest);
    if (accessFunction) {
      const accessResponse = yield call(
        accessFunction as () => IterableIterator<TokenEffectDescriptor>
      );
      if (accessResponse) {
        const { payload, statusCode } = accessResponse;
        if (statusCode === httpUnauthorized) {
          removeToken(refreshTokenKey);
          resetZendeskProps();
          history.push("/");
        } else {
          accessToken = payload;
        }
      }
    }
  }

  if (accessToken) {
    setToken(accessTokenKey, accessToken);
    yield put({
      payload: accessToken,
      type: sagaTypes.tokens.access.success,
    });
  } else {
    yield put({
      type: sagaTypes.tokens.access.error,
    });
  }
}

export const getAccess = (state: StoreModel): string => {
  const {
    tokens: {
      access: { value },
    },
  } = state;
  return value ? value.token : "";
};
