import propertyOf from "../../utils/properties";
import { CommercialDelivery } from "./models";

export const advertiserIdsField =
  propertyOf<CommercialDelivery>("advertiserIds");
export const commercialTitleField =
  propertyOf<CommercialDelivery>("commercialTitle");
export const advertiserNameField =
  propertyOf<CommercialDelivery>("advertiserName");
export const operatorsField = propertyOf<CommercialDelivery>("operators");
export const filenameField = propertyOf<CommercialDelivery>("filename");
export const commentsField = propertyOf<CommercialDelivery>("comments");
export const phonenumberField = propertyOf<CommercialDelivery>("phonenumber");
export const productionCompanyField =
  propertyOf<CommercialDelivery>("productionCompany");
export const lowLoudnessLevelField =
  propertyOf<CommercialDelivery>("lowLoudnessLevel");
export const multiChannelAudioField =
  propertyOf<CommercialDelivery>("multiChannelAudio");
export const tradeInField = propertyOf<CommercialDelivery>("tradeIn");
export const tagOnField = propertyOf<CommercialDelivery>("tagOn");
export const versionField = propertyOf<CommercialDelivery>("version");
