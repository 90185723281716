import { SettingOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Badge, Button, Card, Form, FormInstance, Space, Tooltip } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Callbacks } from "rc-field-form/lib/interface";
import React, { PropsWithChildren, useCallback } from "react";

import { DashboardFilter, DashboardOrder } from "../../models/Dashboard";
import NotUsedOrdersAlert from "./NotUsedOrdersAlert";

export interface DashboardFiltersFormProps {
  form: FormInstance<DashboardFilter>;
  initialValues?: DashboardFilter;
  notUsedOrders?: DashboardOrder[];
  onFinish?: Callbacks<DashboardFilter>["onFinish"];
  edit: boolean;
  onEdit: (e: boolean) => void;
}

const DashboardFiltersForm = ({
  form,
  initialValues,
  notUsedOrders,
  onFinish,
  children,
  edit,
  onEdit,
}: PropsWithChildren<DashboardFiltersFormProps>) => {
  const { i18n } = useLingui();
  const handleEdit = useCallback(() => {
    onEdit(!edit);
  }, [edit, onEdit]);
  return (
    <Card
      type="inner"
      title={
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <span>{i18n._(t`Dashboard`)}</span>
          <span style={{ paddingRight: "2ex" }}>
            <Tooltip
              title={<NotUsedOrdersAlert notUsedOrders={notUsedOrders} />}
            >
              <Badge count={notUsedOrders?.length}>
                <Button
                  type="ghost"
                  icon={<SettingOutlined style={{ color: "#ffffff" }} />}
                  onClick={handleEdit}
                />
              </Badge>
            </Tooltip>
          </span>
        </Space>
      }
      style={{ marginBottom: 8 }}
      bodyStyle={{ display: "flex", justifyContent: "center" }}
    >
      <Form
        name="filters"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        layout="inline"
      >
        {children}
      </Form>
    </Card>
  );
};

export default DashboardFiltersForm;
