import { t } from "@lingui/macro";

import {
  HealthCategory,
  HealthCheckComponents,
} from "../../store/health/models";

export const healthCategories: { [key in HealthCategory]: string } = {
  backoffice: t`TIP-backend en exploitant-verbindingen`,
  commercialdelivery: t`Aanleveren van commercials aan exploitanten`,
  sko: t`Gegevens van NMO`,
};

export const healthLabels: { [key in HealthCheckComponents]: string } = {
  database: t`De database voor TIP`,
  "mxf-storage": t`Interne en tijdelijke opslag voor MXF-bestanden`,
  "mxf-ftp": t`FTP locaties voor de levering van MXF-bestanden`,
  "sko-ftp": t`FTP locaties waar NMO de gegevens klaar zetten`,
  mail: t`Mailserver voor het verzenden van mails vanuit TIP`,
  "backoffice-wsdl": t`Verbinding naar de exploitanten`,
  mediaservices: t`Mediaservice voor verwerken van MXF-bestanden`,
  "backoffice-b2blog": t`Controle van de B2B-functies`,
  "backoffice-ornimport": t`Controle van de ORN import functie`,
};
