import React, { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { CommercialDeliveryStatus } from "../../models/CommercialDeliveryStatus";
import User from "../../models/User";
import {
  adminRoleName,
  contributorRoleName,
  dashboardRoleName,
  plannerRoleName,
  readerRoleName,
} from "../../models/UserRole";
import { OpenOrderRequests } from "../../store/campaigns/requests/models";
import { hasRole } from "../../utils";
import TipHeader from "../../views/layout/TipHeader";
import MainMenu from "./MainMenu";
import NotificationButtonContainer from "./NotificationButtonContainer";
import UserMenu from "./UserMenu";

interface HeaderProps {
  user?: User;
  openOrderRequests?: OpenOrderRequests;
  deliveryStatus?: CommercialDeliveryStatus[];
}

const Header = memo(
  ({ user, openOrderRequests, deliveryStatus }: HeaderProps) => {
    const { pathname } = useLocation();

    const hasDashboard = useMemo(
      () => hasRole(dashboardRoleName)(user),
      [user]
    );
    const isReader = useMemo(() => hasRole(readerRoleName)(user), [user]);
    const isPlanner = useMemo(() => hasRole(plannerRoleName)(user), [user]);
    const isAdmin = useMemo(() => hasRole(adminRoleName)(user), [user]);
    const isContributor = useMemo(
      () => hasRole(contributorRoleName)(user),
      [user]
    );

    if (!user) {
      return <TipHeader />;
    }

    return (
      <TipHeader
        menu={
          <MainMenu
            pathname={pathname}
            isAdmin={isAdmin}
            hasDashboard={hasDashboard}
            isPlanner={isPlanner}
            isReader={isReader}
            isContributor={isContributor}
            openOrderRequests={openOrderRequests}
            deliveryStatus={deliveryStatus}
          />
        }
        userMenu={<UserMenu user={user} />}
        notificationButton={<NotificationButtonContainer />}
      />
    );
  }
);

export default Header;
