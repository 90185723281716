import React from "react";

import { useCulture } from "../utils/hooks";
import { formatNumber } from "./utils";

export interface AmountProps {
  value: number;
  suffix?: string;
  strong?: boolean;
}

const Amount = ({
  value,
  suffix,
  strong = false,
}: AmountProps): JSX.Element => {
  const culture = useCulture();
  const amount = formatNumber(value, 1, culture);
  const content = amount + (suffix ? ` ${suffix}` : "");

  return <>{strong ? <strong>{content}</strong> : content}</>;
};

export default Amount;
