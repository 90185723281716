import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { DatePicker, Form } from "antd";
import { compact } from "lodash/fp";
import moment from "moment/moment";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardFilter, DashboardOrder } from "../../models/Dashboard";
import { filterAction } from "../../store/dashboard/actions";
import {
  dashboardDataCell,
  dashboardDataWithExtrasCell,
} from "../../store/dashboard/cell";
import { StoreModel } from "../../store/models";
import { isNonEmptyArray } from "../../utils";
import FilterActions from "../../views/campaigns/FilterActions";
import DashboardFiltersForm from "../../views/dashboard/DashboardFiltersForm";
import ShowButton from "../actions/ShowButton";

const DashboardFilters = ({
  edit,
  onEdit,
  notUsedOrders,
}: {
  edit: boolean;
  onEdit: (e: boolean) => void;
  notUsedOrders?: DashboardOrder[];
}) => {
  const { i18n } = useLingui();
  const [form] = Form.useForm<DashboardFilter>();

  const dispatch = useDispatch();
  const handleFinish = useCallback(
    (values: DashboardFilter) => {
      dispatch(filterAction(values));
      dispatch({ type: dashboardDataCell.events.invalidate });
      dispatch({ type: dashboardDataWithExtrasCell.events.clear });
      dispatch(
        dashboardDataCell.require({
          onSuccess: () => {
            dispatch(dashboardDataWithExtrasCell.require());
          },
        })
      );
    },
    [dispatch]
  );

  const filterFromStore = useSelector(
    (state: StoreModel) => state.dashboard.filter
  );

  const formPeriod = Form.useWatch<DashboardFilter["period"] | undefined>(
    "period",
    form
  );

  return (
    <DashboardFiltersForm
      form={form}
      initialValues={filterFromStore}
      notUsedOrders={notUsedOrders}
      onFinish={handleFinish}
      edit={edit}
      onEdit={onEdit}
    >
      <Form.Item
        label={i18n._(t`Periode`)}
        name="period"
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker
          format="D MMM YYYY"
          ranges={{
            [i18n._(t`Vorige maand`)]: [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
            [i18n._(t`Huidige maand`)]: [
              moment().startOf("month"),
              moment().endOf("month"),
            ],
            [i18n._(t`Volgende maand`)]: [
              moment().add(1, "month").startOf("month"),
              moment().add(1, "month").endOf("month"),
            ],
          }}
        />
      </Form.Item>

      <Form.Item dependencies={["period"]}>
        {() => (
          <FilterActions>
            <ShowButton
              showIcon
              disabled={
                !(
                  isNonEmptyArray(formPeriod) &&
                  compact(formPeriod).length === 2
                )
              }
            />
          </FilterActions>
        )}
      </Form.Item>
    </DashboardFiltersForm>
  );
};

export default DashboardFilters;
