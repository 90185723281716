import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Checkbox, CheckboxOptionType, Tooltip } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import RadioGroup from "antd/lib/radio/group";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import React, { Key, ReactElement, useMemo } from "react";

import { PreferredPositionSelectionMode } from "../../../../../models/Campaigns/Requests/models";
import {
  PreferredPosition,
  preferredPositionBeforeLastInBlock,
  preferredPositionFirstInBlock,
  preferredPositionLastInBlock,
  preferredPositionNone,
  preferredPositionSecondInBlock,
} from "../../../../../models/Spot";

const emptyPreferredPositionValue: PreferredPosition[] = [];

interface Props {
  enabled: boolean;
  mode: PreferredPositionSelectionMode;
  onChangeMultiple(values: CheckboxValueType[]): void;
  onChangeSingle(e: RadioChangeEvent): void;
  preferredPositionsFree?: PreferredPosition[];
  value: PreferredPosition[] | undefined;
}

const PreferredPositionPickerView = (props: Props) => {
  const {
    enabled,
    mode,
    onChangeMultiple,
    onChangeSingle,
    preferredPositionsFree = [
      preferredPositionNone,
      preferredPositionFirstInBlock,
      preferredPositionSecondInBlock,
      preferredPositionBeforeLastInBlock,
      preferredPositionLastInBlock,
    ],
    value,
  } = props;
  const { i18n } = useLingui();

  const options: (CheckboxOptionType & { key: Key })[] = useMemo(() => {
    const freeFirst = preferredPositionsFree.some(
      (p) => p === preferredPositionFirstInBlock
    );
    const freeSecond = preferredPositionsFree.some(
      (p) => p === preferredPositionSecondInBlock
    );
    const freeBeforeLast = preferredPositionsFree.some(
      (p) => p === preferredPositionBeforeLastInBlock
    );
    const freeLast = preferredPositionsFree.some(
      (p) => p === preferredPositionLastInBlock
    );
    return [
      {
        disabled: !freeFirst,
        key: preferredPositionFirstInBlock,
        label: i18n._(t`A1`),
        style: {
          backgroundColor: freeFirst ? "#efefef" : "transparent",
          margin: "2px",
        },
        value: preferredPositionFirstInBlock,
      },
      {
        disabled: !freeSecond,
        key: preferredPositionSecondInBlock,
        label: i18n._(t`A2`),
        style: {
          backgroundColor: freeSecond ? "#efefef" : "transparent",
          margin: "2px",
        },
        value: preferredPositionSecondInBlock,
      },
      {
        disabled: !freeBeforeLast,
        key: preferredPositionBeforeLastInBlock,
        label: i18n._(t`E2`),
        style: {
          backgroundColor: freeBeforeLast ? "#efefef" : "transparent",
          margin: "2px",
        },
        value: preferredPositionBeforeLastInBlock,
      },
      {
        disabled: !freeLast,
        key: preferredPositionLastInBlock,
        label: i18n._(t`E1`),
        style: {
          backgroundColor: freeLast ? "#efefef" : "transparent",
          margin: "2px",
        },
        value: preferredPositionLastInBlock,
      },
    ];
  }, [i18n, preferredPositionsFree]);

  let element: ReactElement;
  switch (mode) {
    case "Single":
      element = (
        <RadioGroup
          defaultValue={value ? value[0] : undefined}
          disabled={!enabled}
          onChange={onChangeSingle}
          options={options}
          value={value ? value[0] : undefined}
        />
      );
      break;
    case "Multiple":
    default:
      element = (
        <Tooltip
          title={i18n._(t`Een grijs achtergrondvlak is een vrije positie`)}
        >
          <Checkbox.Group
            defaultValue={(value || emptyPreferredPositionValue).map(
              (v) => v as CheckboxValueType
            )}
            disabled={!enabled}
            onChange={onChangeMultiple}
            options={options}
            style={{ backgroundColor: "#ffffff" }}
          />
        </Tooltip>
      );
      break;
  }

  return element;
};

export default PreferredPositionPickerView;
