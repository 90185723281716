import { defaultTo, filter, flow, orderBy } from "lodash/fp";
import moment, { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CalendarEvent } from "../../models/Calendar";
import { calendarCell } from "../../store/calendar/cells";
import { notificationsReadCell } from "../../store/notifications/cells";
import { lastNotificationCheckKey } from "../../utils";
import NotificationButton from "../../views/navigation/NotificationButton";
import { emptyEvents } from "../calendar/Calendar";

export const lastNotificationCheck = (): Moment =>
  window.localStorage.getItem(lastNotificationCheckKey)
    ? moment(window.localStorage.getItem(lastNotificationCheckKey))
    : moment().subtract(1, "year").startOf("year");

const NotificationButtonContainer = (): JSX.Element | null => {
  const [lastCheckDate, setLastCheckDate] = useState(lastNotificationCheck);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(notificationsReadCell.require());
    dispatch(calendarCell.require());
  }, [dispatch]);

  const notification = useSelector(notificationsReadCell.select);
  const calendar = useSelector(calendarCell.select);
  const { start, end } = useMemo(
    () => ({
      start: moment().startOf("day"),
      end: moment().add(1, "day").endOf("day"),
    }),
    []
  );
  const currentEvents = useMemo(
    () =>
      flow(
        filter(
          (e: CalendarEvent) =>
            moment(e.to).isSameOrAfter(start) &&
            moment(e.from).isSameOrBefore(end)
        ),
        orderBy([(e: CalendarEvent) => moment(e.from).valueOf()], ["asc"]),
        defaultTo(emptyEvents)
      )(calendar.value ?? emptyEvents) as CalendarEvent[],
    [calendar.value, end, start]
  );

  if (currentEvents.length === 0 && !notification.value?.notification) {
    return null;
  }

  return (
    <NotificationButton
      notification={notification.value}
      events={currentEvents}
      lastCheckDate={lastCheckDate}
      setLastCheckDate={setLastCheckDate}
    />
  );
};

export default NotificationButtonContainer;
