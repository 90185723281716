import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Typography } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import User from "../../models/User";
import {
  adminRoleName,
  contributorRoleName,
  dashboardRoleName,
  plannerRoleName,
  readerRoleName,
} from "../../models/UserRole";
import { StoreModel } from "../../store/models";
import { hasRole } from "../../utils";
import navigationPaths, { navigationHeaders } from "../../utils/navigation";
import HeaderTitle from "../../views/HeaderTitle";
import {
  IntroductionCalendar,
  IntroductionCampaigns,
  IntroductionCommercials,
  IntroductionCommercialsDelivery,
  IntroductionDashboard,
  IntroductionProfile,
  IntroductionRequests,
  IntroductionRequestsConcept,
  IntroductionRequestsNew,
  IntroductionSettings,
  IntroductionStatus,
  OutroductionCalendar,
} from "../../views/Introduction";
import HtmlHead from "../../views/utils/HtmlHead";
import Activation from "../authentication/Activation";
import ConfirmPasswordResetForm from "../authentication/ConfirmPasswordResetForm";
import ForgotPasswordForm from "../authentication/ForgotPasswordForm";
import LoginContainer from "../authentication/LoginContainer";
import PrivateRoute from "../authentication/PrivateRoute";
import TwoFactor from "../authentication/TwoFactor";
import Calendar from "../calendar/Calendar";
import Campaigns from "../campaigns/Campaigns";
import OrderRequestWizardContainer from "../campaigns/requests/OrderRequestWizardContainer";
import OverviewContainer from "../campaigns/requests/OverviewContainer";
import CommercialLibraryContainer from "../commercials/CommercialLibraryContainer";
import DeliveryFormContainer from "../commercials/DeliveryFormContainer";
import DeliveryStatusContainer from "../commercials/DeliveryStatusContainer";
import Dashboard from "../dashboard/Dashboard";
import HealthContainer from "../health/HealthContainer";
import CalendarManagementContainer from "../management/calendar/CalendarManagementContainer";
import NotificationFormContainer from "../management/notifications/NotificationFormContainer";
import OrganisationsContainer from "../management/organisations/OrganisationsContainer";
import OrnImportReportContainer from "../management/ornImport/OrnImportReportContainer";
import SkoImportReportContainer from "../management/skoImport/SkoImportReportContainer";
import StatisticsByOrganisation from "../management/statistics/StatisticsByOrganisation";
import StatisticsByUser from "../management/statistics/StatisticsByUser";
import Users from "../management/users/Users";
import NormalisationContainer from "../normalisation/NormalisationContainer";
import Page from "../pages/Page";
import ProductRequestFormContainer from "../products/ProductRequestFormContainer";
import ExternalApiClients from "../user/ExternalApiClients";
import UserProfile from "../user/UserProfile";

interface ContentRoutingProps {
  user?: User;
  showTitles: boolean;
}

const RedirectUser = ({ user }: { user: User }): JSX.Element => {
  const isReader = hasRole(readerRoleName)(user);
  const hasDashboard = hasRole(dashboardRoleName)(user);
  const isContributor = hasRole(contributorRoleName)(user);
  const isAdmin = hasRole(adminRoleName)(user);

  if (isReader) {
    return <Navigate to={navigationPaths.Campaigns} />;
  }

  if (hasDashboard) {
    return <Navigate to={navigationPaths.Dashboard} />;
  }

  if (isContributor) {
    return <Navigate to={navigationPaths.CommercialLibrary} />;
  }

  return isAdmin ? (
    <Navigate to={navigationPaths.Organisations} />
  ) : (
    <Navigate to={navigationPaths.Forbidden} />
  );
};

const ContentRouting = memo(({ user, showTitles }: ContentRoutingProps) => {
  const { i18n } = useLingui();
  const enableCalendar = useSelector<StoreModel>(
    (store) => store.application.options.enableCalendar ?? false
  );

  return (
    <Routes>
      <Route path={navigationPaths.Root} element={<Outlet />}>
        {/* Authentication routes */}
        <Route path={navigationPaths.Authentication} element={<Outlet />}>
          <Route
            path={navigationPaths.Activate}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Activate)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.Activate)}
                />
                <Activation />
              </>
            }
          />
          <Route
            path={navigationPaths.TwoFactor}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.TwoFactor)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.TwoFactor)}
                />
                <TwoFactor />
              </>
            }
          />
          <Route
            path={navigationPaths.ForgotPassword}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.ForgotPassword)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.ForgotPassword)}
                />
                <ForgotPasswordForm />
              </>
            }
          />
          <Route
            path={navigationPaths.ConfirmPasswordReset}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.ConfirmPasswordReset)}
                />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.ConfirmPasswordReset)}
                />
                <ConfirmPasswordResetForm />
              </>
            }
          />
          <Route
            path={navigationPaths.Login}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Login)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.Login)}
                />
                <LoginContainer />
              </>
            }
          />
        </Route>

        {/* Dashboard routes */}
        <Route
          element={
            <PrivateRoute user={user} requiredRole={dashboardRoleName} />
          }
        >
          <Route
            path={navigationPaths.Dashboard}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Dashboard)} />
                <IntroductionDashboard />
                <Dashboard />
              </>
            }
          />
        </Route>

        {/* Reader routes */}
        <Route
          element={<PrivateRoute user={user} requiredRole={readerRoleName} />}
        >
          <Route
            path={navigationPaths.Campaigns}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Campaigns)} />
                <IntroductionCampaigns />
                <Campaigns />
              </>
            }
          />
          <Route
            path={navigationPaths.Settings}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Settings)} />
                <IntroductionSettings />
                <NormalisationContainer />
              </>
            }
          />
          <Route
            path={navigationPaths.SkoImportReport}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.SkoImportReport)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.SkoImportReport)}
                />
                <SkoImportReportContainer />
              </>
            }
          />
          <Route
            path={navigationPaths.OrnImportReport}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.OrnImportReport)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.OrnImportReport)}
                />
                <OrnImportReportContainer />
              </>
            }
          />

          {/* Reader router: END */}
        </Route>

        {/* Planner routes */}
        <Route
          element={<PrivateRoute user={user} requiredRole={plannerRoleName} />}
        >
          {[
            navigationPaths.CampaignsRequests,
            navigationPaths.CampaignsRequestsSearch,
          ].map((path) => (
            <Route
              path={path}
              key={path}
              element={
                <>
                  <HtmlHead
                    title={i18n._(navigationHeaders.CampaignsRequests)}
                  />
                  <IntroductionRequests />
                  <OverviewContainer showOpenRequests={false} />
                </>
              }
            />
          ))}
          <Route
            path={navigationPaths.CampaignsRequestsCreateNew}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.CampaignsRequestsCreateNew)}
                />
                <IntroductionRequestsNew />
                <OrderRequestWizardContainer />
              </>
            }
          />
          <Route
            path={navigationPaths.CampaignsRequestsEdit}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.CampaignsRequestsEdit)}
                />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.CampaignsRequestsEdit)}
                />
                <OrderRequestWizardContainer />
              </>
            }
          />
          <Route
            path={navigationPaths.CampaignsRequestsConcept}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.CampaignsRequestsConcept)}
                />
                <IntroductionRequestsConcept />
                <OverviewContainer showOpenRequests />
              </>
            }
          />
          <Route
            path={navigationPaths.ProductRequest}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.ProductRequest)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.ProductRequest)}
                />
                <ProductRequestFormContainer />
              </>
            }
          />

          {/* Planner routes: END */}
        </Route>

        {/* Admin routes */}
        <Route
          element={<PrivateRoute user={user} requiredRole={adminRoleName} />}
        >
          <Route
            path={navigationPaths.Organisations}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Organisations)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.Organisations)}
                />
                <OrganisationsContainer />
              </>
            }
          />
          <Route
            path={navigationPaths.Users}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Users)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.Users)}
                />
                <Users />
              </>
            }
          />
          <Route
            path={navigationPaths.StatisticsByOrganisation}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.StatisticsByOrganisation)}
                />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.StatisticsByOrganisation)}
                />
                <StatisticsByOrganisation />
              </>
            }
          />
          <Route
            path={navigationPaths.StatisticsByUser}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.StatisticsByUser)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.StatisticsByUser)}
                />
                <StatisticsByUser />
              </>
            }
          />
          <Route
            path={navigationPaths.Notifications}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Notifications)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.Notifications)}
                />
                <NotificationFormContainer />
              </>
            }
          />

          <Route
            path={navigationPaths.CalendarManagement}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.CalendarManagement)}
                />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.CalendarManagement)}
                />
                <CalendarManagementContainer />
              </>
            }
          />

          {/* Admin routes: END */}
        </Route>

        {/* Contributor routes */}
        <Route
          element={
            <PrivateRoute user={user} requiredRole={contributorRoleName} />
          }
        >
          {[navigationPaths.CommercialLibrary, navigationPaths.Commercials].map(
            (path) => (
              <Route
                path={path}
                key={path}
                element={
                  <>
                    <HtmlHead
                      title={i18n._(navigationHeaders.CommercialLibrary)}
                    />
                    <IntroductionCommercials />
                    <CommercialLibraryContainer />
                  </>
                }
              />
            )
          )}
          <Route
            path={navigationPaths.CommercialDelivery}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.CommercialDelivery)}
                />
                <IntroductionCommercialsDelivery />
                <DeliveryFormContainer />
              </>
            }
          />
          <Route
            path={navigationPaths.DeliveryStatus}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.DeliveryStatus)} />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.DeliveryStatus)}
                />
                <DeliveryStatusContainer />
              </>
            }
          />

          {/* Contributor routes: END */}
        </Route>

        {/* Other logged in routes */}
        <Route element={<PrivateRoute user={user} />}>
          <Route
            path={navigationPaths.Status}
            element={
              <>
                <HtmlHead title={i18n._(navigationHeaders.Status)} />
                <IntroductionStatus />
                <HealthContainer />
              </>
            }
          />
          <Route path={navigationPaths.Page} element={<Page />} />
          {user ? (
            <Route
              path={navigationPaths.Root}
              element={<RedirectUser user={user} />}
            />
          ) : (
            <Route
              path={navigationPaths.Root}
              element={<PrivateRoute user={user} />}
            />
          )}
          <Route
            path={navigationPaths.ExternalApiClients}
            element={
              <>
                <HtmlHead
                  title={i18n._(navigationHeaders.ExternalApiClients)}
                />
                <HeaderTitle
                  showTitles={showTitles}
                  title={i18n._(navigationHeaders.ExternalApiClients)}
                />
                <ExternalApiClients />
              </>
            }
          />
          <Route
            path={navigationPaths.UserProfile}
            element={
              <>
                <HtmlHead title={i18n._(t`Profiel`)} />
                <IntroductionProfile />
                <UserProfile />
              </>
            }
          />

          {enableCalendar ? (
            <Route
              path={navigationPaths.Calendar}
              element={
                <>
                  <HtmlHead title={i18n._(t`Kalender`)} />
                  <IntroductionCalendar />
                  <Calendar />
                  <OutroductionCalendar />
                </>
              }
            />
          ) : undefined}

          {/* Other logged in routes: END */}
        </Route>

        {/* Other routes */}
        <Route
          path={navigationPaths.Forbidden}
          element={
            <>
              <HtmlHead title={i18n._(t`Geen toegang`)} />
              <HeaderTitle
                showTitles={showTitles}
                title={i18n._(t`Geen toegang`)}
              />
              <Typography.Text>
                <Trans>Geen toegang</Trans>
              </Typography.Text>
            </>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <>
            <HtmlHead title={i18n._(t`Niet gevonden`)} />
            <HeaderTitle
              showTitles={showTitles}
              title={i18n._(t`Niet gevonden`)}
            />
            <Typography.Text>
              <Trans>Pagina niet gevonden</Trans>
            </Typography.Text>
          </>
        }
      />
    </Routes>
  );
});

export default ContentRouting;
