import { saveAs } from "file-saver";
import React, { memo, useCallback, useState } from "react";

import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import { Operator } from "../../models/Operator";
import XmlExport from "../../views/commercials/XmlExport";

const XmlExportButton = memo(
  ({
    id,
    operator,
    src,
    onDownload,
  }: {
    id: string;
    operator: Operator;
    src: string;
    onDownload: OnBlobDownloadFunc;
  }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = useCallback(() => {
      setLoading(true);
      onDownload(`xml-${id}-${operator}`, src, (blob) => {
        setLoading(false);
        saveAs(blob as Blob, `${id}-${operator}.xml`);
      });
    }, [id, operator, src, onDownload]);

    return (
      <XmlExport
        key={operator}
        operator={operator}
        loading={loading}
        onClick={handleClick}
      />
    );
  }
);

export default XmlExportButton;
