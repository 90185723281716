import { DownOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Button, Dropdown, Menu } from "antd";
import moment from "moment";
import React from "react";

import { Period } from "../../../models/periods";
import { TargetPeriod } from "../../../store/campaigns/requests/models";
import { monthFormat } from "../CopyButton";

export interface MultiPeriodProps {
  disabled?: boolean;
  periods: Period[];
  onCopy: (targetPeriod: TargetPeriod) => void;
}

const MultiPeriod = ({
  disabled,
  periods,
  onCopy,
}: MultiPeriodProps): JSX.Element => (
  <Dropdown
    disabled={disabled}
    overlay={
      <Menu>
        {periods.map(({ startDate, endDate }) => {
          const month = moment(startDate).format(monthFormat);
          return (
            <Menu.Item
              key={month}
              onClick={({ domEvent: event }) => {
                onCopy({ startDate, endDate });
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              {month}
            </Menu.Item>
          );
        })}
      </Menu>
    }
    trigger={["hover"]}
  >
    <Button
      disabled={disabled}
      key="copyButton"
      type="link"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <Trans>
        Kopiëren naar... <DownOutlined />
      </Trans>
    </Button>
  </Dropdown>
);

export default MultiPeriod;
