import { Action, AnyAction, Reducer } from "redux";
import { call, put, select } from "redux-saga/effects";

import {
  HttpStatusCode,
  Response,
  httpStatusCodeSuccess,
  httpUnauthorized,
} from "./fetch";
import {
  ApiPromise,
  ApiRequestEffectDescriptor,
  AsyncEvent,
  AsyncIdValue,
  AsyncLifecycle,
  AsyncLifecycleResponse,
  AsyncOptions,
  AsyncRefresh,
  AsyncStatus,
  AsyncValue,
  RequestAction,
} from "./models";
import sagaTypes from "./sagaTypes";
import {
  accessTokenKey,
  checkAccess,
  checkRefresh,
  getAccess,
  removeToken,
} from "./token";

const emptyResult = Object.freeze({ payload: undefined, exception: undefined });

const loadingStatus: AsyncStatus = Object.freeze({
  loading: true,
});
export const initial: AsyncStatus = Object.freeze({
  loading: false,
});

const emptyValue = { status: initial };

type FireFunction<TRequest, TResponse> = (
  request: TRequest
) => (token?: string) => ApiPromise<TResponse>;

const refreshDefault = <TRequest, TResponse>(
  _action: RequestAction<TRequest, TResponse>,
  { expiry, status: { loading } }: AsyncValue<TResponse>
): AsyncRefresh =>
  loading || (expiry && expiry > Date.now().valueOf()) ? "skip" : "keep";

export const asyncLifecycle = <TRequest, TResponse>(
  lifecycle: AsyncLifecycle,
  fire: FireFunction<TRequest, TResponse>,
  options: AsyncOptions<TRequest, TResponse> = {}
): AsyncLifecycleResponse<TRequest, TResponse> =>
  function* requestHandler(
    action: RequestAction<TRequest, TResponse>
  ): IterableIterator<ApiRequestEffectDescriptor<TRequest, TResponse>> {
    const state = options.select ? yield select(options.select) : undefined;
    switch (
      (options.onRefresh || refreshDefault)(action, state || emptyValue)
    ) {
      case "clear":
        yield put({ ...action, type: lifecycle.clear });
        break;
      case "invalidate":
        yield put({ ...action, type: lifecycle.invalidate });
        break;
      case "keep":
        break;
      case "skip":
        yield put({ ...action, type: lifecycle.skip });
        return;
      default:
        break;
    }

    yield put({ ...action, ...emptyResult, type: lifecycle.start });
    try {
      if (!options.disableTokenCheck) {
        // check for valid access token
        yield call(checkRefresh);
        yield call(checkAccess);
        const token = yield select(getAccess);

        if (!token) {
          yield put({
            ...action,
            ...emptyResult,
            type: sagaTypes.global.authentication.error,
          });
          return;
        }
      }

      const accessToken = yield select(getAccess);
      let { payload, exception, statusCode, problem } = (yield call(
        fire(action.payload),
        accessToken
      )) as unknown as Response<TResponse>;

      if (statusCode === httpUnauthorized) {
        removeToken(accessTokenKey);
        yield call(checkAccess);
        const newAccessToken = yield select(getAccess);
        if (newAccessToken) {
          const newAttempt = (yield call(
            fire(action.payload),
            newAccessToken
          )) as unknown as Response<TResponse>;
          payload = newAttempt.payload;
          exception = newAttempt.exception;
          statusCode = newAttempt.statusCode;
          problem = newAttempt.problem;
        }
      }

      if (exception) {
        yield put({
          ...action,
          ...emptyResult,
          exception,
          problem,
          type: lifecycle.error,
        });

        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          console.error(exception, problem);
        }

        if (action && action.onFail) {
          action.onFail(exception, statusCode, payload, problem);
        }
        return;
      }

      const event = httpStatusCodeSuccess[statusCode as HttpStatusCode]
        ? lifecycle.success
        : lifecycle.error;
      switch (event) {
        case lifecycle.success:
          {
            const invalidate = options.invalidate || [];
            for (let i = 0; i < invalidate.length; i += 1) {
              yield put({ type: invalidate[i].invalidate });
            }
          }

          if (options.onSuccess) {
            options.onSuccess(action, payload || ({} as unknown as TResponse));
          }

          if (action.onSuccess) {
            action.onSuccess(statusCode, payload);
          }

          break;

        case lifecycle.error:
          if (process.env.NODE_ENV === "development") {
            // eslint-disable-next-line no-console
            console.error(`Status Code: ${statusCode}`);
          }

          if (action.onFail) {
            action.onFail(
              `Status Code: ${statusCode}`,
              statusCode,
              payload,
              problem
            );
          }

          break;

        default:
          break;
      }

      yield put({
        ...action,
        ...emptyResult,
        payload,
        type: event,
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      yield put({
        ...action,
        ...emptyResult,
        exception: e,
        type: lifecycle.error,
      });
      if (action.onFail) {
        action.onFail(e as string);
      }
    }
  };

const nextStatus: (status: AsyncStatus, event: AsyncEvent) => AsyncStatus = (
  status: AsyncStatus,
  event: AsyncEvent
) => {
  switch (event) {
    case "start":
      return loadingStatus;
    case "update":
    case "success":
    case "clear":
    case "error":
      return initial;
    case "invalidate":
      return status;
    default:
      return status;
  }
};

const nextExpiry: (
  expiry: number | undefined,
  event: AsyncEvent
) => number | undefined = (expiry, event) => {
  switch (event) {
    case "start":
    case "clear":
    case "error":
      return undefined;
    case "success":
      return new Date().valueOf() + 30000;
    case "update":
    case "invalidate":
      return new Date().valueOf();
    default:
      return expiry;
  }
};

export const next: <TRequest, TResponse>(
  state: AsyncValue<TResponse>,
  event: AsyncEvent,
  payload: TRequest | TResponse | undefined
) => AsyncValue<TResponse> = <TRequest, TResponse>(
  state: AsyncValue<TResponse>,
  event: AsyncEvent,
  payload: TRequest | TResponse | undefined
) => {
  const status = nextStatus(state.status, event);
  const expiry = nextExpiry(state.expiry, event);
  const value =
    event === "error" || event === "clear"
      ? undefined
      : (payload as TResponse | undefined) || state.value;
  return (status === state.status &&
    expiry === state.expiry &&
    value === state.value) ||
    event === "request"
    ? state
    : { status, expiry, value };
};

export const patch: <T>(
  state: AsyncIdValue<T>,
  id: string,
  nextValue: AsyncValue<T>
) => AsyncIdValue<T> = (state, id, payload) =>
  state[id] === payload ? state : { ...state, [id]: payload };

export const initialState = <T>(): AsyncValue<T> =>
  Object.freeze({
    status: initial,
    value: undefined,
  });

export const asyncReducer =
  <TRequest, TResponse>(
    lifecycle: AsyncLifecycle
  ): Reducer<
    AsyncValue<TResponse>,
    Action<string> & { payload?: TRequest | TResponse }
  > =>
  (
    state: AsyncValue<TResponse> | undefined,
    { type, payload }: Action<string> & { payload?: TRequest | TResponse }
  ): AsyncValue<TResponse> => {
    const m = type.match(`^${lifecycle.prefix}_([A-Z]+)$`);
    const prev = state || initialState();
    return m ? next(prev, m[1].toLowerCase() as AsyncEvent, payload) : prev;
  };

const initialIdState = <TResponse>(): AsyncIdValue<TResponse> =>
  Object.freeze({});

export const asyncIdReducer =
  <TRequest, TResponse>(
    lifecycle: AsyncLifecycle
  ): Reducer<
    AsyncIdValue<TResponse>,
    Action<string> & { id: string | number; payload?: TRequest | TResponse }
  > =>
  (
    state: AsyncIdValue<TResponse> | undefined,
    {
      type,
      id,
      payload,
    }: Action<string> & { id: string | number; payload?: TRequest | TResponse }
  ): AsyncIdValue<TResponse> => {
    const m = type.match(`^${lifecycle.prefix}_([A-Z]+)$`);
    const prev = state || initialIdState();
    if (!m) {
      return prev;
    }

    const stringId = id.toString();
    return patch(
      prev,
      stringId,
      next(
        prev[stringId] || initialState(),
        m[1].toLowerCase() as AsyncEvent,
        payload
      )
    );
  };

export const composeReducers =
  <TState>(...reducers: Array<(state: TState, action: AnyAction) => TState>) =>
  (state: TState, action: AnyAction): TState =>
    reducers.reduce((s, reducer) => reducer(s, action), state);

export const immediateReducer =
  <TState>(prefix: string, initialValue: TState) =>
  (state: TState | undefined, { type, payload }: AnyAction): TState =>
    (type === `${prefix}_SET` ? payload : state) || initialValue;
