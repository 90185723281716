import moment from "moment";

import {
  OrderRequestSpot,
  OrderRequestSpotFinances,
} from "../../models/Campaigns/Requests/models";
import {
  BookSpotAction,
  BreakRequest,
  RequestToPreferredPosition,
  RequestedPreferredPosition,
} from "../../store/bookspot/models";

export type BlockSelectionMode = "BookSpot" | "OrderRequest";

export interface BlockSelectionItem {
  action?: BookSpotAction; // BreakRequest
  breakId: string;
  channelDescription?: string; // BreakRequest
  finances?: OrderRequestSpotFinances; // Spot
  id?: string; // Spot
  positionFreeIndicator?: string; // BreakRequest
  predictedRating?: number; // Spot
  preferredPosition: RequestedPreferredPosition;
  programmeAfter: string;
  programmeBefore: string;
  remarks?: string; // BreakRequest
  requestStatus?: string; // Spot
  scheduledDate: Date;
  scheduledStartDate: Date;
  selectivity?: number; // BreakRequest
  spotId?: number; // BreakRequest
  spotLength?: number; // Spot
  spotLengthCombination?: string; // BreakRequest
}

export const getUniqueId = (item: BlockSelectionItem): string =>
  `${item.breakId}:${moment(item.scheduledDate).format("YYYYMMDD")}:${
    item.channelDescription
  }`;

export const toBreakRequest = ({
  action,
  breakId,
  channelDescription,
  positionFreeIndicator,
  preferredPosition,
  programmeAfter,
  programmeBefore,
  remarks,
  scheduledDate,
  selectivity,
  spotId,
  spotLengthCombination,
}: BlockSelectionItem): BreakRequest => ({
  action: action || "Book",
  breakId,
  channelDescription: channelDescription || "",
  positionFreeIndicator: positionFreeIndicator || "",
  preferredPosition,
  programmeAfter,
  programmeBefore,
  remarks,
  scheduledDate,
  selectivity,
  spotId,
  spotLengthCombination: spotLengthCombination || "",
});

export const toSpot = (item: BlockSelectionItem): OrderRequestSpot => {
  const {
    breakId,
    id,
    channelDescription,
    predictedRating,
    preferredPosition,
    programmeAfter,
    programmeBefore,
    requestStatus,
    scheduledDate,
    scheduledStartDate,
    spotLength,
  } = item;

  return {
    breakId,
    id: id ?? "",
    channel: { description: channelDescription ?? "" },
    predictedRating: predictedRating ?? 0,
    preferredPosition: RequestToPreferredPosition[preferredPosition],
    programAfter: programmeAfter,
    programBefore: programmeBefore,
    requestStatus: requestStatus ?? "",
    scheduledDate,
    scheduledStartTime: scheduledStartDate,
    spotLength: spotLength ?? 0,
    uniqueId: getUniqueId(item),
  };
};
