import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Checkbox, Form } from "antd";
import moment from "moment";
import React, { memo } from "react";

import { fieldDaysOfWeekName } from "../../../../../models/Campaigns/Requests/models";
import { DayOfWeek } from "../../../../../models/DayOfWeek";
import { labelCol, wrapperColShallow } from "../../../../../views/layout/Form";

export type DayFormat = "Minimal" | "Short" | "Full";

interface DayOfWeekPickerProps {
  dayFormat?: DayFormat;
  enabled?: boolean;
  includeWeekend?: boolean;
}

const DayOfWeekPicker = memo(
  ({
    dayFormat = "Full",
    enabled = true,
    includeWeekend = true,
  }: DayOfWeekPickerProps) => {
    const { i18n } = useLingui();

    /*
     * Depending on the desired format, we want an array of localized day names
     * in the order of the current culture.
     */
    let localizedWeekdays: string[];
    switch (dayFormat) {
      case "Minimal":
        localizedWeekdays = moment.weekdaysMin(true);
        break;
      case "Short":
        localizedWeekdays = moment.weekdaysShort(true);
        break;
      case "Full":
      default:
        localizedWeekdays = moment.weekdays(true);
        break;
    }

    return (
      <Form.Item
        label={i18n._(t`Dag van de week`)}
        labelCol={labelCol}
        name={fieldDaysOfWeekName}
        wrapperCol={wrapperColShallow}
      >
        <Checkbox.Group disabled={!enabled}>
          <Checkbox key={DayOfWeek.Monday} value={DayOfWeek.Monday}>
            {localizedWeekdays[0]}
          </Checkbox>
          <Checkbox key={DayOfWeek.Tuesday} value={DayOfWeek.Tuesday}>
            {localizedWeekdays[1]}
          </Checkbox>
          <Checkbox key={DayOfWeek.Wednesday} value={DayOfWeek.Wednesday}>
            {localizedWeekdays[2]}
          </Checkbox>
          <Checkbox key={DayOfWeek.Thursday} value={DayOfWeek.Thursday}>
            {localizedWeekdays[3]}
          </Checkbox>
          <Checkbox key={DayOfWeek.Friday} value={DayOfWeek.Friday}>
            {localizedWeekdays[4]}
          </Checkbox>
          {includeWeekend && (
            <Checkbox key={DayOfWeek.Saturday} value={DayOfWeek.Saturday}>
              {localizedWeekdays[5]}
            </Checkbox>
          )}
          {includeWeekend && (
            <Checkbox key={DayOfWeek.Sunday} value={DayOfWeek.Sunday}>
              {localizedWeekdays[6]}
            </Checkbox>
          )}
        </Checkbox.Group>
      </Form.Item>
    );
  }
);

export default DayOfWeekPicker;
