import { Button } from "antd";
import React, { ReactNode } from "react";

interface UserContextItemProps {
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const UserContextItem = ({
  children,
  onClick,
}: UserContextItemProps): JSX.Element => (
  <Button type="primary" onClick={onClick} className="tip-user-context-item">
    {children}
  </Button>
);

export default UserContextItem;
