import { DoubleRightOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, List, Modal, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { CommercialInstruction } from "../../models/Instructions";
import Order from "../../models/Order";
import { StoreModel } from "../../store/models";
import { getInstructionTitle } from "../../views/InstructionTitle";
import PeriodView from "../../views/PeriodView";

const CopyInstructionsDialog = ({
  instructionsId,
  instructions,
  order,
  onCancel,
  onSelect,
}: {
  instructionsId: string;
  instructions: CommercialInstruction[];
  order: Order;
  onCancel: () => void;
  onSelect: (instruction: CommercialInstruction) => void;
}): JSX.Element => {
  const { i18n } = useLingui();

  const previous = useSelector(
    ({ instructions: { previous: fromStore } }: StoreModel) =>
      fromStore[instructionsId]
  );

  const current = useSelector(
    ({ instructions: { instructions: fromStore } }: StoreModel) =>
      fromStore[instructionsId]
  );

  const filtered = useMemo(
    () =>
      !previous.value?.instructions
        ? []
        : previous.value?.instructions.filter((i) => i.status === "Processed"),
    [previous.value?.instructions]
  );

  return (
    <Modal
      title={i18n._(t`Instructie van vorige periode kopiëren?`)}
      centered
      open
      width={640}
      onOk={undefined}
      onCancel={onCancel}
      cancelText={i18n._(t`Annuleren`)}
      cancelButtonProps={{
        disabled: current.status.loading,
      }}
      okButtonProps={{ hidden: true }}
      maskClosable={false}
      keyboard={false}
    >
      <Typography.Paragraph>
        <Trans>
          Selecteer een instructie die als basis gebruikt kan worden.
        </Trans>
      </Typography.Paragraph>
      <List
        bordered
        loading={previous.status.loading || current.status.loading}
        pagination={false}
        dataSource={[...instructions, ...filtered].sort(
          (a, b) =>
            moment(a.startDate).toDate().getTime() -
            moment(b.startDate).toDate().getTime()
        )}
        renderItem={(instruction) => {
          const { instructionId, startDate, endDate, rotations } = instruction;

          return (
            <List.Item
              actions={[
                <Tooltip
                  key="use_this"
                  title={i18n._(
                    t`Gebruik deze instructie als basis voor een nieuwe`
                  )}
                >
                  <Button
                    onClick={() => onSelect(instruction)}
                    type="ghost"
                    icon={<DoubleRightOutlined />}
                    disabled={current.status.loading}
                  />
                </Tooltip>,
              ]}
            >
              <PeriodView from={startDate} to={endDate} type="short" />
              {" - "}
              {!instructionId ? (
                i18n._(t`Nieuwe lege uitzendinstructie`)
              ) : (
                <>
                  {getInstructionTitle(order)}
                  {" - "}
                  {rotations.length} {i18n._(t`rotatie(s)`)}
                </>
              )}
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default CopyInstructionsDialog;
