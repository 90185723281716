import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, FormInstance, Input, Space, Typography } from "antd";
import { compact, filter, flatten, flow, map, orderBy, uniq } from "lodash/fp";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  DashboardOrder,
  DashboardOrderList,
  DashboardTileInput,
} from "../../models/Dashboard";
import { operatorLabels } from "../../models/Operator";
import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import {
  dashboardOrderListCell,
  dashboardTilesCell,
} from "../../store/dashboard/cell";
import { skoTargetGroupCell } from "../../store/sko/cells";
import Select from "../../views/Select";

const DashboardCampaignForm = ({
  form,
}: {
  form: FormInstance<DashboardTileInput>;
}) => {
  const { i18n } = useLingui();

  const dashboardOrdersFromStore = useSelector(dashboardOrderListCell.select);
  const dashboardTilesFromStore = useSelector(dashboardTilesCell.select);
  const targetGroupsFromStore = useSelector(skoTargetGroupCell.select);

  const currentTileId = Form.useWatch("id", form);
  const campaignsUsed = useMemo(
    () =>
      flow(
        filter(({ id }: DashboardTileInput) => id !== currentTileId),
        map("campaignIds"),
        flatten,
        compact,
        uniq
      )(dashboardTilesFromStore.value),
    [currentTileId, dashboardTilesFromStore.value]
  );

  const campaignOptions = useMemo(
    () =>
      flow(
        map((item: DashboardOrderList) => ({
          label: item.advertiser.name,
          options: flow(
            filter((o: DashboardOrder) => !campaignsUsed.includes(o.id)),
            map((order: DashboardOrder) => ({
              label: `${order.name} (${operatorLabels[order.operator]})`,
              value: order.id,
            }))
          )(item.orders),
        })),
        filter((o) => o.options.length > 0)
      )(dashboardOrdersFromStore.value ?? []),
    [campaignsUsed, dashboardOrdersFromStore.value]
  );

  return (
    <Space direction="vertical">
      <Typography.Paragraph>
        {i18n._(
          t`Selecteer de campagnes die je wilt toevoegen aan de dashboard tegel.`
        )}
      </Typography.Paragraph>

      <Form.Item name="id" hidden />

      <Form.Item
        label={i18n._(t`Campagnenaam`)}
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={i18n._(t`Selecteer campagnes`)}
        name="campaignIds"
        rules={[{ required: true }]}
      >
        <Select.FullWidth
          mode="multiple"
          showSearch
          allowClear
          loading={dashboardOrdersFromStore.status.loading}
          options={campaignOptions}
        />
      </Form.Item>

      <Form.Item
        label={i18n._(t`Selecteer doelgroep`)}
        name="skoTargetGroupId"
        rules={[{ required: true }]}
      >
        <Select.FullWidth
          showSearch
          loading={targetGroupsFromStore.status.loading}
          options={flow(
            map((s: SkoTargetGroup) => ({ label: s.name, value: s.id })),
            orderBy(["label"], ["asc"])
          )(targetGroupsFromStore.value ?? [])}
        />
      </Form.Item>
      <Typography.Paragraph>
        <Trans>
          Let op; er zit geen filter op billboards in orders en spots
        </Trans>
      </Typography.Paragraph>
    </Space>
  );
};

export default DashboardCampaignForm;
