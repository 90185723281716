import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Result } from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import tip from "../../assets/yeah.png";

const ActivationReady = (): JSX.Element => {
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const handleStart = useCallback(() => navigate("/"), [navigate]);
  return (
    <Result
      status="success"
      icon={<img src={tip} alt="" />}
      title={i18n._(t`Uw account is succesvol opgezet`)}
      subTitle={i18n._(
        t`Log vanaf nu in met uw gebruikersnaam en wachtwoord. Periodiek vragen we ook om Google Authenticator te gebruiken.`
      )}
      extra={
        <Button type="primary" onClick={handleStart}>
          <Trans>Begin met TIP gebruiken</Trans>
        </Button>
      }
    />
  );
};

export default ActivationReady;
