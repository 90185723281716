import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CalendarEvent } from "../../models/Calendar";
import { resetCalendarAction } from "../../store/calendar/actions";
import { calendarCell } from "../../store/calendar/cells";
import { StoreModel } from "../../store/models";
import CalendarContent from "../../views/calendar/CalendarContent";
import MainContent from "../../views/layout/MainContent";

export const emptyEvents: CalendarEvent[] = [];

const Calendar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetCalendarAction());
    dispatch({ type: calendarCell.events.invalidate });
    dispatch(calendarCell.require());
  }, [dispatch]);
  const calendarData = useSelector(calendarCell.select);
  const filterFromStore = useSelector(
    (state: StoreModel) => state.calendar.filter
  );

  return (
    <MainContent>
      <Spin spinning={calendarData.status.loading}>
        <CalendarContent
          period={filterFromStore.period}
          events={calendarData.value ?? emptyEvents}
        />
      </Spin>
    </MainContent>
  );
};

export default Calendar;
