import { createSingle } from "async-lifecycle-saga";

import { NotificationResponse } from "../../models/notifications";
import {
  NotificationRequest,
  notificationGetter,
  notificationPoster,
} from "./api";

export const notificationsReadCell = createSingle<
  NotificationResponse,
  NotificationResponse
>({
  path: ["notifications", "read"],
  require: {
    api: () => notificationGetter(false),
  },
});

export const notificationsEditCell = createSingle<
  NotificationResponse,
  NotificationResponse,
  undefined,
  NotificationRequest,
  NotificationRequest
>({
  path: ["notifications", "edit"],
  require: {
    api: () => notificationGetter(true),
  },
  create: {
    api: notificationPoster,
  },
  update: {
    api: notificationPoster,
  },
});
