import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import classNames from "classnames";
import React, { PropsWithChildren, memo } from "react";

import { useCulture } from "../utils/hooks";
import { formatNumber } from "./utils";

interface GrpsViewProps {
  isPredicted?: boolean;
  standalone?: boolean;
}

const GrpsView = memo(
  ({ standalone, isPredicted, children }: PropsWithChildren<GrpsViewProps>) =>
    standalone ? (
      <span className={classNames("grps", { isPredicted })}>{children}</span>
    ) : (
      <>{children}</>
    )
);

interface GrpsProps {
  amount?: number;
  isPredicted?: boolean;
  showSuffix?: boolean;
  standalone?: boolean;
  strong?: boolean;
}

const Grps = ({
  amount,
  isPredicted = false,
  showSuffix = false,
  standalone = true,
  strong = false,
}: GrpsProps): JSX.Element => {
  const culture = useCulture();
  const { i18n } = useLingui();

  if (amount === undefined) {
    return (
      <GrpsView standalone={standalone} isPredicted={isPredicted}>
        -
      </GrpsView>
    );
  }

  const grpsDigits = formatNumber(amount, 1, culture);
  const grpsSuffix = showSuffix ? ` ${i18n._(t`GRP`)}` : "";
  const grpsContent = grpsDigits + grpsSuffix;

  return (
    <GrpsView standalone={standalone} isPredicted={isPredicted}>
      {strong ? <strong>{grpsContent}</strong> : grpsContent}
    </GrpsView>
  );
};

export default Grps;
