import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Spin } from "antd";
import React from "react";
import ReactPlayer from "react-player";

import MediaCover from "./MediaCover";

const Video = ({
  videoDataUri,
  thumbnailDataUri,
  i18n,
  mediaCover,
}: {
  videoDataUri?: string;
  thumbnailDataUri: string;
  i18n: I18n;
  mediaCover?: boolean;
}) => (
  <MediaCover enabled={mediaCover}>
    <div style={{ position: "relative", paddingTop: "56.25%" }}>
      {videoDataUri ? (
        <ReactPlayer
          width="100%"
          height="100%"
          light={thumbnailDataUri}
          url={videoDataUri}
          pip
          playing
          controls
          style={{ position: "absolute", top: 0, left: 0 }}
        />
      ) : (
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <Spin spinning tip={i18n._(t`Video laden...`)}>
            <img
              src={thumbnailDataUri}
              alt={i18n._(t`Video laden...`)}
              style={{ width: "100%", display: "block" }}
            />
          </Spin>
        </div>
      )}
    </div>
  </MediaCover>
);

export default Video;
