import { Trans } from "@lingui/macro";
import { Tabs } from "antd";
import React, { memo } from "react";

import StatisticsContent from "../../../views/management/statistics/StatisticsContent";
import NumberOfCommercialDeliveries from "./NumberOfCommercialDeliveries";
import NumberOfLogins from "./NumberOfLogins";
import NumberOfOrderRequests from "./NumberOfOrderRequests";

const StatisticsByOrganisation = () => (
  <StatisticsContent>
    <Tabs defaultActiveKey="login">
      <Tabs.TabPane
        tab={
          <span>
            <Trans>Inlogacties</Trans>
          </span>
        }
        key="login"
      >
        <NumberOfLogins />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <span>
            <Trans>Aanvragen</Trans>
          </span>
        }
        key="requests"
      >
        <NumberOfOrderRequests />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <span>
            <Trans>Aangeleverde commercials</Trans>
          </span>
        }
        key="commercialDeliveries"
      >
        <NumberOfCommercialDeliveries />
      </Tabs.TabPane>
    </Tabs>
  </StatisticsContent>
);

export default memo(StatisticsByOrganisation);
