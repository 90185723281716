import { PlusOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, message } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CalendarEvent } from "../../../models/Calendar";
import {
  calendarCell,
  createCalendarCell,
  deleteCalendarCell,
  updateCalendarCell,
} from "../../../store/calendar/cells";
import MainContent from "../../../views/layout/MainContent";
import CalendarEventModal from "../../../views/management/calendar/CalendarEventModal";
import CalendarManagementTable from "../../../views/management/calendar/CalendarManagementTable";
import CalendarEventFilters from "../../calendar/CalendarEventFilters";

const CalendarManagementContainer = () => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();

  const calendar = useSelector(calendarCell.select);

  useEffect(() => {
    dispatch(calendarCell.require());
  }, [dispatch]);

  const [eventToEdit, setEventToEdit] = useState<CalendarEvent | null>(null);
  const handleClose = useCallback(() => {
    setEventToEdit(null);
  }, []);

  const saveEvent = useCallback(
    (calendarEvent: CalendarEvent) => {
      const result = {
        onSuccess: () => {
          message.success(i18n._(t`Evenement is toegevoegd`));
          dispatch(calendarCell.require());
          handleClose();
        },
        onFail: () => {
          message.error(i18n._(t`Er is iets misgegaan`));
        },
      };

      dispatch(
        calendarEvent.id
          ? updateCalendarCell.require(calendarEvent, result)
          : createCalendarCell.require(calendarEvent, result)
      );
    },
    [dispatch, handleClose, i18n]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteEvent = useCallback(
    (calendarEventId: string) => {
      dispatch(
        deleteCalendarCell.require(calendarEventId, {
          onSuccess: () => {
            message.success(i18n._(t`Evenement is verwijderd`));
            dispatch(calendarCell.require());
          },
          onFail: () => {
            message.error(i18n._(t`Er is iets misgegaan`));
          },
        })
      );
    },
    [dispatch, i18n]
  );

  return (
    <MainContent>
      <CalendarEventFilters
        inline
        extra={
          <Button
            type="default"
            icon={<PlusOutlined />}
            onClick={() => {
              setEventToEdit({
                from: moment().startOf("day").toDate(),
                to: moment().endOf("day").toDate(),
                title: "",
              });
            }}
          >
            <Trans>Nieuw evenement toevoegen</Trans>
          </Button>
        }
      />

      <CalendarManagementTable
        events={calendar.value ?? []}
        loading={calendar.status.loading}
        editEvent={setEventToEdit}
        deleteEvent={deleteEvent}
      />

      {eventToEdit && (
        <CalendarEventModal
          eventToEdit={eventToEdit}
          onSave={saveEvent}
          onCancel={handleClose}
        />
      )}
    </MainContent>
  );
};

export default CalendarManagementContainer;
