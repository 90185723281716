import { Trans } from "@lingui/macro";
import React, { memo } from "react";

const SpotLengthIndexView = memo(({ index }: { index: number }) => (
  <span className="tip-spotLengthIndex">
    <span className="tip-spotLengthIndex-label">
      <Trans>Spotlengte index</Trans>:
    </span>
    <span className="tip-spotLengthIndex-value">{index}</span>
  </span>
));

export default SpotLengthIndexView;
