import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Table, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { saveAs } from "file-saver";
import moment from "moment";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  MonthNumber,
  StatisticsByOrganisationModel,
} from "../../../models/StatisticsModels";
import { HttpStatusCode } from "../../../store/fetch";
import { RequestAction, StoreModel } from "../../../store/models";
import sagaTypes from "../../../store/sagaTypes";
import {
  OrganisationLoginStatisticsForYearRequest,
  OrganisationLoginStatisticsForYearResponse,
} from "../../../store/statistics/models";
import navigationPaths from "../../../utils/navigation";
import Ellipsis from "../../../views/Ellipsis";
import BigScreen from "../../../views/layout/BigScreen";
import Flex from "../../../views/layout/Flex";
import YearSelect, { YearChangeFunc } from "../../../views/YearSelect";
import ExportButton from "../../actions/ExportButton";
import { getTotals, organisationTypeColumn, totalFilterOptions } from "./utils";

const NumberOfLogins = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const {
    status: { loading: organisationLoginsForYearLoading },
    value: organisationLoginsForYearValue,
  } = useSelector(
    ({ statistics: { organisationLoginsForYear } }: StoreModel) =>
      organisationLoginsForYear
  );

  const {
    status: { loading: exportOrganisationLoginsForYearLoading },
  } = useSelector(
    ({ exports: { organisationLoginsForYear } }: StoreModel) =>
      organisationLoginsForYear
  );

  const [year, setYear] = useState(moment().year());
  const handleYearChange = useCallback<YearChangeFunc>((value) => {
    setYear(value);
  }, []);

  const handleExportClick = useCallback(() => {
    dispatch({
      type: sagaTypes.exports.organisationLoginsForYear.request,
      payload: { year },
      onFail: () => {
        message.error(i18n._(t`Er is iets misgegaan.`));
      },
      onSuccess: (_statusCode?: HttpStatusCode, response?: Blob) => {
        if (!response) {
          return;
        }

        const filename = `LoginsPerOrganisatie_${year}.xlsx`;
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(response, filename);
        } else {
          saveAs(response, filename);
        }
      },
    });
  }, [dispatch, i18n, year]);

  const columns: ColumnProps<StatisticsByOrganisationModel>[] = [
    {
      key: "organisation",
      title: i18n._(t`Organisatie`),
      width: 200,
      ellipsis: true,
      render: (
        _: string,
        { organisationId, name }: StatisticsByOrganisationModel
      ): ReactNode => (
        <Link
          to={navigationPaths.StatisticsByUser.replace(
            ":organisationId",
            organisationId
          ).replace(":year", year.toString())}
        >
          <Ellipsis text={name} />
        </Link>
      ),
    },
    organisationTypeColumn(
      i18n
    ) as unknown as ColumnProps<StatisticsByOrganisationModel>,
    ...moment.monthsShort().map((monthName, index) => {
      const monthNumber = `${index + 1}`;
      return {
        key: monthNumber,
        title: monthName,
        render: (
          _: string,
          { statistics }: StatisticsByOrganisationModel
        ): ReactNode => <>{statistics[`${monthNumber}` as MonthNumber] ?? 0}</>,
      };
    }),
    {
      key: "total",
      title: i18n._(t`Totaal`),
      ...totalFilterOptions,
      render: (_text, { statistics }): ReactNode => getTotals(statistics),
    },
  ];

  useEffect(() => {
    dispatch<
      RequestAction<
        OrganisationLoginStatisticsForYearRequest,
        OrganisationLoginStatisticsForYearResponse
      >
    >({
      type: sagaTypes.statistics.organisationLoginsForYear.request,
      payload: {
        year,
      },
    });
  }, [dispatch, year]);

  return (
    <>
      <Flex align="end" type="placeholder">
        <YearSelect value={year} onChange={handleYearChange} />
        <ExportButton
          iconType="Excel"
          loading={exportOrganisationLoginsForYearLoading}
          onClick={handleExportClick}
        >
          <BigScreen type="text">
            <Trans>Exporteren</Trans>
          </BigScreen>
        </ExportButton>
      </Flex>
      <Table
        columns={columns}
        dataSource={
          organisationLoginsForYearValue &&
          organisationLoginsForYearValue.statistics
        }
        loading={
          !organisationLoginsForYearValue && organisationLoginsForYearLoading
        }
        pagination={false}
        rowKey={(statistic): string => statistic.organisationId}
      />
    </>
  );
};

export default memo(NumberOfLogins);
