import React, { ReactElement, memo } from "react";

interface CollapseListHeaderProps {
  title: string;
}

const CollapseListHeader = memo(
  ({ title }: CollapseListHeaderProps): ReactElement => (
    <span className="tip-collapseList-headerText">{title}</span>
  )
);

export default CollapseListHeader;
