import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Input, Space } from "antd";
import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
} from "react";

import { fieldSpotLengthsName } from "../../../../../models/Campaigns/Requests/models";
import SpotLengthField from "../../../../../views/campaigns/requests/subOrders/SpotLengthField";
import SpotLengthIndexView from "../../../../../views/campaigns/requests/subOrders/SpotLengthIndexView";
import {
  labelCol,
  wrapperCol,
  wrapperColShallow,
} from "../../../../../views/layout/Form";
import { SpotLengthsPickerProps } from "../models";

const SpotLengthsPicker: FunctionComponent<SpotLengthsPickerProps> = ({
  enabled,
  enableButtons = true,
  fieldName,
  form: { getFieldValue, setFieldsValue },
  spotLengthIndices,
}) => {
  const { i18n } = useLingui();

  const handleSpotLengthChange = useCallback(
    /**
     * Changes the spot lengths field value.
     * @param value The new value.
     */
    (value: number[]): void => {
      setFieldsValue({ [fieldName]: value });
    },
    [fieldName, setFieldsValue]
  );

  const handleRemove = useCallback(
    /**
     * Removes the most recently added spot length.
     */
    () => {
      const currentValue = getFieldValue(fieldSpotLengthsName) as number[];
      const newLength = currentValue.length === 1 ? 1 : currentValue.length - 1;
      setFieldsValue({
        [fieldName]: currentValue.slice(0, newLength),
      });
    },
    [fieldName, getFieldValue, setFieldsValue]
  );

  const handleAdd = useCallback(
    /**
     * Adds a new spot length.
     */
    () => {
      const currentValue = getFieldValue(fieldSpotLengthsName) as number[];
      if (currentValue.length < 5) {
        setFieldsValue({ [fieldName]: [...currentValue, undefined] });
      }
    },
    [fieldName, getFieldValue, setFieldsValue]
  );

  return (
    <Form.Item
      label={i18n._(t`Spotlengtes`)}
      labelCol={labelCol}
      required
      wrapperCol={wrapperCol}
    >
      <Form.Item
        name={fieldSpotLengthsName}
        noStyle
        rules={[
          {
            validator: (
              _rule,
              value: (number | undefined)[]
            ): Promise<void> => {
              const isValid = value.every((v) => v && v % 5 === 0);
              if (!isValid) {
                return Promise.reject(
                  new Error(
                    i18n._(t`Spotlengtes moeten een veelvoud van 5 zijn.`)
                  )
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle shouldUpdate wrapperCol={wrapperColShallow}>
        {(): ReactElement => (
          <Input.Group compact>
            {(getFieldValue(fieldSpotLengthsName) as number[]).map(
              (length, index) => (
                <SpotLengthField
                  key={`index:${index.toString()}`}
                  index={index}
                  onChange={handleSpotLengthChange}
                  length={length}
                  value={getFieldValue(fieldSpotLengthsName)}
                  disabled={!enabled}
                />
              )
            )}
          </Input.Group>
        )}
      </Form.Item>
      {enableButtons && (
        <Form.Item noStyle shouldUpdate>
          {(): ReactElement => {
            const currentValue = getFieldValue(
              fieldSpotLengthsName
            ) as number[];

            return (
              <Space direction="horizontal">
                <Button
                  type="default"
                  disabled={
                    !enabled || !(currentValue[currentValue.length - 1] > 0)
                  }
                  onClick={handleAdd}
                  icon={<PlusOutlined />}
                  size="small"
                  title={i18n._(t`Voeg een tag-on toe`)}
                />
                <Button
                  type="default"
                  disabled={!enabled || currentValue.length === 1}
                  onClick={handleRemove}
                  icon={<MinusOutlined />}
                  size="small"
                  title={i18n._(t`Verwijder een tag-on`)}
                />
              </Space>
            );
          }}
        </Form.Item>
      )}
      <Form.Item noStyle shouldUpdate>
        {(): ReactElement | undefined => {
          const currentValue = getFieldValue(fieldSpotLengthsName) as number[];
          const sum = currentValue.reduce((a, b) => a + b, 0);
          const spotLengthIndex = spotLengthIndices.find(
            ({ length }) => length === sum
          );

          return (
            spotLengthIndex && (
              <SpotLengthIndexView index={spotLengthIndex.index} />
            )
          );
        }}
      </Form.Item>
    </Form.Item>
  );
};

export default memo(
  ({
    enabled,
    enableButtons = true,
    fieldName,
    form,
    spotLengthIndices,
  }: SpotLengthsPickerProps) => (
    <SpotLengthsPicker
      enabled={enabled}
      enableButtons={enableButtons}
      fieldName={fieldName}
      form={form}
      spotLengthIndices={spotLengthIndices}
    />
  )
);
