import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Badge, Button, Form, Input, Select } from "antd";
import React, { memo } from "react";

import { wrapperColNoLabel } from "../../../views/layout/Form";
import { idField, statusField, textField } from "./constants";
import { NotificationFormProps } from "./models";

const NotificationForm = memo(({ saveEnabled }: NotificationFormProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Form.Item name={idField} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Mededeling`)}
        name={textField}
        help={i18n._(
          t`De mededeling die toont als notificatie voor alle bezoekers`
        )}
      >
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Status`)}
        name={statusField}
        rules={[{ required: true, message: i18n._(t`Status is verplicht.`) }]}
        help={i18n._(
          t`Een mededeling met de status "Gepubliceerd" wordt getoond`
        )}
      >
        <Select>
          <Select.Option value="Hidden">
            <Badge status="error" text={i18n._(t`Verborgen`)} />
          </Select.Option>
          <Select.Option value="Published">
            <Badge status="success" text={i18n._(t`Gepubliceerd`)} />
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={wrapperColNoLabel}>
        <Button type="primary" htmlType="submit" disabled={!saveEnabled}>
          <Trans>Mededeling opslaan</Trans>
        </Button>
      </Form.Item>
    </>
  );
});

export default NotificationForm;
