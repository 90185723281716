import { Tooltip } from "antd";
import React, { CSSProperties } from "react";

const Ellipsis = ({
  text,
  long,
  style,
}: {
  text: string;
  long?: string;
  style?: CSSProperties;
}): JSX.Element => (
  <Tooltip className="ellipsis" title={long || text}>
    <span style={style}>{text}</span>
  </Tooltip>
);

export default Ellipsis;
