import { Col, Row, Steps } from "antd";
import React, { PropsWithChildren, ReactNode, memo, useMemo } from "react";

import StepContent from "./StepContent";

export type StepWizardOrientation = "horizontal" | "vertical";

const orientationMap: {
  [orientation in StepWizardOrientation]: "horizontal" | "vertical";
} = {
  horizontal: "horizontal",
  vertical: "vertical",
};

export interface StepWizardStep {
  key: string;
  disabled: boolean;
  icon: ReactNode;
  title: string;
}

interface StepWizardProps {
  buttons: ReactNode;
  current: number;
  onChange?: (current: number) => void;
  orientation: StepWizardOrientation;
  steps: PropsWithChildren<StepWizardStep>[];
}

const StepWizard = memo(
  ({ buttons, current, onChange, orientation, steps }: StepWizardProps) => {
    const direction = useMemo(() => orientationMap[orientation], [orientation]);

    const stepsNode = useMemo(
      () => (
        <Steps direction={direction} current={current} onChange={onChange}>
          {steps.map(({ key, disabled, icon, title }) => (
            <Steps.Step
              key={key}
              disabled={disabled}
              icon={icon}
              title={title}
            />
          ))}
        </Steps>
      ),
      [current, direction, onChange, steps]
    );

    const stepsContentNodes = useMemo(
      () =>
        steps.map(({ key, children }, index) => (
          <StepContent key={key} step={index} currentStep={current}>
            {children}
          </StepContent>
        )),
      [current, steps]
    );

    const layout = useMemo(() => {
      switch (orientation) {
        case "vertical":
          return (
            <Row>
              <Col span={6}>{stepsNode}</Col>
              <Col span={18}>
                <Row className="tip-stepWizard-stepContent">
                  <Col span={24}>{stepsContentNodes}</Col>
                </Row>
                <Row>
                  <Col span={24}>{buttons}</Col>
                </Row>
              </Col>
            </Row>
          );
        case "horizontal":
        default:
          return (
            <>
              <Row>
                <Col span={24}>{stepsNode}</Col>
              </Row>
              <Row>
                <Col span={24}>{stepsContentNodes}</Col>
              </Row>
              <Row className="tip-stepWizard-buttons">
                <Col span={24}>{buttons}</Col>
              </Row>
            </>
          );
      }
    }, [buttons, orientation, stepsContentNodes, stepsNode]);

    return <section className="tip-stepWizard">{layout}</section>;
  }
);

export default StepWizard;
