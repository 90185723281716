import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Tooltip } from "antd";
import moment from "moment/moment";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { DashboardOrder } from "../../models/Dashboard";
import { getIdFromId, operatorLabels } from "../../models/Operator";
import { OperatorIcon } from "../OperatorIcon";

const CampaignLinkButton = ({
  order,
  dateFrom,
  dateTo,
}: {
  order: DashboardOrder;
  dateFrom: Date;
  dateTo: Date;
}) => {
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(
      `/campagnes?dateFrom=${moment(dateFrom).format(
        "YYYY-MM-DD"
      )}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}&orderIds=${order.id}`
    );
  }, [order.id, dateFrom, dateTo, navigate]);

  const id = useMemo(() => getIdFromId(order.id), [order.id]);

  return (
    <Tooltip
      title={i18n._(
        t`Bekijk de details van de campagne met ID ${id} bij ${
          operatorLabels[order.operator]
        }`
      )}
    >
      <Button
        icon={<OperatorIcon operator={order.operator} style={{ width: 24 }} />}
        onClick={handleClick}
        style={{
          backgroundColor: "#008eaf",
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
        }}
      >
        {order.name}
        {order.operator === "Ster" && <>&#160;(ID: {id})</>}
      </Button>
    </Tooltip>
  );
};

export default CampaignLinkButton;
