export const formatNumber = (
  amount: number,
  fractionDigits = 2,
  culture = "nl",
  useGrouping = true
): string =>
  new Intl.NumberFormat(culture, {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    useGrouping,
  }).format(
    Math.round((amount + Number.EPSILON) * 10 ** fractionDigits) /
      10 ** fractionDigits
  );

export const parseNumber = (amount: string): number => {
  const reversedVal = amount.replace(/\./g, "").replace(/,/g, ".");
  const asNumber = parseFloat(reversedVal);
  return reversedVal && Number.isNaN(asNumber) ? 0 : asNumber;
};
