import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Table, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { saveAs } from "file-saver";
import moment from "moment";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Organisation from "../../../models/Organisation";
import {
  MonthNumber,
  StatisticsByUserModel,
} from "../../../models/StatisticsModels";
import { HttpStatusCode } from "../../../store/fetch";
import { RequestAction, StoreModel } from "../../../store/models";
import { organisationsCell } from "../../../store/organisations/cells";
import sagaTypes from "../../../store/sagaTypes";
import {
  UserLoginByOrganisationStatisticsForYearRequest,
  UserLoginByOrganisationStatisticsForYearResponse,
} from "../../../store/statistics/models";
import navigationPaths from "../../../utils/navigation";
import BigScreen from "../../../views/layout/BigScreen";
import Flex from "../../../views/layout/Flex";
import OrganisationSelect from "../../../views/management/statistics/OrganisationSelect";
import StatisticsContent from "../../../views/management/statistics/StatisticsContent";
import StatisticsUserNameView from "../../../views/management/statistics/StatisticsUserNameView";
import BackButton from "../../../views/navigation/BackButton";
import YearSelect, { YearChangeFunc } from "../../../views/YearSelect";
import ExportButton from "../../actions/ExportButton";
import { getTotals, totalFilterOptions } from "./utils";

const organisationsEmpty: Organisation[] = [];

const StatisticsByUser =
  /**
   * Shows login statistics by user for a particular organisation.
   */
  () => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { organisationId, year: initialYear } = useParams() as {
      organisationId: string;
      year: string;
    };

    const { value: organisations } = useSelector(
      ({ organisations: { value } }: StoreModel) => ({ value })
    );

    const {
      status: { loading: userLoginsByOrganisationForYearLoading },
      value: userLoginsByOrganisationForYearValue,
    } = useSelector(
      ({ statistics: { userLoginsByOrganisationForYear } }: StoreModel) =>
        userLoginsByOrganisationForYear
    );

    const {
      status: { loading: exportUserLoginsByOrganisationForYearLoading },
    } = useSelector(
      ({ exports: { userLoginsByOrganisationForYear } }: StoreModel) =>
        userLoginsByOrganisationForYear
    );

    const [year, setYear] = useState(
      parseInt(initialYear || moment().year().toString(), 10)
    );
    const handleYearChange = useCallback<YearChangeFunc>(
      /**
       * Handles a change in the year selection.
       * @param value The newly selected year.
       */
      (value) => {
        setYear(value);
      },
      []
    );

    const handleOrganisationChange = (value: Organisation | null) => {
      const organisationKey = value === null ? "all" : value.id;
      navigate(`/beheer/rapportage/${organisationKey}/${year}`);
    };

    const handleExportClick = useCallback(
      /**
       * Handles the 'click' event on the export button.
       * This should result in downloading an export file that contains the statistics by user for the current organisation.
       */
      () => {
        dispatch({
          type: sagaTypes.exports.userLoginsByOrganisationForYear.request,
          payload: {
            organisationId: organisationId as string,
            year,
          },
          onFail: () => {
            message.error(i18n._(t`Er is iets misgegaan.`));
          },
          onSuccess: (_statusCode?: HttpStatusCode, response?: Blob) => {
            if (!response) {
              return;
            }

            const filename = `LoginsPerGebruikerInOrganisatie_${organisationId}_${year}.xlsx`;
            if (window.navigator.msSaveBlob) {
              window.navigator.msSaveBlob(response, filename);
            } else {
              saveAs(response, filename);
            }
          },
        });
      },
      [dispatch, i18n, organisationId, year]
    );

    const columns = useMemo(
      (): ColumnProps<StatisticsByUserModel>[] => [
        {
          key: "name",
          title: i18n._(t`Gebruiker`) as string,
          render: (_text, { name, userStatus }): ReactNode => (
            <StatisticsUserNameView name={name} userStatus={userStatus} />
          ),
        },
        {
          key: "organisation",
          title: i18n._(t`Organisatie`) as string,
          dataIndex: "organisationName",
        },
        ...moment.monthsShort().map((monthName, index) => {
          const monthNumber = `${index + 1}`;
          return {
            key: monthName,
            title: monthName,
            render: (
              _: string,
              { statistics }: StatisticsByUserModel
            ): ReactNode => (
              <>
                {(statistics && statistics[`${monthNumber}` as MonthNumber]) ||
                  0}
              </>
            ),
          };
        }),
        {
          key: "total",
          title: i18n._(t`Totaal`) as string,
          ...totalFilterOptions,
          render: (_text, { statistics }): ReactNode => getTotals(statistics),
        },
      ],
      [i18n]
    );

    useEffect(() => {
      dispatch(organisationsCell.require());
      dispatch<
        RequestAction<
          UserLoginByOrganisationStatisticsForYearRequest,
          UserLoginByOrganisationStatisticsForYearResponse
        >
      >({
        type: sagaTypes.statistics.userLoginsByOrganisationForYear.request,
        payload: {
          organisationId: organisationId || "",
          year,
        },
      });
    }, [dispatch, organisationId, year]);

    const handleBackButtonClick = useCallback(() => {
      navigate(navigationPaths.StatisticsByOrganisation);
    }, [navigate]);

    const selectedOrganisation =
      organisationId === "all" || !organisations
        ? null
        : organisations.find(({ id }) => id === organisationId) || null;

    return (
      <StatisticsContent>
        <BackButton onClick={handleBackButtonClick}>
          <Trans id="Terug naar organisaties">Terug naar organisaties</Trans>
        </BackButton>
        <Flex align="end" type="placeholder">
          <YearSelect onChange={handleYearChange} value={year} />
          <OrganisationSelect
            onChange={handleOrganisationChange}
            options={organisations || organisationsEmpty}
            value={selectedOrganisation}
          />
          <ExportButton
            iconType="Excel"
            loading={exportUserLoginsByOrganisationForYearLoading}
            onClick={handleExportClick}
          >
            <BigScreen type="text">
              <Trans>Exporteren</Trans>
            </BigScreen>
          </ExportButton>
        </Flex>
        <Table
          columns={columns}
          dataSource={
            userLoginsByOrganisationForYearValue &&
            userLoginsByOrganisationForYearValue.statistics
          }
          loading={
            !userLoginsByOrganisationForYearValue &&
            userLoginsByOrganisationForYearLoading
          }
          pagination={false}
          rowKey={(statistic): string => (statistic && statistic.userId) || ""}
        />
      </StatisticsContent>
    );
  };

export default memo(StatisticsByUser);
