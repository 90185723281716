import React, { Fragment, memo } from "react";

import { Operator, OperatorOrn } from "../../models/Operator";
import Euro from "../Euro";
import NotAvailable from "../NotAvailable";

interface CollapseListExtraStatisticsProps {
  operator: Operator;
  statistics: { [label: string]: number };
}

const CollapseListExtraStatistics = memo(
  ({ operator, statistics }: CollapseListExtraStatisticsProps) => {
    const extraStatistics = (
      <dl>
        {Object.entries(statistics).map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index.toString()}>
            <dt>{value[0]}</dt>
            <dd>
              {operator === OperatorOrn && value && value[1] === 0 ? (
                <NotAvailable />
              ) : (
                <Euro amount={value[1]} fractionDigits={0} />
              )}
            </dd>
          </Fragment>
        ))}
      </dl>
    );

    return (
      <div className="tip-collapseList-extraStatistics">{extraStatistics}</div>
    );
  }
);

export default CollapseListExtraStatistics;
