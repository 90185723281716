import { Col, Row } from "antd";
import React, { Children, PropsWithChildren, memo } from "react";

const ExternalApiClientsContent = memo(
  ({ children }: PropsWithChildren<unknown>) => (
    <section className="tip-externalApiClients">
      {children &&
        Children.map(children, (child) => (
          <Row>
            <Col span={24}>{child}</Col>
          </Row>
        ))}
    </section>
  )
);

export default ExternalApiClientsContent;
