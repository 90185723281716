import {
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AlternationType,
  AlternationTypeAll,
  CommercialInstruction,
  alternationTypeLabel,
  emptyInstruction,
  emptyInstructions,
  emptyRotations,
  instructionStatusLabel,
} from "../../models/Instructions";
import {
  Operator,
  OperatorTalpa,
  getOperatorFromId,
} from "../../models/Operator";
import Order from "../../models/Order";
import {
  instructionsCell,
  instructionsSubmitCell,
  previousInstructionsCell,
} from "../../store/instructions/cells";
import { DatePeriod } from "../../utils/date";
import InstructionTable from "../../views/instructions/InstructionTable";
import { getInstructionTitle } from "../../views/InstructionTitle";
import { OperatorAvatar } from "../../views/OperatorIcon";
import PeriodView from "../../views/PeriodView";
import CopyInstructionsDialog from "./CopyInstructionsDialog";
import InstructionsModalContainer, {
  InstructionEditMode,
} from "./InstructionsModalContainer";
import ShowInfoMessage from "./ShowInfoMessage";
import SubOrderSelect from "./SubOrderSelect";

export interface InstructionTableContainerProps {
  operator: Operator;
  instructionsId: string;
  loading?: boolean;
  instructions?: CommercialInstruction[];
  order: Order;
  canEdit?: boolean;
  canShowDetails?: boolean;
  onEdit?: (mode: InstructionEditMode) => void;
  instructionEditMode: InstructionEditMode;
  isPlanner: boolean;
  minStartDate: Moment;
  availablePeriods: DatePeriod[];
  inCampaignView?: boolean;
}

interface InstructionForm {
  period: RangeValue<Moment>;
  alternationType: AlternationType;
  subOrderId?: string;
  info?: string;
}

const InstructionTableContainer = ({
  operator,
  instructionsId,
  loading,
  instructions,
  order,
  canEdit,
  canShowDetails,
  onEdit,
  instructionEditMode,
  isPlanner,
  minStartDate,
  availablePeriods,
  inCampaignView,
}: InstructionTableContainerProps): JSX.Element => {
  const { subOrders } = order;
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm<InstructionForm>();
  const [original, setOriginal] =
    useState<CommercialInstruction>(emptyInstruction);

  const submitter = useSelector(instructionsSubmitCell.select);

  const canAdd = useMemo(() => {
    const now = moment().startOf("day");
    return (
      order.subOrders.some((s) => now.isBefore(s.startDate, "day")) &&
      availablePeriods.length > 0
    );
  }, [availablePeriods.length, order.subOrders]);

  const newEmptyInstruction: CommercialInstruction | undefined = useMemo(
    () => ({
      creationDate: moment().toDate(),
      startDate: (
        availablePeriods?.[0]?.from ?? moment(order.startDate)
      ).toDate(),
      endDate: (availablePeriods?.[0]?.to ?? moment(order.endDate)).toDate(),
      status: "Concept",
      alternationType: "Percentage",
      product: order.product,
      orderId: order.id,
      rotations: emptyRotations,
    }),
    [availablePeriods, order.endDate, order.id, order.product, order.startDate]
  );

  const addNewInstruction = useCallback(
    (
      newInstruction: CommercialInstruction | undefined = undefined,
      callback: (() => void) | undefined = undefined
    ) => {
      if (!newInstruction && !newEmptyInstruction) {
        return;
      }

      dispatch(
        instructionsCell.update(
          {
            id: instructionsId,
            instructions: [
              (newInstruction ?? newEmptyInstruction) as CommercialInstruction,
            ],
          },
          {
            onSuccess: () => {
              setOriginal(emptyInstruction);
              setEditMode(false);
              onEdit?.("None");
              if (callback) {
                callback();
              }
              message.success(i18n._(t`Instructie toegevoegd.`));
            },

            onFail: () => {
              message.error(
                i18n._(t`Er ging iets mis met het toevoegen van de instructie.`)
              );
            },
          }
        )
      );
    },
    [dispatch, i18n, instructionsId, newEmptyInstruction, onEdit]
  );

  const [showCopyInstructions, setCopyInstructions] = useState(false);
  const handleCancelCopy = useCallback(() => {
    setCopyInstructions(false);
    dispatch({
      type: previousInstructionsCell.events.clear,
      payload: { id: instructionsId },
    });
  }, [dispatch, instructionsId]);

  const handleSelectCopy = useCallback(
    (i: CommercialInstruction) => {
      const period = availablePeriods[0];
      if (!period) {
        return;
      }

      addNewInstruction(
        {
          ...i,
          creationDate: moment().toDate(),
          startDate: period.from.toDate(),
          endDate: period.to.toDate(),
          orderId: order.id,
          status: "Concept",
          rotations: i.rotations.map((r) => ({
            ...r,
            startDate: period.from.toDate(),
            endDate: period.to.toDate(),
          })),
        },
        handleCancelCopy
      );
    },
    [addNewInstruction, availablePeriods, handleCancelCopy, order.id]
  );

  const handleAddInstruction = useCallback(() => {
    dispatch(
      previousInstructionsCell.require(instructionsId, {
        onSuccess: ({ body: { instructions: previousInstructions } }) => {
          if (
            (previousInstructions?.filter((i) => i.status === "Processed")
              .length ?? 0) > 0
          ) {
            setCopyInstructions(true);
          } else {
            // there is nothing to copy => create empty instruction
            addNewInstruction();
          }
        },
      })
    );
  }, [addNewInstruction, dispatch, instructionsId]);

  const handleEdit = useCallback(
    (instruction: CommercialInstruction | undefined = undefined) => {
      if (!onEdit) {
        return;
      }

      const newEditMode = !editMode;
      if (newEditMode && instruction) {
        // Fill form to edit
        onEdit("Instruction");
        form.setFieldsValue({
          period: [moment(instruction.startDate), moment(instruction.endDate)],
          alternationType: instruction.alternationType,
          subOrderId: instruction.subOrderId,
          info: instruction.info,
        });
        setOriginal(instruction);
      } else {
        // Cancel
        onEdit("None");
        form.resetFields();
        setOriginal(emptyInstruction);
      }

      setEditMode(newEditMode);
    },
    [editMode, form, onEdit]
  );

  // Set initial editMode = true when creating new instruction
  useEffect(() => {
    if (
      canEdit &&
      !editMode &&
      instructions &&
      instructions[0] &&
      !instructions[0].id &&
      !instructions[0].instructionId
    ) {
      handleEdit(instructions[0]);
    }
  }, [canEdit, editMode, handleEdit, instructions]);

  const handleFinishForm = useCallback(
    (formObject: InstructionForm) => {
      const { period, alternationType, subOrderId, info } = formObject;
      if (!period || !period[0] || !period[1]) {
        return;
      }

      dispatch(
        instructionsCell.update(
          {
            id: instructionsId,
            instructions: [
              {
                creationDate: moment().toDate(),
                ...original,
                startDate: period[0].toDate(),
                endDate: period[1].toDate(),
                alternationType,
                subOrderId,
                info,
              },
            ],
          },
          {
            onSuccess: () => {
              setOriginal(emptyInstruction);
              setEditMode(false);
              onEdit?.("None");
              message.success(i18n._(t`Instructie opgeslagen.`));
            },

            onFail: () => {
              message.error(
                i18n._(t`Er ging iets mis met het opslaan van de instructie.`)
              );
            },
          }
        )
      );
    },
    [dispatch, i18n, instructionsId, onEdit, original]
  );

  const showAlert = useMemo(
    () =>
      getOperatorFromId(order.id) !== OperatorTalpa &&
      (instructions ?? emptyInstructions).some((i) => i.status === "Concept") &&
      isPlanner,
    [instructions, isPlanner, order.id]
  );

  const columns: ColumnsType<CommercialInstruction> = [
    {
      key: "period",
      align: "center",
      title: (): ReactNode => <OperatorAvatar operator={operator} />,
      width: !canShowDetails ? 300 : 150,
      render: (_, { startDate, endDate, rotations }): ReactNode =>
        editMode ? (
          <Form.Item
            name="period"
            rules={[{ required: true, message: i18n._(t`Verplicht`) }]}
            help={i18n._(t`Kies een periode voor deze instructie`)}
          >
            <DatePicker.RangePicker
              format="D MMM YYYY"
              allowClear={false}
              disabledDate={(current: Moment | null) =>
                Boolean(
                  current &&
                    !(
                      current.isBetween(
                        moment(startDate),
                        moment(endDate),
                        "day",
                        "[]"
                      ) ||
                      availablePeriods.some(({ from, to }) =>
                        current.isBetween(from, to, "day", "[]")
                      )
                    )
                )
              }
              disabled={rotations.length > 0}
            />
          </Form.Item>
        ) : (
          <PeriodView from={startDate} to={endDate} type="short" />
        ),
    },
    {
      key: "title",
      title: i18n._(t`Titel`),
      ellipsis: true,
      render: (_, instruction): ReactNode => {
        const { subOrderId } = instruction;
        const subOrder = subOrderId
          ? subOrders.find((s) => s.id === subOrderId)
          : undefined;

        if (
          editMode &&
          subOrders.length > 1 &&
          order.id &&
          getOperatorFromId(order.id) !== OperatorTalpa
        ) {
          return (
            <Row>
              <Col flex="auto">
                <Form.Item
                  name="subOrderId"
                  help={i18n._(t`Maak een selectie`)}
                >
                  <SubOrderSelect subOrders={subOrders} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="info" noStyle>
                  <ShowInfoMessage editMode />
                </Form.Item>
              </Col>
            </Row>
          );
        }

        return (
          <Row>
            <Col flex="auto">{getInstructionTitle(order, subOrder)}</Col>
            {instruction.info && (
              <Col>
                <ShowInfoMessage value={instruction.info} />
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      key: "alternationType",
      title: i18n._(t`Type afwisseling`),
      width: 200,
      render: (_, { alternationType }): ReactNode =>
        editMode ? (
          <Tooltip
            title={i18n._(
              t`Om commercials alternerend over de zenders heen in te zetten, dien je voor type afwisseling "Procentueel" te kiezen met daarbij een gelijke frequentie per rotatie.`
            )}
          >
            <Form.Item
              name="alternationType"
              rules={[{ required: true, message: i18n._(t`Verplicht`) }]}
              help={i18n._(t`Maak een selectie`)}
            >
              <Select>
                {AlternationTypeAll.map((a) => (
                  <Select.Option key={a} value={a}>
                    {alternationTypeLabel[a]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Tooltip>
        ) : (
          <>{i18n._(alternationTypeLabel[alternationType])}</>
        ),
    },
    {
      key: "rotations",
      title: i18n._(t`Aantal rotaties`),
      width: 150,
      render: (_, { rotations }): ReactNode => <>{rotations.length}</>,
    },
    {
      key: "status",
      title: i18n._(t`Status`),
      width: 200,
      render: (_, { status }): ReactNode => (
        <>{i18n._(instructionStatusLabel[status])}</>
      ),
    },
    {
      key: "show",
      title: i18n._(t`Acties`),
      width: 200,
      render: (_, instruction): ReactNode => (
        <InstructionsModalContainer
          instructionsId={instructionsId}
          instruction={instruction}
          operator={operator}
          order={order}
          minStartDate={minStartDate}
          availablePeriods={availablePeriods}
        />
      ),
    },
    {
      key: "edit",
      width: 100,
      title: i18n._(t`Acties`),
      render: (_, instruction): ReactNode =>
        editMode ? (
          <Space>
            <Tooltip title={i18n._(t`Opslaan`)}>
              <Button
                key="save"
                icon={<SaveOutlined />}
                type="link"
                htmlType="submit"
              />
            </Tooltip>
            <Tooltip title={i18n._(t`Annuleren`)}>
              <Button
                key="cancel"
                icon={<UndoOutlined />}
                type="link"
                onClick={() => handleEdit()}
              />
            </Tooltip>
          </Space>
        ) : (
          <Tooltip title={i18n._(t`Bewerken`)}>
            <Button
              key="edit"
              icon={<EditOutlined />}
              type="link"
              onClick={() => handleEdit(instruction)}
              disabled={!["None", "Instruction"].includes(instructionEditMode)}
            />
          </Tooltip>
        ),
    },
  ];

  const table = [
    <InstructionTable
      instructions={instructions}
      canShowDetails={canShowDetails}
      canEdit={canEdit}
      loading={Boolean(submitter.status.loading || loading)}
      columns={columns}
      key="table"
    />,
    showAlert && (
      <Alert
        showIcon
        type="warning"
        description={i18n._(
          t`Let er op dat uitzendinstructies elkaar niet in periode mogen overlappen. En zodra je uitzendinstructies invoert per deelorder, zul je alle deelorders moeten voorzien van een uitzendinstructie en kun je een uitzendinstructie voor een gehele order achterwege laten.`
        )}
        key="alert"
      />
    ),
  ];

  return (
    <>
      <Form form={form} onFinish={handleFinishForm}>
        {inCampaignView && !canEdit && isPlanner && canAdd ? (
          <Space direction="vertical">
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={handleAddInstruction}
            >
              {i18n._(t`Uitzendinstructie toevoegen`)}
            </Button>
            {table}
          </Space>
        ) : (
          <Space direction="vertical">{table}</Space>
        )}
      </Form>
      {inCampaignView && showCopyInstructions && newEmptyInstruction && (
        <CopyInstructionsDialog
          instructionsId={instructionsId}
          instructions={[newEmptyInstruction]}
          order={order}
          onCancel={handleCancelCopy}
          onSelect={handleSelectCopy}
        />
      )}
    </>
  );
};

export default memo(InstructionTableContainer);
