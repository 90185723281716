import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Col, Row } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Element, Text } from "domhandler/lib/node";
import parse, { domToReact } from "html-react-parser";
import React, { createElement, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import HeaderTitle from "../../views/HeaderTitle";
import PageContainer from "../../views/pages/PageContainer";
import HtmlHead from "../../views/utils/HtmlHead";

const Page = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { name } = useParams() as { name: string };

  useEffect(() => {
    dispatch({
      type: sagaTypes.pages.request,
      id: name,
      payload: { name },
    });
  }, [name, dispatch]);

  const showTitles = useSelector(
    ({
      application: {
        options: { showTitles: showTitlesFromStore },
      },
    }: StoreModel) => !!showTitlesFromStore
  );

  const { loading, content } = useSelector((store: StoreModel) => {
    const requestedPage = store.pages[name];
    return {
      loading: requestedPage?.status.loading,
      content: requestedPage?.value?.content,
    };
  });

  return (
    <Row>
      <Col span={24}>
        <PageContainer spinning={loading}>
          {content &&
            parse(content, {
              replace(domNode) {
                const {
                  name: nodeName,
                  attribs,
                  parent,
                  children,
                } = domNode as Element;
                if (nodeName === "h1") {
                  // create page header
                  const title =
                    children && children[0] && (children[0] as Text).data
                      ? (children[0] as Text).data
                      : i18n._(t`Titel onbekend`);
                  return (
                    <>
                      <HtmlHead title={title} />
                      <HeaderTitle showTitles={showTitles} title={title} />
                    </>
                  );
                }

                if (!parent && children && nodeName) {
                  return (
                    <Row>
                      <Col offset={2} span={20}>
                        {createElement(nodeName, attribs, domToReact(children))}
                      </Col>
                    </Row>
                  );
                }

                return null;
              },
            })}
        </PageContainer>
      </Col>
    </Row>
  );
});

export default Page;
