import { AsyncResponse } from "async-lifecycle-saga";
import { Moment } from "moment";

import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import { formatDateForUri } from "../../utils/date";
import { getter } from "../fetch";

export interface SkoTargetGroupRequest {
  date?: Moment;
}

// eslint-disable-next-line import/prefer-default-export
export const skoTargetGroupGetter = ({
  date,
}: SkoTargetGroupRequest): Promise<AsyncResponse<SkoTargetGroup[]>> =>
  getter<SkoTargetGroup[]>(
    date
      ? `/api/sko/targetgroups?dateTime=${formatDateForUri(date)}`
      : `/api/sko/targetgroups`
  );
