import { ExpandOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Col, Row, Tooltip } from "antd";
import classNames from "classnames";
import React, { ReactNode, memo, useCallback, useState } from "react";

const Introduction = memo(({ children }: { children?: ReactNode }) => {
  const { i18n } = useLingui();

  const [show, setShow] = useState(() => {
    const value = window.localStorage.getItem("tip-show-introtext") ?? "1";
    return Boolean(Number(value));
  });

  const toggleShow = useCallback(() => {
    const newValue = !show;
    window.localStorage.setItem("tip-show-introtext", String(newValue ? 1 : 0));
    setShow(newValue);
  }, [show]);

  return children ? (
    <div className="md:mx-32 mx-8 my-8 font-edmondsans text-xl flex">
      <div
        className={classNames("real-introtext grow", {
          "truncate-introtext": !show,
        })}
      >
        {children}
      </div>
      <div>
        <Button
          type="text"
          icon={
            show ? (
              <Tooltip title={i18n._(t`Introductietekst inklappen`)}>
                <FullscreenExitOutlined />
              </Tooltip>
            ) : (
              <Tooltip title={i18n._(t`Introductietekst uitklappen`)}>
                <ExpandOutlined />
              </Tooltip>
            )
          }
          onClick={toggleShow}
        />
      </div>
    </div>
  ) : null;
});

const IntroductionTitle = memo(({ children }: { children?: ReactNode }) => (
  <div className="tip-intro-title">{children}</div>
));

export const IntroductionDashboard = (): JSX.Element => {
  const { i18n } = useLingui();

  return (
    <Introduction>
      <Trans>
        <p>
          Dit is het dashboard van TIP. Hier vind je een zelf samengesteld
          overzicht van de meest interessante campagne informatie. Al deze
          informatie zie je in een zogenaamde tegel. Deze tegels kun je zelf
          aanmaken door op het tandwieltje te klikken waarna een button
          verschijnt waarin je een tegel kunt toevoegen. Wil je op een later
          moment iets veranderen of toevoegen aan een tegel, klik dan op het
          pennetje in de rode balk om de tegel te bewerken.
        </p>
        <p>
          Een handleiding en tutorialvideo voor het gebruik van het Dashboard
          vind je{" "}
          <a
            href="https://screenforce.nl/over-tip/"
            target="_blank"
            rel="noreferrer"
          >
            hier
          </a>
          .
        </p>
        <p>
          Al deze informatie wordt ook gebruikt voor de TIP-app. Deze app kun je
          downloaden via de Google Store of via de App Store.
        </p>
      </Trans>
      <Row>
        <Col>
          <a
            href="https://play.google.com/store/apps/details?id=nl.appbakkers.tipapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            style={{
              display: "inline-block",
              overflow: "hidden",
              height: i18n.locale === "nl" ? "73px" : "83px",
              paddingTop: i18n.locale === "nl" ? "5px" : undefined,
            }}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt={i18n._(t`Ontdek het op Google Play`)}
              src={i18n._(
                t`https://play.google.com/intl/en_us/badges/static/images/badges/nl_badge_web_generic.png`
              )}
              style={{ height: i18n.locale === "nl" ? "73px" : "83px" }}
            />
          </a>
        </Col>
        <Col>
          <a
            href="https://apps.apple.com/nl/app/tip-dashboard/id6447582239?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              height: "73px",
              padding: "13px",
            }}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={i18n._(
                t`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/nl-nl?size=250x83&amp;releaseDate=1700438400`
              )}
              alt={i18n._(t`Download in de App Store`)}
              style={{ borderRadius: "13px", height: "57px" }}
            />
          </a>
        </Col>
      </Row>
    </Introduction>
  );
};

export const IntroductionCampaigns = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Bekijk, print of exporteer ingekochte TV-campagnes en krijg inzicht in
        zowel de geprognosticeerde als de gerealiseerd GRP&apos;s op de
        inkoopdoelgroep. Deze worden door de exploitant met de campagnedata
        meegeleverd.
      </p>
      <p>
        De GRP analyse biedt de mogelijkheid om de campagne te evalueren op een
        doelgroep naar eigen keuze. Hiervoor levert NMO de GRP&apos;s voor 32
        doelgroepen.
      </p>
    </Trans>
    <IntroductionTitle>
      <Trans>Campagne wijzigen</Trans>
    </IntroductionTitle>
    <Trans>
      <p>
        Spots van een lopende campagne kunnen gewijzigd worden. Dit kan wanneer
        een inkoopoptie het toestaat om zelf een blokselectie op te geven,
        bijvoorbeeld specifieke inkoop.
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionRequests = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        In dit overzicht staan zowel de conceptaanvragen als de ingediende
        aanvragen. De conceptaanvragen zijn nog niet ingediend bij de
        exploitant. Een conceptaanvraag indienen kan door op
        &apos;bewerken&apos; en daarna op &apos;indienen&apos; te drukken.
        Ingediende aanvragen zijn aanvragen die ingediend zijn en door de
        exploitant zijn verwerkt.
      </p>
      <p>
        Het is mogelijk een eerder ingediende aanvraag te kopiëren om dat als
        basis te gebruiken voor je nieuwe aanvraag. Hierbij zul je bij het
        kopiëren moeten aangeven voor welke periode je een kopie wilt maken
        (indien er meerdere verkoopperiodes open staan).
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionRequestsNew = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Vraag een campagne aan bij Ster, Talpa Network, Ad Alliance en ORN. De
        aanvraag wordt samengesteld door de periode te selecteren met het juiste
        pakket en doelgroep. De GRP&apos;s voor de gekozen doelgroep worden
        berekend na het invullen van het budget. Aanvragen worden automatisch
        doorgezet naar de betreffende exploitant. Na de verwerking van de
        aanvraag is de campagne in TIP terug.
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionRequestsConcept = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        In dit overzicht staan de nog niet ingediende aanvragen. Een
        conceptaanvraag indienen kan door op &apos;bewerken&apos; en daarna op
        &apos;indienen&apos; te drukken.
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionCommercials = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Aangeleverde commercials staan in de TIP bibliotheek. TIP maakt een low
        res kopie van de commercial voordat deze wordt verstuurd en plaatst deze
        in de eigen bibliotheek. Van de commercial is terug te zien wanneer hij
        bij welke exploitant is aangeleverd en of deze aanlevering ook is
        gelukt.
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionCommercialsDelivery = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Lever met één druk op de knop de commercial aan bij Ster, Ad Alliance,
        Talpa Network en/of ORN. Je hoeft niet meer voor elke exploitanten los
        aan te leveren. Als je meerdere commercials hebt, moet je wel meerdere
        uploads doen.
      </p>
    </Trans>
    <IntroductionTitle>Geen XML uploaden</IntroductionTitle>
    <Trans>
      <p>
        Het zogenaamde XML bestand met metadata over de commercial wordt, na het
        invullen van de gevraagde gegevens, door TIP gegenereerd. Dit digitale
        formulier wordt samen met de commercial meegeleverd bij de geselecteerde
        exploitanten. Jij hoeft hier zelf niets voor te doen.
      </p>
    </Trans>
    <IntroductionTitle>Technische Voorschriften</IntroductionTitle>
    <Trans>
      <p>
        Let op dat je voor de aanlevering van de TV-commercial rekening dient te
        houden met de technische voorschriften voor commercials zoals die door
        de exploitanten zijn samengesteld. Meer informatie hierover vind je{" "}
        <a
          href="https://screenforce.nl/wp-content/uploads/2022/05/Technische-voorschriften-TV-commercials-en-billboards-Screenforce.pdf"
          target="_blank"
          rel="noreferrer"
        >
          op de site van Screenforce
        </a>
        .<br />
        Tevens dient per levering de titel van de commercial uniek te zijn. Deze
        titel wordt namelijk gebruikt bij het samenstellen van de uiteindelijke
        bestandsnaam.
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionStatus = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Eventuele storingen bij TIP worden weergegeven in dit overzicht. Op deze
        manier wordt het inzichtelijk als zich een fout voordoet bij
        bijvoorbeeld het aanleveren van een MXF bestand of het ophalen van
        campagnegegevens. Mocht er een storing zijn dan worden Twijg IT (de
        bouwer van TIP) en de betreffende exploitant hier ook van op de hoogte
        gesteld.
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionProfile = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Met behulp van onderstaand formulier kun je je profielgegevens bekijken
        en/of wijzigen.
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionCalendar = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Het aanvragen van TV-reclamezendtijd is een proces dat op maandbasis
        plaatsvindt. Om zo goed mogelijk bediend te worden door een exploitant
        is het belangrijk dat de aanvraag voor de desbetreffende maand tijdig
        verstuurd wordt. De exploitanten hanteren een strak schema voor de
        publicatie van hun programmering en tarieven en voor het in ontvangst
        nemen van de campagne-aanvragen.
      </p>
      <p>
        In deze kalender vind je de aanvraagperiodes, de datum van versturen van
        de orderbevestigen en de data van het versturen van facturen. Eventuele
        events of andere belangrijke data worden ook in deze kalender vermeld.
      </p>
    </Trans>
  </Introduction>
);

export const OutroductionCalendar = (): JSX.Element => (
  <Introduction>
    <Trans>
      <h3 className="font-bold">Uitleg Kalender</h3>
      <p>
        In de kalender zie je de belangrijkste data zoals de start van de inkoop
        en wanneer de orderbevestigingen en facturen verstuurd worden. Ook
        relevante events vind je hier terug. Door op een icoontje te klikken,
        zie je wat die betekent. Via de selectie in de rode balk, kom je bij een
        vorige of volgende maand.
      </p>
      <h3 className="font-bold">Start inkoop</h3>
      <p>
        Onder ‘publicatie’ staat de datum waarop de broadcasters hun tarieven en
        nieuwe programmering bekend maken. Dit is de eerste dag waarop
        mediabureaus en adverteerders in de gelegenheid worden gesteld om
        TV-campagnes aan te vragen.
      </p>
      <h3 className="font-bold">Einde inkoop</h3>
      <p>
        ‘Einde inkoop’ is de allerlaatste dag waarop adverteerders de aanvraag
        kunnen insturen. Voor de broadcasters is het belangrijk dat de aanvraag
        voor de desbetreffende maand tijdig – binnen deze periode – wordt
        ingediend. Na de sluitingsdatum verwerken de broadcasters alle
        campagneaanvragen in het TV-uitzendschema voor de adverteerders.
      </p>
      <h3 className="font-bold">Ontvangen orderbevestigingen</h3>
      <p>
        De aanvraag wordt definitief vastgesteld op de datum vermeld onder
        ‘ontvangen orderbevestigingen’. De algemene voorwaarden van de
        broadcasters gelden vanaf deze datum.
      </p>
      <h3 className="font-bold">Facturatiedatum</h3>
      <p>
        Op deze datum wordt de factuur verstuurd. Dit zijn echter streefdata en
        kunnen per broadcaster verschillen.
      </p>
      <h3 className="font-bold">Events</h3>
      <p>Relevante events in de TV / mediabranche.</p>

      <p>
        Kijk voor alle publicatiedata op de Screenforce website:{" "}
        <a
          href="https://screenforce.nl/tip/publicatiedata/"
          target="_blank"
          rel="noreferrer"
        >
          https://screenforce.nl/tip/publicatiedata/
        </a>
      </p>
    </Trans>
  </Introduction>
);

export const IntroductionSettings = (): JSX.Element => (
  <Introduction>
    <Trans>
      <p>
        Met dit menu kun je adverteerders bij verschillende exploitanten
        samenvoegen zodat de campagnegegevens in één overzicht getoond worden.
      </p>
    </Trans>
  </Introduction>
);
