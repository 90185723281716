import React from "react";

import Order from "../models/Order";
import SubOrder from "../models/SubOrder";
import { getSubOrderName } from "../utils";
import Ellipsis from "./Ellipsis";

interface InstructionTitleProps {
  order?: Order;
  subOrder?: SubOrder;
}

export const getInstructionTitle = (
  order: Order | undefined = undefined,
  subOrder: SubOrder | undefined = undefined
): string => {
  if (subOrder) {
    return getSubOrderName(subOrder);
  }

  if (order) {
    return order.name;
  }

  return "Uitzendinstructie";
};

const InstructionTitle = ({
  order,
  subOrder,
}: InstructionTitleProps): JSX.Element => (
  <Ellipsis text={getInstructionTitle(order, subOrder)} />
);

export default InstructionTitle;
