export default interface TargetGroup {
  id: string;
  description: string;
  intomartId: string;
  costPerGrp?: number;
  targetGroupIndex?: number;
}

export const emptyTargetGroup: TargetGroup = {
  id: "",
  description: "",
  intomartId: "",
};
