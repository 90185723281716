import React, { memo } from "react";
import { Helmet } from "react-helmet";

interface HtmlHeadProps {
  title: string;
}

const HtmlHead = memo(({ title }: HtmlHeadProps) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
));

export default HtmlHead;
