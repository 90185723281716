import { AsyncLifecycle } from "./models";

export const sagaType = (prefix: string): AsyncLifecycle => ({
  prefix,
  skip: `${prefix}_SKIP`,
  start: `${prefix}_START`,
  success: `${prefix}_SUCCESS`,
  error: `${prefix}_ERROR`,
  update: `${prefix}_UPDATE`,
  invalidate: `${prefix}_INVALIDATE`,
  clear: `${prefix}_CLEAR`,
  request: `${prefix}_REQUEST`,
});

const sagaTypes = {
  tokens: {
    activateVerify: sagaType("TOKENS_ACTIVATE_VERIFY"),
    activate: sagaType("TOKENS_ACTIVATE"),
    authenticate: sagaType("TOKENS_AUTHENTICATE"),
    authenticateTwoFactor: sagaType("TOKENS_AUTHENTICATE_TWOFACTOR"),
    refresh: sagaType("TOKENS_REFRESH"),
    access: sagaType("TOKENS_ACCESS"),
  },
  advertisers: {
    byOrganisation: sagaType("ADVERTISERS_FETCH_BY_ORGANISATION"),
    all: sagaType("ADVERTISERS_FETCH"),
  },
  bookSpot: {
    availableBreaks: sagaType("BOOKSPOT_AVAILABLE_BREAKS"),
    bookSpot: sagaType("BOOKSPOT_BOOKSPOT"),
  },
  campaigns: {
    fetchOrdersFiltered: sagaType("CAMPAIGNS_FETCH_ORDERS_FILTERED"),
    updateFilter: "CAMPAIGNS_UPDATE_FILTER_VALUE",
    filters: sagaType("CAMPAIGNS_FILTERS"),
    spotsPerOrder: sagaType("CAMPAIGNS_SPOTS_PER_ORDER"),
    requests: {
      advertisers: sagaType("REQUEST_FETCH_ADVERTISERS"),
      advertisersForFilter: sagaType("REQUEST_FETCH_ADVERTISERS_FOR_FILTER"),
      create: sagaType("REQUEST_CREATE"),
      delete: sagaType("REQUEST_DELETE"),
      orderRequest: sagaType("REQUEST_GET"),
      orderRequestsByAdvertiser: sagaType("REQUESTS_BY_ADVERTISER_GET"),
      packages: sagaType("REQUEST_FETCH_PACKAGES"),
      periods: sagaType("REQUEST_FETCH_PERIODS"),
      products: sagaType("REQUEST_FETCH_PRODUCTS"),
      spotLengthIndices: sagaType("REQUEST_FETCH_SPOTLENGTHINDICES"),
      submit: sagaType("REQUEST_SUBMIT"),
      update: sagaType("REQUEST_UPDATE"),
      availableBreaks: sagaType("REQUEST_AVAILABLEBREAKS"),
    },
  },
  exports: {
    orderExcel: sagaType("EXPORTS_ORDER_EXCEL"),
    conceptExcel: sagaType("EXPORTS_CONCEPT_EXCEL"),
    users: sagaType("EXPORTS_USERS_EXCEL"),
    orderPdf: sagaType("EXPORTS_ORDER_PDF"),
    advertiserOrdersExcel: sagaType("EXPORTS_ADVERTISER_ORDERS_EXCEL"),
    advertiserOrdersPdf: sagaType("EXPORTS_ADVERTISER_ORDERS_PDF"),
    filterOrdersExcel: sagaType("EXPORTS_FILTER_ORDERS_EXCEL"),
    filterOrdersPdf: sagaType("EXPORTS_FILTER_ORDERS_PDF"),
    organisationLoginsForYear: sagaType("EXPORTS_ORGANISATION_LOGINS_FOR_YEAR"),
    organisationRequestsForYear: sagaType(
      "EXPORTS_ORGANISATION_REQUESTS_FOR_YEAR"
    ),
    commercialDeliveriesForYear: sagaType(
      "EXPORTS_ORGANISATION_DELIVERIES_FOR_YEAR"
    ),
    userLoginsByOrganisationForYear: sagaType(
      "EXPORTS_USER_LOGINS_BY_ORGANISATION_FOR_YEAR"
    ),
  },
  externalApi: {
    authorizationAdd: sagaType("EXTERNAL_API_AUTHORIZATION_ADD"),
    authorizationDelete: sagaType("EXTERNAL_API_AUTHORIZATION_DELETE"),
    clients: sagaType("EXTERNAL_API_CLIENTS"),
  },
  localization: {
    set: sagaType("LOCALIZATION_SET"),
  },
  statistics: {
    organisationLoginsForYear: sagaType(
      "STATISTICS_ORGANISATION_LOGINS_FOR_YEAR"
    ),
    organisationRequestsForYear: sagaType(
      "STATISTICS_ORGANISATION_REQUESTS_FOR_YEAR"
    ),
    commercialDeliveriesForYear: sagaType(
      "STATISTICS_ORGANISATION_DELIVERIES_FOR_YEAR"
    ),
    userLoginsByOrganisationForYear: sagaType(
      "STATISTICS_USER_LOGINS_BY_ORGANISATION_FOR_YEAR"
    ),
  },
  users: {
    current: sagaType("CURRENT_USER"),
    all: sagaType("USERS_GET_ALL"),
    register: sagaType("USERS_REGISTER"),
    update: sagaType("USERS_UPDATE"),
    reset: sagaType("USERS_RESET"),
    delete: sagaType("USERS_DELETE"),
    logout: "USER_LOGOUT",
  },
  global: {
    authentication: sagaType("GLOBAL_AUTHENTICATION"),
    resetrequest: sagaType("REQUEST_PASSWORD_RESET"),
    resetVerify: sagaType("PASSWORD_RESET_VERIFY"),
  },
  ui: {
    campaigns: {
      selectOrder: "UI_CAMPAIGNS_SELECT_ORDER",
    },
    adBlocker: sagaType("CHECK_ADBLOCKER"),
  },
  normalisation: {
    deleteAdvertiserGroup: sagaType("NORMALISATION_ADVERTISERGROUP_DELETE"),
    saveAdvertiserGroup: sagaType("NORMALISATION_ADVERTISERGROUP_SAVE"),
  },
  notifications: {
    edit: sagaType("NOTIFICATIONS_EDIT"),
    save: sagaType("NOTIFICATIONS_SAVE"),
    read: sagaType("NOTIFICATIONS_READ"),
  },
  product: sagaType("PRODUCT_CREATE"),
  commercials: {
    overview: sagaType("COMMERCIAL_DELIVERY_OVERVIEW"),
    advertisers: sagaType("COMMERCIAL_DELIVERY_ADVERTISERS"),
    delivery: sagaType("COMMERCIAL_DELIVERY"),
    operators: sagaType("COMMERCIAL_OPERATORS"),
    download: sagaType("COMMERCIAL_DOWNLOAD"),
    deleter: sagaType("COMMERCIAL_DELETE"),
  },
  pages: sagaType("PAGE"),
  invoice: {
    download: sagaType("INVOICE_DOWNLOAD"),
  },
};

export default sagaTypes;
