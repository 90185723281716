import { Action } from "redux";

import { CalendarEventFilter } from "../../models/Calendar";

export const filterCalendarAction = (
  payload: CalendarEventFilter
): Action<string> & { payload: CalendarEventFilter } => ({
  type: "CALENDAREVENT_FILTER",
  payload,
});

export const resetCalendarAction = (): Action<string> => ({
  type: "CALENDAREVENT_RESET",
});
