import { Alert } from "antd";
import React, { memo, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { StoreModel } from "../../../store/models";
import { notificationsReadCell } from "../../../store/notifications/cells";
import CenteredBlock from "../../../views/CenteredBlock";

const NotificationMessage = memo(() => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(notificationsReadCell.require());
  }, [dispatch]);

  const { message } = useSelector(
    ({ notifications: { read } }: StoreModel) => ({
      message: read.value?.notification,
    })
  );

  if (!message || !message.text) {
    return null;
  }

  return (
    <CenteredBlock>
      <Alert
        type="info"
        message={
          <ReactMarkdown linkTarget="_blank">{message.text}</ReactMarkdown>
        }
      />
    </CenteredBlock>
  );
});

export default NotificationMessage;
