import { Trans } from "@lingui/macro";
import moment from "moment";
import React, { memo } from "react";

interface PeriodViewProps {
  from?: Date;
  to?: Date;
  type?: "short" | "full";
}

const PeriodView = memo(({ from, to, type = "full" }: PeriodViewProps) => {
  if (!from || !to) {
    return null;
  }

  const fromMoment = moment(from);
  const toMoment = moment(to);
  if (!fromMoment.isValid() || !toMoment.isValid()) {
    return null;
  }

  const format = type === "full" ? "LL" : "D MMM";

  return (
    <span>
      <Trans>
        {fromMoment.format(format)} t/m {toMoment.format(format)}
      </Trans>
    </span>
  );
});

export default PeriodView;
