import { ApiOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import qrCode from "qrcode";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, {
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import ExternalApiClientAuthorization from "../../models/ExternalApiClientAuthorization";
import { PostExternalApiClientAuthorizationResponse } from "../../store/externalApis/models";
import { HttpStatusCode } from "../../store/fetch";
import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import propertyOf from "../../utils/properties";
import QrCodeViewer from "../../views/authentication/QrCodeViewer";
import ExternalApiClientsContent from "../../views/user/ExternalApiClientsContent";
import CancelButton from "../actions/CancelButton";
import DeleteButton from "../actions/DeleteButton";
import SaveButton from "../actions/SaveButton";

interface ExternalApiClientAuthorizationDecorated
  extends ExternalApiClientAuthorization {
  editorState: "New" | "View";
}

const clientIdField = propertyOf<ExternalApiClientAuthorization>("clientId");
const nameField = propertyOf<ExternalApiClientAuthorization>("name");
const createdField = propertyOf<ExternalApiClientAuthorization>("created");

const confirmMessage = t`Heb je de QR Code gescand of de code als tekst in je app gekopiëerd?`;

const ExternalApiClients = memo(() => {
  const { i18n } = useLingui();
  const [form] = useForm();
  const { isFieldTouched, setFieldsValue } = form;
  const dispatch = useDispatch();
  const [dirtyAuthorization, setDirtyAuthorization] =
    useState<ExternalApiClientAuthorizationDecorated | null>(null);
  const [qrCodeDataUrl, setQRCodeDataUrl] = useState<string | null>(null);
  const [qrCodeDataText, setQRCodeDataText] = useState<string | null>(null);
  const showClientJwtTokenModal = useMemo(
    () => Boolean(qrCodeDataUrl && qrCodeDataText),
    [qrCodeDataText, qrCodeDataUrl]
  );

  const {
    status: { loading: currentUserLoading },
    value: currentUserValue,
  } = useSelector(({ users: { current } }: StoreModel) => current);

  const {
    status: { loading: authorizationAddLoading },
  } = useSelector(
    ({ externalApi: { authorizationAdd } }: StoreModel) => authorizationAdd
  );

  const {
    status: { loading: clientsLoading },
    value: clientsValue,
  } = useSelector(({ externalApi: { clients } }: StoreModel) => clients);

  const loadClients = useCallback(() => {
    dispatch({
      type: sagaTypes.externalApi.clients.request,
    });
  }, [dispatch]);

  const loadCurrentUser = useCallback(() => {
    dispatch({
      type: sagaTypes.users.current.request,
    });
  }, [dispatch]);

  const dataSource = useMemo(() => {
    const authorizations =
      currentUserValue?.externalApiClientAuthorizations.map(
        (auth): ExternalApiClientAuthorizationDecorated => ({
          ...auth,
          editorState: "View",
        })
      );

    const result: ExternalApiClientAuthorizationDecorated[] =
      authorizations || [];
    switch (dirtyAuthorization?.editorState) {
      case "New":
        result.unshift(dirtyAuthorization);
        break;
      case "View":
      default:
        break;
    }

    return result;
  }, [currentUserValue, dirtyAuthorization]);

  const handleQRCodeModalClose = useCallback(() => {
    setQRCodeDataUrl(null);
    setQRCodeDataText(null);
  }, []);

  const tableIsInDirtyState = useMemo(
    () => dataSource && dataSource.some((auth) => auth.editorState === "New"),
    [dataSource]
  );

  const handleClientChange = useCallback(
    (value: string) => {
      const selectedClient = clientsValue?.clients.find((c) => c.id === value);
      if (selectedClient) {
        setFieldsValue({ [nameField]: selectedClient.name });
      }
    },
    [clientsValue, setFieldsValue]
  );

  const handleAddClick = useCallback(() => {
    setDirtyAuthorization({
      clientId: clientsValue?.clients[0].id,
      editorState: "New",
      name: "",
    });
  }, [clientsValue]);

  const handleCancelClick = useCallback(() => {
    setDirtyAuthorization(null);
  }, []);

  const handleDeleteClick = useCallback(
    (itemId?: string) => {
      if (itemId) {
        dispatch({
          type: sagaTypes.externalApi.authorizationDelete.request,
          payload: {
            id: itemId,
          },
          onFail: () => {
            message.error(i18n._(t`Er is iets misgegaan.`));
          },
          onSuccess: () => {
            message.success(i18n._(t`Koppeling ingetrokken.`));
            loadCurrentUser();
          },
        });
      }
    },
    [dispatch, i18n, loadCurrentUser]
  );

  const handleFinish = useCallback(
    (values: Store) => {
      dispatch({
        type: sagaTypes.externalApi.authorizationAdd.request,
        payload: {
          authorization: values,
        },
        onFail: () => {
          message.error(i18n._(t`Er is iets misgegaan.`));
        },
        onSuccess: (
          _statusCode: HttpStatusCode,
          response?: PostExternalApiClientAuthorizationResponse
        ) => {
          if (response) {
            const { clientToken } = response;
            message.success(i18n._(t`Koppeling toegevoegd.`));
            setDirtyAuthorization(null);
            loadCurrentUser();
            qrCode.toDataURL(clientToken, (_err, dataUrl) => {
              setQRCodeDataUrl(dataUrl);
            });
            setQRCodeDataText(clientToken);
          }
        },
      });
    },
    [dispatch, i18n, loadCurrentUser]
  );

  const columns = useMemo(
    (): ColumnProps<ExternalApiClientAuthorizationDecorated>[] => [
      {
        title: i18n._(t`Koppeling`),
        key: clientIdField,
        render: (_text, { clientId, editorState }): ReactNode => {
          const client = clientsValue?.clients.find((c) => c.id === clientId);

          const editNode = (
            <Form.Item
              name={clientIdField}
              rules={[
                {
                  required: true,
                  message: i18n._(t`Koppeling is verplicht.`),
                },
              ]}
            >
              <Select
                autoFocus
                placeholder={i18n._(t`Selecteer een koppeling`)}
                onChange={handleClientChange}
              >
                <Select.Option key="empty" value="" disabled>
                  <Trans>Selecteer een koppeling</Trans>
                </Select.Option>
                {clientsValue?.clients.map(
                  ({ id, name, adminOnly }): ReactElement => (
                    <Select.Option key={id} value={id} title={name}>
                      {name}
                      {adminOnly && (
                        <>
                          {" "}
                          <Trans>(alleen voor beheerders)</Trans>
                        </>
                      )}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          );

          if (editorState === "New") {
            return editNode;
          }
          return client ? (
            <Typography.Text>{client.name}</Typography.Text>
          ) : (
            <Typography.Text disabled>
              <Trans>[Ongeldige applicatie]</Trans>
            </Typography.Text>
          );
        },
      },
      {
        title: i18n._(t`Naam`),
        dataIndex: nameField,
        key: nameField,
        render: (_text, { editorState, name }): ReactNode =>
          editorState === "New" ? (
            <Form.Item
              name={nameField}
              rules={[
                { required: true, message: i18n._(t`Naam is verplicht.`) },
              ]}
            >
              <Input />
            </Form.Item>
          ) : (
            <Typography.Text>{name}</Typography.Text>
          ),
      },
      {
        title: i18n._(t`Gecreëerd op`),
        key: createdField,
        dataIndex: createdField,
        render: (_text, { created, editorState }): ReactNode => {
          const content = (
            <Typography.Text>
              {created ? moment(created).format("LLL") : "-"}
            </Typography.Text>
          );

          return editorState === "New" ? (
            <Form.Item name={createdField}>{content}</Form.Item>
          ) : (
            content
          );
        },
      },
      {
        title: i18n._(t`Acties`),
        key: "Acties",
        render: (_text, { id, editorState }): ReactNode =>
          editorState === "New" ? (
            <Form.Item shouldUpdate>
              {(): ReactElement => (
                <>
                  <SaveButton
                    disabled={
                      (editorState === "New" &&
                        !isFieldTouched(clientIdField)) ||
                      form
                        .getFieldsError()
                        .some(({ errors }) => errors.length > 0)
                    }
                    loading={
                      currentUserLoading ||
                      authorizationAddLoading ||
                      clientsLoading
                    }
                  />
                  <CancelButton onClick={handleCancelClick} />
                </>
              )}
            </Form.Item>
          ) : (
            <DeleteButton
              itemId={id}
              disabled={tableIsInDirtyState}
              title={i18n._(`Koppeling intrekken`)}
              onClick={handleDeleteClick}
            />
          ),
      },
    ],
    [
      authorizationAddLoading,
      clientsLoading,
      clientsValue,
      currentUserLoading,
      form,
      handleCancelClick,
      handleClientChange,
      handleDeleteClick,
      i18n,
      isFieldTouched,
      tableIsInDirtyState,
    ]
  );

  useEffect(() => {
    loadClients();
  }, [dispatch, loadClients]);

  return (
    <ExternalApiClientsContent>
      <Button
        type="primary"
        icon={<ApiOutlined />}
        disabled={tableIsInDirtyState}
        onClick={handleAddClick}
      >
        <Trans>Koppeling toevoegen</Trans>
      </Button>
      <Form form={form} name="ExternalApiClients" onFinish={handleFinish}>
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={currentUserLoading || clientsLoading}
          pagination={false}
          rowKey={({ id }: ExternalApiClientAuthorizationDecorated): string =>
            id || "new"
          }
        />
      </Form>
      <Modal
        title={i18n._(t`App koppelen`)}
        open={showClientJwtTokenModal}
        closable={false}
        footer={
          <Popconfirm
            title={i18n._(confirmMessage)}
            okText={i18n._(t`Ja`)}
            cancelText={i18n._(t`Nee`)}
            onConfirm={handleQRCodeModalClose}
            cancelButtonProps={{ type: "link" }}
          >
            <Button type="primary">
              <Trans>OK</Trans>
            </Button>
          </Popconfirm>
        }
      >
        <Row>
          <Col span={24}>
            <QrCodeViewer
              headerText={
                <Typography.Paragraph type="secondary">
                  <Typography.Text type="secondary">
                    <Trans>
                      Om je app te koppelen heb je een toegangscode nodig. Deze
                      toegangscode kun je hieronder scannen. Als je app dat niet
                      ondersteunt kun je de tekst onderaan kopiëren en plakken
                      in de app.
                    </Trans>
                  </Typography.Text>{" "}
                  <Typography.Text type="warning">
                    <Trans>Deze code wordt éénmalig uitgegeven.</Trans>
                  </Typography.Text>
                </Typography.Paragraph>
              }
              dataUrl={qrCodeDataUrl || ""}
              dataText={qrCodeDataText || ""}
            />
          </Col>
        </Row>
      </Modal>
    </ExternalApiClientsContent>
  );
});

export default ExternalApiClients;
