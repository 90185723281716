import classNames from "classnames";
import React, { ReactNode, memo } from "react";

const BudgetGrpsView = memo(
  ({
    children,
    singleField,
  }: {
    children: ReactNode | ReactNode[];
    singleField?: boolean;
  }) => (
    <div
      className={classNames("tip-budget-grps-view", {
        "tip-single-field": singleField,
      })}
    >
      {children}
    </div>
  )
);

export default BudgetGrpsView;
