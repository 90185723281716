import { EditOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Space, Table, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import moment from "moment";
import React, { FunctionComponent, ReactNode } from "react";
import { useSelector } from "react-redux";

import {
  Operator,
  OperatorOrn,
  OperatorRtl,
  OperatorTalpa,
  getOperatorFromId,
} from "../../models/Operator";
import Order from "../../models/Order";
import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import { PreferredPosition, getUniqueId } from "../../models/Spot";
import SubOrder from "../../models/SubOrder";
import { AnalyzedOrder } from "../../store/campaigns/analyzed/models";
import { StoreModel } from "../../store/models";
import { getSubOrderName } from "../../utils";
import { useCulture } from "../../utils/hooks";
import OrderView from "../../views/campaigns/OrderView";
import Ellipsis from "../../views/Ellipsis";
import Euro from "../../views/Euro";
import Grps from "../../views/Grps";
import NotAvailable from "../../views/NotAvailable";
import { OperatorAvatar } from "../../views/OperatorIcon";
import PeriodView from "../../views/PeriodView";
import { formatNumber } from "../../views/utils";
import {
  bookSpotEditCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import EditButton from "../actions/EditButton";
import Instructions from "../instructions/Instructions";
import { SelectedPreferredPositionDictionary } from "./requests/subOrders/models";

export type BookSpotEventHandler = (
  availableBreaksDateFrom: Date,
  availableBreaksDateTo: Date,
  orderId: string,
  subOrderId: string,
  advertiserId: string,
  packageCode: string,
  packageDescription: string,
  spotLengths: number[],
  preferredPositions: SelectedPreferredPositionDictionary,
  bookingIndicator: number | undefined,
  bookedGrpsNo: number | undefined
) => void;

/**
 * The OrderTable component's properties.
 */
interface OrderTableProps {
  /**
   * A unique identifier of the advertiser associated with the order.
   */
  advertiserId?: string;

  /**
   * An event that is triggered when spots are booked.
   */
  onBookSpot?: BookSpotEventHandler;

  /**
   * An event that is triggered when a suborder has changed.
   * @param selectedRowKeys An array of keys of selected rows.
   * @param selectedRows An array of selected suborders.
   */
  onSubOrderChange?: (
    selectedRowKeys: string[] | number[],
    selectedRows: SubOrder[]
  ) => void;

  /**
   * The order of which to display the information.
   */
  order?: Order;

  /**
   * An array of keys of selected suborders.
   */
  selectedSubOrderKeys?: string[];

  /**
   * Indicates whether the book spot functionality is enabled.
   */
  bookSpotEnabled: boolean;

  /**
   * An optional analyzed order
   */
  analyzedOrder?: AnalyzedOrder;

  /**
   * If true, a column with 'Aantal spots' will be showed
   */
  showNumberOfSpots?: boolean;

  /* Show instructions */
  showInstructions?: boolean;

  /* Show impressions */
  impressionsEnabled?: boolean;
}

const useColumns = (): [
  (
    advertiserId: string,
    operator: Operator,
    order: Order,
    onBookSpot: BookSpotEventHandler,
    bookSpotEnabled: boolean,
    skoTargetGroup: SkoTargetGroup | undefined,
    analyzedOrder: AnalyzedOrder | undefined,
    showNumberOfSpots: boolean,
    impressionsEnabled: boolean
  ) => ColumnProps<SubOrder>[]
] => {
  const culture = useCulture();
  const { i18n } = useLingui();

  return [
    (
      advertiserId,
      operator,
      order,
      onBookSpot,
      bookSpotEnabled,
      skoTargetGroup,
      analyzedOrder,
      showNumberOfSpots,
      impressionsEnabled
    ): ColumnProps<SubOrder>[] => {
      const columns: ColumnProps<SubOrder>[] = [
        {
          key: "period",
          align: "center",
          title: (): ReactNode => <OperatorAvatar operator={operator} />,
          width: 170,
          render: (_, { startDate, endDate }): ReactNode => (
            <PeriodView from={startDate} to={endDate} type="short" />
          ),
        },
        {
          key: "packageCode",
          title: i18n._(t`Pakket`),
          width: 235,
          render: (_, subOrder): ReactNode => {
            const {
              id,
              startDate,
              endDate,
              package: pkg,
              spots,
              spotLengths,
              requestedGrpsNo,
            } = subOrder;
            const handleBookSpot = (itemId?: string): void => {
              if (itemId) {
                const preferredPositions: SelectedPreferredPositionDictionary =
                  {};
                if (spots) {
                  for (let i = 0; i < spots.length; i += 1) {
                    const spot = spots[i];
                    const { preferredPosition } = spot;
                    if (preferredPosition) {
                      const uid = getUniqueId(spot);
                      preferredPositions[uid] = preferredPosition
                        .split(",")
                        .filter((s) => s && s !== " ")
                        .map((s) => s.trim() as PreferredPosition);
                    }
                  }
                }

                triggerAnalyticsEvent(
                  bookSpotEditCategory,
                  `${order.id}-${id}`
                );

                const bookingIndicator = pkg.details?.bookingIndicator;

                onBookSpot(
                  startDate,
                  endDate,
                  order.id,
                  itemId,
                  advertiserId,
                  pkg.code,
                  pkg.description,
                  spotLengths,
                  preferredPositions,
                  bookingIndicator,
                  requestedGrpsNo
                );
              }
            };

            const pkgString = getSubOrderName(subOrder, i18n._(t`Onbekend`));
            return (
              <Space>
                <Ellipsis text={pkgString} style={{ maxWidth: 190 }} />
                {moment(endDate).isAfter(moment()) &&
                  !!pkg.details?.breakSelectionInd &&
                  bookSpotEnabled && (
                    <Tooltip
                      title={
                        <>
                          {i18n._(t`Blokselectie aanpassen.`)}
                          {operator === OperatorRtl && (
                            <>
                              <br />
                              {i18n._(
                                t`Bij Ad Alliance is bij- en afboeken alleen toegestaan tussen 08:30 uur en 18:30 uur.`
                              )}
                            </>
                          )}
                          {operator === OperatorTalpa && (
                            <>
                              <br />
                              {i18n._(
                                t`Bij Talpa is bij- en afboeken alleen toegestaan tussen 06:00 uur en 00:00 uur.`
                              )}
                            </>
                          )}
                        </>
                      }
                    >
                      <div>
                        <EditButton
                          icon={<EditOutlined />}
                          itemId={id}
                          onClick={handleBookSpot}
                          size="small"
                          type="link"
                          iconOnly
                        />
                      </div>
                    </Tooltip>
                  )}
              </Space>
            );
          },
        },
        {
          key: "targetGroup",
          render: (_, { targetGroup }): ReactNode => (
            <Ellipsis text={targetGroup?.description ?? i18n._(t`Onbekend`)} />
          ),
          title: i18n._(t`Doelgroep`),
          width: 150,
        },
        {
          key: "grpPrice",
          title: (
            <Tooltip title={i18n._(t`Netto GRP-prijs o.b.v. 30 seconden`)}>
              <span>
                <Trans>GRP prijs</Trans> (€)
              </span>
            </Tooltip>
          ),
          width: 125,
          render: (_, { finances }): ReactNode => {
            if (!finances) {
              return null;
            }

            return operator === OperatorOrn && finances.grpPrice === 0 ? (
              <NotAvailable />
            ) : (
              <Euro amount={finances.grpPrice} />
            );
          },
        },
        {
          title: (
            <Tooltip title={i18n._(t`Initieel aangevraagd budget`)}>
              <span>{i18n._(t`Aangevraagd`)} (€)</span>
            </Tooltip>
          ),
          key: "requestedBudget",
          width: 150,
          align: "center",
          render: (_, { finances }): ReactNode => {
            if (!finances) {
              return null;
            }

            return operator === OperatorOrn &&
              finances.requestedBudget === 0 ? (
              <NotAvailable />
            ) : (
              <Euro amount={finances.requestedBudget} />
            );
          },
        },
        {
          title: (
            <Tooltip title={i18n._(t`Gewijzigd budget en aantal GRP's`)}>
              <span>{i18n._(t`Gewijzigd`)} (€)</span>
            </Tooltip>
          ),
          key: "budget",
          colSpan: 2,
          width: 120,
          align: "center",
          render: (_, { finances }): ReactNode => {
            if (!finances) {
              return null;
            }

            return operator === OperatorOrn && finances.budget === 0 ? (
              <NotAvailable />
            ) : (
              <Euro amount={finances.budget} />
            );
          },
        },
        {
          key: "requestedGrpsNo",
          colSpan: 0,
          width: 110,
          render: (_, { requestedGrpsNo }): ReactNode =>
            operator === OperatorOrn && requestedGrpsNo === 0 ? (
              <NotAvailable />
            ) : (
              <Grps amount={requestedGrpsNo} showSuffix />
            ),
        },
        {
          key: "bookedBudget",
          title: (
            <Tooltip
              title={i18n._(
                t`Door de exploitant ingedeelde budget en aantal GRP's. Dit is een prognose.`
              )}
            >
              <span>{i18n._(t`Geboekt`)} (€)</span>
            </Tooltip>
          ),
          colSpan: 2,
          width: 120,
          align: "center",
          render: (_, { finances }): ReactNode => {
            if (!finances) {
              return null;
            }

            return operator === OperatorOrn && finances.bookedBudget === 0 ? (
              <NotAvailable />
            ) : (
              <Euro amount={finances.bookedBudget} />
            );
          },
        },
        {
          key: "bookedGrpsNo",
          colSpan: 0,
          width: 110,
          render: (_, { bookedGrpsNo }): ReactNode =>
            operator === OperatorOrn && bookedGrpsNo === 0 ? (
              <NotAvailable />
            ) : (
              <Grps amount={bookedGrpsNo} showSuffix />
            ),
        },
        {
          key: "numberOfSpots",
          title: i18n._(t`Aantal spots`),
          align: "right",
          width: 100,
          dataIndex: "numberOfSpots",
        },
        {
          key: "achievedGrpsNo",
          title: (
            <Tooltip
              placement="top"
              title={i18n._(
                t`Gerealiseerde GRP's worden op de inkoopdoelgroep weergegeven`
              )}
            >
              {i18n._(t`Gerealiseerd`)}
            </Tooltip>
          ),
          align: "right",
          width: 125,
          render: (_, { id, achievedGrpNo }): ReactNode =>
            // eslint-disable-next-line no-nested-ternary
            achievedGrpNo ? (
              <Grps amount={achievedGrpNo} showSuffix />
            ) : analyzedOrder ? (
              <Grps
                amount={
                  analyzedOrder.subOrders.find((sub) => sub.id === id)
                    ?.achievedGrpsNo
                }
                showSuffix
              />
            ) : (
              <NotAvailable />
            ),
        },
        {
          key: "skoGrpsNo",
          align: "right",
          title: (
            <Tooltip
              title={`${i18n._(
                t`Gerealiseerde GRP's in de gekozen NMO analyse doelgroep`
              )} ${
                analyzedOrder?.skoTargetGroup?.name ?? skoTargetGroup?.name
              }`}
            >
              {t`GRP's`}
            </Tooltip>
          ),
          width: 110,
          render: (_, { id, skoGrp }): ReactNode =>
            // eslint-disable-next-line no-nested-ternary
            skoTargetGroup && analyzedOrder !== undefined ? (
              <Grps
                amount={
                  analyzedOrder.subOrders.find((sub) => sub.id === id)?.skoGrps
                }
                showSuffix
              />
            ) : skoTargetGroup && skoGrp !== undefined ? (
              <Grps amount={skoGrp} showSuffix />
            ) : (
              <NotAvailable />
            ),
        },
        {
          key: "skoImpressions",
          align: "right",
          title: (
            <Tooltip
              title={`${i18n._(
                t`Aantal impressies in de gekozen NMO analyse doelgroep`
              )} ${
                analyzedOrder?.skoTargetGroup?.name ?? skoTargetGroup?.name
              }`}
            >
              {t`Impressies Doelgroep`}
            </Tooltip>
          ),
          width: 110,
          render: (_, { id }): ReactNode => {
            const impressions =
              skoTargetGroup && analyzedOrder !== undefined
                ? analyzedOrder.subOrders.find((sub) => sub.id === id)
                    ?.skoImpressions
                : undefined;
            return impressions !== undefined ? (
              formatNumber(impressions, 0, culture)
            ) : (
              <NotAvailable />
            );
          },
        },
        {
          key: "allImpressions",
          align: "right",
          title: (
            <Tooltip title={i18n._(t`Totaal aantal impressies (6+)`)}>
              {t`Impressies Totaal`}
            </Tooltip>
          ),
          width: 110,
          render: (_, { id }): ReactNode => {
            const impressions =
              skoTargetGroup && analyzedOrder !== undefined
                ? analyzedOrder.subOrders.find((sub) => sub.id === id)
                    ?.allImpressions
                : undefined;
            return impressions !== undefined ? (
              formatNumber(impressions, 0, culture)
            ) : (
              <NotAvailable />
            );
          },
        },
      ];
      const showAnalysis =
        skoTargetGroup &&
        (analyzedOrder ||
          order.subOrders?.some((so) => (so.spots?.length ?? 0) > 0));
      return columns.filter(({ key }) => {
        switch (key) {
          case "numberOfSpots":
            return showNumberOfSpots;
          case "achievedGrpsNo":
          case "skoGrpsNo":
            return showAnalysis;
          case "skoImpressions":
          case "allImpressions":
            return showAnalysis && impressionsEnabled;
          default:
            return true;
        }
      });
    },
  ];
};

const emptySubOrders: SubOrder[] = [];

/**
 * Renders a component that displays information about an order in a table.
 */
const OrderTable: FunctionComponent<OrderTableProps> = ({
  advertiserId,
  bookSpotEnabled,
  onBookSpot,
  onSubOrderChange,
  order,
  selectedSubOrderKeys,
  analyzedOrder,
  showNumberOfSpots,
  showInstructions,
  impressionsEnabled,
}) => {
  const skoTargetGroup = useSelector(
    ({
      campaigns: {
        selectedFilter: { skoTargetGroupId },
      },
      skoTargetGroup: { value },
    }: StoreModel) => value?.find(({ id }) => id === skoTargetGroupId)
  );

  const instructionsEnabled = useSelector(
    ({
      application: {
        options: { enableInstructions },
      },
    }: StoreModel) => !!enableInstructions
  );

  const [columnsFactory] = useColumns();

  if (!order) {
    return null;
  }

  const { id, subOrders = emptySubOrders } = order;
  const operator = getOperatorFromId(id);

  // rowSelection object indicates the need for row selection
  const rowSelection = onSubOrderChange
    ? ({
        selectedRowKeys: selectedSubOrderKeys,
        onChange: onSubOrderChange,
        getCheckboxProps: (record: SubOrder) => ({
          name: record.id,
        }),
        columnWidth: 60,
        fixed: true,
      } as TableRowSelection<SubOrder>)
    : undefined;

  const columns = columnsFactory(
    advertiserId || "",
    operator,
    order,
    onBookSpot ||
      ((): void => {
        /* */
      }),
    bookSpotEnabled,
    skoTargetGroup,
    analyzedOrder,
    Boolean(showNumberOfSpots),
    Boolean(impressionsEnabled)
  );

  return (
    <>
      <OrderView>
        <Table
          bordered
          columns={columns}
          rowSelection={rowSelection}
          dataSource={subOrders.sort((a, b) => a.id.localeCompare(b.id))}
          tableLayout="fixed"
          scroll={{ x: true }}
          pagination={false}
          rowKey={(subOrder): string => `${id}:${subOrder.id}`}
        />
      </OrderView>
      {instructionsEnabled && showInstructions && (
        <Instructions order={order} />
      )}
    </>
  );
};

export default OrderTable;
