import { CampaignValues } from "../../../models/Campaigns/Requests/models";
import { Period } from "../../../models/periods";
import propertyOf from "../../../utils/properties";
import { OrderRequestWizardState, RequestStatusEditableMap } from "./models";

export const createStateNumeric: {
  [state in OrderRequestWizardState]: number;
} = {
  SelectCampaign: 0,
  ComposeOrder: 1,
  ComposeSubOrder: 2,
};

export const sterSpecificPackageCode = "SPECIFIEK";
export const sterSpecificPackageCode2 = "SPECIFIC";

export const formName = "CreateNewCampaign";
export const fieldIdName = propertyOf<CampaignValues>("id");
export const fieldStartDateName = propertyOf<CampaignValues>("startDate");
export const fieldEndDateName = propertyOf<CampaignValues>("endDate");
export const fieldAdvertiserId = propertyOf<CampaignValues>("advertiserId");
export const fieldNameName = propertyOf<CampaignValues>("name");
export const fieldSubOrdersName = propertyOf<CampaignValues>("subOrders");

export const requestStatusEditable: RequestStatusEditableMap = {
  Concept: true,
  Error: true,
  Submitted: false,
  Deleted: false,
};

export const emptyPeriods: Period[] = [];
