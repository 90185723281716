import { InfoCircleOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Button, List, Popover, Tooltip } from "antd";
import moment from "moment";
import React, { ReactNode, memo, useCallback, useState } from "react";

import Spot from "../../models/Spot";
import Ellipsis from "../Ellipsis";

interface SpotInfoPopupProps {
  spot: Spot;
}

const SpotInfoPopup = memo<SpotInfoPopupProps>(({ spot }) => {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = useCallback(setVisible, [setVisible]);

  if (!spot.commercials || spot.commercials.length === 0) {
    return null;
  }

  return (
    <Popover
      content={
        <List
          dataSource={spot.commercials}
          itemLayout="vertical"
          renderItem={({
            code,
            description,
            spotLength,
            skoData,
          }): ReactNode => {
            const actualStartDate =
              skoData?.broadcastDateTime ?? spot.actualStartDate;
            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    code ? (
                      <Ellipsis text={`${code}: ${description}`} />
                    ) : (
                      <Trans>Commercial onbekend</Trans>
                    )
                  }
                  description={
                    <ul className="tip-spot-commercialdata">
                      <li>
                        <Trans>Spotlengte</Trans>: {spotLength}&quot;
                      </li>
                      {skoData?.positionInBlock && (
                        <li>
                          <Trans>Positie in blok</Trans>:{" "}
                          {skoData.positionInBlock} van{" "}
                          {skoData.totalPositionsInBlock}
                        </li>
                      )}
                      {actualStartDate && (
                        <li>
                          <Trans>Werkelijk uitgezonden</Trans>:{" "}
                          <Tooltip
                            title={moment(actualStartDate).format("LLL")}
                          >
                            <span>
                              {moment(actualStartDate).format("HH:mm")}
                            </span>
                          </Tooltip>
                        </li>
                      )}
                    </ul>
                  }
                />
              </List.Item>
            );
          }}
        />
      }
      title={<Trans>Informatie op spot-niveau</Trans>}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayClassName="tip-spot-infopopup"
      className="tip-spot-infopopup-button"
      placement="left"
    >
      <Button type="link" icon={<InfoCircleOutlined />} shape="circle" />
    </Popover>
  );
});

export default SpotInfoPopup;
