import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Card, DatePicker, Form, Select, Spin, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
} from "react";
import { Link } from "react-router-dom";

import Advertiser from "../../models/Advertiser";
import { getAdvertiserName } from "../../utils";
import navigationPaths from "../../utils/navigation";
import propertyOf from "../../utils/properties";
import FilterActions from "../../views/campaigns/FilterActions";
import { labelCol, wrapperCol } from "../../views/layout/Form";
import ShowButton from "../actions/ShowButton";

interface CommercialFiltersProps {
  filters: CommercialFilterValues;
  advertisers?: Advertiser[];
  onFinish: (values: CommercialFilterValues) => void;
  onPeriodChange: (newPeriod?: RangeValue<Moment>) => void;
  loading: boolean;
}

export interface CommercialFilterValues {
  period: RangeValue<Moment>;
  advertiserId?: string;
}
const filterPeriodFieldName = propertyOf<CommercialFilterValues>("period");
const filterAdvertiserIdFieldName =
  propertyOf<CommercialFilterValues>("advertiserId");

const CommercialFilters: FunctionComponent<CommercialFiltersProps> = ({
  filters,
  advertisers,
  onFinish,
  onPeriodChange,
  loading,
}) => {
  const { i18n } = useLingui();
  const [form] = useForm();

  const handleFinish = useCallback(
    (values: CommercialFilterValues) => onFinish(values),
    [onFinish]
  );
  const handlePeriodChange = useCallback(
    (newPeriod?: RangeValue<Moment>) => {
      form.resetFields([filterAdvertiserIdFieldName]);
      onPeriodChange(newPeriod);
    },
    [onPeriodChange, form]
  );

  return (
    <Spin spinning={loading}>
      <Card type="inner" title={i18n._(t`Commercials`)}>
        <Form
          name="CommercialDeliveryFilterForm"
          form={form}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          onFinish={handleFinish}
          initialValues={{
            [filterAdvertiserIdFieldName]: filters.advertiserId,
            [filterPeriodFieldName]: filters.period,
          }}
        >
          <Form.Item
            label={i18n._(t`Periode`)}
            name={filterPeriodFieldName}
            required
          >
            <DatePicker.RangePicker
              format="D MMM YYYY"
              onChange={handlePeriodChange}
              disabledDate={(current) =>
                current && current.isAfter(moment().endOf("month"))
              }
              ranges={{
                [i18n._(t`Vorige maand`)]: [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
                [i18n._(t`Huidige maand`)]: [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
            />
          </Form.Item>
          <Form.Item
            label={i18n._(t`Adverteerder(s)`)}
            name={filterAdvertiserIdFieldName}
          >
            <Select
              showSearch
              optionFilterProp="title"
              disabled={!advertisers || advertisers.length === 0}
            >
              <Select.Option key="empty" value="">
                <Typography.Text>
                  <Trans>Maak een selectie</Trans>
                </Typography.Text>
              </Select.Option>
              {advertisers?.map(({ id }) => (
                <Select.Option
                  key={id}
                  value={id}
                  title={getAdvertiserName(advertisers, id)}
                >
                  {getAdvertiserName(advertisers, id)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate>
            {(): ReactElement => {
              const period: RangeValue<Moment> = form.getFieldValue(
                filterPeriodFieldName
              );
              const enabled =
                period &&
                period[0] &&
                period[1] &&
                period[0].isValid() &&
                period[1].isValid() &&
                period[0].isBefore(period[1]);

              return (
                <FilterActions>
                  <ShowButton disabled={!enabled} showIcon />

                  <Link to={navigationPaths.CommercialDelivery}>
                    <Button type="default">
                      <Trans>Aanleveren</Trans>
                    </Button>
                  </Link>
                </FilterActions>
              );
            }}
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default memo(CommercialFilters);
