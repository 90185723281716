import { Typography } from "antd";
import React, { memo } from "react";

const DefaultFooter = memo(() => (
  <Typography.Text disabled>
    &copy; Screenforce {new Date().getFullYear()}
  </Typography.Text>
));

export default DefaultFooter;
