import { createForm } from "async-lifecycle-saga";

import { analyzedOrdersGetter } from "./api";

// eslint-disable-next-line import/prefer-default-export
export const analyzedOrdersCell = createForm({
  path: ["campaigns", "analyzed"],
  api: analyzedOrdersGetter,
  onRefresh: (action, state, lastStarted) => {
    const {
      payload: { skoTargetGroupId },
    } = action;
    const lastStartedRequest = lastStarted?.payload;
    return state.value &&
      lastStartedRequest &&
      skoTargetGroupId === lastStartedRequest.skoTargetGroupId
      ? "skip"
      : "clear";
  },
});
