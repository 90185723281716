import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  HttpStatusCode,
  httpInternalServerError,
  httpUnauthorized,
} from "../../store/fetch";
import { AuthenticationRequest, StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { useRedirectIfLoggedIn } from "../../utils/hooks";
import { labelCol, wrapperColShallow } from "../../views/layout/Form";
import Login, { LoginState } from "./Login";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [loginState, setLoginState] = useState<LoginState>("Default");

  /**
   * Determines whether the login request is being processed.
   */
  const { loading = false } = useSelector(
    ({
      tokens: {
        authenticate: { status },
      },
    }: StoreModel) => status
  );

  const handleSubmitFailCallback = useCallback(
    /**
     * Handles a failure in a login request.
     * @param exception Any exception that may have been thrown during the executing of the login request.
     * @param statusCode The HTTP Status Code from the response.
     */
    (exception: Error, statusCode: HttpStatusCode) => {
      switch (statusCode) {
        case httpUnauthorized: {
          setLoginState("Invalid");
          return;
        }
        case httpInternalServerError:
        default: {
          setLoginState("Error");
        }
      }
    },
    []
  );

  const handleFinish = useCallback(
    /**
     * Handles the completion of the login form.
     * @param values The values of the login form.
     */
    (values: AuthenticationRequest): void => {
      if (values) {
        dispatch({
          type: sagaTypes.tokens.authenticate.request,
          payload: values,
          onFail: handleSubmitFailCallback,
        });
      }
    },
    [dispatch, handleSubmitFailCallback]
  );

  useRedirectIfLoggedIn();

  return (
    <Form
      form={form}
      labelCol={labelCol}
      name="Login"
      onFinish={handleFinish}
      wrapperCol={wrapperColShallow}
    >
      <Login form={form} loading={loading} state={loginState} />
    </Form>
  );
};

export default memo(LoginContainer);
