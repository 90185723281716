import { Trans } from "@lingui/macro";
import React, { ReactElement } from "react";

const UserDeleteConfirmMessageView = (): ReactElement => (
  <>
    <p>
      <Trans>
        Aan deze gebruiker zijn één of meer API koppelingen gemaakt.
      </Trans>
    </p>
    <p>
      <Trans>
        Weet je <b>écht</b> zeker dat je deze gebruiker verwijderen wilt?
      </Trans>
    </p>
  </>
);

export default UserDeleteConfirmMessageView;
