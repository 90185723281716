import TagManager from "react-gtm-module";

export const trackingCode =
  process.env.NODE_ENV === "production" ? "GTM-M9JP7WV" : "GTM-0000000";

export const isGAEnabled = process.env.NODE_ENV === "production";

export type AnalyticsCategory =
  | "ShowOrder"
  | "ExportOrder"
  | "PrintOrder"
  | "ExportAdvertiser"
  | "PrintAdvertiser"
  | "ExportAll"
  | "PrintAll"
  | "NormalisationAdd"
  | "NormalisationEdit"
  | "NormalisationDelete"
  | "NormalisationSaved"
  | "ProductCreateRequest"
  | "OrderRequestCreate"
  | "OrderRequestDelete"
  | "OrderRequestSave"
  | "OrderRequestSubmit"
  | "OrderRequestDownload"
  | "CommercialUpload"
  | "CommercialDownload"
  | "CommercialDelete"
  | "BookSpotEdit"
  | "BookSpotRequest"
  | "AnalyzeAll";

export const showOrderCategory: AnalyticsCategory = "ShowOrder";
export const exportOrderCategory: AnalyticsCategory = "ExportOrder";
export const printOrderCategory: AnalyticsCategory = "PrintOrder";
export const exportAdvertiserCategory: AnalyticsCategory = "ExportAdvertiser";
export const printAdvertiserCategory: AnalyticsCategory = "PrintAdvertiser";
export const analyzeAllCategory: AnalyticsCategory = "AnalyzeAll";
export const exportAllCategory: AnalyticsCategory = "ExportAll";
export const printAllCategory: AnalyticsCategory = "PrintAll";
export const normalisationAddCategory: AnalyticsCategory = "NormalisationAdd";
export const normalisationEditCategory: AnalyticsCategory = "NormalisationEdit";
export const normalisationSavedCategory: AnalyticsCategory =
  "NormalisationSaved";
export const normalisationDeleteCategory: AnalyticsCategory =
  "NormalisationDelete";
export const productCreateRequestCategory: AnalyticsCategory =
  "ProductCreateRequest";
export const orderRequestCreateCategory: AnalyticsCategory =
  "OrderRequestCreate";
export const orderRequestDeleteCategory: AnalyticsCategory =
  "OrderRequestDelete";
export const orderRequestSubmitCategory: AnalyticsCategory =
  "OrderRequestSubmit";
export const orderRequestDownloadCategory: AnalyticsCategory =
  "OrderRequestDownload";
export const commercialUploadCategory: AnalyticsCategory = "CommercialUpload";
export const commercialDownloadCategory: AnalyticsCategory =
  "CommercialDownload";
export const commercialDeleteCategory: AnalyticsCategory = "CommercialDelete";
export const bookSpotEditCategory: AnalyticsCategory = "BookSpotEdit";
export const bookSpotRequestCategory: AnalyticsCategory = "BookSpotRequest";

const getAnalyticsAction = (
  category: AnalyticsCategory,
  id?: string
): string => {
  if (!id) {
    return category;
  }

  return `${category}:${id}`;
};

export const triggerAnalyticsEvent = (
  category: AnalyticsCategory,
  id?: string
): void => {
  if (isGAEnabled) {
    TagManager.dataLayer({
      dataLayer: {
        event: "event",
        eventProps: {
          category,
          action: getAnalyticsAction(category, id),
        },
      },
    });
  }
};
