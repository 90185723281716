import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Card, Form, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormFinishInfo } from "rc-field-form/lib/FormContext";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreModel } from "../../../store/models";
import {
  notificationsEditCell,
  notificationsReadCell,
} from "../../../store/notifications/cells";
import { labelCol, wrapperColShallow } from "../../../views/layout/Form";
import MainContent from "../../../views/layout/MainContent";
import { idField, statusField, textField } from "./constants";
import NotificationForm from "./NotificationForm";

const NotificationFormContainer = memo(() => {
  const { i18n } = useLingui();
  const [form] = useForm();
  const { getFieldsError, isFieldsTouched } = form;
  const dispatch = useDispatch();
  const [enableSave, setEnableSave] = useState(false);

  const { notification, loading } = useSelector(
    ({ notifications: { edit } }: StoreModel) => ({
      notification: edit.value,
      loading: edit.status.loading || edit.status.loading,
    })
  );

  const handleChange = useCallback(
    /**
     * Handles a change in the values of the notification form's fields.
     */
    () => {
      setEnableSave(
        !loading &&
          isFieldsTouched([textField, statusField]) &&
          !getFieldsError().some(({ errors }) => errors.length > 0)
      );
    },
    [getFieldsError, isFieldsTouched, loading]
  );

  const handleFinish = useCallback(
    /**
     * Handles finishing the notification form by saving the new values.
     * @param name The name of the finished form.
     * @param values The notification form's values.
     */
    (name: string, { values }: FormFinishInfo) => {
      dispatch(
        notificationsEditCell.update(
          {
            notification: {
              id: values[idField],
              status: values[statusField],
              text: values[textField],
            },
          },
          {
            onFail: () => {
              message.error(
                i18n._(
                  t`Er is iets misgegaan met het opslaan van de mededeling.`
                )
              );
            },
            onSuccess: () => {
              dispatch(notificationsReadCell.require());
              message.success(i18n._(t`Je mededeling is opgeslagen.`));
            },
          }
        )
      );
    },
    [dispatch, i18n]
  );

  // set initial state for the form is notification is filled
  useEffect(() => {
    if (notification?.notification?.id) {
      form.setFieldsValue({
        [idField]: notification.notification.id,
        [textField]: notification.notification.text,
        [statusField]: notification.notification.status,
      });
    }
  }, [form, notification]);

  useEffect(() => {
    dispatch(notificationsEditCell.require());
  }, [dispatch]);

  return (
    <MainContent>
      <Spin spinning={loading}>
        <Card type="inner" title={i18n._(t`Mededelingen`)}>
          <Form.Provider
            onFormChange={handleChange}
            onFormFinish={handleFinish}
          >
            <Form
              form={form}
              labelCol={labelCol}
              name="Notification"
              wrapperCol={wrapperColShallow}
            >
              <NotificationForm loading={loading} saveEnabled={enableSave} />
            </Form>
          </Form.Provider>
        </Card>
      </Spin>
      <div />
    </MainContent>
  );
});

export default NotificationFormContainer;
