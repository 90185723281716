import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { ReactElement, useCallback } from "react";

interface SaveButtonProps {
  onSave: () => void;
}

const SaveButton = ({ onSave }: SaveButtonProps): ReactElement => {
  const handleClick = useCallback(() => {
    onSave();
  }, [onSave]);

  return (
    <Button key="saveButton" type="link" onClick={handleClick}>
      <Trans>OK</Trans>
    </Button>
  );
};

export default SaveButton;
