import { Spin } from "antd";
import classNames from "classnames";
import React, { ReactNode, memo } from "react";

const PageContainer = memo(
  ({ children, spinning }: { children?: ReactNode; spinning?: boolean }) => (
    <section className={classNames("page-container", { spinning })}>
      <Spin className="page-container-spinner" spinning={spinning}>
        {children}
      </Spin>
    </section>
  )
);

export default PageContainer;
