import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Card, Spin } from "antd";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  FocusEvent,
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";

import {
  CampaignSubOrderValues,
  CampaignValues,
  OrderRequest,
} from "../../../models/Campaigns/Requests/models";
import { Operator, OperatorSter } from "../../../models/Operator";
import { StoreModel } from "../../../store/models";
import { advertiserIdField } from "../../products/constants";
import {
  fieldAdvertiserId,
  fieldEndDateName,
  fieldIdName,
  fieldNameName,
  fieldStartDateName,
  fieldSubOrdersName,
  requestStatusEditable,
  sterSpecificPackageCode,
  sterSpecificPackageCode2,
} from "./constants";
import CampaignForm from "./fields/CampaignForm";
import {
  OrderRequestCallbackProps,
  OrderRequestWizardProps,
  OrderRequestWizardState,
  subOrdersToFormValues,
} from "./models";
import SubOrderStepper from "./subOrders/SubOrderStepper";

const OrderRequestWizard: FunctionComponent<
  OrderRequestWizardProps & OrderRequestCallbackProps
> = ({
  advertisers,
  advertisersLoading,
  campaignForm,
  hasConcepts,
  onAdvertiserIdChanged,
  onCreate,
  onDelete,
  onPeriodChanged,
  onSubmit,
  onUpdate,
  packages,
  packagesLoading,
  periods,
  periodsLoading,
  products,
  productsLoading,
  spotLengthIndices,
  subOrders,
}) => {
  const { getFieldValue, setFieldsValue, validateFields } = campaignForm;
  const { i18n } = useLingui();

  const [wizardState, setWizardState] =
    useState<OrderRequestWizardState>("SelectCampaign");
  const [editSubOrder, setEditSubOrder] = useState<
    CampaignSubOrderValues | undefined
  >(undefined);
  const [selectedPeriod, setSelectedPeriod] =
    useState<RangeValue<Moment>>(null);

  const fieldsCampaignEnabled = useMemo(
    () => wizardState === "SelectCampaign",
    [wizardState]
  );

  const handleStateChange = useCallback(
    (nextState: OrderRequestWizardState, fields?: string[]) => {
      if (fields) {
        validateFields(fields).then(() => {
          if (nextState === "ComposeOrder") {
            onCreate();
          }
          setWizardState(nextState);
        });
      } else {
        setWizardState(nextState);
      }
    },
    [onCreate, validateFields]
  );

  const handlePeriodChanged = useCallback(
    /**
     * Handles a change in the selected order request period.
     * @param period The newly selected period.
     */
    (period: RangeValue<Moment>) => {
      setSelectedPeriod(period);
      onPeriodChanged(period);
    },
    [onPeriodChanged]
  );

  const handleCampaignNameBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const id = getFieldValue(fieldIdName);
      if (id) {
        e.preventDefault();
        onUpdate();
      }
    },
    [getFieldValue, onUpdate]
  );

  const handleNext = useCallback(
    /**
     * Handles the next step in the Order Request Wizard.
     */
    () => {
      handleStateChange("ComposeOrder", [
        fieldStartDateName,
        fieldEndDateName,
        fieldAdvertiserId,
        fieldNameName,
      ]);
    },
    [handleStateChange]
  );

  const handleNewSubOrder = useCallback(() => {
    setWizardState("ComposeSubOrder");
    setEditSubOrder(undefined);
  }, []);

  const handleDeleteSubOrder = useCallback(
    (index: number) => {
      const currentSubOrders = getFieldValue(fieldSubOrdersName);
      setWizardState("ComposeOrder");
      const subOrdersNew = [...currentSubOrders];
      subOrdersNew.splice(index, 1);
      setFieldsValue({ [fieldSubOrdersName]: subOrdersNew });
      onUpdate();
      setEditSubOrder(undefined);
    },
    [getFieldValue, onUpdate, setFieldsValue]
  );

  const handleDuplicateSubOrder = useCallback(
    (index: number) => {
      const currentSubOrders = getFieldValue(fieldSubOrdersName);
      const subOrdersNew = [
        ...currentSubOrders,
        { ...currentSubOrders[index], submitted: false },
      ];
      setFieldsValue({ [fieldSubOrdersName]: subOrdersNew });
      onUpdate();
      setEditSubOrder(undefined);
    },
    [getFieldValue, onUpdate, setFieldsValue]
  );

  const handleEditSubOrder = useCallback(
    (index: number) => {
      const currentSubOrders = getFieldValue(fieldSubOrdersName);
      setWizardState("ComposeSubOrder");
      setEditSubOrder(currentSubOrders[index]);
    },
    [getFieldValue]
  );

  const handleAddSubOrder = useCallback(
    (values: CampaignSubOrderValues) => {
      const currentSubOrders = getFieldValue(fieldSubOrdersName);
      setFieldsValue({
        [fieldSubOrdersName]: [...currentSubOrders, values],
      });
      setWizardState("ComposeOrder");
      onUpdate();
    },
    [getFieldValue, onUpdate, setFieldsValue]
  );

  const handleUpdateSubOrder = useCallback(
    (values: CampaignSubOrderValues) => {
      if (!editSubOrder) {
        return;
      }
      const currentSubOrders = getFieldValue(
        fieldSubOrdersName
      ) as CampaignSubOrderValues[];
      const subOrderIndex = currentSubOrders.indexOf(editSubOrder);
      currentSubOrders.splice(subOrderIndex, 1, {
        ...values,
        requestStatus: "Concept",
      });
      setFieldsValue({ [fieldSubOrdersName]: currentSubOrders });
      setWizardState("ComposeOrder");
      onUpdate();
    },
    [editSubOrder, getFieldValue, onUpdate, setFieldsValue]
  );

  const handleCancel = useCallback(() => {
    setEditSubOrder(undefined);
    setWizardState("ComposeOrder");
  }, []);

  const handleFinishRequest = useCallback(() => {
    setWizardState("ComposeOrder");
    onSubmit();
  }, [onSubmit]);

  const operators = useMemo(
    () => [...new Set(products.map((p) => p.id.split(":")[0] as Operator))],
    [products]
  );

  const createNewSuborderStepperVisible = useMemo(
    /**
     * Determines whether the suborder stepper should be visible.
     */
    () => {
      const startDate = getFieldValue(fieldStartDateName);
      const endDate = getFieldValue(fieldEndDateName);

      return Boolean(
        wizardState === "ComposeSubOrder" &&
          startDate &&
          endDate &&
          products &&
          packages &&
          operators
      );
    },
    [wizardState, getFieldValue, operators, packages, products]
  );

  const dtoToValues = useCallback(
    ({
      id: orid,
      startDate: orStartDate,
      endDate: orEndDate,
      advertiser,
      campaignName,
      subOrders: orSubOrders,
    }: OrderRequest): CampaignValues => ({
      id: orid || "",
      startDate: moment(orStartDate).toISOString(true),
      endDate: moment(orEndDate).toISOString(true),
      name: campaignName || "",
      advertiserId: advertiser?.id,
      subOrders: subOrdersToFormValues(orSubOrders),
    }),
    []
  );

  const {
    status: { loading: editingOrderRequestLoading },
    value: editingOrderRequest,
  } = useSelector(({ requests: { orderRequest } }: StoreModel) => orderRequest);

  useEffect(
    /**
     * Determine whether the wizard is editing an existing order request,
     * or creating a new one. Depending on that, the appropriate state should be set,
     * and the form fields should have the appropriate values.
     */
    () => {
      if (editingOrderRequestLoading) {
        return;
      }

      if (editingOrderRequest?.orderRequest) {
        const { orderRequest } = editingOrderRequest;
        setWizardState("ComposeOrder");
        const values = dtoToValues(orderRequest);
        setFieldsValue(values);
      } else {
        setWizardState("SelectCampaign");
      }
    },
    [
      dtoToValues,
      editingOrderRequest,
      editingOrderRequestLoading,
      setFieldsValue,
    ]
  );

  const advertiserHasNoProducts = useMemo(
    /**
     * Indicates whether there are any products associated with the currently selected advertiser.
     * If an advertiser has been selected and no products have been found, the order request can not be created.
     */
    (): boolean =>
      getFieldValue(advertiserIdField) &&
      !productsLoading &&
      products &&
      products.length === 0,
    [getFieldValue, products, productsLoading]
  );

  const advertiserHasNoPackages = useMemo(
    /**
     * Indicates whether there are any packages associated with the currently selected advertiser.
     * If an advertiser has been selected and no packages have been found, the order request can not be created.
     */
    (): boolean =>
      getFieldValue(advertiserIdField) &&
      !packagesLoading &&
      packages &&
      packages.length === 0,
    [getFieldValue, packages, packagesLoading]
  );

  const advertiserOnlyHasSpecificPackage = useMemo(
    /**
     * Determines whether the advertiser only has a specific purchase package.
     */
    (): boolean =>
      getFieldValue(advertiserIdField) &&
      !packagesLoading &&
      packages &&
      packages.length === 1 &&
      (packages[0].code === sterSpecificPackageCode ||
        packages[0].code === sterSpecificPackageCode2) &&
      packages[0].operator === OperatorSter,
    [getFieldValue, packages, packagesLoading]
  );

  const orderRequestEnablesEditing = useMemo(
    /**
     * Determines whether the current status of the order request enables being updated.
     */
    () => {
      const periodStartDate = selectedPeriod && selectedPeriod[0];
      const period = periods.find((p) =>
        moment(p.startDate).isSame(moment(periodStartDate))
      );

      return (
        (period?.canReceiveRequests || false) &&
        requestStatusEditable[
          editingOrderRequest?.orderRequest?.status || "Concept"
        ]
      );
    },
    [editingOrderRequest, periods, selectedPeriod]
  );

  const loading = useMemo(
    /**
     * Determines whether the wizard is in a loading state.
     */
    () =>
      advertisersLoading ||
      periodsLoading ||
      editingOrderRequestLoading ||
      productsLoading ||
      packagesLoading,
    [
      advertisersLoading,
      editingOrderRequestLoading,
      periodsLoading,
      productsLoading,
      packagesLoading,
    ]
  );

  useEffect(() => {
    if (editingOrderRequest?.orderRequest) {
      const period = periods.find((p) =>
        moment(p.startDate).isSame(
          moment(editingOrderRequest.orderRequest.startDate)
        )
      );
      if (period) {
        setSelectedPeriod([moment(period.startDate), moment(period.endDate)]);
      }
    }
  }, [editingOrderRequest, periods]);

  useEffect(
    /**
     * Whenever the list of suborders changes externally, update it in the form's store.
     */
    () => {
      setFieldsValue({ [fieldSubOrdersName]: subOrders });
    },
    [setFieldsValue, subOrders]
  );

  return (
    <Spin spinning={loading}>
      <Card type="inner" title={i18n._(t`Nieuwe aanvraag`)}>
        <CampaignForm
          advertiserHasPackages={!advertiserHasNoPackages}
          advertiserHasProducts={!advertiserHasNoProducts}
          advertiserOnlyHasSpecificPackage={advertiserOnlyHasSpecificPackage}
          advertisers={advertisers}
          advertisersLoading={advertisersLoading}
          fieldsCampaignEnabled={fieldsCampaignEnabled}
          form={campaignForm}
          hasConcepts={hasConcepts || true}
          onAddSubOrder={handleNewSubOrder}
          onAdvertiserIdChanged={onAdvertiserIdChanged}
          onCampaignNameBlur={handleCampaignNameBlur}
          onDelete={onDelete}
          onDeleteSubOrder={handleDeleteSubOrder}
          onDuplicateSubOrder={handleDuplicateSubOrder}
          onEditSubOrder={handleEditSubOrder}
          onFinishRequest={handleFinishRequest}
          onNext={handleNext}
          onPeriodChanged={handlePeriodChanged}
          operators={operators}
          orderRequestEnablesEditing={orderRequestEnablesEditing}
          packages={packages}
          periods={periods}
          periodsLoading={periodsLoading}
          products={products}
          spotLengthIndices={spotLengthIndices}
          subOrders={subOrders}
          wizardState={wizardState}
        />
      </Card>
      {createNewSuborderStepperVisible && !loading && (
        <SubOrderStepper
          editSubOrder={editSubOrder}
          orderPeriod={selectedPeriod}
          onAddSubOrder={handleAddSubOrder}
          onCancel={handleCancel}
          onEditSubOrder={handleUpdateSubOrder}
          operators={operators}
          products={products}
          packages={packages}
          spotLengthIndices={spotLengthIndices}
        />
      )}
    </Spin>
  );
};

export default memo(OrderRequestWizard);
