import { createActor } from "async-lifecycle-saga";
import { Moment } from "moment";

import { StoreModel } from "../models";
import {
  calendarGetter,
  createCalendarEvent,
  deleteCalendarEvent,
  updateCalendarEvent,
} from "./api";

export const calendarCell = createActor({
  path: ["calendar", "events"],
  api: (_, { dateFrom, dateTo }: { dateFrom: Moment; dateTo: Moment }) =>
    calendarGetter({ dateFrom, dateTo }),
  context: (store: StoreModel) => {
    const { period } = store.calendar.filter;
    return { dateFrom: period[0], dateTo: period[1] };
  },
});

export const createCalendarCell = createActor({
  path: ["calendar", "create"],
  api: createCalendarEvent,
});

export const updateCalendarCell = createActor({
  path: ["calendar", "update"],
  api: updateCalendarEvent,
});

export const deleteCalendarCell = createActor({
  path: ["calendar", "delete"],
  api: deleteCalendarEvent,
});
