import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreModel } from "../../../store/models";
import { skoImportReportCell } from "../../../store/skoImport/cells";
import MainContent from "../../../views/layout/MainContent";
import SkoImportReportTable from "./SkoImportReportTable";

const SkoImportReportContainer = memo(() => {
  const dispatch = useDispatch();
  const load = useCallback(() => {
    dispatch(skoImportReportCell.require());
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const { value, status } = useSelector(
    ({ skoImportReport }: StoreModel) => skoImportReport
  );

  return (
    <MainContent>
      <Button onClick={load} type="primary">
        <Trans>Overzicht vernieuwen</Trans>
      </Button>
      <SkoImportReportTable value={value} status={status} />
    </MainContent>
  );
});

export default SkoImportReportContainer;
