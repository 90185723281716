import { AsyncResponse } from "async-lifecycle-saga";

import { getPeriodQueryString } from "../../utils/date";
import { getter, jsonGetter } from "../fetch";
import { TokenApiPromise } from "../models";
import {
  AdvertisersByOrganisationRequest,
  AdvertisersRequest,
  AdvertisersResponse,
} from "./models";

export const advertisersGetter = (): Promise<
  AsyncResponse<AdvertisersResponse>
> => getter<AdvertisersResponse>("/api/advertiser");

export const advertisersByDateGetter = ({
  dateFrom,
  dateTo,
}: AdvertisersRequest): TokenApiPromise<AdvertisersResponse> =>
  jsonGetter<AdvertisersRequest, AdvertisersResponse>(
    `/api/advertiser?${getPeriodQueryString(dateFrom, dateTo)}`
  );

export const advertisersByOrganisationGetter = ({
  organisationId,
}: AdvertisersByOrganisationRequest): Promise<
  AsyncResponse<AdvertisersResponse>
> => getter<AdvertisersResponse>(`/api/advertiser/${organisationId}`);
