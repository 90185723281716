import {
  CheckOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Tooltip } from "antd";
import React, { memo } from "react";

import { CommercialDeliveryStatus } from "../../models/CommercialDeliveryStatus";

const DeliveryStatusBadge = memo(
  ({ deliveryStatus }: { deliveryStatus: CommercialDeliveryStatus }) => {
    const { i18n } = useLingui();
    switch (deliveryStatus.status) {
      case "Success":
        return (
          <Tooltip title={i18n._(t`Aanleveren is succesvol afgerond`)}>
            <CheckOutlined style={{ color: "green" }} />
          </Tooltip>
        );
      case "Failed":
        return (
          <Tooltip title={deliveryStatus.errorMessages.join(", ")}>
            <WarningOutlined style={{ color: "red" }} />
          </Tooltip>
        );
      case "Busy":
        return (
          <Tooltip title={i18n._(t`Het proces is nog bezig...`)}>
            <LoadingOutlined style={{ color: "blue" }} />
          </Tooltip>
        );
      default:
        return null;
    }
  }
);

export default DeliveryStatusBadge;
