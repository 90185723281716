import classNames from "classnames";
import { PropsWithChildren, createElement, memo } from "react";

interface VisibleConditionalProps {
  type: "placeholder" | "paragraph" | "label" | "text";
  visible: boolean;
}

const VisibleConditional = memo(
  ({ children, type, visible }: PropsWithChildren<VisibleConditionalProps>) => {
    const props = {
      children,
      className: classNames(
        "tip-visibleConditional",
        visible ? "" : "tip-visibleConditional-hidden"
      ),
    };

    let htmlElement: string;
    switch (type) {
      case "paragraph":
        htmlElement = "p";
        break;
      case "placeholder":
        htmlElement = "div";
        break;
      case "label":
        htmlElement = "label";
        break;
      case "text":
      default:
        htmlElement = "span";
        break;
    }
    return createElement(htmlElement, props);
  }
);

export default VisibleConditional;
