import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Select } from "antd";
import React, { ReactElement, useCallback } from "react";

import Advertiser from "../../../../models/Advertiser";
import { operatorLabels } from "../../../../models/Operator";

interface AdvertisersSelectProps {
  advertisers: Advertiser[];
  defaultValue: string[];
  enabled: boolean;
  onSelect: (key: string, advertiserIds: string[]) => void;
  rowKey: string;
}

const AdvertisersSelect = ({
  advertisers,
  defaultValue,
  enabled,
  onSelect,
  rowKey,
}: AdvertisersSelectProps): ReactElement => {
  const { i18n } = useLingui();

  const handleChange = useCallback(
    (newValue: string[]) => {
      onSelect(rowKey, newValue);
    },
    [onSelect, rowKey]
  );

  return (
    <Select
      key="advertisersSelect"
      defaultValue={defaultValue}
      disabled={!enabled}
      mode="multiple"
      onChange={handleChange}
      optionFilterProp="title"
      placeholder={i18n._(t`Selecteer een adverteerder...`)}
      showSearch
    >
      {advertisers &&
        advertisers.map(({ id, name, operator }: Advertiser) => {
          const title = `${name} (${operatorLabels[operator]})`;
          return (
            <Select.Option key={id} value={id} title={title}>
              {title}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default AdvertisersSelect;
