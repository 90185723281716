import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input, Select } from "antd";
import moment from "moment";
import React, { FunctionComponent, memo, useCallback } from "react";

import { Period } from "../../../../models/periods";
import { useCulture } from "../../../../utils/hooks";
import { fieldEndDateName, fieldStartDateName } from "../constants";
import { CampaignPeriodPickerProps } from "../models";

const CampaignPeriodPicker: FunctionComponent<CampaignPeriodPickerProps> = ({
  enabled,
  form: { setFieldsValue },
  periods,
  periodsLoading,
}) => {
  const handleChange = useCallback(
    /**
     * Handles a change in the selected period.
     * @param newValue The newly selected value.
     */
    (newValue: string) => {
      const startDate = moment(newValue);
      const endDate = moment(
        periods.find((p) =>
          moment(p.startDate).isSame(moment(startDate), "day")
        )?.endDate
      );
      setFieldsValue({ [fieldEndDateName]: endDate.toISOString(true) });
    },
    [periods, setFieldsValue]
  );

  const culture = useCulture();
  const { i18n } = useLingui();

  return (
    <>
      <Form.Item name={fieldEndDateName} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Periode`)}
        name={fieldStartDateName}
        rules={[
          {
            required: true,
            message: i18n._(t`Periode is verplicht.`),
          },
        ]}
      >
        <Select
          disabled={!enabled || periods.length === 0}
          loading={periodsLoading}
          onChange={handleChange}
        >
          {periods.map((p: Period) => {
            const value = moment(p.startDate).toISOString(true);
            const startDate = moment(p.startDate).format("MMMM YYYY");
            const endDate = moment(p.requestStartDate)
              .toDate()
              .toLocaleDateString(culture, {
                day: "numeric",
                month: "long",
              });
            return (
              <Select.Option
                key={value}
                value={value}
                disabled={!p.canReceiveRequests}
              >
                {i18n._(t`${startDate} (aan te vragen vanaf ${endDate})`)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default memo(CampaignPeriodPicker);
