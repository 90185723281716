import { Select } from "antd";
import React, { memo } from "react";

import { Operator, OperatorsAll, operatorLabels } from "../models/Operator";

const { Option } = Select;

export type OperatorChangeFunc = (value: Operator | string) => void;

const OperatorSelect = memo(
  ({
    value,
    onChange,
  }: {
    value: Operator | string;
    onChange: OperatorChangeFunc;
  }) => (
    <div className="tip-operator-select">
      <div className="tip-operator-select-label">Filteren</div>
      <Select style={{ width: 130 }} onChange={onChange} value={value}>
        <Option key="all" value="all">
          Totaal
        </Option>
        {OperatorsAll.map((operator) => (
          <Option key={operator} value={operator}>
            {operatorLabels[operator]}
          </Option>
        ))}
      </Select>
    </div>
  )
);

export default OperatorSelect;
