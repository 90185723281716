import { useLingui } from "@lingui/react";
import { Card, Form, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { FunctionComponent, memo, useCallback } from "react";

import { navigationHeaders } from "../../../utils/navigation";
import { labelCol, wrapperCol } from "../../../views/layout/Form";
import Filter from "./Filter";
import {
  FilterContainerProps,
  FilterFormValues,
  filterAdvertiserIdFieldName,
  filterPeriodFieldName,
} from "./models";

const FilterContainer: FunctionComponent<FilterContainerProps> = ({
  advertisers,
  loading,
  onPeriodChange,
  onQuery,
  triggerQuery,
}) => {
  const { i18n } = useLingui();
  const [form] = useForm();

  const handleFinish = useCallback(
    (values: FilterFormValues) => {
      if (values) {
        onQuery(values);
      }
    },
    [onQuery]
  );

  return (
    <Spin spinning={loading}>
      <Card
        title={i18n._(navigationHeaders.CampaignsRequestsSearch)}
        type="inner"
      >
        <Form
          name="OrderRequestsOverviewFilterForm"
          form={form}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          onFinish={handleFinish}
          initialValues={{
            [filterAdvertiserIdFieldName]: "",
            [filterPeriodFieldName]: [
              moment().startOf("month"),
              moment().endOf("month"),
            ],
          }}
        >
          <Filter
            form={form}
            advertisers={advertisers}
            loading={loading}
            onPeriodChange={onPeriodChange}
            onQuery={onQuery}
            triggerQuery={triggerQuery}
          />
        </Form>
      </Card>
    </Spin>
  );
};

export default memo(FilterContainer);
