import { all, takeEvery, takeLatest } from "redux-saga/effects";

import { history } from "../App";
import { defaultCulture, preferredCultureKey } from "../utils/hooks";
import navigationPaths from "../utils/navigation";
import { ZendeskUser, initZendeskProps } from "../utils/zendesk";
import { advertisersByDateGetter } from "./advertisers/api";
import {
  advertisersAllCell,
  advertisersByOrganisationCell,
} from "./advertisers/cells";
import {
  activatePoster,
  activateVerifyPoster,
  authenticatePoster,
  authenticateTwoFactorPoster,
  checkForAdBlocker,
  productCreateRequest,
  refreshPoster,
} from "./api";
import {
  getCurrentUser,
  requestResetPassword,
  resetVerifyPassword,
} from "./authentication/api";
import { authenticationSagas } from "./authentication/cells";
import { AuthenticationResponse } from "./authentication/models";
import { bookSpotAvailableBreaksGetter, bookSpotPoster } from "./bookspot/api";
import {
  AvailableBreaksRequest,
  AvailableBreaksResponse,
  BookSpotRequest,
} from "./bookspot/models";
import {
  calendarCell,
  createCalendarCell,
  deleteCalendarCell,
  updateCalendarCell,
} from "./calendar/cells";
import { analyzedOrdersCell } from "./campaigns/analyzed/cells";
import {
  fetchOrdersFilteredGetter,
  filtersGetter,
  spotsGetter,
} from "./campaigns/api";
import {
  advertisersWithRequestsByDateGetter,
  availableBreaksGetter,
  orderRequestCreatePoster,
  orderRequestDeleter,
  orderRequestGetter,
  orderRequestSubmitPoster,
  orderRequestUpdatePutter,
  orderRequestsByAdvertiserGetter,
  packagesGetter,
  periodsGetter,
  productsGetter,
  spotLengthIndicesGetter,
} from "./campaigns/requests/api";
import { copyRequestCell, openRequestsCell } from "./campaigns/requests/cells";
import {
  advertisersWithCommercialsByDateGetter,
  deliveryDeleter,
  deliveryUploadPoster,
  enabledOperators,
  getCommercialBlob,
  getCommercials,
} from "./commercials/api";
import { deliveryStatusCell } from "./commercials/cells";
import { getInvoiceBlob } from "./dashboard/api";
import {
  commercialMatchCell,
  dashboardDataCell,
  dashboardDataWithExtrasCell,
  dashboardNotUsedOrdersCell,
  dashboardOrderListCell,
  dashboardTilesCell,
  spotsCell,
} from "./dashboard/cell";
import {
  exportConceptExcelDownloader,
  exportOrderExcelDownloader,
  exportOrderPdfDownloader,
  exportStatisticsForDeliveriesOrganisationsExcelDownloader,
  exportStatisticsForOrganisationsExcelDownloader,
  exportStatisticsForRequestsOrganisationsExcelDownloader,
  exportStatisticsForUsersByOrganisationExcelDownloader,
  exportUsersDownloader,
} from "./exports/api";
import {
  externalApiClientAuthorizationDeleter,
  externalApiClientAuthorizationPoster,
  externalApiClientsGetter,
} from "./externalApis/api";
import { Response, httpUnauthorized } from "./fetch";
import { healthCell } from "./health/cells";
import {
  channelsCell,
  commercialsCell,
  instructionsCell,
  instructionsSubmitCell,
  previousInstructionsCell,
} from "./instructions/cells";
import { asyncLifecycle } from "./lifecycle";
import { localizationSetPoster } from "./localization/api";
import {
  ActivateRequest,
  ActivateResponse,
  ActivateVerifyRequest,
  ApiPromise,
  AuthenticateTwoFactorRequest,
  AuthenticationRequest,
  RefreshRequest,
  TokenModel,
} from "./models";
import {
  deleteAdvertiserGroup,
  saveAdvertiserGroup,
} from "./normalisation/api";
import {
  notificationsEditCell,
  notificationsReadCell,
} from "./notifications/cells";
import {
  organisationSetPasswordCell,
  organisationsCell,
} from "./organisations/cells";
import { ornImportReportCell } from "./ornImport/cells";
import { pageGetter } from "./pages/api";
import sagaTypes from "./sagaTypes";
import { skoTargetGroupCell } from "./sko/cells";
import { skoImportReportCell } from "./skoImport/cells";
import {
  commercialDeliveriesForYearGetter,
  organisationLoginsForYearGetter,
  organisationRequestsForYearGetter,
  userLoginsByOrganisationForYearGetter,
} from "./statistics/api";
import {
  CommercialDeliveriesStatisticsForYearRequest,
  CommercialDeliveriesStatisticsForYearResponse,
  OrganisationLoginStatisticsForYearRequest,
  OrganisationLoginStatisticsForYearResponse,
  OrganisationRequestStatisticsForYearRequest,
  OrganisationRequestStatisticsForYearResponse,
  UserLoginByOrganisationStatisticsForYearRequest,
  UserLoginByOrganisationStatisticsForYearResponse,
} from "./statistics/models";
import {
  clearTokens,
  getToken,
  getUsername,
  knowledgeTokenKey,
  possessionTokenKey,
  refreshTokenKey,
  removeToken,
  setToken,
  setUsername,
} from "./token";
import {
  allUsersGetter,
  deleteUserDeleter,
  registerUserPoster,
  resetUserPoster,
  updateUserPutter,
} from "./users/api";
import { userProfileCell } from "./users/cells";

export default sagas;

function* sagas(): Generator {
  const tipSagas = [
    takeEvery(
      sagaTypes.tokens.activateVerify.request,
      asyncLifecycle<ActivateVerifyRequest, string>(
        sagaTypes.tokens.activateVerify,
        (request) => activateVerifyPoster(request),
        { disableTokenCheck: true }
      )
    ),
    takeEvery(
      sagaTypes.tokens.activate.request,
      asyncLifecycle<ActivateRequest, ActivateResponse>(
        sagaTypes.tokens.activate,
        (request) => activatePoster(request),
        {
          onSuccess(
            _action,
            { activationResult, user: { emailAddress }, possession, refresh }
          ) {
            if (activationResult === "Success") {
              setUsername(emailAddress);
              setToken(possessionTokenKey, possession);
              setToken(refreshTokenKey, refresh);
            }
          },
          disableTokenCheck: true,
        }
      )
    ),
    takeEvery(
      sagaTypes.tokens.authenticate.request,
      asyncLifecycle<AuthenticationRequest, AuthenticationResponse>(
        sagaTypes.tokens.authenticate,
        (request) => {
          const currentUsername = getUsername();
          if (
            currentUsername?.toLowerCase() !== request.username.toLowerCase()
          ) {
            clearTokens(true);
          }
          setUsername(request.username);
          return authenticatePoster(request);
        },
        {
          onSuccess(_action, payload) {
            if (payload.result === "Success" && payload.knowledgeToken) {
              setToken(knowledgeTokenKey, payload.knowledgeToken, true);
              const possessionToken = getToken(possessionTokenKey);
              if (!possessionToken) {
                history.push(navigationPaths.TwoFactor);
              }
            }
          },
          disableTokenCheck: true,
        }
      )
    ),
    takeEvery(
      sagaTypes.tokens.authenticate.success,
      asyncLifecycle<RefreshRequest, TokenModel>(
        sagaTypes.tokens.refresh,
        () => {
          const username = getUsername();
          const knowledgeToken = getToken(knowledgeTokenKey, true);
          const possessionToken = getToken(possessionTokenKey);
          if (username && knowledgeToken && possessionToken) {
            return (): ApiPromise<TokenModel> =>
              refreshPoster({
                username,
                knowledgeToken: knowledgeToken.token,
                possessionToken: possessionToken.token,
              })().then((response) => {
                const { statusCode } = response;
                if (statusCode === httpUnauthorized) {
                  removeToken(possessionTokenKey);
                  history.push(navigationPaths.TwoFactor);
                }

                return response;
              });
          }
          return (): ApiPromise<TokenModel> =>
            Promise.resolve<Response<TokenModel>>({});
        },
        {
          onSuccess(_action, payload) {
            if (payload.result === "Success") {
              setToken(refreshTokenKey, payload);
              history.push("/");
            }
          },
          disableTokenCheck: true,
        }
      )
    ),
    takeEvery(
      sagaTypes.tokens.authenticateTwoFactor.request,
      asyncLifecycle<AuthenticateTwoFactorRequest, TokenModel>(
        sagaTypes.tokens.authenticateTwoFactor,
        (request) => authenticateTwoFactorPoster(request),
        {
          onSuccess(_action, payload) {
            if (payload.result === "Success") {
              setToken(possessionTokenKey, payload);
            }
          },
          disableTokenCheck: true,
        }
      )
    ),
    takeEvery(
      sagaTypes.tokens.authenticateTwoFactor.success,
      asyncLifecycle<RefreshRequest, TokenModel>(
        sagaTypes.tokens.refresh,
        () => {
          const username = getUsername();
          const knowledgeToken = getToken(knowledgeTokenKey, true);
          const possessionToken = getToken(possessionTokenKey);
          if (username && knowledgeToken && possessionToken) {
            return refreshPoster({
              username,
              knowledgeToken: knowledgeToken.token,
              possessionToken: possessionToken.token,
            });
          }
          return (): ApiPromise<TokenModel> =>
            Promise.resolve<Response<TokenModel>>({
              exception: "Username and/or tokens missing!",
            });
        },
        {
          // eslint-disable-next-line require-yield
          onSuccess(_action, payload) {
            if (payload.result === "Success") {
              setToken(refreshTokenKey, payload);
              history.push("/");
            }
          },
          disableTokenCheck: true,
        }
      )
    ),
    takeEvery(
      sagaTypes.bookSpot.availableBreaks.request,
      asyncLifecycle<AvailableBreaksRequest, AvailableBreaksResponse>(
        sagaTypes.bookSpot.availableBreaks,
        (request: AvailableBreaksRequest) =>
          bookSpotAvailableBreaksGetter(request)
      )
    ),
    takeEvery(
      sagaTypes.bookSpot.bookSpot.request,
      asyncLifecycle<BookSpotRequest, unknown>(
        sagaTypes.bookSpot.bookSpot,
        bookSpotPoster
      )
    ),
    takeEvery(
      sagaTypes.campaigns.filters.request,
      asyncLifecycle(sagaTypes.campaigns.filters, filtersGetter)
    ),
    takeEvery(
      sagaTypes.campaigns.fetchOrdersFiltered.request,
      asyncLifecycle(
        sagaTypes.campaigns.fetchOrdersFiltered,
        fetchOrdersFilteredGetter,
        {
          onRefresh: () => "clear",
        }
      )
    ),
    takeEvery(
      sagaTypes.campaigns.spotsPerOrder.request,
      asyncLifecycle(sagaTypes.campaigns.spotsPerOrder, spotsGetter)
    ),
    takeEvery(
      sagaTypes.exports.orderExcel.request,
      asyncLifecycle(sagaTypes.exports.orderExcel, exportOrderExcelDownloader)
    ),
    takeEvery(
      sagaTypes.exports.users.request,
      asyncLifecycle(sagaTypes.exports.users, exportUsersDownloader)
    ),
    takeEvery(
      sagaTypes.exports.conceptExcel.request,
      asyncLifecycle(
        sagaTypes.exports.conceptExcel,
        exportConceptExcelDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.orderPdf.request,
      asyncLifecycle(sagaTypes.exports.orderPdf, exportOrderPdfDownloader)
    ),
    takeEvery(
      sagaTypes.exports.advertiserOrdersExcel.request,
      asyncLifecycle(
        sagaTypes.exports.advertiserOrdersExcel,
        exportOrderExcelDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.advertiserOrdersPdf.request,
      asyncLifecycle(
        sagaTypes.exports.advertiserOrdersPdf,
        exportOrderPdfDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.filterOrdersExcel.request,
      asyncLifecycle(
        sagaTypes.exports.filterOrdersExcel,
        exportOrderExcelDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.filterOrdersPdf.request,
      asyncLifecycle(
        sagaTypes.exports.filterOrdersPdf,
        exportOrderPdfDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.organisationLoginsForYear.request,
      asyncLifecycle(
        sagaTypes.exports.organisationLoginsForYear,
        exportStatisticsForOrganisationsExcelDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.organisationRequestsForYear.request,
      asyncLifecycle(
        sagaTypes.exports.organisationRequestsForYear,
        exportStatisticsForRequestsOrganisationsExcelDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.commercialDeliveriesForYear.request,
      asyncLifecycle(
        sagaTypes.exports.commercialDeliveriesForYear,
        exportStatisticsForDeliveriesOrganisationsExcelDownloader
      )
    ),
    takeEvery(
      sagaTypes.exports.userLoginsByOrganisationForYear.request,
      asyncLifecycle(
        sagaTypes.exports.userLoginsByOrganisationForYear,
        exportStatisticsForUsersByOrganisationExcelDownloader
      )
    ),
    takeEvery(
      sagaTypes.externalApi.authorizationAdd.request,
      asyncLifecycle(
        sagaTypes.externalApi.authorizationAdd,
        externalApiClientAuthorizationPoster,
        { invalidate: [sagaTypes.users.current] }
      )
    ),
    takeEvery(
      sagaTypes.externalApi.authorizationDelete.request,
      asyncLifecycle(
        sagaTypes.externalApi.authorizationDelete,
        externalApiClientAuthorizationDeleter,
        { invalidate: [sagaTypes.users.current] }
      )
    ),
    takeEvery(
      sagaTypes.externalApi.clients.request,
      asyncLifecycle(sagaTypes.externalApi.clients, externalApiClientsGetter)
    ),
    takeEvery(
      sagaTypes.localization.set.request,
      asyncLifecycle(sagaTypes.localization.set, localizationSetPoster, {
        invalidate: [sagaTypes.users.current],
      })
    ),
    takeEvery(
      sagaTypes.statistics.organisationLoginsForYear.request,
      asyncLifecycle<
        OrganisationLoginStatisticsForYearRequest,
        OrganisationLoginStatisticsForYearResponse
      >(
        sagaTypes.statistics.organisationLoginsForYear,
        organisationLoginsForYearGetter
      )
    ),
    takeEvery(
      sagaTypes.statistics.organisationRequestsForYear.request,
      asyncLifecycle<
        OrganisationRequestStatisticsForYearRequest,
        OrganisationRequestStatisticsForYearResponse
      >(
        sagaTypes.statistics.organisationRequestsForYear,
        organisationRequestsForYearGetter
      )
    ),
    takeEvery(
      sagaTypes.statistics.commercialDeliveriesForYear.request,
      asyncLifecycle<
        CommercialDeliveriesStatisticsForYearRequest,
        CommercialDeliveriesStatisticsForYearResponse
      >(
        sagaTypes.statistics.commercialDeliveriesForYear,
        commercialDeliveriesForYearGetter
      )
    ),
    takeLatest(
      sagaTypes.statistics.userLoginsByOrganisationForYear.request,
      asyncLifecycle<
        UserLoginByOrganisationStatisticsForYearRequest,
        UserLoginByOrganisationStatisticsForYearResponse
      >(
        sagaTypes.statistics.userLoginsByOrganisationForYear,
        userLoginsByOrganisationForYearGetter
      )
    ),
    takeEvery(
      sagaTypes.users.current.request,
      asyncLifecycle(sagaTypes.users.current, getCurrentUser, {
        onSuccess: (action, payload) => {
          const user: ZendeskUser = {
            name: payload.name,
            email: payload.emailAddress,
            organisation: payload.organisation?.name,
            preferredCulture: payload.preferredCulture || defaultCulture,
          };
          localStorage.setItem(
            preferredCultureKey,
            payload.preferredCulture || defaultCulture
          );
          initZendeskProps(user);
        },
      })
    ),
    takeEvery(
      sagaTypes.users.all.request,
      asyncLifecycle(sagaTypes.users.all, allUsersGetter, {
        select: (store) => store.users.all,
      })
    ),
    takeEvery(
      sagaTypes.users.register.request,
      asyncLifecycle(sagaTypes.users.register, registerUserPoster, {
        invalidate: [sagaTypes.users.all],
      })
    ),
    takeEvery(
      sagaTypes.users.update.request,
      asyncLifecycle(sagaTypes.users.update, updateUserPutter, {
        invalidate: [sagaTypes.users.all],
      })
    ),
    takeEvery(
      sagaTypes.users.reset.request,
      asyncLifecycle(sagaTypes.users.reset, resetUserPoster, {
        invalidate: [sagaTypes.users.all],
      })
    ),
    takeEvery(
      sagaTypes.users.delete.request,
      asyncLifecycle(sagaTypes.users.delete, deleteUserDeleter, {
        invalidate: [sagaTypes.users.all],
      })
    ),
    takeEvery(
      sagaTypes.global.resetrequest.request,
      asyncLifecycle(sagaTypes.global.resetrequest, requestResetPassword, {
        disableTokenCheck: true,
      })
    ),
    takeEvery(
      sagaTypes.global.resetVerify.request,
      asyncLifecycle(sagaTypes.global.resetVerify, resetVerifyPassword, {
        disableTokenCheck: true,
      })
    ),
    takeEvery(
      sagaTypes.normalisation.saveAdvertiserGroup.request,
      asyncLifecycle(
        sagaTypes.normalisation.saveAdvertiserGroup,
        saveAdvertiserGroup
      )
    ),
    takeEvery(
      sagaTypes.normalisation.deleteAdvertiserGroup.request,
      asyncLifecycle(
        sagaTypes.normalisation.deleteAdvertiserGroup,
        deleteAdvertiserGroup
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.advertisers.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.advertisers,
        advertisersByDateGetter,
        { onRefresh: () => "clear" }
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.advertisersForFilter.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.advertisersForFilter,
        advertisersWithRequestsByDateGetter,
        { onRefresh: () => "clear" }
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.create.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.create,
        orderRequestCreatePoster,
        {
          invalidate: [sagaTypes.campaigns.requests.advertisersForFilter],
        }
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.delete.request,
      asyncLifecycle(sagaTypes.campaigns.requests.delete, orderRequestDeleter, {
        invalidate: [
          sagaTypes.campaigns.requests.orderRequestsByAdvertiser,
          sagaTypes.campaigns.requests.advertisersForFilter,
        ],
      })
    ),
    takeEvery(
      sagaTypes.campaigns.requests.orderRequest.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.orderRequest,
        orderRequestGetter,
        {
          onRefresh: () => "invalidate",
        }
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.orderRequestsByAdvertiser.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.orderRequestsByAdvertiser,
        orderRequestsByAdvertiserGetter
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.periods.request,
      asyncLifecycle(sagaTypes.campaigns.requests.periods, periodsGetter)
    ),
    takeEvery(
      sagaTypes.campaigns.requests.products.request,
      asyncLifecycle(sagaTypes.campaigns.requests.products, productsGetter, {
        onRefresh: () => "clear",
      })
    ),
    takeEvery(
      sagaTypes.campaigns.requests.spotLengthIndices.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.spotLengthIndices,
        spotLengthIndicesGetter
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.packages.request,
      asyncLifecycle(sagaTypes.campaigns.requests.packages, packagesGetter, {
        onRefresh: () => "clear",
      })
    ),
    takeEvery(
      sagaTypes.campaigns.requests.submit.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.submit,
        orderRequestSubmitPoster,
        {
          invalidate: [
            sagaTypes.campaigns.requests.orderRequest,
            sagaTypes.campaigns.requests.advertisersForFilter,
          ],
        }
      )
    ),
    takeEvery(
      sagaTypes.campaigns.requests.update.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.update,
        orderRequestUpdatePutter
      )
    ),
    takeEvery(
      sagaTypes.product.request,
      asyncLifecycle(sagaTypes.product, productCreateRequest)
    ),
    takeEvery(
      sagaTypes.campaigns.requests.availableBreaks.request,
      asyncLifecycle(
        sagaTypes.campaigns.requests.availableBreaks,
        availableBreaksGetter,
        { onRefresh: () => "clear" }
      )
    ),
    takeEvery(
      sagaTypes.commercials.delivery.request,
      asyncLifecycle(sagaTypes.commercials.delivery, deliveryUploadPoster)
    ),
    takeEvery(
      sagaTypes.commercials.operators.request,
      asyncLifecycle(sagaTypes.commercials.operators, enabledOperators)
    ),
    takeEvery(
      sagaTypes.commercials.overview.request,
      asyncLifecycle(sagaTypes.commercials.overview, getCommercials)
    ),
    takeEvery(
      sagaTypes.commercials.advertisers.request,
      asyncLifecycle(
        sagaTypes.commercials.advertisers,
        advertisersWithCommercialsByDateGetter,
        {
          onRefresh: () => "clear",
        }
      )
    ),
    takeEvery(
      sagaTypes.commercials.download.request,
      asyncLifecycle(sagaTypes.commercials.download, getCommercialBlob)
    ),
    takeEvery(
      sagaTypes.invoice.download.request,
      asyncLifecycle(sagaTypes.invoice.download, getInvoiceBlob)
    ),
    takeEvery(
      sagaTypes.commercials.deleter.request,
      asyncLifecycle(sagaTypes.commercials.deleter, deliveryDeleter, {
        invalidate: [sagaTypes.commercials.overview],
      })
    ),
    takeLatest(
      sagaTypes.ui.adBlocker.request,
      asyncLifecycle(sagaTypes.ui.adBlocker, checkForAdBlocker)
    ),
    takeEvery(
      sagaTypes.pages.request,
      asyncLifecycle(sagaTypes.pages, pageGetter)
    ),
    ...advertisersAllCell.sagas,
    ...advertisersByOrganisationCell.sagas,
    ...analyzedOrdersCell.sagas,
    ...authenticationSagas,
    ...calendarCell.sagas,
    ...channelsCell.sagas,
    ...commercialMatchCell.sagas,
    ...commercialsCell.sagas,
    ...copyRequestCell.sagas,
    ...createCalendarCell.sagas,
    ...dashboardDataCell.sagas,
    ...dashboardDataWithExtrasCell.sagas,
    ...dashboardNotUsedOrdersCell.sagas,
    ...dashboardOrderListCell.sagas,
    ...dashboardTilesCell.sagas,
    ...deleteCalendarCell.sagas,
    ...deliveryStatusCell.sagas,
    ...healthCell.sagas,
    ...instructionsCell.sagas,
    ...instructionsSubmitCell.sagas,
    ...notificationsEditCell.sagas,
    ...notificationsReadCell.sagas,
    ...openRequestsCell.sagas,
    ...organisationsCell.sagas,
    ...organisationSetPasswordCell.sagas,
    ...ornImportReportCell.sagas,
    ...previousInstructionsCell.sagas,
    ...skoImportReportCell.sagas,
    ...skoTargetGroupCell.sagas,
    ...spotsCell.sagas,
    ...updateCalendarCell.sagas,
    ...userProfileCell.sagas,
  ];

  yield all(tipSagas);
}
