import { Notification, NotificationBody } from "../../../models/notifications";
import propertyOf from "../../../utils/properties";

export const idField = propertyOf<Notification>("id");
export const textField = propertyOf<Notification>("text");
export const statusField = propertyOf<Notification>("status");

export const emptyNotification: NotificationBody = {
  text: "",
  status: "Hidden",
};
