import { Col, Row } from "antd";
import React, { Children, ReactElement, ReactNode } from "react";

interface UsersContentProps {
  children?: ReactNode;
}

const UsersContent = ({ children }: UsersContentProps): ReactElement => (
  <section className="usersContent">
    {children &&
      Children.map(children, (child) => (
        <Row>
          <Col span={24}>{child}</Col>
        </Row>
      ))}
  </section>
);

export default UsersContent;
