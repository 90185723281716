import { jsonGetter } from "../fetch";
import { ApiPromise, TokenApiPromise } from "../models";
import {
  CommercialDeliveriesStatisticsForYearRequest,
  CommercialDeliveriesStatisticsForYearResponse,
  OrganisationLoginStatisticsForYearRequest,
  OrganisationLoginStatisticsForYearResponse,
  OrganisationRequestStatisticsForYearRequest,
  OrganisationRequestStatisticsForYearResponse,
  UserLoginByOrganisationStatisticsForYearRequest,
  UserLoginByOrganisationStatisticsForYearResponse,
} from "./models";

export const organisationLoginsForYearGetter =
  ({
    year,
  }: OrganisationLoginStatisticsForYearRequest): TokenApiPromise<OrganisationLoginStatisticsForYearResponse> =>
  (token?: string): ApiPromise<OrganisationLoginStatisticsForYearResponse> =>
    jsonGetter<OrganisationLoginStatisticsForYearResponse>(
      `/api/statistics/overall/${year}`
    )(token);

export const organisationRequestsForYearGetter =
  ({
    year,
    operator,
  }: OrganisationRequestStatisticsForYearRequest): TokenApiPromise<OrganisationRequestStatisticsForYearResponse> =>
  (token?: string): ApiPromise<OrganisationRequestStatisticsForYearResponse> =>
    jsonGetter<OrganisationRequestStatisticsForYearResponse>(
      operator
        ? `/api/statistics/orderRequests/byOrganisation/${year}/${operator}`
        : `/api/statistics/orderRequests/byOrganisation/${year}`
    )(token);

export const commercialDeliveriesForYearGetter =
  ({
    year,
    operator,
  }: CommercialDeliveriesStatisticsForYearRequest): TokenApiPromise<CommercialDeliveriesStatisticsForYearResponse> =>
  (token?: string): ApiPromise<CommercialDeliveriesStatisticsForYearResponse> =>
    jsonGetter<CommercialDeliveriesStatisticsForYearResponse>(
      operator
        ? `/api/statistics/commercialDeliveries/byOrganisation/${year}/${operator}`
        : `/api/statistics/commercialDeliveries/byOrganisation/${year}`
    )(token);

export const userLoginsByOrganisationForYearGetter =
  ({
    organisationId,
    year,
  }: UserLoginByOrganisationStatisticsForYearRequest): TokenApiPromise<UserLoginByOrganisationStatisticsForYearResponse> =>
  (
    token?: string
  ): ApiPromise<UserLoginByOrganisationStatisticsForYearResponse> =>
    jsonGetter<UserLoginByOrganisationStatisticsForYearResponse>(
      `/api/statistics/organisation/${organisationId}/${year}`
    )(token);
