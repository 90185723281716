import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Badge, Table, Tooltip } from "antd";
import { AsyncStatus } from "async-lifecycle-saga/dist/models";
import moment from "moment";
import React, { ReactNode, memo } from "react";

import { SkoImportReport } from "../../../store/skoImport/models";
import Ellipsis from "../../../views/Ellipsis";

const SkoImportReportTable = memo(
  ({ value, status }: { value?: SkoImportReport[]; status: AsyncStatus }) => {
    const { i18n } = useLingui();

    return (
      <Table
        dataSource={value}
        columns={[
          {
            title: i18n._(t`Datum`),
            key: "start",
            width: 250,
            render: (_text, { start, end }): ReactNode => (
              <>
                {moment(start).format("D-M-YYYY LT")} -{" "}
                {moment(end).format("LT")}
              </>
            ),
          },
          {
            title: i18n._(t`Duur`),
            dataIndex: "duration",
            key: "duration",
            width: 100,
            render: (_text, { duration }): ReactNode => duration.substr(0, 8),
          },
          {
            title: i18n._(t`Status`),
            dataIndex: "status",
            key: "status",
            width: 80,
            align: "center",
            render: (_text, { status: importStatus, message }): ReactNode => {
              const badgeStatus =
                importStatus === "Success" ? "success" : "error";
              const badgeTooltip =
                importStatus === "Success"
                  ? i18n._(t`Import succesvol`)
                  : message ?? i18n._(t`Fout bij importeren`);
              return (
                <Tooltip title={badgeTooltip}>
                  <Badge status={badgeStatus} />
                </Tooltip>
              );
            },
          },
          {
            title: (
              <Tooltip
                title={i18n._(
                  t`Geconverteerd aantal rijen vanuit het CSV-bestand`
                )}
              >
                {i18n._(t`Geconverteerd`)}
              </Tooltip>
            ),
            dataIndex: "converted",
            key: "converted",
            width: 150,
          },
          {
            title: (
              <Tooltip
                title={i18n._(
                  t`Geïmporteerde documenten, gegroepeerd opgeslagen per FilmID + dag`
                )}
              >
                {i18n._(t`Geïmporteerd`)}
              </Tooltip>
            ),
            dataIndex: "imported",
            key: "imported",
            width: 150,
          },
          {
            title: i18n._(t`Bestandsnaam`),
            dataIndex: "filename",
            key: "filename",
            ellipsis: true,
            render: (_text, { filename }): ReactNode => (
              <Ellipsis text={filename} />
            ),
          },
        ]}
        loading={status.loading}
        pagination={false}
        rowKey="id"
      />
    );
  }
);

export default SkoImportReportTable;
