import { Trans, plural, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Empty, Form, Input, Space, Table, Typography } from "antd";
import React, { FunctionComponent, memo, useCallback } from "react";

import {
  fieldBudgetName,
  fieldSpotsName,
} from "../../../../../models/Campaigns/Requests/models";
import { Break } from "../../../../../store/campaigns/requests/models";
import { useCulture } from "../../../../../utils/hooks";
import BlocksPickerProgress from "../../../../../views/BlocksPickerProgress";
import BudgetGrpsView from "../../../../../views/campaigns/requests/subOrders/BudgetGrpsView";
import Euro from "../../../../../views/Euro";
import FullScreenModal from "../../../../../views/FullScreenModal";
import Grps from "../../../../../views/Grps";
import { labelCol, wrapperCol } from "../../../../../views/layout/Form";
import MainContent from "../../../../../views/layout/MainContent";
import { formatNumber } from "../../../../../views/utils";
import { BlocksPickerProps } from "../models";
import BudgetField from "./BudgetField";

const emptySelection: Break[] = [];

const BlocksPicker: FunctionComponent<BlocksPickerProps> = ({
  availableBreaks,
  columns,
  enabled,
  handleCancel,
  handleOk,
  handleSelectBlocksClick,
  loading,
  modalVisible,
  selectionFilter,
  onSelectionFilter,
  required = true,
  rowSelection,
  selectedBlocks,
  realGrpPrice,
  selectedRowKeys,
  operator,
  packageCode,
}) => {
  const { i18n } = useLingui();
  const culture = useCulture();

  const handleFilterAll = useCallback(
    /**
     * Handles a click on the show all button.
     */
    () => {
      onSelectionFilter("All");
    },
    [onSelectionFilter]
  );

  const handleFilterSelection = useCallback(
    /**
     * Handles a click on the show selected button.
     */
    () => {
      onSelectionFilter("OnlySelected");
    },
    [onSelectionFilter]
  );

  const grps =
    selectedBlocks
      ?.map((b) => b.predictedRating ?? 0)
      .reduce((previous, current) => previous + current, 0) ?? 0;

  let minimum = 100; // Ster: 100%
  // eslint-disable-next-line default-case
  switch (operator) {
    case "Rtl":
      minimum = packageCode?.includes("Sturing Vast") ? 200 : 150;
      break;
    case "Talpa":
      minimum = 150;
      break;
  }

  return (
    <BudgetGrpsView singleField>
      <BudgetField enabled={enabled} isCombiField={false} />
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const budget = Number(getFieldValue(fieldBudgetName) ?? "0");
          return (
            <Form.Item
              label={i18n._(t`GRP's`)}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              help={
                realGrpPrice > 0 && (
                  <Trans>
                    Let op, het aantal GRPs is slechts een{" "}
                    <strong>indicatie</strong>, hieraan kunnen geen rechten
                    worden ontleend. Als reële GRP-prijs wordt{" "}
                    <Euro amount={realGrpPrice} inline /> gebruikt.
                  </Trans>
                )
              }
            >
              <Grps
                showSuffix
                standalone={false}
                amount={
                  realGrpPrice > 0 && budget > 0
                    ? Number(getFieldValue(fieldBudgetName)) / realGrpPrice
                    : 0
                }
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const currentSelection =
            availableBreaks?.filter((a) =>
              selectedRowKeys.includes(a.uniqueId)
            ) ?? emptySelection;
          const grpsFromSelection = currentSelection.reduce(
            (sum, current) => sum + current.predictedRating,
            0
          );
          const budget = Number(getFieldValue(fieldBudgetName) ?? "0");
          const grpsFromBudget = realGrpPrice > 0 ? budget / realGrpPrice : 0;

          return (
            <Form.Item
              label={i18n._(t`Blokken`)}
              labelCol={labelCol}
              required
              wrapperCol={wrapperCol}
            >
              {selectedBlocks ? (
                <Typography.Paragraph>
                  {i18n._(
                    plural(selectedBlocks.length, {
                      zero: `Geen blokken geselecteerd.`,
                      one: `# blok geselecteerd.`,
                      few: `# blokken geselecteerd.`,
                      many: `# blokken geselecteerd.`,
                      other: `# blokken geselecteerd.`,
                    })
                  )}
                  {grps > 0 && (
                    <Typography.Text strong>
                      {" "}
                      (<Grps amount={grps} showSuffix standalone={false} />
                      {grpsFromBudget > 0 && (
                        <>
                          ;{" "}
                          <Trans>
                            Selectie voortgang:{" "}
                            {formatNumber(
                              (grps / grpsFromBudget) * 100,
                              1,
                              culture
                            )}
                            % van minimaal {minimum}%
                          </Trans>
                        </>
                      )}
                      )
                    </Typography.Text>
                  )}
                </Typography.Paragraph>
              ) : (
                ""
              )}

              <Form.Item
                name={fieldSpotsName}
                rules={[
                  {
                    required,
                    message: i18n._(t`Blokselectie is verplicht.`),
                  },
                ]}
                noStyle
              >
                <Input type="hidden" />
              </Form.Item>

              <Button
                type="default"
                onClick={handleSelectBlocksClick}
                disabled={!enabled || budget === 0}
              >
                <Trans>Blokken kiezen</Trans>
              </Button>

              {!budget && (
                <Typography.Paragraph type="warning">
                  <Trans>Voer eerst een budget in</Trans>
                </Typography.Paragraph>
              )}

              <FullScreenModal
                visible={modalVisible}
                title={i18n._(t`Blokken kiezen`)}
                onCancel={handleCancel}
                onOk={handleOk}
              >
                <MainContent>
                  <Space>
                    <Button
                      type="default"
                      onClick={handleFilterSelection}
                      disabled={selectionFilter === "OnlySelected"}
                    >
                      <Trans>Toon mijn selectie</Trans>
                    </Button>
                    <Button
                      type="default"
                      onClick={handleFilterAll}
                      disabled={selectionFilter === "All"}
                    >
                      <Trans>Toon alles</Trans>
                    </Button>

                    {grpsFromSelection > 0 && grpsFromBudget > 0 && (
                      <BlocksPickerProgress
                        percent={(grpsFromSelection / grpsFromBudget) * 100}
                        minimum={minimum}
                      />
                    )}
                  </Space>
                  <Table
                    bordered
                    columns={columns}
                    dataSource={availableBreaks}
                    loading={!availableBreaks && loading}
                    locale={{
                      emptyText:
                        !availableBreaks && loading ? (
                          " "
                        ) : (
                          <Empty
                            description={i18n._(t`Geen blokken aanwezig.`)}
                          />
                        ),
                    }}
                    pagination={{
                      defaultPageSize: 100,
                      pageSizeOptions: ["100", "250", "500"],
                    }}
                    rowKey={(brk: Break): string => brk.uniqueId}
                    rowSelection={rowSelection}
                    scroll={{ x: 1580, y: 620 }}
                    size="small"
                  />
                </MainContent>
              </FullScreenModal>
            </Form.Item>
          );
        }}
      </Form.Item>
    </BudgetGrpsView>
  );
};

export default memo(BlocksPicker);
