import { SearchOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { memo } from "react";

import BigScreen from "../../views/layout/BigScreen";
import ActionButtonProps from "./ActionButtonProps";

interface ShowButtonProps extends ActionButtonProps {
  showIcon?: boolean;
}

const ShowButton = memo(({ disabled, block, showIcon }: ShowButtonProps) => (
  <Button
    icon={block || showIcon ? <SearchOutlined /> : undefined}
    type="primary"
    htmlType="submit"
    disabled={disabled}
    block={block}
  >
    <BigScreen type="text">
      <Trans id="Tonen">Tonen</Trans>
    </BigScreen>
  </Button>
));

export default ShowButton;
