import { Moment } from "moment";
import React, { ReactElement } from "react";

import PeriodView from "../../../PeriodView";

interface ValidityPeriodViewProps {
  period?: [Moment, Moment];
}

const ValidityPeriodView = ({
  period,
}: ValidityPeriodViewProps): ReactElement => (
  <PeriodView
    from={period && period[0] ? period[0].toDate() : undefined}
    to={period && period[1] ? period[1].toDate() : undefined}
    key="periodView"
  />
);

export default ValidityPeriodView;
