import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { ReactElement, useCallback } from "react";

import AuthorizationRule from "../../../../models/AuthorizationRule";

interface EditButtonProps {
  authorizationRule: AuthorizationRule;
  enabled: boolean;
  onEdit: (key: string, authorizationRule: AuthorizationRule) => void;
  rowKey: string;
}

const EditButton = ({
  authorizationRule,
  enabled,
  onEdit,
  rowKey,
}: EditButtonProps): ReactElement => {
  const handleClick = useCallback(() => {
    onEdit(rowKey, authorizationRule);
  }, [authorizationRule, onEdit, rowKey]);

  return (
    <Button
      key="editButton"
      type="link"
      disabled={!enabled}
      onClick={handleClick}
    >
      <Trans>Bewerken</Trans>
    </Button>
  );
};

export default EditButton;
