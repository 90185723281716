import { createForm, createSingle } from "async-lifecycle-saga";
import { AsyncRefresh } from "async-lifecycle-saga/dist/models";

import { advertisersByOrganisationGetter, advertisersGetter } from "./api";

export const advertisersAllCell = createSingle({
  path: ["advertisers", "all"],
  require: { api: advertisersGetter },
});

export const advertisersByOrganisationCell = createForm({
  api: advertisersByOrganisationGetter,
  onRefresh: (): AsyncRefresh => "clear",
  path: ["advertisers", "byOrganisation"],
});
