import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Col, Row, Spin, Typography, message } from "antd";
import queryString from "query-string";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

import { confirmResetPasswordCell } from "../../store/authentication/cells";
import { ConfirmResetUserPassword } from "../../store/authentication/models";
import {
  HttpStatusCode,
  httpConflict,
  httpInternalServerError,
} from "../../store/fetch";
import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { clearTokens, clearUsername } from "../../store/token";
import navigationPaths, { navigationHeaders } from "../../utils/navigation";
import useIsMounted from "../../utils/statefulness";
import PasswordForm from "./PasswordForm";

interface ConfirmPasswordResetModel {
  password: string;
}

type VerificationStateType = "Valid" | "Invalid" | "Error";

export const ResetVerifyInvalid = memo(() => {
  const { i18n } = useLingui();

  return (
    <Row>
      <Col span={12} offset={6}>
        <Typography.Title>
          {i18n._(navigationHeaders.ConfirmPasswordReset)}
        </Typography.Title>
        <Typography.Paragraph>
          <Trans>
            De URL die je hebt gebruikt is niet meer geldig. Probeer{" "}
            <Link to={navigationPaths.ForgotPassword}>
              opnieuw je wachtwoord te herstellen
            </Link>
            .
          </Trans>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Trans>
            Ga terug naar het{" "}
            <Link to={navigationPaths.Login}>inlogformulier</Link>.
          </Trans>
        </Typography.Paragraph>
      </Col>
    </Row>
  );
});

export const ResetVerifyError = memo(() => {
  const { i18n } = useLingui();

  return (
    <Row>
      <Col span={12} offset={6}>
        <Typography.Title>
          {i18n._(navigationHeaders.ConfirmPasswordReset)}
        </Typography.Title>
        <Typography.Paragraph>
          <Trans>
            Er is iets misgegaan bij het controleren van de
            wachtwoord-herstel-link.
          </Trans>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Trans>
            Ga terug naar het{" "}
            <Link to={navigationPaths.Login}>inlogformulier</Link>.
          </Trans>
        </Typography.Paragraph>
      </Col>
    </Row>
  );
});

const ConfirmPasswordResetFormWrapper = memo(() => {
  const { search } = useLocation();
  const { token, mail } = queryString.parse(search);

  if (!token || !mail) {
    // incorrect uri => redirect to home
    return <Navigate to="/" />;
  }

  return (
    <ConfirmPasswordResetForm token={token as string} mail={mail as string} />
  );
});

const ConfirmPasswordResetForm = memo(
  ({ token, mail }: { token: string; mail: string }) => {
    const { i18n } = useLingui();
    const isMounted = useIsMounted();
    const [verificationState, setVerificationState] =
      useState<VerificationStateType>("Valid");
    const [isConfirmed, setIsConfirmed] = useState(false);
    const dispatch = useDispatch();
    const { loading = false } = useSelector(
      ({
        global: {
          confirmPassword: { status },
        },
      }: StoreModel) => status
    );

    useEffect(() => {
      clearUsername();
      dispatch({
        type: sagaTypes.global.resetVerify.request,
        payload: {
          emailAddress: mail as string,
          token: token as string,
        },
        onFail: (exception?: string, statusCode?: HttpStatusCode) => {
          if (!isMounted.current) {
            return;
          }
          switch (statusCode) {
            case httpConflict:
              setVerificationState("Invalid");
              break;
            case httpInternalServerError:
            default:
              setVerificationState("Error");
              break;
          }
        },
      });
    }, [dispatch, isMounted, mail, token]);

    const handleSuccess = useCallback(
      (values: ConfirmPasswordResetModel | undefined) => {
        if (!values) {
          return;
        }

        const { password } = values;
        const confirm: ConfirmResetUserPassword = {
          resetToken: token as string,
          newPassword: password,
          username: mail as string,
        };

        dispatch(
          confirmResetPasswordCell.require(confirm, {
            onFail: (details) => {
              message.error(`${details.title}: ${details.detail}`);
            },
            onSuccess: () => {
              dispatch({ type: confirmResetPasswordCell.events.clear });
              clearTokens();
              dispatch({
                type: sagaTypes.users.current.clear,
              });
              message.success(
                t`Je wachtwoord is hersteld. Vanaf nu kun je inloggen met je nieuwe wachtwoord.`
              );
              if (isMounted.current) {
                setIsConfirmed(true);
              }
            },
          })
        );
      },
      [dispatch, isMounted, mail, token]
    );

    switch (verificationState) {
      case "Valid":
        return (
          <Spin spinning={loading}>
            <PasswordForm
              name="ConfirmPasswordReset"
              onSuccess={handleSuccess}
              buttonLabel={i18n._(t`Herstellen`)}
              showLoginButton={isConfirmed}
            />
          </Spin>
        );
      case "Invalid":
        return <ResetVerifyInvalid />;
      case "Error":
        return <ResetVerifyError />;
      default:
        return null;
    }
  }
);

export default ConfirmPasswordResetFormWrapper;
