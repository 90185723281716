export type Operator = "Tip" | "Orn" | "Rtl" | "Ster" | "Talpa";

export const OperatorOrn: Operator = "Orn";
export const OperatorRtl: Operator = "Rtl";
export const OperatorSter: Operator = "Ster";
export const OperatorTalpa: Operator = "Talpa";
export const OperatorUnknown: Operator = "Tip";

export const OperatorsAll: Operator[] = [
  OperatorRtl,
  OperatorOrn,
  OperatorSter,
  OperatorTalpa,
];

export const operatorLabels: { [operator in Operator]: string } = {
  Tip: "TIP",
  Orn: "ORN",
  Rtl: "Ad Alliance",
  Ster: "Ster",
  Talpa: "Talpa",
};

export const getOperatorFromId = (id: string): Operator =>
  id.substring(0, id.indexOf(":")) as Operator;

export const getIdFromId = (id: string): number | string => {
  const result = id.substring(id.indexOf(":") + 1);
  if (result && Number.isNaN(Number(result))) {
    return result as string;
  }

  return Number(result);
};

export const emptyOperators: Operator[] = [];
