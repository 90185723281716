import { Moment } from "moment";
import { combineReducers } from "redux";

import Advertiser from "../../../models/Advertiser";
import { PackagesResponse } from "../../../models/packages";
import { Period } from "../../../models/periods";
import { ProductsResponse } from "../../../models/products";
import SpotLengthIndex from "../../../models/SpotLengthIndex";
import { AdvertisersResponse } from "../../advertisers/models";
import { asyncReducer } from "../../lifecycle";
import sagaTypes from "../../sagaTypes";
import { copyRequestCell, openRequestsCell } from "./cells";
import {
  Break,
  OrderRequestCreateRequest,
  OrderRequestCreateResponse,
  OrderRequestDeleteResponse,
  OrderRequestGetResponse,
  OrderRequestSubmissionRequest,
  OrderRequestUpdateRequest,
  OrderRequestsByAdvertiserGetResponse,
} from "./models";

export const openRequestsReducer = openRequestsCell.reducer;

export const copyRequestReducer = copyRequestCell.reducer;

export default combineReducers({
  advertisers: asyncReducer<unknown, AdvertisersResponse>(
    sagaTypes.campaigns.requests.advertisers
  ),
  advertisersForFilter: asyncReducer<unknown, Advertiser[]>(
    sagaTypes.campaigns.requests.advertisersForFilter
  ),
  create: asyncReducer<OrderRequestCreateRequest, OrderRequestCreateResponse>(
    sagaTypes.campaigns.requests.create
  ),
  deleteOrderRequest: asyncReducer<string, OrderRequestDeleteResponse>(
    sagaTypes.campaigns.requests.delete
  ),
  orderRequest: asyncReducer<string, OrderRequestGetResponse>(
    sagaTypes.campaigns.requests.orderRequest
  ),
  orderRequestsByAdvertiser: asyncReducer<
    Moment,
    OrderRequestsByAdvertiserGetResponse
  >(sagaTypes.campaigns.requests.orderRequestsByAdvertiser),
  packages: asyncReducer<unknown, PackagesResponse>(
    sagaTypes.campaigns.requests.packages
  ),
  periods: asyncReducer<unknown, Period[]>(
    sagaTypes.campaigns.requests.periods
  ),
  products: asyncReducer<unknown, ProductsResponse>(
    sagaTypes.campaigns.requests.products
  ),
  spotLengthIndices: asyncReducer<unknown, SpotLengthIndex[]>(
    sagaTypes.campaigns.requests.spotLengthIndices
  ),
  submit: asyncReducer<OrderRequestSubmissionRequest, unknown>(
    sagaTypes.campaigns.requests.submit
  ),
  update: asyncReducer<OrderRequestUpdateRequest, unknown>(
    sagaTypes.campaigns.requests.update
  ),
  availableBreaks: asyncReducer<unknown, Break[]>(
    sagaTypes.campaigns.requests.availableBreaks
  ),
});
