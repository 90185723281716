import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Table, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { saveAs } from "file-saver";
import moment from "moment";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MonthNumber,
  StatisticsByOrganisationModel,
} from "../../../models/StatisticsModels";
import { HttpStatusCode } from "../../../store/fetch";
import { StoreModel } from "../../../store/models";
import sagaTypes from "../../../store/sagaTypes";
import Ellipsis from "../../../views/Ellipsis";
import BigScreen from "../../../views/layout/BigScreen";
import Flex from "../../../views/layout/Flex";
import OperatorSelect, {
  OperatorChangeFunc,
} from "../../../views/OperatorSelect";
import YearSelect, { YearChangeFunc } from "../../../views/YearSelect";
import ExportButton from "../../actions/ExportButton";
import { getTotals, organisationTypeColumn, totalFilterOptions } from "./utils";

const NumberOfCommercialDeliveries = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const {
    status: { loading: commercialDeliveriesForYearLoading },
    value: commercialDeliveriesForYearValue,
  } = useSelector(
    ({ statistics: { commercialDeliveriesForYear } }: StoreModel) =>
      commercialDeliveriesForYear
  );

  const {
    status: { loading: exportCommercialDeliveriesForYearLoading },
  } = useSelector(
    ({ exports: { commercialDeliveriesForYear } }: StoreModel) =>
      commercialDeliveriesForYear
  );

  const [year, setYear] = useState(moment().year());
  const handleYearChange = useCallback<YearChangeFunc>((value) => {
    setYear(value);
  }, []);

  const [operator, setOperator] = useState("all");
  const handleOperatorChange = useCallback<OperatorChangeFunc>((value) => {
    setOperator(value);
  }, []);

  const handleExportClick = useCallback(() => {
    dispatch({
      type: sagaTypes.exports.commercialDeliveriesForYear.request,
      payload: { year, operator: operator === "all" ? undefined : operator },
      onFail: () => {
        message.error(i18n._(t`Er is iets misgegaan.`));
      },
      onSuccess: (_statusCode?: HttpStatusCode, response?: Blob) => {
        if (!response) {
          return;
        }

        const filename = `DeliveriesPerOrganisatie_${year}_${
          operator ?? "Totaal"
        }.xlsx`;
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(response, filename);
        } else {
          saveAs(response, filename);
        }
      },
    });
  }, [dispatch, i18n, operator, year]);

  const columns: ColumnProps<StatisticsByOrganisationModel>[] = [
    {
      key: "organisation",
      title: i18n._(t`Organisatie`),
      width: 200,
      ellipsis: true,
      render: (_text, { name }) => <Ellipsis text={name} />,
    },
    organisationTypeColumn(
      i18n
    ) as unknown as ColumnProps<StatisticsByOrganisationModel>,
    ...moment.monthsShort().map((monthName, index) => {
      const monthNumber = `${index + 1}`;
      return {
        key: monthNumber,
        title: monthName,
        render: (
          _: string,
          { statistics }: StatisticsByOrganisationModel
        ): ReactNode => (
          <>
            {statistics ? statistics[`${monthNumber}` as MonthNumber] ?? 0 : 0}
          </>
        ),
      };
    }),
    {
      key: "total",
      title: i18n._(t`Totaal`),
      ...totalFilterOptions,
      render: (_text, { statistics }): ReactNode => getTotals(statistics),
    },
  ];

  useEffect(() => {
    dispatch({
      type: sagaTypes.statistics.commercialDeliveriesForYear.request,
      payload: { year, operator: operator === "all" ? undefined : operator },
    });
  }, [dispatch, year, operator]);

  return (
    <>
      <Flex align="end" type="placeholder">
        <YearSelect value={year} onChange={handleYearChange} />
        <OperatorSelect value={operator} onChange={handleOperatorChange} />
        <ExportButton
          iconType="Excel"
          loading={exportCommercialDeliveriesForYearLoading}
          onClick={handleExportClick}
        >
          <BigScreen type="text">
            <Trans>Exporteren</Trans>
          </BigScreen>
        </ExportButton>
      </Flex>
      <Table
        columns={columns}
        dataSource={
          commercialDeliveriesForYearValue &&
          commercialDeliveriesForYearValue.statistics
        }
        loading={
          !commercialDeliveriesForYearValue &&
          commercialDeliveriesForYearLoading
        }
        pagination={false}
        rowKey={(statistic): string => statistic.organisationId}
      />
    </>
  );
});

export default NumberOfCommercialDeliveries;
