import Icon from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Avatar, Dropdown } from "antd";
import React, { memo } from "react";

import { ReactComponent as UserIconSvg } from "../../assets/user-icon.svg";
import User from "../../models/User";
import UserContextMenu from "../user/UserContextMenu";

interface UserMenuProps {
  user: User;
}

const UserIcon = () => <Icon component={UserIconSvg} />;

const UserMenu = memo(({ user }: UserMenuProps) => {
  const { i18n } = useLingui();

  return (
    <Dropdown
      overlay={<UserContextMenu text={i18n._(t`Ingelogd als: ${user.name}`)} />}
      placement="bottom"
    >
      <Avatar shape="square" size="large" icon={<UserIcon />} />
    </Dropdown>
  );
});

export default UserMenu;
