import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { saveAs } from "file-saver";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import CampaignsFilterModel from "../../models/CampaignsFilterModel";
import Counter from "../../models/Counter";
import { analyzedOrdersCell } from "../../store/campaigns/analyzed/cells";
import FetchOrdersFilteredResponse from "../../store/campaigns/FetchOrdersFilteredResponse";
import FilterModel from "../../store/campaigns/FilterModel";
import {
  ExportResponse,
  exportFilterSagaTypes,
  exportFormatsFileExtensions,
} from "../../store/exports/models";
import { HttpStatusCode } from "../../store/fetch";
import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { skoTargetGroupCell } from "../../store/sko/cells";
import { handleFailWithProblem } from "../../utils";
import propertyOf from "../../utils/properties";
import { labelCol, wrapperCol } from "../../views/layout/Form";
import { ExportType } from "../actions/ExportActionButtonProps";
import Filter from "./Filter";

export const advertiserPrefix = "advertiser_";
export const orderPrefix = "order_";

const emptyFilterModel: FilterModel = {
  advertisers: [],
};

const emptyFetchOrdersFilteredResponse: FetchOrdersFilteredResponse = {
  advertisers: [],
};

export const periodField = propertyOf<CampaignsFilterModel>("period");
export const advertisersAndOrdersField = propertyOf<CampaignsFilterModel>(
  "advertisersAndOrders"
);
export const skoTargetGroupIdField =
  propertyOf<CampaignsFilterModel>("skoTargetGroupId");

interface FilterContainerProps {
  counter: Counter | undefined;
}

const emptyOrderIds: string[] = [];

const FilterContainer = memo(({ counter }: FilterContainerProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * The selected filter from the store.
   */
  const {
    selectedFilter: {
      advertiserIds,
      dateFrom,
      dateTo,
      orderIds,
      skoTargetGroupId,
    },
    enableSkoGrps,
  } = useSelector(
    ({
      campaigns: { selectedFilter },
      application: {
        options: { enableSkoGrps: enableSkoGrpsFromStore },
      },
    }: StoreModel) => ({
      selectedFilter,
      enableSkoGrps: Boolean(enableSkoGrpsFromStore),
    })
  );

  const handleLoadFilters = useCallback(
    (period?: [Moment, Moment]) => {
      dispatch({
        type: sagaTypes.campaigns.filters.request,
        payload: {
          period: period ? [period[0], period[1]] : [dateFrom, dateTo],
        },
        onFail: handleFailWithProblem(
          i18n._(t`Er trad een fout op bij het ophalen van de filters.`)
        ),
      });

      if (enableSkoGrps) {
        dispatch(
          skoTargetGroupCell.require({ date: period ? period[0] : dateFrom })
        );
      }
    },
    [dateFrom, dateTo, dispatch, enableSkoGrps, i18n]
  );

  /**
   * Track whether the filters are loading.
   */
  const {
    status: { loading: filtersLoading },
    value: { advertisers: filterAdvertisers } = emptyFilterModel,
  } = useSelector(({ campaigns: { filters } }: StoreModel) => filters);

  /**
   * The filtered advertisers with their orders and suborders.
   */
  const {
    orders: { value: { advertisers } = emptyFetchOrdersFilteredResponse },
    targetGroups,
  } = useSelector(
    ({
      campaigns: { orders },
      skoTargetGroup: { value: skoTargetGroups },
    }: StoreModel) => ({
      orders,
      targetGroups: skoTargetGroups,
    })
  );

  /**
   * Track whether the Excel export is loading.
   */
  const {
    status: { loading: exportExcelLoading },
  } = useSelector(
    ({ exports: { byFilterExcel } }: StoreModel) => byFilterExcel
  );

  /**
   * Track whether the PDF print is loading.
   */
  const {
    status: { loading: exportPdfLoading },
  } = useSelector(({ exports: { byFilterPdf } }: StoreModel) => byFilterPdf);

  const foundOrderIds = useMemo(() => {
    if (!advertisers || advertisers.length === 0) {
      return emptyOrderIds;
    }

    return Array.from(
      new Set(
        advertisers
          .map((a) => a.orders)
          .reduce((o1, o2) => o1.concat(o2))
          .map((o) => o.id)
      )
    );
  }, [advertisers]);

  const handleExport = useCallback(
    /**
     * Handles an export request.
     * @param exportType The type of export. The output of the export depends on this type.
     */
    (exportType: ExportType) => {
      if (!advertisers || Object.values(advertisers).length === 0) {
        return;
      }
      dispatch({
        type: exportFilterSagaTypes[exportType],
        payload: {
          dateFrom,
          dateTo,
          orderIds: foundOrderIds,
          skoTargetGroupId:
            skoTargetGroupId !== -1 ? skoTargetGroupId : undefined,
        },
        onFail: handleFailWithProblem(i18n._(t`Er is iets misgegaan.`)),
        onSuccess: (statusCode?: HttpStatusCode, response?: ExportResponse) => {
          const fileContents = response as Blob;
          const filename = `Export.${exportFormatsFileExtensions[exportType]}`;
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(fileContents, filename);
          } else {
            saveAs(fileContents, filename);
          }
        },
      });
    },
    [
      advertisers,
      dateFrom,
      dateTo,
      dispatch,
      foundOrderIds,
      i18n,
      skoTargetGroupId,
    ]
  );

  /**
   * Handles the submit event.
   */
  const handleFinish = useCallback(
    (values: Store) => {
      const {
        period,
        advertisersAndOrders,
        skoTargetGroupId: selectedSkoTargetGroupId,
      } = values as CampaignsFilterModel;
      const selectedAdvertiserIds = advertisersAndOrders
        ? advertisersAndOrders
            .filter((item) => item.startsWith(advertiserPrefix))
            .map((item) => item.substring(advertiserPrefix.length))
        : [];
      const selectedOrderIds = advertisersAndOrders
        ? advertisersAndOrders
            .filter((item) => item.startsWith(orderPrefix))
            .map((item) => item.substring(orderPrefix.length))
        : [];
      dispatch({ type: "CAMPAIGNS_ANALYZED_CLEAR" });

      // Set the search params.
      setSearchParams({
        advertiserIds: selectedAdvertiserIds,
        dateFrom: period[0].format("YYYY-MM-DD"),
        dateTo: period[1].format("YYYY-MM-DD"),
        orderIds: selectedOrderIds,
      });

      dispatch({
        type: sagaTypes.campaigns.fetchOrdersFiltered.request,
        payload: {
          advertiserIds: selectedAdvertiserIds,
          dateFrom: period[0],
          dateTo: period[1],
          orderIds: selectedOrderIds,
          skoTargetGroupId:
            selectedSkoTargetGroupId === -1
              ? undefined
              : selectedSkoTargetGroupId,
        },
      });
    },
    [dispatch, setSearchParams]
  );

  /**
   * Track whether the Excel export is loading.
   */
  const { loading: analyzeLoading } = useSelector(
    ({ campaigns: { analyzed } }: StoreModel) => analyzed.status
  );

  const handleAnalyzeAll = useCallback(() => {
    if (
      advertisers &&
      advertisers.length > 0 &&
      skoTargetGroupId &&
      skoTargetGroupId > 0
    ) {
      dispatch(
        analyzedOrdersCell.require({
          dateFrom,
          dateTo,
          orderIds: foundOrderIds,
          skoTargetGroupId,
        })
      );
    }
  }, [
    advertisers,
    dateFrom,
    dateTo,
    dispatch,
    foundOrderIds,
    skoTargetGroupId,
  ]);

  useEffect(() => {
    const advertiserIdsFromUrl = searchParams.getAll("advertiserIds") ?? [];
    const dateFromFromUrl = searchParams.get("dateFrom")
      ? moment(searchParams.get("dateFrom"), "YYYY-MM-DD")
      : moment().startOf("month");
    const dateToFromUrl = searchParams.get("dateTo")
      ? moment(searchParams.get("dateTo"), "YYYY-MM-DD")
      : moment().endOf("month");
    const orderIdsFromUrl = searchParams.getAll("orderIds") ?? [];

    // Set form values
    form.setFieldsValue({
      [advertisersAndOrdersField]: advertiserIdsFromUrl
        .map((aid) => `${advertiserPrefix}${aid}`)
        .concat(orderIdsFromUrl.map((oid) => `${orderPrefix}${oid}`)),
      [periodField]: [dateFromFromUrl, dateToFromUrl],
    });

    if (advertiserIdsFromUrl.length === 0 && orderIdsFromUrl.length === 0) {
      dispatch({ type: sagaTypes.campaigns.fetchOrdersFiltered.clear });
      dispatch({ type: "CAMPAIGNS_ANALYZED_CLEAR" });
    } else {
      dispatch({
        type: sagaTypes.campaigns.fetchOrdersFiltered.request,
        payload: {
          advertiserIds: advertiserIdsFromUrl,
          dateFrom: dateFromFromUrl,
          dateTo: dateToFromUrl,
          orderIds: orderIdsFromUrl,
        },
      });
    }
  }, [dispatch, form, searchParams]);

  return (
    <Form
      form={form}
      initialValues={{
        [advertisersAndOrdersField]: advertiserIds
          .map((aid) => `${advertiserPrefix}${aid}`)
          .concat(orderIds.map((oid) => `${orderPrefix}${oid}`)),
        [periodField]: [dateFrom, dateTo],
        [skoTargetGroupIdField]: skoTargetGroupId ?? -1,
      }}
      labelCol={labelCol}
      name="Filter"
      wrapperCol={wrapperCol}
      onFinish={handleFinish}
    >
      <Filter
        advertisers={advertisers}
        targetGroups={targetGroups}
        counter={counter}
        dateFrom={dateFrom}
        dateTo={dateTo}
        exportExcelLoading={exportExcelLoading}
        exportPdfLoading={exportPdfLoading}
        filterAdvertisers={filterAdvertisers}
        filtersLoading={filtersLoading}
        form={form}
        onExport={handleExport}
        onLoadFilters={handleLoadFilters}
        analyzeLoading={analyzeLoading}
        onAnalyze={handleAnalyzeAll}
      />
    </Form>
  );
});

export default FilterContainer;
