import { LockOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { ReactElement, useCallback } from "react";

import {
  passwordIdenticalMessage,
  passwordRegex,
  passwordRegexMessage,
  passwordRequiredMessage,
} from "../../utils/password";
import propertyOf from "../../utils/properties";
import useIsMounted from "../../utils/statefulness";
import LoginButton from "../../views/authentication/LoginButton";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../views/layout/Form";

interface PasswordFormComponentProps {
  name: string;
  onSuccess: (values?: PasswordMinimalValues) => void;
  buttonLabel: string;
  showLoginButton: boolean;
}

export interface PasswordMinimalValues {
  password: string;
  passwordRepeat: string;
}

const fieldPasswordName = propertyOf<PasswordMinimalValues>("password");
const fieldPasswordRepeatName =
  propertyOf<PasswordMinimalValues>("passwordRepeat");

const PasswordForm = ({
  name,
  onSuccess,
  buttonLabel,
  showLoginButton,
}: PasswordFormComponentProps): JSX.Element => {
  const { i18n } = useLingui();
  const [form] = useForm();
  const isMounted = useIsMounted();

  const handleFinish = useCallback(
    (values: PasswordMinimalValues) => {
      if (isMounted.current) {
        onSuccess(values);
        form.resetFields();
      }
    },
    [form, isMounted, onSuccess]
  );

  return (
    <Form
      form={form}
      name={name}
      labelCol={labelCol}
      wrapperCol={wrapperColShallow}
      onFinish={handleFinish}
    >
      <Form.Item
        label={i18n._(t`Nieuw wachtwoord`)}
        name={fieldPasswordName}
        rules={[
          { required: true, message: i18n._(passwordRequiredMessage) },
          { pattern: passwordRegex, message: i18n._(passwordRegexMessage) },
        ]}
      >
        <Input.Password
          autoFocus
          prefix={<LockOutlined />}
          placeholder={i18n._(t`Wachtwoord`)}
        />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Nieuw wachtwoord (opnieuw)`)}
        name={fieldPasswordRepeatName}
        rules={[
          { required: true, message: i18n._(passwordRequiredMessage) },
          ({ getFieldValue }) => ({
            validator: (_rule, value) =>
              value === getFieldValue(fieldPasswordName)
                ? Promise.resolve()
                : Promise.reject(i18n._(passwordIdenticalMessage)),
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={i18n._(t`Wachtwoord (opnieuw)`)}
        />
      </Form.Item>
      <Form.Item shouldUpdate wrapperCol={wrapperColNoLabel}>
        {(): ReactElement => (
          <>
            <Button
              disabled={
                !form.isFieldsTouched(true) ||
                form.getFieldsError().some(({ errors }) => errors.length > 0)
              }
              type="primary"
              htmlType="submit"
            >
              {buttonLabel}
            </Button>
            {showLoginButton && (
              <LoginButton>
                <Trans>Naar het inlogformulier</Trans>
              </LoginButton>
            )}
          </>
        )}
      </Form.Item>
    </Form>
  );
};

export default PasswordForm;
