import { Col, Empty, Row, Space } from "antd";
import classNames from "classnames";
import { find, flow, map } from "lodash/fp";
import moment from "moment/moment";
import React, { ReactNode } from "react";

import { DashboardTile, DashboardTileData } from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import User from "../../models/User";
import { isNonEmptyArray } from "../../utils";
import CollapseList from "../collapselists/CollapseList";
import CollapseListHeader from "../collapselists/CollapseListHeader";
import DashboardTileItem from "./DashboardTileItem";

export interface DashboardTileListProps {
  user: User;
  edit?: boolean;
  tiles: DashboardTile[];
  data: DashboardTileData[];
  extrasLoading: boolean;
  targetGroups: SkoTargetGroup[];
  renderActions: (tile: DashboardTile, data: DashboardTileData) => ReactNode[];
  wizard?: ReactNode;
  onDownload: OnBlobDownloadFunc;
  impressionsEnabled?: boolean;
}

interface CollapseData
  extends Record<string, DashboardTile | DashboardTileData> {
  tile: DashboardTile;
  tileData: DashboardTileData;
}

const DashboardTileList = ({
  user,
  edit,
  tiles,
  data,
  extrasLoading,
  targetGroups,
  wizard,
  renderActions,
  onDownload,
  impressionsEnabled,
}: DashboardTileListProps) => (
  <Space direction="vertical">
    {wizard}
    <CollapseList
      items={
        flow(
          map((tile: DashboardTile) => ({
            tile,
            tileData: find((d: DashboardTileData) => d.id === tile.id)(data),
          }))
        )(tiles) as CollapseData[]
      }
      keyGetter={({ tile }: CollapseData) => tile.id}
      header={({ tile, tileData }) => {
        const title = tileData?.period
          ? `${tile.name} (${moment(tileData.period.from).format(
              "LL"
            )} - ${moment(tileData.period.to).format("LL")})`
          : tile.name;
        return <CollapseListHeader key={tile.id} title={title} />;
      }}
      extra={({ tile, tileData }) => <>{renderActions(tile, tileData)}</>}
      content={({ tile, tileData }) =>
        !tileData || !isNonEmptyArray(tileData.data) ? (
          <div className={classNames({ "dashboard-tile-editing": edit })}>
            <Row gutter={[16, 16]} style={{ overflow: "hidden" }}>
              <Col span={24}>
                <Empty />
              </Col>
            </Row>
          </div>
        ) : (
          <DashboardTileItem
            key={tile.id}
            user={user}
            tile={tile}
            data={tileData}
            extrasLoading={extrasLoading}
            targetGroups={targetGroups}
            onDownload={onDownload}
            edit={edit}
            impressionsEnabled={impressionsEnabled}
          />
        )
      }
      variant="Main"
    />
  </Space>
);

export default DashboardTileList;
