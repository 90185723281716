import { combineReducers } from "redux";

import { asyncReducer } from "../lifecycle";
import { TokenModel } from "../models";
import sagaTypes from "../sagaTypes";
import { AuthenticationResponse } from "./models";

export default combineReducers({
  authenticate: asyncReducer<unknown, AuthenticationResponse>(
    sagaTypes.tokens.authenticate
  ),
  authenticateTwoFactor: asyncReducer<unknown, TokenModel>(
    sagaTypes.tokens.authenticateTwoFactor
  ),
  refresh: asyncReducer<unknown, TokenModel>(sagaTypes.tokens.refresh),
  access: asyncReducer<unknown, TokenModel>(sagaTypes.tokens.access),
});
