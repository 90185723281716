import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useForm } from "antd/lib/form/Form";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldData } from "rc-field-form/lib/interface";
import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  CampaignSubOrderValues,
  emptyCampaignSubOrderValues,
  fieldDaysOfWeekName,
  fieldOperatorName,
  fieldPackageName,
  fieldProductName,
  fieldRemarksName,
  fieldSpotLengthsName,
  fieldSpotsName,
  fieldSubOrderPeriodName,
} from "../../../../models/Campaigns/Requests/models";
import { DayOfWeekAll } from "../../../../models/DayOfWeek";
import { PreferredPosition } from "../../../../models/Spot";
import { getFirstBookingDay } from "../../../../utils/date";
import StepWizard, {
  StepWizardStep,
} from "../../../../views/steppers/StepWizard";
import PreviousNextButtons from "../../../actions/PreviousNextButtons";
import { BlockSelectionItem, toSpot } from "../../../bookspot/models";
import {
  ornPackageCodeGrps,
  ornPackageCodeSpecific,
  stepIcons,
} from "./constants";
import {
  SelectedPreferredPositionDictionary,
  Step,
  SubOrderStepperProps,
} from "./models";
import DetailsStep from "./steps/DetailsStep";
import {
  DetailsStepStoreValues,
  OperatorStepStoreValues,
  ProductStepStoreValues,
  PurchaseOptionsStepStoreValues,
} from "./steps/models";
import OperatorStep from "./steps/OperatorStep";
import ProductStep from "./steps/ProductStep";
import PurchaseOptionsStep from "./steps/PurchaseOptionsStep";
import RemarksStep, { RemarksStepStoreValues } from "./steps/RemarksStep";

const SubOrderStepper: FunctionComponent<SubOrderStepperProps> = ({
  editSubOrder,
  orderPeriod,
  onAddSubOrder,
  onCancel,
  onEditSubOrder,
  operators,
  products,
  packages,
  spotLengthIndices,
}) => {
  const { i18n } = useLingui();

  const [currentStep, setCurrentStep] = useState<Step>(0);
  const [finishedSteps, setFinishedSteps] = useState<Step[]>([]);
  const [editingSubOrder, setEditingSubOrder] =
    useState<CampaignSubOrderValues>(
      editSubOrder || emptyCampaignSubOrderValues
    );

  const [operatorStepForm] = useForm();
  const [productStepForm] = useForm();
  const [detailsStepForm] = useForm();
  const [purchaseOptionsStepForm] = useForm();
  const [remarksStepForm] = useForm();

  useEffect(
    /**
     * If we are editing an existing suborder, the wizard should be set to the last step and all steps set to finished.
     */
    () => {
      if (editSubOrder) {
        setCurrentStep(4);

        operatorStepForm.setFieldsValue(editSubOrder);
        productStepForm.setFieldsValue(editSubOrder);
        detailsStepForm.setFieldsValue(editSubOrder);
        purchaseOptionsStepForm.setFieldsValue(editSubOrder);
        remarksStepForm.setFieldsValue(editSubOrder);

        setFinishedSteps([0, 1, 2, 3, 4]);
      }
    },
    [
      detailsStepForm,
      editSubOrder,
      operatorStepForm,
      productStepForm,
      purchaseOptionsStepForm,
      remarksStepForm,
    ]
  );

  const isStepFinished = useCallback(
    /**
     * Determines whether the specified step is finished.
     * @param current The current step.
     */
    (current: Step) => finishedSteps.some((step) => step === current),
    [finishedSteps]
  );
  const isStepDisabled = useCallback(
    /**
     * Determines whether the specified step is disabled.
     * @param current The current step.
     */
    (current: Step) => !isStepFinished(current),
    [isStepFinished]
  );
  const getStepIcon = useCallback(
    /**
     * Retrieves the relevant icon for the specified step.
     * @param current The current step.
     */
    (current: Step) => stepIcons[current],
    []
  );

  const selectedOperator = useMemo(
    /**
     * Determines the selected operator.
     */
    () => editingSubOrder?.operator,
    [editingSubOrder]
  );
  const selectedPackage = useMemo(
    /**
     * Determines the selected package.
     */
    () => packages.find((p) => p.code === editingSubOrder?.pkg),
    [editingSubOrder, packages]
  );
  const selectedTargetGroup = useMemo(
    /**
     * Determines the selected target group.
     */
    () =>
      selectedPackage?.details?.targetGroups?.find(
        (tg) => tg.id === editingSubOrder?.targetGroup
      ),
    [editingSubOrder, selectedPackage]
  );
  const total = useMemo(
    /**
     * Determines the total spot length.
     */
    () =>
      editingSubOrder?.spotLengths?.reduce(
        (a: number, b: number) => a + b,
        0
      ) ?? 0,
    [editingSubOrder]
  );
  const selectedSpotLengthIndex = useMemo(
    /**
     * Determines the selected spot length index.
     */
    () => spotLengthIndices.find(({ length }) => length === total),
    [spotLengthIndices, total]
  );

  const realGrpPrice = useMemo(
    /**
     * Determines the real GRP price.
     */
    () =>
      selectedTargetGroup?.costPerGrp &&
      selectedTargetGroup.costPerGrp > 0 &&
      selectedSpotLengthIndex?.index &&
      selectedSpotLengthIndex.index > 0
        ? selectedTargetGroup.costPerGrp * (selectedSpotLengthIndex.index / 100)
        : 0,
    [selectedSpotLengthIndex, selectedTargetGroup]
  );

  const handleStepsChange = useCallback(
    /**
     * Handles a change of the current step.
     * @param current The new current step.
     */
    (current: number) => {
      if (finishedSteps.some((step) => step === current)) {
        setCurrentStep(current as Step);
      }
    },
    [finishedSteps]
  );

  const handleReset = useCallback(
    /**
     * Resets all artifacts to their default state.
     */
    () => {
      setFinishedSteps([]);
      setCurrentStep(0);
      operatorStepForm.resetFields();
      productStepForm.resetFields();
      detailsStepForm.resetFields();
      purchaseOptionsStepForm.resetFields();
      remarksStepForm.resetFields();
    },
    [
      operatorStepForm,
      productStepForm,
      detailsStepForm,
      purchaseOptionsStepForm,
      remarksStepForm,
    ]
  );

  const handleCancel = useCallback(
    /**
     * Handles cancelling the entire suborder.
     */
    () => {
      handleReset();
      onCancel();
    },
    [handleReset, onCancel]
  );

  const handlePrevious = useCallback(
    /**
     * Handles moving to the previous step.
     */
    () => {
      setCurrentStep((currentStep - 1) as Step);
    },
    [currentStep]
  );

  const handleNext = useCallback(
    /**
     * Handles moving to the next step in the suborder.
     */
    () => {
      switch (currentStep) {
        case 1:
          productStepForm.submit();
          break;
        case 2:
          detailsStepForm.submit();
          break;
        case 3:
          purchaseOptionsStepForm.submit();
          break;
        case 4:
          remarksStepForm.submit();
          break;
        case 0:
        default:
          operatorStepForm.submit();
          break;
      }
    },
    [
      currentStep,
      detailsStepForm,
      operatorStepForm,
      productStepForm,
      purchaseOptionsStepForm,
      remarksStepForm,
    ]
  );

  const breakSelectionRequired = useMemo(
    /**
     * Determines whether break selection is required for this suborder.
     */
    () => Boolean(selectedPackage?.details?.breakSelectionInd),
    [selectedPackage]
  );

  const grpsRequired = useMemo(
    /**
     * Determines whether GRPs are required.
     */
    () =>
      currentStep === 3 &&
      !breakSelectionRequired &&
      (selectedPackage?.operator !== "Orn" ||
        (selectedPackage?.operator === "Orn" &&
          selectedPackage?.code === ornPackageCodeGrps)),
    [breakSelectionRequired, currentStep, selectedPackage]
  );

  const grpsVisible = useMemo(
    /**
     * Determines whether GRPs should be visible.
     */
    () =>
      !breakSelectionRequired &&
      (selectedPackage?.operator !== "Orn" ||
        (selectedPackage?.operator === "Orn" &&
          selectedPackage?.code === ornPackageCodeGrps)),
    [breakSelectionRequired, selectedPackage]
  );

  const budgetRequired = useMemo(
    /**
     * Determines whether the budget is required.
     */
    () =>
      currentStep === 3 &&
      !breakSelectionRequired &&
      (selectedPackage?.operator !== "Orn" ||
        (selectedPackage?.operator === "Orn" &&
          selectedPackage?.code === ornPackageCodeSpecific)),
    [breakSelectionRequired, currentStep, selectedPackage]
  );

  const budgetVisible = useMemo(
    /**
     * Determines whether the budget is visible.
     */
    () =>
      !breakSelectionRequired &&
      (selectedPackage?.operator !== "Orn" ||
        (selectedPackage?.operator === "Orn" &&
          selectedPackage?.code === ornPackageCodeSpecific)),
    [breakSelectionRequired, selectedPackage]
  );

  const enablePreferredPositionSelection = useMemo(
    /**
     * Determines whether preferred position selection is enabled.
     */
    (): boolean =>
      selectedPackage?.details?.vkpInd === undefined ||
      selectedPackage?.details?.vkpInd,
    [selectedPackage]
  );

  const preferredPositions = useMemo(
    /**
     * Determines the currently selected preferred positions for every selected break.
     */
    (): SelectedPreferredPositionDictionary => {
      if (!editSubOrder) {
        return {};
      }

      const dictionary: SelectedPreferredPositionDictionary = {};
      for (let i = 0; i < editSubOrder.spots.length; i += 1) {
        const spotRequest = editSubOrder.spots[i];
        const { preferredPosition } = spotRequest;
        if (preferredPosition) {
          dictionary[spotRequest.uniqueId] = preferredPosition
            .split(",")
            .filter((s) => s && s !== " ")
            .map((s) => s.trim() as PreferredPosition);
        }
      }
      return dictionary;
    },
    [editSubOrder]
  );

  const spotLengths = useMemo(
    /**
     * Determines the selected spot lengths.
     */
    (): number[] => editingSubOrder?.spotLengths || [],
    [editingSubOrder]
  );

  const stepNext = useCallback(
    /**
     * Moves to the next step.
     */
    () => {
      setFinishedSteps(Array.from(new Set([...finishedSteps, currentStep])));
      setCurrentStep((currentStep + 1) as Step);
    },
    [currentStep, finishedSteps]
  );

  const handleOperatorStepFinish = useCallback(
    /**
     * Handles finishing the operator selection step.
     * @param values The form values.
     */
    (values: OperatorStepStoreValues) => {
      setEditingSubOrder({ ...editingSubOrder, ...values });
      stepNext();
    },
    [editingSubOrder, stepNext]
  );

  const handleProductStepChange = useCallback(
    /**
     * Handles a change in the product step.
     * @param changedFields The changed fields.
     */
    (changedFields: FieldData[]) => {
      const productField = changedFields.find(
        ({ name }) =>
          Array.isArray(name) &&
          (name as string[]).length > 0 &&
          (name as string[])[0] === fieldProductName
      );
      if (productField) {
        purchaseOptionsStepForm.resetFields([fieldSpotsName]);
        setEditingSubOrder({ ...editingSubOrder, spots: [] });
      }
    },
    [editingSubOrder, purchaseOptionsStepForm]
  );

  const handleProductStepFinish = useCallback(
    /**
     * Handles finishing the product selection step.
     * @param values The form values.
     */
    (values: ProductStepStoreValues) => {
      setEditingSubOrder({ ...editingSubOrder, ...values });
      stepNext();
    },
    [editingSubOrder, stepNext]
  );

  const handleDetailsStepChange = useCallback(
    /**
     * Handles a change in the suborder details step.
     * @param changedFields The changed fields.
     */
    (changedFields: FieldData[]) => {
      const packageField = changedFields.find(
        ({ name }) =>
          Array.isArray(name) &&
          (name as string[]).length > 0 &&
          (name as string[])[0] === fieldPackageName
      );
      if (packageField) {
        purchaseOptionsStepForm.resetFields([fieldSpotsName]);
        setEditingSubOrder({ ...editingSubOrder, spots: [] });
      }
      const periodField = changedFields.find(
        ({ name }) =>
          Array.isArray(name) &&
          (name as string[]).length > 0 &&
          (name as string[])[0] === fieldSubOrderPeriodName
      );
      if (periodField) {
        purchaseOptionsStepForm.resetFields([fieldSpotsName]);
        setEditingSubOrder({ ...editingSubOrder, spots: [] });
      }
    },
    [editingSubOrder, purchaseOptionsStepForm]
  );

  const handleBlockSelection = useCallback(
    /**
     * Handles the selection of break blocks.
     * @param selection The new selection.
     */
    (selection: BlockSelectionItem[]) => {
      setEditingSubOrder({
        ...editingSubOrder,
        spots: selection.map(toSpot),
      });
    },
    [editingSubOrder]
  );

  const handleDetailsStepFinish = useCallback(
    /**
     * Handles finishing the Details step.
     * @param values The values for the Details step.
     */
    (values: DetailsStepStoreValues) => {
      setEditingSubOrder({ ...editingSubOrder, ...values });
      stepNext();
    },
    [editingSubOrder, stepNext]
  );

  const handlePurchaseOptionsStepFinish = useCallback(
    /**
     * Handles finishing the purchase options step.
     * @param values The values for the purchase options step.
     */
    (values: PurchaseOptionsStepStoreValues) => {
      setEditingSubOrder({ ...editingSubOrder, ...values });
      stepNext();
    },
    [editingSubOrder, stepNext]
  );

  const handleRemarksStepFinish = useCallback(
    /**
     * Handles finishing the remarks step.
     * @param values The values for the remarks step.
     */
    (values: RemarksStepStoreValues) => {
      if (editSubOrder) {
        onEditSubOrder({ ...editingSubOrder, ...values });
      } else {
        onAddSubOrder({ ...editingSubOrder, ...values });
      }
      handleReset();
    },
    [editSubOrder, editingSubOrder, handleReset, onAddSubOrder, onEditSubOrder]
  );

  const firstBookingDay = getFirstBookingDay();

  const steps = useMemo(
    /**
     * The collection of steps for composing an order request suborder.
     */
    (): PropsWithChildren<StepWizardStep>[] => [
      {
        key: "Operator",
        disabled: isStepDisabled(0),
        icon: getStepIcon(0),
        title: i18n._(t`Exploitant`),
        children: (
          <OperatorStep
            callbacks={{ onReset: handleReset }}
            enabled={currentStep === 0}
            form={operatorStepForm}
            initialValues={{ [fieldOperatorName]: editingSubOrder?.operator }}
            onFinish={handleOperatorStepFinish}
            referenceValues={{
              operators,
              packages,
              products,
            }}
          />
        ),
      },
      {
        key: "Product",
        disabled: isStepDisabled(1),
        icon: getStepIcon(1),
        title: i18n._(t`Product`),
        children: (
          <ProductStep
            enabled={currentStep === 1}
            form={productStepForm}
            initialValues={{ [fieldProductName]: undefined }}
            onFieldsChange={handleProductStepChange}
            onFinish={handleProductStepFinish}
            referenceValues={{
              products,
              selectedOperator,
            }}
          />
        ),
      },
      {
        key: "Details",
        disabled: isStepDisabled(2),
        icon: getStepIcon(2),
        title: i18n._(t`Details`),
        children: (
          <DetailsStep
            enabled={currentStep === 2}
            form={detailsStepForm}
            initialValues={{
              [fieldSubOrderPeriodName]: [
                orderPeriod &&
                orderPeriod[0] &&
                orderPeriod[0] < firstBookingDay
                  ? firstBookingDay
                  : orderPeriod && orderPeriod[0],
                orderPeriod &&
                orderPeriod[1] &&
                orderPeriod[1] < firstBookingDay
                  ? firstBookingDay
                  : orderPeriod && orderPeriod[1],
              ],
              [fieldDaysOfWeekName]: DayOfWeekAll,
              [fieldSpotLengthsName]: [undefined],
            }}
            onFieldsChange={handleDetailsStepChange}
            onFinish={handleDetailsStepFinish}
            referenceValues={{
              orderPeriod,
              packages,
              selectedOperator,
              spotLengthIndices,
            }}
          />
        ),
      },
      {
        key: "PurchaseOptions",
        disabled: isStepDisabled(3),
        icon: getStepIcon(3),
        title: i18n._(t`Inkoopopties`),
        children: (
          <PurchaseOptionsStep
            callbacks={{
              onBlockSelection: handleBlockSelection,
            }}
            enabled={currentStep === 3}
            form={purchaseOptionsStepForm}
            initialValues={{
              budget: editingSubOrder?.budget,
              grps: editingSubOrder?.grps,
              selectedBlocks: editingSubOrder?.spots || [],
            }}
            onFinish={handlePurchaseOptionsStepFinish}
            referenceValues={{
              breakSelectionRequired,
              budgetRequired,
              budgetVisible,
              enablePreferredPositionSelection,
              grpsRequired,
              grpsVisible,
              preferredPositions,
              realGrpPrice,
              selectedOperator,
              selectedPackageCode: selectedPackage?.code,
              selectedPeriod: editingSubOrder?.subOrderPeriod,
              selectedProductId: editingSubOrder?.product,
              selectedTargetGroup,
              spotLengths,
            }}
          />
        ),
      },
      {
        key: "Ready",
        disabled: isStepDisabled(4),
        icon: getStepIcon(4),
        title: i18n._(t`Opmerkingen`),
        children: (
          <RemarksStep
            enabled={currentStep === 4}
            form={remarksStepForm}
            initialValues={{
              [fieldRemarksName]: editingSubOrder?.remarks,
            }}
            onFinish={handleRemarksStepFinish}
            referenceValues={{}}
          />
        ),
      },
    ],
    [
      isStepDisabled,
      getStepIcon,
      i18n,
      handleReset,
      currentStep,
      operatorStepForm,
      editingSubOrder,
      handleOperatorStepFinish,
      operators,
      packages,
      products,
      productStepForm,
      handleProductStepChange,
      handleProductStepFinish,
      selectedOperator,
      detailsStepForm,
      orderPeriod,
      firstBookingDay,
      handleDetailsStepChange,
      handleDetailsStepFinish,
      spotLengthIndices,
      handleBlockSelection,
      purchaseOptionsStepForm,
      handlePurchaseOptionsStepFinish,
      breakSelectionRequired,
      budgetRequired,
      budgetVisible,
      enablePreferredPositionSelection,
      grpsRequired,
      grpsVisible,
      preferredPositions,
      realGrpPrice,
      selectedPackage,
      selectedTargetGroup,
      spotLengths,
      remarksStepForm,
      handleRemarksStepFinish,
    ]
  );

  return (
    <StepWizard
      current={currentStep}
      onChange={handleStepsChange}
      orientation="vertical"
      steps={steps}
      buttons={
        <PreviousNextButtons
          showCancel
          showPrevious={currentStep > 0}
          showNext
          onCancel={handleCancel}
          onNext={handleNext}
          onPrevious={handlePrevious}
          nextIsSubmit={false}
          textPrevious={
            currentStep === 4
              ? i18n._(t`Aanvraag bewerken`)
              : i18n._(t`Vorige stap`)
          }
          textNext={
            currentStep === 4
              ? i18n._(t`Aanvraag afronden`)
              : i18n._(t`Volgende stap`)
          }
          tooltipPrevious={
            currentStep === 4
              ? i18n._(
                  t`Klik hierna op 'vorige stap' of selecteer een van de menu's aan de linkerkant om de aanvraag te bewerken.`
                )
              : undefined
          }
        />
      }
    />
  );
};

export default memo(SubOrderStepper);
