import { useLingui } from "@lingui/react";
import { List } from "antd";
import React from "react";

import { IndividualHealthCheckResponse } from "../../store/health/models";
import HealthStatusIcon from "./HealthStatusIcon";
import { healthLabels } from "./texts";

const HealthCheckList = ({
  checks,
}: {
  checks: IndividualHealthCheckResponse[];
}): JSX.Element => {
  const { i18n } = useLingui();
  return (
    <List
      dataSource={checks}
      renderItem={(check) => (
        <List.Item key={check.component}>
          <List.Item.Meta
            avatar={<HealthStatusIcon healthy={check.status === "Healthy"} />}
            title={
              check.status !== "Healthy" && check.description ? (
                <strong>
                  {i18n._(healthLabels[check.component])}: {check.description}
                </strong>
              ) : (
                <>
                  {i18n._(healthLabels[check.component])}
                  {check.description ? <>: {check.description}</> : null}
                </>
              )
            }
          />
        </List.Item>
      )}
    />
  );
};

export default HealthCheckList;
