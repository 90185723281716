import { Typography } from "antd";
import React, { ReactElement } from "react";

import { UserRoleName, roleDisplayNames } from "../../../../models/UserRole";

interface RoleViewProps {
  role: UserRoleName;
}

const RoleView = ({ role }: RoleViewProps): ReactElement => (
  <Typography.Text key="roleText">{roleDisplayNames[role]}</Typography.Text>
);

export default RoleView;
