import { createActor, createSparse } from "async-lifecycle-saga";

import { StoreModel } from "../models";
import {
  channelsGetter,
  commercialsGetter,
  instructionsGetter,
  instructionsPoster,
  instructionsSubmitter,
} from "./api";

export const previousInstructionsCell = createSparse({
  path: ["instructions", "previous"],
  require: { api: instructionsGetter },
  context: ({
    campaigns: {
      selectedFilter: { dateFrom, dateTo },
    },
  }: StoreModel) => ({
    dateFrom: dateFrom.clone().subtract(1, "month"),
    dateTo: dateTo.clone().subtract(1, "month"),
  }),
});

export const instructionsCell = createSparse({
  path: ["instructions", "instructions"],
  require: { api: instructionsGetter },
  update: {
    api: instructionsPoster,
    anticipate: (value) => value.value,
  },
  context: ({ campaigns: { selectedFilter } }: StoreModel) => selectedFilter,
});

export const instructionsSubmitCell = createActor({
  path: ["instructions", "submit"],
  api: instructionsSubmitter,
  context: ({ campaigns: { selectedFilter } }: StoreModel) => selectedFilter,
});

export const commercialsCell = createSparse({
  path: ["instructions", "commercials"],
  require: { api: commercialsGetter },
  context: ({ campaigns: { selectedFilter } }: StoreModel) => selectedFilter,
});

export const channelsCell = createSparse({
  path: ["instructions", "channels"],
  require: { api: channelsGetter },
  context: ({ campaigns: { selectedFilter } }: StoreModel) => selectedFilter,
});
