import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Space } from "antd";
import React, { MouseEvent, useCallback } from "react";
import { useSelector } from "react-redux";

import { DashboardTile, DashboardTileData } from "../../models/Dashboard";
import { StoreModel } from "../../store/models";

export interface ShowSpotsButtonProps {
  tile: DashboardTile;
  data: DashboardTileData;
  onOpen: (tile: DashboardTile, data: DashboardTileData) => void;
  onExport: (tile: DashboardTile, data: DashboardTileData) => void;
}

const ShowSpotsButton = ({
  tile,
  data,
  onOpen,
  onExport,
}: ShowSpotsButtonProps) => {
  const { i18n } = useLingui();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onOpen(tile, data);
    },
    [data, onOpen, tile]
  );

  const handleExport = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onExport(tile, data);
    },
    [data, onExport, tile]
  );

  const exportByAdvertiserExcel = useSelector(
    ({ exports: { byAdvertiserExcel } }: StoreModel) => byAdvertiserExcel
  );

  return (
    <Space>
      <Button type="link" onClick={handleClick}>
        {i18n._(t`Toon uitzendschema`)}
      </Button>
      <Button
        type="link"
        onClick={handleExport}
        loading={exportByAdvertiserExcel[tile.id]?.status.loading}
        disabled={exportByAdvertiserExcel[tile.id]?.status.loading}
      >
        {i18n._(t`Export uitzendschema`)}
      </Button>
    </Space>
  );
};

export default ShowSpotsButton;
