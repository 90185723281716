import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Divider, Steps } from "antd";
import React, { memo } from "react";

interface ActivationStepsProps {
  currentStep?: number;
}

const ActivationSteps = memo(({ currentStep }: ActivationStepsProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Steps current={currentStep} size="small">
        <Steps.Step title={i18n._(t`E-mailadres verifiëren`)} />
        <Steps.Step title={i18n._(t`Wachtwoord instellen`)} />
        <Steps.Step title={i18n._(t`2-Staps authenticatie`)} />
        <Steps.Step title={i18n._(t`Klaar voor gebruik`)} />
      </Steps>
      <Divider />
    </>
  );
});

export default ActivationSteps;
