import React, { ReactNode, memo } from "react";
import { Link } from "react-router-dom";

import navigationPaths from "../../utils/navigation";

interface LoginButtonProps {
  children?: ReactNode;
}

const LoginButton = memo(({ children }: LoginButtonProps) => (
  <span className="login-button">
    <Link to={navigationPaths.Login}>{children}</Link>
  </span>
));

export default LoginButton;
