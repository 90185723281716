import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { message } from "antd";
import moment, { Moment } from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CommercialInstruction,
  emptyRotations,
} from "../../models/Instructions";
import { Operator, getOperatorFromId } from "../../models/Operator";
import Order from "../../models/Order";
import { plannerRoleName } from "../../models/UserRole";
import {
  instructionsCell,
  instructionsSubmitCell,
} from "../../store/instructions/cells";
import { CommercialInstructions } from "../../store/instructions/models";
import { StoreModel } from "../../store/models";
import { handleAsyncFailWithProblem, hasRole } from "../../utils";
import { DatePeriod, getAvailablePeriods, toPeriod } from "../../utils/date";
import InstructionsModal from "../../views/instructions/InstructionsModal";
import InstructionTableContainer from "./InstructionTableContainer";
import RotationsTableContainer from "./RotationsTableContainer";

export interface InstructionsModalContainerProps {
  operator: Operator;
  instructionsId: string;
  instruction: CommercialInstruction;
  order: Order;
  minStartDate: Moment;
  availablePeriods: DatePeriod[];
}

export type InstructionEditMode = "None" | "Instruction" | "Rotation" | "Tagon";

const InstructionsModalContainer = ({
  operator,
  instructionsId,
  instruction,
  order,
  minStartDate,
  availablePeriods,
}: InstructionsModalContainerProps): JSX.Element => {
  const { i18n } = useLingui();
  const [show, setShow] = useState(false);
  const openDialog = useCallback(() => setShow(true), []);
  const closeDialog = useCallback(() => setShow(false), []);

  const canEdit = useMemo(
    () => instruction.status === "Concept",
    [instruction.status]
  );

  const dispatch = useDispatch();
  const deleteInstruction = useCallback(() => {
    if (canEdit && instructionsId) {
      /**
       * Delete action is actually a variant of the update
       * POST-action; because extra data is needed to delete
       * the correct instruction from the list
       */
      dispatch(
        instructionsCell.update(
          {
            id: instructionsId,
            internalId: instruction.id,
          } as CommercialInstructions,
          {
            onSuccess: () => {
              message.success(i18n._(t`Instructie is verwijderd`));
            },

            onFail: () => {
              message.error(
                i18n._(
                  t`Er ging iets mis met het verwijderen van de instructie.`
                )
              );
            },
          }
        )
      );
    }
  }, [canEdit, dispatch, i18n, instruction, instructionsId]);

  const spotLengths = useMemo(() => {
    const main = [...new Set(order.subOrders.map((s) => s.spotLengths[0]))];
    const tagons = [
      ...new Set(
        order.subOrders
          .map((s) => (s.spotLengths.length > 1 ? s.spotLengths.slice(1) : []))
          .flat()
      ),
    ];
    return { main, tagons };
  }, [order.subOrders]);

  const [instructionEditMode, setInstructionEditMode] =
    useState<InstructionEditMode>("None");

  const submitInstruction = useCallback(() => {
    if (!instructionsId || !instruction.id || !instruction.orderId) {
      return;
    }

    dispatch(
      instructionsSubmitCell.require(
        { instructionsId, internalId: instruction.id },
        {
          onSuccess: () => {
            // refresh all instruction-data
            dispatch({ type: instructionsCell.events.reset });
            dispatch(instructionsCell.require(instructionsId));

            message.success(
              i18n._(
                t`Instructie ingediend bij ${getOperatorFromId(
                  instruction.orderId as string
                )}.`
              )
            );
          },

          onFail: handleAsyncFailWithProblem(
            i18n._(t`Er ging iets mis met het indienen van de instructie.`)
          ),
        }
      )
    );
  }, [dispatch, i18n, instruction.id, instruction.orderId, instructionsId]);

  const { value: user } = useSelector(
    ({ users: { current } }: StoreModel) => current
  );
  const isPlanner = useMemo(() => hasRole(plannerRoleName)(user), [user]);

  const rotationPeriods = useMemo(
    () =>
      (instruction.rotations ?? emptyRotations).map((i) =>
        toPeriod(i.startDate, i.endDate)
      ),
    [instruction.rotations]
  );

  const availableRotationPeriods = getAvailablePeriods(
    { from: moment(instruction.startDate), to: moment(instruction.endDate) },
    rotationPeriods
  );

  const subOrder = useMemo(
    () => order.subOrders.find((s) => s.id === instruction.subOrderId),
    [instruction.subOrderId, order.subOrders]
  );

  const canSubmit = useMemo(
    () =>
      instruction.rotations.length > 0 &&
      instruction.rotations.every(
        ({ commercial, tagons }) =>
          // every rotation needs a commercial
          commercial?.code &&
          // every optional tagon needs a commercials
          tagons.every((tagon) => tagon.commercial?.code)
      ) &&
      // instruction period is covered by rotations
      availableRotationPeriods.length === 0 &&
      (!subOrder ||
        (subOrder &&
          subOrder.spotLengths.length > 1 &&
          instruction.rotations.every((r) => r.tagons.length > 0))),
    [availableRotationPeriods.length, instruction.rotations, subOrder]
  );

  return (
    <InstructionsModal
      instruction={instruction}
      order={order}
      show={show}
      canEdit={canEdit}
      isPlanner={isPlanner}
      openDialog={openDialog}
      closeDialog={closeDialog}
      deleteInstruction={deleteInstruction}
      submitInstruction={submitInstruction}
      canSubmit={canSubmit}
    >
      <InstructionTableContainer
        operator={operator}
        instructionsId={instructionsId}
        instructions={instruction ? [instruction] : []}
        order={order}
        canEdit={canEdit}
        onEdit={setInstructionEditMode}
        instructionEditMode={instructionEditMode}
        isPlanner={isPlanner}
        minStartDate={minStartDate}
        availablePeriods={availablePeriods}
      />

      <RotationsTableContainer
        operator={operator}
        instructionsId={instructionsId}
        spotLengths={spotLengths}
        instruction={instruction}
        canEdit={instruction.status === "Concept"}
        onEdit={setInstructionEditMode}
        instructionEditMode={instructionEditMode}
      />
    </InstructionsModal>
  );
};

export default memo(InstructionsModalContainer);
