import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, { FunctionComponent, memo, useCallback } from "react";

import { fieldRemarksName } from "../../../../../models/Campaigns/Requests/models";
import { labelCol, wrapperCol } from "../../../../../views/layout/Form";
import { StepProps } from "./models";

export interface RemarksStepStoreValues {
  remarks?: string;
}

const RemarksStep: FunctionComponent<
  StepProps<undefined, unknown, RemarksStepStoreValues>
> = ({ enabled, form, initialValues, onFieldsChange, onFinish }) => {
  const { i18n } = useLingui();

  const handleFinish = useCallback(
    /**
     * Handles a finish of the form.
     * @param values The values of the form.
     */
    (values: Store) => {
      onFinish(values as RemarksStepStoreValues);
    },
    [onFinish]
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFieldsChange={onFieldsChange}
      onFinish={handleFinish}
    >
      <Form.Item
        label={i18n._(t`Opmerkingen`)}
        labelCol={labelCol}
        name={fieldRemarksName}
        wrapperCol={wrapperCol}
      >
        <Input.TextArea rows={5} disabled={!enabled} />
      </Form.Item>
    </Form>
  );
};

export default memo(RemarksStep);
