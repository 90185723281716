import classNames from "classnames";
import React from "react";

import { formatNumber } from "./utils";

const Euro = ({
  amount,
  fractionDigits = 2,
  inline = false,
}: {
  amount: number;
  fractionDigits?: number;
  inline?: boolean;
}): JSX.Element => (
  <span className={classNames("euro", { "euro-inline": inline })}>
    {formatNumber(amount, fractionDigits, "nl")}
  </span>
);

export default Euro;
