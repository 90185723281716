import { AsyncResponse } from "async-lifecycle-saga";
import { Id } from "async-lifecycle-saga/dist/models";
import moment from "moment";

import {
  CommercialInstruction,
  emptyInstruction,
} from "../../models/Instructions";
import { getPeriodQueryString } from "../../utils/date";
import { getter, poster } from "../fetch";
import {
  ChannelRequestContext,
  Channels,
  CommercialInstructions,
  CommercialInstructionsRequestContext,
  CommercialRequestContext,
  Commercials,
} from "./models";

export const instructionsGetter = (
  { id }: Id<string>,
  { dateFrom, dateTo }: CommercialInstructionsRequestContext
): Promise<AsyncResponse<CommercialInstructions>> =>
  getter<CommercialInstruction[], CommercialInstructions>(
    `/api/instructions/${id}?${getPeriodQueryString(dateFrom, dateTo)}`
  );

/**
 * This `instructionsPoster` method is for create/update and delete of a specific instruction
 * To create/update the `instruction.instructions` need te be filled with one instruction
 * To delete the an instruction, you need te set the `instruction.internalId` property
 */
export const instructionsPoster = (
  instruction: CommercialInstructions,
  { dateFrom, dateTo }: CommercialInstructionsRequestContext
): Promise<AsyncResponse<CommercialInstructions>> => {
  if (
    (!instruction.instructions || !instruction.instructions[0]) &&
    !instruction.internalId
  ) {
    Promise.reject(new Error("Incorrect data"));
  }

  const uri = `/api/instructions/${instruction.id}?${getPeriodQueryString(
    dateFrom,
    dateTo
  )}`;

  return poster<CommercialInstructions, CommercialInstruction>(
    instruction.internalId
      ? `${uri}&internalId=${instruction.internalId}`
      : uri,
    (instruction.instructions?.[0] as CommercialInstruction) ?? emptyInstruction
  );
};

export const instructionsSubmitter = (
  {
    instructionsId,
    internalId,
  }: { instructionsId: string; internalId: string },
  { dateFrom, dateTo }: CommercialInstructionsRequestContext
): Promise<AsyncResponse<CommercialInstructions>> =>
  poster<CommercialInstructions, undefined>(
    `/api/instructions/${instructionsId}/submit?${getPeriodQueryString(
      dateFrom,
      dateTo
    )}&internalId=${internalId}`,
    null
  );

export const commercialsGetter = (
  { id }: Id<string>,
  { dateFrom, dateTo }: CommercialRequestContext
): Promise<AsyncResponse<Commercials>> =>
  getter<Commercials>(
    `/api/instructions/commercials/${id}?${getPeriodQueryString(
      moment(dateFrom),
      moment(dateTo)
    )}`
  );

export const channelsGetter = (
  { id: orderId }: Id<string>,
  { dateFrom, dateTo }: ChannelRequestContext
): Promise<AsyncResponse<Channels>> =>
  getter<Channels>(
    `/api/instructions/channels/${orderId}?${getPeriodQueryString(
      moment(dateFrom),
      moment(dateTo)
    )}`
  );
