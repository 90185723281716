import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input, Select } from "antd";
import React, { memo, useCallback, useEffect } from "react";

import {
  fieldTargetGroupIntomartName,
  fieldTargetGroupName,
} from "../../../../../models/Campaigns/Requests/models";
import Euro from "../../../../../views/Euro";
import { labelCol, wrapperColShallow } from "../../../../../views/layout/Form";
import { TargetGroupPickerProps } from "../models";

const TargetGroupPicker = memo(
  ({
    enabled,
    form: { setFieldsValue, resetFields },
    selectedPackage,
  }: TargetGroupPickerProps) => {
    const { i18n } = useLingui();

    const handleSelect = useCallback(
      (value: string) => {
        if (selectedPackage?.details?.targetGroups) {
          const targetGroupId = value;
          const targetGroup = selectedPackage.details.targetGroups.find(
            ({ id }) => id === targetGroupId
          );
          if (targetGroup) {
            setFieldsValue({
              [fieldTargetGroupIntomartName]: targetGroup.intomartId,
            });
          } else {
            setFieldsValue({
              [fieldTargetGroupIntomartName]: null,
            });
          }
        }
      },
      [selectedPackage, setFieldsValue]
    );

    // only 1 targetgroup for the selected package => select it!
    useEffect(() => {
      if (
        selectedPackage &&
        selectedPackage.details?.targetGroups.length === 1
      ) {
        const selectedTargetGroup = selectedPackage.details?.targetGroups[0];
        setFieldsValue({
          [fieldTargetGroupName]: selectedTargetGroup.id,
          [fieldTargetGroupIntomartName]: selectedTargetGroup?.intomartId,
        });
      }

      if (!selectedPackage) {
        resetFields([fieldTargetGroupName, fieldTargetGroupIntomartName]);
      }
    }, [resetFields, selectedPackage, setFieldsValue]);

    return (
      <>
        <Form.Item name={fieldTargetGroupIntomartName} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label={i18n._(t`Doelgroep`)}
          labelCol={labelCol}
          name={fieldTargetGroupName}
          rules={[
            {
              required: true,
              message: i18n._(t`Doelgroep is verplicht.`),
            },
          ]}
          wrapperCol={wrapperColShallow}
        >
          <Select
            showSearch
            optionFilterProp="title"
            disabled={!enabled || !selectedPackage}
            onChange={handleSelect}
          >
            {selectedPackage
              ? selectedPackage.details?.targetGroups.map((targetGroup) => (
                  <Select.Option
                    key={targetGroup.id}
                    value={targetGroup.id}
                    title={targetGroup.description ?? targetGroup.id}
                  >
                    {targetGroup.description ?? targetGroup.intomartId}
                    {targetGroup.costPerGrp !== undefined &&
                      targetGroup.costPerGrp !== 0 && (
                        <>
                          {" "}
                          (
                          <Trans>
                            GRP prijs:{" "}
                            <Euro amount={targetGroup.costPerGrp} inline />
                          </Trans>
                          {targetGroup.targetGroupIndex && (
                            <>
                              ;{" "}
                              <Trans>
                                Index: {targetGroup.targetGroupIndex}
                              </Trans>
                            </>
                          )}
                          )
                        </>
                      )}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>
      </>
    );
  }
);

export default TargetGroupPicker;
