import { createForm } from "async-lifecycle-saga";
import { AsyncRefresh } from "async-lifecycle-saga/dist/models";
import { refreshDefault } from "async-lifecycle-saga/dist/workers";

import { skoTargetGroupGetter } from "./api";

// eslint-disable-next-line import/prefer-default-export
export const skoTargetGroupCell = createForm({
  path: ["skoTargetGroup"],
  api: skoTargetGroupGetter,
  onRefresh: (action, state, lastStarted): AsyncRefresh =>
    action.payload.date?.isSame(lastStarted?.payload.date)
      ? refreshDefault(() => state)(action, state)
      : "keep",
});
