export const arraysAreEqual = <T>(
  array1: Array<T>,
  array2: Array<T>
): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  const array1sorted = array1.sort();
  const array2sorted = array2.sort();
  for (let i = 0; i < array1sorted.length; i += 1) {
    if (array1sorted[i] !== array2sorted[i]) {
      return false;
    }
  }

  return true;
};

export default arraysAreEqual;
