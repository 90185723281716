import { Row } from "antd";
import React, { ReactNode, memo } from "react";

interface CommercialResultsProps {
  children?: ReactNode;
}

const CommercialResults = memo(({ children }: CommercialResultsProps) => (
  <Row gutter={[16, 16]} className="commercial-results">
    {children}
  </Row>
));

export default CommercialResults;
