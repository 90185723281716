import { combineReducers } from "redux";

import { asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";

export default combineReducers({
  saveResult: asyncReducer<unknown, string>(
    sagaTypes.normalisation.saveAdvertiserGroup
  ),
  deleteResult: asyncReducer<unknown, string>(
    sagaTypes.normalisation.deleteAdvertiserGroup
  ),
});
