import { HTMLProps, ReactNode, createElement, memo } from "react";

interface BigScreenProps {
  children?: ReactNode;
  type: "placeholder" | "paragraph" | "label" | "text";
}

const BigScreen = memo(({ children, type }: BigScreenProps) => {
  const props: HTMLProps<HTMLElement> = {
    className: "tip-bigScreen",
    children,
  };
  let htmlElement = "span";
  switch (type) {
    case "paragraph":
      htmlElement = "p";
      break;
    case "placeholder":
      htmlElement = "div";
      break;
    case "label":
      htmlElement = "label";
      break;
    case "text":
    default:
      htmlElement = "span";
      break;
  }
  return createElement(htmlElement, props);
});

export default BigScreen;
