/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, Space, Tooltip, Typography } from "antd";
import React from "react";

import { DashboardCommercial } from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import DashboardCommercialView from "./DashboardCommercialView";

type CheckboxGroupProps = React.ComponentProps<typeof Checkbox.Group>;

const CommercialSelect = ({
  commercials,
  onDownload,
  value,
  ...rest
}: {
  commercials: DashboardCommercial[];
  onDownload: OnBlobDownloadFunc;
} & CheckboxGroupProps) => (
  <Checkbox.Group value={value} {...rest}>
    <Space direction="horizontal" align="start" wrap>
      {commercials.map((c) => {
        const selected = (value ?? []).includes(c.commercialDeliveryId);
        return (
          <Checkbox
            style={{
              padding: 4,
              border: selected ? "1px solid #DE2A1E" : "1px solid transparent",
              backgroundColor: selected ? "rgba(222, 42, 30, 0.25)" : "unset",
            }}
            key={c.commercialDeliveryId}
            value={c.commercialDeliveryId}
          >
            <div style={{ width: 120, height: 120 }}>
              <DashboardCommercialView
                commercial={c}
                onDownload={onDownload}
                width={120}
                height={68}
              />
              <br />
              <div style={{ marginLeft: -24, marginRight: -12, marginTop: 4 }}>
                <Tooltip title={`${c.title} (${c.lengthInSeconds}")`}>
                  <Typography.Text ellipsis>
                    {c.title} ({c.lengthInSeconds}&quot;)
                  </Typography.Text>
                </Tooltip>
              </div>
            </div>
          </Checkbox>
        );
      })}
    </Space>
  </Checkbox.Group>
);

export default CommercialSelect;
