import { Alert, Statistic } from "antd";
import React, { ReactNode, memo } from "react";

import Euro from "./Euro";

interface DashboardStatisticProps {
  title: string;
  value: number;
}

const DashboardStatistic = memo(({ title, value }: DashboardStatisticProps) => (
  <Alert
    className="tip-dashboardStatistic-alert"
    type="info"
    message={
      <Statistic
        title={title}
        valueRender={(): ReactNode => (
          <Euro amount={value} fractionDigits={0} />
        )}
        className="tip-dashboardStatistic-statistic"
      />
    }
  />
));

export default DashboardStatistic;
