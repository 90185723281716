import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { memo, useCallback } from "react";

import ItemActionButtonProps from "./ItemActionButtonProps";

const EditButton = memo(
  ({
    disabled,
    icon,
    itemId,
    onClick,
    size = "middle",
    type = "link",
    iconOnly,
  }: ItemActionButtonProps) => {
    const handleClick = useCallback(() => {
      if (onClick && itemId) {
        onClick(itemId);
      }
    }, [itemId, onClick]);

    return (
      <Button
        disabled={disabled}
        icon={icon}
        key="editButton"
        onClick={handleClick}
        size={size}
        type={type}
      >
        {!iconOnly && <Trans>Bewerken</Trans>}
      </Button>
    );
  }
);

export default EditButton;
