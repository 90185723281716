import { combineReducers } from "redux";

import { asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";
import {
  AvailableBreaksRequest,
  AvailableBreaksResponse,
  BookSpotRequest,
} from "./models";

export default combineReducers({
  availableBreaks: asyncReducer<
    AvailableBreaksRequest,
    AvailableBreaksResponse
  >(sagaTypes.bookSpot.availableBreaks),
  bookSpot: asyncReducer<BookSpotRequest, unknown>(sagaTypes.bookSpot.bookSpot),
});
