import { I18nProvider } from "@lingui/react";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import nlNL from "antd/lib/locale/nl_NL";
import moment from "moment";
import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useEffect,
  useMemo,
} from "react";

import { i18nSetup } from "../../App";
import { Culture, useCulture } from "../../utils/hooks";

const LocalizationProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  /**
   * The most relevant culture for the current user.
   * Requires `<Provider>` from React-Redux to work.
   */
  const language = useCulture();

  const antLocale = useMemo(
    /**
     * Returns the Ant Design locale that is appropriate for the currently preferred culture.
     */
    () => (language === Culture.enUS ? enUS : nlNL),
    [language]
  );

  useEffect(
    /**
     * Switch locale of 'moment'.
     */
    () => {
      if (language) {
        moment.locale(language);
        i18nSetup.activate(language);
      }
    },
    [language]
  );

  return (
    <ConfigProvider locale={antLocale}>
      <I18nProvider i18n={i18nSetup}>{children}</I18nProvider>
    </ConfigProvider>
  );
};

export default memo(LocalizationProvider);
