import { createActor } from "async-lifecycle-saga";

import { ChangePasswordRequest } from "../../models/User";
import { poster } from "../fetch";
import { ConfirmResetUserPassword } from "./models";

const confirmResetPasswordCell = createActor<ConfirmResetUserPassword, never>({
  api: (request) => poster("/api/user/password/confirm", request, true),
  path: ["global", "confirmPassword"],
});

const changePasswordCell = createActor<ChangePasswordRequest, never>({
  api: (request) => poster("/api/user/password/change", request),
  path: ["global", "changePassword"],
});

const authenticationSagas = [
  ...confirmResetPasswordCell.sagas,
  ...changePasswordCell.sagas,
];

export { authenticationSagas, confirmResetPasswordCell, changePasswordCell };
