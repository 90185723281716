import { t } from "@lingui/macro";

import {
  DetailsStepStoreValues,
  OperatorStepStoreValues,
  ProductStepStoreValues,
} from "../../../components/campaigns/requests/subOrders/steps/models";
import { Break, Channel } from "../../../store/campaigns/requests/models";
import propertyOf from "../../../utils/properties";
import Advertiser from "../../Advertiser";
import { DayOfWeek } from "../../DayOfWeek";
import { Operator } from "../../Operator";
import { Package } from "../../packages";
import { Product } from "../../products";
import { PreferredPosition } from "../../Spot";
import TargetGroup from "../../TargetGroup";

export type SubOrderCostsType = "FixedPrice" | "PricePerGrp";

export interface CampaignSubOrderValues
  extends OperatorStepStoreValues,
    ProductStepStoreValues,
    DetailsStepStoreValues {
  budget?: number;
  costsType?: SubOrderCostsType;
  daysOfWeek?: DayOfWeek[];
  grps?: number;
  intomartId?: string;
  remarks?: string;
  requestStatus: OrderRequestStatus;
  spots: OrderRequestSpot[];
}

export const emptyCampaignSubOrderValues: CampaignSubOrderValues = {
  requestStatus: "Concept",
  spots: [],
};

export interface CampaignValues {
  id: string;
  startDate: string;
  endDate: string;
  advertiserId: string;
  name: string;
  subOrders?: CampaignSubOrderValues[];
}

export const fieldOperatorName = propertyOf<CampaignSubOrderValues>("operator");
export const fieldProductName = propertyOf<CampaignSubOrderValues>("product");
export const fieldSubOrderPeriodName =
  propertyOf<CampaignSubOrderValues>("subOrderPeriod");
export const fieldSpotLengthsName =
  propertyOf<CampaignSubOrderValues>("spotLengths");
export const fieldPackageName = propertyOf<CampaignSubOrderValues>("pkg");
export const fieldTargetGroupName =
  propertyOf<CampaignSubOrderValues>("targetGroup");
export const fieldTargetGroupIntomartName =
  propertyOf<CampaignSubOrderValues>("intomartId");
export const fieldBudgetName = propertyOf<CampaignSubOrderValues>("budget");
export const fieldGrpsName = propertyOf<CampaignSubOrderValues>("grps");
export const fieldCostsTypeName =
  propertyOf<CampaignSubOrderValues>("costsType");
export const fieldDaysOfWeekName =
  propertyOf<CampaignSubOrderValues>("daysOfWeek");
export const fieldRemarksName = propertyOf<CampaignSubOrderValues>("remarks");
export const fieldStatusName =
  propertyOf<CampaignSubOrderValues>("requestStatus");
export const fieldSpotsName = propertyOf<CampaignSubOrderValues>("spots");
export const allSubOrderFields: string[] = [
  fieldOperatorName,
  fieldProductName,
  fieldSubOrderPeriodName,
  fieldSpotLengthsName,
  fieldPackageName,
  fieldTargetGroupName,
  fieldBudgetName,
  fieldGrpsName,
  fieldCostsTypeName,
  fieldRemarksName,
  fieldStatusName,
];

export interface ContactPerson {
  email: string;
  name: string;
}

export interface OrderRequestSpotFinances {
  grossSpotPrice: number;
  netSpotPrice: number;
}

export interface OrderRequestSpot {
  id?: string;
  channel: Channel;
  scheduledDate: Date;
  scheduledStartTime: Date;
  breakId: string;
  predictedRating: number;
  selectivity?: number;
  preferredPosition?: string;
  programBefore: string;
  programAfter: string;
  spotLength?: number;
  requestStatus?: string;
  finances?: OrderRequestSpotFinances;
  uniqueId: string;
}

export const fromBreakWithPreferredPosition = (
  brk: Break,
  preferredPosition: string
): OrderRequestSpot => ({
  breakId: brk.breakId,
  finances: brk.finances,
  id: brk.id,
  channel: brk.channel,
  predictedRating: brk.predictedRating,
  selectivity: brk.selectivity,
  preferredPosition,
  programBefore: brk.programBefore,
  programAfter: brk.programAfter,
  scheduledDate: brk.scheduledDate,
  scheduledStartTime: brk.scheduledStartTime,
  uniqueId: brk.uniqueId,
});

export const emptyOrderRequestSpots: OrderRequestSpot[] = [];

export interface SubOrderRequestLength {
  preferredPosition: PreferredPosition;
  spotLength: number;
}

export interface OrderRequestSubOrder {
  id?: string;
  budget: number;
  daysOfWeek?: DayOfWeek[];
  endDate: string;
  startDate: string;
  grps?: number;
  operator: Operator;
  package: Package;
  product: Product;
  remarks?: string;
  requestStatus: OrderRequestStatus;
  spots: OrderRequestSpot[];
  subOrderRequestLengths: SubOrderRequestLength[];
  targetGroup: TargetGroup;
}

export interface OrderRequest extends Record<string, unknown> {
  id?: string;
  tipId?: string;
  advertiser: Advertiser;
  startDate?: string;
  endDate?: string;
  campaignName: string;
  contactPerson: ContactPerson;
  product?: Product;
  budget: number;
  status?: OrderRequestStatus;
  subOrders: OrderRequestSubOrder[];
}

export type OrderRequestStatus = "Concept" | "Submitted" | "Error" | "Deleted";

export interface OrderRequestsByAdvertiser extends Record<string, unknown> {
  advertiserId: string;
  advertiserName: string;
  orderRequests: OrderRequest[];
}

export const emptyOrderRequestsByAdvertiserItems: OrderRequestsByAdvertiser[] =
  [];

export type PreferredPositionSelectionMode = "Single" | "Multiple";

export const emptyValue = t`n.v.t.`;
