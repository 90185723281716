import moment from "moment";

import { Channel } from "../store/campaigns/requests/models";
import SpotFinances from "./SpotFinances";

export type PreferredPosition =
  | "None"
  | "FirstInBlock"
  | "SecondInBlock"
  | "BeforeLastInBlock"
  | "LastInBlock"
  | "ThirdInBlock"
  | "ThirdLastInBlock";

export const preferredPositionNone: PreferredPosition = "None";
export const preferredPositionFirstInBlock: PreferredPosition = "FirstInBlock";
export const preferredPositionSecondInBlock: PreferredPosition =
  "SecondInBlock";
export const preferredPositionBeforeLastInBlock: PreferredPosition =
  "BeforeLastInBlock";
export const preferredPositionLastInBlock: PreferredPosition = "LastInBlock";
export const preferredPositionThirdInBlock: PreferredPosition = "ThirdInBlock";
export const preferredPositionThirdLastInBlock: PreferredPosition =
  "ThirdLastInBlock";

export const preferredPositionMap: {
  [preferredPosition in PreferredPosition]: string;
} = {
  [preferredPositionNone]: "Geen",
  [preferredPositionFirstInBlock]: "Eerste in blok",
  [preferredPositionSecondInBlock]: "Tweede in blok",
  [preferredPositionBeforeLastInBlock]: "Voor laatste in blok",
  [preferredPositionLastInBlock]: "Laatste in blok",
  [preferredPositionThirdInBlock]: "Derde in blok",
  [preferredPositionThirdLastInBlock]: "Derde laatste in blok",
};

export interface SkoData {
  broadcastDateTime: Date;
  positionInBlock: number;
  totalPositionsInBlock: number;
}

export interface Commercial {
  code: string;
  description: string;
  spotLength: number;
  skoData?: SkoData;
  sequenceNumber?: number;
}

export interface SpotType {
  id: string;
  number?: number;
}

export interface SpotRatingRegio {
  intomartTargetGroupId?: string;
  targetGroupId?: string;
  channelId?: string;
  channelNumber?: string;
  channelDescription?: string;
  actualStartTime?: Date;
  predictedRating?: number;
  achievedRating?: number;
  ratingImportIndicator?: string;
}

export default interface Spot {
  id: string;
  channel: Channel;
  scheduledDate: Date;
  scheduledStartDate: Date;
  actualStartDate?: Date;
  breakId: string;
  spotLength: number;
  programBefore: string;
  programAfter: string;
  preferredPosition: PreferredPosition;
  compositionSeqNr: number;
  commercials: Commercial[];
  predictedRating?: number;
  achievedRating?: number;
  spotType: SpotType;
  cancellationInd: boolean;
  finances?: SpotFinances;
  skoGrp?: number;
  spotRatingRegios?: SpotRatingRegio[];
}

export const getUniqueId = (spot: Spot): string =>
  `${spot.breakId}:${moment(spot.scheduledDate).format("YYYYMMDD")}:${
    spot.channel.description
  }`;
