import { Trans } from "@lingui/macro";
import { Button } from "antd";
import moment, { Moment } from "moment";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deliveryStatusCell } from "../../store/commercials/cells";
import { StoreModel } from "../../store/models";
import MainContent from "../../views/layout/MainContent";
import DeliveryStatusTable from "./DeliveryStatusTable";

const lastCheckKey = "tip-commercialstatus-check";

export const lastCheck = (): Moment =>
  window.localStorage.getItem(lastCheckKey)
    ? moment(window.localStorage.getItem(lastCheckKey))
    : moment().subtract(1, "year").startOf("year");

const DeliveryStatusContainer = memo(() => {
  const dispatch = useDispatch();
  const load = useCallback(() => {
    dispatch(deliveryStatusCell.require());
    window.localStorage.setItem(lastCheckKey, moment().toISOString(true));
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const reload = useCallback(() => {
    dispatch({ type: deliveryStatusCell.events.invalidate });
    load();
  }, [dispatch, load]);

  const { value, status } = useSelector(
    ({ deliveryStatus }: StoreModel) => deliveryStatus
  );

  return (
    <MainContent>
      <Button onClick={reload} type="primary">
        <Trans>Overzicht vernieuwen</Trans>
      </Button>
      <DeliveryStatusTable value={value} status={status} />
    </MainContent>
  );
});

export default DeliveryStatusContainer;
