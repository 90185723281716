declare global {
  interface Document {
    documentMode?: unknown; // IE 6-11 feature
  }
}

const { documentMode } = document as Document;

// eslint-disable-next-line import/prefer-default-export
export const isIE = !!documentMode;
