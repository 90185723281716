import { Collapse } from "antd";
import classnames from "classnames";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

export type KeyGetter<TModel> = (item: TModel) => string | number;
export type CollapseListVariant = "Main" | "Details";

interface CollapseListProps<TModel extends Record<string, unknown>> {
  items: TModel[];
  keyGetter: KeyGetter<TModel>;
  header: FC<TModel>;
  extra: FC<TModel>;
  content: FC<TModel>;
  variant?: CollapseListVariant;
  accordion?: boolean;
}

const variantClasses: { [variant in CollapseListVariant]: string } = {
  Details: "tip-collapseList-details",
  Main: "tip-collapseList-main",
};

const CollapseList = <TModel extends Record<string, unknown>>({
  items,
  keyGetter,
  header,
  extra,
  content,
  variant = "Main",
  accordion,
}: CollapseListProps<TModel>): ReactElement => {
  const className = useMemo(
    (): string =>
      classnames("tip-collapseList", variantClasses[variant || "Main"]),
    [variant]
  );

  const defaultActiveKey = useMemo(() => {
    if (!items || items.length === 0) {
      return undefined;
    }
    if (!accordion) {
      return items.map(keyGetter);
    }
    return [keyGetter(items[0])];
  }, [accordion, items, keyGetter]);

  const [activeKey, setActiveKey] = useState<(string | number)[] | undefined>(
    undefined
  );
  const handleActiveKeyChange = useCallback(
    (key: string | string[]): void => {
      if (accordion || Array.isArray(key)) {
        setActiveKey(Array.isArray(key) ? key : [key]);
      } else {
        setActiveKey((prevActiveKey) => {
          const current = prevActiveKey ?? [];
          const index = current.indexOf(key);
          if (index > -1) {
            return current.filter((k) => k !== key);
          }
          return [...current, key];
        });
      }
    },
    [accordion]
  );

  useEffect(() => {
    if (defaultActiveKey) {
      setActiveKey(defaultActiveKey);
    }
  }, [defaultActiveKey]);

  const isBordered = useMemo((): boolean => variant === "Main", [variant]);

  return (
    <section className={className}>
      {items && items.length > 0 && activeKey && (
        <Collapse
          accordion={accordion}
          bordered={isBordered}
          defaultActiveKey={defaultActiveKey}
          activeKey={activeKey}
          onChange={handleActiveKeyChange}
        >
          {items.map((item) => (
            <Collapse.Panel
              header={header(item)}
              extra={extra(item)}
              key={keyGetter(item)}
            >
              {content(item)}
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </section>
  );
};

export default CollapseList;
