import { Col, Row } from "antd";
import React, { Children, ReactNode, memo } from "react";

interface CampaignsContentProps {
  children?: ReactNode;
}

const CampaignsContent = memo(({ children }: CampaignsContentProps) => (
  <section className="campaignsContent">
    {children &&
      Children.map(children, (child) => (
        <Row>
          <Col span={24}>{child}</Col>
        </Row>
      ))}
  </section>
));

export default CampaignsContent;
