/* eslint-disable @typescript-eslint/no-unused-vars */
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import React, { useEffect } from "react";

interface WarnBeforeUnloadProps {
  active: boolean;
  message: string;
  notificationOptions: ArgsProps;
}

const WarnBeforeUnload = ({
  active,
  message,
  notificationOptions,
}: WarnBeforeUnloadProps) => {
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      event.preventDefault();
      notification.open({ ...notificationOptions, key: "warnBeforeUnload" });
      return message;
    };

    if (active) {
      window.onbeforeunload = handleBeforeUnload;
    } else {
      window.onbeforeunload = null;
    }
  }, [active, message, notificationOptions]);

  return null;
};

export default WarnBeforeUnload;
