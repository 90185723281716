import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Popconfirm, Result, Spin, Tooltip, message } from "antd";
import FileSaver, { saveAs } from "file-saver";
import { compact, flatten, flow, map, orderBy, uniq } from "lodash/fp";
import moment from "moment";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DashboardTile,
  DashboardTileData,
  DashboardTileInput,
} from "../../models/Dashboard";
import { CommercialBlobResponse } from "../../models/OnBlobDownloadFunc";
import {
  dashboardDataCell,
  dashboardDataWithExtrasCell,
  dashboardNotUsedOrdersCell,
  dashboardTilesCell,
  spotsCell,
} from "../../store/dashboard/cell";
import { ExportResponse } from "../../store/exports/models";
import { HttpStatusCode } from "../../store/fetch";
import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { skoTargetGroupCell } from "../../store/sko/cells";
import { handleFailWithProblem, isNonEmptyArray } from "../../utils";
import DashboardContent from "../../views/dashboard/DashboardContent";
import DashboardTileList from "../../views/dashboard/DashboardTileList";
import DashboardFilters from "./DashboardFilters";
import DashboardSpotsDialog from "./DashboardSpotsDialog";
import DashboardWizard, { DashboardWizardButton } from "./DashboardWizard";
import DownloadInvoices from "./DownloadInvoices";
import ShowSpotsButton from "./ShowSpotsButton";

export interface DeleteDashboardTileButtonProps {
  tile: DashboardTile;
}

export interface EditDashboardTileButtonProps {
  tile: DashboardTile;
  onEdit: (tile: DashboardTileInput) => void;
}

const DeleteDashboardTileButton = ({
  tile,
}: DeleteDashboardTileButtonProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const handlePrevent = useCallback(
    (event: MouseEvent<HTMLElement> | undefined) => {
      event?.stopPropagation();
      event?.preventDefault();
    },
    []
  );

  const handleDelete = useCallback(
    (event: MouseEvent<HTMLElement> | undefined) => {
      event?.stopPropagation();
      event?.preventDefault();

      dispatch(dashboardTilesCell.delete({ id: tile.id }));
    },
    [dispatch, tile.id]
  );

  return (
    <Popconfirm
      title={i18n._(t`Weet je zeker dat je deze tegel wilt verwijderen?`)}
      onConfirm={handleDelete}
      onCancel={handlePrevent}
      cancelButtonProps={{ type: "link" }}
    >
      <Tooltip title={i18n._(t`Deze tegel verwijderen`)}>
        <Button
          type="ghost"
          icon={<DeleteOutlined />}
          onClick={handlePrevent}
        />
      </Tooltip>
    </Popconfirm>
  );
};

const EditDashboardTileButton = ({
  tile,
  onEdit,
}: EditDashboardTileButtonProps) => {
  const { i18n } = useLingui();
  const handlePrevent = useCallback(
    (event: MouseEvent<HTMLElement> | undefined) => {
      event?.stopPropagation();
      event?.preventDefault();

      const input: DashboardTileInput = {
        id: tile.id,
        name: tile.name,
        campaignIds: tile.campaignIds,
        skoTargetGroupId: tile.skoTargetGroupId,
        commercialDeliveryIds: tile.commercialDeliveryIds,
      };

      onEdit(input);
    },
    [onEdit, tile]
  );

  return (
    <Tooltip title={i18n._(t`Deze tegel bewerken`)}>
      <Button type="ghost" icon={<EditOutlined />} onClick={handlePrevent} />
    </Tooltip>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    dispatch(dashboardTilesCell.require());
    dispatch(
      dashboardDataCell.require({
        onSuccess: () => {
          dispatch(dashboardDataWithExtrasCell.require());
        },
      })
    );
    dispatch(dashboardNotUsedOrdersCell.require());
  }, [dispatch]);

  useEffect(() => {
    loadData();
    const today = moment();
    dispatch(skoTargetGroupCell.require({ date: today }));
  }, [dispatch, loadData]);

  const dashboardTilesFromStore = useSelector(dashboardTilesCell.select);
  const dashboardDataFromStore = useSelector(dashboardDataCell.select);
  const dashboardDataExtrasFromStore = useSelector(
    dashboardDataWithExtrasCell.select
  );
  const targetGroupsFromStore = useSelector(skoTargetGroupCell.select);
  const spotsFromStore = useSelector(spotsCell.select);
  const notUsedOrdersFromStore = useSelector(dashboardNotUsedOrdersCell.select);

  // Download function
  const { i18n } = useLingui();
  const handleDownload = useCallback(
    (
      id: string,
      path: string,
      callback: (blob: CommercialBlobResponse) => void
    ) => {
      dispatch({
        type: sagaTypes.commercials.download.request,
        id,
        payload: { path },
        onFail: () => {
          message.error(i18n._(t`Er is iets misgegaan met het downloaden`));
        },
        onSuccess: (_: number, blob: CommercialBlobResponse) => {
          callback(blob);
        },
      });
    },
    [dispatch, i18n]
  );

  const [invoiceDownloadBusy, setInvoiceDownloadBusy] = useState(false);
  const handleInvoiceDownload = useCallback(
    (invoiceId: string) => {
      setInvoiceDownloadBusy(true);
      dispatch({
        type: sagaTypes.invoice.download.request,
        id: invoiceId,
        payload: { id: invoiceId },
        onFail: () => {
          message.error(
            i18n._(t`Er is iets misgegaan met het downloaden van de factuur`)
          );
          setInvoiceDownloadBusy(false);
        },
        onSuccess: (_: number, payload: Blob) => {
          FileSaver.saveAs(
            payload,
            `invoice_${invoiceId.replace(/:/g, "_")}.pdf`
          );
          setInvoiceDownloadBusy(false);
        },
      });
    },
    [dispatch, i18n]
  );

  /* Functions for the "Uitzendschema" dialog */
  const [spotsDialog, setSpotsDialog] = useState<{
    show: boolean;
    tile: DashboardTile | null;
    data: DashboardTileData | null;
  }>({ show: false, data: null, tile: null });
  const openModal = useCallback(
    (tile: DashboardTile, data: DashboardTileData) => {
      setSpotsDialog({ show: true, tile, data });
    },
    []
  );
  const closeModal = useCallback(() => {
    setSpotsDialog({ show: false, tile: null, data: null });
    dispatch({ type: spotsCell.events.clear });
  }, [dispatch]);
  const exportTile = useCallback(
    (
      { id, skoTargetGroupId }: DashboardTile,
      { period, data }: DashboardTileData
    ) => {
      const orderIds = flow(
        map("orders"),
        flatten,
        map("id"),
        flatten,
        uniq,
        compact
      )(data);
      const dateFrom = moment(period.from);
      const dateTo = moment(period.to);
      dispatch({
        type: sagaTypes.exports.advertiserOrdersExcel.request,
        id,
        payload: { dateFrom, dateTo, tileId: id, orderIds, skoTargetGroupId },
        onFail: handleFailWithProblem(i18n._(t`Er is iets misgegaan.`)),
        onSuccess: (_statusCode: HttpStatusCode, response?: ExportResponse) => {
          const fileContents = response as Blob;
          const filename = `Export_${id}_${dateFrom.format(
            "yyyyMMDD"
          )}_${dateTo.format("yyyyMMDD")}.xlsx`;
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(fileContents, filename);
          } else {
            saveAs(response as Blob, filename);
          }
        },
      });
    },
    [dispatch, i18n]
  );

  const user = useSelector(
    ({ users: { current } }: StoreModel) => current.value
  );
  const impressionsEnabled = useSelector(
    ({
      application: {
        options: { enableImpressions },
      },
    }: StoreModel) => Boolean(enableImpressions)
  );

  /* Functions for the Wizard */
  const [edit, setEdit] = useState(false);
  const [wizardDialog, setWizardDialog] = useState<{
    show: boolean;
    values: DashboardTileInput | null;
  }>({ show: false, values: null });
  const openWizardModal = useCallback((values: DashboardTileInput | null) => {
    setWizardDialog({ show: true, values });
  }, []);
  const closeWizardModal = useCallback(() => {
    setWizardDialog({ show: false, values: null });
  }, []);
  const handleSuccessWizard = useCallback(() => {
    setEdit(false);
    loadData();
  }, [loadData]);

  if (
    (!dashboardTilesFromStore.value &&
      dashboardTilesFromStore.status.loading) ||
    !user
  ) {
    return <Spin spinning />;
  }

  return (
    <>
      <DashboardContent>
        <Spin
          spinning={
            dashboardTilesFromStore.status.loading ||
            dashboardDataFromStore.status.loading ||
            targetGroupsFromStore.status.loading ||
            spotsFromStore.status.loading
          }
        >
          {!isNonEmptyArray(dashboardTilesFromStore.value) ? (
            <Result
              status="warning"
              title={
                <Trans>
                  Jouw dashboard bevat nog geen tegels.
                  <br />
                  Voeg tegels toe via de knop &apos;Tegels toevoegen&apos;.
                </Trans>
              }
              extra={
                <DashboardWizardButton
                  show={wizardDialog.show}
                  onClick={openWizardModal}
                />
              }
            />
          ) : (
            <>
              <DashboardFilters
                edit={edit}
                onEdit={setEdit}
                notUsedOrders={notUsedOrdersFromStore.value}
              />
              <DashboardTileList
                user={user}
                edit={edit}
                tiles={
                  orderBy(
                    ["name"],
                    ["asc"]
                  )(dashboardTilesFromStore.value) as DashboardTile[]
                }
                data={
                  (isNonEmptyArray(dashboardDataExtrasFromStore.value)
                    ? dashboardDataExtrasFromStore.value
                    : dashboardDataFromStore.value) ?? []
                }
                extrasLoading={dashboardDataExtrasFromStore.status.loading}
                targetGroups={targetGroupsFromStore.value ?? []}
                renderActions={(
                  tile: DashboardTile,
                  data: DashboardTileData | undefined
                ) => [
                  !edit && data && (
                    <DownloadInvoices
                      key={`download-${tile.id}`}
                      data={data}
                      onDownload={handleInvoiceDownload}
                      busy={invoiceDownloadBusy}
                    />
                  ),
                  !edit && data && (
                    <ShowSpotsButton
                      key={`show-${tile.id}`}
                      tile={tile}
                      data={data}
                      onOpen={openModal}
                      onExport={exportTile}
                    />
                  ),
                  edit && (
                    <EditDashboardTileButton
                      key={`edit-${tile.id}`}
                      tile={tile}
                      onEdit={openWizardModal}
                    />
                  ),
                  edit && (
                    <DeleteDashboardTileButton
                      key={`delete-${tile.id}`}
                      tile={tile}
                    />
                  ),
                ]}
                wizard={
                  edit && (
                    <DashboardWizardButton
                      show={wizardDialog.show}
                      onClick={openWizardModal}
                    />
                  )
                }
                onDownload={handleDownload}
                impressionsEnabled={impressionsEnabled}
              />
              <DashboardSpotsDialog
                show={spotsDialog.show}
                tile={spotsDialog.tile}
                data={spotsDialog.data}
                onClose={closeModal}
              />
            </>
          )}
        </Spin>
      </DashboardContent>

      {wizardDialog.show && (
        <DashboardWizard
          onSuccess={handleSuccessWizard}
          onDownload={handleDownload}
          show
          onClose={closeWizardModal}
          initialValues={wizardDialog.values}
        />
      )}
    </>
  );
};

export default Dashboard;
