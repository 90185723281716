import { Col, Row } from "antd";
import React, { Children, ReactNode } from "react";

interface MainContentProps {
  children?: ReactNode;
}

const MainContent = ({ children }: MainContentProps): JSX.Element => (
  <section className="mainContent">
    {children &&
      Children.map(children, (child) => (
        <Row>
          <Col span={24}>{child}</Col>
        </Row>
      ))}
  </section>
);

export default MainContent;
