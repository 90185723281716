import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React from "react";

import { DayOfWeekAll } from "../../models/DayOfWeek";
import { getDayOfWeek } from "../../utils/date";

export type DayOfWeekSelectProps = Omit<
  SelectProps<string>,
  "mode" | "maxTagCount" | "allowClear"
>;

const DayOfWeekSelect = (props: DayOfWeekSelectProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Select mode="multiple" maxTagCount="responsive" allowClear {...props}>
    {DayOfWeekAll.map((day) => (
      <Select.Option key={day} value={day}>
        {getDayOfWeek(day, "full")}
      </Select.Option>
    ))}
  </Select>
);

export default DayOfWeekSelect;
