import { DownOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import moment from "moment";
import React, { MouseEvent, useCallback } from "react";

import { DashboardTileData } from "../../models/Dashboard";
import {
  getIdFromId,
  getOperatorFromId,
  operatorLabels,
} from "../../models/Operator";

export interface DownloadInvoicesProps {
  data: DashboardTileData;
  onDownload: (invoiceId: string) => void;
  busy: boolean;
}

const DownloadInvoices = ({
  data,
  onDownload,
  busy,
}: DownloadInvoicesProps) => {
  const { i18n } = useLingui();

  const handleClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  if (!data.invoices[0]) {
    return null;
  }

  const items: MenuProps["items"] = data.invoices.map((i) => ({
    key: i.id,
    label: (
      <>
        {getIdFromId(i.product.id)}: {i.product.description} (
        {operatorLabels[getOperatorFromId(i.id)]}){" - "}
        {moment(i.invoiceDate).format("ll")}
      </>
    ),
    onClick: (event) => {
      event.domEvent.preventDefault();
      event.domEvent.stopPropagation();
      onDownload(event.key);
    },
  }));

  return (
    <Dropdown menu={{ items }} trigger={["hover"]} disabled={busy}>
      <Button type="link" onClick={handleClick} disabled={busy} loading={busy}>
        <Space>
          {i18n._(t`Download factuur`)}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DownloadInvoices;
