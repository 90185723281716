import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert } from "antd";
import queryString from "query-string";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import ActivationPasswordStepModel from "../../models/ActivationPasswordStepModel";
import ActivationTwoFactorStepModel from "../../models/ActivationTwoFactorStepModel";
import {
  HttpStatusCode,
  httpGone,
  httpInternalServerError,
  httpUnauthorized,
} from "../../store/fetch";
import {
  ActivateRequest,
  ActivateResponse,
  RequestAction,
} from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { clearTokens, clearUsername } from "../../store/token";
import useIsMounted from "../../utils/statefulness";
import ActivationPasswordStep from "./ActivationPasswordStep";
import ActivationReady from "./ActivationReady";
import ActivationSteps from "./ActivationSteps";
import ActivationTwoFactorStep from "./ActivationTwoFactorStep";

type ActivationState = "Default" | "Invalid" | "Error";

const Activation = memo(() => {
  const { i18n } = useLingui();
  const isMounted = useIsMounted();
  const [activationState, setActivationState] =
    useState<ActivationState>("Default");

  const dispatch = useDispatch();
  const { search } = useLocation();
  const { token, mail } = queryString.parse(search);
  const [currentStep, setCurrentStep] = useState(1);
  const [passwordStep, setPasswordStep] = useState<
    ActivationPasswordStepModel | undefined
  >();

  useEffect(() => {
    if (isMounted.current && (!token || !mail)) {
      setActivationState("Error");
      return;
    }

    // don't hijack the currently logged in user's session, if there exists one.
    clearUsername();
    clearTokens();
    dispatch({
      type: sagaTypes.users.current.clear,
    });

    dispatch({
      type: sagaTypes.tokens.activateVerify.request,
      payload: {
        emailAddress: mail as string,
        token: token as string,
      },
      onFail: (exception?: string, statusCode?: HttpStatusCode) => {
        if (!isMounted.current) {
          return;
        }
        switch (statusCode) {
          case httpGone:
            setActivationState("Invalid");
            setCurrentStep(0);
            break;
          case httpUnauthorized:
          case httpInternalServerError:
          default:
            setActivationState("Error");
            setCurrentStep(0);
            break;
        }
      },
    });
  }, [dispatch, isMounted, mail, token]);

  const handlePasswordNext = useCallback(
    (values?: ActivationPasswordStepModel) => {
      if (!isMounted.current) {
        return;
      }
      setPasswordStep(values);
      setCurrentStep(currentStep + 1);
    },
    [currentStep, isMounted]
  );
  const handleTwoFactorNext = useCallback(
    (values?: ActivationTwoFactorStepModel) => {
      if (passwordStep !== undefined && values !== undefined) {
        dispatch<RequestAction<ActivateRequest, ActivateResponse>>({
          type: sagaTypes.tokens.activate.request,
          payload: {
            emailAddress: mail as string,
            ...passwordStep,
            ...values,
            activateToken: token as string,
          },
          onSuccess: () => {
            dispatch({
              type: sagaTypes.users.current.request,
            });
          },
        });
        if (isMounted.current) {
          setCurrentStep(currentStep + 1);
        }
      }
    },
    [currentStep, dispatch, isMounted, mail, passwordStep, token]
  );

  return (
    <>
      <ActivationSteps currentStep={currentStep} />
      {activationState === "Invalid" && (
        <Alert
          message={i18n._(t`Ongeldig`)}
          description={i18n._(
            t`Deze link is verlopen. Er wordt een nieuwe link per mail naar je verzonden.`
          )}
          type="warning"
          showIcon
        />
      )}
      {activationState === "Error" && (
        <Alert
          message={i18n._(t`Fout`)}
          description={i18n._(t`Een onbekende fout is opgetreden.`)}
          type="error"
          showIcon
        />
      )}
      {currentStep === 1 && (
        <ActivationPasswordStep onNext={handlePasswordNext} />
      )}
      {currentStep === 2 && (
        <ActivationTwoFactorStep onNext={handleTwoFactorNext} />
      )}
      {currentStep === 3 && <ActivationReady />}
    </>
  );
});

export default Activation;
