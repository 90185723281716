import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Select } from "antd";
import moment from "moment";
import React, { memo } from "react";

const { Option } = Select;

export type YearChangeFunc = (value: number) => void;

const YearSelect = ({
  value,
  onChange,
}: {
  value: number;
  onChange: YearChangeFunc;
}) => {
  const { i18n } = useLingui();
  return (
    <div className="tip-year-select">
      <div className="tip-year-select-label">
        {i18n._(t`Selecteer een jaar`)}
      </div>
      <Select style={{ width: 120 }} onChange={onChange} value={value}>
        {[0, 1, 2].map((i) => {
          const year = moment().subtract(i, "year").year();

          return (
            <Option key={year} value={year}>
              {year}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default memo(YearSelect);
