import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, InputNumber } from "antd";
import React, { memo } from "react";

import { fieldGrpsName } from "../../../../../models/Campaigns/Requests/models";
import { GrpsFieldProps } from "../models";

const GrpsField = memo(({ enabled, onChange }: GrpsFieldProps) => {
  const { i18n } = useLingui();

  return (
    <Form.Item
      label={i18n._(t`GRP's`)}
      labelCol={{ span: 24 }}
      name={fieldGrpsName}
      rules={[
        {
          type: "number",
          min: 0,
          message: i18n._(t`GRP's moet een positieve waarde zijn.`),
        },
      ]}
      wrapperCol={{ span: 24 }}
    >
      <InputNumber
        min={0}
        onChange={onChange}
        step={1}
        disabled={!enabled}
        precision={2}
        decimalSeparator=","
      />
    </Form.Item>
  );
});

export default GrpsField;
