import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React from "react";

import { Operator } from "../../models/Operator";
import { Commercial } from "../../models/Spot";

export type CommercialSelectProps = {
  operator: Operator;
  commercials: Commercial[];
} & Omit<SelectProps<string>, "mode">;

export const createCommercialLabel = (c: Commercial, o: Operator): string => {
  if (["Orn", "Rtl"].includes(o)) {
    return `${c.description} ${c.spotLength}"`;
  }

  return `${c.code}: ${c.description} ${c.spotLength}"`;
};

const CommercialSelect = ({
  commercials,
  operator,
  ...props
}: CommercialSelectProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Select {...props} dropdownMatchSelectWidth={false}>
    {commercials
      ?.sort((a, b) => a.code.localeCompare(b.code))
      .map((c) => (
        <Select.Option key={c.code} value={c.code}>
          {createCommercialLabel(c, operator)}
        </Select.Option>
      ))}
  </Select>
);

export default CommercialSelect;
