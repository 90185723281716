import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Card, Form, FormInstance } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Callbacks } from "rc-field-form/lib/interface";
import React, { PropsWithChildren } from "react";

import { CalendarEventFilter } from "../../models/Calendar";

export interface CalendarEventFiltersFormProps {
  form: FormInstance<CalendarEventFilter>;
  initialValues?: CalendarEventFilter;
  onFinish?: Callbacks<CalendarEventFilter>["onFinish"];
  inline?: boolean | "right";
}

const CalendarEventFiltersForm = ({
  form,
  initialValues,
  onFinish,
  children,
  inline,
}: PropsWithChildren<CalendarEventFiltersFormProps>) => {
  const { i18n } = useLingui();

  const formComponent = (
    <Form
      name="filters"
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout="inline"
    >
      {children}
    </Form>
  );

  if (inline) {
    if (inline === "right") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {formComponent}
        </div>
      );
    }

    return formComponent;
  }

  return (
    <Card
      type="inner"
      title={<span>{i18n._(t`Kalender`)}</span>}
      bodyStyle={{ display: "flex", justifyContent: "center" }}
    >
      {formComponent}
    </Card>
  );
};

export default CalendarEventFiltersForm;
