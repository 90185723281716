import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Empty, Table, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import { ColumnFilterItem } from "antd/lib/table/interface";
import moment from "moment";
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import Order from "../../models/Order";
import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import Spot from "../../models/Spot";
import { AnalyzedOrder } from "../../store/campaigns/analyzed/models";
import OrderWithSpotsResponse from "../../store/campaigns/OrderWithSpotsResponse";
import SelectOrderRequest from "../../store/campaigns/SelectOrderRequest";
import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { handleFailWithProblem } from "../../utils";
import PreferredPositionView from "../../views/campaigns/PreferredPositionView";
import SpotInfoPopup from "../../views/campaigns/SpotInfoPopup";
import SpotZoomPopup from "../../views/campaigns/SpotZoomPopup";
import Ellipsis from "../../views/Ellipsis";
import Euro from "../../views/Euro";
import Grps from "../../views/Grps";
import NotAvailable from "../../views/NotAvailable";
import OrderTable from "./OrderTable";

const now = moment();
const emptyMergedSpotsArray: MergedSpotSubOrder[] = [];

const useColumns = (
  channels: ColumnFilterItem[],
  packages: ColumnFilterItem[],
  targetGroups: ColumnFilterItem[],
  skoTargetGroup: SkoTargetGroup | undefined,
  analyzedOrder: AnalyzedOrder | undefined
): ColumnProps<MergedSpotSubOrder>[] => {
  const { i18n } = useLingui();

  const columns: ColumnProps<MergedSpotSubOrder>[] = [
    {
      title: i18n._(t`Datum`),
      key: "scheduledDate",
      width: 110,
      fixed: "left",
      showSorterTooltip: false,
      sorter: (a, b): number =>
        moment(a.scheduledDate).toDate().getTime() -
        moment(b.scheduledDate).toDate().getTime(),
      render: (_, { scheduledDate }): ReactNode => (
        <>{moment(scheduledDate).format("ll")}</>
      ),
    },
    {
      title: i18n._(t`Tijd`),
      key: "scheduledStartDate",
      width: 64,
      fixed: "left",
      showSorterTooltip: false,
      sorter: (a, b): number =>
        moment(a.scheduledStartDate).toDate().getTime() -
        moment(b.scheduledStartDate).toDate().getTime(),
      render: (_, { scheduledStartDate }): ReactNode => (
        <Tooltip title={moment(scheduledStartDate).format("LLL")}>
          <span>{moment(scheduledStartDate).format("HH:mm")}</span>
        </Tooltip>
      ),
    },
    {
      title: i18n._(t`Blok`),
      key: "breakId",
      dataIndex: "breakId",
      fixed: "left",
      width: 115,
      showSorterTooltip: false,
      sorter: (a, b): number => `${a.breakId}`.localeCompare(b.breakId),
    },
    {
      title: i18n._(t`Zender`),
      key: "channel",
      width: 115,
      filters: channels,
      onFilter: (value, { channel }): boolean =>
        channel && channel.description === value,
      showSorterTooltip: false,
      sorter: (a, b): number => {
        const aChannel = a.channel.intomartCode ?? a.channel.description;
        const bChannel = b.channel.intomartCode ?? b.channel.description;
        return aChannel.localeCompare(bChannel);
      },
      render: (_, { channel: { description } }): ReactNode => (
        <Ellipsis text={description} />
      ),
    },
    {
      title: i18n._(t`Programma voor`),
      key: "programBefore",
      showSorterTooltip: false,
      sorter: (a, b): number => a.programBefore.localeCompare(b.programBefore),
      render: (_, { programBefore }): ReactNode => (
        <Ellipsis text={programBefore} />
      ),
    },
    {
      title: i18n._(t`Programma na`),
      key: "programAfter",
      showSorterTooltip: false,
      sorter: (a, b): number => a.programAfter.localeCompare(b.programAfter),
      render: (_, { programAfter }): ReactNode => (
        <Ellipsis text={programAfter} />
      ),
    },
    {
      title: i18n._(t`Pakket`),
      key: "packageCode",
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b): number => a.packageCode.localeCompare(b.packageCode),
      render: (_, { packageDescr }): ReactNode => (
        <Ellipsis text={packageDescr} />
      ),
      filters: packages,
      onFilter: (value, { packageDescr }): boolean => packageDescr === value,
    },
    {
      title: i18n._(t`Lengtes`),
      key: "spotLengths",
      width: 100,
      showSorterTooltip: false,
      sorter: (a, b): number =>
        a.spotLengths.reduce((prev, curr) => prev + curr) -
        b.spotLengths.reduce((prev, curr) => prev + curr),
      render: (_, { spotLengths }): ReactNode => `${spotLengths.join("+")}"`,
    },
    {
      title: i18n._(t`Doelgroep`),
      key: "targetGroupId",
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b): number => a.targetGroupId.localeCompare(b.targetGroupId),
      render: (_, { targetGroupDescr }): ReactNode => (
        <Ellipsis text={targetGroupDescr} />
      ),
      filters: targetGroups,
      onFilter: (value, { targetGroupDescr }): boolean =>
        targetGroupDescr === value,
    },
    {
      title: <span>{i18n._(t`Netto tarief`)} (€)</span>,
      key: "netSpotPrice",
      align: "right",
      width: 140,
      showSorterTooltip: false,
      sorter: (a, b): number =>
        a.finances && b.finances
          ? a.finances.netSpotPrice - b.finances.netSpotPrice
          : 0,
      render: (_, { finances }): ReactNode =>
        finances ? <Euro amount={finances.netSpotPrice} /> : null,
    },
    {
      align: "center",
      key: "predictedRating",
      render: (_, { predictedRating }): ReactNode => (
        <Grps amount={predictedRating} />
      ),
      showSorterTooltip: false,
      sorter: (a, b): number =>
        (a.predictedRating ?? 0) - (b.predictedRating ?? 0),
      title: (
        <Tooltip
          placement="bottom"
          title={i18n._(
            t`De verwachte kijkdichtheid van het reclameblok binnen de geselecteerde doelgroep`
          )}
        >
          <span>
            <Trans>Prognose</Trans>
          </span>
        </Tooltip>
      ),
      width: 115,
    },
    {
      title: (
        <Tooltip
          placement="bottom"
          title={i18n._(
            t`De getoonde GRP's voor een datum in de toekomst is gelijk aan de prognose`
          )}
        >
          <span>
            <Trans>Gerealiseerd</Trans>
          </span>
        </Tooltip>
      ),
      key: "achievedRating",
      width: 125,
      align: "center",
      showSorterTooltip: false,
      sorter: (a, b): number =>
        (a.achievedRating ?? a.predictedRating ?? 0) -
        (b.achievedRating ?? b.predictedRating ?? 0),
      render: (
        _,
        { achievedRating, predictedRating, scheduledDate }
      ): ReactNode => {
        const isPredicted =
          achievedRating !== undefined &&
          moment(scheduledDate).isSameOrAfter(now, "day");
        return (
          <Grps
            amount={isPredicted ? predictedRating : achievedRating}
            isPredicted={isPredicted}
          />
        );
      },
    },
    {
      key: "skoGrpsNo",
      align: "right",
      title: (
        <Tooltip
          title={`${i18n._(
            t`Gerealiseerde GRP's in de gekozen NMO analyse doelgroep`
          )} ${analyzedOrder?.skoTargetGroup?.name ?? skoTargetGroup?.name}`}
        >
          {t`GRP's`}
        </Tooltip>
      ),
      width: 110,
      render: (_, { id, subOrderId, skoGrp }): ReactNode => {
        const analyzedSpot = analyzedOrder?.subOrders
          .find((sub) => sub.id === subOrderId)
          ?.spots?.find((spot) => spot.id === id);

        // eslint-disable-next-line no-nested-ternary
        return skoTargetGroup && analyzedSpot !== undefined ? (
          <Grps amount={analyzedSpot.skoGrp} />
        ) : skoTargetGroup && skoGrp !== undefined ? (
          <Grps amount={skoGrp} />
        ) : (
          <NotAvailable />
        );
      },
    },
    {
      title: (
        <Tooltip placement="bottom" title={i18n._(t`Voorkeurspositie`)}>
          <span>
            <Trans>VKP</Trans>
          </span>
        </Tooltip>
      ),
      key: "preferredPosition",
      width: 75,
      align: "center",
      render: (_, { preferredPosition, spotLengths }): ReactNode => (
        <PreferredPositionView
          preferredPosition={preferredPosition}
          isMulti={spotLengths.length > 1}
        />
      ),
    },
    {
      title: (
        <Tooltip
          placement="bottom"
          title={i18n._(
            t`Deze kolom bevat detail informatie over de uitgezonden commercial`
          )}
        >
          <span>
            <Trans>Detail</Trans>
          </span>
        </Tooltip>
      ),
      key: "extraInfo",
      width: 75,
      align: "center",
      render: (_, spot): ReactNode => <SpotInfoPopup spot={spot} />,
    },
    {
      title: (
        <Tooltip
          placement="left"
          title={i18n._(
            t`Inzoomen op de uitgezonden commercials bij de regionale omroepen (Ster)`
          )}
        >
          <span>
            <Trans>Inzoomen</Trans>
          </span>
        </Tooltip>
      ),
      key: "zoom",
      width: 75,
      align: "center",
      render: (_, { scheduledStartDate, spotRatingRegios }): ReactNode =>
        moment(scheduledStartDate).startOf("day").date() <
          moment().startOf("day").date() &&
        spotRatingRegios &&
        spotRatingRegios.length > 0 ? (
          <SpotZoomPopup spotRatingsRegio={spotRatingRegios} />
        ) : (
          <></>
        ),
    },
  ];
  const showAnalysis = skoTargetGroup && analyzedOrder;
  return columns.filter(({ key }) => {
    switch (key) {
      case "skoGrpsNo":
        return showAnalysis;
      default:
        return true;
    }
  });
};

const createFilterArray =
  (func: (spot: MergedSpotSubOrder) => string) =>
  (mergedSpots: MergedSpotSubOrder[]): ColumnFilterItem[] =>
    (mergedSpots && mergedSpots.length > 0
      ? [...new Set(mergedSpots.map(func))]
      : []
    )
      .filter((value) => value && value !== "")
      .sort()
      .map((value) => ({ text: value, value }));

interface OrderDetailsTableProps {
  order: SelectOrderRequest;
  analyzedOrder?: AnalyzedOrder;
}

interface SubOrderSpotData {
  subOrderId: string;
  packageCode: string;
  packageDescr: string;
  targetGroupId: string;
  targetGroupDescr: string;
  spotLengths: number[];
  rowKey: string;
}

type MergedSpotSubOrder = Spot & SubOrderSpotData;

/**
 * Renders a component that displays additional information about an order in a table.
 */
const OrderDetailsTable: FunctionComponent<OrderDetailsTableProps> = ({
  order,
  analyzedOrder,
}) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const skoTargetGroup = useSelector(
    ({
      campaigns: {
        selectedFilter: { skoTargetGroupId },
      },
      skoTargetGroup: { value },
    }: StoreModel) =>
      skoTargetGroupId && value
        ? value.find(({ id }) => id === skoTargetGroupId)
        : undefined
  );

  useEffect(() => {
    if (order) {
      dispatch({
        type: sagaTypes.campaigns.spotsPerOrder.request,
        id: order.id,
        payload: {
          orderId: order.id,
          dateFrom: order.dateFrom,
          dateTo: order.dateTo,
          skoTargetGroupId: skoTargetGroup?.id,
        },
        onFail: handleFailWithProblem(
          i18n._(t`Er trad een fout op bij het ophalen van de gegevens.`)
        ),
      });
    }
  }, [dispatch, i18n, order, skoTargetGroup]);

  const loading = useSelector(
    ({ campaigns: { details } }: StoreModel): boolean =>
      details[order.id] ? details[order.id].status.loading : true
  );

  const orderDetails = useSelector(
    ({ campaigns: { details } }: StoreModel): Order | undefined =>
      details[order.id] &&
      details[order.id].value &&
      (details[order.id].value as OrderWithSpotsResponse).order
        ? (details[order.id].value as OrderWithSpotsResponse).order
        : undefined
  );

  const [subOrderKeys, updateSubOrderKeys] = useState<string[]>([]);
  useEffect(() => {
    if (orderDetails && orderDetails.subOrders) {
      const allKeys = orderDetails.subOrders.map(
        (subOrder) => `${orderDetails.id}:${subOrder.id}`
      );
      updateSubOrderKeys(allKeys);
    }
  }, [orderDetails]);

  const handleSubOrderChange = useCallback(
    (selectedKeys: string[] | number[]): void =>
      updateSubOrderKeys(selectedKeys as string[]),
    []
  );

  const spots = useMemo(
    (): MergedSpotSubOrder[] =>
      orderDetails && orderDetails.subOrders
        ? orderDetails.subOrders
            .map((subOrder) =>
              (subOrder.spots as Spot[]).map(
                (spot: Spot): MergedSpotSubOrder =>
                  ({
                    ...spot,
                    rowKey: `${orderDetails.id}:${subOrder.id}`,
                    subOrderId: subOrder.id,
                    packageCode: subOrder.package.code,
                    packageDescr: subOrder.package.description,
                    targetGroupId: subOrder.targetGroup
                      ? subOrder.targetGroup.id
                      : "",
                    targetGroupDescr: subOrder.targetGroup
                      ? subOrder.targetGroup.description
                      : "",
                    spotLengths: subOrder.spotLengths,
                  } as MergedSpotSubOrder)
              )
            )
            .reduce((x, y) => x.concat(y), []) // Microsoft Edge does not support .flatMap, however, Microsoft Edge with Chromium does.
            .map((spot) => ({
              key: spot.id,
              ...spot,
            }))
            .sort(
              (a, b) =>
                moment(a.scheduledStartDate).toDate().getTime() -
                moment(b.scheduledStartDate).toDate().getTime()
            )
        : emptyMergedSpotsArray,
    [orderDetails]
  );

  const channels: ColumnFilterItem[] = createFilterArray(
    ({ channel }): string => channel.description
  )(spots);

  const packages: ColumnFilterItem[] = createFilterArray(
    ({ packageDescr }): string => packageDescr
  )(spots);

  const targetGroups: ColumnFilterItem[] = createFilterArray(
    ({ targetGroupDescr }): string => targetGroupDescr
  )(spots);

  const dataSource = useMemo(
    () =>
      spots.filter((subOrder) =>
        subOrderKeys.some((key) => subOrder.rowKey === key)
      ),
    [spots, subOrderKeys]
  );
  const impressionsEnabled = useSelector(
    ({
      application: {
        options: { enableImpressions },
      },
    }: StoreModel) => Boolean(enableImpressions)
  );

  const columns = useColumns(
    channels,
    packages,
    targetGroups,
    skoTargetGroup,
    analyzedOrder
  );

  return (
    <div>
      <OrderTable
        bookSpotEnabled={false}
        onSubOrderChange={handleSubOrderChange}
        order={orderDetails}
        analyzedOrder={analyzedOrder}
        selectedSubOrderKeys={subOrderKeys}
        showNumberOfSpots
        showInstructions={false}
        impressionsEnabled={impressionsEnabled}
      />
      <Table
        bordered
        columns={columns}
        dataSource={dataSource}
        loading={!orderDetails && loading}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ["100", "250", "500"],
        }}
        size="small"
        scroll={{ x: 1580, y: 320 }}
        locale={{
          emptyText:
            !orderDetails && loading ? (
              " "
            ) : (
              <Empty description={i18n._(t`Geen spots aanwezig.`)} />
            ),
        }}
      />
    </div>
  );
};

export default OrderDetailsTable;
