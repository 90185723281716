import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Form, Select, Typography } from "antd";
import React, { memo, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { fieldProductName } from "../../../../../models/Campaigns/Requests/models";
import navigationPaths from "../../../../../utils/navigation";
import { labelCol, wrapperColShallow } from "../../../../../views/layout/Form";
import { ProductPickerProps } from "../models";

const ProductPicker = memo(
  ({
    enabled,
    form: { setFieldsValue },
    products,
    selectedOperator,
  }: ProductPickerProps) => {
    const { i18n } = useLingui();

    const filteredProducts = useMemo(
      () =>
        selectedOperator
          ? products.filter((prop) => prop.id.startsWith(selectedOperator))
          : [],
      [products, selectedOperator]
    );

    // only 1 product => select it!
    useEffect(() => {
      if (filteredProducts.length === 1) {
        setFieldsValue({ [fieldProductName]: filteredProducts[0].id });
      }
    }, [filteredProducts, setFieldsValue]);

    return selectedOperator ? (
      <Form.Item
        label={i18n._(t`Product`)}
        labelCol={labelCol}
        wrapperCol={wrapperColShallow}
      >
        <Form.Item
          name={fieldProductName}
          noStyle
          rules={[
            { required: true, message: i18n._(t`Product is verplicht.`) },
          ]}
        >
          <Select showSearch optionFilterProp="title" disabled={!enabled}>
            {filteredProducts.map((prod) => {
              const title = `${prod.id.split(":")[1]}: ${prod.description}`;
              return (
                <Select.Option key={prod.id} value={prod.id} title={title}>
                  {title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Typography.Paragraph>
          <Trans>
            Zit je product er niet tussen,{" "}
            <Link to={navigationPaths.ProductRequest}>
              vraag een nieuw product aan
            </Link>
            .
          </Trans>
        </Typography.Paragraph>
      </Form.Item>
    ) : (
      <Alert
        message={i18n._(t`Selecteer eerst een exploitant.`)}
        type="warning"
      />
    );
  }
);

export default ProductPicker;
