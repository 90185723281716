import { Trans } from "@lingui/macro";
import { Col, Row, Typography } from "antd";
import React, { ReactNode, memo } from "react";

interface QrCodeViewerProps {
  headerText: ReactNode;
  dataUrl: string;
  dataText: string;
}

const QrCodeViewer = memo(
  ({ headerText: header, dataUrl, dataText }: QrCodeViewerProps) => (
    <section className="tip-qrCodeViewer">
      <Row>
        <Col span={24}>{header}</Col>
      </Row>
      <Row>
        <Col span={24}>
          <img src={dataUrl} alt="QR Code" />
          <Typography.Paragraph type="secondary">
            <Trans>Scannen lukt niet? Kopieer deze code in de app.</Trans>
          </Typography.Paragraph>
          <Typography.Text code copyable>
            {dataText}
          </Typography.Text>
          <Typography.Paragraph type="warning">
            <Trans>Deel deze niet met anderen.</Trans>
          </Typography.Paragraph>
        </Col>
      </Row>
    </section>
  )
);

export default QrCodeViewer;
