import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Typography } from "antd";
import moment from "moment";
import React from "react";
import ReactMarkdown from "react-markdown";

import { CalendarEvent } from "../../models/Calendar";

export const CalendarEventDate = ({
  event,
  i18n,
}: {
  event: CalendarEvent;
  i18n: I18n;
}) =>
  moment(event.to).diff(moment(event.from), "days") === 0 ? (
    <>
      {moment(event.from).format("ll")}
      {!event.isAllDay && (
        <>
          {" "}
          {i18n._(t`van`)} {moment(event.from).format("LT")} {i18n._(t`tot`)}{" "}
          {moment(event.to).format("LT")}
        </>
      )}
    </>
  ) : (
    <>
      {moment(event.from).format(event.isAllDay ? "ll" : "lll")}{" "}
      {i18n._(t`t/m`)} {moment(event.to).format(event.isAllDay ? "ll" : "lll")}
    </>
  );

const CalendarEventContent = ({
  event,
  i18n,
}: {
  event: CalendarEvent;
  i18n: I18n;
}) => (
  <>
    <Typography.Paragraph strong>
      <CalendarEventDate event={event} i18n={i18n} />
    </Typography.Paragraph>
    {event.description && <ReactMarkdown>{event.description}</ReactMarkdown>}
    {event.location && (
      <Typography.Paragraph italic>
        {i18n._(t`Locatie`)}: {event.location}
      </Typography.Paragraph>
    )}
  </>
);

export default CalendarEventContent;
