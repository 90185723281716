import { combineReducers } from "redux";

import { asyncIdReducer, asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";
import {
  ExportCommercialDeliveriesForYearStatisticsRequest,
  ExportConceptRequest,
  ExportOrdersRequest,
  ExportOrganisationLoginsForYearStatisticsRequest,
  ExportOrganisationRequestsForYearStatisticsRequest,
  ExportResponse,
  ExportUserLoginsByOrganisationForYearStatisticsRequest,
} from "./models";

export default combineReducers({
  byOrderExcel: asyncIdReducer<ExportOrdersRequest, ExportResponse>(
    sagaTypes.exports.orderExcel
  ),
  byOrderPdf: asyncIdReducer<ExportOrdersRequest, ExportResponse>(
    sagaTypes.exports.orderPdf
  ),
  byAdvertiserExcel: asyncIdReducer<ExportOrdersRequest, ExportResponse>(
    sagaTypes.exports.advertiserOrdersExcel
  ),
  byAdvertiserPdf: asyncIdReducer<ExportOrdersRequest, ExportResponse>(
    sagaTypes.exports.advertiserOrdersPdf
  ),
  byFilterExcel: asyncReducer<ExportOrdersRequest, ExportResponse>(
    sagaTypes.exports.filterOrdersExcel
  ),
  byFilterPdf: asyncReducer<ExportOrdersRequest, ExportResponse>(
    sagaTypes.exports.filterOrdersPdf
  ),
  organisationLoginsForYear: asyncReducer<
    ExportOrganisationLoginsForYearStatisticsRequest,
    ExportResponse
  >(sagaTypes.exports.organisationLoginsForYear),
  organisationRequestsForYear: asyncReducer<
    ExportOrganisationRequestsForYearStatisticsRequest,
    ExportResponse
  >(sagaTypes.exports.organisationRequestsForYear),
  commercialDeliveriesForYear: asyncReducer<
    ExportCommercialDeliveriesForYearStatisticsRequest,
    ExportResponse
  >(sagaTypes.exports.organisationRequestsForYear),
  userLoginsByOrganisationForYear: asyncReducer<
    ExportUserLoginsByOrganisationForYearStatisticsRequest,
    ExportResponse
  >(sagaTypes.exports.userLoginsByOrganisationForYear),
  byConceptExcel: asyncReducer<ExportConceptRequest, ExportResponse>(
    sagaTypes.exports.conceptExcel
  ),
  usersExcel: asyncReducer<undefined, ExportResponse>(sagaTypes.exports.users),
});
