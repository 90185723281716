import CampaignsFilterModel from "../../models/CampaignsFilterModel";
import { getPeriodQueryString } from "../../utils/date";
import { jsonGetter } from "../fetch";
import { TokenApiPromise } from "../models";
import FetchOrdersFilteredResponse from "./FetchOrdersFilteredResponse";
import FilterModel from "./FilterModel";
import FilterRequest from "./FilterRequest";
import OrderWithSpotsResponse from "./OrderWithSpotsResponse";
import SelectedFilterModel from "./SelectedFilterModel";
import SpotsRequest from "./SpotsRequest";

export const filtersGetter = ({
  period,
}: FilterRequest): TokenApiPromise<FilterModel> =>
  jsonGetter<FilterRequest, FilterModel>(
    `/api/campaigns/filters?${getPeriodQueryString(period[0], period[1])}`
  );

export const fetchOrdersFilteredGetter = ({
  dateFrom,
  dateTo,
  advertiserIds,
  orderIds,
}: SelectedFilterModel): TokenApiPromise<FetchOrdersFilteredResponse> => {
  const advertisers = advertiserIds
    .map((item) => `&advertiserIds=${item}`)
    .join("");
  const orders = orderIds.map((item) => `&orderIds=${item}`).join("");
  return jsonGetter<CampaignsFilterModel, FetchOrdersFilteredResponse>(
    `/api/campaigns?${getPeriodQueryString(
      dateFrom,
      dateTo
    )}${advertisers}${orders}`
  );
};

export const spotsGetter = ({
  dateFrom,
  dateTo,
  orderId,
  skoTargetGroupId,
}: SpotsRequest): TokenApiPromise<OrderWithSpotsResponse> => {
  const url = skoTargetGroupId
    ? `/api/campaigns/spots/${orderId}?${getPeriodQueryString(
        dateFrom,
        dateTo
      )}&skoTargetGroupId=${skoTargetGroupId}`
    : `/api/campaigns/spots/${orderId}?${getPeriodQueryString(
        dateFrom,
        dateTo
      )}`;

  return jsonGetter<OrderWithSpotsResponse, OrderWithSpotsResponse>(url);
};
