import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { StoreModel } from "../store/models";
import { getToken, refreshTokenKey } from "../store/token";
import navigationPaths from "./navigation";

// eslint-disable-next-line no-shadow
export enum Culture {
  enus = "en-us",
  enUS = "en-US",
  nl = "nl",
}

export const defaultCulture = Culture.nl;
export const preferredCultureKey = "preferredCulture";

export const useCulture = (): Culture => {
  const storeCulture = useSelector(
    ({
      users: {
        current: { value },
      },
    }: StoreModel) => value?.preferredCulture
  );

  return (localStorage.getItem(preferredCultureKey) ||
    storeCulture ||
    window.tipBrowserCulture ||
    defaultCulture) as Culture;
};

export const useRedirectIfLoggedIn = (): void => {
  const navigate = useNavigate();
  useEffect(() => {
    const refreshToken = getToken(refreshTokenKey);
    if (refreshToken) {
      navigate(navigationPaths.Root);
    }
  }, [navigate]);
};
