import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { message } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Advertiser, { emptyAdvertisers } from "../../../models/Advertiser";
import {
  CampaignSubOrderValues,
  CampaignValues,
  OrderRequest,
  emptyOrderRequestSpots,
} from "../../../models/Campaigns/Requests/models";
import {
  Package,
  PackagesRequest,
  PackagesResponse,
} from "../../../models/packages";
import {
  Product,
  ProductsRequest,
  ProductsResponse,
  emptyProduct,
} from "../../../models/products";
import { preferredPositionNone } from "../../../models/Spot";
import SpotLengthIndex from "../../../models/SpotLengthIndex";
import { emptyTargetGroup } from "../../../models/TargetGroup";
import {
  AdvertisersRequest,
  AdvertisersResponse,
} from "../../../store/advertisers/models";
import { openRequestsCell } from "../../../store/campaigns/requests/cells";
import {
  OrderRequestCreateResponse,
  OrderRequestGetResponse,
} from "../../../store/campaigns/requests/models";
import { HttpStatusCode } from "../../../store/fetch";
import { RequestAction, StoreModel } from "../../../store/models";
import sagaTypes from "../../../store/sagaTypes";
import { handleFailWithProblem } from "../../../utils";
import navigationPaths from "../../../utils/navigation";
import MainContent from "../../../views/layout/MainContent";
import {
  orderRequestCreateCategory,
  orderRequestDeleteCategory,
  orderRequestSubmitCategory,
  triggerAnalyticsEvent,
} from "../../../views/utils/analytics";
import WarnBeforeUnload from "../../navigation/WarnBeforeUnload";
import { emptyPeriods, fieldIdName, fieldNameName } from "./constants";
import { subOrdersToFormValues } from "./models";
import OrderRequestSubmissionModalContainer from "./OrderRequestSubmissionModalContainer";
import OrderRequestWizard from "./OrderRequestWizard";

const emptySpotLengthIndices: SpotLengthIndex[] = [];
const emptyProducts: Product[] = [];
const emptyPackages: Package[] = [];
const emptySubOrders: CampaignSubOrderValues[] = [];

const OrderRequestWizardContainer: FunctionComponent = () => {
  const { i18n } = useLingui();

  const dispatch = useDispatch();
  const [campaignForm] = useForm();
  const { getFieldValue, getFieldsValue, resetFields, setFieldsValue } =
    campaignForm;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { orderRequestId } = useParams<{ orderRequestId?: string }>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedPeriod, setSelectedPeriod] =
    useState<RangeValue<Moment>>(null);
  const [selectedAdvertiserId, setSelectedAdvertiserId] = useState<
    string | null
  >(null);
  const [currentSubOrders, setCurrentSubOrders] = useState<
    CampaignSubOrderValues[]
  >([]);
  const [submissionModalVisible, setSubmissionModalVisible] = useState(false);

  const reloadOpenRequests = useCallback(() => {
    dispatch({ type: openRequestsCell.events.invalidate });
    dispatch(openRequestsCell.require());
  }, [dispatch]);

  const {
    status: { loading: submitLoading },
  } = useSelector(({ requests: { submit } }: StoreModel) => submit);

  useEffect(() => {
    // haal de lijst met verkoopperiodes op
    dispatch({
      type: sagaTypes.campaigns.requests.periods.request,
    });

    // haal de lijst met indices op
    dispatch({
      type: sagaTypes.campaigns.requests.spotLengthIndices.request,
    });
  }, [dispatch]);

  const {
    value: { emailAddress: userEmail, name: userName } = {
      emailAddress: "",
      name: "",
    },
  } = useSelector(({ users: { current } }: StoreModel) => current);

  const { periods, loading: periodsLoading } = useSelector(
    ({ requests: { periods: periodsInStore } }: StoreModel) => ({
      periods: periodsInStore.value ?? emptyPeriods,
      loading: periodsInStore.status.loading,
    })
  );

  const { value: orderRequestValue } = useSelector(
    ({ requests: { orderRequest } }: StoreModel) => orderRequest
  );

  const spotLengthIndices = useSelector(
    ({
      requests: { spotLengthIndices: spotLengthIndicesInStore },
    }: StoreModel) => spotLengthIndicesInStore.value ?? emptySpotLengthIndices
  );

  const getAdvertisers = useCallback(
    /**
     * Gets the available advertisers within the specified period.
     * @param dateFrom The start date of the period.
     * @param dateTo The end date of the period.
     */
    (dateFrom: Moment, dateTo: Moment) => {
      dispatch<RequestAction<AdvertisersRequest, AdvertisersResponse>>({
        type: sagaTypes.campaigns.requests.advertisers.request,
        payload: {
          dateFrom,
          dateTo,
        },
        onFail: handleFailWithProblem(
          i18n._(t`Er is iets misgegaan bij het ophalen van de adverteerders.`)
        ),
      });
    },
    [dispatch, i18n]
  );

  useEffect(() => {
    resetFields();
    if (pathname === navigationPaths.CampaignsRequestsCreateNew) {
      dispatch({
        type: sagaTypes.campaigns.requests.orderRequest.clear,
      });
    } else {
      dispatch({
        type: sagaTypes.campaigns.requests.orderRequest.request,
        payload: orderRequestId,
        onSuccess: (
          _statusCode: HttpStatusCode,
          response?: OrderRequestGetResponse
        ) => {
          if (response?.orderRequest) {
            const {
              startDate,
              advertiser: { id: advertiserId },
              subOrders,
            } = response.orderRequest;
            const period = periods.find(({ startDate: pStart }) =>
              moment(pStart).isSame(moment(startDate), "day")
            );
            if (period) {
              setSelectedPeriod([
                moment(period.startDate),
                moment(period.endDate),
              ]);
            }

            setSelectedAdvertiserId(advertiserId);
            setCurrentSubOrders(subOrdersToFormValues(subOrders));
          }
        },
      });
    }
  }, [dispatch, orderRequestId, pathname, periods, resetFields]);

  useEffect(() => {
    // als er een periode is gekozen, lijst met beschikbare adverteerders ophalen
    if (selectedPeriod && selectedPeriod[0] && selectedPeriod[1]) {
      getAdvertisers(selectedPeriod[0], selectedPeriod[1]);
    }
  }, [getAdvertisers, selectedPeriod]);

  const { advertisers, loading: advertisersLoading } = useSelector(
    ({
      requests: {
        advertisers: advertisersInStore,
        orderRequest: { value: orderRequestInStore },
      },
    }: StoreModel) => ({
      advertisers: [
        ...new Set(
          [
            ...(advertisersInStore.value?.advertisers ?? emptyAdvertisers),
            orderRequestInStore?.orderRequest?.advertiser,
          ].filter((a): a is Advertiser => !!a)
        ),
      ],
      loading: advertisersInStore.status.loading,
    })
  );

  useEffect(() => {
    // als er een adverteerder + periode gekozen is: producten + pakketten ophalen
    if (
      selectedAdvertiserId &&
      selectedPeriod &&
      selectedPeriod[0] &&
      selectedPeriod[1]
    ) {
      dispatch<RequestAction<ProductsRequest, ProductsResponse>>({
        type: sagaTypes.campaigns.requests.products.request,
        payload: {
          dateFrom: selectedPeriod[0],
          dateTo: selectedPeriod[1],
          advertiserId: selectedAdvertiserId,
          orderRequestId,
        },
        onFail: handleFailWithProblem(
          i18n._(t`De producten konden niet geladen worden.`)
        ),
      });
      dispatch<RequestAction<PackagesRequest, PackagesResponse>>({
        type: sagaTypes.campaigns.requests.packages.request,
        payload: {
          dateFrom: selectedPeriod[0],
          dateTo: selectedPeriod[1],
          advertiserId: selectedAdvertiserId,
          orderRequestId,
        },
        onFail: handleFailWithProblem(
          i18n._(t`De pakketten konden niet geladen worden.`)
        ),
      });
    }
  }, [dispatch, i18n, orderRequestId, selectedAdvertiserId, selectedPeriod]);

  const { products, packages, loadingPackages, loadingProducts } = useSelector(
    ({
      requests: { products: productsInStore, packages: packagesInStore },
    }: StoreModel) => {
      const prods = productsInStore.value?.products ?? emptyProducts;

      // gets already used products; per operator only 1 product can be used in a request
      const selectedProds = [
        ...new Set(
          currentSubOrders.filter((s) => s.product).map((s) => s.product)
        ),
      ] as string[];

      // gets already used operators
      const selectedOperators = selectedProds.map((id) => id.split(":")[0]);

      return {
        products: prods.filter(
          (p) =>
            selectedProds.indexOf(p.id) > -1 ||
            selectedOperators.indexOf(p.id.split(":")[0]) === -1
        ),
        packages: packagesInStore.value?.packages ?? emptyPackages,
        loadingPackages: packagesInStore.status.loading,
        loadingProducts: productsInStore.status.loading,
      };
    }
  );

  const valuesToDto = useCallback(
    ({ id, name, subOrders = emptySubOrders }: CampaignValues) => {
      const { name: advertiserName, operator: advertiserOperator }: Advertiser =
        advertisers.find((a) => a.id === selectedAdvertiserId) || {
          id: "",
          name: "",
          operator: "Tip",
          orders: [],
        };
      const orderRequest: OrderRequest = {
        id,
        advertiser: {
          id: selectedAdvertiserId || "",
          name: advertiserName,
          operator: advertiserOperator,
          orders: [],
        },
        budget: subOrders
          .map((so) => so.budget || 0.0)
          .reduce((prev, current) => prev + current, 0),
        campaignName: name,
        contactPerson: { email: userEmail, name: userName },
        startDate:
          selectedPeriod && selectedPeriod[0]
            ? selectedPeriod[0].toISOString(true)
            : undefined,
        endDate:
          selectedPeriod && selectedPeriod[1]
            ? selectedPeriod[1].toISOString(true)
            : undefined,
        product: undefined,
        subOrders: subOrders.map(
          ({
            budget,
            daysOfWeek,
            grps,
            operator,
            pkg,
            product,
            remarks,
            requestStatus,
            spotLengths,
            spots,
            subOrderPeriod,
            targetGroup,
          }) => {
            const soPackage: Package = packages.find((p) => p.code === pkg) || {
              code: "",
              description: "",
              operator: "Tip",
            };
            return {
              budget: budget || 0.0,
              daysOfWeek,
              startDate:
                (subOrderPeriod &&
                  subOrderPeriod[0] &&
                  subOrderPeriod[0].toISOString(true)) ||
                moment().toISOString(true),
              endDate:
                (subOrderPeriod &&
                  subOrderPeriod[1] &&
                  subOrderPeriod[1].toISOString(true)) ||
                moment().toISOString(true),
              grps,
              operator: operator || "Tip",
              package: soPackage,
              product: products.find((p) => p.id === product) || emptyProduct,
              remarks,
              requestStatus,
              spots: spots ?? emptyOrderRequestSpots,
              subOrderRequestLengths:
                (spotLengths &&
                  spotLengths.map((sl) => ({
                    preferredPosition: preferredPositionNone,
                    spotLength: sl,
                  }))) ||
                [],
              targetGroup:
                soPackage.details?.targetGroups?.find(
                  (tg) => tg.id === targetGroup
                ) || emptyTargetGroup,
            };
          }
        ),
      };
      return orderRequest;
    },
    [
      advertisers,
      packages,
      products,
      selectedAdvertiserId,
      selectedPeriod,
      userEmail,
      userName,
    ]
  );

  const handlePeriodChanged = useCallback(
    /**
     * Handles a change in the selected period.
     * @param period The newly selected period.
     */
    (period: RangeValue<Moment>) => {
      setSelectedPeriod(period);
    },
    []
  );

  const handleAdvertiserIdChanged = useCallback(
    /**
     * Handles a change in the selected advertiser identifier.
     * @param advertiserId
     */
    (advertiserId: string) => {
      setSelectedAdvertiserId(advertiserId);
    },
    []
  );

  const handleCreate = useCallback(() => {
    const values = getFieldsValue() as CampaignValues;
    const orderRequest = valuesToDto(values);

    dispatch({
      type: sagaTypes.campaigns.requests.create.request,
      payload: {
        orderRequest,
      },
      onFail: () => {
        message.error(
          i18n._(t`Campagne ${orderRequest.campaignName} aanmaken mislukt.`)
        );
      },
      onSuccess: (
        _statusCode: HttpStatusCode,
        response?: OrderRequestCreateResponse
      ) => {
        const { id } = response || { id: "" };
        reloadOpenRequests();
        setFieldsValue({
          id,
          subOrders: (values.subOrders ?? emptySubOrders).map((so) => ({
            ...so,
            submitted: false,
          })),
        });
        triggerAnalyticsEvent(orderRequestCreateCategory, id);
        navigate(
          navigationPaths.CampaignsRequestsEdit.replace(":orderRequestId", id)
        );
        message.success(
          i18n._(t`Campagne ${orderRequest.campaignName} aangemaakt.`)
        );
      },
    });
  }, [
    dispatch,
    getFieldsValue,
    i18n,
    navigate,
    reloadOpenRequests,
    setFieldsValue,
    valuesToDto,
  ]);

  const handleDelete = useCallback(
    /**
     * Deletes an order request.
     */
    () => {
      setIsDeleting(true);
      dispatch({
        type: sagaTypes.campaigns.requests.delete.request,
        payload: orderRequestValue?.orderRequest?.id || "",
        onSuccess: () => {
          reloadOpenRequests();
          triggerAnalyticsEvent(
            orderRequestDeleteCategory,
            orderRequestValue?.orderRequest?.id
          );
          navigate(navigationPaths.CampaignsRequests);
        },
      });
    },
    [
      dispatch,
      navigate,
      orderRequestValue?.orderRequest?.id,
      reloadOpenRequests,
    ]
  );

  const handleUpdate = useCallback(
    /**
     * Handles an update of the current order request.
     */
    () => {
      const values = getFieldsValue() as CampaignValues;
      const orderRequest = valuesToDto(values);

      dispatch({
        type: sagaTypes.campaigns.requests.update.request,
        payload: {
          orderRequest,
        },
        onFail: () => {
          message.error(
            i18n._(t`Campagne ${orderRequest.campaignName} bijwerken mislukt.`)
          );
        },
        onSuccess: () => {
          reloadOpenRequests();
          setFieldsValue({
            subOrders: (values.subOrders ?? emptySubOrders).map((so) => ({
              ...so,
              submitted: false,
            })),
          });
          dispatch({
            type: sagaTypes.campaigns.requests.orderRequest.request,
            payload: values.id,
            onSuccess: (
              _statusCode: HttpStatusCode | undefined,
              response: OrderRequestGetResponse | undefined
            ) => {
              if (response?.orderRequest) {
                setCurrentSubOrders(
                  subOrdersToFormValues(response.orderRequest.subOrders)
                );
              }
            },
          });

          message.success(
            i18n._(t`Campagne ${orderRequest.campaignName} bijgewerkt.`)
          );
        },
      });
    },
    [
      dispatch,
      getFieldsValue,
      i18n,
      reloadOpenRequests,
      setFieldsValue,
      valuesToDto,
    ]
  );

  const handleSubmit = useCallback(
    /**
     * Handles a request to submit the order request.
     */
    () => {
      setSubmissionModalVisible(true);
    },
    []
  );

  const handleSubmitCancel = useCallback(
    /**
     * Handles a cancellation of the submission of the order request.
     */
    () => {
      setSubmissionModalVisible(false);
    },
    []
  );

  const handleSubmitConfirm = useCallback(
    (sendConfirmation: boolean) => {
      const id = getFieldValue(fieldIdName);
      const campaignName = getFieldValue(fieldNameName);

      dispatch({
        type: sagaTypes.campaigns.requests.submit.request,
        payload: {
          id,
          sendConfirmation,
        },
        onFail: handleFailWithProblem(i18n._(t`Indienen mislukt.`), () => {
          dispatch({
            type: sagaTypes.campaigns.requests.orderRequest.request,
            payload: id,
          });
        }),
        onSuccess: () => {
          setSubmissionModalVisible(false);
          dispatch({
            type: sagaTypes.campaigns.requests.orderRequest.request,
            payload: id,
          });

          reloadOpenRequests();

          triggerAnalyticsEvent(orderRequestSubmitCategory, id);

          message.success(i18n._(t`Campagne ${campaignName} ingediend.`));
        },
      });
    },
    [dispatch, getFieldValue, i18n, reloadOpenRequests]
  );

  const hasConcepts = useMemo(
    () =>
      Boolean(
        pathname !== navigationPaths.CampaignsRequestsCreateNew &&
          orderRequestValue &&
          orderRequestValue.orderRequest &&
          (!orderRequestValue.orderRequest.subOrders ||
            orderRequestValue.orderRequest.subOrders.length === 0 ||
            orderRequestValue.orderRequest.subOrders?.some(
              (so) => so.requestStatus !== "Submitted"
            ))
      ),
    [orderRequestValue, pathname]
  );
  const warnBeforeUnloadMessage = useMemo(
    () =>
      i18n._(
        t`Weet je zeker dat je de pagina wilt verlaten en dat je de aanvraag een concept wilt laten blijven?`
      ),
    [i18n]
  );
  const warnBeforeUnloadNotificationOptions = useMemo(
    () => ({
      message: i18n._(t`Aanvraag indienen`),
      description: warnBeforeUnloadMessage,
      duration: 10,
    }),
    [i18n, warnBeforeUnloadMessage]
  );

  return (
    <>
      <WarnBeforeUnload
        active={hasConcepts && !isDeleting}
        message={warnBeforeUnloadMessage}
        notificationOptions={warnBeforeUnloadNotificationOptions}
      />
      <MainContent>
        <OrderRequestWizard
          advertisers={advertisers}
          advertisersLoading={advertisersLoading}
          campaignForm={campaignForm}
          hasConcepts={hasConcepts}
          onAdvertiserIdChanged={handleAdvertiserIdChanged}
          onCreate={handleCreate}
          onDelete={handleDelete}
          onPeriodChanged={handlePeriodChanged}
          onSubmit={handleSubmit}
          onUpdate={handleUpdate}
          packages={packages}
          packagesLoading={loadingPackages}
          periods={periods}
          periodsLoading={periodsLoading}
          products={products}
          productsLoading={loadingProducts}
          spotLengthIndices={spotLengthIndices}
          subOrders={currentSubOrders}
        />
        <OrderRequestSubmissionModalContainer
          loading={submitLoading}
          onCancel={handleSubmitCancel}
          onConfirm={handleSubmitConfirm}
          visible={submissionModalVisible}
        />
      </MainContent>
    </>
  );
};

export default memo(OrderRequestWizardContainer);
