import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { memo } from "react";

import Counter from "../../models/Counter";
import DashboardStatistic from "../../views/DashboardStatistic";
import Flex from "../../views/layout/Flex";

interface CampaignStatisticsProps {
  counter: Counter;
}

const CampaignStatistics = memo(
  ({ counter: { requested = 0, booked = 0 } }: CampaignStatisticsProps) => {
    const { i18n } = useLingui();

    return (
      <Flex type="placeholder">
        <DashboardStatistic title={i18n._(t`Aangevraagd`)} value={requested} />
        <DashboardStatistic title={i18n._(t`Geboekt`)} value={booked} />
      </Flex>
    );
  }
);

export default CampaignStatistics;
