import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { DatePicker, Form, Space } from "antd";
import { compact } from "lodash/fp";
import moment from "moment/moment";
import React, { ReactNode, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CalendarEventFilter } from "../../models/Calendar";
import { defaultCalendarFilter } from "../../models/Dashboard";
import { filterCalendarAction } from "../../store/calendar/actions";
import { calendarCell } from "../../store/calendar/cells";
import { StoreModel } from "../../store/models";
import { isNonEmptyArray } from "../../utils";
import CalendarEventFiltersForm from "../../views/calendar/CalendarEventFiltersForm";
import ShowButton from "../actions/ShowButton";

export interface CalendarEventFiltersProps {
  inline?: boolean | "right";
  extra?: ReactNode;
}

const CalendarEventFilters = ({ inline, extra }: CalendarEventFiltersProps) => {
  const { i18n } = useLingui();
  const [form] = Form.useForm<CalendarEventFilter>();

  const dispatch = useDispatch();
  const handleFinish = useCallback(
    (values: CalendarEventFilter) => {
      dispatch(filterCalendarAction(values));
      dispatch({ type: calendarCell.events.invalidate });
      dispatch(calendarCell.require());
    },
    [dispatch]
  );

  const filterFromStore = useSelector(
    (state: StoreModel) => state.calendar.filter
  );
  const formPeriod = Form.useWatch<CalendarEventFilter["period"] | undefined>(
    "period",
    form
  );

  return (
    <CalendarEventFiltersForm
      form={form}
      initialValues={filterFromStore}
      onFinish={handleFinish}
      inline={inline}
    >
      <Form.Item
        label={i18n._(t`Periode`)}
        name="period"
        rules={[{ required: true, message: i18n._(t`Selecteer een periode`) }]}
      >
        <DatePicker.RangePicker
          format="ll"
          ranges={{
            [i18n._(t`Basis selectie`)]: defaultCalendarFilter.period,
            [i18n._(t`Vorige maand`)]: [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
            [i18n._(t`Huidige maand`)]: [
              moment().startOf("month"),
              moment().endOf("month"),
            ],
            [i18n._(t`Volgende maand`)]: [
              moment().add(1, "month").startOf("month"),
              moment().add(1, "month").endOf("month"),
            ],
          }}
        />
      </Form.Item>

      <Form.Item dependencies={["period"]}>
        {() => (
          <Space>
            <ShowButton
              showIcon
              disabled={
                !(
                  isNonEmptyArray(formPeriod) &&
                  compact(formPeriod).length === 2
                )
              }
            />
            {extra}
          </Space>
        )}
      </Form.Item>
    </CalendarEventFiltersForm>
  );
};

export default CalendarEventFilters;
