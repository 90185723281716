import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Col, Form, Row } from "antd";
import React, { memo, useCallback, useEffect } from "react";

import {
  fieldBudgetName,
  fieldGrpsName,
} from "../../../../../models/Campaigns/Requests/models";
import BudgetGrpsView from "../../../../../views/campaigns/requests/subOrders/BudgetGrpsView";
import Euro from "../../../../../views/Euro";
import { labelCol, wrapperCol } from "../../../../../views/layout/Form";
import { BudgetGrpsFieldProps } from "../models";
import BudgetField from "./BudgetField";
import GrpsField from "./GrpsField";

const BudgetGrpsField = memo(
  ({
    realGrpPrice,
    budgetEnabled,
    budgetVisible,
    grpsEnabled,
    grpsVisible,
    form: { setFieldsValue, getFieldValue },
  }: BudgetGrpsFieldProps) => {
    const { i18n } = useLingui();

    const handleBudgetChange = useCallback(
      (value?: number | string | null) => {
        if (value && typeof value === "number") {
          setFieldsValue({
            [fieldGrpsName]: parseFloat((value / realGrpPrice).toFixed(2)),
          });
        }
      },
      [realGrpPrice, setFieldsValue]
    );

    const handleGrpChange = useCallback(
      /**
       * Handles a change in the GRP value.
       * @param value The new GRP value.
       */
      (value?: number | string | null) => {
        if (value && typeof value === "number") {
          setFieldsValue({
            [fieldBudgetName]: parseFloat((value * realGrpPrice).toFixed(2)),
          });
        }
      },
      [realGrpPrice, setFieldsValue]
    );

    useEffect(
      /**
       * update GRP's if price changes; budget is leading
       */
      () => {
        const currentBudget = getFieldValue(fieldBudgetName);
        if (currentBudget && realGrpPrice) {
          handleBudgetChange(currentBudget);
        }
      },
      [getFieldValue, handleBudgetChange, realGrpPrice]
    );

    return (
      <Form.Item
        label={i18n._(t`Plannen op basis van`)}
        labelCol={labelCol}
        help={
          realGrpPrice > 0 && (
            <Trans>
              Let op, het aantal GRPs is slechts een <strong>indicatie</strong>,
              hieraan kunnen geen rechten worden ontleend. Als reële GRP-prijs
              wordt <Euro amount={realGrpPrice} inline /> gebruikt.
            </Trans>
          )
        }
        wrapperCol={wrapperCol}
      >
        <BudgetGrpsView>
          <Row>
            <Col span={12}>
              {budgetVisible && (
                <BudgetField
                  enabled={budgetEnabled}
                  onChange={handleBudgetChange}
                  isCombiField
                />
              )}
            </Col>
            <Col span={12}>
              {grpsVisible && (
                <GrpsField enabled={grpsEnabled} onChange={handleGrpChange} />
              )}
            </Col>
          </Row>
        </BudgetGrpsView>
      </Form.Item>
    );
  }
);

export default BudgetGrpsField;
