import React, {
  MouseEventHandler,
  ReactNode,
  ReactText,
  memo,
  useCallback,
} from "react";

import { history } from "../App";

/**
 * Helaas werkt een `Link` van react-router niet binnen een `notification` van Ant Design
 * omdat deze buiten de <Router /> context valt, daarom deze manier van linken.
 */
const LinkInNotification = memo(
  ({ to, children }: { to: string; children: ReactNode | ReactText }) => {
    const handleClick = useCallback<MouseEventHandler>(
      (event) => {
        event.preventDefault();
        history.push(to);
      },
      [to]
    );

    return (
      <a href={to} onClick={handleClick}>
        {children}
      </a>
    );
  }
);

export default LinkInNotification;
