import {
  ApiOutlined,
  GlobalOutlined,
  PoweroffOutlined,
  TranslationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { LocalizationSetAction } from "../../store/localization/models";
import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { clearTokens } from "../../store/token";
import { Culture, useCulture } from "../../utils/hooks";
import navigationPaths from "../../utils/navigation";
import UserContextDropdown from "../../views/user/UserContextDropdown";
import UserContextItem from "../../views/user/UserContextItem";

const externalApisKey = navigationPaths.ExternalApiClients;
const logOffKey = "LogOff";
const wwwKey = "www";

const UserContextMenu =
  /**
   * Renders a component that displays a user account's context menu.
   * @constructor Initializes a new instance of UserContextMenu.
   */
  ({ text }: { text: string }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const culture = useCulture();

    const handleSwitchCulture = useCallback(
      /**
       * Switches the currently preferred culture.
       */
      (): void => {
        const newCulture =
          culture === Culture.enus || culture === Culture.enUS
            ? Culture.nl
            : Culture.enUS;
        dispatch<LocalizationSetAction>({
          type: sagaTypes.localization.set.request,
          onSuccess: () => {
            window.location.reload();
          },
          payload: {
            preferredCulture: newCulture,
          },
        });
      },
      [culture, dispatch]
    );

    const handleLogOffClick = useCallback(
      /**
       * Logs the user off.
       */
      (): void => {
        clearTokens();
        dispatch({ type: sagaTypes.users.logout });
        navigate(navigationPaths.Login);
      },
      [dispatch, navigate]
    );

    const websiteUrl = useSelector(
      (store: StoreModel) => store.application.options.websiteUrl
    );
    const handleWwwClick = useCallback(() => {
      if (websiteUrl) {
        window.location.assign(websiteUrl);
      }
    }, [websiteUrl]);

    return (
      <UserContextDropdown text={text}>
        <UserContextItem key="localization" onClick={handleSwitchCulture}>
          <TranslationOutlined />
          {culture === Culture.enus && "Nederlands"}
          {culture === Culture.enUS && "Nederlands"}
          {culture === Culture.nl && "English (United States)"}
        </UserContextItem>
        <UserContextItem key={externalApisKey}>
          <Link to={navigationPaths.UserProfile}>
            <UserOutlined />
            <Trans>Profiel</Trans>
          </Link>
        </UserContextItem>
        <UserContextItem key={externalApisKey}>
          <Link to={navigationPaths.ExternalApiClients}>
            <ApiOutlined />
            <Trans>App koppelen</Trans>
          </Link>
        </UserContextItem>
        {websiteUrl && (
          <UserContextItem key={wwwKey} onClick={handleWwwClick}>
            <GlobalOutlined />
            <Trans>Website Screenforce</Trans>
          </UserContextItem>
        )}
        <UserContextItem key={logOffKey} onClick={handleLogOffClick}>
          <PoweroffOutlined />
          <Trans>Uitloggen</Trans>
        </UserContextItem>
      </UserContextDropdown>
    );
  };

export default memo(UserContextMenu);
