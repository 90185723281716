import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Divider, Form, Spin, Steps, message } from "antd";
import { map } from "lodash/fp";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardTileInput } from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import {
  dashboardDataCell,
  dashboardDataWithExtrasCell,
  dashboardNotUsedOrdersCell,
  dashboardOrderListCell,
  dashboardTilesCell,
} from "../../store/dashboard/cell";
import { skoTargetGroupCell } from "../../store/sko/cells";
import { handleAsyncFailWithProblem } from "../../utils";
import Centered from "../../views/Centered";
import DashboardModal from "../../views/dashboard/DashboardModal";
import { labelCol, wrapperColShallow } from "../../views/layout/Form";
import DashboardCampaignForm from "./DashboardCampaignForm";
import DashboardCommercialForm from "./DashboardCommercialForm";
import DashboardFinishForm from "./DashboardFinishForm";

interface DashboardStepContent {
  key: string;
  title: string;
  content: React.ReactNode;
}

export interface DashboardWizardProps {
  onSuccess: () => void;
  onDownload: OnBlobDownloadFunc;
  initialValues?: DashboardTileInput | null;
  show?: boolean;
  onClose: () => void;
}

export interface DashboardWizardButtonProps {
  show: boolean;
  onClick: (values: DashboardTileInput | null) => void;
}

export const DashboardWizardButton = ({
  show,
  onClick,
}: DashboardWizardButtonProps) => {
  const handleClick = useCallback(() => {
    onClick(null);
  }, [onClick]);

  return (
    <Centered>
      <Button type="primary" disabled={show} onClick={handleClick}>
        <Trans>Nieuwe tegel toevoegen</Trans>
      </Button>
    </Centered>
  );
};

const DashboardWizard = ({
  onSuccess,
  onDownload,
  initialValues,
  show,
  onClose,
}: DashboardWizardProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [form] = Form.useForm<DashboardTileInput>();

  const loadData = useCallback(() => {
    const today = moment();
    dispatch(
      dashboardOrderListCell.require({
        dateFrom: today.clone().subtract(1, "month").startOf("month"),
        dateTo: today.clone().add(1, "month").endOf("month"),
      })
    );
    dispatch(skoTargetGroupCell.require({ date: today }));
  }, [dispatch]);

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (show) {
      setCurrent(0);
      loadData();

      if (initialValues) {
        form.setFieldsValue(initialValues);
      } else {
        form.resetFields();
      }
    }
  }, [form, initialValues, loadData, show]);

  const handleOk = useCallback(() => {
    // Submit the form
    form.submit();
  }, [form]);

  const handleClose = useCallback(() => {
    // Reset the form and close the modal
    form.resetFields();
    onClose();
    dispatch({ type: dashboardOrderListCell.events.clear });
  }, [dispatch, form, onClose]);

  const dashboardOrdersFromStore = useSelector(dashboardOrderListCell.select);
  const dashboardTilesFromStore = useSelector(dashboardTilesCell.select);
  const targetGroupsFromStore = useSelector(skoTargetGroupCell.select);

  // Loading state
  const loading = useMemo(
    () =>
      dashboardOrdersFromStore.status.loading ||
      dashboardTilesFromStore.status.loading ||
      targetGroupsFromStore.status.loading,
    [
      dashboardOrdersFromStore.status.loading,
      dashboardTilesFromStore.status.loading,
      targetGroupsFromStore.status.loading,
    ]
  );

  // Steps in the wizard
  const steps = useMemo<DashboardStepContent[]>(
    () => [
      {
        key: "campaign",
        title: i18n._(t`Campagne samenstellen`),
        content: <DashboardCampaignForm form={form} />,
      },
      {
        key: "commercial",
        title: i18n._(t`Commercial koppelen`),
        content: (
          <DashboardCommercialForm form={form} onDownload={onDownload} />
        ),
      },
      {
        key: "finish",
        title: i18n._(t`Afronden`),
        content: <DashboardFinishForm form={form} onDownload={onDownload} />,
      },
    ],
    [form, i18n, onDownload]
  );

  const handlePrevious = useCallback(() => {
    form.validateFields().then(() => {
      setCurrent((c) => c - 1);
    });
  }, [form]);
  const handleNext = useCallback(() => {
    form.validateFields().then(() => {
      setCurrent((c) => c + 1);
    });
  }, [form]);

  // Form
  const handleFormFinish = useCallback(
    (values: DashboardTileInput) => {
      dispatch(
        dashboardTilesCell.create(values, {
          onSuccess: () => {
            handleClose();
            message.success(i18n._(t`Tegel is opgeslagen.`));

            // Refresh
            dispatch({ type: dashboardTilesCell.events.invalidate });
            dispatch({ type: dashboardDataCell.events.invalidate });
            dispatch({ type: dashboardDataWithExtrasCell.events.invalidate });
            dispatch({ type: dashboardNotUsedOrdersCell.events.invalidate });

            onSuccess();
          },
          onFail: handleAsyncFailWithProblem(
            i18n._(t`Er is iets misgegaan bij het opslaan van deze tegel.`)
          ),
        })
      );
    },
    [dispatch, handleClose, i18n, onSuccess]
  );

  return (
    <DashboardModal
      onOk={current === steps.length - 1 ? handleOk : handleNext}
      okText={
        current === steps.length - 1 ? i18n._(t`Opslaan`) : i18n._(t`Volgende`)
      }
      onCancel={current === 0 ? handleClose : handlePrevious}
      cancelText={current === 0 ? i18n._(t`Annuleren`) : i18n._(t`Vorige`)}
      loading={loading}
      open={show}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleFormFinish}
          disabled={dashboardTilesFromStore.status.loading}
          labelCol={labelCol}
          wrapperCol={wrapperColShallow}
        >
          <Steps
            current={current}
            items={map((s: DashboardStepContent) => ({
              key: s.key,
              title: s.title,
            }))(steps)}
            size="small"
          />

          <Divider />

          {steps[current].content}
        </Form>
      </Spin>
    </DashboardModal>
  );
};

export default DashboardWizard;
