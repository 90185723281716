import { Col, Row } from "antd";
import React, { Children, PropsWithChildren, memo } from "react";

const DashboardContent = memo(({ children }: PropsWithChildren) => (
  <section className="dashboardContent">
    {children &&
      Children.map(children, (child) => (
        <Row>
          <Col span={24}>{child}</Col>
        </Row>
      ))}
  </section>
));

export default DashboardContent;
