import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Radio, Typography } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { fieldOperatorName } from "../../../../../models/Campaigns/Requests/models";
import { Operator, operatorLabels } from "../../../../../models/Operator";
import { StoreModel } from "../../../../../store/models";
import { labelCol, wrapperColShallow } from "../../../../../views/layout/Form";
import { OperatorPickerProps } from "../models";

const OperatorPicker = memo(
  ({
    enabled,
    form: { setFieldsValue },
    onChange,
    operators,
    products,
    packages,
  }: OperatorPickerProps) => {
    const { i18n } = useLingui();
    const orderRequestLinks = useSelector(
      (store: StoreModel) => store.application.options.links
    );

    const filteredOperators = useMemo(
      /**
       * filter operators: only with packages and products
       */
      () =>
        operators.filter(
          (op) =>
            packages.filter((p) => p.operator === op).length > 0 &&
            products.filter((p) => p.id.startsWith(op)).length > 0
        ),
      [operators, packages, products]
    );

    const handleChange = useCallback(
      /**
       * Handles a change in the selected operator.
       * @param target The target HTML element that has a changed value.
       */
      ({ target }: RadioChangeEvent) => {
        onChange(target.value);
      },
      [onChange]
    );

    useEffect(
      /**
       * If there is only one operator, it should be selected immediately.
       */
      () => {
        if (filteredOperators.length === 1) {
          setFieldsValue({ [fieldOperatorName]: operators[0] });
        }
      },
      [filteredOperators.length, operators, setFieldsValue]
    );

    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const selectedOperator = getFieldValue(fieldOperatorName) as Operator;
          const moreInfoLink = orderRequestLinks?.[selectedOperator];

          return (
            <Form.Item
              label={i18n._(t`Exploitant`)}
              labelCol={labelCol}
              name={fieldOperatorName}
              rules={[
                {
                  required: true,
                  message: i18n._(t`Exploitant is verplicht.`),
                },
              ]}
              wrapperCol={wrapperColShallow}
              help={
                moreInfoLink && (
                  <Typography.Paragraph>
                    <a href={moreInfoLink} target="_blank" rel="noreferrer">
                      <Trans>
                        Meer inkoop informatie voor{" "}
                        {operatorLabels[selectedOperator]}
                      </Trans>
                    </a>
                  </Typography.Paragraph>
                )
              }
            >
              <Radio.Group
                buttonStyle="solid"
                disabled={!enabled}
                onChange={handleChange}
              >
                {filteredOperators
                  .map((op) => ({
                    label: operatorLabels[op],
                    value: op,
                  }))
                  .sort(({ label: labelA }, { label: labelB }) =>
                    labelA.localeCompare(labelB)
                  )
                  .map(({ label, value }) => (
                    <Radio.Button key={value} value={value}>
                      {label}
                    </Radio.Button>
                  ))}
              </Radio.Group>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
);

export default OperatorPicker;
