import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { memo } from "react";

import { Package } from "../../models/packages";
import Ellipsis from "../Ellipsis";

const emptyValue = t`n.v.t.`;

interface PackageViewProps {
  packageCode?: string;
  packages: Package[];
  spotLengths?: number[];
}

const PackageView = memo(
  ({ packageCode, packages, spotLengths }: PackageViewProps) => {
    const { i18n } = useLingui();

    const packageName =
      packages?.find((p) => p.code === packageCode)?.description ||
      i18n._(emptyValue);

    const spotLengthsText = spotLengths
      ? spotLengths
          .filter((length) => length && length !== 0)
          .map((length) => length.toString(10))
          .reduce(
            (previousValue, currentValue) => `${previousValue}+${currentValue}`
          )
      : i18n._(emptyValue);

    return <Ellipsis text={`${packageName} ${spotLengthsText}"`} />;
  }
);

export default PackageView;
