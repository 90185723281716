import { useLingui } from "@lingui/react";
import React, { memo } from "react";
import { Link } from "react-router-dom";

import navigationPaths, { navigationHeaders } from "../../utils/navigation";

const ForgotPasswordButton = memo(() => {
  const { i18n } = useLingui();

  return (
    <div className="login-form-forgot">
      <Link to={navigationPaths.ForgotPassword}>
        {i18n._(navigationHeaders.ForgotPassword)}
      </Link>
    </div>
  );
});

export default ForgotPasswordButton;
