import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { memo } from "react";

import ActionButtonProps from "./ActionButtonProps";

const SaveButton = memo(({ disabled, loading }: ActionButtonProps) => (
  <Button
    type="link"
    disabled={!!disabled}
    htmlType="submit"
    loading={!!loading}
  >
    <Trans id="Opslaan">Opslaan</Trans>
  </Button>
));

export default SaveButton;
