import { useLingui } from "@lingui/react";
import { Modal } from "antd";
import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import { DashboardCommercial } from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import CommercialVideo from "../commercials/CommercialVideo";
import Image from "../Image";
import MediaCover from "../MediaCover";

const DashboardCommercialView = ({
  commercial,
  width,
  height,
  onDownload,
  style,
}: {
  commercial: DashboardCommercial;
  width: number;
  height: number;
  onDownload: OnBlobDownloadFunc;
  style?: CSSProperties;
}) => {
  const { i18n } = useLingui();
  const [dataUri, updateUri] = useState("");

  const openDialog = useCallback(() => {
    Modal.info({
      title: `${commercial.title} (${commercial.lengthInSeconds}")`,
      icon: null,
      content: (
        <CommercialVideo
          commercial={{
            id: commercial.commercialDeliveryId,
            lowResFilename: commercial.lowResFilename,
          }}
          thumbnailDataUri={dataUri}
          onDownload={onDownload}
          i18n={i18n}
          mediaCover
        />
      ),
      width: 720,
    });
  }, [
    commercial.commercialDeliveryId,
    commercial.lengthInSeconds,
    commercial.lowResFilename,
    commercial.title,
    dataUri,
    i18n,
    onDownload,
  ]);

  useEffect(() => {
    onDownload(
      commercial.commercialDeliveryId,
      commercial.thumbnail,
      (blob) => {
        updateUri(URL.createObjectURL(blob as Blob));
      }
    );
  }, [commercial.commercialDeliveryId, commercial.thumbnail, onDownload]);

  return (
    <MediaCover enabled>
      <div style={{ display: "block", width, height, ...style }}>
        <Image
          alt={commercial.title}
          dataUri={dataUri}
          onClick={openDialog}
          mode="play"
          width={width}
          height={height}
        />
      </div>
    </MediaCover>
  );
};

export default DashboardCommercialView;
