import { Form } from "antd";
import React, { memo, useCallback } from "react";

import { fieldOperatorName } from "../../../../../models/Campaigns/Requests/models";
import { Operator } from "../../../../../models/Operator";
import OperatorPicker from "../fields/OperatorPicker";
import {
  OperatorStepCallbacks,
  OperatorStepReferenceValues,
  OperatorStepStoreValues,
  StepProps,
} from "./models";

const OperatorStep = memo(
  ({
    callbacks: { onReset } = {
      onReset: (): void => {
        /* do nothing */
      },
    },
    enabled,
    form,
    initialValues,
    onFinish,
    referenceValues: { operators, packages, products },
  }: StepProps<
    OperatorStepCallbacks,
    OperatorStepReferenceValues,
    OperatorStepStoreValues
  >) => {
    const { setFieldsValue } = form;

    const handleOperatorChange = useCallback(
      /**
       * If the selected operator changes, the form must be reset.
       * @param newValue The newly selected operator.
       */
      (newValue: Operator) => {
        onReset();
        setFieldsValue({ [fieldOperatorName]: newValue });
      },
      [onReset, setFieldsValue]
    );

    return (
      <Form form={form} initialValues={initialValues} onFinish={onFinish}>
        <OperatorPicker
          enabled={enabled}
          form={form}
          onChange={handleOperatorChange}
          operators={operators}
          products={products}
          packages={packages}
        />
      </Form>
    );
  }
);

export default OperatorStep;
