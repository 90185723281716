import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Tooltip } from "antd";
import React, { FunctionComponent, memo } from "react";

import { PreferredPosition } from "../../models/Spot";

/**
 * The properties of the PreferredPositionView component.
 */
interface PreferredPositionViewProps {
  /**
   * The preferred position.
   */
  preferredPosition: PreferredPosition;

  /**
   * Indicates whether multiple preferred positions may be selected.
   */
  isMulti?: boolean;
}

/**
 * Renders a React component for displaying a preferred position.
 * @constructor Initializes a new instance of PreferredPositionView.
 * @returns {ReactElement<any, any> | null} The rendered component.
 */
const PreferredPositionView: FunctionComponent<PreferredPositionViewProps> = ({
  preferredPosition,
  isMulti,
}) => {
  const { i18n } = useLingui();

  switch (preferredPosition) {
    case "FirstInBlock":
      return (
        <Tooltip
          placement="bottom"
          title={
            isMulti
              ? i18n._(t`Eerste in blok (basis spot)`)
              : i18n._(t`Eerste in blok`)
          }
        >
          <span>
            <Trans>A1</Trans>
          </span>
        </Tooltip>
      );
    case "SecondInBlock":
      return (
        <Tooltip
          placement="bottom"
          title={
            isMulti
              ? i18n._(t`Tweede in blok (basis spot)`)
              : i18n._(t`Tweede in blok`)
          }
        >
          <span>
            <Trans>A2</Trans>
          </span>
        </Tooltip>
      );
    case "BeforeLastInBlock":
      return (
        <Tooltip
          placement="bottom"
          title={
            isMulti
              ? i18n._(t`Een na laatste in blok (tag-on)`)
              : i18n._(t`Een na laatste in blok`)
          }
        >
          <span>
            <Trans>E2</Trans>
          </span>
        </Tooltip>
      );
    case "LastInBlock":
      return (
        <Tooltip
          placement="bottom"
          title={
            isMulti
              ? i18n._(t`Laatste in blok (tag-on)`)
              : i18n._(t`Laatste in blok`)
          }
        >
          <span>
            <Trans>E1</Trans>
          </span>
        </Tooltip>
      );
    default:
      return null;
  }
};

export default memo(PreferredPositionView);
