import { Action, combineReducers } from "redux";

import { CalendarEventFilter } from "../../models/Calendar";
import { defaultCalendarFilter } from "../../models/Dashboard";
import {
  calendarCell,
  createCalendarCell,
  deleteCalendarCell,
  updateCalendarCell,
} from "./cells";

const filterReducer = (
  state: CalendarEventFilter | undefined,
  action: Action<string>
): CalendarEventFilter => {
  switch (action.type) {
    case "CALENDAREVENT_FILTER":
      return (action as unknown as { payload: CalendarEventFilter }).payload;
    case "CALENDAREVENT_RESET":
      return defaultCalendarFilter;
    default:
      return state ?? defaultCalendarFilter;
  }
};

const calendarReducer = combineReducers({
  filter: filterReducer,
  events: calendarCell.reducer,
  create: createCalendarCell.reducer,
  update: updateCalendarCell.reducer,
  delete: deleteCalendarCell.reducer,
});

export default calendarReducer;
