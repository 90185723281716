import React, { ReactNode } from "react";

interface UserContextDropdownProps {
  text: string;
  children?: ReactNode;
}

const UserContextDropdown = ({
  text,
  children,
}: UserContextDropdownProps): JSX.Element => (
  <div className="font-edmondsans bg-transparent flex flex-col justify-around mx-1 space-y-1">
    <div className="text-xs text-primary text-center">{text}</div>
    {children}
  </div>
);

export default UserContextDropdown;
