import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { memo, useCallback, useState } from "react";

import Advertiser, { emptyAdvertisers } from "../../models/Advertiser";
import { emptyOperators } from "../../models/Operator";
import { getAdvertiserName } from "../../utils";
import { advertiserIdsField } from "./constants";

const emptyStringArray: string[] = [];
const AdvertiserSelect = memo(
  ({
    form,
    advertisers,
    loadingAdvertisers,
  }: {
    form: FormInstance;
    advertisers: Advertiser[];
    loadingAdvertisers: boolean;
  }) => {
    const { i18n } = useLingui();
    const advertiserIdsValue =
      form.getFieldValue(advertiserIdsField) ?? emptyStringArray;
    const [selectedOperators, setOperators] = useState(emptyOperators);
    const handleSelectAdvertiser = useCallback(
      (advertiserIds: string) => {
        const selectedAdvertisers: Advertiser[] = !advertiserIds
          ? emptyAdvertisers
          : advertisers.filter(({ id }) => advertiserIds.indexOf(id) > -1);
        const operators = [
          ...new Set(selectedAdvertisers.map(({ operator }) => operator)),
        ];

        // Set a distinct list of operators
        setOperators(operators);
      },
      [advertisers]
    );

    return (
      <Form.Item
        name={advertiserIdsField}
        rules={[
          { required: true, message: i18n._(t`Kies minimaal 1 adverteerder`) },
        ]}
        noStyle
      >
        <Select
          loading={loadingAdvertisers}
          mode="multiple"
          showSearch
          optionFilterProp="title"
          allowClear
          onChange={handleSelectAdvertiser}
        >
          {advertisers.map((a) => {
            const title = getAdvertiserName(advertisers, a.id);
            const disabled =
              advertiserIdsValue.indexOf(a.id) === -1 &&
              selectedOperators.indexOf(a.operator) > -1;
            return (
              <Select.Option
                key={a.id}
                value={a.id}
                title={title}
                disabled={disabled}
              >
                {title}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
);

export default AdvertiserSelect;
