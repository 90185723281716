import React from "react";

export const COLORS: Record<string, string> = {
  Orn: "#ff6600",
  Rtl: "#6e2c57",
  Ster: "#008eaf",
  Talpa: "#de2a1e",
  requestedGrps: "#008eaf",
  bookedGrps: "#6e2c57",
  achievedGrps: "#de2a1e",
  otherSpots: "#de2a1e",
  prefSpots: "#6e2c57",
};

const ChartDot = ({ colorKeyword }: { colorKeyword: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g>
      <circle cx="8" cy="8" r="4" fill={COLORS[colorKeyword]} />
    </g>
  </svg>
);

export default ChartDot;
