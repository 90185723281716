import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreModel } from "../../../store/models";
import { ornImportReportCell } from "../../../store/ornImport/cells";
import MainContent from "../../../views/layout/MainContent";
import OrnImportReportTable from "./OrnImportReportTable";

const OrnImportReportContainer = memo(() => {
  const dispatch = useDispatch();
  const load = useCallback(() => {
    dispatch(ornImportReportCell.require());
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const { value, status } = useSelector(
    ({ ornImportReport }: StoreModel) => ornImportReport
  );

  return (
    <MainContent>
      <Button onClick={load} type="primary">
        <Trans>Overzicht vernieuwen</Trans>
      </Button>
      <OrnImportReportTable value={value} status={status} />
    </MainContent>
  );
});

export default OrnImportReportContainer;
