import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  TreeSelect,
} from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TreeDataNode } from "rc-tree-select/lib/interface";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import Advertiser from "../../models/Advertiser";
import AdvertiserFilter from "../../models/AdvertiserFilter";
import Counter from "../../models/Counter";
import { operatorLabels } from "../../models/Operator";
import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import { instructionsCell } from "../../store/instructions/cells";
import sagaTypes from "../../store/sagaTypes";
import BigScreen from "../../views/layout/BigScreen";
import { wrapperColNoLabel } from "../../views/layout/Form";
import {
  analyzeAllCategory,
  exportAllCategory,
  printAllCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import AnalyzeButton from "../actions/AnalyzeButton";
import { ExportType } from "../actions/ExportActionButtonProps";
import ExportButton from "../actions/ExportButton";
import ShowButton from "../actions/ShowButton";
import CampaignStatistics from "./CampaignStatistics";
import {
  advertiserPrefix,
  advertisersAndOrdersField,
  orderPrefix,
  periodField,
  skoTargetGroupIdField,
} from "./FilterContainer";

const advertisersAndOrdersRequiredValidationMessage = t`Adverteerders en/of Orders is verplicht.`;

interface FilterProps {
  advertisers?: Advertiser[];
  targetGroups?: SkoTargetGroup[];
  counter: Counter | undefined;
  dateFrom: Moment;
  dateTo: Moment;
  exportExcelLoading: boolean;
  exportPdfLoading: boolean;
  filterAdvertisers: AdvertiserFilter[];
  filtersLoading: boolean;
  form: FormInstance;
  onExport: (exportType: ExportType) => void;
  onLoadFilters: (period?: [Moment, Moment]) => void;
  analyzeLoading: boolean;
  onAnalyze: () => void;
}

const Filter = memo(
  ({
    advertisers,
    targetGroups,
    counter,
    dateFrom,
    dateTo,
    exportExcelLoading,
    exportPdfLoading,
    filterAdvertisers,
    filtersLoading,
    form,
    onExport,
    onLoadFilters,
    analyzeLoading,
    onAnalyze,
  }: FilterProps) => {
    const { i18n } = useLingui();
    const { setFieldsValue } = form;
    const dispatch = useDispatch();

    const selectedFilter = Form.useWatch("advertisersAndOrders", form);
    const selectedSkoTargetGroupId = Form.useWatch("skoTargetGroupId", form);

    /**
     * Load the filter values.
     */
    const loadFilters = useCallback(
      (period?: [Moment, Moment]) => {
        if (!period) {
          return;
        }

        onLoadFilters(period);
      },
      [onLoadFilters]
    );

    /**
     * On first load, fetch the filter values using this month as the period.
     */
    useEffect(() => {
      loadFilters([dateFrom, dateTo]);
    }, [dateFrom, dateTo, loadFilters]);

    const advertisersAndOrdersFieldRules = useMemo(
      /**
       * Returns the validation rules for the advertisers and orders field.
       */
      (): Rule[] => [
        {
          message: i18n._(advertisersAndOrdersRequiredValidationMessage),
          required: true,
          validator: (_, value): Promise<void> => {
            if (Array.isArray(value) && value.length > 0) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(i18n._(advertisersAndOrdersRequiredValidationMessage))
            );
          },
        },
      ],
      [i18n]
    );

    const handlePeriodChange = useCallback(
      /**
       * Handles a period change event.
       * @param dates The selected period dates.
       */
      (dates: RangeValue<Moment>) => {
        const period = dates as [Moment, Moment];
        setFieldsValue({ [advertisersAndOrdersField]: [] });
        dispatch({ type: sagaTypes.campaigns.filters.clear });
        dispatch({ type: sagaTypes.campaigns.fetchOrdersFiltered.clear });
        dispatch({ type: "CAMPAIGNS_ANALYZED_CLEAR" });
        dispatch({ type: instructionsCell.events.reset });
        loadFilters(period);
      },
      [dispatch, loadFilters, setFieldsValue]
    );

    const handleAdvertisersAndOrderChange = useCallback(() => {
      dispatch({ type: sagaTypes.campaigns.fetchOrdersFiltered.clear });
      dispatch({ type: "CAMPAIGNS_ANALYZED_CLEAR" });
    }, [dispatch]);

    /**
     * The tree of advertiser filter values.
     */
    const advertisersTree = useMemo(
      () =>
        filterAdvertisers
          ? filterAdvertisers.map(
              ({
                id: advertiserId,
                name: advertiserName,
                operator,
                orders,
              }): TreeDataNode => ({
                key: `${advertiserPrefix}${advertiserId}`,
                value: `${advertiserPrefix}${advertiserId}`,
                title:
                  operator === "Tip"
                    ? advertiserName
                    : `${advertiserName} (${operatorLabels[operator]})`,
                children:
                  orders &&
                  orders.map(
                    ({ id: orderId, name: orderName }): TreeDataNode => ({
                      key: `${orderPrefix}${orderId}`,
                      value: `${orderPrefix}${orderId}`,
                      title: orderName,
                    })
                  ),
              })
            )
          : [],
      [filterAdvertisers]
    );

    /**
     * Tracks whether show should be enabled.
     */
    const showDisabled = useMemo(
      () =>
        !filterAdvertisers ||
        filtersLoading ||
        (selectedFilter?.length ?? 0) === 0,
      [filterAdvertisers, filtersLoading, selectedFilter?.length]
    );

    /**
     * Tracks whether export should be enabled.
     */
    const exportDisabled = useMemo(
      () => showDisabled || !advertisers || advertisers.length === 0,
      [advertisers, showDisabled]
    );

    /**
     * Tracks whether analyze button should be enabled.
     */
    const analyzeDisabled = useMemo(
      () =>
        showDisabled ||
        !advertisers ||
        advertisers.length === 0 ||
        !selectedSkoTargetGroupId ||
        selectedSkoTargetGroupId <= 0,
      [advertisers, selectedSkoTargetGroupId, showDisabled]
    );

    const handleSkoTargetGroupChange = useCallback(
      /**
       * Determines whether the selected target group is changed.
       */
      (value: number) => {
        setFieldsValue({ [skoTargetGroupIdField]: value });
        dispatch({
          type: sagaTypes.campaigns.updateFilter,
          payload: { [skoTargetGroupIdField]: value },
        });
      },
      [dispatch, setFieldsValue]
    );

    const loading = useMemo(
      /**
       * Indicates whether the filter should be temporarily locked in order to allow the application to load lookup values for the filter's fields.
       */
      () => filterAdvertisers.length === 0 && filtersLoading,
      [filterAdvertisers.length, filtersLoading]
    );

    return (
      <Spin spinning={loading}>
        <Card type="inner" title={i18n._(t`Campagnes`)}>
          <Tooltip
            title={i18n._(
              t`De periode is van toepassing op de selectie van orders, niet van de spots`
            )}
            placement="leftTop"
          >
            <div>
              <Form.Item
                label={i18n._(t`Periode`)}
                name={periodField}
                rules={[
                  { required: true, message: i18n._(t`Periode is verplicht.`) },
                ]}
              >
                <DatePicker.RangePicker
                  format="D MMM YYYY"
                  onChange={handlePeriodChange}
                  ranges={{
                    [i18n._(t`Vorige maand`)]: [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                    [i18n._(t`Huidige maand`)]: [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    [i18n._(t`Volgende maand`)]: [
                      moment().add(1, "month").startOf("month"),
                      moment().add(1, "month").endOf("month"),
                    ],
                  }}
                />
              </Form.Item>
            </div>
          </Tooltip>
          <Form.Item
            shouldUpdate
            label={i18n._(t`Adverteerders en/of Orders`)}
            rules={[{ required: true }]}
          >
            {(): JSX.Element => (
              <Row gutter={[8, 8]}>
                <Col span={18}>
                  <Form.Item
                    name={advertisersAndOrdersField}
                    rules={advertisersAndOrdersFieldRules}
                    noStyle
                  >
                    <TreeSelect
                      allowClear
                      disabled={!filterAdvertisers}
                      multiple
                      onChange={handleAdvertisersAndOrderChange}
                      placeholder={i18n._(t`Maak een selectie`)}
                      showSearch
                      treeData={advertisersTree}
                      treeNodeFilterProp="title"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    noStyle
                    dependencies={[periodField, advertisersAndOrdersField]}
                  >
                    {() => <ShowButton disabled={showDisabled} block />}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.Item>
          <Form.Item wrapperCol={wrapperColNoLabel}>
            <Space>
              <ExportButton
                buttonType="default"
                iconType="Excel"
                disabled={exportDisabled}
                loading={exportExcelLoading}
                title={i18n._(t`Alles exporteren naar Excel`)}
                onClick={(): void => {
                  triggerAnalyticsEvent(exportAllCategory);
                  onExport("Excel");
                }}
              >
                <BigScreen type="text">
                  <Trans>Alles exporteren</Trans>
                </BigScreen>
              </ExportButton>
              <ExportButton
                buttonType="default"
                iconType="PDF"
                disabled={exportDisabled}
                loading={exportPdfLoading}
                title={i18n._(t`Alles printen naar PDF`)}
                onClick={(): void => {
                  triggerAnalyticsEvent(printAllCategory);
                  onExport("PDF");
                }}
              >
                <BigScreen type="text">
                  <Trans>Alles printen</Trans>
                </BigScreen>
              </ExportButton>
            </Space>
          </Form.Item>

          {targetGroups && targetGroups.length > 0 && (
            <Form.Item
              label={i18n._(t`NMO analyse doelgroep`)}
              name={skoTargetGroupIdField}
            >
              <Row gutter={[8, 0]}>
                <Col span={18}>
                  <Select
                    onChange={handleSkoTargetGroupChange}
                    placeholder={i18n._(t`Maak een selectie`)}
                    disabled={exportDisabled}
                    showSearch
                    optionFilterProp="title"
                  >
                    <Select.Option value={-1}>
                      <Trans>Geen geselecteerd</Trans>
                    </Select.Option>
                    {targetGroups?.map((tg) => (
                      <Select.Option key={tg.id} value={tg.id} title={tg.name}>
                        {tg.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col span={6}>
                  <AnalyzeButton
                    onClick={(): void => {
                      triggerAnalyticsEvent(analyzeAllCategory);
                      onAnalyze();
                    }}
                    disabled={analyzeDisabled}
                    loading={analyzeLoading}
                    title={i18n._(
                      t`Analyseren met de gekozen NMO analyse doelgroep`
                    )}
                  >
                    <BigScreen type="text">
                      <Trans>Analyse tonen</Trans>
                    </BigScreen>
                  </AnalyzeButton>
                </Col>
              </Row>
            </Form.Item>
          )}

          {counter && <CampaignStatistics counter={counter} />}
        </Card>
      </Spin>
    );
  }
);

export default Filter;
