import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import React from "react";

interface OrganisationOperatorHasPasswordIconProps {
  hasPassword: boolean;
}

const OrganisationOperatorHasPasswordIcon = ({
  hasPassword,
}: OrganisationOperatorHasPasswordIconProps) =>
  hasPassword ? <LockOutlined /> : <UnlockOutlined />;

export default OrganisationOperatorHasPasswordIcon;
