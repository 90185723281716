import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Popconfirm } from "antd";
import React, { memo, useCallback } from "react";

import ItemActionButtonProps from "./ItemActionButtonProps";

const CancelButton = memo(
  ({ itemId, onClick, disabled }: ItemActionButtonProps) => {
    const { i18n } = useLingui();

    const handleClick = useCallback(() => {
      if (onClick) {
        onClick(itemId);
      }
    }, [itemId, onClick]);

    return (
      <Popconfirm
        title={i18n._(t`Weet je zeker dat je wil annuleren?`)}
        onConfirm={handleClick}
        cancelButtonProps={{ type: "link" }}
      >
        <Button type="link" disabled={disabled}>
          <Trans id="Annuleren">Annuleren</Trans>
        </Button>
      </Popconfirm>
    );
  }
);

export default CancelButton;
