import { PreferredPosition } from "../../models/Spot";
import { Break } from "../campaigns/requests/models";

export interface AvailableBreak {
  break: Break;
  preferredPosition?: PreferredPosition;
  selected: boolean;
  spotId?: number;
  canBook: boolean;
  canCancel: boolean;
}

export interface AvailableBreaksRequest {
  dateFrom: Date;
  dateTo: Date;
  orderId: string;
  subOrderId: string;
  packageCode: string;
  spotLengths: number[];
}

export interface AvailableBreaksResponse {
  availableBreaks: AvailableBreak[];
}

export type BookSpotAction =
  | "Book"
  | "Cancel"
  | "PreferredPositionAdd"
  | "PreferredPositionRemove"
  | "Request";

export type RequestedPreferredPosition = 0 | 1 | 2 | 4 | 8 | 16 | 32;

export const PreferredPositionToRequest: {
  [preferredPosition in PreferredPosition]: RequestedPreferredPosition;
} = {
  None: 0,
  FirstInBlock: 1,
  SecondInBlock: 2,
  BeforeLastInBlock: 4,
  LastInBlock: 8,
  ThirdInBlock: 16,
  ThirdLastInBlock: 32,
};

export const RequestToPreferredPosition: {
  [requestedPosition in RequestedPreferredPosition]: PreferredPosition;
} = {
  0: "None",
  1: "FirstInBlock",
  2: "SecondInBlock",
  4: "BeforeLastInBlock",
  8: "LastInBlock",
  16: "ThirdInBlock",
  32: "ThirdLastInBlock",
};

export interface BreakRequest {
  action: BookSpotAction;
  breakId: string;
  channelDescription: string;
  positionFreeIndicator: string;
  predictedRating?: number;
  preferredPosition: RequestedPreferredPosition;
  programmeAfter: string;
  programmeBefore: string;
  remarks?: string;
  scheduledDate: Date;
  selectivity?: number;
  spotId?: number;
  spotLengthCombination: string;
}

export interface BookSpotRequest {
  breakRequests: BreakRequest[];
  emailPlannerAgency: string;
  orderId: string;
  orderDescription: string;
  subOrderId: string;
}
