import { Col, Row } from "antd";
import React, { Children, ReactNode, memo } from "react";

interface CommercialLibraryContentProps {
  children?: ReactNode;
}

const CommercialLibraryContent = memo(
  ({ children }: CommercialLibraryContentProps) => (
    <section className="commercials-overview">
      {children &&
        Children.map(children, (child) =>
          child ? (
            <Row>
              <Col span={24}>{child}</Col>
            </Row>
          ) : null
        )}
    </section>
  )
);

export default CommercialLibraryContent;
