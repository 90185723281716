import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Collapse } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { emptyInstructions } from "../../models/Instructions";
import { OperatorSter, getOperatorFromId } from "../../models/Operator";
import Order from "../../models/Order";
import { plannerRoleName } from "../../models/UserRole";
import { instructionsCell } from "../../store/instructions/cells";
import { StoreModel } from "../../store/models";
import { distinct, handleAsyncFailWithProblem, hasRole } from "../../utils";
import { getAvailablePeriods, toPeriod } from "../../utils/date";
import InstructionsView from "../../views/instructions/InstructionsView";
import InstructionTableContainer from "./InstructionTableContainer";

export interface InstructionsProps {
  order: Order;
}

const Instructions = ({ order }: InstructionsProps): JSX.Element | null => {
  const { i18n } = useLingui();
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const handleActiveKey = useCallback((key: string | string[]) => {
    setActiveKey(key as string);
  }, []);

  const productId = useMemo(() => {
    if (!order || !order.id.startsWith(`${OperatorSter}:`)) {
      return undefined;
    }

    return order.product?.id;
  }, [order]);

  const orderId = useMemo(
    () => (productId ? undefined : order?.id),
    [order?.id, productId]
  );

  const idForInstructions = useMemo(
    () => (productId ?? orderId) as string,
    [orderId, productId]
  );

  const instructions = useSelector(
    ({ instructions: instructionsFromStore }: StoreModel) =>
      instructionsFromStore.instructions[idForInstructions]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (idForInstructions && activeKey) {
      dispatch(
        instructionsCell.require(idForInstructions, {
          onFail: handleAsyncFailWithProblem(
            i18n._(t`Er ging iets mis met het ophalen van uitzendinstructies.`)
          ),
        })
      );
    }
  }, [activeKey, dispatch, i18n, idForInstructions, orderId, productId]);

  const { value: user } = useSelector(
    ({ users: { current } }: StoreModel) => current
  );
  const isPlanner = useMemo(() => hasRole(plannerRoleName)(user), [user]);

  const filteredInstructions = useMemo(
    () =>
      (instructions?.value?.instructions ?? emptyInstructions).filter(
        (i) => (i.orderId && i.orderId === order.id) || !i.orderId
      ),
    [instructions?.value?.instructions, order.id]
  );

  const instructionPeriods = useMemo(
    () =>
      filteredInstructions.map((i) => ({
        subOrderId: i.subOrderId,
        period: toPeriod(i.startDate, i.endDate),
      })),
    [filteredInstructions]
  );

  const minStartDate = useMemo(
    () =>
      moment(
        Math.min(...order.subOrders.map((s) => new Date(s.startDate).getTime()))
      ),
    [order.subOrders]
  );

  const maxEndDate = useMemo(
    () =>
      moment(
        Math.max(...order.subOrders.map((s) => new Date(s.endDate).getTime()))
      ),
    [order.subOrders]
  );

  const hasSubOrderInstructions = useMemo(
    () => filteredInstructions.some((i) => i.subOrderId !== undefined),
    [filteredInstructions]
  );

  const availablePeriods = useMemo(() => {
    const available = hasSubOrderInstructions
      ? order.subOrders.flatMap((subOrder) =>
          getAvailablePeriods(
            { from: moment(subOrder.startDate), to: moment(subOrder.endDate) },
            instructionPeriods
              .filter((i) => i.subOrderId === subOrder.id)
              .map((i) => i.period)
          )
        )
      : getAvailablePeriods(
          { from: minStartDate.clone(), to: maxEndDate.clone() },
          instructionPeriods.filter((i) => !i.subOrderId).map((i) => i.period)
        );

    return distinct(
      available,
      (item) => `${item.from.format()}=${item.to.format()}`
    );
  }, [
    hasSubOrderInstructions,
    instructionPeriods,
    maxEndDate,
    minStartDate,
    order.subOrders,
  ]);

  const key = `instructions-${productId ?? orderId}`;
  if (!idForInstructions) {
    return null;
  }

  return (
    <InstructionsView>
      <Collapse accordion activeKey={activeKey} onChange={handleActiveKey}>
        <Collapse.Panel key={key} header={i18n._(t`Toon uitzendinstructies`)}>
          <InstructionTableContainer
            operator={getOperatorFromId(idForInstructions)}
            instructionsId={idForInstructions}
            loading={instructions?.status.loading}
            instructions={filteredInstructions}
            order={order}
            canShowDetails
            instructionEditMode="None"
            isPlanner={isPlanner}
            minStartDate={minStartDate}
            availablePeriods={availablePeriods}
            inCampaignView
          />
        </Collapse.Panel>
      </Collapse>
    </InstructionsView>
  );
};

export default Instructions;
