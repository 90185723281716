import { UserOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Button, Col, Form, Input, Row, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, {
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { RequestResetUserPassword, StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { getUsername } from "../../store/token";
import propertyOf from "../../utils/properties";
import useIsMounted from "../../utils/statefulness";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../views/layout/Form";

type RequestResetState = "Default" | "Error";
const usernameField = propertyOf<RequestResetUserPassword>("username");

const ForgotPasswordForm = memo(() => {
  const [form] = useForm();
  const { getFieldValue, isFieldTouched, setFieldsValue, validateFields } =
    form;
  const isMounted = useIsMounted();
  const [requestResetState, setRequestResetState] =
    useState<RequestResetState>("Default");
  const [hasErrors, setHasErrors] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useLingui();

  const { loading = false } = useSelector(
    ({
      global: {
        resetPassword: { status },
      },
    }: StoreModel) => status
  );

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    setFieldsValue({
      [usernameField]: getUsername(),
    });
  }, [isMounted, setFieldsValue]);

  const handleFail = useCallback(() => {
    if (!isMounted.current) {
      return;
    }
    setRequestResetState("Error");
  }, [isMounted]);

  const handleSuccess = useCallback(() => {
    dispatch({
      type: sagaTypes.global.resetrequest.clear,
    });
    const successMessage = i18n._(t`Mail verzonden`);
    message.success(successMessage);
    if (isMounted.current) {
      form.resetFields([usernameField]);
    }
  }, [dispatch, form, i18n, isMounted]);

  const handleChange = useCallback(
    /**
     * Handles a change in the form fields.
     */
    () => {
      validateFields()
        .then(() => {
          setHasErrors(false);
        })
        .catch(() => {
          setHasErrors(true);
        });
    },
    [validateFields]
  );

  const handleFinish = useCallback(
    (values: RequestResetUserPassword) => {
      if (values) {
        dispatch({
          type: sagaTypes.global.resetrequest.request,
          payload: values,
          onFail: handleFail,
          onSuccess: handleSuccess,
        });
      }
    },
    [dispatch, handleFail, handleSuccess]
  );

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            labelCol={labelCol}
            name="ForgotPasswordForm"
            onChange={handleChange}
            onFinish={handleFinish}
            wrapperCol={wrapperColShallow}
          >
            <Form.Item
              label={i18n._(t`Gebruikersnaam`)}
              name={usernameField}
              rules={[
                {
                  required: true,
                  message: i18n._(t`Gebruikersnaam is verplicht.`),
                },
              ]}
              help={i18n._(
                t`Voer je gebruikersnaam in. Indien je een gebruiker van deze applicatie bent, ontvang je een mail met daarin een link om je wachtwoord te herstellen.`
              )}
            >
              <Input
                autoFocus
                prefix={<UserOutlined />}
                placeholder={i18n._(t`Gebruikersnaam`)}
              />
            </Form.Item>
            <Form.Item shouldUpdate wrapperCol={wrapperColNoLabel}>
              {(): ReactElement => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    (!getFieldValue(usernameField) &&
                      !isFieldTouched(usernameField)) ||
                    hasErrors
                  }
                >
                  Wachtwoord herstellen
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {requestResetState === "Error" && (
        <Row>
          <Col span={24}>
            <Alert
              type="error"
              message={i18n._(t`Er is iets misgegaan.`)}
              showIcon
            />
          </Col>
        </Row>
      )}
    </Spin>
  );
});

export default ForgotPasswordForm;
