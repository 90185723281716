import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Popconfirm } from "antd";
import React, { MouseEventHandler, memo, useCallback } from "react";

import ItemActionButtonProps from "./ItemActionButtonProps";

const DeleteButton = ({
  itemId,
  onClick,
  confirmMessage,
  title,
  disabled,
}: ItemActionButtonProps) => {
  const { i18n } = useLingui();

  const handleConfirm = useCallback(() => {
    if (onClick && itemId) {
      onClick(itemId);
    }
  }, [itemId, onClick]);

  const handleClick = useCallback<MouseEventHandler>((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
    <span role="dialog" onClick={handleClick}>
      <Popconfirm
        title={
          confirmMessage ||
          i18n._(t`Weet je zeker dat je dit item wil verwijderen?`)
        }
        onConfirm={handleConfirm}
        disabled={disabled}
        cancelButtonProps={{ type: "link" }}
      >
        <Button
          key="deleteButton"
          type="link"
          onClick={handleClick}
          disabled={disabled}
        >
          {title || <Trans>Verwijderen</Trans>}
        </Button>
      </Popconfirm>
    </span>
  );
};

export default memo(DeleteButton);
