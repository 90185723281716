import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Card, Carousel, List, Typography } from "antd";
import React from "react";

import { DashboardCommercial, DashboardOrder } from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import { isNonEmptyArray } from "../../utils";
import { OperatorAvatar } from "../OperatorIcon";
import CommercialEmpty from "./CommercialEmpty";
import DashboardCommercialView from "./DashboardCommercialView";

export interface DashboardFinishCardProps {
  title: string;
  commercials: DashboardCommercial[];
  orders: DashboardOrder[];
  targetGroup: string;
  onDownload: OnBlobDownloadFunc;
}

const DashboardFinishCard = ({
  title,
  commercials,
  orders,
  targetGroup,
  onDownload,
}: DashboardFinishCardProps) => {
  const { i18n } = useLingui();
  return (
    <Card
      bodyStyle={{ padding: 0 }}
      cover={
        isNonEmptyArray(commercials) ? (
          <Carousel autoplay style={{ maxWidth: 480, margin: "0 auto" }}>
            {commercials.map((c) => (
              <DashboardCommercialView
                key={c.commercialDeliveryId}
                commercial={c}
                onDownload={onDownload}
                width={480}
                height={270}
              />
            ))}
          </Carousel>
        ) : (
          <CommercialEmpty />
        )
      }
      style={{ maxWidth: 480, width: "100%", margin: "0 auto" }}
    >
      <Card.Meta
        title={
          <Typography.Title level={3} ellipsis style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        }
        style={{ padding: 16, borderBottom: "1px solid #f0f0f0" }}
      />
      <List
        dataSource={orders}
        header={
          <Typography.Text style={{ padding: "8px 16px" }}>
            {i18n._(t`Analyse doelgroep`)}
            {": "}
            {targetGroup}
          </Typography.Text>
        }
        renderItem={(o) => (
          <List.Item key={o.id} style={{ padding: "8px 16px" }}>
            <List.Item.Meta
              title={o.name}
              avatar={<OperatorAvatar operator={o.operator} invert />}
            />
          </List.Item>
        )}
        style={{ width: "100%" }}
      />
    </Card>
  );
};

export default DashboardFinishCard;
