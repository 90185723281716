import { Moment } from "moment";

import { Operator } from "./Operator";

export interface ProductsRequest {
  dateFrom: Moment;
  dateTo: Moment;
  advertiserId: string;
  orderRequestId?: string;
}

export interface Product {
  id: string;
  description: string;
  advertiser?: { id: string; name: string; operator: Operator };
}

export const emptyProduct: Product = { id: "", description: "" };

export interface ProductsResponse {
  products: Product[];
}
