import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Modal, ModalProps } from "antd";
import React, { PropsWithChildren } from "react";

export interface DashboardModalProps {
  onOk: ModalProps["onOk"];
  okText: ModalProps["okText"];
  onCancel: ModalProps["onCancel"];
  cancelText: ModalProps["cancelText"];
  loading: boolean;
  open?: boolean;
}

const DashboardModal = ({
  onOk,
  okText,
  onCancel,
  cancelText,
  loading,
  open,
  children,
}: PropsWithChildren<DashboardModalProps>) => {
  const { i18n } = useLingui();
  return (
    <Modal
      title={i18n._(t`Tegel samenstellen`)}
      width="100%"
      style={{ maxWidth: 960 }}
      open={open}
      maskClosable={false}
      onOk={onOk}
      okText={okText}
      okButtonProps={{ disabled: loading }}
      onCancel={onCancel}
      cancelText={cancelText}
      cancelButtonProps={{ disabled: loading }}
      destroyOnClose
      closable={false}
    >
      {children}
    </Modal>
  );
};

export default DashboardModal;
