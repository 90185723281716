import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, FormInstance, Typography } from "antd";
import { filter, find, flatten, flow, get, map, orderBy } from "lodash/fp";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  DashboardCommercial,
  DashboardOrder,
  DashboardTileInput,
} from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import {
  commercialMatchCell,
  dashboardOrderListCell,
} from "../../store/dashboard/cell";
import { skoTargetGroupCell } from "../../store/sko/cells";
import DashboardFinishCard from "../../views/dashboard/DashboardFinishCard";

const DashboardFinishForm = ({
  form,
  onDownload,
}: {
  form: FormInstance<DashboardTileInput>;
  onDownload: OnBlobDownloadFunc;
}) => {
  const { i18n } = useLingui();
  const dashboardOrdersFromStore = useSelector(dashboardOrderListCell.select);
  const orders = useMemo<DashboardOrder[]>(
    () =>
      flow(
        map("orders"),
        flatten,
        filter((o: DashboardOrder) =>
          form.getFieldsValue().campaignIds.includes(o.id)
        ),
        orderBy(["name"], ["asc"])
      )(dashboardOrdersFromStore.value ?? []) as DashboardOrder[],
    [dashboardOrdersFromStore.value, form]
  );
  const targetGroupsFromStore = useSelector(skoTargetGroupCell.select);
  const targetGroup = useMemo<string>(
    () =>
      flow(
        find(
          (s: SkoTargetGroup) => form.getFieldsValue().skoTargetGroupId === s.id
        ),
        get("name")
      )(targetGroupsFromStore.value ?? []) as string,
    [form, targetGroupsFromStore.value]
  );

  const commercialsFromStore = useSelector(commercialMatchCell.select);
  const commercials = useMemo<DashboardCommercial[]>(
    () =>
      filter((c: DashboardCommercial) =>
        (form.getFieldsValue().commercialDeliveryIds ?? []).includes(
          c.commercialDeliveryId
        )
      )(commercialsFromStore.value ?? []),
    [form, commercialsFromStore.value]
  );

  return (
    <>
      <Typography.Paragraph>
        {i18n._(
          t`Ben je tevreden? Klik dan op 'Opslaan' om je tegel op te slaan.`
        )}
      </Typography.Paragraph>

      <Form.Item name="id" hidden />
      <Form.Item name="name" rules={[{ required: true }]} hidden />
      <Form.Item name="campaignIds" rules={[{ required: true }]} hidden />
      <Form.Item name="skoTargetGroupId" rules={[{ required: true }]} hidden />
      <Form.Item name="commercialDeliveryIds" hidden />

      <DashboardFinishCard
        title={form.getFieldValue("name")}
        commercials={commercials}
        orders={orders}
        targetGroup={targetGroup}
        onDownload={onDownload}
      />
    </>
  );
};

export default DashboardFinishForm;
