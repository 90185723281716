import classNames from "classnames";
import React, { ReactElement, ReactNode } from "react";

interface TrunkProps {
  className?: string;
  children: ReactNode;
}

const Trunk = ({ className, children }: TrunkProps): ReactElement => (
  <div className={classNames("trunk", className)}>{children}</div>
);

export default Trunk;
