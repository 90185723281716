import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Table } from "antd";
import { AsyncStatus } from "async-lifecycle-saga/dist/models";
import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ColumnType } from "rc-table/lib/interface";
import React, { ReactNode, memo } from "react";

import { CommercialDeliveryStatus } from "../../models/CommercialDeliveryStatus";
import { OperatorsAll, operatorLabels } from "../../models/Operator";
import DeliveryStatusBadge from "../../views/commercials/DeliveryStatusBadge";
import DeliveryStatusBadgePerOperator from "../../views/commercials/DeliveryStatusBadgePerOperator";
import Ellipsis from "../../views/Ellipsis";

const DeliveryStatusTable = memo(
  ({
    value,
    status,
  }: {
    value?: CommercialDeliveryStatus[];
    status: AsyncStatus;
  }) => {
    const { i18n } = useLingui();

    return (
      <Table
        dataSource={value}
        columns={[
          {
            title: i18n._(t`Aangeleverd`),
            dataIndex: "created",
            key: "created",
            width: 175,
            render: (_text, { created }): ReactNode => (
              <>{moment(created).format("D-M-YYYY LT")}</>
            ),
          },
          {
            title: i18n._(t`Gewijzigd`),
            dataIndex: "modified",
            key: "modified",
            width: 125,
            render: (_text, { modified }): ReactNode => (
              <>{moment(modified).format("LT")}</>
            ),
          },
          {
            title: i18n._(t`Status`),
            dataIndex: "status",
            key: "status",
            width: 80,
            align: "center",
            render: (_text, deliveryStatus): ReactNode => (
              <DeliveryStatusBadge deliveryStatus={deliveryStatus} />
            ),
          },
          ...OperatorsAll.map(
            (operator): ColumnType<CommercialDeliveryStatus> => ({
              title: operatorLabels[operator],
              key: `operator-${operator}`,
              width: 125,
              align: "center",
              render: (_, deliveryStatus) => (
                <DeliveryStatusBadgePerOperator
                  deliveryStatus={deliveryStatus}
                  operator={operator}
                />
              ),
            })
          ),
          {
            title: i18n._(t`Bestandsnaam`),
            dataIndex: "filename",
            key: "filename",
            ellipsis: true,
            render: (_text, { filename }): ReactNode => (
              <Ellipsis text={filename} />
            ),
          },
          {
            title: i18n._(t`Adverteerders`),
            dataIndex: "advertisers",
            key: "advertisers",
            ellipsis: true,
            render: (_text, { advertisers }): ReactNode => (
              <Ellipsis
                text={advertisers
                  .sort((a, b) =>
                    operatorLabels[a.operator].localeCompare(
                      operatorLabels[b.operator]
                    )
                  )
                  .map(
                    ({ name, operator }) =>
                      `${name} (${operatorLabels[operator]})`
                  )
                  .join(", ")}
              />
            ),
          },
          {
            title: i18n._(t`Gebruiker`),
            dataIndex: "user",
            key: "user",
            render: (_text, { user }): ReactNode => (
              <a href={`mailto:${user.emailAddress}`}>{user.name}</a>
            ),
          },
        ]}
        loading={status.loading}
        pagination={false}
        rowKey="id"
      />
    );
  }
);

export default DeliveryStatusTable;
