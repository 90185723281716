import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Checkbox, DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, { useCallback } from "react";

import { CalendarEvent } from "../../../models/Calendar";
import { labelCol, wrapperColShallow } from "../../layout/Form";

export interface CalendarEventModalProps {
  eventToEdit: CalendarEvent;
  onSave: (event: CalendarEvent) => void;
  onCancel: () => void;
}

const CalendarEventModal = ({
  eventToEdit,
  onSave,
  onCancel,
}: CalendarEventModalProps) => {
  const { i18n } = useLingui();
  const [form] = Form.useForm<CalendarEvent>();

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const isAllDay = Form.useWatch("isAllDay", form);

  const handleFinish = useCallback(
    (values: Store) => {
      const event: CalendarEvent = {
        id: values.id,
        from: moment(values.period[0]).toDate(),
        to: moment(values.period[1]).toDate(),
        isAllDay: values.isAllDay,
        title: values.title,
        location: values.location,
        description: values.description,
      };

      onSave(event);
    },
    [onSave]
  );

  return (
    <Modal
      title={i18n._(t`Evenement`)}
      width="100%"
      style={{ maxWidth: 960 }}
      open
      maskClosable={false}
      onOk={handleOk}
      okText={i18n._(t`Opslaan`)}
      onCancel={handleCancel}
      cancelText={i18n._(t`Annuleren`)}
      destroyOnClose
      closable={false}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          id: eventToEdit.id,
          period: [moment(eventToEdit.from), moment(eventToEdit.to)],
          isAllDay: eventToEdit.isAllDay ?? true,
          title: eventToEdit.title,
          location: eventToEdit.location,
          description: eventToEdit.description,
        }}
        labelCol={labelCol}
        wrapperCol={wrapperColShallow}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label={i18n._(t`Titel`)}
          name="title"
          rules={[{ required: true, message: i18n._(t`Titel is verplicht`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={i18n._(t`Periode`)}
          name="period"
          rules={[{ required: true, message: i18n._(t`Periode is verplicht`) }]}
          dependencies={["wholeDay"]}
        >
          <DatePicker.RangePicker
            showTime={!isAllDay ? { format: "HH:mm" } : undefined}
            format={isAllDay ? "ll" : "ll HH:mm"}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label={i18n._(t`Gehele dag`)}
          name="isAllDay"
          valuePropName="checked"
        >
          <Checkbox>{i18n._(t`Ja, het evenement duurt de hele dag`)}</Checkbox>
        </Form.Item>
        <Form.Item label={i18n._(t`Locatie`)} name="location">
          <Input
            placeholder={i18n._(t`Optionele beschrijving van de locatie`)}
          />
        </Form.Item>
        <Form.Item label={i18n._(t`Omschrijving`)} name="description">
          <Input.TextArea
            placeholder={i18n._(t`Optionele omschrijving van het evenement`)}
            rows={5}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CalendarEventModal;
