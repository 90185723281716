import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Tooltip } from "antd";
import moment from "moment";
import React, { MouseEventHandler, useCallback } from "react";

import { Period } from "../../../models/periods";
import { TargetPeriod } from "../../../store/campaigns/requests/models";
import { monthFormat } from "../CopyButton";

export interface SinglePeriodProps {
  disabled?: boolean;
  periods: Period[];
  onCopy: (targetPeriod: TargetPeriod) => void;
}

const SinglePeriod = ({
  disabled,
  periods,
  onCopy,
}: SinglePeriodProps): JSX.Element => {
  const { i18n } = useLingui();
  const noSalesPeriod = periods.length === 0;
  const handleClick = useCallback<MouseEventHandler>(
    (event) => {
      if (noSalesPeriod) {
        return;
      }

      const period = {
        startDate: periods[0].startDate,
        endDate: periods[0].endDate,
      };

      onCopy(period);

      event.preventDefault();
      event.stopPropagation();
    },
    [noSalesPeriod, onCopy, periods]
  );

  const button = (
    <Button
      disabled={disabled || noSalesPeriod}
      key="copyButton"
      type="link"
      onClick={handleClick}
    >
      <Trans>Kopiëren</Trans>
    </Button>
  );

  return noSalesPeriod ? (
    button
  ) : (
    <Tooltip
      title={i18n._(
        t`Kopieer deze aanvraag naar ${moment(periods[0].startDate).format(
          monthFormat
        )}`
      )}
    >
      {button}
    </Tooltip>
  );
};

export default SinglePeriod;
