import { LockOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { ReactElement } from "react";

interface UserLockedViewProps {
  tooltip: string;
}

const UserLockedView = ({ tooltip }: UserLockedViewProps): ReactElement => (
  <Tooltip title={tooltip}>
    <span className="text-primary">
      <LockOutlined className="text-primary" />
    </span>
  </Tooltip>
);

export default UserLockedView;
