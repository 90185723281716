import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, InputNumber } from "antd";
import { Rule } from "antd/lib/form";
import React, { memo } from "react";

import { fieldBudgetName } from "../../../../../models/Campaigns/Requests/models";
import { labelCol, wrapperCol } from "../../../../../views/layout/Form";
import { BudgetFieldProps } from "../models";

const BudgetField = memo(
  ({ enabled, onChange, isCombiField }: BudgetFieldProps) => {
    const { i18n } = useLingui();

    const field = (
      <InputNumber<string | number>
        min={0}
        onChange={onChange}
        step={1000}
        disabled={!enabled}
        precision={2}
        decimalSeparator=","
        formatter={(val): string => (val ? `€ ${val}` : "")}
        parser={(val): string => val?.replace("€ ", "") ?? ""}
      />
    );

    const rules: Rule[] = enabled
      ? [
          {
            type: "number",
            required: true,
            message: i18n._(t`Budget is verplicht.`),
          },
          () => ({
            validator(_, value) {
              if (Number(value ?? "0") > 0) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t`Budget moet een positieve waarde zijn.`)
              );
            },
          }),
        ]
      : [];

    return isCombiField ? (
      <Form.Item
        label={i18n._(t`Budget`)}
        labelCol={{ span: 24 }}
        name={fieldBudgetName}
        required
        rules={rules}
        wrapperCol={{ span: 24 }}
      >
        {field}
      </Form.Item>
    ) : (
      <Form.Item
        label={i18n._(t`Budget`)}
        labelCol={labelCol}
        name={fieldBudgetName}
        required
        rules={rules}
        wrapperCol={wrapperCol}
      >
        {field}
      </Form.Item>
    );
  }
);

export default BudgetField;
