import moment, { Moment } from "moment";

import Advertiser from "../Advertiser";
import { CalendarEventFilter } from "../Calendar";
import { Operator } from "../Operator";
import { Product } from "../products";
import Spot from "../Spot";
import TargetGroup from "../TargetGroup";

export interface DashboardTile {
  id: string;
  name: string;
  campaignIds: string[];
  skoTargetGroupId: number;
  commercialDeliveryIds?: string[];
}

export interface DashboardQuantities {
  requestedBudget: number;
  bookedBudget: number;
  requestedGrps: number;
  bookedGrps: number;
  achievedGrps: number;
  realizedGrps: number;
  impressionsInTargetGroup: number;
  impressionsTotal: number;
  numberOfSpots: number;
  numberOfSpotsWithPreferredPosition: number;
}

export interface DashboardOrderRef {
  id: string;
  name: string;
  operator: Operator;
}

export interface DashboardPurchasingTargetGroup extends DashboardQuantities {
  operator: Operator;
  purchasingTargetGroup: string;
}

export type DashboardOrder = DashboardOrderRef & {
  purchasingTargetGroups: DashboardPurchasingTargetGroup[];
};

export interface DashboardOrderList {
  advertiser: Advertiser;
  orders: DashboardOrder[];
}

export interface DashboardTileInput {
  id?: string;
  name: string;
  campaignIds: string[];
  skoTargetGroupId: number;
  commercialDeliveryIds?: string[];
}

export interface DashboardFilter {
  period: [Moment, Moment];
}

export interface DashboardData extends DashboardQuantities {
  operator: Operator;
  orders: DashboardOrder[];
  firstSpotsOnTv: FirstSpotOnTv[];
}

export interface FirstSpotOnTv {
  channel: string;
  onAir: Date;
}

export interface Invoice {
  id: string;
  invoiceDate: Date;
  dueDate: Date;
  invoiceAmount: number;
  vatAmount: number;
  status: string;
  product: Product;
}

export interface DashboardTileData {
  id: string;
  period: { from: Date; to: Date };
  data: DashboardData[];
  commercials: DashboardCommercial[];
  invoices: Invoice[];
  purchasingTargetGroups: string[];
}

export interface CommercialMatchInput {
  advertiserName: string;
  campaignIds: string[];
  dateFrom: Date;
  dateTo: Date;
}

export interface DashboardCommercial {
  commercialDeliveryId: string;
  title: string;
  created: Date;
  lengthInSeconds: number;
  thumbnail: string;
  lowResFilename: string;
  remoteFilename: string;
}

export interface SpotInput {
  campaignIds: string[];
  dateFrom: Date;
  dateTo: Date;
  skoTargetGroupId: number;
}

export interface SpotWithTargetGroup {
  spot: Spot;
  targetGroup: TargetGroup;
}

const today = moment().startOf("day");
export const defaultCalendarFilter: CalendarEventFilter = {
  period: [
    today.clone().subtract(1, "month").startOf("year"),
    today.clone().add(1, "year").endOf("year"),
  ],
};
