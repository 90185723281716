import { Typography } from "antd";
import React, { ReactElement } from "react";

import Advertiser from "../../../../models/Advertiser";
import { getAdvertiserName } from "../../../../utils";

interface AdvertisersViewProps {
  advertiserIds: string[];
  advertisers: Advertiser[];
}

const AdvertisersView = ({
  advertiserIds,
  advertisers,
}: AdvertisersViewProps): ReactElement => (
  <Typography.Text key="advertisersText">
    {advertiserIds.map((id) => getAdvertiserName(advertisers, id)).join(", ")}
  </Typography.Text>
);

export default AdvertisersView;
