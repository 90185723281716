import { combineReducers } from "redux";

import { asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";
import { LocalizationSetRequest } from "./models";

export default combineReducers({
  set: asyncReducer<LocalizationSetRequest, unknown>(
    sagaTypes.localization.set
  ),
});
