import { LineChartOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Button, Tooltip } from "antd";
import React, { memo } from "react";

import ActionButtonProps from "./ActionButtonProps";

/**
 * Renders a button for analyzing data.
 * @constructor Initializes a new instance of AnalyzeButton.
 * @returns {ReactElement<any, any> | null} The rendered component.
 */
const AnalyzeButton = ({
  children,
  disabled,
  loading,
  title,
  onClick,
}: ActionButtonProps & { onClick?: React.MouseEventHandler<HTMLElement> }) => {
  const button = (
    <Button
      icon={<LineChartOutlined />}
      type="default"
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      block
    >
      {children || <Trans>Analyseren</Trans>}
    </Button>
  );

  return title ? <Tooltip title={title}>{button}</Tooltip> : button;
};

export default memo(AnalyzeButton);
