import { QuestionCircleOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Carousel,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import classNames from "classnames";
import {
  compact,
  defaultTo,
  filter,
  find,
  flatten,
  flow,
  map,
  orderBy,
  sumBy,
  take,
} from "lodash/fp";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

import {
  DashboardData,
  DashboardOrder,
  DashboardTile,
  DashboardTileData,
  FirstSpotOnTv,
} from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import { operatorLabels } from "../../models/Operator";
import { SkoTargetGroup } from "../../models/SkoTargetGroup";
import User from "../../models/User";
import { financesRoleName, readerRoleName } from "../../models/UserRole";
import { hasRole, isNonEmptyArray } from "../../utils";
import Euro from "../Euro";
import Grps from "../Grps";
import { formatNumber } from "../utils";
import CampaignLinkButton from "./CampaignLinkButton";
import ChartDot, { COLORS } from "./ChartDot";
import CommercialEmpty from "./CommercialEmpty";
import DashboardCommercialView from "./DashboardCommercialView";
import DashboardDataContainer from "./DashboardDataContainer";
import DashboardDataItem from "./DashboardDataItem";
import GrpTable from "./GrpTable";
import ImpressionsTable from "./ImpressionsTable";

export interface DashboardCardProps {
  user: User;
  tile: DashboardTile;
  data: DashboardTileData;
  extrasLoading: boolean;
  targetGroups: SkoTargetGroup[];
  onDownload: OnBlobDownloadFunc;
  edit?: boolean;
  impressionsEnabled?: boolean;
}

const renderEuro = (v: number) => <Euro amount={v} />;
const renderGrps = (v: number) => <Grps amount={v} showSuffix standalone />;

export interface VkpPercentageProps {
  value: number;
  total: number;
}

const VkpPercentage = ({ value, total }: VkpPercentageProps) => {
  if (!value || !total) {
    return null;
  }

  const percentage = ((value / total) * 100).toFixed(0);
  return <>{percentage}%</>;
};

const oneDecimal = (n: number): number =>
  Number(formatNumber(n, 1, "en", false));

const DashboardTileItem = ({
  user,
  tile,
  data,
  extrasLoading,
  targetGroups,
  onDownload,
  edit,
  impressionsEnabled,
}: DashboardCardProps) => {
  const { i18n } = useLingui();
  const targetGroup = useMemo(
    () =>
      find((tg: SkoTargetGroup) => tg.id === tile.skoTargetGroupId)(
        targetGroups
      ),
    [targetGroups, tile.skoTargetGroupId]
  );

  const grpSkoData = useMemo<DashboardData[]>(
    () =>
      flow(
        filter((d: DashboardData) => d.operator !== "Orn"),
        map((d: DashboardData) => ({
          ...d,
          realizedGrps: oneDecimal(d.realizedGrps),
        }))
      )(data.data),
    [data.data]
  );

  const spotData = useMemo(() => {
    const allSpots = sumBy("numberOfSpots")(data.data);
    const prefPosSpots = sumBy("numberOfSpotsWithPreferredPosition")(data.data);
    return [
      {
        name: "spots",
        other: allSpots - prefPosSpots,
        pref: prefPosSpots,
      },
    ];
  }, [data.data]);

  const isReader = useMemo(() => hasRole(readerRoleName)(user), [user]);
  const isFinance = useMemo(() => hasRole(financesRoleName)(user), [user]);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const totalLabels: Record<string, string> = {
    targetAudience: t`Doelgroep`,
    requestedGrps: t`Aangevraagd`,
    bookedGrps: t`Geboekt`,
    achievedGrps: t`Gerealiseerd`,
    impressionsInTargetGroup:
      targetGroup?.name ?? tile.skoTargetGroupId.toString(),
    impressionsTotal: t`Totaal (6+)`,
  };

  return (
    <div className={classNames({ "dashboard-tile-editing": edit })}>
      {showInfoBox && (
        <Modal
          title={i18n._(t`Extra informatie`)}
          open
          onOk={() => setShowInfoBox(false)}
          cancelButtonProps={{
            style: { display: "none", visibility: "hidden" },
            disabled: true,
          }}
          maskClosable={false}
          destroyOnClose
        >
          <Typography.Paragraph>
            <Trans>
              Het getoonde totaal aantal GRP&apos;s is een optelsom per
              inkoopdoelgroep bij Ster, Talpa en/of Ad Alliance.
            </Trans>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Trans>Voor ORN zijn geen GRP&apos;s beschikbaar.</Trans>
          </Typography.Paragraph>
        </Modal>
      )}

      <Row gutter={[16, 16]} style={{ overflow: "hidden" }}>
        <Col sm={24} md={12} xl={8}>
          {isFinance && (
            <DashboardDataItem
              title={
                <Tooltip
                  title={i18n._(
                    t`Het budget dat voor de geselecteerde order(s) bij de exploitanten is aangevraagd.`
                  )}
                >
                  {i18n._(t`Aangevraagd budget`)} (€)
                </Tooltip>
              }
              data={data.data}
              renderTotal={(d: DashboardData[]) => (
                <Euro amount={sumBy("requestedBudget")(d)} />
              )}
              labelGetter={(d: DashboardData) => operatorLabels[d.operator]}
              nameGetter={(d: DashboardData) => d.operator}
              valueGetter={(d: DashboardData) => d.requestedBudget}
              renderValue={renderEuro}
              chartType="pie"
            />
          )}

          <Spin spinning={extrasLoading}>
            <DashboardDataContainer
              title={
                <>
                  <Tooltip
                    title={i18n._(
                      t`Het totaal aantal GRP's over de inkoopdoelgroepen ingekocht bij Ster, Talpa en/of Ad Alliance.`
                    )}
                  >
                    {i18n._(t`GRP's totaal`)}
                  </Tooltip>{" "}
                  <Tooltip
                    title={i18n._(
                      t`Klik hier voor een toelichting op "GRP's totaal"`
                    )}
                  >
                    <Button
                      icon={<QuestionCircleOutlined />}
                      type="ghost"
                      onClick={() => setShowInfoBox(true)}
                    />
                  </Tooltip>
                </>
              }
            >
              <Col span={24}>
                <GrpTable totalLabels={totalLabels} data={data.data} />
              </Col>
            </DashboardDataContainer>
          </Spin>
          {impressionsEnabled && (
            <Spin spinning={extrasLoading}>
              <DashboardDataContainer
                title={
                  <>
                    <Tooltip
                      title={i18n._(
                        t`Het totaal aantal impressies berekend op de doelgroep, die in de tegel is geselecteerd.`
                      )}
                    >
                      {i18n._(t`Totaal Impressies`)}
                    </Tooltip>
                  </>
                }
                style={{ marginTop: "5ex" }}
              >
                <Col span={24}>
                  <ImpressionsTable
                    totalLabels={totalLabels}
                    data={data.data}
                  />
                </Col>
              </DashboardDataContainer>
            </Spin>
          )}
        </Col>
        <Col sm={24} md={12} xl={8}>
          {isFinance && (
            <DashboardDataItem
              title={
                <Tooltip
                  title={i18n._(
                    t`De waarde van de spots die op de geselecteerde order(s) zijn geboekt door de exploitanten.`
                  )}
                >
                  {i18n._(t`Geboekt budget`)} (€)
                </Tooltip>
              }
              data={data.data}
              renderTotal={(d: DashboardData[]) => (
                <Euro amount={sumBy("bookedBudget")(d)} />
              )}
              labelGetter={(d: DashboardData) => operatorLabels[d.operator]}
              nameGetter={(d: DashboardData) => d.operator}
              valueGetter={(d: DashboardData) => d.bookedBudget}
              renderValue={renderEuro}
              chartType="pie"
            />
          )}

          <Spin spinning={extrasLoading}>
            <DashboardDataItem
              title={
                <Tooltip
                  title={i18n._(
                    t`Het aantal behaalde GRP's berekend op de doelgroep, die in de tegel is geselecteerd.`
                  )}
                >{`${i18n._(t`GRP's gerealiseerd`)}: ${
                  targetGroup?.name
                }`}</Tooltip>
              }
              data={grpSkoData}
              renderTotal={(d: DashboardData[]) => (
                <Grps amount={sumBy("realizedGrps")(d)} showSuffix />
              )}
              labelGetter={(d: DashboardData) => operatorLabels[d.operator]}
              nameGetter={(d: DashboardData) => d.operator}
              valueGetter={(d: DashboardData) => d.realizedGrps}
              renderValue={renderGrps}
              chartType="pie"
            />
          </Spin>
        </Col>
        <Col sm={24} md={24} xl={8}>
          <Typography.Title>
            <Tooltip
              title={i18n._(t`De in de tegel geselecteerde commercial(s).`)}
            >
              {i18n._(t`Commercial`)}
            </Tooltip>
          </Typography.Title>
          <div style={{ margin: "0 auto 16px auto", maxWidth: 448 }}>
            {isNonEmptyArray(data.commercials) ? (
              <Carousel style={{ maxWidth: 448, margin: "0 auto" }}>
                {data.commercials.map((c) => (
                  <DashboardCommercialView
                    key={c.commercialDeliveryId}
                    commercial={c}
                    onDownload={onDownload}
                    width={448}
                    height={252}
                  />
                ))}
              </Carousel>
            ) : (
              <CommercialEmpty />
            )}
          </div>

          <Spin spinning={extrasLoading}>
            <Typography.Paragraph style={{ textAlign: "center" }}>
              <Trans>Eerstvolgende uitzending(en):</Trans>
              <br />
              <strong>
                {flow(
                  map((d: DashboardData) => d.firstSpotsOnTv),
                  flatten,
                  compact,
                  orderBy(
                    [
                      (f: FirstSpotOnTv) =>
                        moment(f.onAir).format("YYYYMMDDHHmmss"),
                    ],
                    ["asc"]
                  ),
                  map((f: FirstSpotOnTv) => (
                    <span key={JSON.stringify(f)}>
                      {moment(f.onAir).format("ll - LT")} {i18n._(t`op`)}{" "}
                      {f.channel}
                      <br />
                    </span>
                  )),
                  take(3),
                  defaultTo(<>-</>)
                )(data.data)}
              </strong>
            </Typography.Paragraph>

            <Typography.Paragraph style={{ textAlign: "center" }}>
              <Trans>
                Totaal aantal spots:{" "}
                <strong>{sumBy("numberOfSpots")(data?.data ?? [])}</strong>
                <span>{" - "}</span>
                <span>
                  Aantal met een VKP:{" "}
                  <strong>
                    {sumBy("numberOfSpotsWithPreferredPosition")(
                      data?.data ?? []
                    )}
                  </strong>
                  <ChartDot colorKeyword="prefSpots" />
                </span>
              </Trans>
            </Typography.Paragraph>
          </Spin>

          <ResponsiveContainer aspect={480 / 24}>
            <BarChart
              layout="vertical"
              width={480}
              height={24}
              data={spotData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              barGap={0}
              barCategoryGap={0}
            >
              <Bar
                dataKey="other"
                stackId="vkp"
                fill={COLORS.otherSpots}
                label={({ x, y, width, height }) => (
                  <text
                    x={x + width / 2 - 10}
                    y={y + height / 2 + 6}
                    fill="white"
                  >
                    <VkpPercentage
                      value={spotData?.length === 1 ? spotData[0].other : 0}
                      total={
                        spotData?.length === 1
                          ? spotData[0].pref + spotData[0].other
                          : 0
                      }
                    />
                  </text>
                )}
              />
              <Bar
                dataKey="pref"
                stackId="vkp"
                fill={COLORS.prefSpots}
                label={({ x, y, width, height }) => (
                  <text
                    x={x + width / 2 - 10}
                    y={y + height / 2 + 6}
                    fill="white"
                  >
                    <VkpPercentage
                      value={spotData?.length === 1 ? spotData[0].pref : 0}
                      total={
                        spotData?.length === 1
                          ? spotData[0].pref + spotData[0].other
                          : 0
                      }
                    />
                  </text>
                )}
              />
              <XAxis type="number" hide domain={["dataMin", "dataMax"]} />
              <YAxis type="category" dataKey="name" hide />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Paragraph>
            <Trans>
              De looptijd van de tegel is gelijk aan de looptijd van de
              geselecteerde order
            </Trans>
          </Typography.Paragraph>
        </Col>
      </Row>
      {isReader && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Title>
              <Trans>Geselecteerde orders</Trans>
            </Typography.Title>
            <Space wrap>
              {flow(
                map("orders"),
                flatten,
                orderBy([(order: DashboardOrder) => order.id], ["asc"]),
                map((order: DashboardOrder) => (
                  <CampaignLinkButton
                    key={order.id}
                    order={order}
                    dateFrom={data.period.from}
                    dateTo={data.period.to}
                  />
                ))
              )(data.data)}
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DashboardTileItem;
