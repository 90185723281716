import { MessageOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Input, Modal, Tooltip, Typography } from "antd";
import React, { ChangeEventHandler, useCallback, useState } from "react";

const ShowInfoMessage = ({
  value,
  onChange,
  editMode,
}: {
  value?: string;
  onChange?: (infoText: string) => void;
  editMode?: boolean;
}): JSX.Element => {
  const { i18n } = useLingui();
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState(value ?? "");

  const handleShow = useCallback(
    (newShow: boolean) => {
      setInfo(newShow && value ? value : "");
      setShow(newShow);
    },
    [value]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    ({ target: { value: newValue } }) => {
      setInfo(newValue);
    },
    []
  );

  const handleCancel = useCallback(() => {
    handleShow(false);
  }, [handleShow]);

  const handleOk = useCallback(() => {
    onChange?.(info);
    handleShow(false);
  }, [handleShow, info, onChange]);

  return (
    <>
      {show && (
        <Modal
          title={i18n._(t`Opmerking(en)`)}
          width={640}
          centered
          open
          onCancel={handleCancel}
          cancelText={!onChange ? i18n._(t`Sluiten`) : i18n._(t`Annuleren`)}
          onOk={handleOk}
          okButtonProps={{ hidden: !onChange }}
          maskClosable={false}
          keyboard={false}
        >
          {editMode ? (
            <>
              <Input.TextArea
                rows={4}
                placeholder={i18n._(
                  t`Voeg hier een eventuele opmerking toe aan de uitzendinstructie.`
                )}
                onChange={handleChange}
                value={info}
              />
              <Typography.Paragraph type="secondary">
                {i18n._(
                  t`Na het aanpassen van dit veld, dien je de uitzendinstructie op te slaan.`
                )}
              </Typography.Paragraph>
            </>
          ) : (
            <Typography.Paragraph>{value}</Typography.Paragraph>
          )}
        </Modal>
      )}
      <Tooltip
        title={i18n._(t`Toon de opmerking(en) bij deze uitzendinstructie`)}
      >
        <Button
          type="ghost"
          icon={<MessageOutlined />}
          onClick={() => handleShow(true)}
        />
      </Tooltip>
    </>
  );
};

export default ShowInfoMessage;
