import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { message } from "antd";
import { saveAs } from "file-saver";
import moment, { Moment } from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { emptyAdvertisers } from "../../../models/Advertiser";
import {
  OrderRequestStatus,
  emptyOrderRequestsByAdvertiserItems,
} from "../../../models/Campaigns/Requests/models";
import {
  copyRequestCell,
  openRequestsCell,
} from "../../../store/campaigns/requests/cells";
import {
  OrderRequestDeleteResponse,
  OrderRequestsByAdvertiserGetRequest,
  OrderRequestsByAdvertiserGetResponse,
  TargetPeriod,
} from "../../../store/campaigns/requests/models";
import { ExportConceptRequest } from "../../../store/exports/models";
import { HttpStatusCode } from "../../../store/fetch";
import { RequestAction, StoreModel } from "../../../store/models";
import sagaTypes from "../../../store/sagaTypes";
import { handleFailWithProblem } from "../../../utils";
import navigationPaths from "../../../utils/navigation";
import OrderRequestsOverviewContent from "../../../views/campaigns/requests/OrderRequestsOverviewContent";
import { ItemOnClickEventHandler } from "../../actions/ItemActionButtonProps";
import { emptyPeriods } from "./constants";
import FilterContainer from "./FilterContainer";
import { FilterFormValues } from "./models";
import Overview from "./Overview";

const firstOfCurrentMonth = moment().startOf("month");
const lastOfNextMonth = firstOfCurrentMonth.clone().add(3, "months");

const OverviewContainer = memo(
  ({ showOpenRequests }: { showOpenRequests: boolean }) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
      status: { loading: advertisersLoading },
      value: advertisersValue,
    } = useSelector(
      ({ requests: { advertisersForFilter } }: StoreModel) =>
        advertisersForFilter
    );
    const safeAdvertisersValue = useMemo(
      () => advertisersValue ?? emptyAdvertisers,
      [advertisersValue]
    );

    const {
      status: { loading: orderRequestsLoading },
      value,
    } = useSelector(
      ({ requests: { orderRequestsByAdvertiser } }: StoreModel) =>
        orderRequestsByAdvertiser
    );

    const copyBusy = useSelector(({ copyRequest: { status } }: StoreModel) =>
      Boolean(status.loading)
    );

    const loadOrderRequests = useCallback(
      (
        period: [Moment, Moment],
        advertiserId?: string,
        status?: OrderRequestStatus
      ) => {
        dispatch<
          RequestAction<
            OrderRequestsByAdvertiserGetRequest,
            OrderRequestsByAdvertiserGetResponse
          >
        >({
          type: sagaTypes.campaigns.requests.orderRequestsByAdvertiser.request,
          payload: {
            period,
            advertiserId,
            status,
          },
        });
      },
      [dispatch]
    );

    const safeOrderRequestsByAdvertiser = useMemo(
      () =>
        value?.orderRequestsByAdvertiser ?? emptyOrderRequestsByAdvertiserItems,
      [value]
    );

    const handleOrderRequestDelete = useCallback<ItemOnClickEventHandler>(
      (id, event) => {
        if (event) {
          event.stopPropagation();
        }
        dispatch<RequestAction<string, OrderRequestDeleteResponse>>({
          type: sagaTypes.campaigns.requests.delete.request,
          payload: id || "",
          onSuccess: () => {
            setTriggerQuery(true);
            message.success(i18n._(t`Aanvraag verwijderd.`));

            // teller updaten
            dispatch(openRequestsCell.require());

            if (showOpenRequests) {
              loadOrderRequests(
                [firstOfCurrentMonth, lastOfNextMonth],
                undefined,
                "Concept"
              );
            }
          },
        });
      },
      [dispatch, i18n, loadOrderRequests, showOpenRequests]
    );

    const handleOrderRequestEdit = useCallback<ItemOnClickEventHandler>(
      (id, event) => {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        navigate(
          navigationPaths.CampaignsRequestsEdit.replace(
            ":orderRequestId",
            id || ""
          )
        );
      },
      [navigate]
    );

    const handlePeriodChange = useCallback(
      (newPeriod?: [Moment, Moment]) => {
        if (!newPeriod) {
          return;
        }

        dispatch({
          type: sagaTypes.campaigns.requests.advertisersForFilter.request,
          payload: {
            dateFrom: newPeriod[0],
            dateTo: newPeriod[1],
          },
        });
      },
      [dispatch]
    );

    const handleQuery = useCallback(
      ({ advertiserId, period }: FilterFormValues) => {
        loadOrderRequests(period, advertiserId);
        setTriggerQuery(false);
      },
      [loadOrderRequests]
    );

    useEffect(() => {
      // haal de lijst met verkoopperiodes op
      dispatch({
        type: sagaTypes.campaigns.requests.periods.request,
      });

      if (showOpenRequests) {
        loadOrderRequests(
          [firstOfCurrentMonth, lastOfNextMonth],
          undefined,
          "Concept"
        );
      } else {
        dispatch({
          type: sagaTypes.campaigns.requests.orderRequestsByAdvertiser.clear,
        });
      }
    }, [dispatch, showOpenRequests, loadOrderRequests]);

    const [triggerQuery, setTriggerQuery] = useState(false);

    const isConceptLoading = useSelector(
      ({
        exports: {
          byConceptExcel: { status },
        },
      }: StoreModel) => status.loading
    );
    const handleExportConcept = useCallback<ItemOnClickEventHandler>(
      (orderRequestId) => {
        dispatch({
          type: sagaTypes.exports.conceptExcel.request,
          payload: { orderRequestId } as ExportConceptRequest,
          onFail: handleFailWithProblem(i18n._(t`Er is iets misgegaan.`)),
          onSuccess: (_statusCode?: HttpStatusCode, response?: Blob) => {
            if (!response) {
              return;
            }

            const filename = `Concept_Order_${orderRequestId}.xlsx`;
            if (window.navigator.msSaveBlob) {
              window.navigator.msSaveBlob(response, filename);
            } else {
              saveAs(response, filename);
            }
          },
        });
      },
      [dispatch, i18n]
    );

    const salesPeriods = useSelector(({ requests: { periods } }: StoreModel) =>
      !periods.status.loading && periods.value ? periods.value : emptyPeriods
    );

    const handleCopy = useCallback(
      (id: string, period: TargetPeriod) => {
        dispatch(
          copyRequestCell.require(
            { id, period },
            {
              onSuccess: (response) => {
                setTriggerQuery(true);
                message.success(i18n._(t`Aanvraag gekopieerd.`));

                // teller updaten
                dispatch(openRequestsCell.require());

                if (showOpenRequests) {
                  loadOrderRequests(
                    [firstOfCurrentMonth, lastOfNextMonth],
                    undefined,
                    "Concept"
                  );
                }

                // redirect to newly created order request
                navigate(
                  navigationPaths.CampaignsRequestsEdit.replace(
                    ":orderRequestId",
                    response.body.id
                  )
                );
              },
              onFail: ({ status }) => {
                const msg =
                  status === 409
                    ? t`Deze aanvraag kan niet gekopieerd worden naar de gekozen periode`
                    : t`Er is iets misgegaan bij het kopiëren`;
                message.error(i18n._(msg));
              },
            }
          )
        );
      },
      [dispatch, i18n, loadOrderRequests, navigate, showOpenRequests]
    );

    return (
      <OrderRequestsOverviewContent>
        {!showOpenRequests && (
          <FilterContainer
            advertisers={safeAdvertisersValue}
            loading={advertisersLoading}
            onPeriodChange={handlePeriodChange}
            onQuery={handleQuery}
            triggerQuery={triggerQuery}
          />
        )}
        <Overview
          loading={orderRequestsLoading || copyBusy}
          onDelete={handleOrderRequestDelete}
          onEdit={handleOrderRequestEdit}
          orderRequestsByAdvertiserItems={safeOrderRequestsByAdvertiser}
          onExport={handleExportConcept}
          isDownloading={isConceptLoading}
          periods={salesPeriods}
          onCopy={handleCopy}
        />
      </OrderRequestsOverviewContent>
    );
  }
);

export default OverviewContainer;
