import classNames from "classnames";
import { groupBy } from "lodash";
import React, { Fragment } from "react";

import { DashboardData } from "../../models/Dashboard";
import { Operator, operatorLabels } from "../../models/Operator";
import Grps from "../Grps";

interface Props {
  data: DashboardData[];
  totalLabels: Record<string, string>;
}

const GrpTable = ({ data, totalLabels }: Props) => {
  const orders = data.flatMap((d) => d.orders);
  const rows = Object.entries(
    groupBy(
      orders.flatMap((order) => order.purchasingTargetGroups),
      (ptg) => `${ptg.operator}:${ptg.purchasingTargetGroup}`
    )
  ).map(([key, purchasingTargetGroups]) => ({
    key,
    operator: key.substring(0, key.indexOf(":")),
    purchasingTargetGroup: purchasingTargetGroups[0].purchasingTargetGroup,
    requestedGrps: purchasingTargetGroups.reduce(
      (acc, curr) => acc + curr.requestedGrps,
      0
    ),
    bookedGrps: purchasingTargetGroups.reduce(
      (acc, curr) => acc + curr.bookedGrps,
      0
    ),
    achievedGrps: purchasingTargetGroups.reduce(
      (acc, curr) => acc + curr.achievedGrps,
      0
    ),
  }));
  const operators = Object.entries(
    groupBy(rows, ({ operator }) => operator)
  ).map(([operator, r]: [string, typeof rows]) => ({
    operator,
    length: r.length,
  }));

  const Cells = ({
    purchasingTargetGroup,
    requestedGrps,
    bookedGrps,
    achievedGrps,
  }: {
    purchasingTargetGroup: string;
    requestedGrps: number;
    bookedGrps: number;
    achievedGrps: number;
  }) => (
    <>
      <th scope="row">{purchasingTargetGroup}</th>
      <td>
        <Grps amount={requestedGrps} />
      </td>
      <td>
        <Grps amount={bookedGrps} />
      </td>
      <td>
        <Grps amount={achievedGrps} />
      </td>
    </>
  );

  return (
    <table className="grp">
      <colgroup>
        <col className="operator" />
      </colgroup>
      <thead>
        <tr>
          <td />
          <th scope="col">{totalLabels.targetAudience}</th>
          <th scope="col">{totalLabels.requestedGrps}</th>
          <th scope="col">{totalLabels.bookedGrps}</th>
          <th scope="col">{totalLabels.achievedGrps}</th>
        </tr>
      </thead>
      <tbody>
        {operators.map(({ operator, length }) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const firstRow = rows.find((r) => r.operator === operator)!;
          return (
            <Fragment key={operator}>
              <tr>
                <th
                  scope="col"
                  key={operator}
                  rowSpan={length}
                  className={classNames("operator", `operator-${operator}`)}
                >
                  {operatorLabels[operator as Operator]}
                </th>
                <Cells
                  purchasingTargetGroup={firstRow.purchasingTargetGroup}
                  requestedGrps={firstRow.requestedGrps}
                  bookedGrps={firstRow.bookedGrps}
                  achievedGrps={firstRow.requestedGrps}
                />
              </tr>
              {rows
                .filter((r) => r.operator === operator)
                .filter((_, index) => index > 0)
                .map((row) => (
                  <tr key={row.key}>
                    <Cells
                      purchasingTargetGroup={row.purchasingTargetGroup}
                      requestedGrps={row.requestedGrps}
                      bookedGrps={row.bookedGrps}
                      achievedGrps={row.requestedGrps}
                    />
                  </tr>
                ))}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default GrpTable;
