import { UploadOutlined } from "@ant-design/icons/lib";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Checkbox, Form, Input, InputNumber, Upload } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
} from "react";

import { operatorLabels } from "../../models/Operator";
import { wrapperColNoLabel } from "../../views/layout/Form";
import AdvertiserSelect from "./AdvertiserSelect";
import {
  advertiserIdsField,
  advertiserNameField,
  commentsField,
  commercialTitleField,
  filenameField,
  lowLoudnessLevelField,
  multiChannelAudioField,
  operatorsField,
  phonenumberField,
  productionCompanyField,
  tagOnField,
  tradeInField,
  versionField,
} from "./constants";
import { DeliveryFormProps } from "./models";

const DeliveryForm: FunctionComponent<DeliveryFormProps> = ({
  form,
  loadingAdvertisers,
  loadingUpload,
  advertisers,
  operators,
  setFileList,
  fileList,
}) => {
  const { i18n } = useLingui();

  const handleBeforeUpload = useCallback(
    (file: RcFile): boolean => {
      setFileList([file]);
      return false;
    },
    [setFileList]
  );

  return (
    <>
      {advertisers && advertisers.length > 0 && (
        <Form.Item
          label={i18n._(t`Adverteerder(s)`)}
          rules={[{ required: true }]}
          shouldUpdate
        >
          {(): JSX.Element => (
            <AdvertiserSelect
              form={form}
              advertisers={advertisers}
              loadingAdvertisers={loadingAdvertisers}
            />
          )}
        </Form.Item>
      )}
      {operators && advertisers && advertisers.length === 0 && (
        <>
          <Form.Item
            label={i18n._(t`Naam adverteerder`)}
            name={advertiserNameField}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={i18n._(t`Exploitant`)}
            name={operatorsField}
            rules={[{ required: true }]}
          >
            <Checkbox.Group
              options={operators.map((o) => ({
                label: operatorLabels[o],
                value: o,
              }))}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label={i18n._(t`MXF-bestand`)}
        name={filenameField}
        rules={[{ required: true, message: i18n._(t`Bestand is verplicht`) }]}
      >
        <Upload
          accept=".mxf"
          beforeUpload={handleBeforeUpload}
          fileList={fileList.map((f) => ({
            uid: f.uid,
            size: f.size,
            type: f.type,
            name: f.name,
            originFileObj: f,
          }))}
          multiple={false}
          onRemove={(file: UploadFile): boolean => {
            const newFileList = fileList.filter((f) => f.uid !== file.uid);
            setFileList(newFileList);
            return false;
          }}
        >
          <Button type="default">
            <UploadOutlined /> <Trans>Selecteer een MXF-bestand</Trans>
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item
        label={i18n._(t`Titel van commercial`)}
        name={commercialTitleField}
        rules={[{ required: true, message: i18n._(t`Titel is verplicht`) }]}
        help={i18n._(
          t`Gebruik alleen letters, cijfers, spaties en koppeltekens. Gebruik een unieke titel. Deze titel wordt gebruikt bij het samenstellen van de bestandsnaam.`
        )}
      >
        <Input />
      </Form.Item>

      <Form.Item
        help={i18n._(
          t`Op welk telefoonnummer kunnen we je bereiken, mochten er vragen zijn?`
        )}
        label={i18n._(t`Telefoonnummer`)}
        name={phonenumberField}
      >
        <Input type="tel" />
      </Form.Item>

      <Form.Item
        label={i18n._(t`Productiebedrijf`)}
        name={productionCompanyField}
      >
        <Input />
      </Form.Item>

      <Form.Item
        help={i18n._(
          t`Pas het versienummer aan bij een nieuwe versie van een eerder geleverde commercial`
        )}
        label={i18n._(t`Versie`)}
        name={versionField}
        rules={[{ required: true, message: i18n._(t`Versie is verplicht`) }]}
      >
        <InputNumber min={1} max={99} step={1} precision={0} />
      </Form.Item>

      <Form.Item
        label={i18n._(t`Omruil`)}
        name={tradeInField}
        valuePropName="checked"
      >
        <Checkbox>
          <Trans>Deze commercial vervangt een ander</Trans>
        </Checkbox>
      </Form.Item>

      <Form.Item
        label={i18n._(t`Tag-on`)}
        name={tagOnField}
        valuePropName="checked"
      >
        <Checkbox>
          <Trans>Deze commercial is een tag-on</Trans>
        </Checkbox>
      </Form.Item>

      <Form.Item
        label={i18n._(t`Low loudness level`)}
        name={lowLoudnessLevelField}
        valuePropName="checked"
      >
        <Checkbox>
          <Trans>Ja, hiervan maakt dit MXF-bestand gebruik</Trans>
          <br />
          <Trans>
            Indien bewust gebruik worden gemaakt van audio op een laag niveau,
            vink je <em>low loudness level</em> aan. Het ingest-proces
            accepteert dan materiaal dat een lagere waarde heeft dan het
            genormaliseerde luidheidsniveau van ‒23 LUFS.
          </Trans>
        </Checkbox>
      </Form.Item>

      <Form.Item
        label={i18n._(t`Multi-channel audio`)}
        name={multiChannelAudioField}
        valuePropName="checked"
      >
        <Checkbox>
          <Trans>Ja, hiervan maakt dit MXF-bestand gebruik</Trans>
          <br />
          <Trans>
            vink multi-channel audio aan als jouw commercial een surround-mix
            bevat
          </Trans>
        </Checkbox>
      </Form.Item>

      <Form.Item label={i18n._(t`Opmerkingen`)} name={commentsField}>
        <Input.TextArea rows={5} />
      </Form.Item>

      <Form.Item shouldUpdate wrapperCol={wrapperColNoLabel}>
        {(): ReactElement => (
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              (advertisers && advertisers.length > 0
                ? !form.isFieldsTouched(
                    [advertiserIdsField, filenameField, commercialTitleField],
                    true
                  )
                : !form.isFieldsTouched(
                    [
                      advertiserNameField,
                      operatorsField,
                      filenameField,
                      commercialTitleField,
                    ],
                    true
                  )) ||
              form.getFieldsError().some(({ errors }) => errors.length > 0) ||
              loadingUpload
            }
          >
            <Trans>Commercial aanleveren</Trans>
          </Button>
        )}
      </Form.Item>
    </>
  );
};

export default memo(DeliveryForm);
