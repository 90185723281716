import { Layout } from "antd";
import classnames from "classnames";
import React, { PropsWithChildren } from "react";

import Trunk from "./layout/Trunk";

const Content = ({
  isAuthentication,
  children,
}: PropsWithChildren<{ isAuthentication: boolean }>): JSX.Element => (
  <Layout.Content className={classnames({ "login-wrapper": isAuthentication })}>
    <Trunk className={classnames({ "login-trunk": isAuthentication })}>
      {children}
    </Trunk>
  </Layout.Content>
);

export default Content;
