import { combineReducers } from "redux";

import { CommercialDeliveryView } from "../../components/commercials/models";
import Advertiser from "../../models/Advertiser";
import { CommercialBlobResponse } from "../../models/OnBlobDownloadFunc";
import { Operator } from "../../models/Operator";
import { asyncIdReducer, asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";
import { deliveryStatusCell } from "./cells";

export const deliveryStatusReducer = deliveryStatusCell.reducer;

export default combineReducers({
  overview: asyncReducer<unknown, CommercialDeliveryView[]>(
    sagaTypes.commercials.overview
  ),
  advertisers: asyncReducer<unknown, Advertiser[]>(
    sagaTypes.commercials.advertisers
  ),
  delivery: asyncReducer<unknown, unknown>(sagaTypes.commercials.delivery),
  operators: asyncReducer<unknown, Operator[]>(sagaTypes.commercials.operators),
  download: asyncIdReducer<unknown, CommercialBlobResponse>(
    sagaTypes.commercials.download
  ),
  deleter: asyncReducer<unknown, unknown>(sagaTypes.commercials.deleter),
});
