import { jsonDeleter, jsonGetter, jsonPoster } from "../fetch";
import { ApiPromise, TokenApiPromise } from "../models";
import {
  DeleteExternalApiClientAuthorizationRequest,
  GetExternalApiClientsResponse,
  PostExternalApiClientAuthorizationRequest,
  PostExternalApiClientAuthorizationResponse,
} from "./models";

export const externalApiClientsGetter =
  (): TokenApiPromise<GetExternalApiClientsResponse> =>
    jsonGetter<unknown, GetExternalApiClientsResponse>("/api/app/clients");

export const externalApiClientAuthorizationPoster =
  (
    request: PostExternalApiClientAuthorizationRequest
  ): TokenApiPromise<PostExternalApiClientAuthorizationResponse> =>
  (token?: string): ApiPromise<PostExternalApiClientAuthorizationResponse> =>
    jsonPoster<
      PostExternalApiClientAuthorizationResponse,
      PostExternalApiClientAuthorizationRequest
    >("/api/app/apps")(request, token);

export const externalApiClientAuthorizationDeleter = ({
  id,
}: DeleteExternalApiClientAuthorizationRequest): TokenApiPromise<unknown> =>
  jsonDeleter<unknown>(`/api/app/apps/${id}`);
