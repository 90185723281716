import { SearchOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Alert,
  Badge,
  Button,
  Empty,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import {
  ColumnFilterItem,
  FilterDropdownProps,
  TableRowSelection,
} from "antd/lib/table/interface";
import moment from "moment";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Operator,
  OperatorRtl,
  OperatorTalpa,
  OperatorUnknown,
} from "../../models/Operator";
import { PreferredPosition, preferredPositionNone } from "../../models/Spot";
import { AvailableBreak } from "../../store/bookspot/models";
import { positionsFree } from "../../store/campaigns/requests/models";
import { arraysAreEqual } from "../../utils/equality";
import Amount from "../../views/Amount";
import BlocksPickerTimeWindowFilter, {
  momentTime,
  timeWindows,
} from "../../views/bookspot/BlocksPickerTimeWindowFilter";
import ColumnSearchFilter from "../../views/campaigns/requests/subOrders/ColumnSearchFilter";
import Ellipsis from "../../views/Ellipsis";
import FullScreenModal from "../../views/FullScreenModal";
import Grps from "../../views/Grps";
import MainContent from "../../views/layout/MainContent";
import PreferredPositionPicker from "../campaigns/requests/subOrders/fields/PreferredPositionPicker";
import { SelectedPreferredPositionDictionary } from "../campaigns/requests/subOrders/models";
import { BlockSelectionMode } from "./models";

interface BlocksPickerProps {
  blocks?: AvailableBreak[];
  enablePreferredPositionSelection: boolean;
  loading: boolean;
  mode: BlockSelectionMode;
  onCancel: () => void;
  onChange: (preferredPositions: SelectedPreferredPositionDictionary) => void;
  onCommit: (
    breaks: AvailableBreak[],
    preferredPositions: SelectedPreferredPositionDictionary
  ) => void;
  preferredPositions: SelectedPreferredPositionDictionary;
  visible: boolean;
  bookingIndicator?: number;
  operator: Operator;
  subOrderGrps?: number;
}

const emptyBlocks: AvailableBreak[] = [];

type SelectionFilterValue = "All" | "OnlySelected";

const adAllianceAllowStart = moment("08:30", "hh:mm");
const adAllianceAllowEnd = moment("18:30", "hh:mm");

const talpaAllowStart = moment("06:00", "hh:mm");
const talpaAllowEnd = moment("23:59", "hh:mm");

const BlocksPicker = memo(
  ({
    blocks,
    enablePreferredPositionSelection,
    loading,
    mode,
    onCancel,
    onChange,
    onCommit,
    preferredPositions,
    visible,
    bookingIndicator,
    operator,
    subOrderGrps,
  }: BlocksPickerProps) => {
    const { i18n } = useLingui();

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
      (blocks ?? emptyBlocks)
        .filter((b) => b.selected)
        .map((b) => b.break.uniqueId)
    );
    const [selectedPreferredPositions, setSelectedPreferredPositions] =
      useState<SelectedPreferredPositionDictionary>(preferredPositions);
    const [selectionFilterValue, setSelectionFilterValue] =
      useState<SelectionFilterValue>("All");

    const blocksSafe = useMemo(
      /**
       * Returns a safe selection of blocks.
       */
      () => blocks || emptyBlocks,
      [blocks]
    );

    const selectedBlocks = useMemo(
      () => blocksSafe.filter((b) => b.selected),
      [blocksSafe]
    );

    useEffect(() => {
      const selected = selectedBlocks.map((b) => b.break.uniqueId);
      setSelectedRowKeys(selected);
      const predefinedPreferredPositions: SelectedPreferredPositionDictionary =
        blocksSafe
          .filter((b) => Boolean(b.preferredPosition))
          .reduce(
            (dictionary, item) =>
              ({
                ...dictionary,
                [item.break.uniqueId]: item.preferredPosition
                  ? [item.preferredPosition]
                  : [],
              } as SelectedPreferredPositionDictionary),
            {} as SelectedPreferredPositionDictionary
          );
      setSelectedPreferredPositions(predefinedPreferredPositions);
    }, [blocksSafe, selectedBlocks]);

    const addedBreaks = useMemo<AvailableBreak[]>(
      () =>
        blocksSafe
          .filter(
            (b) =>
              !b.selected &&
              selectedRowKeys.some((srk) => srk === b.break.uniqueId)
          )
          .map(({ break: brk, spotId, canBook, canCancel }) => ({
            break: {
              ...brk,
              preferredPositions: selectedPreferredPositions[brk.uniqueId] || [
                preferredPositionNone,
              ],
            },
            selected: true,
            spotId,
            canBook,
            canCancel,
          })),
      [blocksSafe, selectedPreferredPositions, selectedRowKeys]
    );

    const removedBreaks = useMemo<AvailableBreak[]>(
      () =>
        blocksSafe
          .filter(
            (b) =>
              b.selected &&
              !selectedRowKeys.some((srk) => srk === b.break.uniqueId)
          )
          .map(({ break: brk, spotId, canBook, canCancel }) => ({
            break: {
              ...brk,
              preferredPositions: selectedPreferredPositions[brk.uniqueId] || [
                preferredPositionNone,
              ],
            },
            selected: false,
            spotId,
            canBook,
            canCancel,
          })),
      [blocksSafe, selectedPreferredPositions, selectedRowKeys]
    );

    const handleOk = useCallback(
      /**
       * Handles the 'OK' result of the modal.
       */
      () => {
        switch (mode) {
          case "BookSpot":
            onCommit(
              removedBreaks.concat(addedBreaks),
              selectedPreferredPositions
            );
            break;
          case "OrderRequest":
          default:
            onCommit(
              blocksSafe.filter(({ break: brk }) =>
                selectedRowKeys.some((key) => key === brk.uniqueId)
              ),
              selectedPreferredPositions
            );
            break;
        }
      },
      [
        addedBreaks,
        blocksSafe,
        mode,
        onCommit,
        removedBreaks,
        selectedPreferredPositions,
        selectedRowKeys,
      ]
    );

    const handlePreferredPositionChange = useCallback(
      /**
       * Handles a change in preferred position.
       * @param uid The unique identifier of the break.
       * @param newValue The new preferred position selection.
       */
      (uid: string, newValue: PreferredPosition | PreferredPosition[]) => {
        const newSelectedPositions = {
          ...selectedPreferredPositions,
          [uid]: Array.isArray(newValue)
            ? (newValue as PreferredPosition[])
            : [newValue],
        };
        setSelectedPreferredPositions(newSelectedPositions);
        onChange(newSelectedPositions);
      },
      [onChange, selectedPreferredPositions, setSelectedPreferredPositions]
    );

    const channels: ColumnFilterItem[] = useMemo(
      /**
       * Creates channel filter items.
       */
      () =>
        [
          ...new Set(
            (blocks ?? emptyBlocks).map(
              ({ break: { channel } }) => channel.description
            )
          ),
        ]
          .filter((value) => value && value !== "")
          .sort()
          .map((value) => ({ text: value, value })),
      [blocks]
    );

    const handleRowSelectionChange = useCallback(
      /**
       * Handles a change in row selection.
       * @param rowKeys The new selection.
       */
      (rowKeys: string[]) => {
        setSelectedRowKeys(rowKeys);
      },
      []
    );

    const rowSelection = useMemo<TableRowSelection<AvailableBreak>>(
      /**
       * Returns the row selection.
       */
      () =>
        ({
          getCheckboxProps: (availableBreak) => ({
            // voor AdAlliance mag je alleen maar bijboeken als `bookingIndicator === 2`
            disabled:
              !availableBreak.canBook ||
              (bookingIndicator === 2 && availableBreak.selected) ||
              (availableBreak.selected && !availableBreak.canCancel),
            name: availableBreak.break.uniqueId,
          }),
          onChange: handleRowSelectionChange,
          selectedRowKeys,
          columnWidth: 60,
          fixed: true,
        } as TableRowSelection<AvailableBreak>),
      [bookingIndicator, handleRowSelectionChange, selectedRowKeys]
    );

    const handleFilterAll = useCallback(() => {
      /**
       * Handles a request to show all items.
       */
      setSelectionFilterValue("All");
    }, []);

    const handleFilterSelectionOnly = useCallback(
      /**
       * Handles a request to show only selected items.
       */
      () => {
        setSelectionFilterValue("OnlySelected");
      },
      []
    );

    const columns: ColumnProps<AvailableBreak>[] = useMemo(
      () => [
        mode === "BookSpot"
          ? {
              fixed: "left",
              key: "status",
              render: (_, cellObj): ReactNode => {
                const { break: breakObj, selected: wasSelected } = cellObj;
                const breakUid = breakObj.uniqueId;
                const isSelected = selectedRowKeys.indexOf(breakUid) > -1;
                return (
                  <>
                    {wasSelected && !isSelected && (
                      <Tooltip title={i18n._(t`Verzoek om blok te annuleren`)}>
                        <Badge dot status="error" />
                      </Tooltip>
                    )}
                    {!wasSelected && isSelected && (
                      <Tooltip title={i18n._(t`Verzoek om blok toe te voegen`)}>
                        <Badge dot status="success" />
                      </Tooltip>
                    )}
                  </>
                );
              },
              width: 15,
            }
          : { fixed: "left", key: "empty", width: 0 },
        {
          fixed: "left",
          key: "scheduledDate",
          render: (_, { break: { scheduledDate } }): ReactNode => (
            <>{moment(scheduledDate).format("ll")}</>
          ),
          showSorterTooltip: false,
          sorter: (a, b): number =>
            moment(a.break.scheduledDate).toDate().getTime() -
            moment(b.break.scheduledDate).toDate().getTime(),
          title: <Trans>Datum</Trans>,
          width: 110,
        },
        {
          filterDropdown: ({
            clearFilters,
            confirm,
            prefixCls,
            selectedKeys,
            setSelectedKeys,
            visible: timeWindowFilterVisible,
          }: FilterDropdownProps): ReactNode => (
            <BlocksPickerTimeWindowFilter
              clearFilters={clearFilters}
              confirm={confirm}
              prefixCls={prefixCls}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              visible={timeWindowFilterVisible}
            />
          ),
          filterIcon: <SearchOutlined />,
          fixed: "left",
          key: "scheduledStartTime",
          onFilter: (value, { break: { scheduledStartTime } }): boolean => {
            const timeWindow = timeWindows.find((tw) =>
              tw.startTime.isSame(momentTime(value as string))
            );

            if (!timeWindow) {
              return false;
            }

            const windowStart = moment(scheduledStartTime).set({
              hour: timeWindow.startTime.hour(),
              minute: timeWindow.startTime.minute(),
              seconds: 0,
            });
            const windowEnd = moment(scheduledStartTime).set({
              hour: timeWindow.endTime.hour(),
              minute: timeWindow.endTime.minute(),
              seconds: 0,
            });

            return (
              windowStart.isSameOrBefore(scheduledStartTime) &&
              windowEnd.isSameOrAfter(scheduledStartTime)
            );
          },
          render: (_, { break: { scheduledStartTime } }): ReactNode => (
            <Tooltip title={moment(scheduledStartTime).format("LLL")}>
              <span>{moment(scheduledStartTime).format("HH:mm")}</span>
            </Tooltip>
          ),
          showSorterTooltip: false,
          sorter: (a, b): number =>
            moment(a.break.scheduledStartTime).toDate().getTime() -
            moment(b.break.scheduledStartTime).toDate().getTime(),
          title: <Trans>Tijd</Trans>,
          width: 64,
        },
        {
          fixed: "left",
          key: "breakId",
          render: (_, { break: { breakId } }): ReactNode => <>{breakId}</>,
          showSorterTooltip: false,
          sorter: (a, b): number =>
            `${a.break.breakId}`.localeCompare(b.break.breakId),
          title: <Trans>Blok</Trans>,
          width: 115,
        },
        {
          filters: channels,
          key: "channel",
          onFilter: (value, { break: { channel } }): boolean =>
            channel && channel.description === value,
          render: (
            _,
            {
              break: {
                channel: { description },
              },
            }
          ): ReactNode => <Ellipsis text={description} />,
          showSorterTooltip: false,
          sorter: (a, b): number => {
            const aChannel =
              a.break.channel.intomartCode ?? a.break.channel.description;
            const bChannel =
              b.break.channel.intomartCode ?? b.break.channel.description;
            return aChannel.localeCompare(bChannel);
          },
          title: <Trans>Zender</Trans>,
          width: 115,
        },
        {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }: FilterDropdownProps): ReactNode => (
            <ColumnSearchFilter
              text={
                selectedKeys && selectedKeys[0]
                  ? selectedKeys[0].toString()
                  : ""
              }
              setSelectedKeys={setSelectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          ),
          filterIcon: <SearchOutlined />,
          key: "programBefore",
          onFilter: (value, { break: { programBefore } }): boolean =>
            new RegExp(`${`${value}`.toLowerCase()}`).test(
              programBefore.toLowerCase()
            ),
          render: (_, { break: { programBefore } }): ReactNode => (
            <Ellipsis text={programBefore} />
          ),
          showSorterTooltip: false,
          sorter: (a, b): number =>
            a.break.programBefore.localeCompare(b.break.programBefore),
          title: <Trans>Programma voor</Trans>,
          width: 150,
        },
        {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }: FilterDropdownProps): ReactNode => (
            <ColumnSearchFilter
              text={
                selectedKeys && selectedKeys[0]
                  ? selectedKeys[0].toString()
                  : ""
              }
              setSelectedKeys={setSelectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          ),
          filterIcon: <SearchOutlined />,
          key: "programAfter",
          onFilter: (value, { break: { programAfter } }): boolean =>
            new RegExp(`${`${value}`.toLowerCase()}`).test(
              programAfter.toLowerCase()
            ),
          render: (_, { break: { programAfter } }): ReactNode => (
            <Ellipsis text={programAfter} />
          ),
          showSorterTooltip: false,
          sorter: (a, b): number =>
            a.break.programAfter.localeCompare(b.break.programAfter),
          title: <Trans>Programma na</Trans>,
          width: 150,
        },
        {
          align: "right",
          key: "predictedRating",
          render: (_, { break: { predictedRating = 0 } }): ReactNode => (
            <Grps amount={predictedRating} standalone={false} />
          ),
          showSorterTooltip: false,
          sorter: (a, b): number =>
            a.break.predictedRating - b.break.predictedRating,
          title: (
            <Tooltip
              placement="bottom"
              title={i18n._(
                t`De verwachte kijkdichtheid van het reclameblok binnen de geselecteerde doelgroep`
              )}
            >
              <span>
                <Trans>Prognose</Trans>
              </span>
            </Tooltip>
          ),
          width: 115,
        },
        {
          align: "right",
          key: "selectivity",
          render: (_, { break: { selectivity } }): ReactNode => (
            <Grps amount={selectivity} standalone={false} />
          ),
          showSorterTooltip: false,
          sorter: (a, b): number => a.break.selectivity - b.break.selectivity,
          title: (
            <Tooltip
              placement="bottom"
              title={i18n._(
                t`Index berekend als de verwachte kijkdichtheid van de geselecteerde doelgroep gedeeld op de kijkdichtheid van de basis doelgroep van de exploitant. Ad Alliance kent een basis doelgroep per zender.`
              )}
            >
              <span>
                <Trans>Selectiviteit</Trans>
              </span>
            </Tooltip>
          ),
          width: 125,
        },
        {
          align: "right",
          key: "PreferredPosition",
          render: (_, { break: breakObj, canBook }): ReactNode => {
            const breakUid = breakObj.uniqueId;
            const enabledVkp =
              enablePreferredPositionSelection &&
              selectedRowKeys.indexOf(breakUid) > -1;
            return (
              <PreferredPositionPicker
                enabled={canBook && enabledVkp}
                mode={mode === "BookSpot" ? "Single" : "Multiple"}
                onChange={handlePreferredPositionChange}
                preferredPositionsFree={positionsFree(
                  breakObj.positionFreeString
                )}
                uniqueId={breakUid}
                value={selectedPreferredPositions[breakUid]}
              />
            );
          },
          title: (
            <Tooltip
              placement="bottom"
              title={i18n._(
                t`Voorkeursposities kunnen alleen via een mail naar de exploitant bij- of afgeboekt worden.`
              )}
            >
              <span>
                <Trans>Voorkeurspositie</Trans>
              </span>
            </Tooltip>
          ),
          width: 200,
        },
      ],
      [
        channels,
        enablePreferredPositionSelection,
        handlePreferredPositionChange,
        i18n,
        mode,
        selectedPreferredPositions,
        selectedRowKeys,
      ]
    );

    const adAllianceTimeAlert = useMemo(
      () =>
        operator === OperatorRtl &&
        !moment().isBetween(adAllianceAllowStart, adAllianceAllowEnd),
      [operator]
    );

    const talpaTimeAlert = useMemo(
      () =>
        operator === OperatorTalpa &&
        !moment().isBetween(talpaAllowStart, talpaAllowEnd),
      [operator]
    );

    const okEnabled = useMemo<boolean>(
      /**
       * Determines whether there have been any changes compared to the current state of the store. (BookSpot)
       * Or defaults to `true` if the block selection is for a new order request. (OrderRequest)
       */
      () =>
        (mode === "BookSpot" &&
          !arraysAreEqual(
            selectedRowKeys,
            blocksSafe.filter((b) => b.selected).map((b) => b.break.uniqueId)
          ) &&
          !adAllianceTimeAlert &&
          !talpaTimeAlert) ||
        mode === "OrderRequest",
      [adAllianceTimeAlert, blocksSafe, mode, selectedRowKeys, talpaTimeAlert]
    );

    const dataSource = useMemo(
      /**
       * The data source for the table.
       */
      () =>
        selectionFilterValue === "All"
          ? blocks
          : blocks?.filter(({ break: { uniqueId }, selected }) => {
              const isSelected =
                rowSelection.selectedRowKeys?.includes(uniqueId);
              const wasSelected =
                selected && !rowSelection.selectedRowKeys?.includes(uniqueId);

              return isSelected || wasSelected;
            }),
      [blocks, rowSelection.selectedRowKeys, selectionFilterValue]
    );

    const currentSelection = useMemo(
      () => [
        ...selectedBlocks.filter(
          ({ break: { uniqueId } }) =>
            !removedBreaks.some((b) => b.break.uniqueId === uniqueId)
        ),
        ...addedBreaks,
      ],
      [addedBreaks, removedBreaks, selectedBlocks]
    );

    const currentGrps = useMemo(
      () =>
        currentSelection.reduce<number>(
          (grps, b) => grps + b.break.predictedRating,
          0
        ),
      [currentSelection]
    );

    return (
      <FullScreenModal
        okEnabled={okEnabled}
        onCancel={onCancel}
        onOk={handleOk}
        title={i18n._(t`Blokselectie bewerken`)}
        visible={visible}
      >
        <MainContent>
          <Space>
            <Button
              type="default"
              onClick={handleFilterSelectionOnly}
              disabled={selectionFilterValue === "OnlySelected"}
            >
              <Trans>Toon mijn selectie</Trans>
            </Button>
            <Button
              type="default"
              onClick={handleFilterAll}
              disabled={selectionFilterValue === "All"}
            >
              <Trans>Toon alles</Trans>
            </Button>
            <Typography.Text>
              <Trans>
                Er zijn <strong>{currentSelection.length} blokken</strong>{" "}
                geselecteerd met totaal{" "}
                <Grps amount={currentGrps} standalone={false} strong />{" "}
                GRP&apos;s van de totaal{" "}
                <Grps amount={subOrderGrps ?? 0} standalone={false} strong />{" "}
                aangevraagde GRP&apos;s
                {subOrderGrps && (
                  <>
                    {" ("}
                    <Amount
                      value={(currentGrps / subOrderGrps) * 100}
                      suffix="%"
                    />
                    )
                  </>
                )}
                .
              </Trans>
            </Typography.Text>
            {/* operator kan evt. onbekend zijn als er iets mis gaat met ophalen van pakketten, toon dan ook maar de waarschuwing */}
            {!loading &&
              (operator === OperatorRtl || operator === OperatorUnknown) && (
                <Alert
                  message={i18n._(
                    t`Bij Ad Alliance is bij- en afboeken alleen toegestaan tussen 08:30 uur en 18:30 uur.`
                  )}
                  type="warning"
                />
              )}
            {!loading && operator === OperatorTalpa && (
              <Alert
                message={i18n._(
                  t`Bij Talpa is bij- en afboeken alleen toegestaan tussen 06:00 uur en 00:00 uur.`
                )}
                type="warning"
              />
            )}
          </Space>
          <Table
            bordered
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            locale={{
              emptyText:
                !blocks && loading ? (
                  " "
                ) : (
                  <Empty description={i18n._(t`Geen blokken aanwezig.`)} />
                ),
            }}
            pagination={{
              defaultPageSize: 100,
              pageSizeOptions: ["100", "250", "500"],
            }}
            rowKey={(availableBreak: AvailableBreak): string =>
              availableBreak.break.uniqueId
            }
            rowSelection={rowSelection}
            scroll={{ x: 1580, y: 620 }}
            size="small"
          />
        </MainContent>
      </FullScreenModal>
    );
  }
);

export default BlocksPicker;
