import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { memo } from "react";

import ActivationPasswordStepModel from "../../models/ActivationPasswordStepModel";
import ActivationStepProps from "./ActivationStepProps";
import PasswordForm from "./PasswordForm";

const ActivationPasswordStep = memo(
  ({ onNext }: ActivationStepProps<ActivationPasswordStepModel>) => {
    const { i18n } = useLingui();

    return (
      <PasswordForm
        name="PasswordActivation"
        onSuccess={onNext}
        buttonLabel={i18n._(t`Volgende`)}
        showLoginButton={false}
      />
    );
  }
);

export default ActivationPasswordStep;
