import { DownloadOutlined } from "@ant-design/icons";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Button } from "antd";
import { saveAs } from "file-saver";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import {
  commercialDownloadCategory,
  triggerAnalyticsEvent,
} from "../utils/analytics";
import Video from "../Video";

export interface CommercialVideoViewProps {
  id: string;
  lowResFilename: string;
}

export interface CommercialVideoProps {
  commercial: CommercialVideoViewProps;
  thumbnailDataUri: string;
  onDownload: OnBlobDownloadFunc;
  i18n: I18n;
  mediaCover?: boolean;
}

const CommercialVideo: FunctionComponent<CommercialVideoProps> = ({
  commercial,
  thumbnailDataUri,
  onDownload,
  i18n,
  mediaCover,
}) => {
  const [videoDataUri, updateUri] = useState("");
  useEffect(() => {
    onDownload(`video-${commercial.id}`, commercial.lowResFilename, (blob) => {
      updateUri(URL.createObjectURL(blob as Blob));
      triggerAnalyticsEvent(commercialDownloadCategory, commercial.id);
    });
  }, [commercial.id, commercial.lowResFilename, updateUri, onDownload]);

  const handleDownload = useCallback(() => {
    const deliveryId = `video-${commercial.id}`;
    onDownload(deliveryId, commercial.lowResFilename, (blob) => {
      const fileExtension = commercial.lowResFilename.split(".").pop();
      const filename = `${commercial.id}.${fileExtension}`;
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob as Blob, filename);
      } else {
        saveAs(blob as Blob, filename);
      }
    });
  }, [commercial.id, commercial.lowResFilename, onDownload]);

  return (
    <>
      <Video
        videoDataUri={videoDataUri}
        thumbnailDataUri={thumbnailDataUri}
        i18n={i18n}
        mediaCover={mediaCover}
      />
      <div style={{ textAlign: "center" }}>
        <Button
          icon={<DownloadOutlined />}
          type="link"
          onClick={handleDownload}
        >
          {i18n._(t`Download bestand`)}
        </Button>
      </div>
    </>
  );
};

export default CommercialVideo;
