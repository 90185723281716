import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Empty, Table, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import { compact, flattenDeep, flow, map, orderBy, uniqBy } from "lodash/fp";
import moment from "moment/moment";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DashboardData,
  DashboardOrder,
  DashboardTile,
  DashboardTileData,
  SpotWithTargetGroup,
} from "../../models/Dashboard";
import { getOperatorFromId } from "../../models/Operator";
import { spotsCell } from "../../store/dashboard/cell";
import PreferredPositionView from "../../views/campaigns/PreferredPositionView";
import SpotInfoPopup from "../../views/campaigns/SpotInfoPopup";
import SpotZoomPopup from "../../views/campaigns/SpotZoomPopup";
import Ellipsis from "../../views/Ellipsis";
import Euro from "../../views/Euro";
import FullScreenModal from "../../views/FullScreenModal";
import Grps from "../../views/Grps";
import { OperatorAvatar } from "../../views/OperatorIcon";

export interface DashboardSpotsDialogProps {
  show: boolean;
  tile: DashboardTile | null;
  data: DashboardTileData | null;
  onClose: () => void;
}

const DashboardSpotsDialog = ({
  show,
  tile,
  data,
  onClose,
}: DashboardSpotsDialogProps) => {
  const { i18n } = useLingui();
  const now = moment();

  const dispatch = useDispatch();
  const getSpots = useCallback(() => {
    if (!tile || !data) {
      return;
    }

    const campaignIds = flow(
      map((d: DashboardData) => map(({ id }: DashboardOrder) => id)(d.orders)),
      flattenDeep,
      compact
    )(data.data);
    if (campaignIds.length === 0) {
      return;
    }

    dispatch(
      spotsCell.require({
        dateFrom: data.period.from,
        dateTo: data.period.to,
        campaignIds,
        skoTargetGroupId: tile.skoTargetGroupId,
      })
    );
  }, [data, dispatch, tile]);

  const spotsFromStore = useSelector(spotsCell.select);
  useEffect(() => {
    if (show) {
      getSpots();
    }
  }, [getSpots, show]);

  const channels = useMemo(
    () =>
      flow(
        map((s: SpotWithTargetGroup) => ({
          text: s.spot.channel.description,
          value: s.spot.channel.description,
        })),
        uniqBy("text"),
        orderBy(["text"], ["asc"])
      )(spotsFromStore.value ?? []),
    [spotsFromStore.value]
  );

  const targetGroups = useMemo(
    () =>
      flow(
        map((s: SpotWithTargetGroup) => ({
          text: s.targetGroup.description,
          value: s.targetGroup.id,
        })),
        uniqBy("text"),
        orderBy(["text"], ["asc"])
      )(spotsFromStore.value ?? []),
    [spotsFromStore.value]
  );

  const columns: ColumnProps<SpotWithTargetGroup>[] = useMemo(
    () => [
      {
        title: i18n._(t`Datum`),
        key: "scheduledDate",
        width: 110,
        fixed: "left",
        showSorterTooltip: false,
        sorter: (a, b): number =>
          moment(a.spot.scheduledDate).toDate().getTime() -
          moment(b.spot.scheduledDate).toDate().getTime(),
        render: (_, { spot: { scheduledDate } }): ReactNode => (
          <>{moment(scheduledDate).format("ll")}</>
        ),
      },
      {
        title: i18n._(t`Tijd`),
        key: "scheduledStartDate",
        width: 64,
        fixed: "left",
        showSorterTooltip: false,
        sorter: (a, b): number =>
          moment(a.spot.scheduledStartDate).toDate().getTime() -
          moment(b.spot.scheduledStartDate).toDate().getTime(),
        render: (_, { spot: { scheduledStartDate } }): ReactNode => (
          <Tooltip title={moment(scheduledStartDate).format("LLL")}>
            <span>{moment(scheduledStartDate).format("HH:mm")}</span>
          </Tooltip>
        ),
      },
      {
        title: i18n._(t`Blok`),
        key: "breakId",
        fixed: "left",
        width: 115,
        showSorterTooltip: false,
        sorter: (a, b): number =>
          `${a.spot.breakId}`.localeCompare(b.spot.breakId),
        render: (_, { spot: { breakId } }): ReactNode => breakId,
      },
      {
        title: i18n._(t`Zender`),
        key: "channel",
        width: 115,
        filters: channels,
        onFilter: (value, { spot: { channel } }): boolean =>
          channel && channel.description === value,
        showSorterTooltip: false,
        sorter: (a, b): number => {
          const aChannel = a.spot.channel.description;
          const bChannel = b.spot.channel.description;
          return aChannel.localeCompare(bChannel);
        },
        render: (
          _,
          {
            spot: {
              channel: { description },
            },
          }
        ): ReactNode => <Ellipsis text={description} />,
      },
      {
        key: "operator",
        width: 48,
        align: "center",
        render: (_, { spot: { id } }): ReactNode => {
          const operator = getOperatorFromId(id);
          return <OperatorAvatar operator={operator} invert />;
        },
      },
      {
        title: i18n._(t`Programma voor`),
        key: "programBefore",
        showSorterTooltip: false,
        sorter: (a, b): number =>
          a.spot.programBefore.localeCompare(b.spot.programBefore),
        render: (_, { spot: { programBefore } }): ReactNode => (
          <Ellipsis text={programBefore} />
        ),
      },
      {
        title: i18n._(t`Programma na`),
        key: "programAfter",
        showSorterTooltip: false,
        sorter: (a, b): number =>
          a.spot.programAfter.localeCompare(b.spot.programAfter),
        render: (_, { spot: { programAfter } }): ReactNode => (
          <Ellipsis text={programAfter} />
        ),
      },
      {
        title: i18n._(t`Lengtes`),
        key: "spotLengths",
        width: 100,
        showSorterTooltip: false,
        sorter: (a, b): number => a.spot.spotLength - b.spot.spotLength,
        render: (_, { spot: { commercials } }): ReactNode =>
          `${commercials.map((c) => c.spotLength).join("+")}"`,
      },
      {
        title: i18n._(t`Doelgroep`),
        key: "targetGroup",
        width: 150,
        showSorterTooltip: false,
        sorter: (a, b): number =>
          a.targetGroup.id.localeCompare(b.targetGroup.id),
        render: (_, { targetGroup: { description } }): ReactNode => (
          <Ellipsis text={description} />
        ),
        filters: targetGroups,
        onFilter: (value, { targetGroup: { id } }): boolean => id === value,
      },
      {
        title: <span>{i18n._(t`Netto tarief`)} (€)</span>,
        key: "netSpotPrice",
        align: "right",
        width: 140,
        showSorterTooltip: false,
        sorter: (a, b): number =>
          a.spot.finances && b.spot.finances
            ? a.spot.finances.netSpotPrice - b.spot.finances.netSpotPrice
            : 0,
        render: (_, { spot: { finances } }): ReactNode =>
          finances ? <Euro amount={finances.netSpotPrice} /> : null,
      },
      {
        align: "center",
        key: "predictedRating",
        render: (_, { spot: { predictedRating } }): ReactNode => (
          <Grps amount={predictedRating} />
        ),
        showSorterTooltip: false,
        sorter: (a, b): number =>
          (a.spot.predictedRating ?? 0) - (b.spot.predictedRating ?? 0),
        title: (
          <Tooltip
            placement="bottom"
            title={i18n._(
              t`De verwachte kijkdichtheid van het reclameblok binnen de geselecteerde inkoopdoelgroep.`
            )}
          >
            <span>
              <Trans>Prognose</Trans>
            </span>
          </Tooltip>
        ),
        width: 115,
      },
      {
        title: (
          <Tooltip
            placement="bottom"
            title={i18n._(
              t`De getoonde GRP's voor een datum in de toekomst is gelijk aan de prognose. De getoonde GRP's gelden voor de inkoopdoelgroep.`
            )}
          >
            <span>
              <Trans>Gerealiseerd</Trans>
            </span>
          </Tooltip>
        ),
        key: "achievedRating",
        width: 125,
        align: "center",
        showSorterTooltip: false,
        sorter: (a, b): number =>
          (a.spot.achievedRating ?? a.spot.predictedRating ?? 0) -
          (b.spot.achievedRating ?? b.spot.predictedRating ?? 0),
        render: (
          _,
          { spot: { achievedRating, predictedRating, scheduledDate } }
        ): ReactNode => {
          const isPredicted =
            achievedRating !== undefined &&
            moment(scheduledDate).isSameOrAfter(now, "day");
          return (
            <Grps
              amount={isPredicted ? predictedRating : achievedRating}
              isPredicted={isPredicted}
            />
          );
        },
      },
      {
        title: (
          <Tooltip placement="bottom" title={i18n._(t`Voorkeurspositie`)}>
            <span>
              <Trans>VKP</Trans>
            </span>
          </Tooltip>
        ),
        key: "preferredPosition",
        width: 75,
        align: "center",
        render: (_, { spot: { preferredPosition } }): ReactNode => (
          <PreferredPositionView preferredPosition={preferredPosition} />
        ),
      },
      {
        title: (
          <Tooltip
            placement="bottom"
            title={i18n._(
              t`Deze kolom bevat detail informatie over de uitgezonden commercial`
            )}
          >
            <span>
              <Trans>Detail</Trans>
            </span>
          </Tooltip>
        ),
        key: "extraInfo",
        width: 75,
        align: "center",
        render: (_, spot): ReactNode => <SpotInfoPopup spot={spot.spot} />,
      },
      {
        title: (
          <Tooltip
            placement="bottom"
            title={i18n._(
              t`Inzoomen op de uitgezonden commercials bij de regionale omroepen (Ster)`
            )}
          >
            <span>
              <Trans>Inzoomen</Trans>
            </span>
          </Tooltip>
        ),
        key: "zoom",
        width: 75,
        align: "center",
        render: (
          _,
          { spot: { scheduledStartDate, spotRatingRegios } }
        ): ReactNode =>
          moment(scheduledStartDate).startOf("day").date() <
            moment().startOf("day").date() &&
          spotRatingRegios &&
          spotRatingRegios.length > 0 ? (
            <SpotZoomPopup spotRatingsRegio={spotRatingRegios} />
          ) : (
            <></>
          ),
      },
    ],
    [channels, i18n, now, targetGroups]
  );

  return (
    <FullScreenModal
      footer={[
        <Button key="CloseButton" type="primary" onClick={onClose}>
          <Trans>Sluiten</Trans>
        </Button>,
      ]}
      onCancel={onClose}
      onOk={onClose}
      title={i18n._(t`Uitzendschema`)}
      visible={show}
    >
      <Table
        bordered
        rowKey={(record): string => record.spot.id}
        columns={columns}
        dataSource={spotsFromStore.value}
        loading={spotsFromStore.status.loading}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ["100", "250", "500"],
        }}
        size="small"
        scroll={{ x: 1580, y: 640 }}
        locale={{
          emptyText:
            !spotsFromStore.value && spotsFromStore.status.loading ? (
              " "
            ) : (
              <Empty description={i18n._(t`Geen spots aanwezig.`)} />
            ),
        }}
      />
    </FullScreenModal>
  );
};

export default DashboardSpotsDialog;
