import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { ColumnProps } from "antd/lib/table";
import {
  ColumnFilterItem,
  CompareFn,
  Key,
  SortOrder,
} from "antd/lib/table/interface";
import React from "react";

import { organisationTypeLabels } from "../../../models/Organisation";
import {
  MonthStatistics,
  StatisticsByOrganisationBaseModel,
  StatisticsByOrganisationModel,
} from "../../../models/StatisticsModels";
import Ellipsis from "../../../views/Ellipsis";

export interface TotalFilterOptions<T> {
  width: number;
  filters: ColumnFilterItem[];
  filterMultiple: boolean;
  defaultFilteredValue: Key[];
  onFilter?: (value: string | number | boolean, record: T) => boolean;
  showSorterTooltip?: boolean;
  sorter?:
    | boolean
    | CompareFn<T>
    | {
        compare: CompareFn<T>;
        /** Config multiple sorter order priority */
        multiple: number;
      };
  defaultSortOrder?: SortOrder;
}

export const totalFilterOptions: TotalFilterOptions<StatisticsByOrganisationModel> =
  {
    width: 125,
    filters: [
      { text: "Met statistieken", value: "filtered" },
      { text: "Toon alles", value: "all" },
    ],
    defaultFilteredValue: ["filtered"],
    filterMultiple: false,
    onFilter: (value, { statistics }): boolean => {
      const totals = getTotals(statistics);
      return value === "filtered" && totals !== undefined ? totals > 0 : true;
    },
    showSorterTooltip: false,
    sorter: (a, b) => {
      const totalsA = getTotals(a.statistics) ?? 0;
      const totalsB = getTotals(b.statistics) ?? 0;
      return totalsA - totalsB;
    },
  };

export const getTotals = (
  statistics: MonthStatistics | undefined
): number | undefined =>
  statistics
    ? Object.values<number | undefined>(statistics).reduce(
        (previousValue, currentValue) =>
          (previousValue ?? 0) + (currentValue ?? 0),
        0
      )
    : 0;

export const organisationTypeColumn = (
  i18n: I18n
): ColumnProps<StatisticsByOrganisationBaseModel> => ({
  key: "organisationType",
  title: i18n._(t`Type`),
  width: 150,
  fixed: "left",
  render: (_text, { type }) => (
    <Ellipsis text={i18n._(organisationTypeLabels[type])} />
  ),
  filterMultiple: false,
  filters: [
    {
      text: i18n._(organisationTypeLabels.Advertiser),
      value: "Advertiser",
    },
    {
      text: i18n._(organisationTypeLabels.MediaAgency),
      value: "MediaAgency",
    },
    {
      text: i18n._(organisationTypeLabels.ProductionCompany),
      value: "ProductionCompany",
    },
  ],
  onFilter: (value, { type }) => (value ? type === value : true),
});
