import { Trans } from "@lingui/macro";
import { Button, Form } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";

import User from "../../../models/User";
import { StoreModel } from "../../../store/models";
import propertyOf from "../../../utils/properties";

const idField = propertyOf<User>("id");
const nameField = propertyOf<User>("name");
const emailAddressField = propertyOf<User>("emailAddress");
const organisationIdField = propertyOf<User>("organisationId");

const UserFormItemConfirmButton = ({ disabled }: { disabled: boolean }) => {
  const isSaving = useSelector(
    ({ users: { register } }: StoreModel) => register.status.loading ?? false
  );

  return (
    <Form.Item noStyle shouldUpdate>
      {(form) => {
        const idValue = form.getFieldValue(idField);
        const fieldsError = form.getFieldsError();
        const fieldsTouched = form.isFieldsTouched(
          [nameField, emailAddressField, organisationIdField],
          true
        );
        const confirmEnabled =
          Boolean(idValue) ||
          (fieldsTouched &&
            !fieldsError.some((fieldError) => fieldError.errors.length > 0));

        const handleClick = () => {
          form.submit();
        };

        return (
          <Button
            key="confirmButton"
            disabled={disabled || !confirmEnabled}
            htmlType="button"
            loading={isSaving}
            onClick={handleClick}
            type="primary"
          >
            <Trans>Opslaan</Trans>
          </Button>
        );
      }}
    </Form.Item>
  );
};

export default memo(UserFormItemConfirmButton);
