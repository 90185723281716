// polyfill only stable `core-js` features - ES and web standards:
import "core-js/stable";
import "moment/locale/nl";

import "./index.less";

import moment from "moment";
import * as momentTZ from "moment-timezone";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";

import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import { resetZendeskProps } from "./utils/zendesk";
import { isGAEnabled, trackingCode } from "./views/utils/analytics";

momentTZ.tz.setDefault("Europe/Amsterdam");
moment.locale("nl");

// Google Analytics
if (isGAEnabled) {
  TagManager.initialize({
    gtmId: trackingCode,
  });
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R;
    zE?: (
      id: string,
      func: string,
      options?: Record<string, unknown> | string
    ) => void;
  }

  interface Navigator {
    msSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
  }
}

window.onbeforeunload = () => {
  resetZendeskProps();
};

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <ErrorBoundary
      instrumentationKey={window.tipApplicationOptions?.instrumentationKey}
    >
      <App />
    </ErrorBoundary>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
