import { UserProfile } from "../../models/User";
import {
  jsonDeleter,
  jsonGetter,
  jsonPoster,
  jsonPutter,
  poster,
} from "../fetch";
import { ApiPromise, TokenApiPromise } from "../models";
import {
  GetAllUsersResponse,
  UserDeleteRequest,
  UserDeleteResponse,
  UserRegistrationRequest,
  UserRegistrationResponse,
  UserResetAuthenticationRequest,
  UserUpdateRequest,
} from "./models";

export const allUsersGetter = (): TokenApiPromise<GetAllUsersResponse> =>
  jsonGetter<unknown, GetAllUsersResponse>("/api/user/all");

export const registerUserPoster =
  (
    request: UserRegistrationRequest
  ): TokenApiPromise<UserRegistrationResponse> =>
  (token?: string): ApiPromise<UserRegistrationResponse> =>
    jsonPoster<UserRegistrationResponse, UserRegistrationRequest>("/api/user")(
      request,
      token
    );

/**
 * Updates an existing user.
 * @param request The user update request.
 */
export const updateUserPutter =
  (request: UserUpdateRequest): TokenApiPromise<unknown> =>
  (token?: string): ApiPromise<unknown> =>
    jsonPutter<unknown, UserUpdateRequest>(`/api/user/${request.user.id}`)(
      request,
      token
    );

/**
 * Resets a user's authentication.
 * @param request A request that indicates which user's authentication to reset.
 */
export const resetUserPoster =
  (request: UserResetAuthenticationRequest): TokenApiPromise<unknown> =>
  (token?: string): ApiPromise<unknown> =>
    jsonPoster<unknown, UserResetAuthenticationRequest>(`/api/user/reset`)(
      request,
      token
    );

/**
 * 'Deletes' (hides and disables) an existing user.
 * @param request The user delete request.
 */
export const deleteUserDeleter =
  (request: UserDeleteRequest): TokenApiPromise<UserDeleteResponse> =>
  (token?: string): ApiPromise<UserDeleteResponse> =>
    jsonDeleter<UserDeleteResponse>(`/api/user/${request.userId}`)(token);

export const updateUserProfile = (input: UserProfile) =>
  poster<undefined, UserProfile>("/api/user/profile", input);
