import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Badge, Tooltip } from "antd";
import React, { memo, useMemo } from "react";

import { OrderRequestStatus } from "../../../models/Campaigns/Requests/models";

const OrderRequestStatusView = memo(
  ({ status }: { status: OrderRequestStatus }) => {
    const { i18n } = useLingui();

    const conceptText = useMemo(() => i18n._(t`Concept`), [i18n]);
    const submittedText = useMemo(() => i18n._(t`Ingediend`), [i18n]);
    const errorText = useMemo(() => i18n._(t`Fout`), [i18n]);
    const deletedTooltipText = useMemo(
      () =>
        i18n._(
          t`Aanvraag is na een controle niet meer aanwezig bij de exploitant`
        ),
      [i18n]
    );
    const deletedText = useMemo(() => i18n._(t`Verwijderd`), [i18n]);

    switch (status) {
      case "Concept":
        return <Badge status="processing" text={conceptText} />;
      case "Submitted":
        return <Badge status="success" text={submittedText} />;
      case "Error":
        return <Badge status="error" text={errorText} />;
      case "Deleted":
        return (
          <Tooltip title={deletedTooltipText}>
            <Badge status="warning" text={deletedText} />
          </Tooltip>
        );
      default:
        return null;
    }
  }
);

export default OrderRequestStatusView;
