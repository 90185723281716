import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Modal, Typography, message } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Operator, operatorLabels } from "../../models/Operator";
import { preferredPositionNone } from "../../models/Spot";
import {
  AvailableBreak,
  BookSpotRequest,
  PreferredPositionToRequest,
  RequestToPreferredPosition,
  RequestedPreferredPosition,
} from "../../store/bookspot/models";
import { RequestAction, StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { getUsername } from "../../store/token";
import { handleFailWithProblem } from "../../utils";
import {
  bookSpotRequestCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import { SelectedPreferredPositionDictionary } from "../campaigns/requests/subOrders/models";
import BlocksPicker from "./BlocksPicker";
import {
  BlockSelectionItem,
  BlockSelectionMode,
  getUniqueId,
  toBreakRequest,
} from "./models";

interface BlocksPickerContainerProps {
  enablePreferredPositionSelection: boolean;
  initialValue?: BlockSelectionItem[];
  mode: BlockSelectionMode;
  onCancel: () => void;
  onChange: (preferredPositions: SelectedPreferredPositionDictionary) => void;
  onFinish: (selection: BlockSelectionItem[]) => void;
  orderId?: string;
  orderDescription?: string;
  preferredPositions: SelectedPreferredPositionDictionary;
  spotLengths: number[];
  subOrderId?: string;
  subOrderGrps?: number;
  visible: boolean;
  bookingIndicator?: number;
  operator: Operator;
}

const emptyAvailableBreaks: AvailableBreak[] = [];
const emptySelectedBlocks: BlockSelectionItem[] = [];

const BlocksPickerContainer = memo(
  ({
    enablePreferredPositionSelection,
    initialValue,
    mode,
    onCancel,
    onChange,
    onFinish,
    orderId,
    orderDescription,
    preferredPositions,
    spotLengths,
    subOrderId,
    subOrderGrps,
    visible,
    bookingIndicator, // AdAlliance: als `2` => ALLEEN BIJBOEKEN met `Request`
    operator,
  }: BlocksPickerContainerProps) => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [selectedBlocks, setSelectedBlocks] = useState<BlockSelectionItem[]>(
      initialValue || emptySelectedBlocks
    );

    useEffect(() => {
      if (orderId && subOrderId) {
        setSelectedBlocks(emptySelectedBlocks);
      }
    }, [orderId, subOrderId]);

    const {
      status: { loading: requestAvailableBreaksLoading },
      value: requestAvailableBreaksValue,
    } = useSelector(
      /**
       * Selected the available breaks response in the Redux store.
       * @param availableBreaks The available breaks response that is found in the Redux store.
       */
      ({ requests: { availableBreaks } }: StoreModel) => availableBreaks
    );

    const {
      status: { loading: bookSpotAvailableBreaksLoading },
      value: bookSpotAvailablebreaksValue,
    } = useSelector(
      /**
       * Selects the available breaks response in the Redux store.
       * @param availableBreaks The available breaks response that is found in the Redux store.
       */
      ({ bookSpot: { availableBreaks } }: StoreModel) =>
        !availableBreaks.status.loading
          ? availableBreaks
          : {
              status: availableBreaks.status,
              value: { availableBreaks: emptyAvailableBreaks },
            }
    );

    const { loading: packagesLoading } = useSelector(
      /**
       * Selects the status of the packages.
       * @param status The status of the packages.
       */
      ({
        requests: {
          packages: { status },
        },
      }: StoreModel) => status
    );

    const loading = useMemo(
      /**
       * Returns a value whether the data is loading.
       */
      () =>
        bookSpotAvailableBreaksLoading ||
        requestAvailableBreaksLoading ||
        packagesLoading,
      [
        bookSpotAvailableBreaksLoading,
        requestAvailableBreaksLoading,
        packagesLoading,
      ]
    );

    const tomorrow = moment().add(1, "days").startOf("day");
    const availableBlocks = useMemo(
      /**
       * Safely returns the available breaks from the store value.
       * If the store value is not present, an empty array will be returned.
       */
      () =>
        bookSpotAvailablebreaksValue?.availableBreaks ||
        requestAvailableBreaksValue?.map(
          (b): AvailableBreak => ({
            break: b,
            selected: false,
            canBook: moment(b.scheduledDate).isSameOrAfter(tomorrow),
            canCancel: true,
          })
        ) ||
        [],
      [
        bookSpotAvailablebreaksValue?.availableBreaks,
        requestAvailableBreaksValue,
        tomorrow,
      ]
    );

    const handleCommit = useCallback(
      /**
       * Handles a change in the block selection.
       * @param blocks The new block selection.
       * @param newPreferredPositions The new selection of preferred positions.
       */
      (
        blocks: AvailableBreak[],
        newPreferredPositions: SelectedPreferredPositionDictionary
      ) => {
        const newSelectedBlocks: BlockSelectionItem[] = blocks.map(
          ({
            break: brk,
            break: {
              breakId,
              channel: { description: channelDescription },
              predictedRating,
              programAfter,
              programBefore,
              scheduledDate,
              scheduledStartTime,
            },
            selected,
            spotId,
          }) => ({
            // eslint-disable-next-line no-nested-ternary
            action: selected
              ? bookingIndicator && bookingIndicator === 2
                ? "Request"
                : "Book"
              : "Cancel",
            breakId,
            channelDescription,
            positionFreeIndicator: "",
            predictedRating,
            preferredPosition: (
              newPreferredPositions[brk.uniqueId] || [preferredPositionNone]
            )
              .map((p): number => PreferredPositionToRequest[p])
              .reduce(
                (a, b) => a + b,
                0
              ) as unknown as RequestedPreferredPosition,
            programmeAfter: programAfter,
            programmeBefore: programBefore,
            remarks: "",
            scheduledDate,
            scheduledStartDate: scheduledStartTime,
            selectivity: undefined,
            spotId,
            spotLengthCombination: spotLengths
              .map((sl) => sl.toString())
              .reduce((first, second) => `${first}+${second}`),
          })
        );
        setSelectedBlocks(newSelectedBlocks);
        switch (mode) {
          case "BookSpot":
            setConfirmModalVisible(true);
            break;
          case "OrderRequest":
          default:
            onFinish(newSelectedBlocks);
            break;
        }
      },
      [bookingIndicator, mode, onFinish, spotLengths]
    );

    const handleConfirm = useCallback(
      /**
       * Handles the confirmation of a new booking request.
       */
      () => {
        if (!orderId || !subOrderId) {
          return;
        }
        switch (mode) {
          case "BookSpot":
            dispatch<RequestAction<BookSpotRequest, unknown>>({
              type: sagaTypes.bookSpot.bookSpot.request,
              payload: {
                breakRequests: selectedBlocks.map(toBreakRequest),
                emailPlannerAgency: getUsername() || "",
                orderId,
                orderDescription: orderDescription || "",
                subOrderId,
              },
              onFail: handleFailWithProblem(i18n._(t`Boeking mislukt.`)),
              onSuccess: () => {
                onFinish(selectedBlocks);
                setConfirmModalVisible(false);
                const successMessage = i18n._(
                  t`De aanpassingen zijn ontvangen en doorgegeven aan de exploitant. Zodra zij de aanpassingen hebben doorgevoerd, zullen ze in TIP zichtbaar worden.`
                );
                message.success(successMessage);
                triggerAnalyticsEvent(
                  bookSpotRequestCategory,
                  `${orderId}-${subOrderId}`
                );
              },
            });
            break;
          case "OrderRequest":
          default:
            onFinish(selectedBlocks);
            break;
        }
      },
      [
        dispatch,
        i18n,
        mode,
        onFinish,
        orderDescription,
        orderId,
        selectedBlocks,
        subOrderId,
      ]
    );

    const handleUndo = useCallback(
      /**
       * Handles undoing a booking of spots.
       */
      () => {
        setConfirmModalVisible(false);
      },
      []
    );

    return (
      <>
        <BlocksPicker
          blocks={
            mode === "BookSpot"
              ? availableBlocks
              : availableBlocks.map((ab) => ({
                  ...ab,
                  preferredPosition:
                    RequestToPreferredPosition[
                      initialValue?.find(
                        (item) => getUniqueId(item) === ab.break.uniqueId
                      )?.preferredPosition || (0 as RequestedPreferredPosition)
                    ],
                  selected:
                    initialValue?.some(
                      (item) => getUniqueId(item) === ab.break.uniqueId
                    ) || false,
                }))
          }
          enablePreferredPositionSelection={enablePreferredPositionSelection}
          loading={loading}
          mode={mode}
          onCancel={onCancel}
          onChange={onChange}
          onCommit={handleCommit}
          preferredPositions={preferredPositions}
          visible={visible}
          bookingIndicator={bookingIndicator}
          operator={operator}
          subOrderGrps={subOrderGrps}
        />
        <Modal
          destroyOnClose
          onCancel={handleUndo}
          onOk={handleConfirm}
          open={confirmModalVisible}
          maskClosable={false}
          keyboard={false}
        >
          <Typography.Paragraph>
            <Trans>
              Weet je zeker dat je de geselecteerde spots wil bij-/afboeken?
            </Trans>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Trans>
              {operatorLabels[operator]} gaat vervolgens met het verzoek aan de
              slag. Zodra de aanpassingen doorgevoerd zijn, word je op de hoogte
              gebracht.
            </Trans>
          </Typography.Paragraph>
        </Modal>
      </>
    );
  }
);

export default BlocksPickerContainer;
