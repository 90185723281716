import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Empty, Spin } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";

import {
  OrderRequest,
  OrderRequestsByAdvertiser,
} from "../../../models/Campaigns/Requests/models";
import OrderRequestView from "../../../views/campaigns/requests/OrderRequestView";
import CollapseList from "../../../views/collapselists/CollapseList";
import CollapseListExtraActions from "../../../views/collapselists/CollapseListExtraActions";
import CollapseListExtraPeriod from "../../../views/collapselists/CollapseListExtraPeriod";
import CollapseListHeader from "../../../views/collapselists/CollapseListHeader";
import {
  orderRequestDownloadCategory,
  triggerAnalyticsEvent,
} from "../../../views/utils/analytics";
import { ItemOnClickEventHandler } from "../../actions/ItemActionButtonProps";
import ButtonBar from "./ButtonBar";
import { OverviewProps } from "./models";

const Overview = memo(
  ({
    loading,
    onDelete,
    onEdit,
    orderRequestsByAdvertiserItems,
    onExport,
    isDownloading,
    periods,
    onCopy,
  }: OverviewProps) => {
    const { i18n } = useLingui();

    const campaignKeyGetter = useCallback(
      ({ campaignName, id }: OrderRequest): string => `${id}:${campaignName}`,
      []
    );

    const campaignHeader = useCallback(
      ({ id, campaignName }: OrderRequest) => (
        <CollapseListHeader key={id} title={campaignName} />
      ),
      []
    );

    const [nowDownloading, setNowDownloading] = useState("");
    useEffect(() => {
      if (!isDownloading && nowDownloading !== "") {
        // reset `nowDownloading`
        setNowDownloading("");
      }
    }, [isDownloading, nowDownloading]);

    const handleExport = useCallback<ItemOnClickEventHandler>(
      (id, event) => {
        event?.preventDefault();
        event?.stopPropagation();

        if (id) {
          onExport(id);
          triggerAnalyticsEvent(orderRequestDownloadCategory, id);
          setNowDownloading(id);
        }
      },
      [onExport]
    );

    const campaignExtra = useCallback(
      ({ id, startDate, endDate, status, subOrders }: OrderRequest) => (
        <>
          <CollapseListExtraActions>
            <ButtonBar
              id={id}
              enabledEdit={status !== "Submitted" && status !== "Deleted"}
              onEdit={onEdit}
              enabledDelete={status !== "Submitted"}
              onDelete={onDelete}
              enabledExport={
                id !== undefined &&
                subOrders?.length > 0 &&
                nowDownloading === ""
              }
              onExport={handleExport}
              isExportLoading={
                nowDownloading !== "" && nowDownloading === id && isDownloading
              }
              periods={periods}
              onCopy={onCopy}
              enableCopy={id !== undefined && subOrders?.length > 0}
            />
          </CollapseListExtraActions>
          <CollapseListExtraPeriod
            period={[moment(startDate), moment(endDate)]}
          />
        </>
      ),
      [
        handleExport,
        isDownloading,
        nowDownloading,
        onCopy,
        onDelete,
        onEdit,
        periods,
      ]
    );

    const campaignContent = useCallback(
      (orderRequest: OrderRequest) => (
        <OrderRequestView orderRequest={orderRequest} />
      ),
      []
    );

    const advertiserKeyGetter = useCallback(
      ({ advertiserId }: OrderRequestsByAdvertiser) => advertiserId,
      []
    );
    const advertiserHeader = useCallback(
      ({ advertiserId, advertiserName }: OrderRequestsByAdvertiser) => (
        <CollapseListHeader key={advertiserId} title={advertiserName} />
      ),
      []
    );
    const advertiserExtra = useCallback(() => <></>, []);
    const advertiserContent = useCallback(
      ({ orderRequests }: OrderRequestsByAdvertiser) => (
        <CollapseList
          items={orderRequests}
          keyGetter={campaignKeyGetter}
          header={campaignHeader}
          extra={campaignExtra}
          content={campaignContent}
          variant="Details"
        />
      ),
      [campaignContent, campaignExtra, campaignHeader, campaignKeyGetter]
    );

    return (
      <Spin spinning={loading}>
        {orderRequestsByAdvertiserItems &&
          orderRequestsByAdvertiserItems.length > 0 && (
            <CollapseList
              items={orderRequestsByAdvertiserItems}
              keyGetter={advertiserKeyGetter}
              header={advertiserHeader}
              extra={advertiserExtra}
              content={advertiserContent}
              variant="Main"
            />
          )}
        {(!orderRequestsByAdvertiserItems ||
          orderRequestsByAdvertiserItems.length === 0) && (
          <Empty description={i18n._(t`Geen resultaten gevonden.`)} />
        )}
      </Spin>
    );
  }
);

export default Overview;
