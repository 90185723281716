import { DeleteOutlined } from "@ant-design/icons/lib";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Card, Col, Modal, Typography } from "antd";
import moment from "moment";
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";

import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import CommercialVideo from "../../views/commercials/CommercialVideo";
import Ellipsis from "../../views/Ellipsis";
import Image from "../../views/Image";
import { CommercialDeliveryView } from "./models";
import XmlExportButton from "./XmlExportButton";

interface CommercialViewProps {
  commercial: CommercialDeliveryView;
  onDownload: OnBlobDownloadFunc;
  onDelete: (id: string) => void;
}

const CommercialView: FunctionComponent<CommercialViewProps> = ({
  commercial,
  onDownload,
  onDelete,
}) => {
  const { i18n } = useLingui();
  const [dataUri, updateUri] = useState("");
  const openDialog = useCallback(() => {
    Modal.info({
      title: commercial.title,
      icon: null,
      content: (
        <CommercialVideo
          commercial={commercial}
          thumbnailDataUri={dataUri}
          onDownload={onDownload}
          i18n={i18n}
        />
      ),
      width: 720,
    });
  }, [commercial, dataUri, onDownload, i18n]);

  useEffect(() => {
    onDownload(`thumbnail-${commercial.id}`, commercial.thumbnail, (blob) =>
      updateUri(URL.createObjectURL(blob as Blob))
    );
  }, [commercial.id, commercial.thumbnail, updateUri, onDownload]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = useCallback(() => {
    setDeleting(true);
    Modal.confirm({
      title: <Ellipsis text={i18n._(t`${commercial.title} verwijderen?`)} />,
      icon: <DeleteOutlined />,
      content: (
        <Typography.Paragraph>
          {i18n._(
            t`De commercial wordt niet verwijderd bij de exploitanten. Alleen binnen TIP.`
          )}
          <br />
          {i18n._(t`Weet je het zeker?`)}
        </Typography.Paragraph>
      ),
      onCancel() {
        setDeleting(false);
      },
      onOk() {
        onDelete(commercial.id);
        return Promise.resolve().then(() => setDeleting(false));
      },
      cancelButtonProps: { type: "default" },
    });
  }, [commercial.id, commercial.title, i18n, onDelete]);

  return (
    <Col key={commercial.id} xs={24} sm={12} md={8} lg={6} xl={6}>
      <Card
        hoverable
        cover={
          <Image
            alt={commercial.title}
            dataUri={dataUri}
            onClick={openDialog}
            mode="play"
            width={640}
            height={320}
          />
        }
        actions={[
          ...commercial.advertisers
            .map((a) => a.operator)
            .sort()
            .map((operator) => (
              <XmlExportButton
                key={operator}
                id={commercial.id}
                operator={operator}
                src={`${commercial.id}/${operator.toLowerCase()}.xml`}
                onDownload={onDownload}
              />
            )),
        ]}
      >
        <Card.Meta
          title={commercial.title}
          description={
            <Typography.Paragraph>
              <Ellipsis text={commercial.advertisers[0].name} />
              <small>
                <Trans>Lengte</Trans>: {commercial.lengthInSeconds}&quot;
                <br />
                <Trans>Ontvangen bij TIP</Trans>:{" "}
                {moment(commercial.created).format("LLL")}
                <br />
                <Trans>Aangeleverd</Trans>:{" "}
                {moment(commercial.modified).format("LLL")}
                <br />
                <Button type="link" disabled={deleting} onClick={handleDelete}>
                  <Trans>Verwijder deze commercial</Trans>
                </Button>
              </small>
            </Typography.Paragraph>
          }
        />
      </Card>
    </Col>
  );
};

export default memo(CommercialView);
