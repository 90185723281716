import { Trans } from "@lingui/macro";
import { Button, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { FilterDropdownProps } from "antd/lib/table/interface";
import moment, { Moment } from "moment";
import React, { memo, useCallback } from "react";

interface TimeWindow {
  startTime: Moment;
  endTime: Moment;
}

const timeFormat = "HH:mm";

export const momentTime = (timeString: string): Moment =>
  moment(timeString, timeFormat, true);

const timeFirst = "00:00";

export const timeWindows: TimeWindow[] = [
  {
    startTime: momentTime(timeFirst),
    endTime: momentTime("06:00"),
  },
  {
    startTime: momentTime("06:00"),
    endTime: momentTime("18:00"),
  },
  {
    startTime: momentTime("18:00"),
    endTime: momentTime("23:59"),
  },
  {
    startTime: momentTime("19:30"),
    endTime: momentTime("23:00"),
  },
];

const BlocksPickerTimeWindowFilter = memo(
  ({
    clearFilters,
    confirm,
    selectedKeys,
    setSelectedKeys,
  }: Omit<FilterDropdownProps, "close">) => {
    const handleChange = useCallback(
      /**
       * Handles a change of the selected time window.
       */
      (value: SelectValue) => {
        setSelectedKeys([value as string]);
      },
      [setSelectedKeys]
    );

    const handleConfirm = useCallback(() => {
      confirm();
    }, [confirm]);

    return (
      <div style={{ padding: 8 }}>
        <Select
          onChange={handleChange}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          value={selectedKeys.length === 0 ? "" : (selectedKeys[0] as string)}
        >
          <Select.Option key="empty" value="">
            <Trans>Geen</Trans>
          </Select.Option>
          {timeWindows.map(({ startTime, endTime }) => (
            <Select.Option
              key={startTime.format(timeFormat)}
              value={startTime.format(timeFormat)}
            >
              <Trans>
                {startTime.format(timeFormat)} tot {endTime.format(timeFormat)}
              </Trans>
            </Select.Option>
          ))}
        </Select>
        <Button
          onClick={clearFilters}
          size="small"
          type="link"
          disabled={selectedKeys.length === 0}
          style={{ width: 90, marginRight: 8 }}
        >
          <Trans>Reset</Trans>
        </Button>
        <Button
          type="primary"
          onClick={handleConfirm}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          <Trans>OK</Trans>
        </Button>
      </div>
    );
  }
);

export default BlocksPickerTimeWindowFilter;
