import { Form } from "antd";
import { FormProps } from "antd/lib/form";
import React, { PropsWithChildren, memo } from "react";

import User from "../../../models/User";

const UsersButtonFormView = ({
  children,
  form,
  onFinish,
}: PropsWithChildren<FormProps<User>>) => (
  <Form className="tip-form-users-button" form={form} onFinish={onFinish}>
    {children}
  </Form>
);

export default memo(UsersButtonFormView);
