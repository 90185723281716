import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Popconfirm, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { ReactNode, useCallback, useMemo, useState } from "react";

import {
  CommercialInstructionRotationTagon,
  emptyRotation,
  emptyTagon,
} from "../../models/Instructions";
import { Operator } from "../../models/Operator";
import { Commercial } from "../../models/Spot";
import CommercialSelect, { createCommercialLabel } from "./CommercialSelect";
import { InstructionEditMode } from "./InstructionsModalContainer";

export interface TagonsTableContainerProps {
  operator: Operator;
  tagons: CommercialInstructionRotationTagon[];
  commercials: Commercial[];
  onChange: (
    newTagons: CommercialInstructionRotationTagon[],
    onSuccess?: () => void,
    onFail?: () => void
  ) => void;
  spotLengths: number[];
  onEdit: (mode: InstructionEditMode) => void;
  instructionEditMode: InstructionEditMode;
  canEdit: boolean;
}

export interface TagonForm {
  commercialCode: string;
}

const TagonsTableContainer = ({
  operator,
  tagons,
  commercials,
  onChange,
  spotLengths,
  onEdit,
  instructionEditMode,
  canEdit,
}: TagonsTableContainerProps): JSX.Element => {
  const { i18n } = useLingui();
  const [form] = Form.useForm<TagonForm>();

  // Rotation edit state
  const [editIndex, setEditIndex] = useState(-1);

  // Keep original rotation for cancel action
  const [original, setOriginal] =
    useState<CommercialInstructionRotationTagon>(emptyTagon);

  const handleEdit = useCallback(
    (edit: boolean, index = -1) => {
      form.resetFields();
      setEditIndex(index);

      if (edit) {
        // Fill form
        const rotation = tagons[index];
        form.setFieldsValue({
          commercialCode: rotation.commercial?.code,
        });
        setOriginal(rotation);
        onEdit("Tagon");
      } else {
        // Cancel
        setOriginal(emptyRotation);
        onEdit("None");
      }
    },
    [form, onEdit, tagons]
  );

  const handleDelete = useCallback(
    (index: number) => {
      const newTagons = tagons.filter((_, idx) => idx !== index);
      onChange(newTagons, () => {
        setOriginal(emptyTagon);
        setEditIndex(-1);
        onEdit("None");
      });
    },
    [onChange, onEdit, tagons]
  );

  const handleFinishForm = useCallback(
    (formObject: TagonForm) => {
      const { commercialCode } = formObject;
      const edited: CommercialInstructionRotationTagon = {
        ...original,
        commercial: commercials.find((c) => c.code === commercialCode),
      };

      const newTagons: CommercialInstructionRotationTagon[] = [
        ...tagons.slice(0, editIndex),
        edited,
        ...tagons.slice(editIndex + 1),
      ];

      onChange(newTagons, () => {
        setOriginal(emptyTagon);
        setEditIndex(-1);
        onEdit("None");
      });
    },
    [commercials, editIndex, onChange, onEdit, original, tagons]
  );

  const disabled = useMemo(
    () => !["None", "Tagon"].includes(instructionEditMode) || editIndex > -1,
    [editIndex, instructionEditMode]
  );

  const columns: ColumnsType<CommercialInstructionRotationTagon> = [
    {
      key: "sequenceNumber",
      title: "#",
      dataIndex: "sequenceNumber",
      width: 50,
    },
    {
      key: "commercial",
      title: i18n._(t`Commercial van de tagon`),
      ellipsis: true,
      render: (_, { commercial }, index) =>
        editIndex === index && commercials ? (
          <Form.Item
            name="commercialCode"
            rules={[{ required: true, message: i18n._(t`Verplicht`) }]}
            help={i18n._(t`Kies een tagon`)}
          >
            <CommercialSelect
              operator={operator}
              commercials={commercials.filter((c) =>
                spotLengths.includes(c.spotLength)
              )}
            />
          </Form.Item>
        ) : (
          <>{commercial ? createCommercialLabel(commercial, operator) : "-"}</>
        ),
    },
    {
      key: "spotLength",
      width: 100,
      title: i18n._(t`Lengte`),
      render: ({ commercial }) =>
        commercial ? <>{commercial?.spotLength}&quot;</> : <>-</>,
    },
    {
      key: "edit",
      title: i18n._(t`Acties`),
      width: 100,
      render: (_1, tagon, index): ReactNode =>
        editIndex === index ? (
          <Space size="small">
            <Tooltip title={i18n._(t`Opslaan`)}>
              <Button
                key="save"
                icon={<SaveOutlined />}
                type="link"
                htmlType="submit"
                size="small"
              />
            </Tooltip>
            <Tooltip title={i18n._(t`Annuleren`)}>
              <Button
                key="cancel"
                icon={<UndoOutlined />}
                type="link"
                size="small"
                onClick={() => {
                  handleEdit(false);
                }}
              />
            </Tooltip>
          </Space>
        ) : (
          <Space size="small">
            <Tooltip title={i18n._(t`Bewerken`)}>
              <Button
                key="edit"
                icon={<EditOutlined />}
                type="link"
                size="small"
                onClick={() => handleEdit(true, index)}
                disabled={disabled}
              />
            </Tooltip>
            <Popconfirm
              title={i18n._(
                t`Weet je zeker dat je deze tagon wilt verwijderen?`
              )}
              onConfirm={() => handleDelete(index)}
              cancelButtonProps={{ type: "link" }}
              disabled={disabled}
            >
              <Tooltip title={i18n._(t`Verwijderen`)}>
                <Button
                  key="delete"
                  icon={<DeleteOutlined />}
                  type="link"
                  size="small"
                  disabled={
                    !["None", "Tagon"].includes(instructionEditMode) ||
                    editIndex > -1
                  }
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        ),
    },
  ];

  return (
    <Form form={form} onFinish={handleFinishForm} autoComplete="off">
      <Table
        bordered
        size="small"
        pagination={false}
        columns={columns.filter(
          (c) => (!canEdit && c.key !== "edit") || canEdit
        )}
        dataSource={tagons}
        rowKey={(tagon) => JSON.stringify(tagon)}
      />
    </Form>
  );
};

export default TagonsTableContainer;
