import { PageHeader, Space } from "antd";
import React, { ReactNode, memo } from "react";

const HeaderTitle = memo(
  ({ title, showTitles }: { title: ReactNode; showTitles: boolean }) =>
    showTitles ? (
      <PageHeader title={title} className="tip-header-title" />
    ) : (
      <Space size="small" className="tip-header-title">
        <div className="tip-spacer" />
      </Space>
    )
);

export default HeaderTitle;
