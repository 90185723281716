import { AsyncResponse } from "async-lifecycle-saga";
import { Moment } from "moment";

import { CalendarEvent } from "../../models/Calendar";
import { getPeriodQueryString } from "../../utils/date";
import { deleter, getter, poster } from "../fetch";

export const calendarGetter = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: Moment;
  dateTo: Moment;
}): Promise<AsyncResponse<CalendarEvent[]>> =>
  getter<CalendarEvent[]>(
    `/api/calendar/events?${getPeriodQueryString(dateFrom, dateTo)}`
  );

export const createCalendarEvent = (
  input: CalendarEvent
): Promise<AsyncResponse<CalendarEvent>> =>
  poster<CalendarEvent, CalendarEvent>("/api/calendar", input);

export const updateCalendarEvent = (
  input: CalendarEvent
): Promise<AsyncResponse<CalendarEvent>> => {
  if (!input.id) {
    return Promise.reject(new Error("CalendarEvent.id is required for update"));
  }

  return poster<CalendarEvent, CalendarEvent>(
    `/api/calendar/${input.id}`,
    input
  );
};

export const deleteCalendarEvent = (calendarEventId: string) =>
  deleter<unknown, void>(`/api/calendar/${calendarEventId}`);
