import { combineReducers } from "redux";

import User from "../../models/User";
import { asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";
import { userProfileCell } from "./cells";
import {
  GetAllUsersResponse,
  UserDeleteRequest,
  UserDeleteResponse,
  UserResetAuthenticationRequest,
  UserUpdateRequest,
} from "./models";

export default combineReducers({
  current: asyncReducer<unknown, User>(sagaTypes.users.current),
  all: asyncReducer<unknown, GetAllUsersResponse>(sagaTypes.users.all),
  register: asyncReducer<UserUpdateRequest, unknown>(sagaTypes.users.register),
  reset: asyncReducer<UserResetAuthenticationRequest, unknown>(
    sagaTypes.users.reset
  ),
  delete: asyncReducer<UserDeleteRequest, UserDeleteResponse>(
    sagaTypes.users.delete
  ),
  profile: userProfileCell.reducer,
});
