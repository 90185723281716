import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Button, Form, Input, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import qrcode from "qrcode";
import queryString from "query-string";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import speakeasy, { GeneratedSecret } from "speakeasy";

import ActivationTwoFactorStepModel from "../../models/ActivationTwoFactorStepModel";
import { StoreModel } from "../../store/models";
import { propertyOf } from "../../utils/properties";
import useIsMounted from "../../utils/statefulness";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../views/layout/Form";
import ActivationStepProps from "./ActivationStepProps";

const secretField = propertyOf<ActivationTwoFactorStepModel>("twoFactorSecret");
const totpField = propertyOf<ActivationTwoFactorStepModel>("twoFactorTotp");

const ActivationTwoFactorStep = memo(
  ({ onNext }: ActivationStepProps<ActivationTwoFactorStepModel>) => {
    const { i18n } = useLingui();
    const [form] = useForm();
    const { setFieldsValue, validateFields } = form;
    const isMounted = useIsMounted();
    const { search } = useLocation();
    const { mail } = queryString.parse(search);
    const [secret, setSecret] = useState<GeneratedSecret>();
    const [qrcodeAuthUrl, setQrcodeAuthUrl] = useState<string | undefined>();
    const [totpIsValid, setTotpIsValid] = useState<boolean | null>(null);
    const [hasErrors, setHasErrors] = useState(false);

    const handleFinish = useCallback(
      (values: ActivationTwoFactorStepModel) => {
        if (!isMounted.current) {
          return;
        }
        if (!secret) {
          return;
        }
        const currentTotpIsValid = speakeasy.totp.verify({
          secret: secret.hex,
          encoding: "hex",
          token: values.twoFactorTotp,
        });
        setTotpIsValid(currentTotpIsValid);
        if (!currentTotpIsValid) {
          return;
        }
        onNext(values);
      },
      [isMounted, onNext, secret]
    );

    const appName = useSelector(
      ({
        application: {
          options: { applicationName },
        },
      }: StoreModel) => applicationName
    );

    const handleChange = useCallback(
      /**
       * Handles changes in the form.
       */
      () => {
        validateFields()
          .then(() => {
            setHasErrors(false);
          })
          .catch(() => {
            setHasErrors(true);
          });
      },
      [validateFields]
    );

    useEffect(() => {
      if (!isMounted.current) {
        return;
      }
      const newSecret = speakeasy.generateSecret({
        issuer: "Screenforce-TIP",
        name: `${appName} (${mail})`,
      });
      setSecret(newSecret);
      setFieldsValue({ [secretField]: newSecret.base32 });
      qrcode.toDataURL(newSecret.otpauth_url as string, (_err, dataUrl) => {
        setQrcodeAuthUrl(dataUrl);
      });
    }, [appName, isMounted, mail, setFieldsValue]);

    return (
      <>
        <Typography>
          Als extra beveiliging van je account gebruik je de Google
          Authenticator app. Installeer deze app op je telefoon en scan
          onderstaande streepjescode.
        </Typography>
        <Form
          form={form}
          labelCol={labelCol}
          onChange={handleChange}
          onFinish={handleFinish}
          wrapperCol={wrapperColShallow}
        >
          <Form.Item name={secretField} noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item label={i18n._(t`QR Code`)}>
            <img src={qrcodeAuthUrl || ""} alt={i18n._(t`QR Code`)} />
            <Typography.Paragraph type="secondary">
              <Trans>Scannen lukt niet? Kopieer deze code in de app.</Trans>
            </Typography.Paragraph>
            <Typography.Text code copyable>
              {secret?.base32}
            </Typography.Text>
            <Typography.Paragraph type="warning">
              <Trans>Deel deze niet met anderen.</Trans>
            </Typography.Paragraph>
          </Form.Item>
          <Form.Item
            label={i18n._(t`Verificatie`)}
            name={totpField}
            rules={[
              {
                required: true,
                message: i18n._(t`De verificatie code is verplicht.`),
              },
            ]}
          >
            <Input type="text" autoFocus />
          </Form.Item>
          {totpIsValid !== null && !totpIsValid && (
            <Alert
              type="warning"
              showIcon
              message={i18n._(t`Verificatiecode is incorrect.`)}
            />
          )}
          <Form.Item wrapperCol={wrapperColNoLabel}>
            <Button type="primary" htmlType="submit" disabled={hasErrors}>
              <Trans>Opslaan</Trans>
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
);

export default ActivationTwoFactorStep;
