import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Modal, Tooltip } from "antd";
import { ButtonProps } from "antd/lib/button/button";
import React, {
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
  useState,
} from "react";

export interface FullScreenModalProps {
  footer?: ReactNode;
  onCancel?: () => void;
  onOk?: () => void;
  okEnabled?: boolean;
  okText?: string;
  title: string;
  visible: boolean;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
}

const FullScreenModal = memo(
  ({
    children,
    footer,
    okEnabled = true,
    okText,
    onCancel,
    onOk,
    title,
    visible,
    okButtonProps,
    cancelButtonProps,
  }: PropsWithChildren<FullScreenModalProps>) => {
    const { i18n } = useLingui();
    const [showFullScreen, setShowFullScreen] = useState(false);

    /**
     * Handles a click on the 'show full screen' button.
     */
    const handleActivateFullScreen = useCallback(() => {
      setShowFullScreen(true);
    }, []);

    /**
     * Handles a click on the 'show in normal mode' button.
     */
    const handleDeactivateFullScreen = useCallback(() => {
      setShowFullScreen(false);
    }, []);

    /**
     * Handles the 'close' event.
     */
    const handleClose = useCallback(() => {
      handleDeactivateFullScreen();
    }, [handleDeactivateFullScreen]);

    return (
      <Modal
        afterClose={handleClose}
        className={
          showFullScreen ? "tip-fullScreen-enabled" : "tip-fullScreen-disabled"
        }
        destroyOnClose
        footer={footer}
        onCancel={onCancel}
        cancelButtonProps={{ ...cancelButtonProps, type: "default" }}
        onOk={onOk}
        okButtonProps={{ ...okButtonProps, disabled: !okEnabled }}
        okText={okText}
        style={{ top: 32 }}
        title={
          <>
            <span>
              {showFullScreen ? (
                <Tooltip
                  title={i18n._(t`In normale grootte bekijken`)}
                  placement="bottomRight"
                >
                  <Button
                    key="NormalSizeButton"
                    icon={<FullscreenExitOutlined />}
                    onClick={handleDeactivateFullScreen}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={i18n._(t`Schermvullend maken`)}
                  placement="bottomRight"
                >
                  <Button
                    key="FullScreenButton"
                    icon={<FullscreenOutlined />}
                    onClick={handleActivateFullScreen}
                  />
                </Tooltip>
              )}
            </span>
            <span>{title}</span>
          </>
        }
        open={visible}
        width={showFullScreen ? "100%" : "95%"}
        maskClosable={false}
        keyboard={false}
      >
        {children}
      </Modal>
    );
  }
);

export default FullScreenModal;
