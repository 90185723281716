import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Empty, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";

import { CommercialInstruction } from "../../models/Instructions";
import { getOperatorFromId } from "../../models/Operator";

export interface InstructionTableProps {
  loading?: boolean;
  instructions?: CommercialInstruction[];
  canShowDetails?: boolean;
  canEdit?: boolean;
  columns: ColumnsType<CommercialInstruction>;
}

const InstructionTable = ({
  loading,
  instructions,
  canShowDetails,
  canEdit,
  columns,
}: InstructionTableProps): JSX.Element => {
  const { i18n } = useLingui();

  return (
    <Spin spinning={loading}>
      <Table
        className="tip-instructions-table"
        bordered
        columns={columns
          .filter(
            (c) =>
              (!canShowDetails && c.key !== "show" && c.key !== "rotations") ||
              canShowDetails
          )
          .filter((c) => (!canEdit && c.key !== "edit") || canEdit)}
        dataSource={instructions}
        size="small"
        pagination={false}
        rowClassName={({ orderId, status }) =>
          `status-${status.toLocaleLowerCase()} ${getOperatorFromId(
            orderId ?? ""
          )}`
        }
        rowKey={({ id, instructionId }, index) =>
          `${id}-${instructionId}-${index}`
        }
        locale={{
          emptyText: (
            <Empty description={i18n._(t`Geen uitzendinstructies gevonden.`)} />
          ),
        }}
      />
    </Spin>
  );
};

export default InstructionTable;
