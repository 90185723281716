import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Card, Form, Input, Spin, Typography } from "antd";
import React, { ReactElement, memo } from "react";

import CenteredBlock from "../../views/CenteredBlock";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../views/layout/Form";
import MainContent from "../../views/layout/MainContent";
import {
  advertiserIdField,
  branchField,
  descriptionField,
  remarksField,
} from "./constants";
import { ProductRequestFormProps } from "./models";
import ProductAdvertiserSelect from "./ProductAdvertiserSelect";

const ProductRequestForm = memo(
  ({
    loading,
    advertisers,
    loadingAdvertisers,
    onFinish,
    form,
  }: ProductRequestFormProps) => {
    const { i18n } = useLingui();

    return (
      <MainContent>
        <CenteredBlock>
          <Typography.Paragraph>
            <Trans>
              Met behulp van onderstaand formulier is het mogelijk om een nieuw
              product aan te vragen om vervolgens een nieuwe aanvraag mee in te
              dienen.
            </Trans>
          </Typography.Paragraph>
        </CenteredBlock>
        <Spin spinning={loading}>
          <Card type="inner" title={i18n._(t`Product aanvragen`)}>
            <Form
              form={form}
              labelCol={labelCol}
              wrapperCol={wrapperColShallow}
              onFinish={onFinish}
            >
              <ProductAdvertiserSelect
                form={form}
                loadingAdvertisers={loadingAdvertisers}
                advertisers={advertisers}
              />
              <Form.Item
                name={descriptionField}
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Productomschrijving is verplicht.`),
                  },
                ]}
                label={i18n._(t`Productomschrijving`)}
                help={i18n._(t`Omschrijf het product of campagne in het kort`)}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={branchField}
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Branche omschrijving is verplicht.`),
                  },
                ]}
                label={i18n._(t`Branche omschrijving`)}
                help={i18n._(
                  t`Omschrijf de branche voor dit product of deze campagne`
                )}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item name={remarksField} label={i18n._(t`Opmerkingen`)}>
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item shouldUpdate wrapperCol={wrapperColNoLabel}>
                {(): ReactElement => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      loading ||
                      !form.isFieldsTouched(
                        [advertiserIdField, descriptionField, branchField],
                        true
                      ) ||
                      form
                        .getFieldsError()
                        .some(({ errors }) => errors.length > 0)
                    }
                  >
                    <Trans>Product aanvragen</Trans>
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </MainContent>
    );
  }
);

export default ProductRequestForm;
