import { SearchOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Input } from "antd";
import React, { ChangeEventHandler, useCallback } from "react";

interface ColumnSearchFilterProps {
  text: string;
  setSelectedKeys?: (value: string[]) => void;
  confirm?: () => void;
  clearFilters?: () => void;
}

const ColumnSearchFilter = ({
  text,
  setSelectedKeys,
  confirm,
  clearFilters,
}: ColumnSearchFilterProps): JSX.Element => {
  const { i18n } = useLingui();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      if (setSelectedKeys) {
        setSelectedKeys([value]);
      }
    },
    [setSelectedKeys]
  );

  const handleSearch = useCallback(() => {
    if (confirm) {
      confirm();
    }
  }, [confirm]);

  const handleReset = useCallback(() => {
    if (clearFilters) {
      clearFilters();
    }
  }, [clearFilters]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={i18n._(t`Zoeken`)}
        value={text ?? ""}
        onChange={handleChange}
        onPressEnter={handleSearch}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
        onClick={handleReset}
        size="small"
        type="link"
        disabled={!text}
        style={{ width: 90, marginRight: 8 }}
      >
        <Trans>Reset</Trans>
      </Button>
      <Button
        type="primary"
        onClick={handleSearch}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        <Trans>OK</Trans>
      </Button>
    </div>
  );
};

export default ColumnSearchFilter;
