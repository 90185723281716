import { message } from "antd";
import { Details } from "async-lifecycle-saga/dist/models";
import md5 from "md5";

import Advertiser from "../models/Advertiser";
import AuthorizationRule from "../models/AuthorizationRule";
import { operatorLabels } from "../models/Operator";
import SubOrder from "../models/SubOrder";
import User from "../models/User";
import {
  UserRoleName,
  dashboardRoleName,
  readerRoleName,
} from "../models/UserRole";
import { HttpStatusCode, ProblemDetails } from "../store/fetch";

export const getAdvertiserName = (
  advertisers: Advertiser[] | undefined,
  id: string
): string => {
  if (!advertisers) {
    return "";
  }

  const advertiser = advertisers.find((a) => a.id === id);
  if (!advertiser) {
    return id;
  }

  if (advertiser.name && advertiser.operator === "Tip") {
    return advertiser.name;
  }

  return advertiser.name
    ? `${advertiser.name} (${operatorLabels[advertiser.operator]})`
    : advertiser.id;
};

const impliedRoles: Record<string, UserRoleName[]> = {
  [readerRoleName]: [dashboardRoleName],
};

export const hasRole =
  (roleToCheck: UserRoleName) =>
  (user?: User): boolean =>
    user?.authorizations?.some(
      ({ role }: AuthorizationRule) =>
        impliedRoles[role]?.includes(roleToCheck) || role === roleToCheck
    ) ?? false;

const getGravatarHash = (email: string): string =>
  md5(email.toLowerCase().trim());

export const getGravatarUrl = (email: string): string =>
  `https://www.gravatar.com/avatar/${getGravatarHash(email)}?d=404`;

export const lastNotificationCheckKey = "tip-notification-check";

export const getSubOrderName = (
  { package: { description }, spotLengths }: SubOrder,
  defaultName: string | undefined = undefined
): string => `${description ?? defaultName} ${spotLengths.join("+")}"`;

export const distinct = <T>(array: T[], keyFn: (item: T) => string): T[] => [
  ...new Map(array.map((item) => [keyFn(item), item])).values(),
];

const problemAlert = (problem: ProblemDetails): void => {
  if (problem.extensions?.problems) {
    for (let i = 0; i < problem.extensions.problems.length; i += 1) {
      const p = problem.extensions.problems[i];
      message.error(p.title);
    }
  } else {
    message.error(problem.title);
  }
};

export const handleFailWithProblem =
  (fallbackMessage: string, callbackFn?: () => void) =>
  <T>(
    exception?: string,
    statusCode?: HttpStatusCode,
    response?: T,
    problem?: ProblemDetails
  ): void => {
    if (problem) {
      problemAlert(problem);
    } else {
      message.error(fallbackMessage);
    }

    if (callbackFn) {
      callbackFn();
    }
  };

export const handleAsyncFailWithProblem =
  (fallbackMessage: string, callbackFn?: () => void) =>
  (problem: Details): void => {
    if (problem) {
      problemAlert(problem as ProblemDetails);
    } else {
      message.error(fallbackMessage);
    }

    if (callbackFn) {
      callbackFn();
    }
  };

export const isNonEmptyArray = <T>(a: Array<T> | undefined | null) =>
  a && Array.isArray(a) && a.length > 0;
