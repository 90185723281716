import { combineReducers } from "redux";

import {
  channelsCell,
  commercialsCell,
  instructionsCell,
  instructionsSubmitCell,
  previousInstructionsCell,
} from "./cells";

// eslint-disable-next-line import/prefer-default-export
export const instructionsReducer = combineReducers({
  instructions: instructionsCell.reducer,
  previous: previousInstructionsCell.reducer,
  commercials: commercialsCell.reducer,
  channels: channelsCell.reducer,
  submit: instructionsSubmitCell.reducer,
});
