import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import { DashboardOrder } from "../../models/Dashboard";

const NotUsedOrdersAlert = ({
  notUsedOrders,
}: {
  notUsedOrders?: DashboardOrder[];
}) => {
  const { i18n } = useLingui();
  const length = notUsedOrders?.length ?? 0;

  const addTile = i18n._(
    t`Om nog een tegel aan te maken, klik je op het tandwiel en vervolgens op tegel toevoegen.`
  );
  const addOrder = i18n._(
    t`Je kunt ook orders toevoegen aan een bestaande tegel, hiervoor klik je op het tandwiel en ga je naar het pennetje bij de bestaande tegel. Er opent zich dan een pop up scherm waar je campagnes kunt aanvullen. De orders die al geselecteerd zijn, zijn rood gemarkeerd en hebben een vinkje.`
  );

  switch (true) {
    case length > 15:
      return (
        <>
          {i18n._(t`Er is nog een groot aantal orders niet ingesteld.`)}{" "}
          {addTile}
          <br />
          {addOrder}
        </>
      );
    case length > 0:
      return (
        <>
          {i18n._(
            t`Er zijn nog orders die niet zijn ingesteld voor een tegel.`
          )}{" "}
          {addTile}
          <br />
          {addOrder}
        </>
      );
    default:
      return (
        <>
          {addTile}
          <br />
          {addOrder}
        </>
      );
  }
};
export default NotUsedOrdersAlert;
