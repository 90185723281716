import { Divider } from "antd";
import React, { ReactNode, memo } from "react";

import BigScreen from "../layout/BigScreen";

interface CollapseListExtraActionsProps {
  children?: ReactNode;
}

const CollapseListExtraActions = memo(
  ({ children }: CollapseListExtraActionsProps) => (
    <div className="tip-collapseList-actions">
      <span>{children}</span>
      <BigScreen type="placeholder">
        <Divider type="vertical" />
      </BigScreen>
    </div>
  )
);

export default CollapseListExtraActions;
