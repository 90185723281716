import { toAsyncResponse } from "async-lifecycle-saga";

import {
  NotificationBody,
  NotificationResponse,
} from "../../models/notifications";
import { getter, jsonHeaders, poster } from "../fetch";

export const notificationGetter = (editMode: boolean) => {
  if (editMode) {
    return getter<NotificationResponse>("/api/notifications/edit");
  }

  return fetch("/api/notifications", {
    method: "GET",
    headers: jsonHeaders,
  }).then(toAsyncResponse);
};

export interface NotificationRequest {
  notification: NotificationBody;
}

export const notificationPoster = ({ notification }: NotificationRequest) =>
  poster("/api/notifications", notification);
