import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { message } from "antd";
import { useForm } from "antd/lib/form/Form";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Advertiser from "../../models/Advertiser";
import User from "../../models/User";
import { advertisersAllCell } from "../../store/advertisers/cells";
import { AsyncValue, StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { handleAsyncFailWithProblem, handleFailWithProblem } from "../../utils";
import {
  productCreateRequestCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import {
  advertiserIdField,
  advertiserNameField,
  branchField,
  descriptionField,
  remarksField,
} from "./constants";
import ProductRequestForm from "./ProductRequestForm";

const emptyAdvertisers: Advertiser[] = [];

const ProductRequestFormContainer = memo(() => {
  const { i18n } = useLingui();
  const [form] = useForm();
  const dispatch = useDispatch();
  const { getFieldValue, resetFields } = form;

  const {
    status: { loading: loadingUser },
    value: user,
  } = useSelector(
    ({ users: { current } }: StoreModel): AsyncValue<User> => current
  );

  // haal alle adverteerders op, indien nodig
  useEffect(() => {
    if (user && user.organisation) {
      dispatch(
        advertisersAllCell.require({
          onFail: handleAsyncFailWithProblem(
            i18n._(
              t`Er is iets misgegaan bij het ophalen van de adverteerders.`
            )
          ),
        })
      );
    }
  }, [dispatch, i18n, user]);

  const { loading: loadingAdvertisers, advertisers } = useSelector(
    ({ advertisers: { all } }: StoreModel) => ({
      advertisers: all.value?.advertisers ?? emptyAdvertisers,
      loading: all.status.loading,
    })
  );

  // indienen van een product aanvraag
  const handleFinish = useCallback(
    (values: Store) => {
      dispatch({
        type: sagaTypes.product.request,
        payload: {
          advertiser: {
            id: values[advertiserIdField],
            name: values[advertiserNameField],
          } as Advertiser,
          description: values[descriptionField],
          branch: values[branchField],
          remarks: values[remarksField],
        },
        onFail: handleFailWithProblem(
          i18n._(
            t`Er is iets misgegaan met het versturen van de product aanvraag.`
          )
        ),
        onSuccess: () => {
          triggerAnalyticsEvent(
            productCreateRequestCategory,
            getFieldValue(advertiserIdField)
          );
          resetFields();
          message.success(
            i18n._(t`Je aanvraag voor een nieuw product is verzonden.`)
          );
        },
      });
    },
    [dispatch, getFieldValue, i18n, resetFields]
  );

  const {
    status: { loading: loadingProductRequest },
  } = useSelector(({ product }: StoreModel) => product);

  const loading = loadingUser || loadingProductRequest;

  return (
    <ProductRequestForm
      loading={loading}
      advertisers={advertisers}
      loadingAdvertisers={loadingAdvertisers}
      form={form}
      onFinish={handleFinish}
    />
  );
});

export default ProductRequestFormContainer;
