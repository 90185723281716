import React, { ReactNode } from "react";

interface ChartTooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: { name: string };
    value: number;
  }>;
  renderValue?: (value: number) => ReactNode;
  hideLabel?: boolean;
}

const ChartTooltip = ({
  active,
  payload,
  hideLabel,
  renderValue,
}: ChartTooltipProps) =>
  active && payload && payload[0].payload ? (
    <div
      style={{
        padding: 8,
        backgroundColor: "#fff",
        borderRadius: 4,
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {!hideLabel && (
        <>
          {payload[0].payload.name}
          {": "}
        </>
      )}
      {renderValue?.(payload[0].value) ?? payload[0].value}
    </div>
  ) : null;

export default ChartTooltip;
