import {
  CheckOutlined,
  LoadingOutlined,
  MinusOutlined,
  QuestionOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Tooltip } from "antd";
import React from "react";

import { CommercialDeliveryStatus } from "../../models/CommercialDeliveryStatus";
import { Operator, operatorLabels } from "../../models/Operator";

export interface DeliveryStatusBadgePerOperatorProps {
  deliveryStatus: CommercialDeliveryStatus;
  operator: Operator;
}

const DeliveryStatusBadgePerOperator = ({
  deliveryStatus: { advertisers, status: currentStatus, uploadDone },
  operator,
}: DeliveryStatusBadgePerOperatorProps) => {
  const { i18n } = useLingui();

  if (!advertisers.map((a) => a.operator).includes(operator)) {
    return (
      <Tooltip title={i18n._(t`Niet van toepassing voor deze levering`)}>
        <MinusOutlined style={{ color: "blue" }} />
      </Tooltip>
    );
  }

  const done = uploadDone.find((u) => u.operator === operator);
  const currentOperator = operatorLabels[operator];
  if (done) {
    if (done.status === "Success") {
      return (
        <Tooltip title={i18n._(t`Bestand is geleverd aan ${currentOperator}`)}>
          <CheckOutlined style={{ color: "green" }} />
        </Tooltip>
      );
    }

    if (done.status === "Fail") {
      return (
        <Tooltip
          title={i18n._(t`Bestand is niet geleverd aan ${currentOperator}`)}
        >
          <WarningOutlined style={{ color: "red" }} />
        </Tooltip>
      );
    }
  }

  switch (currentStatus) {
    case "Failed":
      return (
        <Tooltip
          title={i18n._(
            t`Bestand is niet geleverd aan ${currentOperator}, er is iets misgegaan`
          )}
        >
          <QuestionOutlined style={{ color: "red" }} />
        </Tooltip>
      );
    case "Success": {
      return (
        <Tooltip title={i18n._(t`Het proces is succesvol afgerond`)}>
          <CheckOutlined style={{ color: "green" }} />
        </Tooltip>
      );
    }
    case "Busy":
    default:
      return (
        <Tooltip title={i18n._(t`Het proces is nog bezig...`)}>
          <LoadingOutlined style={{ color: "blue" }} />
        </Tooltip>
      );
  }
};

export default DeliveryStatusBadgePerOperator;
