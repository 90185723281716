/* eslint-disable react/jsx-props-no-spreading */

import { Select as AntSelect } from "antd";
import {
  SelectProps as AntSelectProps,
  BaseOptionType,
  DefaultOptionType,
} from "antd/lib/select";
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from "rc-select";
import React, { ReactNode, Ref } from "react";

export type SelectProps<
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType
> = AntSelectProps<ValueType, OptionType> & {
  children?: ReactNode;
} & {
  ref?: Ref<BaseSelectRef> | undefined;
};

const Select = <
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
>(
  props: SelectProps<ValueType, OptionType>
) => <AntSelect<ValueType, OptionType> {...props} />;

const FullWidth = <
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
>(
  props: SelectProps<ValueType, OptionType>
) => <Select style={{ width: "100%" }} {...props} />;

Select.FullWidth = FullWidth;

export default Select;
