import { combineReducers } from "redux";

import { advertisersAllCell, advertisersByOrganisationCell } from "./cells";

const byOrganisationReducer = advertisersByOrganisationCell.reducer;
const allReducer = advertisersAllCell.reducer;

export default combineReducers({
  byOrganisation: byOrganisationReducer,
  all: allReducer,
});
