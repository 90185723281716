import { Operator } from "./Operator";
import Order from "./Order";

export default interface Advertiser extends Record<string, unknown> {
  id: string;
  name: string;
  operator: Operator;
  orders: Order[];
  requestedBudget?: number;
  bookedBudget?: number;
}
export const emptyAdvertisers: Advertiser[] = [];
