import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input } from "antd";
import React, { memo } from "react";

import { fieldNameName } from "../constants";
import { CampaignNameFieldProps } from "../models";

const CampaignNameField = memo(
  ({ enabled, onBlur }: CampaignNameFieldProps) => {
    const { i18n } = useLingui();

    return (
      <Form.Item
        label={i18n._(t`Campagnenaam`)}
        name={fieldNameName}
        rules={[
          {
            required: true,
            message: i18n._(t`Campagnenaam is verplicht.`),
          },
          {
            max: 30,
            message: i18n._(t`Campagnenaam mag maximaal 30 tekens bevatten.`),
          },
        ]}
      >
        <Input disabled={!enabled} onBlur={onBlur} />
      </Form.Item>
    );
  }
);

export default CampaignNameField;
