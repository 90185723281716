import { createForm, createSingle } from "async-lifecycle-saga";

import { copyRequestsPoster, openRequestsGetter } from "./api";

export const openRequestsCell = createSingle({
  path: ["openRequests"],
  require: { api: openRequestsGetter },
});

export const copyRequestCell = createForm({
  path: ["copyRequest"],
  api: copyRequestsPoster,
  onRefresh: () => "clear",
});
