import { AsyncResponse } from "async-lifecycle-saga";
import moment from "moment";

import Advertiser from "../../../models/Advertiser";
import {
  CampaignValues,
  OrderRequest,
} from "../../../models/Campaigns/Requests/models";
import { PackagesRequest, PackagesResponse } from "../../../models/packages";
import { Period } from "../../../models/periods";
import { ProductsRequest, ProductsResponse } from "../../../models/products";
import SpotLengthIndex from "../../../models/SpotLengthIndex";
import { isIE } from "../../../utils/browsers";
import { formatDateForUri, getPeriodQueryString } from "../../../utils/date";
import { AdvertisersRequest } from "../../advertisers/models";
import {
  getter,
  jsonDeleter,
  jsonGetter,
  jsonPoster,
  jsonPutter,
  poster,
} from "../../fetch";
import { ApiPromise, TokenApiPromise } from "../../models";
import {
  AvailableBreaksRequest,
  CopyOrderRequestResponse,
  OpenOrderRequests,
  OrderRequestCreateRequest,
  OrderRequestCreateResponse,
  OrderRequestDeleteResponse,
  OrderRequestGetResponse,
  OrderRequestSubmissionRequest,
  OrderRequestUpdateRequest,
  OrderRequestsByAdvertiserGetRequest,
  OrderRequestsByAdvertiserGetResponse,
  TargetPeriod,
} from "./models";

export const orderRequestCreatePoster =
  ({
    orderRequest,
  }: OrderRequestCreateRequest): TokenApiPromise<OrderRequestCreateResponse> =>
  (token?: string): ApiPromise<OrderRequestCreateResponse> =>
    jsonPoster<OrderRequestCreateResponse, OrderRequest>("/api/request")(
      orderRequest,
      token
    );

export const orderRequestGetter = (
  id: string
): TokenApiPromise<OrderRequestGetResponse> => {
  const cacheFixForIe = isIE ? `?t=${moment().unix()}` : "";
  return jsonGetter(`/api/request/${id}${cacheFixForIe}`);
};

export const orderRequestDeleter = (
  id: string
): TokenApiPromise<OrderRequestDeleteResponse> =>
  jsonDeleter(`/api/request/${id}`);

export const orderRequestsByAdvertiserGetter = ({
  advertiserId,
  period,
  status,
}: OrderRequestsByAdvertiserGetRequest): TokenApiPromise<OrderRequestsByAdvertiserGetResponse> =>
  jsonGetter(
    `/api/request/byAdvertiser?fromDate=${formatDateForUri(
      period[0]
    )}&toDate=${formatDateForUri(period[1])}${
      advertiserId ? `&advertiserId=${advertiserId}` : ""
    }${status ? `&status=${status}` : ""}`
  );

export const orderRequestSubmitPoster =
  ({
    id,
    sendConfirmation,
  }: OrderRequestSubmissionRequest): TokenApiPromise<unknown> =>
  (token?: string): ApiPromise<unknown> =>
    jsonPoster<unknown, unknown>(
      `/api/request/${id}/submit/${
        sendConfirmation ? "?sendConfirmation=true" : ""
      }`
    )({}, token);

export const orderRequestUpdatePutter =
  ({ orderRequest }: OrderRequestUpdateRequest): TokenApiPromise<unknown> =>
  (token?: string): ApiPromise<unknown> =>
    jsonPutter<CampaignValues, unknown>("/api/request")(orderRequest, token);

export const packagesGetter = ({
  dateFrom,
  dateTo,
  advertiserId,
  orderRequestId,
}: PackagesRequest): TokenApiPromise<PackagesResponse> =>
  jsonGetter<PackagesRequest, PackagesResponse>(
    `/api/request/packages?${getPeriodQueryString(
      dateFrom,
      dateTo
    )}&advertiserId=${advertiserId}&orderRequestId=${orderRequestId ?? ""}`
  );

export const openRequestsGetter = (): Promise<
  AsyncResponse<OpenOrderRequests>
> => getter<OpenOrderRequests>("/api/request/open");

export const periodsGetter = (): TokenApiPromise<Period[]> =>
  jsonGetter<unknown, Period[]>(`/api/request/periods`);

export const productsGetter = ({
  dateFrom,
  dateTo,
  advertiserId,
  orderRequestId,
}: ProductsRequest): TokenApiPromise<ProductsResponse> =>
  jsonGetter<ProductsRequest, ProductsResponse>(
    `/api/request/products?${getPeriodQueryString(
      dateFrom,
      dateTo
    )}&advertiserId=${advertiserId}&orderRequestId=${orderRequestId ?? ""}`
  );

export const spotLengthIndicesGetter = (): TokenApiPromise<SpotLengthIndex[]> =>
  jsonGetter<unknown, SpotLengthIndex[]>(`/api/request/spotlength/indices`);

export const availableBreaksGetter = ({
  dateFrom,
  dateTo,
  spotLengths,
  packageCode,
  targetGroupId,
  intomartId,
  productId,
  orderId,
}: AvailableBreaksRequest): TokenApiPromise<ProductsResponse> => {
  const spotLengtsForUri = spotLengths
    .map((length) => `spotLengths=${length}`)
    .join("&");
  const packageCodeString = encodeURIComponent(packageCode);

  let uri = `/api/breaks?${getPeriodQueryString(
    dateFrom,
    dateTo
  )}&${spotLengtsForUri}&packageCode=${packageCodeString}&targetGroupId=${targetGroupId}&intomartId=${intomartId}`;
  if (productId) {
    uri = `${uri}&productId=${productId}`;
  }
  if (orderId) {
    uri = `${uri}&orderId=${orderId}`;
  }

  return jsonGetter<ProductsRequest, ProductsResponse>(uri);
};

export const advertisersWithRequestsByDateGetter = ({
  dateFrom,
  dateTo,
}: AdvertisersRequest): TokenApiPromise<Advertiser[]> =>
  jsonGetter<AdvertisersRequest, Advertiser[]>(
    `/api/request/advertisers?${getPeriodQueryString(dateFrom, dateTo)}`
  );

export const copyRequestsPoster = ({
  id,
  period,
}: {
  id: string;
  period: TargetPeriod;
}): Promise<AsyncResponse<CopyOrderRequestResponse>> =>
  poster<unknown, TargetPeriod, unknown>(`/api/request/copy/${id}`, period);
