export const labelCol = {
  xs: { span: 24 },
  sm: { span: 8 },
};

export const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
};

export const wrapperColFullWidth = {
  xs: { span: 24 },
  sm: { span: 24 },
};

export const wrapperColShallower = {
  xs: { span: 24 },
  sm: { span: 8 },
};

export const wrapperColShallow = {
  xs: { span: 24 },
  sm: { span: 11 },
};

export const wrapperColNoLabel = {
  xs: { span: 24 },
  sm: { span: 11, offset: 8 },
};
