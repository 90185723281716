import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Empty } from "antd";
import React from "react";

const CommercialEmpty = () => {
  const { i18n } = useLingui();
  return (
    <div
      style={{
        backgroundColor: "#f0f2f5",
        padding: 0,
        margin: 0,
        width: "100%",
        maxWidth: 480,
        height: 270,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Empty description={i18n._(t`Geen commercial gekoppeld`)} />
    </div>
  );
};

export default CommercialEmpty;
