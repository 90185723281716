import { Trans } from "@lingui/macro";
import React, { memo } from "react";

import Euro from "../../../Euro";

const SubOrderTotalBudget = memo(
  ({ subOrderBudgets }: { subOrderBudgets: number[] }) => (
    <div className="tip-suborder-total-budget">
      <Trans>Totaalbudget</Trans>:{" "}
      <Euro amount={subOrderBudgets.reduce((a, b) => a + b, 0)} inline />
    </div>
  )
);

export default SubOrderTotalBudget;
