import { Col, Row } from "antd";
import React, { PropsWithChildren, memo } from "react";

const CenteredBlock = memo(({ children }: PropsWithChildren<unknown>) =>
  children ? (
    <Row gutter={[0, 16]}>
      <Col span={14} offset={5}>
        {children}
      </Col>
    </Row>
  ) : null
);

export default CenteredBlock;
