import {
  DeleteTwoTone,
  ExclamationCircleTwoTone,
  InfoCircleTwoTone,
  PlayCircleTwoTone,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Space, Table, Tooltip, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { saveAs } from "file-saver";
import moment from "moment";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import AuthorizationRule from "../../../models/AuthorizationRule";
import Organisation from "../../../models/Organisation";
import User, {
  StatusMap,
  UserStatus,
  statusTitleMap,
} from "../../../models/User";
import {
  HttpStatusCode,
  httpConflict,
  httpInternalServerError,
} from "../../../store/fetch";
import {
  AsyncValue,
  RequestAction,
  ResolutionSuggestionResponse,
  StoreModel,
} from "../../../store/models";
import sagaTypes from "../../../store/sagaTypes";
import {
  GetAllUsersResponse,
  UserDeleteRequest,
  UserDeleteResponse,
} from "../../../store/users/models";
import { handleFailWithProblem } from "../../../utils";
import propertyOf from "../../../utils/properties";
import useIsMounted from "../../../utils/statefulness";
import {
  colorDeleted,
  colorError,
  colorInformation,
  colorOk,
  colorWarning,
} from "../../../views/colors";
import UserDeleteConfirmMessageView from "../../../views/management/users/UserDeleteConfirmMessageView";
import UserLockedView from "../../../views/management/users/UserLockedView";
import UsersContent from "../../../views/management/users/UsersContent";
import DeleteButton from "../../actions/DeleteButton";
import EditButton from "../../actions/EditButton";
import ResetButton from "../../actions/ResetButton";
import { emptyOrganisation } from "../organisations/Organisations";
import UserFormModal from "./UserFormModal";

const nameField = propertyOf<User>("name");
const emailField = propertyOf<User>("emailAddress");
const phoneNumberField = propertyOf<User>("phoneNumber");
const organisationField = propertyOf<User>("organisation");
const statusField = propertyOf<User>("status");

/**
 * Maps user statuses to a two tone color for the Ant Design icon.
 */
const statusColorMap: StatusMap<string> = {
  Empty: colorWarning,
  Active: colorOk,
  Deleted: colorDeleted,
  Created: colorInformation,
  Failed: colorError,
};

/**
 * Finds all unique organisation names of the organisations
 * that are associated with the provided users
 * and creates column filters for the Ant Design table,
 * so the table can filter organisations.
 * @param users The provided users that should have organisations associated with them.
 */
const createOrganisationFilters = (users: User[]): ColumnFilterItem[] => [
  ...Array.from(
    new Set( // unique values
      users
        .filter((user: User): boolean => Boolean(user.organisation)) // should have an organisation
        .map((user: User): string => (user.organisation as Organisation).name)
    ) // all unique organisation names
  )
    .map(
      (organisationName: string): ColumnFilterItem => ({
        text: organisationName,
        value: organisationName,
      })
    )
    .sort((a, b) => `${a.value}`.localeCompare(`${b.value}`)),
];

/**
 * Creates table filters for the user status.
 * @param users The data source of users.
 */
const createStatusFilters = (users: User[]): ColumnFilterItem[] => [
  ...Array.from(
    new Set(
      users.map(
        (user: User): UserStatus => (user.status ? user.status : "Empty")
      )
    )
  )
    .map(
      (status: string): ColumnFilterItem => ({
        text: statusTitleMap[status as UserStatus],
        value: status,
      })
    )
    .sort((a, b) =>
      (a?.text as string)
        ? (a.text as string).localeCompare((b.text as string) || "")
        : -1
    ),
];

/**
 * Make sure we don't send any useless data to the API.
 * @param authorizations The dirty authorization rules.
 */
const mapAuthorizations = (
  authorizations: AuthorizationRule[]
): AuthorizationRule[] =>
  authorizations.map((auth) => ({
    ...auth,
    key: undefined,
    valid: undefined,
    validFrom:
      auth.valid &&
      auth.valid[0] &&
      auth.valid[0].toDate &&
      auth.valid[0].toDate(),
    validTo:
      auth.valid &&
      auth.valid[1] &&
      auth.valid[1].toDate &&
      auth.valid[1].toDate(),
  }));

const emptyUsers: User[] = [];

/**
 * A component for displaying and managing the users of the TIP application.
 */
const Users = () => {
  const { i18n } = useLingui();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<User | null>(null);

  /**
   * Get the users from the store.
   */
  const {
    status: { loading: usersLoading },
    value: { users } = { users: emptyUsers },
  } = useSelector(
    ({ users: { all } }: StoreModel): AsyncValue<GetAllUsersResponse> => all
  );
  const [myUsers, setMyUsers] = useState<User[]>(users);

  /**
   * Tracks whether a user is being reset.
   */
  const {
    status: { loading: resetLoading },
  } = useSelector(({ users: { reset: userReset } }: StoreModel) => userReset);

  /**
   * Tracks whether a user is being deleted.
   */
  const {
    status: { loading: deleteLoading },
  } = useSelector(
    ({ users: { delete: userDelete } }: StoreModel) => userDelete
  );

  /**
   * Loads the latest state of the user directory from the API.
   */
  const loadUsers = useCallback((): void => {
    dispatch({
      type: sagaTypes.users.all.request,
    });
  }, [dispatch]);

  /**
   * Handles the 'click' event on the reset button next to an existing user.
   */
  const handleResetClick = useCallback(
    (itemId?: string) => {
      if (itemId) {
        dispatch({
          type: sagaTypes.users.reset.request,
          payload: {
            userId: itemId,
          },
          onFail: () => {
            message.error(i18n._(t`Er is iets misgegaan.`));
          },
          onSuccess: () => {
            message.success(
              i18n._(t`Gebruiker hersteld. Een e-mail is verzonden.`)
            );
            loadUsers();
          },
        });
      }
    },
    [dispatch, i18n, loadUsers]
  );

  /**
   * Handles the 'click' event on the edit button next to an existing user.
   */
  const handleEditClick = useCallback(
    (itemId?: string) => {
      if (isMounted.current) {
        setEditUser(users.find((u) => u.id === itemId) || null);
      }
    },
    [isMounted, users]
  );

  /**
   * Handles the 'click' event on the delete button next to an existing user.
   */
  const handleDeleteClick = useCallback(
    (itemId?: string) => {
      const user = myUsers.find((u) => u.id === itemId);
      if (user) {
        dispatch<RequestAction<UserDeleteRequest, UserDeleteResponse>>({
          type: sagaTypes.users.delete.request,
          payload: { userId: user.id as string },
          onFail: (_exception, statusCode, response) => {
            switch (statusCode) {
              case httpConflict:
                if (response) {
                  message.warning(
                    (response as ResolutionSuggestionResponse).message
                  );
                }
                break;
              case httpInternalServerError:
              default:
                message.error(i18n._(t`Een onverwachte fout is opgetreden.`));
            }
          },
          onSuccess: () => {
            message.success(i18n._(t`Gebruiker verwijderd.`));
            dispatch({
              type: sagaTypes.users.all.request,
            });
          },
        });
      }
    },
    [dispatch, i18n, myUsers]
  );

  /**
   * Handles a click on the "Add User" button.
   */
  const handleAddClick = useCallback(() => {
    if (isMounted.current) {
      setShowAddUser(true);
      setEditUser(null);
    }
  }, [isMounted]);

  /**
   * Handles closing the User modal.
   */
  const handleClose = useCallback(() => {
    if (isMounted.current) {
      setShowAddUser(false);
      setEditUser(null);
    }
  }, [isMounted]);

  /**
   * Handles the submit event of the form.
   */
  const handleSubmit = useCallback(
    ({
      id,
      name,
      emailAddress,
      organisation,
      organisation: { id: organisationId } = emptyOrganisation,
      authorizations,
      externalApiClientAuthorizations,
    }: User) => {
      if (editUser) {
        // If we are editing an existing user, make sure we submit the data to the API endpoint for updating a user.
        dispatch({
          type: sagaTypes.users.update.request,
          payload: {
            user: {
              id,
              name,
              emailAddress,
              organisation,
              authorizations: authorizations
                ? mapAuthorizations(authorizations)
                : [],
              externalApiClientAuthorizations,
            },
          },
          onFail: (msg: string, statusCode: HttpStatusCode) => {
            if (statusCode === httpConflict) {
              message.warn(i18n._(t`De gebruiker bestaat al`));
            } else {
              message.error(i18n._(t`Er is iets fout gegaan bij het opslaan`));
            }
          },
          onSuccess: () => {
            if (isMounted.current) {
              loadUsers();
              handleClose();
            }

            message.success(i18n._(t`Gebruiker is opgeslagen`));
          },
        });
      } else {
        // If we are registering a new user, make sure we submit the data to the API endpoint for registering a user.
        dispatch({
          type: sagaTypes.users.register.request,
          payload: {
            name,
            emailAddress,
            organisationId,
            authorizations: authorizations
              ? mapAuthorizations(authorizations)
              : [],
          },
          onFail: (msg: string, statusCode: HttpStatusCode) => {
            if (statusCode === httpConflict) {
              message.warn(i18n._(t`De gebruiker bestaat al`));
            } else {
              message.error(i18n._(t`Er is iets fout gegaan bij het opslaan`));
            }
          },
          onSuccess: () => {
            if (isMounted.current) {
              loadUsers();
              handleClose();
            }

            message.success(i18n._(t`Gebruiker is opgeslagen`));
          },
        });
      }
    },
    [dispatch, editUser, handleClose, i18n, isMounted, loadUsers]
  );

  /**
   * Creates the columns for the Ant Design table.
   * @param users The users data source.
   */
  const createColumns = useCallback(
    (): ColumnProps<User>[] => [
      {
        title: i18n._(t`Naam`),
        dataIndex: nameField,
        key: nameField,
        showSorterTooltip: false,
        sorter: (user1, user2): number => user1.name.localeCompare(user2.name),
        ellipsis: true,
        width: 225,
      },
      {
        title: i18n._(t`E-mailadres`),
        dataIndex: emailField,
        key: emailField,
        showSorterTooltip: false,
        sorter: (user1, user2): number =>
          user1.emailAddress.localeCompare(user2.name),
        ellipsis: true,
        width: 200,
      },
      {
        title: i18n._(t`Telefoonnummer`),
        dataIndex: phoneNumberField,
        key: phoneNumberField,
        render: (_, { phoneNumber }): React.ReactNode =>
          (
            <Tooltip
              title={i18n._(
                t`Telefoonnummer is door de gebruiker zelf te beheren vanuit het "Profiel" scherm`
              )}
            >
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </Tooltip>
          ) ?? "-",
      },
      {
        title: i18n._(t`Organisatie`),
        dataIndex: organisationField,
        key: organisationField,
        filters: createOrganisationFilters(users),
        onFilter: (value, record: User): boolean =>
          Boolean(
            record && record.organisation && record.organisation.name === value
          ),
        showSorterTooltip: false,
        sorter: (user1, user2): number =>
          user1.organisation
            ? user1.organisation.name.localeCompare(
                user2.organisation ? user2.organisation.name : ""
              )
            : 0,
        render: (value: Organisation): React.ReactNode => value && value.name,
        ellipsis: true,
        width: 225,
      },
      {
        title: i18n._(t`Status`),
        dataIndex: statusField,
        key: statusField,
        filters: createStatusFilters(users),
        onFilter: (value, record: User): boolean => record.status === value,
        showSorterTooltip: false,
        sorter: (user1, user2): number =>
          user1.status
            ? user1.status.localeCompare(user2.status ? user2.status : "Empty")
            : 0,
        width: 100,
        align: "center",
        render: (value: UserStatus): React.ReactNode => {
          const title = statusTitleMap[value];
          const twoToneColor = statusColorMap[value];
          switch (value) {
            case "Created":
              return (
                <InfoCircleTwoTone title={title} twoToneColor={twoToneColor} />
              );
            case "Active":
              return (
                <PlayCircleTwoTone title={title} twoToneColor={twoToneColor} />
              );
            case "Deleted":
              return (
                <DeleteTwoTone title={title} twoToneColor={twoToneColor} />
              );
            case "Failed":
              return (
                <ExclamationCircleTwoTone
                  title={title}
                  twoToneColor={twoToneColor}
                />
              );
            case "Empty":
            default:
              return (
                <QuestionCircleTwoTone
                  title={title}
                  twoToneColor={twoToneColor}
                />
              );
          }
        },
      },
      {
        title: i18n._(t`API`),
        key: "API",
        width: 100,
        align: "center",
        render: ({ externalApiClientAuthorizations }: User) => (
          <>
            {externalApiClientAuthorizations &&
            externalApiClientAuthorizations.length > 0 ? (
              <UserLockedView tooltip={i18n._(t`API koppeling`)} />
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        title: i18n._(t`Acties`),
        key: "Acties",
        width: 375,
        render: ({ id, externalApiClientAuthorizations }: User): ReactNode => (
          <>
            <ResetButton
              itemId={id || ""}
              loading={resetLoading}
              onClick={handleResetClick}
            />
            <EditButton itemId={id || ""} onClick={handleEditClick} />
            <DeleteButton
              confirmMessage={
                externalApiClientAuthorizations &&
                externalApiClientAuthorizations.length > 0 ? (
                  <UserDeleteConfirmMessageView />
                ) : undefined
              }
              itemId={id || ""}
              onClick={handleDeleteClick}
            />
          </>
        ),
      },
    ],
    [
      handleDeleteClick,
      handleEditClick,
      handleResetClick,
      i18n,
      resetLoading,
      users,
    ]
  );

  /**
   * On first load, fetch the users from the API and put them in the store.
   */
  useEffect(() => {
    if (isMounted.current) {
      loadUsers();
    }
  }, [dispatch, isMounted, loadUsers]);

  /**
   * Whenever the collection of Users in the store changes,
   * update the backing variable with the latest value.
   */
  useEffect(() => {
    if (isMounted.current) {
      setMyUsers(users);
    }
  }, [isMounted, users]);

  const isExportLoading = useSelector(
    ({
      exports: {
        usersExcel: { status },
      },
    }: StoreModel) => status.loading
  );

  /**
   * Exports all users to an Excel file.
   */
  const handleExport = useCallback(() => {
    dispatch({
      type: sagaTypes.exports.users.request,
      onFail: handleFailWithProblem(i18n._(t`Er is iets misgegaan.`)),
      onSuccess: (_statusCode?: HttpStatusCode, response?: Blob) => {
        if (!response) {
          return;
        }

        const filename = `Users_${moment().format("YYYYMMDDHHmmss")}.xlsx`;
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(response, filename);
        } else {
          saveAs(response, filename);
        }
      },
    });
  }, [dispatch, i18n]);

  return (
    <UsersContent>
      <Space>
        <Button type="primary" onClick={handleAddClick}>
          <Trans>Gebruiker toevoegen</Trans>
        </Button>
        <Button
          type="default"
          onClick={handleExport}
          disabled={usersLoading || deleteLoading || myUsers.length === 0}
          loading={isExportLoading}
          size="middle"
        >
          <Trans>Gebruikers exporteren</Trans>
        </Button>
      </Space>
      <Table
        dataSource={myUsers}
        columns={createColumns()}
        loading={usersLoading || deleteLoading}
        rowKey={(user): string => user.id || ""}
        pagination={{
          defaultPageSize: 100,
          pageSizeOptions: ["100", "250", "500"],
        }}
      />
      {(showAddUser || editUser) && (
        <UserFormModal
          onClose={handleClose}
          onSubmit={handleSubmit}
          title={
            editUser
              ? i18n._(t`Gebruiker bewerken`)
              : i18n._(t`Gebruiker toevoegen`)
          }
          value={{
            id: editUser ? editUser.id : undefined,
            authorizations: editUser ? editUser.authorizations : [],
            emailAddress: editUser ? editUser.emailAddress : "",
            externalApiClientAuthorizations: editUser
              ? editUser.externalApiClientAuthorizations
              : [],
            name: editUser ? editUser.name : "",
            organisation: editUser ? editUser.organisation : undefined,
          }}
          visible
        />
      )}
    </UsersContent>
  );
};

export default memo(Users);
