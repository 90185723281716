import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Space, Table, Tooltip, Typography, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { saveAs } from "file-saver";
import moment from "moment";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MonthNumber,
  OrderRequestMonthStatistics,
  OrderRequestStats,
  StatisticsByOrganisationOrderRequestModel,
} from "../../../models/StatisticsModels";
import { HttpStatusCode } from "../../../store/fetch";
import { StoreModel } from "../../../store/models";
import sagaTypes from "../../../store/sagaTypes";
import BigScreen from "../../../views/layout/BigScreen";
import Flex from "../../../views/layout/Flex";
import OperatorSelect, {
  OperatorChangeFunc,
} from "../../../views/OperatorSelect";
import YearSelect, { YearChangeFunc } from "../../../views/YearSelect";
import ExportButton from "../../actions/ExportButton";
import { organisationTypeColumn } from "./utils";

export const getTotals = (
  statistics: OrderRequestMonthStatistics | undefined
): number | undefined =>
  statistics
    ? Object.values(statistics).reduce(
        (previousValue, currentValue) =>
          (previousValue ?? 0) +
          (currentValue?.request ?? 0) +
          (currentValue?.created ?? 0) +
          (currentValue?.submitted ?? 0),
        0
      )
    : 0;

export const getTotalsBy = (
  statistics: OrderRequestMonthStatistics | undefined,
  prop: keyof OrderRequestStats
): number | undefined =>
  statistics
    ? Object.values(statistics).reduce(
        (previousValue, currentValue) =>
          (previousValue ?? 0) + (currentValue?.[prop] ?? 0),
        0
      )
    : 0;

const NumberOfOrderRequests = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const {
    status: { loading: organisationRequestsForYearLoading },
    value: organisationRequestsForYearValue,
  } = useSelector(
    ({ statistics: { organisationRequestsForYear } }: StoreModel) =>
      organisationRequestsForYear
  );

  const {
    status: { loading: exportOrganisationRequestsForYearLoading },
  } = useSelector(
    ({ exports: { organisationRequestsForYear } }: StoreModel) =>
      organisationRequestsForYear
  );

  const [year, setYear] = useState(moment().year());
  const handleYearChange = useCallback<YearChangeFunc>((value) => {
    setYear(value);
  }, []);

  const [operator, setOperator] = useState("all");
  const handleOperatorChange = useCallback<OperatorChangeFunc>((value) => {
    setOperator(value);
  }, []);

  const handleExportClick = useCallback(() => {
    dispatch({
      type: sagaTypes.exports.organisationRequestsForYear.request,
      payload: { year, operator: operator === "all" ? undefined : operator },
      onFail: () => {
        message.error(i18n._(t`Er is iets misgegaan.`));
      },
      onSuccess: (_statusCode?: HttpStatusCode, response?: Blob) => {
        if (!response) {
          return;
        }

        const fileNamePart = operator ?? i18n._(t`Totaal`);
        const filename = `RequestsPerOrganisatie_${year}_${fileNamePart}.xlsx`;
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(response, filename);
        } else {
          saveAs(response, filename);
        }
      },
    });
  }, [dispatch, i18n, operator, year]);

  const columns: ColumnProps<StatisticsByOrganisationOrderRequestModel>[] = [
    {
      key: "organisation",
      title: i18n._(t`Organisatie`),
      width: 200,
      ellipsis: true,
      dataIndex: "name",
      fixed: "left",
    },
    organisationTypeColumn(
      i18n
    ) as unknown as ColumnProps<StatisticsByOrganisationOrderRequestModel>,
    ...moment
      .monthsShort()
      .flatMap(
        (
          monthName,
          index
        ): ColumnProps<StatisticsByOrganisationOrderRequestModel>[] => {
          const monthNumber = `${index + 1}`;
          return [
            {
              key: `request-${monthNumber}`,
              title: monthName,
              width: 50,
              colSpan: 3,
              align: "center",
              render: (
                _: string,
                { statistics }: StatisticsByOrganisationOrderRequestModel
              ): ReactNode => (
                <Tooltip title={`Aangevraagd voor ${monthName} ${year}`}>
                  {statistics
                    ? statistics[`${monthNumber}` as MonthNumber]?.request ?? 0
                    : 0}
                </Tooltip>
              ),
            },
            {
              key: `created-${monthNumber}`,
              width: 50,
              colSpan: 0,
              align: "center",
              render: (
                _: string,
                { statistics }: StatisticsByOrganisationOrderRequestModel
              ): ReactNode => (
                <Tooltip title={`Aangemaakt in ${monthName} ${year}`}>
                  {statistics
                    ? statistics[`${monthNumber}` as MonthNumber]?.created ?? 0
                    : 0}
                </Tooltip>
              ),
            },
            {
              key: `submitted-${monthNumber}`,
              width: 50,
              colSpan: 0,
              align: "center",
              render: (
                _: string,
                { statistics }: StatisticsByOrganisationOrderRequestModel
              ): ReactNode => (
                <Tooltip title={`Ingediend in ${monthName} ${year}`}>
                  {statistics
                    ? statistics[`${monthNumber}` as MonthNumber]?.submitted ??
                      0
                    : 0}
                </Tooltip>
              ),
            },
          ];
        }
      ),
    {
      key: "total",
      title: i18n._(t`Totaal`),
      colSpan: 3,
      width: 50,
      fixed: "right",
      align: "center",
      filters: [
        { text: "Met statistieken", value: "filtered" },
        { text: "Toon alles", value: "all" },
      ],
      defaultFilteredValue: ["filtered"],
      filterMultiple: false,
      onFilter: (value, { statistics }): boolean => {
        const totals = getTotals(statistics);
        return value === "filtered" && totals !== undefined ? totals > 0 : true;
      },
      showSorterTooltip: false,
      sorter: (a, b) => {
        const totalsA = getTotals(a.statistics) ?? 0;
        const totalsB = getTotals(b.statistics) ?? 0;
        return totalsA - totalsB;
      },
      render: (_text, { statistics }): ReactNode => (
        <Tooltip title={`Totaal aangevraagd in ${year}`}>
          {statistics ? getTotalsBy(statistics, "request") : 0}
        </Tooltip>
      ),
    },
    {
      key: "total-created",
      colSpan: 0,
      width: 50,
      fixed: "right",
      align: "center",
      render: (_text, { statistics }): ReactNode => (
        <Tooltip title={`Totaal aangemaakt in ${year}`}>
          {statistics ? getTotalsBy(statistics, "created") : 0}
        </Tooltip>
      ),
    },
    {
      key: "total-submitted",
      colSpan: 0,
      width: 50,
      fixed: "right",
      align: "center",
      render: (_text, { statistics }): ReactNode => (
        <Tooltip title={`Totaal ingediend in ${year}`}>
          {statistics ? getTotalsBy(statistics, "submitted") : 0}
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    dispatch({
      type: sagaTypes.statistics.organisationRequestsForYear.request,
      payload: { year, operator: operator === "all" ? undefined : operator },
    });
  }, [dispatch, year, operator]);

  return (
    <>
      <Space direction="vertical">
        <Flex align="end" type="placeholder">
          <YearSelect value={year} onChange={handleYearChange} />
          <OperatorSelect value={operator} onChange={handleOperatorChange} />
          <ExportButton
            iconType="Excel"
            loading={exportOrganisationRequestsForYearLoading}
            onClick={handleExportClick}
          >
            <BigScreen type="text">
              <Trans>Exporteren</Trans>
            </BigScreen>
          </ExportButton>
        </Flex>
        <Typography.Paragraph type="warning">
          Per maand tonen deze statistieken, in deze volgorde: (1.) aantal
          aanvragen voor de betreffende maand, (2.) aantal aangemaakte aanvragen
          in die maand en (3.) het aantal ingediende aanvragen in die maand.
        </Typography.Paragraph>
      </Space>
      <Table
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={
          organisationRequestsForYearValue &&
          organisationRequestsForYearValue.statistics
        }
        loading={
          !organisationRequestsForYearValue &&
          organisationRequestsForYearLoading
        }
        pagination={false}
        rowKey={(statistic): string => statistic.organisationId}
        bordered
      />
    </>
  );
});

export default NumberOfOrderRequests;
