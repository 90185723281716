import { createActor, createList } from "async-lifecycle-saga";

import { StoreModel } from "../models";
import {
  dashboardCommercials,
  dashboardDataGetter,
  dashboardNotUsedOrdersGetter,
  dashboardOrderListGetter,
  dashboardSpots,
  dashboardTileAdd,
  dashboardTileRemove,
  dashboardTilesGetter,
} from "./api";

export const dashboardOrderListCell = createActor({
  path: ["dashboard", "list"],
  api: dashboardOrderListGetter,
});

export const dashboardTilesCell = createList({
  path: ["dashboard", "tiles"],
  require: {
    api: dashboardTilesGetter,
  },
  create: {
    api: dashboardTileAdd,
  },
  delete: {
    api: dashboardTileRemove,
  },
});

export const dashboardDataCell = createList({
  path: ["dashboard", "data"],
  require: {
    api: (_, { dateFrom, dateTo }) =>
      dashboardDataGetter({ dateFrom, dateTo, omitExtras: true }),
  },
  context: (store: StoreModel) => {
    const { period } = store.dashboard.filter;
    return { dateFrom: period[0], dateTo: period[1] };
  },
});

export const dashboardDataWithExtrasCell = createList({
  path: ["dashboard", "all"],
  require: {
    api: (_, { dateFrom, dateTo }) => dashboardDataGetter({ dateFrom, dateTo }),
  },
  context: (store: StoreModel) => {
    const { period } = store.dashboard.filter;
    return { dateFrom: period[0], dateTo: period[1] };
  },
});

export const commercialMatchCell = createActor({
  path: ["dashboard", "match"],
  api: dashboardCommercials,
});

export const spotsCell = createActor({
  path: ["dashboard", "spots"],
  api: dashboardSpots,
});

export const dashboardNotUsedOrdersCell = createList({
  path: ["dashboard", "notused"],
  require: {
    api: (_, { dateFrom, dateTo }) =>
      dashboardNotUsedOrdersGetter({ dateFrom, dateTo }),
  },
  context: (store: StoreModel) => {
    const { period } = store.dashboard.filter;
    return { dateFrom: period[0], dateTo: period[1] };
  },
});
