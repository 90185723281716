export type UserRoleName =
  | "aanleveren"
  | "b2bdocumentatie"
  | "beheerder"
  | "dashboard"
  | "financiën"
  | "lezen"
  | "plannen";

export default interface UserRole {
  name: UserRoleName;
}

export const adminRoleName: UserRoleName = "beheerder";
export const b2bDocumentationRoleName: UserRoleName = "b2bdocumentatie";
export const contributorRoleName: UserRoleName = "aanleveren";
export const dashboardRoleName: UserRoleName = "dashboard";
export const financesRoleName: UserRoleName = "financiën";
export const plannerRoleName: UserRoleName = "plannen";
export const readerRoleName: UserRoleName = "lezen";

export const allRoleNames: UserRoleName[] = [
  adminRoleName,
  b2bDocumentationRoleName,
  contributorRoleName,
  dashboardRoleName,
  financesRoleName,
  plannerRoleName,
  readerRoleName,
];

export const operatorRoles: UserRoleName[] = [
  dashboardRoleName,
  financesRoleName,
  plannerRoleName,
  readerRoleName,
];

export const roleDisplayNames: { [roleName in UserRoleName]: string } = {
  [adminRoleName]: "Beheerder",
  [b2bDocumentationRoleName]: "MediaDogs",
  [contributorRoleName]: "Aanleveren",
  [dashboardRoleName]: "Dashboard",
  [financesRoleName]: "Financiën",
  [plannerRoleName]: "Planner",
  [readerRoleName]: "Campagnes",
};
