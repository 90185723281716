import { Form } from "antd";
import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, {
  FunctionComponent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
} from "react";

import {
  OrderRequestSpot,
  fieldSpotsName,
} from "../../../../../models/Campaigns/Requests/models";
import BlocksPickerContainer from "../fields/BlocksPickerContainer";
import BudgetGrpsField from "../fields/BudgetGrpsField";
import {
  PurchaseOptionsStepReferenceValues,
  PurchaseOptionsStepStoreValues,
  StepProps,
} from "./models";

const PurchaseOptionsStep: FunctionComponent<
  StepProps<
    unknown,
    PurchaseOptionsStepReferenceValues,
    PurchaseOptionsStepStoreValues
  >
> = ({
  enabled,
  form,
  form: { setFieldsValue },
  initialValues,
  initialValues: { selectedBlocks },
  onFieldsChange,
  onFinish,
  referenceValues: {
    breakSelectionRequired,
    budgetRequired,
    budgetVisible,
    enablePreferredPositionSelection,
    grpsRequired,
    grpsVisible,
    preferredPositions,
    selectedOperator,
    selectedPackageCode,
    selectedPeriod,
    selectedProductId,
    selectedTargetGroup,
    spotLengths,
    realGrpPrice,
  },
}) => {
  const handleFinish = useCallback(
    /**
     * Handles a submission of the form.
     * @param values The form values.
     */
    (values: Store) => {
      onFinish(values as PurchaseOptionsStepStoreValues);
    },
    [onFinish]
  );

  useEffect(
    /**
     * Updates the selected blocks in the form if they change externally.
     */
    () => {
      setFieldsValue({ [fieldSpotsName]: selectedBlocks });
    },
    [selectedBlocks, setFieldsValue]
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFieldsChange={onFieldsChange}
      onFinish={handleFinish}
    >
      <Form.Item noStyle>
        {!breakSelectionRequired && (
          <BudgetGrpsField
            budgetEnabled={budgetRequired}
            budgetVisible={budgetVisible}
            form={form}
            grpsEnabled={grpsRequired}
            grpsVisible={grpsVisible}
            realGrpPrice={realGrpPrice}
          />
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }): ReactNode => {
          const currentSelectedBlocks = getFieldValue(
            fieldSpotsName
          ) as OrderRequestSpot[];

          return (
            breakSelectionRequired && (
              <BlocksPickerContainer
                fieldName={fieldSpotsName}
                form={form}
                enabled={enabled}
                enablePreferredPositionSelection={
                  enablePreferredPositionSelection
                }
                operator={selectedOperator}
                packageCode={selectedPackageCode}
                period={selectedPeriod || [moment(), moment()]}
                preferredPositions={preferredPositions}
                productId={selectedProductId}
                required={breakSelectionRequired}
                selectedBlocks={currentSelectedBlocks}
                spotLengths={spotLengths}
                targetGroupId={selectedTargetGroup?.id}
                targetGroupIntomartId={selectedTargetGroup?.intomartId}
                realGrpPrice={realGrpPrice}
              />
            )
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default memo(PurchaseOptionsStep);
