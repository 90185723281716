import { InputNumber } from "antd";
import React, { FunctionComponent, memo, useCallback } from "react";

interface SpotLengthFieldProps {
  index: number;
  length: number;
  value: number[];
  disabled?: boolean;
  onChange: (value: number[]) => void;
}

const SpotLengthField: FunctionComponent<SpotLengthFieldProps> = ({
  index,
  length,
  value,
  disabled,
  onChange,
}) => {
  const handleChange = useCallback(
    (inputValue: string | number | null): void => {
      if (inputValue && typeof inputValue === "number") {
        value.splice(index, 1, inputValue);
        onChange(value);
      }
    },
    [index, onChange, value]
  );

  return (
    <InputNumber<string | number>
      className="spotlength-input"
      disabled={disabled}
      formatter={(val): string => (val ? `${val}"` : "")}
      min={0}
      onChange={handleChange}
      parser={(val): string => val?.replace('"', "") ?? ""}
      step={5}
      style={{ width: 60, marginRight: "1em" }}
      value={length}
    />
  );
};

export default memo(SpotLengthField);
