import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input } from "antd";
import React, { ReactElement } from "react";

import User from "../../../models/User";
import propertyOf from "../../../utils/properties";

const nameField = propertyOf<User>("name");

interface UserFormItemNameProps {
  initialValue: string;
}

const UserFormItemName = ({
  initialValue,
}: UserFormItemNameProps): ReactElement => {
  const { i18n } = useLingui();

  return (
    <Form.Item
      initialValue={initialValue}
      label={i18n._(t`Naam`)}
      name={nameField}
      rules={[
        {
          message: i18n._(t`Naam is verplicht!`),
          required: true,
        },
      ]}
    >
      <Input autoFocus />
    </Form.Item>
  );
};

export default UserFormItemName;
