import { Trans } from "@lingui/macro";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React from "react";

import SubOrder from "../../models/SubOrder";
import { getSubOrderName } from "../../utils";

export type SubOrderSelectProps = { subOrders: SubOrder[] } & Omit<
  SelectProps<string>,
  "mode"
>;

const SubOrderSelect = ({
  subOrders,
  value,
  ...props
}: SubOrderSelectProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Select {...props} value={!value ? "" : value}>
    <Select.Option value="">
      <Trans>Geldt voor de gehele order</Trans>
    </Select.Option>
    {subOrders.map((s) => (
      <Select.Option key={s.id} value={s.id}>
        {getSubOrderName(s)}
      </Select.Option>
    ))}
  </Select>
);

export default SubOrderSelect;
