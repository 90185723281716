import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Spin } from "antd";
import { saveAs } from "file-saver";
import moment from "moment";
import React, {
  FC,
  FunctionComponent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import Advertiser from "../../models/Advertiser";
import {
  Operator,
  OperatorRtl,
  OperatorTalpa,
  OperatorUnknown,
  operatorLabels,
} from "../../models/Operator";
import { OrderWithAdvertiser } from "../../models/Order";
import { PackagesResponse } from "../../models/packages";
import { plannerRoleName } from "../../models/UserRole";
import {
  AvailableBreaksRequest,
  AvailableBreaksResponse,
} from "../../store/bookspot/models";
import { AnalyzedOrder } from "../../store/campaigns/analyzed/models";
import SelectOrderRequest from "../../store/campaigns/SelectOrderRequest";
import {
  ExportResponse,
  exportAdvertiserSagaTypes,
  exportFormatsFileExtensions,
  exportOrderSagaTypes,
} from "../../store/exports/models";
import { HttpStatusCode } from "../../store/fetch";
import { AsyncIdValue, RequestAction, StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { handleFailWithProblem, hasRole } from "../../utils";
import CampaignsContents from "../../views/campaigns/CampaignsContent";
import CollapseList from "../../views/collapselists/CollapseList";
import CollapseListExtraActions from "../../views/collapselists/CollapseListExtraActions";
import CollapseListExtraPeriod from "../../views/collapselists/CollapseListExtraPeriod";
import CollapseListExtraStatistics from "../../views/collapselists/CollapseListExtraStatistics";
import CollapseListHeader from "../../views/collapselists/CollapseListHeader";
import FullScreenModal from "../../views/FullScreenModal";
import BigScreen from "../../views/layout/BigScreen";
import {
  exportAdvertiserCategory,
  exportOrderCategory,
  printAdvertiserCategory,
  printOrderCategory,
  showOrderCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import { ExportType } from "../actions/ExportActionButtonProps";
import ExportButton from "../actions/ExportButton";
import ShowMoreButton from "../actions/ShowMoreButton";
import BlocksPickerContainer from "../bookspot/BlocksPickerContainer";
import FilterContainer from "./FilterContainer";
import OrderDetailsTable from "./OrderDetailsTable";
import OrderTable, { BookSpotEventHandler } from "./OrderTable";
import { SelectedPreferredPositionDictionary } from "./requests/subOrders/models";

/* Advertisers */
const keyGetterAdvertiser = (item: Advertiser) => item.id;

const headerAdvertisers: FC<Advertiser> = ({
  id,
  name,
  operator,
}: Advertiser) => (
  <CollapseListHeader
    key={id}
    title={operator === "Tip" ? name : `${name} (${operatorLabels[operator]})`}
  />
);

/* Orders */
const keyGetterOrder = (order: OrderWithAdvertiser) => order.id;

const headerOrder = ({ id, name }: OrderWithAdvertiser) => (
  <CollapseListHeader key={id} title={name} />
);

interface BookSpotOptions {
  enablePreferredPositionSelection: boolean;
  preferredPositionSelection: SelectedPreferredPositionDictionary;
  selectedOrderId?: string;
  selectedOrderDescription?: string;
  selectedSubOrderId?: string;
  selectedSubOrderGrps?: number;
  spotLengths: number[];
  visible: boolean;
  bookingIndicator?: number;
  operator?: Operator;
}

const emptyAnalyzedOrders: AnalyzedOrder[] = [];

const Campaigns: FunctionComponent =
  /**
   * Renders a component that shows current campaigns.
   * @constructor Initializes a new instance of the Campaigns functional component.
   */
  () => {
    const { i18n } = useLingui();
    const dispatch = useDispatch();
    const [bookSpotOptions, setBookSpotOptions] = useState<BookSpotOptions>({
      enablePreferredPositionSelection: false,
      preferredPositionSelection: {},
      selectedOrderId: undefined,
      selectedOrderDescription: undefined,
      selectedSubOrderId: undefined,
      selectedSubOrderGrps: undefined,
      spotLengths: [],
      visible: false,
      operator: undefined,
    });

    /**
     * Selected filter.
     */
    const {
      dateFrom,
      dateTo,
      skoTargetGroupId = -1,
    } = useSelector(
      ({ campaigns: { selectedFilter } }: StoreModel) => selectedFilter
    );

    /**
     * Show filtered orders.
     */
    const {
      orders: {
        status: { loading: loadingOrders },
        value: { advertisers, counter } = {
          advertisers: [],
          counter: undefined,
        },
      },
      analyzed: {
        value: analyzedOrders = emptyAnalyzedOrders,
        status: { loading: loadingAnalyzedOrders },
      },
    } = useSelector(({ campaigns: { orders, analyzed } }: StoreModel) => ({
      orders,
      analyzed,
    }));

    const selectOrder = useSelector(
      /**
       * Reads the selected order from the Redux Store.
       * @param selected The selected order.
       */
      ({
        ui: {
          campaigns: { selectOrder: selected },
        },
      }: StoreModel): SelectOrderRequest | null => selected
    );
    const modalIsVisible = Boolean(selectOrder);
    const handleModalClose = useCallback(
      /**
       * Handles the event in case the modal closes.
       */
      () => {
        dispatch({
          type: sagaTypes.ui.campaigns.selectOrder,
          payload: null,
        });

        if (selectOrder) {
          // opschonen spot gegevens bij sluiten
          dispatch({
            type: sagaTypes.campaigns.spotsPerOrder.clear,
            id: selectOrder.id,
          });
        }
      },
      [dispatch, selectOrder]
    );

    /**
     * Track whether an export of an order is loading.
     */
    const exportedOrdersExcel = useSelector(
      ({
        exports: { byOrderExcel },
      }: StoreModel): AsyncIdValue<ExportResponse> => byOrderExcel
    );
    const exportedOrdersPdf = useSelector(
      ({ exports: { byOrderPdf } }: StoreModel): AsyncIdValue<ExportResponse> =>
        byOrderPdf
    );

    /**
     * Track whether an export of orders by an advertiser is loading.
     */
    const exportedOrdersByAdvertiserExcel = useSelector(
      ({
        exports: { byAdvertiserExcel },
      }: StoreModel): AsyncIdValue<ExportResponse> => byAdvertiserExcel
    );
    const exportedOrdersByAdvertiserPdf = useSelector(
      ({
        exports: { byAdvertiserPdf },
      }: StoreModel): AsyncIdValue<ExportResponse> => byAdvertiserPdf
    );

    const handleBlocksPickerChange = useCallback(
      /**
       * Handles a change in the blocks picker.
       * @param newPreferredPositions The new selection of preferred positions.
       */
      (newPreferredPositions: SelectedPreferredPositionDictionary) => {
        setBookSpotOptions({
          ...bookSpotOptions,
          preferredPositionSelection: newPreferredPositions,
        });
      },
      [bookSpotOptions]
    );

    const handleBlocksPickerClose = useCallback(
      /**
       * Handles closing the blocks picker.
       */
      () => {
        setBookSpotOptions({
          ...bookSpotOptions,
          enablePreferredPositionSelection: false,
          preferredPositionSelection: {},
          visible: false,
          selectedSubOrderGrps: undefined,
        });
      },
      [bookSpotOptions]
    );
    const handleBookSpot = useCallback<BookSpotEventHandler>(
      /**
       * Handles a request to book spots on a suborder.
       * @param availableBreaksDateFrom The start date of the period from which to retrieve the available breaks.
       * @param availableBreaksDateTo The end date of the period from which to retrieve the available breaks.
       * @param orderId The order id.
       * @param subOrderId The suborder id.
       * @param advertiserId The identifier of the advertiser.
       * @param packageCode The code of the package.
       * @param packageDescription The description of the package.
       * @param spotLengths The spot lengths.
       * @param prefPos The preferred positions.
       * @param bookingIndicator Booking indicator for AdAlliance
       * @param requestedGrpsNo Requested GRP
       */
      (
        availableBreaksDateFrom,
        availableBreaksDateTo,
        orderId,
        subOrderId,
        advertiserId,
        packageCode,
        packageDescription,
        spotLengths,
        prefPos,
        bookingIndicator,
        requestedGrpsNo
      ) => {
        setBookSpotOptions({
          ...bookSpotOptions,
          visible: true,
        });
        dispatch<
          RequestAction<AvailableBreaksRequest, AvailableBreaksResponse>
        >({
          type: sagaTypes.bookSpot.availableBreaks.request,
          payload: {
            dateFrom: availableBreaksDateFrom,
            dateTo: availableBreaksDateTo,
            orderId,
            subOrderId,
            packageCode,
            spotLengths,
          },
          onFail: handleFailWithProblem(
            i18n._(t`De beschikbare blokken konden niet geladen worden.`)
          ),
        });
        dispatch({
          type: sagaTypes.campaigns.requests.packages.request,
          payload: {
            advertiserId,
            dateFrom: moment(availableBreaksDateFrom),
            dateTo: moment(availableBreaksDateTo),
          },
          onFail: handleFailWithProblem(
            i18n._(t`De pakketten konden niet geladen worden.`),
            () => {
              setBookSpotOptions({
                enablePreferredPositionSelection: false,
                preferredPositionSelection: prefPos,
                selectedOrderId: orderId,
                selectedOrderDescription: "",
                selectedSubOrderId: subOrderId,
                selectedSubOrderGrps: undefined,
                spotLengths,
                visible: true,
                bookingIndicator,
                operator: undefined,
              });
            }
          ),
          onSuccess: (
            _statusCode: HttpStatusCode,
            response?: PackagesResponse
          ) => {
            if (!response) {
              return;
            }

            const { packages } = response;
            const selectedPackage = packages.find(
              (p) =>
                p.code === packageCode ||
                (p.code.startsWith(`${packageCode}_${packageDescription}::`) && // TODO: workaround voor RTL
                  p.operator === OperatorRtl) ||
                (p.description.startsWith(packageCode) && // TODO: workaround voor TIP-644
                  p.operator === OperatorTalpa)
            );

            setBookSpotOptions({
              enablePreferredPositionSelection:
                selectedPackage?.details?.vkpInd || false,
              preferredPositionSelection: prefPos,
              selectedOrderId: orderId,
              selectedOrderDescription: "",
              selectedSubOrderId: subOrderId,
              selectedSubOrderGrps: requestedGrpsNo,
              spotLengths,
              visible: true,
              bookingIndicator,
              operator: selectedPackage?.operator,
            });
          },
        });
      },
      [bookSpotOptions, dispatch, i18n]
    );

    /**
     * Export an order using the specified format.
     */
    const handleExportOrder = useCallback(
      (orderId: string, format: ExportType) => {
        dispatch({
          type: exportOrderSagaTypes[format],
          id: orderId,
          payload: {
            dateFrom,
            dateTo,
            orderIds: [orderId],
            skoTargetGroupId:
              skoTargetGroupId !== -1 ? skoTargetGroupId : undefined,
          },
          onFail: handleFailWithProblem(i18n._(t`Er is iets misgegaan.`)),
          onSuccess: (
            _statusCode: HttpStatusCode,
            response?: ExportResponse
          ) => {
            const fileContents = response as Blob;
            const filename = `Export_Order_${orderId}.${exportFormatsFileExtensions[format]}`;
            if (window.navigator.msSaveBlob) {
              window.navigator.msSaveBlob(fileContents, filename);
            } else {
              saveAs(fileContents, filename);
            }
          },
        });
      },
      [dateFrom, dateTo, dispatch, i18n, skoTargetGroupId]
    );

    /**
     * Export the Orders associated with an Advertiser in the specified format.
     */
    const handleExportByAdvertiser = useCallback(
      (advertiserId: string, format: ExportType) => {
        const advertiser = advertisers.find(
          (a) => a.id === advertiserId
        ) as Advertiser;
        dispatch({
          type: exportAdvertiserSagaTypes[format],
          id: advertiserId,
          payload: {
            dateFrom,
            dateTo,
            orderIds: advertiser.orders.map((o) => o.id),
            skoTargetGroupId:
              skoTargetGroupId !== -1 ? skoTargetGroupId : undefined,
          },
          onFail: handleFailWithProblem(i18n._(t`Er is iets misgegaan.`)),
          onSuccess: (
            _statusCode: HttpStatusCode,
            response?: ExportResponse
          ) => {
            const fileContents = response as Blob;
            const filename = `Export_Adverteerder_${advertiserId}.${exportFormatsFileExtensions[format]}`;
            if (window.navigator.msSaveBlob) {
              window.navigator.msSaveBlob(fileContents, filename);
            } else {
              saveAs(response as Blob, filename);
            }
          },
        });
      },
      [advertisers, dateFrom, dateTo, dispatch, i18n, skoTargetGroupId]
    );

    const handleSelectOrder = useCallback(
      /**
       * Selects an order for showing more details.
       * @param id The unique identifier of the order.
       * @param name The name of the order.
       * @param advertiserId The unique identifier of the advertiser that is associated to the order.
       */
      (id: string | undefined, name: string, advertiserId: string): void => {
        if (!id) {
          return;
        }
        dispatch({
          type: sagaTypes.ui.campaigns.selectOrder,
          payload: {
            id,
            dateFrom,
            dateTo,
            name,
            advertiserId,
          },
        });
      },
      [dateFrom, dateTo, dispatch]
    );

    const extraOrder: FC<OrderWithAdvertiser> = useCallback(
      /**
       * Displays an order's extra details.
       * @param id The unique identifier of the order.
       * @param name The name of the order.
       * @param startDate The start date of the order.
       * @param endDate The end date of the order.
       * @param advertiser The advertiser associated with the order.
       */
      ({ id, name, startDate, endDate, advertiser }: OrderWithAdvertiser) => (
        <>
          <CollapseListExtraActions>
            <ExportButton
              key="exportToExcel"
              iconType="Excel"
              loading={
                exportedOrdersExcel[id] &&
                exportedOrdersExcel[id].status.loading
              }
              onClick={(event): void => {
                event.stopPropagation();
                triggerAnalyticsEvent(exportOrderCategory, id);
                handleExportOrder(id, "Excel");
              }}
            >
              <BigScreen type="text">
                <Trans>Exporteren</Trans>
              </BigScreen>
            </ExportButton>
            <ExportButton
              key="exportToPdf"
              iconType="PDF"
              loading={
                exportedOrdersPdf[id] && exportedOrdersPdf[id].status.loading
              }
              onClick={(event): void => {
                event.stopPropagation();
                triggerAnalyticsEvent(printOrderCategory, id);
                handleExportOrder(id, "PDF");
              }}
            >
              <BigScreen type="text">
                <Trans>Printen</Trans>
              </BigScreen>
            </ExportButton>
            <ShowMoreButton
              key="showMore"
              itemId={id}
              onClick={(itemId, event): void => {
                if (event) {
                  event.stopPropagation();
                }

                triggerAnalyticsEvent(showOrderCategory, itemId);
                handleSelectOrder(itemId, name, advertiser.id);
              }}
            />
          </CollapseListExtraActions>
          <CollapseListExtraPeriod
            period={[moment(startDate), moment(endDate)]}
          />
        </>
      ),
      [
        exportedOrdersExcel,
        exportedOrdersPdf,
        handleExportOrder,
        handleSelectOrder,
      ]
    );

    const { value: user } = useSelector(
      ({ users: { current } }: StoreModel) => current
    );
    const isPlanner = useMemo(() => hasRole(plannerRoleName)(user), [user]);
    const [bookSpotEnabled, impressionsEnabled] = useSelector(
      ({
        application: {
          options: { enableBookSpot, enableImpressions },
        },
      }: StoreModel) => [Boolean(enableBookSpot), Boolean(enableImpressions)]
    );

    const contentOrder: FC<OrderWithAdvertiser> = useCallback(
      /**
       * Displays an order's contents.
       * @param order The order to display.
       */
      (order: OrderWithAdvertiser) => (
        <OrderTable
          advertiserId={order.advertiser.id}
          bookSpotEnabled={bookSpotEnabled && isPlanner}
          onBookSpot={handleBookSpot}
          order={order}
          analyzedOrder={analyzedOrders.find((o) => o.id === order.id)}
          showInstructions
          impressionsEnabled={impressionsEnabled}
        />
      ),
      [
        bookSpotEnabled,
        isPlanner,
        handleBookSpot,
        analyzedOrders,
        impressionsEnabled,
      ]
    );

    /**
     * Displays advertiser information.
     */
    const contentAdvertisers: FC<Advertiser> = useCallback(
      ({ id, name, orders }: Advertiser) => (
        <CollapseList
          items={orders.map(
            (o) => ({ ...o, advertiser: { id, name } } as OrderWithAdvertiser)
          )}
          keyGetter={keyGetterOrder}
          header={headerOrder}
          extra={extraOrder}
          content={contentOrder}
          variant="Details"
        />
      ),
      [contentOrder, extraOrder]
    );

    const extraAdvertisers: FC<Advertiser> = useCallback(
      ({ id, operator, requestedBudget, bookedBudget }: Advertiser) => (
        <>
          <CollapseListExtraActions>
            <ExportButton
              iconType="Excel"
              title={i18n._(t`Exporteren naar Excel`)}
              loading={
                exportedOrdersByAdvertiserExcel[id] &&
                exportedOrdersByAdvertiserExcel[id].status.loading
              }
              onClick={(event): void => {
                event.stopPropagation();
                triggerAnalyticsEvent(exportAdvertiserCategory, id);
                handleExportByAdvertiser(id, "Excel");
              }}
            >
              <BigScreen type="text">
                <Trans>Exporteren</Trans>
              </BigScreen>
            </ExportButton>
            <ExportButton
              iconType="PDF"
              title={i18n._(t`Printen naar PDF`)}
              loading={
                exportedOrdersByAdvertiserPdf[id] &&
                exportedOrdersByAdvertiserPdf[id].status.loading
              }
              onClick={(event): void => {
                event.stopPropagation();
                triggerAnalyticsEvent(printAdvertiserCategory, id);
                handleExportByAdvertiser(id, "PDF");
              }}
            >
              <BigScreen type="text">
                <Trans>Printen</Trans>
              </BigScreen>
            </ExportButton>
          </CollapseListExtraActions>
          {requestedBudget !== undefined && bookedBudget !== undefined && (
            <CollapseListExtraStatistics
              operator={operator}
              statistics={{
                [i18n._(t`Aangevraagd`)]: requestedBudget,
                [i18n._(t`Geboekt`)]: bookedBudget,
              }}
            />
          )}
        </>
      ),
      [
        exportedOrdersByAdvertiserExcel,
        exportedOrdersByAdvertiserPdf,
        handleExportByAdvertiser,
        i18n,
      ]
    );

    return (
      <CampaignsContents>
        <FilterContainer counter={counter} />
        <Spin spinning={!advertisers || loadingOrders || loadingAnalyzedOrders}>
          <CollapseList
            items={advertisers}
            keyGetter={keyGetterAdvertiser}
            header={headerAdvertisers}
            extra={extraAdvertisers}
            content={contentAdvertisers}
            variant="Main"
          />
        </Spin>
        <FullScreenModal
          footer={[
            <Button key="CloseButton" type="primary" onClick={handleModalClose}>
              <Trans>Sluiten</Trans>
            </Button>,
          ]}
          onCancel={handleModalClose}
          onOk={handleModalClose}
          title={selectOrder && selectOrder.name ? selectOrder.name : "Order"}
          visible={modalIsVisible}
        >
          {selectOrder && (
            <OrderDetailsTable
              order={selectOrder}
              analyzedOrder={analyzedOrders.find(
                (o) => o.id === selectOrder.id
              )}
            />
          )}
        </FullScreenModal>
        {bookSpotOptions.visible && (
          <BlocksPickerContainer
            enablePreferredPositionSelection={
              bookSpotOptions.enablePreferredPositionSelection
            }
            mode="BookSpot"
            onCancel={handleBlocksPickerClose}
            onChange={handleBlocksPickerChange}
            onFinish={handleBlocksPickerClose}
            orderId={bookSpotOptions.selectedOrderId}
            orderDescription={bookSpotOptions.selectedOrderDescription}
            preferredPositions={bookSpotOptions.preferredPositionSelection}
            subOrderId={bookSpotOptions.selectedSubOrderId}
            subOrderGrps={bookSpotOptions.selectedSubOrderGrps}
            spotLengths={bookSpotOptions.spotLengths}
            visible
            bookingIndicator={bookSpotOptions.bookingIndicator}
            operator={bookSpotOptions.operator ?? OperatorUnknown}
          />
        )}
      </CampaignsContents>
    );
  };

export default memo(Campaigns);
