import AdvertiserGroup from "./AdvertiserGroup";
import AuthorizationRule from "./AuthorizationRule";
import { DashboardTile } from "./Dashboard";
import ExternalApiClientAuthorization from "./ExternalApiClientAuthorization";
import Organisation from "./Organisation";

export type UserStatus = "Created" | "Active" | "Deleted" | "Failed" | "Empty";

export type StatusStringMap = StatusMap<string>;

export type StatusMap<TValue> = {
  [key in UserStatus]: TValue;
};

export const statusTitleMap: StatusStringMap = {
  Created: "Aangemaakt",
  Active: "Actief",
  Deleted: "Verwijderd",
  Failed: "Fout",
  Empty: "Onbekend",
};

export default interface User extends Record<string, unknown> {
  id?: string;
  name: string;
  emailAddress: string;
  created?: Date;
  modified?: Date;
  status?: UserStatus;
  preferredCulture?: string;
  organisation?: Organisation;
  organisationId?: string;
  authorizations: AuthorizationRule[];
  externalApiClientAuthorizations: ExternalApiClientAuthorization[];
  advertiserGroups?: AdvertiserGroup[];
  dashboardTiles?: DashboardTile[];
  phoneNumber?: string | null;
}

export interface UserProfile {
  name: string;
  phoneNumber?: string | null;
}

export interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
