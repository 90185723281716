import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Popconfirm } from "antd";
import React, { memo, useCallback } from "react";

import ItemActionButtonProps from "./ItemActionButtonProps";

const ResetButton = memo(
  ({ disabled, itemId, onClick }: ItemActionButtonProps) => {
    const { i18n } = useLingui();

    const handleClick = useCallback(() => {
      if (itemId && onClick) {
        onClick(itemId);
      }
    }, [itemId, onClick]);

    return (
      <Popconfirm
        disabled={disabled}
        title={i18n._(t`Weet je zeker dat je dit item wil herstellen?`)}
        onConfirm={handleClick}
        cancelButtonProps={{ type: "link" }}
      >
        <Button key="resetButton" type="link" disabled={disabled}>
          <Trans>Herstellen</Trans>
        </Button>
      </Popconfirm>
    );
  }
);

export default ResetButton;
