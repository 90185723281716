import { jsonPoster } from "../fetch";
import { ApiPromise, TokenApiPromise } from "../models";
import { LocalizationSetRequest } from "./models";

// eslint-disable-next-line import/prefer-default-export
export const localizationSetPoster =
  (request: LocalizationSetRequest): TokenApiPromise<unknown> =>
  (token?: string): ApiPromise<unknown> =>
    jsonPoster<unknown, LocalizationSetRequest>("/api/localization/set")(
      request,
      token
    );
