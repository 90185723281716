import { Moment } from "moment";

import { ExportType } from "../../components/actions/ExportActionButtonProps";
import { Operator } from "../../models/Operator";
import sagaTypes from "../sagaTypes";

export interface ExportOrdersRequest {
  dateFrom: Moment;
  dateTo: Moment;
  orderIds: string[];
  skoTargetGroupId?: number;
}

export interface ExportConceptRequest {
  orderRequestId: string;
}

export interface ExportOrganisationLoginsForYearStatisticsRequest {
  year: number;
}

export interface ExportOrganisationRequestsForYearStatisticsRequest {
  year: number;
  operator?: Operator;
}

export interface ExportCommercialDeliveriesForYearStatisticsRequest {
  year: number;
  operator?: Operator;
}

export interface ExportUserLoginsByOrganisationForYearStatisticsRequest {
  organisationId: string;
  year: number;
}

export type ExportResponse = Blob | unknown;

type ExportFormatDictionary<TValue> = { [format in ExportType]: TValue };

export const exportFormatsFileExtensions: ExportFormatDictionary<string> = {
  Excel: "xlsx",
  PDF: "pdf",
};

export const exportOrderSagaTypes: ExportFormatDictionary<string> = {
  Excel: sagaTypes.exports.orderExcel.request,
  PDF: sagaTypes.exports.orderPdf.request,
};

export const exportAdvertiserSagaTypes: ExportFormatDictionary<string> = {
  Excel: sagaTypes.exports.advertiserOrdersExcel.request,
  PDF: sagaTypes.exports.advertiserOrdersPdf.request,
};

export const exportFilterSagaTypes: ExportFormatDictionary<string> = {
  Excel: sagaTypes.exports.filterOrdersExcel.request,
  PDF: sagaTypes.exports.filterOrdersPdf.request,
};
