import { LockOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Input } from "antd";
import React, { useCallback } from "react";

import { ChangePasswordRequest } from "../../models/User";
import {
  passwordIdenticalMessage,
  passwordRegex,
  passwordRegexMessage,
  passwordRequiredMessage,
} from "../../utils/password";
import { labelCol, wrapperColNoLabel, wrapperColShallow } from "../layout/Form";

export interface UserPasswordFormProps {
  onFinish: (values: ChangePasswordRequest) => void;
  loading: boolean;
}

const UserPasswordForm = ({ onFinish, loading }: UserPasswordFormProps) => {
  const { i18n } = useLingui();
  const [form] = Form.useForm<ChangePasswordRequest>();

  const handleFinish = useCallback(
    (values: ChangePasswordRequest) => {
      onFinish(values);
      form.resetFields();
    },
    [form, onFinish]
  );

  return (
    <Form
      form={form}
      labelCol={labelCol}
      wrapperCol={wrapperColShallow}
      onFinish={handleFinish}
    >
      <Form.Item
        label={i18n._(t`Huidige wachtwoord`)}
        name="currentPassword"
        rules={[{ required: true, message: i18n._(passwordRequiredMessage) }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={i18n._(t`Huidige wachtwoord`)}
        />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Nieuw wachtwoord`)}
        name="newPassword"
        rules={[
          { required: true, message: i18n._(passwordRequiredMessage) },
          { pattern: passwordRegex, message: i18n._(passwordRegexMessage) },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={i18n._(t`Nieuw wachtwoord`)}
        />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Nieuw wachtwoord (opnieuw)`)}
        name="confirmPassword"
        rules={[
          { required: true, message: i18n._(passwordRequiredMessage) },
          ({ getFieldValue }) => ({
            validator: (_rule, value) =>
              value === getFieldValue("newPassword")
                ? Promise.resolve()
                : Promise.reject(i18n._(passwordIdenticalMessage)),
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={i18n._(t`Nieuw wachtwoord (opnieuw)`)}
        />
      </Form.Item>
      <Form.Item wrapperCol={wrapperColNoLabel}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          {i18n._(t`Wachtwoord wijzigen`)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserPasswordForm;
