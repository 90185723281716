import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Spin, message } from "antd";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { CommercialBlobResponse } from "../../models/OnBlobDownloadFunc";
import User from "../../models/User";
import { AsyncValue, StoreModel, TokenModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import CommercialLibraryContent from "../../views/commercials/CommercialLibraryContent";
import CommercialResults from "../../views/commercials/CommercialResults";
import {
  commercialDeleteCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import CommercialFilters, { CommercialFilterValues } from "./CommercialFilters";
import CommercialView from "./CommercialView";
import { CommercialDeliveryView } from "./models";

interface UserWithToken {
  user?: User;
  accessToken?: TokenModel;
}

const initialFilterState: CommercialFilterValues = {
  period: [moment().startOf("month"), moment().endOf("month")],
};

const CommercialLibraryContainer: FunctionComponent = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [filters, updateFilters] =
    useState<CommercialFilterValues>(initialFilterState);

  const { user } = useSelector(
    ({ users: { current } }: StoreModel): UserWithToken => ({
      user: current.value,
    })
  );

  useEffect(() => {
    if (user && user.organisation) {
      const { period } = filters;
      if (!period) {
        updateFilters(initialFilterState);
        return;
      }

      dispatch({
        type: sagaTypes.commercials.advertisers.request,
        payload: { dateFrom: period[0], dateTo: period[1] },
      });
    }
  }, [filters, dispatch, user]);

  const {
    status: { loading: advertisersLoading },
    value: advertisersValue,
  } = useSelector(
    ({ commercials: { advertisers } }: StoreModel) => advertisers
  );

  const handlePeriodChange = useCallback(
    (newPeriod?: RangeValue<Moment>) => {
      if (!newPeriod) {
        dispatch({
          type: sagaTypes.commercials.advertisers.clear,
        });

        return;
      }

      updateFilters({ period: newPeriod });
      dispatch({
        type: sagaTypes.commercials.advertisers.request,
        payload: {
          dateFrom: newPeriod[0],
          dateTo: newPeriod[1],
        },
      });
    },
    [dispatch]
  );

  const {
    value: commercials,
    status: { loading: loadingCommercials },
  } = useSelector(
    ({
      commercials: { overview },
    }: StoreModel): AsyncValue<CommercialDeliveryView[]> => overview
  );

  const handleFilters = useCallback(
    (f: CommercialFilterValues) => {
      updateFilters(f);
      dispatch({
        type: sagaTypes.commercials.overview.request,
        payload: f,
      });
    },
    [dispatch]
  );

  const handleDownload = useCallback(
    (
      commercialDeliveryId: string,
      path: string,
      callback: (blob: CommercialBlobResponse) => void
    ) => {
      dispatch({
        type: sagaTypes.commercials.download.request,
        id: commercialDeliveryId,
        payload: { path },
        onFail: () => {
          message.error(i18n._(t`Er is iets misgegaan met het downloaden`));
        },
        onSuccess: (_: number, payload: CommercialBlobResponse) => {
          callback(payload);
        },
      });
    },
    [dispatch, i18n]
  );

  const handleDelete = useCallback(
    (commercialDeliveryId: string) => {
      dispatch({
        type: sagaTypes.commercials.deleter.request,
        id: commercialDeliveryId,
        payload: { id: commercialDeliveryId },
        onFail: () => {
          message.error(i18n._(t`Er is iets misgegaan met het verwijderen`));
        },
        onSuccess: () => {
          triggerAnalyticsEvent(commercialDeleteCategory, commercialDeliveryId);
          dispatch({
            type: sagaTypes.commercials.overview.request,
            payload: filters,
          });

          message.success(i18n._(t`Commercial is verwijderd`));
        },
      });
    },
    [dispatch, i18n, filters]
  );

  return (
    <CommercialLibraryContent>
      <CommercialFilters
        filters={filters}
        onFinish={handleFilters}
        onPeriodChange={handlePeriodChange}
        advertisers={advertisersValue}
        loading={advertisersLoading || loadingCommercials}
      />

      <Spin spinning={loadingCommercials}>
        {commercials && (
          <CommercialResults>
            {commercials.map((c) => (
              <CommercialView
                key={c.id}
                commercial={c}
                onDownload={handleDownload}
                onDelete={handleDelete}
              />
            ))}
          </CommercialResults>
        )}
      </Spin>
    </CommercialLibraryContent>
  );
};

export default memo(CommercialLibraryContainer);
