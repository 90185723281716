import { createActor, createList } from "async-lifecycle-saga";

import {
  organisationCreatePoster,
  organisationDeleter,
  organisationSetPasswordPutter,
  organisationUpdatePutter,
  organisationsAllGetter,
} from "./api";

export const organisationsCell = createList({
  path: ["organisations"],
  require: {
    api: organisationsAllGetter,
  },
  create: {
    api: organisationCreatePoster,
  },
  update: {
    api: organisationUpdatePutter,
  },
  delete: {
    api: organisationDeleter,
  },
});

export const organisationSetPasswordCell = createActor({
  path: ["organisationSetPassword"],
  api: organisationSetPasswordPutter,
});
