import { defaultCulture } from "./hooks";

export type ZendeskUser = {
  name: string;
  email: string;
  organisation?: string;
  preferredCulture?: string;
};

export const initZendeskSuggestions = (
  pageUri: string,
  preferredCulture: string | undefined
): void => {
  if (window.zE) {
    window.zE("webWidget", "updateSettings", {
      // Set the filter to the section ID of the help center for the TIP web app
      webWidget: { helpCenter: { filter: { section: "360001130778" } } },
    });
    window.zE("webWidget", "helpCenter:setSuggestions", {
      url: true,
      search: `${preferredCulture || defaultCulture}:${pageUri}`,
    });
  }
};

export const initZendeskProps = (user: ZendeskUser): void => {
  if (window.zE) {
    window.zE("webWidget", "identify", user);
    window.zE("webWidget", "prefill", {
      name: { value: user.name },
      email: { value: user.email },
      organization: { value: user.organisation || "" },
    });

    initZendeskSuggestions(
      `${window.location.pathname}${window.location.search}${window.location.hash}`,
      user.preferredCulture
    );

    window.zE("webWidget", "setLocale", user.preferredCulture);
  }
};

export const resetZendeskProps = (): void => {
  if (window.zE) {
    window.zE("webWidget", "logout");
  }
};
