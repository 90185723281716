import classNames from "classnames";
import { groupBy } from "lodash";
import React from "react";

import { DashboardData } from "../../models/Dashboard";
import { Operator, operatorLabels } from "../../models/Operator";
import { useCulture } from "../../utils/hooks";
import { formatNumber } from "../utils";

interface Props {
  data: DashboardData[];
  totalLabels: Record<string, string>;
}

const ImpressionsTable = ({ data, totalLabels }: Props) => {
  const orders = data.flatMap((d) => d.orders);
  const rows = Object.entries(groupBy(orders, (order) => order.operator)).map(
    ([key, operatorOrders]) => ({
      key,
      operator: key,
      impressionsInTargetGroup: operatorOrders
        .flatMap((o) => o.purchasingTargetGroups)
        .reduce((acc, curr) => acc + curr.impressionsInTargetGroup, 0),
      impressionsTotal: operatorOrders
        .flatMap((o) => o.purchasingTargetGroups)
        .reduce((acc, curr) => acc + curr.impressionsTotal, 0),
    })
  );

  const Cells = ({
    operator,
    impressionsInTargetGroup,
    impressionsTotal,
  }: {
    operator: string;
    impressionsInTargetGroup: number;
    impressionsTotal: number;
  }) => {
    const culture = useCulture();
    return (
      <>
        <th
          scope="col"
          className={classNames("operator", `operator-${operator}`)}
        >
          {operatorLabels[operator as Operator]}
        </th>
        <td>{formatNumber(impressionsInTargetGroup, 0, culture)}</td>
        <td>{formatNumber(impressionsTotal, 0, culture)}</td>
      </>
    );
  };

  return (
    <table className="impressions">
      <colgroup>
        <col className="operator" />
      </colgroup>
      <thead>
        <tr>
          <td />
          <th scope="col">
            {totalLabels.impressionsInTargetGroup ?? "impressionsInTargetGroup"}
          </th>
          <th scope="col">
            {totalLabels.impressionsTotal ?? "impressionsTotal"}
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map(
          ({ operator, impressionsInTargetGroup, impressionsTotal }) => (
            <tr>
              <Cells
                operator={operator}
                impressionsInTargetGroup={impressionsInTargetGroup}
                impressionsTotal={impressionsTotal}
              />
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default ImpressionsTable;
