import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Collapse, Space, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { healthCell } from "../../store/health/cells";
import { HealthCategory } from "../../store/health/models";
import { StoreModel } from "../../store/models";
import HealthContent from "../../views/health/HealthContent";
import { initialHealth } from "./constants";
import HealthCheckList from "./HealthCheckList";
import HealthStatusIcon from "./HealthStatusIcon";
import { healthCategories } from "./texts";

const HealthContainer = () => {
  const dispatch = useDispatch();
  const load = useCallback(() => {
    dispatch(healthCell.require());
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const { i18n } = useLingui();

  const { value, status } = useSelector(
    ({ health }: StoreModel) => health ?? initialHealth
  );

  return (
    <HealthContent>
      <Space direction="horizontal">
        <Button onClick={load} type="primary">
          <Trans>Overzicht vernieuwen</Trans>
        </Button>
        {value && (
          <Tooltip title={moment(value.modified).format("LLL")}>
            <Trans>Laatste controle</Trans>: {moment(value.modified).fromNow()}
          </Tooltip>
        )}
      </Space>

      <Spin spinning={status.loading}>
        {value && (
          <Collapse expandIconPosition="right">
            {Object.keys(value.healthChecks).map((key) => {
              const checks = value.healthChecks[key as HealthCategory];
              const healthy = checks.every((c) => c.status === "Healthy");
              const header = !healthy ? (
                <strong>
                  {i18n._(healthCategories[key as HealthCategory])}
                </strong>
              ) : (
                i18n._(healthCategories[key as HealthCategory])
              );

              return (
                <Collapse.Panel
                  key={key}
                  header={header}
                  extra={<HealthStatusIcon healthy={healthy} />}
                >
                  <HealthCheckList checks={checks} />
                </Collapse.Panel>
              );
            })}
          </Collapse>
        )}
      </Spin>
    </HealthContent>
  );
};

export default memo(HealthContainer);
