import { DatePicker } from "antd";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, { ReactElement, useCallback } from "react";

interface ValidityPeriodPickerProps {
  defaultValue?: [Moment, Moment];
  enabled: boolean;
  onPicked: (key: string, value: [Moment, Moment]) => void;
  rowKey: string;
}

const ValidityPeriodPicker = ({
  defaultValue,
  enabled,
  onPicked,
  rowKey,
}: ValidityPeriodPickerProps): ReactElement => {
  const handleChange = useCallback(
    (values: RangeValue<Moment>) => {
      const pickedValues = values as [Moment, Moment];
      const normalizedValues: [Moment, Moment] = [
        moment(pickedValues[0]).startOf("day"),
        moment(pickedValues[1].endOf("day")),
      ];
      onPicked(rowKey, normalizedValues);
    },
    [onPicked, rowKey]
  );

  return (
    <DatePicker.RangePicker
      key="periodPicker"
      defaultValue={defaultValue}
      disabled={!enabled}
      format="D MMMM YYYY"
      onChange={handleChange}
    />
  );
};

export default ValidityPeriodPicker;
