import { AuthenticationResponse } from "./authentication/models";
import { adBlockChecker, jsonPoster } from "./fetch";
import {
  AccessRequest,
  AccessTokenModel,
  ActivateRequest,
  ActivateResponse,
  ActivateVerifyRequest,
  AdBlockCheck,
  ApiPromise,
  AuthenticateTwoFactorRequest,
  AuthenticationRequest,
  ProductRequestBody,
  RefreshRequest,
  RefreshTokenModel,
  TokenApiPromise,
  TokenModel,
} from "./models";

export const activateVerifyPoster =
  (request: ActivateVerifyRequest): TokenApiPromise<string> =>
  (): ApiPromise<string> =>
    jsonPoster<string, ActivateVerifyRequest>("/api/user/activate/verify")(
      request
    );

export const activatePoster =
  (tokenRequest: ActivateRequest): TokenApiPromise<ActivateResponse> =>
  (): ApiPromise<ActivateResponse> =>
    jsonPoster<ActivateResponse, ActivateRequest>("/api/user/activate")(
      tokenRequest
    ).then((response) =>
      response.payload
        ? {
            statusCode: response.statusCode,
            payload: {
              user: response.payload.user,
              activationResult: response.payload.activationResult,
              possession: response.payload.possession,
              refresh: response.payload.refresh,
            },
          }
        : { statusCode: response.statusCode, exception: response.exception }
    );

export const authenticatePoster =
  (request: AuthenticationRequest): TokenApiPromise<AuthenticationResponse> =>
  (): ApiPromise<AuthenticationResponse> =>
    jsonPoster<AuthenticationResponse, AuthenticationRequest>(
      "/api/user/authenticate"
    )(request);

export const authenticateTwoFactorPoster =
  (request: AuthenticateTwoFactorRequest): TokenApiPromise<TokenModel> =>
  (): ApiPromise<TokenModel> =>
    jsonPoster<TokenModel, AuthenticateTwoFactorRequest>(
      "/api/user/authenticate/twofactor"
    )(request);

export const refreshPoster =
  (tokenRequest: RefreshRequest): TokenApiPromise<TokenModel> =>
  (): ApiPromise<TokenModel> =>
    jsonPoster<RefreshTokenModel, RefreshRequest>("/api/user/refresh")(
      tokenRequest
    ).then((response) =>
      response.payload
        ? {
            statusCode: response.statusCode,
            payload: {
              token: response.payload.refreshToken,
              result: response.payload.result,
              expires: new Date(response.payload.expires),
            },
          }
        : { statusCode: response.statusCode, exception: response.exception }
    );

export const accessPoster =
  (tokenRequest: AccessRequest): TokenApiPromise<TokenModel> =>
  (): ApiPromise<TokenModel> =>
    jsonPoster<AccessTokenModel, AccessRequest>("/api/user/access")(
      tokenRequest
    ).then((response) =>
      response.payload
        ? {
            statusCode: response.statusCode,
            payload: {
              token: response.payload.accessToken,
              result: response.payload.result,
              expires: new Date(response.payload.expires),
            },
          }
        : { statusCode: response.statusCode, exception: response.exception }
    );

export const productCreateRequest =
  (productRequest: ProductRequestBody): TokenApiPromise<string> =>
  (token?: string): ApiPromise<string> =>
    jsonPoster<string, ProductRequestBody>("/api/product/request")(
      productRequest,
      token
    );

export const checkForAdBlocker =
  (): TokenApiPromise<AdBlockCheck> => (): ApiPromise<AdBlockCheck> =>
    adBlockChecker();
