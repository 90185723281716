import { Trans } from "@lingui/macro";
import { Progress, Space, Typography } from "antd";
import React from "react";

import { useCulture } from "../utils/hooks";
import { formatNumber } from "./utils";

const BlocksPickerProgress = ({
  percent,
  minimum,
}: {
  percent: number;
  minimum: number;
}): JSX.Element => {
  const culture = useCulture();
  const progress = (percent / minimum) * 100;

  return (
    <Space>
      <Progress percent={progress} style={{ width: 100 }} showInfo={false} />
      <Typography.Text>
        <Trans>
          {formatNumber(percent, 1, culture)}% van minimaal {minimum}%
        </Trans>
      </Typography.Text>
    </Space>
  );
};

export default BlocksPickerProgress;
