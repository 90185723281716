import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Table, Tooltip, Typography } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import React, { ReactNode, memo, useMemo } from "react";

import {
  OrderRequest,
  OrderRequestSubOrder,
  emptyValue,
} from "../../../models/Campaigns/Requests/models";
import { OperatorOrn } from "../../../models/Operator";
import Ellipsis from "../../Ellipsis";
import Euro from "../../Euro";
import Grps from "../../Grps";
import NotAvailable from "../../NotAvailable";
import { OperatorAvatar } from "../../OperatorIcon";
import PeriodView from "../../PeriodView";
import OrderRequestStatusView from "./OrderRequestStatusView";

interface OrderRequestViewProps {
  orderRequest: OrderRequest;
}

const OrderRequestView = memo(
  ({ orderRequest: { subOrders } }: OrderRequestViewProps) => {
    const { i18n } = useLingui();

    const columns = useMemo(
      (): ColumnProps<OrderRequestSubOrder>[] => [
        {
          key: "operator",
          title: "",
          dataIndex: "operator",
          align: "center",
          width: 38,
          render: (_text, { operator }): ReactNode =>
            operator && <OperatorAvatar operator={operator} invert />,
        },
        {
          key: "product",
          title: i18n._(t`Product`),
          dataIndex: "product",
          width: 200,
          ellipsis: true,
          render: (_text, { product }): ReactNode =>
            product && (
              <Ellipsis
                text={`${product.id.split(":")[1]}: ${product.description}`}
              />
            ),
        },
        {
          key: "period",
          title: i18n._(t`Periode`),
          dataIndex: "subOrderPeriod",
          width: 150,
          render: (_text, { startDate, endDate }): ReactNode => (
            <PeriodView
              from={moment(startDate).toDate()}
              to={moment(endDate).toDate()}
              type="short"
            />
          ),
        },
        {
          key: "pkg",
          title: i18n._(t`Pakket`),
          dataIndex: "pkg",
          width: 200,
          ellipsis: true,
          render: (
            _text,
            { package: pkg, subOrderRequestLengths }
          ): ReactNode => {
            const spotLengthsText =
              subOrderRequestLengths &&
              `${subOrderRequestLengths
                .map((length) => length.spotLength.toString())
                .reduce((first, second) => `${first}+${second}`)}"`;

            return (
              pkg && <Ellipsis text={`${pkg.description} ${spotLengthsText}`} />
            );
          },
        },
        {
          key: "targetGroup",
          title: i18n._(t`Doelgroep`),
          dataIndex: "targetGroup",
          width: 200,
          ellipsis: true,
          render: (_text, { package: { details }, targetGroup }): ReactNode =>
            targetGroup && (
              <Ellipsis
                text={
                  details?.targetGroups?.find((tg) => tg.id === targetGroup.id)
                    ?.description || targetGroup.intomartId
                }
              />
            ),
        },
        {
          key: "grps",
          title: (
            <Tooltip
              placement="bottom"
              title={i18n._(
                t`GRP's zijn een inschatting op basis van ingevoerd budget en gehanteerde netto GRP prijs`
              )}
            >
              <span>{i18n._(t`GRP's`)}</span>
            </Tooltip>
          ),
          dataIndex: "grps",
          width: 100,
          render: (_text, { grps }): ReactNode =>
            grps ? (
              <Grps amount={grps} showSuffix isPredicted />
            ) : (
              <Typography.Text>{i18n._(emptyValue)}</Typography.Text>
            ),
        },
        {
          key: "budget",
          title: i18n._(t`Budget`),
          dataIndex: "budget",
          width: 100,
          render: (_text, { operator, budget }): ReactNode =>
            operator === OperatorOrn && budget === 0 ? (
              <NotAvailable />
            ) : (
              <Euro amount={budget} fractionDigits={2} />
            ),
        },
        {
          key: "status",
          title: i18n._(t`Status`),
          dataIndex: "requestStatus",
          width: 120,
          render: (_text, { requestStatus }): ReactNode => (
            <OrderRequestStatusView status={requestStatus} />
          ),
        },
      ],
      [i18n]
    );

    return (
      <section className="tip-order-view">
        <Table
          bordered
          columns={columns}
          dataSource={subOrders}
          size="small"
          pagination={false}
          rowKey={({ product }): string => `${product}`}
        />
      </section>
    );
  }
);

export default memo(
  ({ orderRequest: { subOrders }, orderRequest }: OrderRequestViewProps) => {
    if (!subOrders) {
      return null;
    }

    return <OrderRequestView orderRequest={orderRequest} />;
  }
);
