import { LockOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Button, Col, Form, Input, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, {
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import {
  HttpStatusCode,
  httpInternalServerError,
  httpUnauthorized,
} from "../../store/fetch";
import {
  AuthenticateTwoFactorRequest,
  StoreModel,
  TokenModel,
} from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { getToken, getUsername, knowledgeTokenKey } from "../../store/token";
import { useRedirectIfLoggedIn } from "../../utils/hooks";
import navigationPaths from "../../utils/navigation";
import propertyOf from "../../utils/properties";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../views/layout/Form";

type TwoFactorState = "Default" | "Error" | "Invalid";

const usernameField = propertyOf<AuthenticateTwoFactorRequest>("username");
const knowledgeTokenField =
  propertyOf<AuthenticateTwoFactorRequest>("knowledgeToken");
const totpField = propertyOf<AuthenticateTwoFactorRequest>("totp");

const TwoFactorWrapper = memo(() => {
  const knowledgeToken = getToken(knowledgeTokenKey, true);
  const username = getUsername();
  if (!knowledgeToken || !username) {
    return <Navigate to={navigationPaths.Login} />;
  }

  return <TwoFactor username={username} knowledgeToken={knowledgeToken} />;
});

const TwoFactor = ({
  username,
  knowledgeToken,
}: {
  username: string;
  knowledgeToken: TokenModel;
}) => {
  const { i18n } = useLingui();
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue({
      [usernameField]: username,
      [knowledgeTokenField]: knowledgeToken.token,
    });
  }, [form, knowledgeToken.token, username]);

  const dispatch = useDispatch();
  const [twoFactorState, setTwoFactorState] =
    useState<TwoFactorState>("Default");

  const { loading = false } = useSelector(
    ({
      tokens: {
        authenticateTwoFactor: { status },
      },
    }: StoreModel) => status
  );

  const handleSubmitFailCallback = useCallback(
    (exception: Error, statusCode: HttpStatusCode) => {
      switch (statusCode) {
        case httpUnauthorized: {
          setTwoFactorState("Invalid");
          return;
        }
        case httpInternalServerError:
        default: {
          setTwoFactorState("Error");
        }
      }
    },
    []
  );
  const handleFinish = useCallback(
    (values: AuthenticateTwoFactorRequest) => {
      if (values) {
        dispatch({
          type: sagaTypes.tokens.authenticateTwoFactor.request,
          payload: values,
          onFail: handleSubmitFailCallback,
        });
      }
    },
    [dispatch, handleSubmitFailCallback]
  );

  useRedirectIfLoggedIn();

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name="TwoFactorAuthentication"
            labelCol={labelCol}
            wrapperCol={wrapperColShallow}
            onFinish={handleFinish}
          >
            <Form.Item name={usernameField} noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name={knowledgeTokenField} noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              label={i18n._(t`Verificatiecode`)}
              name={totpField}
              rules={[
                {
                  required: true,
                  message: i18n._(t`Verificatiecode is verplicht.`),
                },
              ]}
              help={i18n._(
                t`Open de Google Authenticator app om je 2-staps authenticatie token te vinden.`
              )}
            >
              <Input prefix={<LockOutlined />} autoFocus />
            </Form.Item>
            <Form.Item shouldUpdate wrapperCol={wrapperColNoLabel}>
              {(): ReactElement => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form
                      .getFieldsError()
                      .some(({ errors }) => errors.length > 0)
                  }
                >
                  <Trans>Versturen</Trans>
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {twoFactorState === "Invalid" && (
        <Row>
          <Col span={24}>
            <Alert
              type="warning"
              message={i18n._(t`Verificatiecode is ongeldig.`)}
              showIcon
            />
          </Col>
        </Row>
      )}
      {twoFactorState === "Error" && (
        <Row>
          <Col span={24}>
            <Alert
              type="error"
              message={i18n._(t`Er is iets misgegaan.`)}
              showIcon
            />
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default memo(TwoFactorWrapper);
