import AdvertiserGroup, {
  AdvertiserGroupCreate,
} from "../../models/AdvertiserGroup";
import { jsonDeleter, jsonPoster, jsonPutter } from "../fetch";
import { ApiPromise, TokenApiPromise } from "../models";

// eslint-disable-next-line import/prefer-default-export
export const saveAdvertiserGroup =
  (advertiserGroup: AdvertiserGroup): TokenApiPromise<string> =>
  (token?: string): ApiPromise<string> => {
    if (advertiserGroup.id && advertiserGroup.id !== " ") {
      // update
      return jsonPutter<string, AdvertiserGroup>(
        `/api/normalisation/advertiser/${advertiserGroup.id}`
      )(
        {
          id: advertiserGroup.id,
          name: advertiserGroup.name,
          advertiserIds: advertiserGroup.advertiserIds,
        },
        token
      );
    }
    // create
    return jsonPoster<string, AdvertiserGroupCreate>(
      "/api/normalisation/advertiser"
    )(
      {
        name: advertiserGroup.name,
        advertiserIds: advertiserGroup.advertiserIds,
      },
      token
    );
  };

export const deleteAdvertiserGroup =
  ({ id }: { id: string }): TokenApiPromise<string> =>
  (token?: string): ApiPromise<string> =>
    jsonDeleter<string>(`/api/normalisation/advertiser/${id}`)(token);
