import { Col, Row } from "antd";
import React, { Children, PropsWithChildren, memo } from "react";

const StatisticsContent = memo(({ children }: PropsWithChildren<unknown>) => (
  <section className="tip-statistics">
    {children &&
      Children.map(children, (child) => (
        <Row>
          <Col span={24}>{child}</Col>
        </Row>
      ))}
  </section>
));

export default StatisticsContent;
