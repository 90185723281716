import { getPeriodQueryString } from "../../utils/date";
import { jsonDownloader } from "../fetch";
import { TokenApiPromise } from "../models";
import {
  ExportConceptRequest,
  ExportOrdersRequest,
  ExportOrganisationLoginsForYearStatisticsRequest,
  ExportOrganisationRequestsForYearStatisticsRequest,
  ExportResponse,
  ExportUserLoginsByOrganisationForYearStatisticsRequest,
} from "./models";

export const exportOrderExcelDownloader = ({
  orderIds,
  dateFrom,
  dateTo,
  skoTargetGroupId,
}: ExportOrdersRequest): TokenApiPromise<ExportResponse> => {
  const url = skoTargetGroupId
    ? `/api/campaigns/export?${getPeriodQueryString(
        dateFrom,
        dateTo
      )}&orderId=${orderIds
        .map((oid) => encodeURIComponent(oid))
        .join("&orderId=")}&skoTargetGroupId=${skoTargetGroupId}`
    : `/api/campaigns/export?${getPeriodQueryString(
        dateFrom,
        dateTo
      )}&orderId=${orderIds
        .map((oid) => encodeURIComponent(oid))
        .join("&orderId=")}`;

  return jsonDownloader<ExportResponse>(url);
};

export const exportOrderPdfDownloader = ({
  orderIds,
  dateFrom,
  dateTo,
  skoTargetGroupId,
}: ExportOrdersRequest): TokenApiPromise<ExportResponse> => {
  const url = skoTargetGroupId
    ? `/api/campaigns/print?${getPeriodQueryString(
        dateFrom,
        dateTo
      )}&orderId=${orderIds
        .map((oid) => encodeURIComponent(oid))
        .join("&orderId=")}&skoTargetGroupId=${skoTargetGroupId}`
    : `/api/campaigns/print?${getPeriodQueryString(
        dateFrom,
        dateTo
      )}&orderId=${orderIds
        .map((oid) => encodeURIComponent(oid))
        .join("&orderId=")}`;

  return jsonDownloader<ExportResponse>(url);
};

export const exportStatisticsForOrganisationsExcelDownloader = ({
  year,
}: ExportOrganisationLoginsForYearStatisticsRequest): TokenApiPromise<ExportResponse> =>
  jsonDownloader<ExportResponse>(`/api/statistics/export/overall/${year}`);

export const exportStatisticsForUsersByOrganisationExcelDownloader = ({
  organisationId,
  year,
}: ExportUserLoginsByOrganisationForYearStatisticsRequest): TokenApiPromise<ExportResponse> =>
  jsonDownloader<ExportResponse>(
    `/api/statistics/export/organisation/${organisationId}/${year}`
  );

export const exportStatisticsForRequestsOrganisationsExcelDownloader = ({
  year,
  operator,
}: ExportOrganisationRequestsForYearStatisticsRequest): TokenApiPromise<ExportResponse> =>
  jsonDownloader<ExportResponse>(
    operator
      ? `/api/statistics/export/orderRequests/byOrganisation/${year}/${operator}`
      : `/api/statistics/export/orderRequests/byOrganisation/${year}`
  );

export const exportStatisticsForDeliveriesOrganisationsExcelDownloader = ({
  year,
  operator,
}: ExportOrganisationRequestsForYearStatisticsRequest): TokenApiPromise<ExportResponse> =>
  jsonDownloader<ExportResponse>(
    operator
      ? `/api/statistics/export/commercialDeliveries/byOrganisation/${year}/${operator}`
      : `/api/statistics/export/commercialDeliveries/byOrganisation/${year}`
  );

export const exportConceptExcelDownloader = ({
  orderRequestId,
}: ExportConceptRequest): TokenApiPromise<ExportResponse> =>
  jsonDownloader<ExportResponse>(`/api/request/export/${orderRequestId}`);

export const exportUsersDownloader = (
  _: unknown
): TokenApiPromise<ExportResponse> =>
  jsonDownloader<ExportResponse>("/api/user/export");
