import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Badge, Table, Tooltip } from "antd";
import { AsyncStatus } from "async-lifecycle-saga/dist/models";
import moment from "moment";
import React, { ReactNode, memo } from "react";

import { OrnImportReport } from "../../../models/OrnImportReport";

const OrnImportReportTable = memo(
  ({ value, status }: { value?: OrnImportReport[]; status: AsyncStatus }) => {
    const { i18n } = useLingui();

    return (
      <Table
        dataSource={value}
        columns={[
          {
            title: i18n._(t`Datum`),
            key: "start",
            width: 250,
            render: (_, { dateTime }): ReactNode => (
              <>{moment(dateTime).format("D-M-YYYY LT")}</>
            ),
          },
          {
            title: i18n._(t`Status`),
            dataIndex: "status",
            key: "status",
            width: 80,
            align: "center",
            render: (_, { items }): ReactNode => {
              const badgeStatus = items.some((i) => !i.success)
                ? "error"
                : "success";
              return <Badge status={badgeStatus} />;
            },
          },
          {
            title: (
              <Tooltip
                title={i18n._(
                  t`Geïmporteerde ORN orders, vanuit 'OTVxxxxxx.txt' bestanden`
                )}
              >
                {i18n._(t`Geïmporteerde orders`)}
              </Tooltip>
            ),
            key: "imported",
            align: "center",
            width: 200,
            render: (_, { items }): ReactNode => (
              <>
                {
                  items.filter(
                    (i) => i.success && i.referencedType.endsWith(".OrnOrder")
                  ).length
                }
              </>
            ),
          },
          {
            title: (
              <Tooltip
                title={i18n._(
                  t`ORN orders die niet geimporteerd zijn vanwege een fout`
                )}
              >
                {i18n._(t`Genegeerde orders`)}
              </Tooltip>
            ),
            key: "ignored",
            align: "center",
            width: 200,
            render: (_, { items }): ReactNode => (
              <>
                {
                  items.filter(
                    (i) => !i.success && i.referencedType.endsWith(".OrnOrder")
                  ).length
                }
              </>
            ),
          },
          {
            title: i18n._(t`Foutmelding(en)`),
            key: "errors",
            render: (_, { items }): ReactNode =>
              items.some((i) => !i.success) ? (
                <ul>
                  {items
                    .filter((i) => !i.success)
                    .map((i) => (
                      <li key={i.referencedId}>
                        {i.referencedId}: {i.message}
                      </li>
                    ))}
                </ul>
              ) : (
                <>{i18n._(t`Geen`)}</>
              ),
          },
        ]}
        loading={status.loading}
        pagination={false}
        rowKey="id"
      />
    );
  }
);

export default OrnImportReportTable;
