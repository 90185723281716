import {
  DeleteOutlined,
  EditOutlined,
  SendOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import React, { PropsWithChildren, useMemo } from "react";

import { CommercialInstruction } from "../../models/Instructions";
import Order from "../../models/Order";
import FullScreenModal from "../FullScreenModal";
import { getInstructionTitle } from "../InstructionTitle";

export interface InstructionsModalProps {
  instruction: CommercialInstruction;
  order: Order;
  show: boolean;
  canEdit?: boolean;
  canSubmit: boolean;
  isPlanner: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  deleteInstruction: () => void;
  submitInstruction: () => void;
}

const InstructionsModal = ({
  instruction,
  order,
  canEdit,
  canSubmit,
  show,
  openDialog,
  closeDialog,
  deleteInstruction,
  submitInstruction,
  isPlanner,
  children,
}: PropsWithChildren<InstructionsModalProps>): JSX.Element => {
  const { i18n } = useLingui();

  const subOrder = useMemo(
    () => order.subOrders.find((s) => s.id === instruction.subOrderId),
    [instruction.subOrderId, order.subOrders]
  );

  const create = !(instruction.id || instruction.instructionId);

  const canDelete = useMemo(
    () => instruction.status === "Concept" && canEdit,
    [canEdit, instruction.status]
  );

  return (
    <>
      {canEdit && isPlanner ? (
        <>
          {instruction.status === "Concept" && (
            <Popconfirm
              title={i18n._(
                t`Weet je zeker dat je deze uitzendinstructie wilt indienen?`
              )}
              onConfirm={submitInstruction}
              disabled={!canSubmit}
              cancelButtonProps={{ type: "link" }}
            >
              <Tooltip title={i18n._(t`Deze uitzendinstructie indienen`)}>
                <Button
                  type="link"
                  icon={<SendOutlined />}
                  disabled={!canSubmit}
                >
                  {i18n._(t`Indienen`)}
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
          <Tooltip title={i18n._(t`Bewerken`)}>
            <Button icon={<EditOutlined />} type="link" onClick={openDialog} />
          </Tooltip>
          {!create && (
            <Popconfirm
              title={i18n._(
                t`Weet je zeker dat je deze instructie wilt verwijderen?`
              )}
              onConfirm={deleteInstruction}
              cancelButtonProps={{ type: "link" }}
              disabled={!canDelete}
            >
              <Tooltip title={i18n._(t`Verwijderen`)}>
                <Button
                  icon={<DeleteOutlined />}
                  type="link"
                  disabled={!canDelete}
                />
              </Tooltip>
            </Popconfirm>
          )}
        </>
      ) : (
        <Tooltip title={i18n._(t`Details van deze uitzendinstructie bekijken`)}>
          <Button icon={<ZoomInOutlined />} type="link" onClick={openDialog}>
            {" "}
            <Trans>Tonen</Trans>
          </Button>
        </Tooltip>
      )}

      {show && (
        <FullScreenModal
          visible
          cancelButtonProps={{ hidden: canSubmit }}
          onCancel={closeDialog}
          onOk={closeDialog}
          okText={i18n._(t`Sluiten`)}
          okEnabled={canSubmit || instruction.status !== "Concept"}
          title={
            instruction
              ? getInstructionTitle(order, subOrder)
              : i18n._(t`Instructie aanmaken`)
          }
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            {children}
          </Space>
        </FullScreenModal>
      )}
    </>
  );
};

export default InstructionsModal;
