import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { ReactElement } from "react";

interface CancelButtonProps {
  onCancel: () => void;
}

const CancelButton = ({ onCancel }: CancelButtonProps): ReactElement => (
  <Button key="cancelButton" type="link" onClick={onCancel}>
    <Trans>Annuleren</Trans>
  </Button>
);

export default CancelButton;
