import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RequestCallback } from "async-lifecycle-saga";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Operator } from "../../../models/Operator";
import Organisation, { OrganisationType } from "../../../models/Organisation";
import { StoreModel } from "../../../store/models";
import { organisationsCell } from "../../../store/organisations/cells";
import Organisations from "./Organisations";

const emptyOrganisations: Organisation[] = [];

const showResult = (
  i18n: I18n,
  onSuccessCallback?: () => void
): RequestCallback<Organisation> => ({
  onSuccess: (): void => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }

    message.success(i18n._(t`Gelukt`), 1);
  },
  onCancel: (): void => {
    message.info(i18n._(t`Afgebroken`), 1);
  },
  onFail: (): void => {
    message.error(i18n._(t`Er is iets misgegaan`), 1);
  },
});

const OrganisationsContainer = memo(() => {
  const { i18n } = useLingui();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [form] = useForm<Organisation>();
  const { validateFields } = form;
  const dispatch = useDispatch();

  /**
   * Get the organisations from the store.
   */
  const {
    value: organisations = emptyOrganisations,
    status: { loading: organisationsLoading },
  } = useSelector(({ organisations: all }: StoreModel) => all);

  const deleteOrganisation = useCallback(
    /**
     * Requests deletion of an existing organisation.
     * @param id The unique identifier of the organisation.
     */
    ({ id }: Organisation) => {
      dispatch(organisationsCell.delete({ id }, showResult(i18n)));
    },
    [dispatch, i18n]
  );

  /**
   * Dispatch a request to load all organisations from the API.
   */
  const loadAllOrganisations = useCallback(() => {
    dispatch(organisationsCell.require());
  }, [dispatch]);

  const handleChange = useCallback(
    /**
     * Whenever a value of the form's fields changes, determine whether the save button should be enabled.
     */
    () => {
      validateFields()
        .then(() => {
          setSaveEnabled(true);
        })
        .catch(() => {
          setSaveEnabled(false);
        });
    },
    [validateFields]
  );

  const handleSelectTypeChange = useCallback(
    (value: OrganisationType) => {
      form.setFieldsValue({ type: value });
      handleChange(); // trigger explicit change for `<Select />`
    },
    [form, handleChange]
  );

  const onSuccess = useCallback(() => {
    // trigger form reset
    setResetForm(true);
  }, []);

  const handleFinish = useCallback(
    /**
     * The form has been validated and submitted, now we need to take care of the form's values.
     * @param values The form's validated values.
     */
    (values: Store) => {
      const transformedValues: Organisation = {
        ...(values as Organisation),
        logins: values.logins // Form uses 'flat' array of only operator strings.
          ? (values.logins as Operator[]).map((operator: Operator) => ({
              operator,
              hasPassword: false,
            }))
          : [],
      };

      if (values.id) {
        dispatch(
          organisationsCell.update(
            transformedValues,
            showResult(i18n, onSuccess)
          )
        );
      } else {
        const { name, code, logins, isKnownByOperators, type } =
          transformedValues;
        dispatch(
          organisationsCell.create(
            { name, code, logins, isKnownByOperators, type },
            showResult(i18n, onSuccess)
          )
        );
      }
    },
    [dispatch, i18n, onSuccess]
  );

  /**
   * On first load, fetch the organisations from the API and put them in the store.
   */
  useEffect(() => {
    loadAllOrganisations();
  }, [loadAllOrganisations]);

  return (
    <Form form={form} onChange={handleChange} onFinish={handleFinish}>
      <Organisations
        form={form}
        onDelete={deleteOrganisation}
        organisations={organisations.sort((a, b) =>
          a.name.localeCompare(b.name)
        )}
        organisationsLoading={organisationsLoading}
        resetForm={resetForm}
        setReset={setResetForm}
        saveEnabled={saveEnabled}
        onTypeChange={handleSelectTypeChange}
      />
    </Form>
  );
});

export default OrganisationsContainer;
