import { Col, Row } from "antd";
import React, { Children, PropsWithChildren, memo } from "react";

const OrderRequestsOverviewContent = memo(
  ({ children }: PropsWithChildren<unknown>) => (
    <section className="tip-orderRequests-overview">
      {children &&
        Children.map(children, (child) =>
          child ? (
            <Row>
              <Col span={24}>{child}</Col>
            </Row>
          ) : null
        )}
    </section>
  )
);

export default OrderRequestsOverviewContent;
