import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import React, { memo, useCallback } from "react";

import { PreferredPositionSelectionMode } from "../../../../../models/Campaigns/Requests/models";
import {
  PreferredPosition,
  preferredPositionBeforeLastInBlock,
  preferredPositionFirstInBlock,
  preferredPositionLastInBlock,
  preferredPositionNone,
  preferredPositionSecondInBlock,
} from "../../../../../models/Spot";
import PreferredPositionPickerView from "../../../../../views/campaigns/requests/subOrders/fields/PreferredPositionPickerView";

interface PreferredPositionPickerProps {
  enabled: boolean;
  mode?: PreferredPositionSelectionMode;
  onChange: (uid: string, values: PreferredPosition[]) => void;
  preferredPositionsFree?: PreferredPosition[];
  uniqueId: string;
  value?: PreferredPosition[];
}

const PreferredPositionPicker = memo(
  ({
    enabled,
    mode = "Multiple",
    onChange,
    preferredPositionsFree = [
      preferredPositionNone,
      preferredPositionFirstInBlock,
      preferredPositionSecondInBlock,
      preferredPositionBeforeLastInBlock,
      preferredPositionLastInBlock,
    ],
    uniqueId,
    value,
  }: PreferredPositionPickerProps) => {
    const handlePreferredPositionChangeSingle = useCallback(
      (e: RadioChangeEvent) => {
        onChange(uniqueId, e.target.value || "");
      },
      [onChange, uniqueId]
    );

    const handlePreferredPositionChangeMultiple = useCallback(
      (values: CheckboxValueType[]) => {
        onChange(
          uniqueId,
          values.map((v) => v as PreferredPosition)
        );
      },
      [onChange, uniqueId]
    );

    return (
      <PreferredPositionPickerView
        enabled={enabled}
        mode={mode}
        onChangeMultiple={handlePreferredPositionChangeMultiple}
        onChangeSingle={handlePreferredPositionChangeSingle}
        preferredPositionsFree={preferredPositionsFree}
        value={value}
      />
    );
  }
);

export default PreferredPositionPicker;
