import { combineReducers } from "redux";

import { asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";
import {
  CommercialDeliveriesStatisticsForYearRequest,
  CommercialDeliveriesStatisticsForYearResponse,
  OrganisationLoginStatisticsForYearRequest,
  OrganisationLoginStatisticsForYearResponse,
  OrganisationRequestStatisticsForYearRequest,
  OrganisationRequestStatisticsForYearResponse,
  UserLoginByOrganisationStatisticsForYearRequest,
  UserLoginByOrganisationStatisticsForYearResponse,
} from "./models";

export default combineReducers({
  organisationLoginsForYear: asyncReducer<
    OrganisationLoginStatisticsForYearRequest,
    OrganisationLoginStatisticsForYearResponse
  >(sagaTypes.statistics.organisationLoginsForYear),
  organisationRequestsForYear: asyncReducer<
    OrganisationRequestStatisticsForYearRequest,
    OrganisationRequestStatisticsForYearResponse
  >(sagaTypes.statistics.organisationRequestsForYear),
  commercialDeliveriesForYear: asyncReducer<
    CommercialDeliveriesStatisticsForYearRequest,
    CommercialDeliveriesStatisticsForYearResponse
  >(sagaTypes.statistics.commercialDeliveriesForYear),
  userLoginsByOrganisationForYear: asyncReducer<
    UserLoginByOrganisationStatisticsForYearRequest,
    UserLoginByOrganisationStatisticsForYearResponse
  >(sagaTypes.statistics.userLoginsByOrganisationForYear),
});
