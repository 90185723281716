import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, DatePicker, Form, Select, Typography } from "antd";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  FunctionComponent,
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Link } from "react-router-dom";

import { getAdvertiserName } from "../../../utils";
import navigationPaths from "../../../utils/navigation";
import FilterActions from "../../../views/campaigns/FilterActions";
import ShowButton from "../../actions/ShowButton";
import {
  FilterProps,
  filterAdvertiserIdFieldName,
  filterPeriodFieldName,
} from "./models";

const Filter: FunctionComponent<FilterProps> = ({
  form,
  advertisers,
  loading,
  onPeriodChange,
  onQuery,
  triggerQuery,
}) => {
  const { getFieldValue } = form;
  const { i18n } = useLingui();

  const handlePeriodChange = useCallback(
    (dates: RangeValue<Moment>) => {
      const period = dates as [Moment, Moment];
      onPeriodChange(period);
    },
    [onPeriodChange]
  );

  useEffect(() => {
    const period = getFieldValue(filterPeriodFieldName) as [Moment, Moment];
    onPeriodChange(period);
  }, [getFieldValue, onPeriodChange]);

  useEffect(() => {
    if (triggerQuery) {
      const advertiserId = getFieldValue(filterAdvertiserIdFieldName) as
        | string
        | undefined;
      const period = getFieldValue(filterPeriodFieldName) as [Moment, Moment];
      onQuery({ advertiserId, period });
    }
  }, [getFieldValue, onQuery, triggerQuery]);

  /**
   * Tracks whether show should be enabled.
   */
  const showDisabled = useMemo(
    () => !advertisers || loading,
    [advertisers, loading]
  );

  return (
    <>
      <Form.Item
        label={i18n._(t`Periode`)}
        name={filterPeriodFieldName}
        required
      >
        <DatePicker.RangePicker
          format="D MMM YYYY"
          onChange={handlePeriodChange}
          ranges={{
            [i18n._(t`Vorige maand`)]: [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
            [i18n._(t`Huidige maand`)]: [
              moment().startOf("month"),
              moment().endOf("month"),
            ],
            [i18n._(t`Volgende maand`)]: [
              moment().add(1, "month").startOf("month"),
              moment().add(1, "month").endOf("month"),
            ],
          }}
        />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Adverteerder(s)`)}
        name={filterAdvertiserIdFieldName}
      >
        <Select showSearch optionFilterProp="title">
          <Select.Option key="empty" value="" disabled>
            <Typography.Text>
              <Trans>Maak een selectie</Trans>
            </Typography.Text>
          </Select.Option>
          {advertisers.map(({ id }) => (
            <Select.Option
              key={id}
              value={id}
              title={getAdvertiserName(advertisers, id)}
            >
              {getAdvertiserName(advertisers, id)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate>
        {(): ReactElement => (
          <FilterActions>
            <ShowButton disabled={showDisabled} showIcon />

            <Link to={navigationPaths.CampaignsRequestsCreateNew}>
              <Button type="default">
                <Trans>Nieuwe aanvraag</Trans>
              </Button>
            </Link>

            <Link to={navigationPaths.CampaignsRequestsConcept}>
              <Button type="default">
                <Trans>Concept aanvragen</Trans>
              </Button>
            </Link>
          </FilterActions>
        )}
      </Form.Item>
    </>
  );
};

export default memo(Filter);
