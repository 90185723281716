import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  memo,
  useMemo,
} from "react";
import { ScrollTo } from "react-scroll-to";

import {
  CampaignSubOrderValues,
  emptyValue,
  fieldProductName,
} from "../../../../models/Campaigns/Requests/models";
import propertyOf from "../../../../utils/properties";
import PackageView from "../../../../views/campaigns/PackageView";
import OrderRequestStatusView from "../../../../views/campaigns/requests/OrderRequestStatusView";
import SubOrderTotalBudget from "../../../../views/campaigns/requests/subOrders/SubOrderTotalBudget";
import Ellipsis from "../../../../views/Ellipsis";
import Euro from "../../../../views/Euro";
import Grps from "../../../../views/Grps";
import NotAvailable from "../../../../views/NotAvailable";
import { OperatorAvatar } from "../../../../views/OperatorIcon";
import PeriodView from "../../../../views/PeriodView";
import { fieldSubOrdersName } from "../constants";
import SubOrderActionsDropdown from "./fields/SubOrderActionsDropdown";
import { SubOrdersTableProps } from "./models";

/**
 * Renders a table that displays an order's suborders.
 * @constructor Initializes a new instance of SubOrdersTable.
 * @returns {ReactElement<any, any> | null} The rendered component.
 */
const SubOrdersTable: FunctionComponent<SubOrdersTableProps> = ({
  enableEdit,
  loading,
  onAddSubOrder,
  onDeleteSubOrder,
  onDuplicateSubOrder,
  onEditSubOrder,
  onDeleteRequest,
  onFinishRequest,
  products,
  packages,
  hasConcepts,
  onExport,
  isDownloading,
}) => {
  const { i18n } = useLingui();

  const columns = useMemo(
    (): ColumnProps<CampaignSubOrderValues>[] => [
      {
        key: "operator",
        title: null,
        dataIndex: propertyOf<CampaignSubOrderValues>("operator"),
        width: 50,
        align: "center",
        render: (_text, { operator }): ReactNode =>
          operator ? <OperatorAvatar operator={operator} invert /> : null,
      },
      {
        key: "product",
        title: i18n._(t`Product`),
        dataIndex: fieldProductName,
        width: 200,
        ellipsis: true,
        render: (_text, { product }): ReactNode => {
          const selectedProduct = products?.find((p) => p.id === product);
          return (
            <Ellipsis
              text={
                selectedProduct
                  ? `${selectedProduct.id.split(":")[1]}: ${
                      selectedProduct.description
                    }`
                  : i18n._(emptyValue)
              }
            />
          );
        },
      },
      {
        key: "period",
        title: i18n._(t`Periode`),
        dataIndex: propertyOf<CampaignSubOrderValues>("subOrderPeriod"),
        width: 200,
        render: (_text, { subOrderPeriod }): ReactNode =>
          subOrderPeriod ? (
            <PeriodView
              from={subOrderPeriod[0]?.toDate()}
              to={subOrderPeriod[1]?.toDate()}
              type="short"
            />
          ) : null,
      },
      {
        key: "pkg",
        title: i18n._(`Pakket`),
        dataIndex: propertyOf<CampaignSubOrderValues>("pkg"),
        width: 200,
        ellipsis: true,
        render: (_text, { pkg, spotLengths }): ReactNode => (
          <PackageView
            packageCode={pkg}
            packages={packages}
            spotLengths={spotLengths}
          />
        ),
      },
      {
        key: "targetGroup",
        title: i18n._(`Doelgroep`),
        dataIndex: propertyOf<CampaignSubOrderValues>("targetGroup"),
        width: 200,
        ellipsis: true,
        render: (_text, { pkg, targetGroup }): ReactNode => (
          <Ellipsis
            text={
              packages
                ?.find((p) => p.code === pkg)
                ?.details?.targetGroups?.find((tg) => tg.id === targetGroup)
                ?.description ||
              targetGroup ||
              i18n._(emptyValue)
            }
          />
        ),
      },
      {
        key: "grps",
        title: (
          <Tooltip
            placement="bottom"
            title={i18n._(
              t`GRP's zijn een inschatting op basis van ingevoerd budget en gehanteerde netto GRP prijs`
            )}
          >
            <span>{i18n._(t`GRP's`)}</span>
          </Tooltip>
        ),
        dataIndex: propertyOf<CampaignSubOrderValues>("grps"),
        render: (_text, { grps }): ReactNode =>
          grps ? (
            <Grps amount={grps} showSuffix isPredicted />
          ) : (
            <Typography.Text>{i18n._(emptyValue)}</Typography.Text>
          ),
      },
      {
        key: "budget",
        title: i18n._(t`Budget`),
        dataIndex: propertyOf<CampaignSubOrderValues>("budget"),
        render: (_text, { budget }): ReactNode =>
          budget ? <Euro amount={budget} /> : <NotAvailable />,
      },
      {
        key: "submitted",
        title: i18n._(t`Status`),
        dataIndex: propertyOf<CampaignSubOrderValues>("requestStatus"),
        render: (_text, { requestStatus }): ReactNode => (
          <OrderRequestStatusView status={requestStatus} />
        ),
      },
      {
        key: "edit",
        title: i18n._(t`Acties`),
        render: (_text, { requestStatus }, index): ReactNode => (
          <ScrollTo>
            {({ scroll }): JSX.Element => (
              <SubOrderActionsDropdown
                enableEdit={enableEdit}
                requestStatus={requestStatus}
                index={index}
                onDelete={onDeleteSubOrder}
                onDuplicate={onDuplicateSubOrder}
                onEdit={(i): void => {
                  onEditSubOrder(i);
                  window.setTimeout(
                    () => scroll({ y: window.outerHeight, smooth: true }),
                    256
                  );
                }}
              />
            )}
          </ScrollTo>
        ),
      },
    ],
    [
      enableEdit,
      i18n,
      onDeleteSubOrder,
      onDuplicateSubOrder,
      onEditSubOrder,
      packages,
      products,
    ]
  );

  return (
    <>
      <Form.Item name={fieldSubOrdersName} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }): ReactElement => {
          const subOrders = getFieldValue(
            fieldSubOrdersName
          ) as CampaignSubOrderValues[];

          return (
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={subOrders.map((s, index) => ({
                    ...s,
                    key: `${s.pkg}+${s.costsType}+${index}`,
                  }))}
                  loading={loading}
                  pagination={false}
                  footer={(): ReactNode => (
                    <SubOrderTotalBudget
                      subOrderBudgets={subOrders.map((s) => s.budget || 0)}
                    />
                  )}
                />
              </Col>
            </Row>
          );
        }}
      </Form.Item>

      <Row justify="end" gutter={[16, 16]}>
        <Col flex="auto" />
        <Col>
          <Space>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }): ReactElement => {
                const subOrders = getFieldValue(
                  fieldSubOrdersName
                ) as CampaignSubOrderValues[];
                const enableDownload = subOrders && subOrders.length > 0;

                return (
                  <Button
                    type="default"
                    disabled={!enableDownload}
                    onClick={onExport}
                    loading={isDownloading}
                  >
                    <Trans>Downloaden</Trans>
                  </Button>
                );
              }}
            </Form.Item>
            {enableEdit && (
              <Popconfirm
                title={i18n._(
                  t`Weet je zeker dat je de aanvraag wil verwijderen?`
                )}
                onConfirm={onDeleteRequest}
                cancelButtonProps={{ type: "link" }}
              >
                <Button type="primary" disabled={!enableEdit}>
                  <Trans>Verwijderen</Trans>
                </Button>
              </Popconfirm>
            )}
            {hasConcepts && (
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }): ReactElement => {
                  const subOrders = getFieldValue(
                    fieldSubOrdersName
                  ) as CampaignSubOrderValues[];
                  const enableSubmission =
                    enableEdit &&
                    subOrders &&
                    subOrders.length > 0 &&
                    hasConcepts;

                  return (
                    <Popconfirm
                      title={i18n._(
                        t`Weet je zeker dat je de aanvraag wil indienen?`
                      )}
                      onConfirm={onFinishRequest}
                      cancelButtonProps={{ type: "link" }}
                    >
                      <Button type="primary" disabled={!enableSubmission}>
                        <Trans>Indienen</Trans>
                      </Button>
                    </Popconfirm>
                  );
                }}
              </Form.Item>
            )}
            <ScrollTo>
              {({ scroll }): JSX.Element => (
                <Button
                  type="primary"
                  onClick={(): void => {
                    onAddSubOrder();
                    window.setTimeout(
                      () => scroll({ y: window.outerHeight, smooth: true }),
                      256
                    );
                  }}
                  disabled={!enableEdit}
                >
                  <Trans>Toevoegen</Trans>
                </Button>
              )}
            </ScrollTo>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default memo(SubOrdersTable);
