import { Moment } from "moment";

import {
  CommercialMatchInput,
  DashboardCommercial,
  DashboardOrder,
  DashboardOrderList,
  DashboardTile,
  DashboardTileData,
  DashboardTileInput,
  SpotInput,
  SpotWithTargetGroup,
} from "../../models/Dashboard";
import User from "../../models/User";
import { formatDateForUri } from "../../utils/date";
import { deleter, getter, jsonDownloader, poster } from "../fetch";

export const dashboardOrderListGetter = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: Moment;
  dateTo: Moment;
}) =>
  getter<DashboardOrderList[]>(
    `/api/dashboard/orderlist?dateFrom=${formatDateForUri(
      dateFrom
    )}&dateTo=${formatDateForUri(dateTo)}`,
    false
  );

export const dashboardDataGetter = ({
  dateFrom,
  dateTo,
  omitExtras,
}: {
  dateFrom: Moment;
  dateTo: Moment;
  omitExtras?: boolean;
}) => {
  const url = omitExtras
    ? `/api/dashboard?dateFrom=${formatDateForUri(
        dateFrom
      )}&dateTo=${formatDateForUri(dateTo)}&omitExtras=${omitExtras}`
    : `/api/dashboard?dateFrom=${formatDateForUri(
        dateFrom
      )}&dateTo=${formatDateForUri(dateTo)}`;
  return getter<DashboardTileData[]>(url, false);
};

export const dashboardTilesGetter = () =>
  getter<User, DashboardTile[]>(
    "/api/user",
    false,
    (user) => user.dashboardTiles ?? []
  );

export const dashboardTileAdd = (tile: DashboardTileInput) =>
  poster<DashboardTileInput, DashboardTileInput, DashboardTile>(
    "/api/dashboard/add",
    tile
  );

export const dashboardTileRemove = ({ id }: { id: string }) =>
  deleter<DashboardTile, void>(`/api/dashboard/remove/${id}`);

export const dashboardCommercials = (matchInput: CommercialMatchInput) =>
  poster<CommercialMatchInput, CommercialMatchInput, DashboardCommercial[]>(
    "/api/dashboard/commercials",
    matchInput
  );

export const dashboardSpots = (spotInput: SpotInput) =>
  poster<SpotInput, SpotInput, SpotWithTargetGroup[]>(
    "/api/dashboard/spots",
    spotInput
  );

export const getInvoiceBlob = ({ id }: { id: string }) =>
  jsonDownloader<Blob>(`/api/dashboard/invoice/${id}`);

export const dashboardNotUsedOrdersGetter = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: Moment;
  dateTo: Moment;
}) =>
  getter<DashboardOrder[]>(
    `/api/dashboard/notused?dateFrom=${formatDateForUri(
      dateFrom
    )}&dateTo=${formatDateForUri(dateTo)}`,
    false
  );
