import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { DatePicker, Form } from "antd";
import moment, { Moment } from "moment";
import React, { memo, useCallback } from "react";

import { fieldSubOrderPeriodName } from "../../../../../models/Campaigns/Requests/models";
import { getFirstBookingDay } from "../../../../../utils/date";
import { labelCol, wrapperCol } from "../../../../../views/layout/Form";
import { SubOrderPeriodPickerProps } from "../models";

const SubOrderPeriodPicker = memo(
  ({ enabled, orderPeriod }: SubOrderPeriodPickerProps) => {
    const { i18n } = useLingui();

    const firstBookingDay = getFirstBookingDay();
    const handleDisabledDate = useCallback(
      (current: Moment | null) =>
        Boolean(
          current &&
            orderPeriod &&
            orderPeriod[0] &&
            orderPeriod[1] &&
            (current < firstBookingDay ||
              current < orderPeriod[0] ||
              current > orderPeriod[1])
        ),
      [firstBookingDay, orderPeriod]
    );

    return (
      <Form.Item
        label={i18n._(t`Periode`)}
        labelCol={labelCol}
        name={fieldSubOrderPeriodName}
        rules={[{ required: true, message: i18n._(t`Periode is verplicht.`) }]}
        wrapperCol={wrapperCol}
      >
        <DatePicker.RangePicker
          disabledDate={handleDisabledDate}
          format="D MMM YYYY"
          ranges={{ Order: orderPeriod || [moment(), moment()] }}
          disabled={!enabled}
        />
      </Form.Item>
    );
  }
);

export default SubOrderPeriodPicker;
