import { Form } from "antd";
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { fieldSpotLengthsName } from "../../../../../models/Campaigns/Requests/models";
import { Package } from "../../../../../models/packages";
import VisibleConditional from "../../../../../views/layout/VisibleConditional";
import { ornPackageCodeSpecific } from "../constants";
import DayOfWeekPicker from "../fields/DayOfWeekPicker";
import PackagePicker from "../fields/PackagePicker";
import SpotLengthsPicker from "../fields/SpotLengthsPicker";
import SubOrderPeriodPicker from "../fields/SubOrderPeriodPicker";
import TargetGroupPicker from "../fields/TargetGroupPicker";
import {
  DetailsStepReferenceValues,
  DetailsStepStoreValues,
  StepProps,
} from "./models";

const DetailsStep: FunctionComponent<
  StepProps<undefined, DetailsStepReferenceValues, DetailsStepStoreValues>
> = ({
  enabled,
  form,
  initialValues,
  onFieldsChange,
  onFinish,
  referenceValues: {
    orderPeriod,
    packages,
    selectedOperator,
    spotLengthIndices,
  },
}: StepProps<
  undefined,
  DetailsStepReferenceValues,
  DetailsStepStoreValues
>) => {
  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>();

  const handlePackagePickerChange = useCallback(
    /**
     * Handles a change of the selected package.
     * @param pkg The newly selected package.
     */
    (pkg: string) => {
      setSelectedPackage(packages.find((p) => p.code === pkg));
    },
    [packages]
  );

  const daysOfWeekSelectionRequired = useMemo(
    () =>
      selectedPackage?.operator === "Orn" &&
      selectedPackage?.code === ornPackageCodeSpecific,
    [selectedPackage]
  );

  const pkg = form.getFieldValue("pkg");
  useEffect(() => {
    if (pkg && !selectedPackage) {
      setSelectedPackage(packages.find((p) => p.code === pkg));
    }
  }, [form, packages, pkg, selectedPackage]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFieldsChange={onFieldsChange}
      onFinish={onFinish}
    >
      <>
        <SubOrderPeriodPicker enabled={enabled} orderPeriod={orderPeriod} />
        <PackagePicker
          enabled={enabled}
          onChange={handlePackagePickerChange}
          packages={packages}
          selectedOperator={selectedOperator}
        />
        <TargetGroupPicker
          enabled={enabled}
          form={form}
          selectedPackage={selectedPackage}
        />
        <SpotLengthsPicker
          enableButtons
          enabled={enabled}
          fieldName={fieldSpotLengthsName}
          form={form}
          spotLengthIndices={spotLengthIndices}
        />
        <VisibleConditional
          type="placeholder"
          visible={daysOfWeekSelectionRequired}
        >
          <DayOfWeekPicker
            dayFormat="Minimal"
            enabled={enabled}
            includeWeekend
          />
        </VisibleConditional>
      </>
    </Form>
  );
};

export default memo(DetailsStep);
