import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { MouseEventHandler, memo, useCallback } from "react";

import { Period } from "../../../models/periods";
import { TargetPeriod } from "../../../store/campaigns/requests/models";
import CopyButton from "../../actions/CopyButton";
import DeleteButton from "../../actions/DeleteButton";
import EditButton from "../../actions/EditButton";
import { ItemOnClickEventHandler } from "../../actions/ItemActionButtonProps";

const ButtonBar = memo(
  ({
    id,
    enabledEdit,
    onEdit,
    enabledDelete,
    onDelete,
    enabledExport,
    onExport,
    isExportLoading,
    periods,
    onCopy,
    enableCopy,
  }: {
    id?: string;
    enabledEdit: boolean;
    onEdit: ItemOnClickEventHandler;
    enabledDelete: boolean;
    onDelete: ItemOnClickEventHandler;
    enabledExport: boolean;
    onExport: ItemOnClickEventHandler;
    isExportLoading: boolean;
    periods: Period[];
    onCopy: (sourceId: string, period: TargetPeriod) => void;
    enableCopy: boolean;
  }) => {
    const handleExport = useCallback<MouseEventHandler>(
      (event) => {
        onExport(id, event);
      },
      [id, onExport]
    );

    const handleCopy = useCallback(
      (targetPeriod: TargetPeriod) => {
        if (id) {
          onCopy(id, targetPeriod);
        }
      },
      [id, onCopy]
    );

    return (
      <>
        <EditButton disabled={!enabledEdit} itemId={id} onClick={onEdit} />
        <CopyButton
          periods={periods}
          onCopy={handleCopy}
          disabled={!enableCopy}
        />
        <DeleteButton
          disabled={!enabledDelete}
          itemId={id}
          onClick={onDelete}
        />
        <Button
          type="link"
          onClick={handleExport}
          disabled={!enabledExport}
          loading={isExportLoading}
          size="middle"
        >
          <Trans>Downloaden</Trans>
        </Button>
      </>
    );
  }
);

export default ButtonBar;
