import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, { ReactElement, useCallback } from "react";

interface RemoveButtonProps {
  enabled: boolean;
  onRemove: (key: string) => void;
  rowKey: string;
}

const RemoveButton = ({
  enabled,
  onRemove,
  rowKey,
}: RemoveButtonProps): ReactElement => {
  const handleClick = useCallback(() => {
    onRemove(rowKey);
  }, [onRemove, rowKey]);

  return (
    <Button
      key="removeButton"
      type="link"
      disabled={!enabled}
      onClick={handleClick}
    >
      <Trans>Verwijderen</Trans>
    </Button>
  );
};

export default RemoveButton;
