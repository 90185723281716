import { ExclamationCircleOutlined } from "@ant-design/icons";
import Badge from "antd/lib/badge";
import React, { PropsWithChildren, memo } from "react";

import { colorError } from "../colors";

const WarnBadge = ({
  warn,
  children,
}: PropsWithChildren<{ warn?: boolean }>) => (
  <Badge
    count={
      warn ? (
        <ExclamationCircleOutlined
          style={{ color: colorError, marginRight: -10 }}
        />
      ) : (
        0
      )
    }
  >
    {children}
  </Badge>
);

export default memo(WarnBadge);
