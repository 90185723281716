import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Dropdown, Menu, Modal } from "antd";
import React, { memo, useCallback } from "react";

import { SubOrderActionsDropdownProps } from "../models";

const SubOrderActionsDropdown = memo(
  ({
    enableEdit,
    index,
    onDelete,
    onDuplicate,
    onEdit,
    requestStatus,
  }: SubOrderActionsDropdownProps) => {
    const { i18n } = useLingui();

    const handleDeleteClick = useCallback(() => {
      Modal.confirm({
        title: i18n._(t`Deelorder verwijderen`),
        icon: <DeleteOutlined />,
        content: i18n._(t`Weet je het zeker?`),
        onOk() {
          onDelete(index);
          return Promise.resolve();
        },
      });
    }, [i18n, index, onDelete]);

    const handleDuplicateClick = useCallback(() => {
      onDuplicate(index);
    }, [index, onDuplicate]);

    const handleEditClick = useCallback(() => {
      onEdit(index);
    }, [index, onEdit]);

    return (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="edit"
              disabled={!enableEdit}
              onClick={handleEditClick}
              title={i18n._(t`Bewerken`)}
            >
              <EditOutlined /> <Trans>Bewerken</Trans>
            </Menu.Item>
            <Menu.Item
              key="duplicate"
              disabled={!enableEdit}
              onClick={handleDuplicateClick}
              title={i18n._(t`Dupliceren`)}
            >
              <CopyOutlined /> <Trans>Dupliceren</Trans>
            </Menu.Item>
            <Menu.Item
              key="delete"
              disabled={!enableEdit || requestStatus === "Submitted"}
              onClick={handleDeleteClick}
              title={i18n._(t`Verwijderen`)}
            >
              <DeleteOutlined /> <Trans>Verwijderen</Trans>
            </Menu.Item>
          </Menu>
        }
      >
        <Button type="default">
          <Trans>Acties</Trans> <DownOutlined />
        </Button>
      </Dropdown>
    );
  }
);

export default SubOrderActionsDropdown;
