import { AsyncResponse } from "async-lifecycle-saga";

import Organisation from "../../models/Organisation";
import { deleter, getter, poster, putter } from "../fetch";
import {
  OrganisationCreateRequest,
  OrganisationCreateResponse,
  OrganisationDeleteRequest,
  OrganisationGetAllResponse,
  OrganisationSetPasswordRequest,
  OrganisationUpdateApiRequest,
  OrganisationUpdateRequest,
  OrganisationUpdateResponse,
} from "./models";

export const organisationsAllGetter = (): Promise<
  AsyncResponse<Organisation[]>
> =>
  getter<OrganisationGetAllResponse, Organisation[]>(
    "/api/organisation",
    false,
    ({ organisations }) => organisations
  );

export const organisationCreatePoster = (
  request: OrganisationCreateRequest
): Promise<AsyncResponse<Organisation>> =>
  poster<OrganisationCreateResponse, OrganisationCreateRequest, Organisation>(
    "/api/organisation",
    request,
    false,
    undefined,
    ({ organisation }) => organisation
  );

export const organisationUpdatePutter = (
  request: OrganisationUpdateRequest
): Promise<AsyncResponse<Organisation>> =>
  putter<
    OrganisationUpdateResponse,
    OrganisationUpdateApiRequest,
    Organisation
  >(
    `/api/organisation/${request.id}`,
    { organisation: request },
    false,
    undefined,
    ({ organisation }) => organisation
  );

export const organisationDeleter = (
  request: OrganisationDeleteRequest
): Promise<AsyncResponse<Organisation>> =>
  deleter<Organisation, OrganisationDeleteRequest>(
    `/api/organisation/${request.id}`
  );

export const organisationSetPasswordPutter = (
  request: OrganisationSetPasswordRequest
): Promise<AsyncResponse<never>> =>
  putter<never, { password: string | undefined }>(
    `/api/organisation/${request.organisationId}/${request.operator}`,
    { password: request.password }
  );
