import classNames from "classnames";
import { HTMLProps, ReactNode, createElement, memo } from "react";

type FlexAlign = "center" | "end";

const flexAlignClasses: { [align in FlexAlign]: string } = {
  center: "tip-flex-align-center",
  end: "tip-flex-align-end",
};

interface FlexProps {
  align?: FlexAlign;
  children?: ReactNode;
  type: "placeholder" | "paragraph" | "label" | "text";
}

const Flex = memo(({ align = "center", children, type }: FlexProps) => {
  const className = classNames(
    "tip-flex",
    "tip-flex-wrap",
    flexAlignClasses[align]
  );
  const props: HTMLProps<HTMLElement> = {
    className,
    children,
  };
  let htmlElement: string;
  switch (type) {
    case "paragraph":
      htmlElement = "p";
      break;
    case "placeholder":
      htmlElement = "div";
      break;
    case "label":
      htmlElement = "label";
      break;
    case "text":
    default:
      htmlElement = "span";
      break;
  }
  return createElement(htmlElement, props);
});

export default Flex;
