import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { FormInstance } from "antd/lib/form";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import { FocusEvent } from "react";

import Advertiser from "../../../models/Advertiser";
import {
  CampaignSubOrderValues,
  OrderRequestStatus,
  OrderRequestSubOrder,
  OrderRequestsByAdvertiser,
} from "../../../models/Campaigns/Requests/models";
import { Package } from "../../../models/packages";
import { Period } from "../../../models/periods";
import { Product } from "../../../models/products";
import SpotLengthIndex from "../../../models/SpotLengthIndex";
import { TargetPeriod } from "../../../store/campaigns/requests/models";
import propertyOf from "../../../utils/properties";
import { ItemOnClickEventHandler } from "../../actions/ItemActionButtonProps";

export interface CampaignAdvertiserPickerProps {
  advertiserHasProducts: boolean;
  advertiserHasPackages: boolean;
  advertiserOnlyHasSpecificPackage: boolean;
  advertisers: Advertiser[];
  advertisersLoading: boolean;
  enabled: boolean;
}

export interface CampaignPeriodPickerProps {
  enabled: boolean;
  form: FormInstance;
  periods: Period[];
  periodsLoading: boolean;
}

export interface CampaignNameFieldProps {
  enabled: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export interface FilterFormValues {
  period: [Moment, Moment];
  advertiserId?: string;
}

export const filterPeriodFieldName = propertyOf<FilterFormValues>("period");
export const filterAdvertiserIdFieldName =
  propertyOf<FilterFormValues>("advertiserId");

export interface FilterContainerProps {
  advertisers: Advertiser[];
  loading: boolean;
  onPeriodChange: (newPeriod: [Moment, Moment]) => void;
  onQuery: (filterValues: FilterFormValues) => void;
  triggerQuery: boolean;
}

export interface FilterProps extends FilterContainerProps {
  form: FormInstance;
}

export interface OrderRequestSubmissionModalFormValues {
  sendConfirmation: boolean;
}

export interface OrderRequestSubmissionModalProps
  extends FormComponentProps<OrderRequestSubmissionModalFormValues> {
  loading: boolean;
  onCancel: () => void;
  onConfirm: (sendConfirmation: boolean) => void;
  visible: boolean;
}

export interface OrderRequestCallbackProps {
  onCreate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  onSubmit: () => void;
}

export const subOrdersToFormValues = (
  subOrders: OrderRequestSubOrder[]
): CampaignSubOrderValues[] =>
  subOrders.map(
    ({
      operator,
      product,
      startDate: soStartDate,
      endDate: soEndDate,
      package: pkg,
      targetGroup,
      subOrderRequestLengths,
      daysOfWeek,
      budget,
      grps,
      spots,
      remarks,
      requestStatus,
    }) => ({
      operator,
      product: product.id,
      subOrderPeriod: [moment(soStartDate), moment(soEndDate)],
      pkg: pkg.code,
      targetGroup: targetGroup.id,
      intomartId: targetGroup.intomartId,
      spotLengths: subOrderRequestLengths.map((sorl) => sorl.spotLength),
      daysOfWeek,
      budget,
      grps,
      spots,
      remarks,
      requestStatus,
    })
  );

export interface OrderRequestWizardProps {
  advertisers: Advertiser[];
  advertisersLoading: boolean;
  campaignForm: FormInstance;
  onAdvertiserIdChanged: (advertiserId: string) => void;
  onPeriodChanged: (period: RangeValue<Moment>) => void;
  packages: Package[];
  packagesLoading: boolean;
  periods: Period[];
  periodsLoading: boolean;
  products: Product[];
  productsLoading: boolean;
  spotLengthIndices: SpotLengthIndex[];
  subOrders: CampaignSubOrderValues[];
  hasConcepts?: boolean;
}

export interface SubOrderCallbackProps {
  onAddSubOrder: () => void;
  onDeleteSubOrder: (index: number) => void;
  onDuplicateSubOrder: (index: number) => void;
  onEditSubOrder: (index: number) => void;
}

export type OrderRequestWizardState =
  | "SelectCampaign"
  | "ComposeOrder"
  | "ComposeSubOrder";

export interface OverviewProps {
  loading: boolean;
  onDelete: ItemOnClickEventHandler;
  onEdit: ItemOnClickEventHandler;
  orderRequestsByAdvertiserItems: OrderRequestsByAdvertiser[];
  onExport: ItemOnClickEventHandler;
  isDownloading: boolean;
  periods: Period[];
  onCopy: (id: string, period: TargetPeriod) => void;
}

export type RequestStatusEditableMap = {
  [status in OrderRequestStatus]: boolean;
};
