import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Col } from "antd";
import { flow, map, orderBy } from "lodash/fp";
import React, { ReactNode } from "react";

import BudgetPieChart from "./BudgetPieChart";
import ChartDot from "./ChartDot";
import DashboardDataContainer from "./DashboardDataContainer";
import GrpBarChart from "./GrpBarChart";

export interface DashboardDataProps<TData> {
  title: string | ReactNode;
  data: TData[];
  renderTotal?: (d: TData[]) => ReactNode;
  labelGetter: (data: TData) => string;
  nameGetter: (data: TData) => string;
  valueGetter: (data: TData) => number;
  renderValue: (value: number) => ReactNode;
  chartType: "pie" | "bar";
}

const DashboardDataItem = <TData,>({
  title,
  data,
  renderTotal,
  labelGetter,
  nameGetter,
  valueGetter,
  renderValue,
  chartType,
}: DashboardDataProps<TData>) => {
  const { i18n } = useLingui();
  if (data.length === 0) {
    return null;
  }

  return (
    <DashboardDataContainer title={title}>
      <Col span={12}>
        {chartType === "pie" && (
          <BudgetPieChart
            data={data}
            labelGetter={labelGetter}
            nameGetter={nameGetter}
            valueGetter={valueGetter}
            renderValue={renderValue}
          />
        )}
        {chartType === "bar" && (
          <GrpBarChart
            data={data}
            labelGetter={labelGetter}
            nameGetter={nameGetter}
            valueGetter={valueGetter}
            renderValue={renderValue}
          />
        )}
      </Col>
      <Col span={12}>
        <table>
          <tbody>
            {renderTotal && (
              <tr>
                <th>{i18n._(t`Totaal`)}</th>
                <td>{renderTotal(data)}</td>
                <td>&#160;</td>
              </tr>
            )}
            {flow(
              orderBy([(d: TData) => labelGetter(d)], ["asc"]),
              map((item: TData) => (
                <tr key={nameGetter(item)}>
                  <th style={{ width: 100 }}>{labelGetter(item)}</th>
                  <td>{renderValue(valueGetter(item))}</td>
                  <td>
                    <ChartDot colorKeyword={nameGetter(item)} />
                  </td>
                </tr>
              ))
            )(data)}
          </tbody>
        </table>
      </Col>
    </DashboardDataContainer>
  );
};

export default DashboardDataItem;
