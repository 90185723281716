import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Empty, Popconfirm, Space, Table, Tooltip } from "antd";
import React from "react";

import { CalendarEvent } from "../../../models/Calendar";
import { CalendarEventDate } from "../../calendar/CalendarEventContent";
import Ellipsis from "../../Ellipsis";

export interface CalendarManagementTableProps {
  events: CalendarEvent[];
  loading: boolean;
  editEvent: (e: CalendarEvent) => void;
  deleteEvent: (eventId: string) => void;
}

const CalendarManagementTable = ({
  events,
  loading,
  editEvent,
  deleteEvent,
}: CalendarManagementTableProps) => {
  const { i18n } = useLingui();
  return (
    <Table
      dataSource={events}
      columns={[
        {
          title: i18n._(t`Datum`),
          key: "date",
          width: 350,
          render: (e: CalendarEvent) => (
            <CalendarEventDate event={e} i18n={i18n} />
          ),
        },
        {
          title: i18n._(t`Titel`),
          key: "title",
          ellipsis: true,
          render: ({ title }: CalendarEvent) => <Ellipsis text={title} />,
        },
        {
          title: i18n._(t`Omschrijving`),
          key: "description",
          ellipsis: true,
          render: ({ description }: CalendarEvent) =>
            description ? <Ellipsis text={description} /> : null,
        },
        {
          title: i18n._(t`Locatie`),
          key: "location",
          ellipsis: true,
          render: ({ location }: CalendarEvent) =>
            location ? <Ellipsis text={location} /> : null,
        },
        {
          title: i18n._(t`Acties`),
          key: "actions",
          width: 100,
          render: (calendarEvent: CalendarEvent) => (
            <Space>
              <Tooltip title={i18n._(t`Bewerken`)}>
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => {
                    editEvent(calendarEvent);
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={i18n._(
                  t`Weet je zeker dat je dit evenement wilt verwijderen?`
                )}
                onConfirm={() => {
                  if (calendarEvent.id) {
                    deleteEvent(calendarEvent.id);
                  }
                }}
                cancelButtonProps={{ type: "link" }}
              >
                <Tooltip title={i18n._(t`Verwijderen`)}>
                  <Button type="link" icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Space>
          ),
        },
      ]}
      loading={loading}
      pagination={false}
      rowKey={({ id }): string => id ?? "unknown"}
      locale={{
        emptyText: (
          <Empty
            description={i18n._(
              t`Geen evenementen gevonden in de opgegeven periode.`
            )}
          />
        ),
      }}
    />
  );
};

export default CalendarManagementTable;
