import { PhoneOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, FormInstance, Input } from "antd";
import { startsWith } from "lodash";
import { phone } from "phone";
import React from "react";

import { labelCol, wrapperColNoLabel, wrapperColShallow } from "../layout/Form";

export interface UserProfileFormInput {
  name: string;
  emailAddress: string;
  organisation: string;
  phoneNumber?: string | null;
}

export interface UserProfileFormProps {
  form: FormInstance<UserProfileFormInput>;
  onFinish: (values: UserProfileFormInput) => void;
  values: UserProfileFormInput;
  loading: boolean;
}

const UserProfileForm = ({
  form,
  onFinish,
  values: { name, emailAddress, organisation, phoneNumber },
  loading,
}: UserProfileFormProps) => {
  const { i18n } = useLingui();

  return (
    <Form
      form={form}
      labelCol={labelCol}
      wrapperCol={wrapperColShallow}
      onFinish={onFinish}
      initialValues={{ name, emailAddress, organisation, phoneNumber }}
    >
      <Form.Item
        label={i18n._(t`Naam`)}
        name="name"
        rules={[{ required: true, message: i18n._(t`Naam is verplicht.`) }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={i18n._(t`E-mailadres`)}
        name="emailAddress"
        rules={[
          {
            required: true,
            type: "email",
            message: i18n._(t`E-mailadres is verplicht.`),
          },
        ]}
      >
        <Input readOnly bordered={false} />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Organisatie`)}
        name="organisation"
        rules={[
          {
            required: true,
            message: i18n._(t`Organisatie is verplicht.`),
          },
        ]}
      >
        <Input readOnly bordered={false} />
      </Form.Item>
      <Form.Item
        label={i18n._(t`Telefoonnummer`)}
        name="phoneNumber"
        rules={[
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }

              const international = startsWith(value, "+");
              const parsed = phone(
                value,
                !international ? { country: "NL" } : undefined
              );
              if (parsed.isValid) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error(
                  i18n._(
                    t`Telefoonnummer is in een onjuist formaat. Gebruik indien nodig het internationale formaat.`
                  )
                )
              );
            },
          },
        ]}
      >
        <Input suffix={<PhoneOutlined />} />
      </Form.Item>
      <Form.Item wrapperCol={wrapperColNoLabel}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          {i18n._(t`Profiel opslaan`)}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserProfileForm;
