import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Button, Col, Form, Input, Row, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { ReactNode, memo, useEffect } from "react";

import { passwordField, usernameField } from "../../store/models";
import { getUsername } from "../../store/token";
import ForgotPasswordButton from "../../views/authentication/ForgotPasswordButton";
import CenteredBlock from "../../views/CenteredBlock";
import { wrapperColNoLabel } from "../../views/layout/Form";
import NotificationMessage from "../management/notifications/NotificationMessage";

export type LoginState = "Default" | "Error" | "Invalid";

interface LoginProps {
  form: FormInstance;
  loading: boolean;
  state: LoginState;
}

const Login = ({ form, loading, state }: LoginProps) => {
  const { i18n } = useLingui();
  const username = getUsername();
  const { setFieldsValue } = form;

  useEffect(() => {
    if (username) {
      setFieldsValue({ [usernameField]: username, [passwordField]: "" });
    } else {
      setFieldsValue({ [passwordField]: "" });
    }
  }, [setFieldsValue, username]);

  return (
    <Spin spinning={loading}>
      <Row>
        <Col xs={24}>
          <Form.Item
            label={i18n._(t`Gebruikersnaam`)}
            name={usernameField}
            rules={[
              {
                required: true,
                message: i18n._(t`Gebruikersnaam is verplicht.`),
              },
            ]}
          >
            <Input
              autoFocus
              prefix={<UserOutlined />}
              placeholder={i18n._(t`Gebruikersnaam`)}
            />
          </Form.Item>
          <Form.Item
            label={i18n._(t`Wachtwoord`)}
            name={passwordField}
            rules={[
              {
                required: true,
                message: i18n._(t`Wachtwoord is verplicht.`),
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={i18n._(t`Wachtwoord`)}
            />
          </Form.Item>
          <Form.Item wrapperCol={wrapperColNoLabel} shouldUpdate>
            {(): ReactNode => (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0
                  }
                >
                  <Trans>Inloggen</Trans>
                </Button>

                <ForgotPasswordButton />
              </>
            )}
          </Form.Item>
        </Col>
      </Row>
      {state === "Invalid" && (
        <CenteredBlock>
          <Alert
            type="warning"
            message={i18n._(t`Gebruikersnaam of wachtwoord is niet correct.`)}
            showIcon
          />
        </CenteredBlock>
      )}
      {state === "Error" && (
        <CenteredBlock>
          <Alert
            type="error"
            message={i18n._(t`Er is iets misgegaan.`)}
            showIcon
          />
        </CenteredBlock>
      )}

      <NotificationMessage />
    </Spin>
  );
};

export default memo(Login);
