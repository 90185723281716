import { plural, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Layout, Typography, message, notification } from "antd";
import React, { memo, useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { contributorRoleName, plannerRoleName } from "../models/UserRole";
import { calendarCell } from "../store/calendar/cells";
import { openRequestsCell } from "../store/campaigns/requests/cells";
import { deliveryStatusCell } from "../store/commercials/cells";
import { healthCell } from "../store/health/cells";
import { StoreModel } from "../store/models";
import sagaTypes from "../store/sagaTypes";
import { hasRole } from "../utils";
import navigationPaths from "../utils/navigation";
import Content from "../views/Content";
import DefaultFooter from "../views/layout/DefaultFooter";
import Trunk from "../views/layout/Trunk";
import { isGAEnabled, trackingCode } from "../views/utils/analytics";
import CheckForAdBlocker from "./CheckForAdBlocker";
import LinkInNotification from "./LinkInNotification";
import ContentRouting from "./navigation/ContentRouting";
import Header from "./navigation/Header";

interface AppLayoutProps {
  refreshToken?: string;
}

const AppLayout = memo(({ refreshToken }: AppLayoutProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const {
    value: user,
    status: { loading: userLoading },
  } = useSelector(({ users: { current } }: StoreModel) => current);

  const location = useLocation();
  const isAuthentication =
    location.pathname.startsWith(navigationPaths.Authentication) &&
    !location.pathname.startsWith(navigationPaths.Activate);

  const { showTitles, enableHealth } = useSelector(
    ({
      application: {
        options: {
          showTitles: showTitlesFromStore,
          enableHealth: enableHealthFromStore,
        },
      },
    }: StoreModel) => ({
      showTitles: !!showTitlesFromStore,
      enableHealth: !!enableHealthFromStore,
    })
  );

  message.config({
    duration: 5,
  });

  useEffect(() => {
    if (!user && !userLoading) {
      dispatch({ type: sagaTypes.users.current.request });
    } else if (isGAEnabled) {
      // Set User ID
      TagManager.initialize({
        gtmId: trackingCode,
        dataLayer: {
          userId: user?.id,
        },
      });
    }
  }, [userLoading, dispatch, user, refreshToken]);

  const { value: openOrderRequests } = useSelector(
    ({ openRequests }: StoreModel) => openRequests
  );
  const isPlanner = useMemo(() => hasRole(plannerRoleName)(user), [user]);
  useEffect(() => {
    if (user && isPlanner) {
      dispatch(openRequestsCell.require());
    }
  }, [dispatch, isPlanner, user]);

  useEffect(() => {
    if (openOrderRequests && openOrderRequests.numberOfRequests > 0) {
      notification.warning({
        key: "openOrderRequests",
        message: i18n._(t`Niet ingediend`),
        description: (
          <Typography.Paragraph>
            {i18n._(
              plural(openOrderRequests.numberOfRequests, {
                zero: `Alle aanvragen zijn ingediend.`,
                one: `Er is # aanvraag nog niet ingediend.`,
                few: `Er zijn # aanvragen nog niet ingediend.`,
                many: `Er zijn # aanvragen nog niet ingediend.`,
                other: `Er zijn # aanvragen nog niet ingediend.`,
              })
            )}{" "}
            <LinkInNotification to={navigationPaths.CampaignsRequestsConcept}>
              {i18n._(t`Bekijk je aanvragen`)}
            </LinkInNotification>
            .
          </Typography.Paragraph>
        ),
        placement: "bottomRight",
      });
    }
  }, [i18n, openOrderRequests]);

  const isContributor = useMemo(
    () => hasRole(contributorRoleName)(user),
    [user]
  );
  useEffect(() => {
    if (user && isContributor) {
      dispatch(deliveryStatusCell.require());
    }
  }, [dispatch, isContributor, user]);

  const { value: deliveryStatus } = useSelector(
    ({ deliveryStatus: deliveryStatusFromStore }: StoreModel) =>
      deliveryStatusFromStore
  );

  useEffect(() => {
    if (user && enableHealth) {
      dispatch(healthCell.require());
    }
  }, [dispatch, enableHealth, user]);

  useEffect(() => {
    if (user) {
      // If logged in, get the calendar events
      dispatch(calendarCell.require());
    }
  }, [dispatch, user]);

  return (
    <Layout>
      <Layout.Header>
        <Trunk>
          <Header
            user={user}
            openOrderRequests={openOrderRequests}
            deliveryStatus={deliveryStatus}
          />
        </Trunk>
      </Layout.Header>
      <Content isAuthentication={isAuthentication}>
        <CheckForAdBlocker />
        <ContentRouting user={user} showTitles={showTitles} />
      </Content>
      <Layout.Footer id="tip-footer">
        <Trunk>
          <DefaultFooter />
        </Trunk>
      </Layout.Footer>
    </Layout>
  );
});

export default AppLayout;
