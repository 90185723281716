import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { message } from "antd";
import { Component, ErrorInfo, ReactNode } from "react";

// Application Insights
const reactPlugin = new ReactPlugin();

interface ErrorBoundaryProps {
  children?: ReactNode;
  instrumentationKey?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private readonly appInsights?: ApplicationInsights;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    const { instrumentationKey } = this.props;

    if (instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          extensions: [reactPlugin],
          isBrowserLinkTrackingEnabled: true,
          enableAutoRouteTracking: true,
          isCookieUseDisabled: true,
          disableFetchTracking: false,
        },
      });

      this.appInsights.loadAppInsights();
      this.appInsights.addTelemetryInitializer((envelope) => {
        if (envelope && envelope.tags) {
          // eslint-disable-next-line no-param-reassign
          envelope.tags["ai.operation.name"] = window.document.title;
        }
      });
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ hasError: true });
    if (this.appInsights) {
      this.appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...info },
      });
    }
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      message.error({
        content: "Er is iets misgegaan in de TIP applicatie.",
        duration: 20,
        onClose: () => {
          this.setState({ hasError: false });
        },
      });

      return null;
    }

    return children || null;
  }
}

export default withAITracking(reactPlugin, ErrorBoundary);
