import { Trans } from "@lingui/macro";
import { Button, Col, Row, Tooltip } from "antd";
import React, { ReactNode, memo } from "react";

import Centered from "../../views/Centered";

interface PreviousNextButtonsProps {
  enableNext?: boolean;
  nextIsSubmit?: boolean;
  onCancel?: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
  showCancel?: boolean;
  showPrevious: boolean;
  showNext: boolean;
  textPrevious?: string;
  textNext?: string;
  tooltipNext?: ReactNode;
  tooltipPrevious?: ReactNode;
}

const PreviousNextButtons = memo(
  ({
    enableNext = true,
    nextIsSubmit = false,
    onCancel,
    onPrevious,
    onNext,
    showCancel = false,
    showPrevious,
    showNext,
    textPrevious,
    textNext,
    tooltipNext,
    tooltipPrevious,
  }: PreviousNextButtonsProps) => {
    const nextButton = showNext ? (
      <Button
        disabled={!enableNext}
        type="primary"
        htmlType={(!!nextIsSubmit && "submit") || undefined}
        onClick={onNext}
      >
        {textNext || <Trans id="Volgende">Volgende</Trans>}
      </Button>
    ) : (
      <></>
    );

    const previousButton = (
      <Button type="primary" onClick={onPrevious}>
        {textPrevious || <Trans id="Vorige">Vorige</Trans>}
      </Button>
    );

    return (
      <Row justify="center">
        <Col>
          {showPrevious &&
            (tooltipPrevious ? (
              <Tooltip title={tooltipPrevious}>{previousButton}</Tooltip>
            ) : (
              previousButton
            ))}
        </Col>
        <Col flex="auto">
          {showCancel && (
            <Centered>
              <Button type="default" onClick={onCancel}>
                <Trans id="Annuleren">Annuleren</Trans>
              </Button>
            </Centered>
          )}
        </Col>
        <Col>
          {tooltipNext ? (
            <Tooltip title={tooltipNext}>{nextButton}</Tooltip>
          ) : (
            nextButton
          )}
        </Col>
      </Row>
    );
  }
);

export default PreviousNextButtons;
