import { Moment } from "moment";

import { UserRoleName } from "./UserRole";

export type SubModule = "Contribution" | "Organizations";
export const subModules: SubModule[] = ["Contribution", "Organizations"];

export default interface AuthorizationRule {
  role: UserRoleName;
  subModules?: SubModule[];
  advertiserIds?: string[];
  valid?: [Moment, Moment];
  validFrom?: Date;
  validTo?: Date;
}
