import moment, { Moment } from "moment";
import React, { memo } from "react";

import BigScreen from "../layout/BigScreen";
import SmallScreen from "../layout/SmallScreen";

interface CollapseListExtraPeriodProps {
  period: [Moment, Moment];
}

const CollapseListExtraPeriod = memo(
  ({ period: [startDate, endDate] }: CollapseListExtraPeriodProps) => {
    const periodView = (
      <>
        <BigScreen type="text">
          {moment(startDate).format("LL")} - {moment(endDate).format("LL")}
        </BigScreen>
        <SmallScreen type="text">
          {moment(startDate).format("L")} - {moment(endDate).format("L")}
        </SmallScreen>
      </>
    );

    return <div className="tip-collapseList-extraPeriod">{periodView}</div>;
  }
);

export default CollapseListExtraPeriod;
