import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import User from "../../models/User";
import { UserRoleName } from "../../models/UserRole";
import sagaTypes from "../../store/sagaTypes";
import { getToken, refreshTokenKey } from "../../store/token";
import { hasRole } from "../../utils";
import navigationPaths from "../../utils/navigation";

interface PrivateRouteProps {
  user?: User;
  requiredRole?: UserRoleName;
}

const PrivateRoute = memo(({ user, requiredRole }: PrivateRouteProps) => {
  const dispatch = useDispatch();
  const refreshToken = getToken(refreshTokenKey);
  const validToken =
    refreshToken &&
    refreshToken.token &&
    refreshToken.expires &&
    new Date(refreshToken.expires) > new Date();
  if (validToken && user && requiredRole && !hasRole(requiredRole)(user)) {
    return <Navigate to={navigationPaths.Forbidden} replace />;
  }

  if (!validToken) {
    dispatch({
      type: sagaTypes.users.current.clear,
    });

    return <Navigate to={navigationPaths.Login} replace />;
  }

  return <Outlet />;
});

export default PrivateRoute;
