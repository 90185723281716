import { Select } from "antd";
import { flow, map, orderBy } from "lodash/fp";
import React, { ReactElement, useCallback, useMemo } from "react";

import {
  UserRoleName,
  allRoleNames,
  operatorRoles,
  roleDisplayNames,
} from "../../../../models/UserRole";

interface RoleSelectProps {
  defaultValue: UserRoleName;
  isKnownByOperators: boolean;
  onSelect: (key: string, role: UserRoleName) => void;
  rowKey: string;
  value: UserRoleName;
}

const RoleSelect = ({
  defaultValue,
  isKnownByOperators,
  onSelect,
  rowKey,
  value,
}: RoleSelectProps): ReactElement => {
  const handleChange = useCallback(
    (newValue: UserRoleName) => {
      onSelect(rowKey, newValue);
    },
    [onSelect, rowKey]
  );

  const roles = useMemo(
    () =>
      isKnownByOperators
        ? allRoleNames
        : allRoleNames.filter((r) => !operatorRoles.some((role) => role === r)),
    [isKnownByOperators]
  );

  return (
    <Select
      key="roleSelect"
      defaultValue={defaultValue}
      onChange={handleChange}
      value={value}
    >
      {flow(
        orderBy([(role: UserRoleName) => roleDisplayNames[role]], ["asc"]),
        map((role: UserRoleName) => (
          <Select.Option key={role} value={role}>
            {roleDisplayNames[role]}
          </Select.Option>
        ))
      )(roles)}
    </Select>
  );
};

export default RoleSelect;
