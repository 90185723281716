import moment from "moment";

import { formatDateForUri } from "../../utils/date";
import { jsonGetter, jsonPoster } from "../fetch";
import { ApiPromise, TokenApiPromise } from "../models";
import {
  AvailableBreaksRequest,
  AvailableBreaksResponse,
  BookSpotRequest,
} from "./models";

export const bookSpotAvailableBreaksGetter = ({
  dateFrom,
  dateTo,
  orderId,
  subOrderId,
  packageCode,
  spotLengths,
}: AvailableBreaksRequest): TokenApiPromise<AvailableBreaksResponse> => {
  const dateFromString = formatDateForUri(moment(dateFrom));
  const dateToString = formatDateForUri(moment(dateTo));
  const spotLengtsForUri = spotLengths
    .map((length) => `spotLengths=${length}`)
    .join("&");
  const packageCodeString = encodeURIComponent(packageCode);

  return jsonGetter<AvailableBreaksRequest, AvailableBreaksResponse>(
    `api/bookspot/availablebreaks?dateFrom=${dateFromString}&dateTo=${dateToString}&orderId=${orderId}&subOrderId=${subOrderId}&packageCode=${packageCodeString}&${spotLengtsForUri}`
  );
};

export const bookSpotPoster =
  (request: BookSpotRequest): TokenApiPromise<BookSpotRequest> =>
  (token?: string): ApiPromise<BookSpotRequest> =>
    jsonPoster<BookSpotRequest, BookSpotRequest>("api/bookspot")(
      request,
      token
    );
