import { AsyncResponse } from "async-lifecycle-saga";
import { Moment } from "moment";

import { CommercialDeliveryView } from "../../components/commercials/models";
import Advertiser from "../../models/Advertiser";
import { CommercialDeliveryStatus } from "../../models/CommercialDeliveryStatus";
import { CommercialBlobResponse } from "../../models/OnBlobDownloadFunc";
import { Operator } from "../../models/Operator";
import { getPeriodQueryString } from "../../utils/date";
import { AdvertisersRequest } from "../advertisers/models";
import {
  getter,
  jsonDeleter,
  jsonDownloader,
  jsonGetter,
  jsonPoster,
} from "../fetch";
import { ApiPromise, TokenApiPromise } from "../models";

const apiPrefix = "/api/commercial";

export const enabledOperators = (): TokenApiPromise<Operator[]> =>
  jsonGetter<unknown, Operator[]>(`${apiPrefix}/operators`);

interface CommercialFilterRequest {
  period: [Moment, Moment];
  advertiserId: string;
}

export const getCommercials = ({
  period,
  advertiserId,
}: CommercialFilterRequest): TokenApiPromise<CommercialDeliveryView[]> => {
  let path = `/api/commercial?${getPeriodQueryString(period[0], period[1])}`;
  if (advertiserId) {
    path += `&advertiserId=${advertiserId}`;
  }

  return jsonGetter<CommercialFilterRequest, CommercialDeliveryView[]>(path);
};

export interface CommercialBlobRequest {
  path: string;
}

export const getCommercialBlob = ({
  path,
}: CommercialBlobRequest): TokenApiPromise<CommercialBlobResponse> =>
  jsonDownloader<CommercialBlobResponse>(`${apiPrefix}/view/${path}`);

export const deliveryUploadPoster =
  (form: FormData): TokenApiPromise<unknown> =>
  (token?: string): ApiPromise<unknown> =>
    jsonPoster<unknown, FormData>(`${apiPrefix}`)(form, token, {
      Accept: "application/json",
    });

export const advertisersWithCommercialsByDateGetter = ({
  dateFrom,
  dateTo,
}: AdvertisersRequest): TokenApiPromise<Advertiser[]> =>
  jsonGetter<AdvertisersRequest, Advertiser[]>(
    `${apiPrefix}/advertisers?${getPeriodQueryString(dateFrom, dateTo)}`
  );

export const deliveryDeleter =
  ({ id }: { id: string }): TokenApiPromise<unknown> =>
  (token?: string): ApiPromise<unknown> =>
    jsonDeleter<unknown>(`/api/commercial/${id}`)(token);

export const deliveryStatusGetter = (): Promise<
  AsyncResponse<CommercialDeliveryStatus[]>
> => getter<CommercialDeliveryStatus[]>("/api/commercial/status");
