import { map } from "lodash/fp";
import React, { ReactNode } from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

import { COLORS } from "./ChartDot";
import ChartTooltip from "./ChartTooltip";

export interface GrpBarChartProps<TData> {
  data: TData[];
  labelGetter: (data: TData) => string;
  nameGetter: (data: TData) => string;
  valueGetter: (data: TData) => number;
  renderValue: (value: number) => ReactNode;
}

const GrpBarChart = <TData,>({
  data,
  labelGetter,
  nameGetter,
  valueGetter,
  renderValue,
}: GrpBarChartProps<TData>) => (
  <ResponsiveContainer aspect={1}>
    <BarChart
      width={150}
      height={150}
      data={map((d: TData) => ({
        name: labelGetter(d),
        value: valueGetter(d),
      }))(data)}
      margin={{ bottom: 75 }}
    >
      <XAxis dataKey="name" textAnchor="end" interval={0} angle={-70} />
      <Tooltip content={<ChartTooltip renderValue={renderValue} />} />

      <Bar dataKey="value" label={{ position: "center", fill: "#fff" }}>
        {map((d: TData) => {
          const name = nameGetter(d);
          return <Cell key={`cell-${name}`} fill={COLORS[name]} />;
        })(data)}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

export default GrpBarChart;
