import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Button, Form, Input, List, Result, Spin, Table } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { ColumnProps } from "antd/lib/table";
import { flow, map, orderBy } from "lodash/fp";
import moment, { Moment } from "moment";
import React, { Fragment, memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import Advertiser from "../../../../models/Advertiser";
import AuthorizationRule, {
  SubModule,
} from "../../../../models/AuthorizationRule";
import User from "../../../../models/User";
import {
  UserRoleName,
  adminRoleName,
  b2bDocumentationRoleName,
  contributorRoleName,
  readerRoleName,
  roleDisplayNames,
} from "../../../../models/UserRole";
import { StoreModel } from "../../../../store/models";
import propertyOf from "../../../../utils/properties";
import { wrapperColFullWidth } from "../../../../views/layout/Form";
import AdvertisersView from "../../../../views/management/users/authorization/AdvertisersView";
import RoleView from "../../../../views/management/users/authorization/RoleView";
import ValidityPeriodView from "../../../../views/management/users/authorization/ValidityPeriodView";
import UsersContent from "../../../../views/management/users/UsersContent";
import AdvertisersSelect from "./AdvertisersSelect";
import CancelButton from "./CancelButton";
import EditButton from "./EditButton";
import RemoveButton from "./RemoveButton";
import RoleSelect from "./RoleSelect";
import SaveButton from "./SaveButton";
import SubModulesSelect from "./SubModulesSelect";
import ValidityPeriodPicker from "./ValidityPeriodPicker";

export interface EditingRule {
  key: string;
  authorizationRule: AuthorizationRule;
}

interface UserFormItemAuthorizationRulesProps {
  form: FormInstance<User>;
  initialValue: AuthorizationRule[];
  isKnownByOperators: boolean;
  editingRule?: EditingRule | null;
  setEditingRule: (rule: EditingRule | null) => void;
}

const authorizationRulesField = propertyOf<User>("authorizations");

const emptyAdvertisersArray: Advertiser[] = [];
const emptyAuthorizationRule: AuthorizationRule = { role: readerRoleName };
const emptyStringArray: string[] = [];
const emptySubModules: SubModule[] = [];
const staticRoles: UserRoleName[] = [adminRoleName, b2bDocumentationRoleName];

const createKey = (
  { role, advertiserIds, valid }: AuthorizationRule,
  index?: number
): string => {
  const advertiserIdsComponent =
    advertiserIds && advertiserIds.length > 0
      ? `_${advertiserIds?.sort((a1, a2) => a1.localeCompare(a2)).join(",")}`
      : "";
  const validComponent = valid
    ? `_${valid[0].toISOString()}_${valid[1].toISOString()}`
    : "";
  const indexComponent = index ? `_${index}` : "";
  return `auth_${role}${advertiserIdsComponent}${validComponent}${indexComponent}`;
};

const decorate = (authorizationRule: AuthorizationRule): AuthorizationRule => ({
  ...authorizationRule,
  valid:
    !authorizationRule.valid &&
    authorizationRule.validFrom &&
    authorizationRule.validTo
      ? [moment(authorizationRule.validFrom), moment(authorizationRule.validTo)]
      : authorizationRule.valid,
});

const UserFormItemAuthorizationRules = ({
  form,
  initialValue,
  isKnownByOperators,
  editingRule,
  setEditingRule,
}: UserFormItemAuthorizationRulesProps) => {
  const { i18n } = useLingui();

  const initialValueDecorated = useMemo(
    () =>
      flow(
        map(decorate),
        orderBy(
          [(rule: AuthorizationRule) => roleDisplayNames[rule.role]],
          ["asc"]
        )
      )(initialValue),
    [initialValue]
  );

  const organisationAdvertisers = useSelector(
    ({ advertisers: { byOrganisation } }: StoreModel) => byOrganisation
  );
  const getAdvertisers = useCallback(
    (): Advertiser[] =>
      organisationAdvertisers.value?.advertisers || emptyAdvertisersArray,
    [organisationAdvertisers]
  );

  const handleAdd = useCallback(() => {
    const currentValue = form.getFieldValue(
      authorizationRulesField
    ) as AuthorizationRule[];
    const defaultAuthorization = {
      role: !isKnownByOperators ? contributorRoleName : readerRoleName,
    };

    const newValue = [
      ...orderBy(
        [(rule: AuthorizationRule) => roleDisplayNames[rule.role]],
        ["asc"]
      )(currentValue),
      defaultAuthorization,
    ];

    const newKey = createKey(defaultAuthorization, currentValue.length);
    form.setFieldsValue({
      [authorizationRulesField]: newValue,
    });
    setEditingRule({
      key: newKey,
      authorizationRule: defaultAuthorization,
    });
  }, [form, isKnownByOperators, setEditingRule]);

  const handleCancel = useCallback(() => {
    setEditingRule(null);
  }, [setEditingRule]);

  const handleEdit = useCallback(
    (key: string, authorizationRule: AuthorizationRule) => {
      setEditingRule({ key, authorizationRule });
    },
    [setEditingRule]
  );

  const handleRemove = useCallback(
    (key: string) => {
      const currentValue = form.getFieldValue(
        authorizationRulesField
      ) as AuthorizationRule[];
      const index = currentValue.findIndex(
        (item, i) => createKey(item, i) === key
      );
      const newValue = [...currentValue];
      newValue.splice(index, 1);
      form.setFieldsValue({
        [authorizationRulesField]: newValue,
      });
    },
    [form]
  );

  const handleSave = useCallback(() => {
    if (!editingRule) {
      return;
    }
    const currentValue = form.getFieldValue(
      authorizationRulesField
    ) as AuthorizationRule[];
    const newValue = [...currentValue];
    const index = newValue.findIndex(
      (item, i) => createKey(item, i) === editingRule.key
    );
    const item = newValue[index];
    newValue.splice(index, 1, {
      ...item,
      ...editingRule.authorizationRule,
    });
    form.setFieldsValue({
      [authorizationRulesField]: newValue,
    });
    setEditingRule(null);
  }, [editingRule, form, setEditingRule]);

  const handleRoleSelect = useCallback(
    (key: string, role: UserRoleName) => {
      setEditingRule({
        ...(editingRule || { key }),
        authorizationRule: {
          ...(editingRule?.authorizationRule || emptyAuthorizationRule),
          role,
          subModules:
            role === "b2bdocumentatie"
              ? editingRule?.authorizationRule.subModules
              : emptySubModules,
        },
      });
    },
    [editingRule, setEditingRule]
  );

  const handleSubModulesSelect = useCallback(
    (key: string, subModules: SubModule[]) => {
      setEditingRule({
        ...(editingRule || { key }),
        authorizationRule: {
          ...(editingRule?.authorizationRule || emptyAuthorizationRule),
          subModules,
        },
      });
    },
    [editingRule, setEditingRule]
  );

  const handleAdvertisersSelect = useCallback(
    (key: string, advertiserIds: string[]) => {
      setEditingRule({
        ...(editingRule || { key }),
        authorizationRule: {
          ...(editingRule?.authorizationRule || emptyAuthorizationRule),
          advertiserIds,
        },
      });
    },
    [editingRule, setEditingRule]
  );

  const handleValidityPeriodPicked = useCallback(
    (key: string, valid: [Moment, Moment]) => {
      setEditingRule({
        ...(editingRule || { key }),
        authorizationRule: {
          ...(editingRule?.authorizationRule || emptyAuthorizationRule),
          valid,
        },
      });
    },
    [editingRule, setEditingRule]
  );

  const createTableColumns = useCallback(
    (advertisers: Advertiser[]): ColumnProps<AuthorizationRule>[] => [
      {
        title: i18n._(t`Rol`),
        width: 200,
        render: (_, record, index) => {
          const currentKey = createKey(record, index);
          const isEditing = editingRule?.key === currentKey;
          return isEditing ? (
            <RoleSelect
              defaultValue={readerRoleName}
              isKnownByOperators={isKnownByOperators}
              onSelect={handleRoleSelect}
              rowKey={currentKey}
              value={editingRule?.authorizationRule.role || readerRoleName}
            />
          ) : (
            <RoleView role={record.role} />
          );
        },
      },
      {
        title: i18n._(t`Submodule(s)`),
        width: 200,
        render: (_, record, index) => {
          const currentKey = createKey(record, index);
          const isEditing = editingRule?.key === currentKey;
          if (
            (isEditing &&
              editingRule?.authorizationRule.role !== "b2bdocumentatie") ||
            (!isEditing && record.role !== "b2bdocumentatie")
          ) {
            return <></>;
          }
          const subModulesSelected = record.subModules || [];
          return isEditing ? (
            <SubModulesSelect
              defaultValue={subModulesSelected}
              onSelect={handleSubModulesSelect}
              rowKey={currentKey}
            />
          ) : (
            <List
              dataSource={subModulesSelected}
              locale={{ emptyText: <></> }}
              renderItem={(sm: SubModule) => (
                <List.Item key={sm}>{sm}</List.Item>
              )}
            />
          );
        },
      },
      {
        title: i18n._(t`Adverteerder(s)`),
        width: 400,
        render: (_, record, index) => {
          const currentKey = createKey(record, index);
          const isEditing = editingRule?.key === currentKey;
          return isEditing ? (
            <Fragment key="advertisersSelect">
              {!isKnownByOperators ||
              (advertisers && advertisers.length === 0) ? (
                <Alert
                  type="warning"
                  message={i18n._(
                    t`Er zijn geen adverteerders om uit te kiezen.`
                  )}
                />
              ) : (
                <AdvertisersSelect
                  advertisers={advertisers}
                  defaultValue={record.advertiserIds || emptyStringArray}
                  enabled={
                    !staticRoles.some(
                      (roleName) =>
                        roleName === editingRule?.authorizationRule.role
                    )
                  }
                  onSelect={handleAdvertisersSelect}
                  rowKey={currentKey}
                />
              )}
            </Fragment>
          ) : (
            <AdvertisersView
              advertiserIds={record.advertiserIds || emptyStringArray}
              advertisers={advertisers}
            />
          );
        },
      },
      {
        ellipsis: true,
        key: "validityPeriod",
        title: i18n._(t`Geldigheidsperiode`),
        width: 350,
        render: (_, record, index) => {
          const currentKey = createKey(record, index);
          const isEditing = editingRule?.key === currentKey;
          return isEditing ? (
            <ValidityPeriodPicker
              defaultValue={editingRule?.authorizationRule.valid}
              enabled={
                !staticRoles.some(
                  (roleName) => roleName === editingRule?.authorizationRule.role
                )
              }
              onPicked={handleValidityPeriodPicked}
              rowKey={currentKey}
            />
          ) : (
            <ValidityPeriodView period={record.valid} />
          );
        },
      },
      {
        key: "actions",
        title: i18n._(t`Acties`),
        render: (_, record, index) => {
          const currentKey = createKey(record, index);
          const isEditing = editingRule?.key === currentKey;
          return isEditing ? (
            <>
              <SaveButton key="saveButton" onSave={handleSave} />
              <CancelButton key="cancelButton" onCancel={handleCancel} />
            </>
          ) : (
            <>
              <EditButton
                key="editButton"
                authorizationRule={record}
                enabled={!isEditing}
                onEdit={handleEdit}
                rowKey={currentKey}
              />
              <RemoveButton
                key="removeButton"
                enabled={!isEditing}
                onRemove={handleRemove}
                rowKey={currentKey}
              />
            </>
          );
        },
      },
    ],
    [
      editingRule?.authorizationRule.role,
      editingRule?.authorizationRule.valid,
      editingRule?.key,
      handleAdvertisersSelect,
      handleCancel,
      handleEdit,
      handleRemove,
      handleRoleSelect,
      handleSave,
      handleSubModulesSelect,
      handleValidityPeriodPicked,
      i18n,
      isKnownByOperators,
    ]
  );

  return (
    <>
      <Form.Item
        initialValue={initialValueDecorated}
        name={authorizationRulesField}
        noStyle
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item shouldUpdate wrapperCol={wrapperColFullWidth}>
        {() => {
          const advertisers = getAdvertisers();
          const authorizationRules = form.getFieldValue(
            authorizationRulesField
          );

          const advertisersLoading =
            isKnownByOperators && organisationAdvertisers.status.loading;

          if (isKnownByOperators && !advertisers && !advertisersLoading) {
            return (
              <Result
                status="warning"
                subTitle={i18n._(
                  t`Om te bepalen welke adverteerders aan de autorisaties gekoppeld kunnen worden, moet eerst een organisatie geselecteerd worden.`
                )}
                title={i18n._(t`Selecteer eerst een organisatie...`)}
              />
            );
          }

          return (
            <Spin spinning={advertisersLoading}>
              <UsersContent>
                <Button
                  type="primary"
                  onClick={handleAdd}
                  disabled={editingRule?.key !== undefined}
                >
                  <Trans>Regel toevoegen</Trans>
                </Button>
                <Table
                  columns={createTableColumns(advertisers)}
                  dataSource={authorizationRules}
                  pagination={false}
                  rowKey={createKey}
                  rowClassName={(record, index) => {
                    const currentKey = createKey(record, index);
                    const isEditing = editingRule?.key === currentKey;
                    return isEditing ? "editing" : "";
                  }}
                  size="small"
                />
              </UsersContent>
            </Spin>
          );
        }}
      </Form.Item>
    </>
  );
};

export default memo(UserFormItemAuthorizationRules);
