import { Action, Reducer, combineReducers } from "redux";

import advertisersReducer from "../advertisers/reducer";
import applicationReducer from "../application/reducer";
import tokenReducer from "../authentication/reducer";
import bookSpotReducer from "../bookspot/reducer";
import calendarReducer from "../calendar/reducers";
import campaignsReducer from "../campaigns/reducer";
import requestsReducer, {
  copyRequestReducer,
  openRequestsReducer,
} from "../campaigns/requests/reducer";
import commercialsReducer, {
  deliveryStatusReducer,
} from "../commercials/reducers";
import dashboardReducer from "../dashboard/reducer";
import exportsReducer from "../exports/reducer";
import externalApiReducer from "../externalApis/reducer";
import healthReducer from "../health/reducers";
import { instructionsReducer } from "../instructions/reducers";
import { asyncIdReducer, asyncReducer } from "../lifecycle";
import localizationReducer from "../localization/reducer";
import { StoreModel } from "../models";
import normalisationReducer from "../normalisation/reducer";
import notificationReducer from "../notifications/reducer";
import { organisationSetPasswordCell } from "../organisations/cells";
import organisationsReducer from "../organisations/reducer";
import ornImportReportReducer from "../ornImport/reducers";
import { PageResponse } from "../pages/models";
import sagaTypes from "../sagaTypes";
import skoTargetGroupReducer from "../sko/reducers";
import skoImportReportReducer from "../skoImport/reducers";
import statisticsReducer from "../statistics/reducer";
import usersReducer from "../users/reducer";
import globalReducer from "./globalReducer";
import uiReducer from "./uiReducer";

const appReducer: Reducer<
  StoreModel,
  Action<string>
> = combineReducers<StoreModel>({
  advertisers: advertisersReducer,
  application: applicationReducer,
  bookSpot: bookSpotReducer,
  calendar: calendarReducer,
  campaigns: campaignsReducer,
  commercials: commercialsReducer,
  copyRequest: copyRequestReducer,
  dashboard: dashboardReducer,
  deliveryStatus: deliveryStatusReducer,
  exports: exportsReducer,
  externalApi: externalApiReducer,
  global: globalReducer,
  health: healthReducer,
  instructions: instructionsReducer,
  invoice: combineReducers({
    download: asyncIdReducer<unknown, Blob>(sagaTypes.invoice.download),
  }),
  localization: localizationReducer,
  normalisation: normalisationReducer,
  notifications: notificationReducer,
  openRequests: openRequestsReducer,
  organisations: organisationsReducer,
  organisationSetPassword: organisationSetPasswordCell.reducer,
  ornImportReport: ornImportReportReducer,
  pages: asyncIdReducer<unknown, PageResponse>(sagaTypes.pages),
  product: asyncReducer<unknown, string>(sagaTypes.product),
  requests: requestsReducer,
  skoImportReport: skoImportReportReducer,
  skoTargetGroup: skoTargetGroupReducer,
  statistics: statisticsReducer,
  tokens: tokenReducer,
  ui: uiReducer,
  users: usersReducer,
});

export default appReducer;
