import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { notification } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreModel } from "../store/models";
import sagaTypes from "../store/sagaTypes";

const CheckForAdBlocker = memo(() => {
  const { i18n } = useLingui();
  const adBlockerCheckEnabled = useSelector(
    ({
      application: {
        options: { enableAdBlockerCheck },
      },
    }: StoreModel) => !!enableAdBlockerCheck
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (adBlockerCheckEnabled) {
      dispatch({ type: sagaTypes.ui.adBlocker.request });
    }
  }, [adBlockerCheckEnabled, dispatch]);

  const [showAdBlockerMessage, updateAdBlockerMessage] = useState(false);
  const isAdBlocked = useSelector(({ ui }: StoreModel) => {
    const {
      value: isBlockedValue,
      status: { loading: isBlockedLoading },
    } = ui.adBlocker;

    return !isBlockedLoading && isBlockedValue?.isBlocked;
  });

  useEffect(() => {
    if (adBlockerCheckEnabled) {
      const previouslyClosed =
        window.sessionStorage.getItem("tip-adblocker-message-closed") ===
        "true";
      if (!previouslyClosed && !showAdBlockerMessage && isAdBlocked) {
        updateAdBlockerMessage(true);
        notification.error({
          placement: "topRight",
          message: i18n._(t`AdBlocker`),
          description: i18n._(
            t`Het lijkt erop dat je een AdBlocker hebt, deze kun je het beste uitzetten!`
          ),
          duration: 0,
          onClose() {
            window.sessionStorage.setItem(
              "tip-adblocker-message-closed",
              "true"
            );
          },
        });
      }
    }
  }, [
    i18n,
    adBlockerCheckEnabled,
    isAdBlocked,
    showAdBlockerMessage,
    updateAdBlockerMessage,
  ]);

  return null;
});

export default CheckForAdBlocker;
