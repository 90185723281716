import { t } from "@lingui/macro";

export type NavigationKey =
  | "About"
  | "Activate"
  | "Authentication"
  | "Calendar"
  | "CalendarManagement"
  | "Campaigns"
  | "CampaignsRequests"
  | "CampaignsRequestsConcept"
  | "CampaignsRequestsCreateNew"
  | "CampaignsRequestsEdit"
  | "CampaignsRequestsSearch"
  | "CommercialDelivery"
  | "CommercialLibrary"
  | "Commercials"
  | "ConfirmPasswordReset"
  | "Dashboard"
  | "DeliveryStatus"
  | "ExternalApiClients"
  | "Forbidden"
  | "ForgotPassword"
  | "Login"
  | "Management"
  | "Notifications"
  | "Organisations"
  | "OrnImportReport"
  | "Page"
  | "ProductRequest"
  | "ReleaseNotes"
  | "Root"
  | "Settings"
  | "SkoImportReport"
  | "StatisticsByOrganisation"
  | "StatisticsByUser"
  | "Status"
  | "TermsOfUse"
  | "TermsOfUseDashboard"
  | "Tutorials"
  | "TwoFactor"
  | "UserProfile"
  | "Users";

type NavigationDictionary<ValueType> = {
  [navigationKey in NavigationKey]: ValueType;
};

export const navigationPaths: NavigationDictionary<string> = {
  About: "/over",
  Activate: "/auth/activeren",
  Authentication: "/auth",
  Calendar: "/kalender",
  CalendarManagement: "/beheer/kalender",
  Campaigns: "/campagnes",
  CampaignsRequests: "/aanvragen",
  CampaignsRequestsConcept: "/aanvragen/concept",
  CampaignsRequestsCreateNew: "/aanvragen/nieuw",
  CampaignsRequestsEdit: "/aanvragen/bewerken/:orderRequestId",
  CampaignsRequestsSearch: "/aanvragen/zoeken",
  CommercialDelivery: "/commercials/aanleveren",
  CommercialLibrary: "/commercials/bibliotheek",
  Commercials: "/commercials",
  ConfirmPasswordReset: "/auth/wachtwoord-herstellen",
  Dashboard: "/dashboard",
  DeliveryStatus: "/commercials/status",
  ExternalApiClients: "/apps/koppelen",
  Forbidden: "/geen-toegang",
  ForgotPassword: "/auth/wachtwoord-vergeten",
  Login: "/auth/login",
  Management: "/beheer",
  Notifications: "/beheer/mededelingen",
  Organisations: "/beheer/organisaties",
  OrnImportReport: "/beheer/imports/orn",
  Page: "/pagina/:name",
  ProductRequest: "/product/aanvragen",
  ReleaseNotes: "/releasenotes",
  Root: "/",
  Settings: "/instellingen",
  SkoImportReport: "/beheer/imports/sko",
  StatisticsByOrganisation: "/beheer/rapportage",
  StatisticsByUser: "/beheer/rapportage/:organisationId/:year",
  Status: "/status",
  TermsOfUse: "/pagina/gebruiksvoorwaarden",
  TermsOfUseDashboard: "/pagina/gebruiksvoorwaarden-dashboard",
  Tutorials: "/tutorials",
  TwoFactor: "/auth/twoFactor",
  UserProfile: "/profiel",
  Users: "/beheer/gebruikers",
};

export const navigationHeaders: NavigationDictionary<string> = {
  About: t`Over`,
  Activate: t`Activeren`,
  Authentication: t`Authenticatie`,
  Calendar: t`Kalender`,
  CalendarManagement: t`Kalender beheer`,
  Campaigns: t`Campagnes`,
  CampaignsRequests: t`Aanvragen`,
  CampaignsRequestsConcept: t`Concept aanvragen`,
  CampaignsRequestsCreateNew: t`Nieuwe aanvraag`,
  CampaignsRequestsEdit: t`Aanvraag bewerken`,
  CampaignsRequestsSearch: t`Alle aanvragen`,
  CommercialDelivery: t`Aanleveren`,
  CommercialLibrary: t`Bibliotheek`,
  Commercials: t`Commercials`,
  ConfirmPasswordReset: t`Wachtwoord herstellen`,
  Dashboard: t`Dashboard`,
  DeliveryStatus: t`Status`,
  ExternalApiClients: t`App koppelen`,
  Forbidden: t`Geen toegang`,
  ForgotPassword: t`Wachtwoord vergeten`,
  Login: t`Inloggen`,
  Management: t`Beheer`,
  Notifications: t`Mededelingen`,
  Organisations: t`Organisaties`,
  OrnImportReport: t`ORN import rapportage`,
  Page: t`Pagina`,
  ProductRequest: t`Product aanvragen`,
  ReleaseNotes: t`Release notes`,
  Root: t`Startpagina`,
  Settings: t`Instellingen`,
  SkoImportReport: t`NMO import rapportage`,
  StatisticsByOrganisation: t`Rapportage`,
  StatisticsByUser: t`Rapportage per gebruiker`,
  Status: t`Status`,
  TermsOfUse: t`Gebruiksvoorwaarden`,
  TermsOfUseDashboard: t`Gebruiksvoorwaarden dashboard`,
  Tutorials: t`Tutorials`,
  TwoFactor: t`Verificatiecode invoeren`,
  UserProfile: t`Profiel`,
  Users: t`Gebruikers`,
};

export default navigationPaths;
