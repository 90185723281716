import { Action } from "redux";

import { DashboardFilter } from "../../models/Dashboard";

// eslint-disable-next-line import/prefer-default-export
export const filterAction = (
  payload: DashboardFilter
): Action<string> & { payload: DashboardFilter } => ({
  type: "DASHBOARD_FILTER",
  payload,
});
