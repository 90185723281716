import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  Popconfirm,
  Space,
  Tooltip,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { Key } from "antd/lib/table/interface";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { DayOfWeek, DayOfWeekAll } from "../../models/DayOfWeek";
import {
  AlternationTypeChannel,
  CommercialInstruction,
  CommercialInstructionRotation,
  CommercialInstructionRotationTagon,
  emptyRotation,
  emptyRotations,
  emptyTagons,
} from "../../models/Instructions";
import { Operator, OperatorTalpa } from "../../models/Operator";
import {
  channelsCell,
  commercialsCell,
  instructionsCell,
} from "../../store/instructions/cells";
import { StoreModel } from "../../store/models";
import { getDayOfWeek } from "../../utils/date";
import RotationsTable from "../../views/instructions/RotationsTable";
import PeriodView from "../../views/PeriodView";
import ChannelSelect from "./ChannelSelect";
import CommercialSelect, { createCommercialLabel } from "./CommercialSelect";
import DayOfWeekSelect from "./DayOfWeekSelect";
import { InstructionEditMode } from "./InstructionsModalContainer";
import TagonsTableContainer from "./TagonsTableContainer";

export interface RotationsTableContainerProps {
  operator: Operator;
  instructionsId: string;
  spotLengths: { main: number[]; tagons: number[] };
  instruction: CommercialInstruction;
  canEdit: boolean;
  onEdit: (mode: InstructionEditMode) => void;
  instructionEditMode: InstructionEditMode;
}

export interface RotationForm {
  period: RangeValue<Moment>;
  commercialCode: string;
  frequency: number;
  daysOfWeek: DayOfWeek[];
  intomartCode: string;
}

const RotationsTableContainer = ({
  operator,
  instructionsId,
  spotLengths,
  instruction,
  canEdit,
  onEdit,
  instructionEditMode,
}: RotationsTableContainerProps): JSX.Element => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [form] = Form.useForm<RotationForm>();

  // initialize data
  const [rotations, setRotations] = useState(emptyRotations);
  useEffect(() => {
    setRotations(instruction.rotations ?? emptyRotations);
  }, [instruction.rotations]);

  // Keep original rotation for cancel action
  const [original, setOriginal] =
    useState<CommercialInstructionRotation>(emptyRotation);

  // Rotation edit state
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    if (canEdit) {
      // Get commercials
      dispatch(commercialsCell.require(instructionsId));
      if (instruction.orderId) {
        // Get channels
        dispatch(channelsCell.require(instruction.orderId));
      }
    }
  }, [canEdit, dispatch, editIndex, instruction.orderId, instructionsId]);

  // Add a new rotation
  const handleAddRotation = useCallback(() => {
    const newRotation: CommercialInstructionRotation = {
      rotationId: "new",
      startDate: instruction.startDate,
      endDate: instruction.endDate,
      sequenceNumber:
        rotations.length === 0
          ? 1
          : Math.max(...rotations.map((r) => r.sequenceNumber)) + 1,
      daysOfWeek: DayOfWeekAll,
      frequency: 100,
      tagons: emptyTagons,
    };

    const newRotations = [...rotations, newRotation];

    // update
    setRotations(newRotations);

    // Fill form
    form.setFieldsValue({
      period: [moment(newRotation.startDate), moment(newRotation.endDate)],
      commercialCode: newRotation.commercial?.code,
      frequency: newRotation.frequency,
      daysOfWeek: newRotation.daysOfWeek,
      intomartCode: newRotation.channel?.intomartCode,
    });
    setOriginal(newRotation);

    // Edit mode ON
    setEditIndex(newRotations.length - 1);
    onEdit("Rotation");
  }, [form, instruction.endDate, instruction.startDate, onEdit, rotations]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const onExpandedRowsChange = useCallback((keys: readonly Key[]) => {
    setExpandedRowKeys(keys as string[]);
  }, []);

  // Add a new togon
  const handleAddTagon = useCallback(
    (index = -1) => {
      const { tagons, ...editedRotation } = rotations[index];
      const newTagon: CommercialInstructionRotationTagon = {
        sequenceNumber:
          tagons.length === 0
            ? 1
            : Math.max(...tagons.map(({ sequenceNumber }) => sequenceNumber)) +
              1,
      };

      const newRotations = [
        ...rotations.slice(0, index),
        { ...editedRotation, tagons: [...tagons, newTagon] },
        ...rotations.slice(index + 1),
      ];

      const newInstructions: CommercialInstruction[] = [
        { ...instruction, rotations: newRotations },
      ];

      setExpandedRowKeys([
        ...expandedRowKeys,
        JSON.stringify({ ...editedRotation }),
      ]);

      // update
      dispatch(
        instructionsCell.update(
          {
            id: instructionsId,
            instructions: newInstructions,
          },
          {
            onSuccess: () => {
              setOriginal(emptyRotation);
              message.success(i18n._(t`Tagon is toegevoegd.`));
            },

            onFail: () => {
              message.error(
                i18n._(t`Er ging iets mis met het toevoegen van de tagon.`)
              );
            },
          }
        )
      );
    },
    [dispatch, expandedRowKeys, i18n, instruction, instructionsId, rotations]
  );

  // Edit rotations and set form
  const handleEdit = useCallback(
    (edit: boolean, index = -1) => {
      form.resetFields();

      if (edit) {
        // Fill form
        const rotation = rotations[index];
        form.setFieldsValue({
          period: [moment(rotation.startDate), moment(rotation.endDate)],
          commercialCode: rotation.commercial?.code,
          frequency: rotation.frequency,
          daysOfWeek: rotation.daysOfWeek,
          intomartCode: rotation.channel?.intomartCode,
        });
        setOriginal(rotation);

        // Edit mode ON
        setEditIndex(index);
        onEdit("Rotation");
      } else {
        // Cancel
        setOriginal(emptyRotation);

        setEditIndex(-1);
        onEdit("None");
      }
    },
    [form, onEdit, rotations]
  );

  const handleDelete = useCallback(
    (index: number) => {
      const newRotations: CommercialInstructionRotation[] = rotations.filter(
        (_, idx) => idx !== index
      );

      if (rotations[index].rotationId === "new") {
        setRotations(newRotations);
      } else {
        const newInstructions: CommercialInstruction[] = [
          { ...instruction, rotations: newRotations },
        ];
        dispatch(
          instructionsCell.update(
            {
              id: instructionsId,
              instructions: newInstructions,
            },
            {
              onSuccess: () => {
                setOriginal(emptyRotation);
                message.success(i18n._(t`Rotatie is verwijderd.`));
              },

              onFail: () => {
                message.error(
                  i18n._(
                    t`Er ging iets mis met het verwijderen van de rotatie.`
                  )
                );
              },
            }
          )
        );
      }
    },
    [dispatch, i18n, instruction, instructionsId, rotations]
  );

  const updateTagons = useCallback(
    (index: number) =>
      (
        newTagons: CommercialInstructionRotationTagon[],
        onSuccess?: () => void,
        onFail?: () => void
      ) => {
        const editedRotation = rotations[index];
        const newRotations = [
          ...rotations.slice(0, index),
          { ...editedRotation, tagons: newTagons },
          ...rotations.slice(index + 1),
        ];

        const newInstructions: CommercialInstruction[] = [
          { ...instruction, rotations: newRotations },
        ];

        // update
        dispatch(
          instructionsCell.update(
            {
              id: instructionsId,
              instructions: newInstructions,
            },
            {
              onSuccess: () => {
                setOriginal(emptyRotation);
                message.success(i18n._(t`Tagons zijn aangepast.`));
                onSuccess?.();
              },

              onFail: () => {
                message.error(
                  i18n._(t`Er ging iets mis met het aanpassen van de tagons.`)
                );
                onFail?.();
              },
            }
          )
        );
      },
    [dispatch, i18n, instruction, instructionsId, rotations]
  );

  const commercialsFromStore = useSelector(
    ({ instructions: { commercials: fromStore } }: StoreModel) =>
      fromStore[instructionsId]
  );

  const { loading: commercialsLoading, commercials } = useMemo(
    () => ({
      loading: commercialsFromStore?.status.loading ?? false,
      commercials: commercialsFromStore?.value?.commercials ?? [],
    }),
    [
      commercialsFromStore?.status.loading,
      commercialsFromStore?.value?.commercials,
    ]
  );

  const channelsFromStore = useSelector(
    ({ instructions: { channels: fromStore } }: StoreModel) =>
      instruction.orderId ? fromStore[instruction.orderId] : undefined
  );

  const { loading: channelsLoading, channels } = useMemo(
    () => ({
      loading: channelsFromStore?.status.loading ?? false,
      channels: channelsFromStore?.value?.channels ?? [],
    }),
    [channelsFromStore?.status.loading, channelsFromStore?.value?.channels]
  );

  const handleFinishForm = useCallback(
    (formObject: RotationForm) => {
      const { period, commercialCode, frequency, daysOfWeek, intomartCode } =
        formObject;
      if (!period || !period[0] || !period[1]) {
        return;
      }

      const { rotationId, ...restProps } = original;
      const edited: CommercialInstructionRotation = {
        ...restProps,
        rotationId: rotationId === "new" ? undefined : original.rotationId,
        startDate: period[0].toDate(),
        endDate: period[1].toDate(),
        frequency,
        commercial: commercials.find((c) => c.code === commercialCode),
        daysOfWeek,
        channel: intomartCode
          ? channels.find((c) => c.intomartCode === intomartCode)
          : undefined,
      };

      const newRotations: CommercialInstructionRotation[] = [
        ...rotations.slice(0, editIndex),
        edited,
        ...rotations.slice(editIndex + 1),
      ];
      const newInstructions: CommercialInstruction[] = [
        { ...instruction, rotations: newRotations },
      ];

      dispatch(
        instructionsCell.update(
          {
            id: instructionsId,
            instructions: newInstructions,
          },
          {
            onSuccess: () => {
              setOriginal(emptyRotation);
              setEditIndex(-1);
              onEdit("None");
              message.success(i18n._(t`Rotatie opgeslagen.`));
            },

            onFail: () => {
              message.error(
                i18n._(t`Er ging iets mis met het opslaan van de rotatie.`)
              );
            },
          }
        )
      );
    },
    [
      channels,
      commercials,
      dispatch,
      editIndex,
      i18n,
      instruction,
      instructionsId,
      onEdit,
      original,
      rotations,
    ]
  );

  const handleDisabledDates = useCallback(
    (current: Moment | null) =>
      Boolean(
        current &&
          !current.isBetween(
            moment(instruction.startDate),
            moment(instruction.endDate),
            "day",
            "[]"
          )
      ),
    [instruction.endDate, instruction.startDate]
  );

  const disabled = useMemo(
    () => !["None", "Rotation"].includes(instructionEditMode) || editIndex > -1,
    [editIndex, instructionEditMode]
  );

  const columns: ColumnsType<CommercialInstructionRotation> = [
    {
      key: "sequenceNumber",
      title: "#",
      dataIndex: "sequenceNumber",
      width: 50,
    },
    {
      key: "period",
      title: i18n._(t`Periode`),
      width: 300,
      render: (_, { startDate, endDate }, index): ReactNode =>
        index === editIndex ? (
          <Form.Item
            name="period"
            rules={[{ required: true, message: i18n._(t`Verplicht`) }]}
            help={i18n._(t`Kies een periode`)}
          >
            <DatePicker.RangePicker
              format="D MMM YYYY"
              allowClear
              disabledDate={handleDisabledDates}
            />
          </Form.Item>
        ) : (
          <PeriodView from={startDate} to={endDate} type="short" />
        ),
    },
    {
      key: "commercial",
      title: i18n._(t`Commercial`),
      ellipsis: true,
      render: (_, { commercial }, index) =>
        editIndex === index && commercials ? (
          <Form.Item
            name="commercialCode"
            rules={[{ required: true, message: i18n._(t`Verplicht`) }]}
            help={i18n._(t`Kies een hoofdspot`)}
          >
            <CommercialSelect
              operator={operator}
              commercials={commercials.filter((c) =>
                spotLengths.main.includes(c.spotLength)
              )}
            />
          </Form.Item>
        ) : (
          <>{commercial ? createCommercialLabel(commercial, operator) : "-"}</>
        ),
    },
    {
      key: "spotLength",
      width: 100,
      title: i18n._(t`Lengte`),
      render: ({ commercial }) =>
        commercial ? <>{commercial?.spotLength}&quot;</> : <>-</>,
    },
    {
      key: "frequency",
      width: 100,
      title: i18n._(t`Frequentie`),
      render: (_, { frequency }, index) => {
        if (
          editIndex === index &&
          instruction.alternationType === AlternationTypeChannel &&
          operator === OperatorTalpa
        ) {
          // Read-only => value `1`, will be fixed when submitting to Talpa-backoffice
          return <>1</>;
        }

        return editIndex === index ? (
          <Form.Item
            name="frequency"
            rules={[{ required: true, message: i18n._(t`Verplicht`) }]}
            help={i18n._(t`Verdeling`)}
          >
            <InputNumber min={1} max={100} step={1} />
          </Form.Item>
        ) : (
          <>{!frequency ? "-" : frequency}</>
        );
      },
    },
    {
      key: "channel",
      title: i18n._(t`Zender`),
      width: 150,
      render: (_, { channel }, index): ReactNode =>
        // eslint-disable-next-line no-nested-ternary
        editIndex === index ? (
          <Form.Item name="intomartCode" help={i18n._(t`Zenderkeuze`)}>
            <ChannelSelect channels={channels} />
          </Form.Item>
        ) : channel ? (
          <>{channel.description}</>
        ) : (
          <>{i18n._(t`Alle zenders`)}</>
        ),
    },
    {
      key: "daysOfWeek",
      ellipsis: true,
      title: i18n._(t`Dagen van de week`),
      render: (_, { daysOfWeek = [] }, index) =>
        editIndex === index ? (
          <Form.Item
            name="daysOfWeek"
            rules={[{ required: true, message: i18n._(t`Verplicht`) }]}
            help={i18n._(t`Dagen van de week`)}
          >
            <DayOfWeekSelect />
          </Form.Item>
        ) : (
          <>
            {daysOfWeek.length === 7
              ? i18n._(`Alle dagen`)
              : daysOfWeek.map((d) => getDayOfWeek(d, "full")).join(", ")}
          </>
        ),
    },
    {
      key: "edit",
      title: i18n._(t`Acties`),
      width: 175,
      render: (_1, rotation, index): ReactNode =>
        editIndex === index ? (
          <Space size="small">
            <Tooltip title={i18n._(t`Opslaan`)}>
              <Button
                key="save"
                icon={<SaveOutlined />}
                type="link"
                htmlType="submit"
                size="small"
              />
            </Tooltip>
            <Tooltip title={i18n._(t`Annuleren`)}>
              <Button
                key="cancel"
                icon={<UndoOutlined />}
                type="link"
                size="small"
                onClick={() => {
                  handleEdit(false);
                  if (rotation.rotationId === "new") {
                    handleDelete(index);
                  }
                }}
              />
            </Tooltip>
          </Space>
        ) : (
          <Space size="small">
            {instruction.id && (
              <Tooltip title={i18n._(t`Voeg een lege tagon toe`)}>
                <Button
                  key="add-tagon"
                  icon={<PlusOutlined />}
                  type="link"
                  size="small"
                  onClick={() => {
                    handleAddTagon(index);
                  }}
                  disabled={disabled || spotLengths.tagons.length === 0}
                >
                  {" "}
                  {i18n._(t`Tagon`)}
                </Button>
              </Tooltip>
            )}
            <Tooltip title={i18n._(t`Bewerken`)}>
              <Button
                key="edit"
                icon={<EditOutlined />}
                type="link"
                size="small"
                onClick={() => handleEdit(true, index)}
                disabled={disabled}
              />
            </Tooltip>
            {instruction.id && (
              <Popconfirm
                title={i18n._(
                  t`Weet je zeker dat je deze rotatie wilt verwijderen?`
                )}
                onConfirm={() => handleDelete(index)}
                cancelButtonProps={{ type: "link" }}
                disabled={
                  !["None", "Rotation"].includes(instructionEditMode) ||
                  editIndex > -1
                }
              >
                <Tooltip title={i18n._(t`Verwijderen`)}>
                  <Button
                    key="delete"
                    icon={<DeleteOutlined />}
                    type="link"
                    size="small"
                    disabled={disabled}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </Space>
        ),
    },
  ];

  const table = (
    <RotationsTable
      columns={columns.filter((c) => (!canEdit && c.key !== "edit") || canEdit)}
      rotations={rotations}
      loading={commercialsLoading || channelsLoading}
      expandedRowKeys={expandedRowKeys}
      onExpandedRowsChange={onExpandedRowsChange}
      tagonsRender={(rotation, index) => (
        <TagonsTableContainer
          operator={operator}
          tagons={rotation.tagons}
          commercials={commercials}
          onChange={updateTagons(index)}
          spotLengths={spotLengths.tagons}
          instructionEditMode={instructionEditMode}
          onEdit={onEdit}
          canEdit={canEdit}
        />
      )}
    />
  );

  return instruction.status !== "Concept" ? (
    table
  ) : (
    <Space direction="vertical">
      <Space>
        <Button
          icon={<PlusOutlined />}
          type="default"
          onClick={handleAddRotation}
          disabled={
            !["None", "Rotation"].includes(instructionEditMode) ||
            !instruction.id ||
            editIndex > -1
          }
        >
          {i18n._(t`Voeg rotatie toe`)}
        </Button>
      </Space>

      {rotations.length > 0 && editIndex > -1 ? (
        <Form form={form} onFinish={handleFinishForm} autoComplete="off">
          {table}
        </Form>
      ) : (
        table
      )}
    </Space>
  );
};

export default RotationsTableContainer;
