import { Moment } from "moment";

import Advertiser from "../../../models/Advertiser";
import {
  OrderRequest,
  OrderRequestStatus,
  OrderRequestsByAdvertiser,
} from "../../../models/Campaigns/Requests/models";
import {
  PreferredPosition,
  preferredPositionBeforeLastInBlock,
  preferredPositionFirstInBlock,
  preferredPositionLastInBlock,
  preferredPositionSecondInBlock,
} from "../../../models/Spot";

export interface OrderRequestCreateRequest {
  orderRequest: OrderRequest;
}

export interface OrderRequestCreateResponse {
  id: string;
}

export interface OrderRequestGetResponse {
  orderRequest: OrderRequest;
}

export interface OrderRequestDeleteResponse {
  result: "Success" | "DoesNotExist" | "Submitted" | "NoChanges";
}

export interface OrderRequestsByAdvertiserGetRequest {
  advertiserId?: string;
  period: [Moment, Moment];
  status?: OrderRequestStatus;
}

export interface OrderRequestsByAdvertiserGetResponse {
  orderRequestsByAdvertiser: OrderRequestsByAdvertiser[];
}

export interface OrderRequestUpdateRequest {
  orderRequest: OrderRequest;
}

export interface OrderRequestSubmissionRequest {
  id: string;
  sendConfirmation: boolean;
}

export interface AvailableBreaksRequest {
  dateFrom: Moment;
  dateTo: Moment;
  spotLengths: number[];
  packageCode: string;
  targetGroupId: string;
  intomartId: string;
  productId?: string; // Ster
  orderId?: string; // RTL/Talpa
  subOrderId?: string;
}

export interface Channel {
  description: string;
  intomartCode?: string;
  budget?: number;
}

export interface AvailableBreakFinances {
  baseBreakPrice: number;
  positionFree: string;
  grossSpotPrice: number;
  netSpotPrice: number;
}

export interface Break {
  id: string;
  breakId: string;
  channel: Channel;
  finances: AvailableBreakFinances;
  scheduledDate: Date;
  scheduledStartTime: Date;
  positionFreeString: string;
  predictedRating: number;
  programBefore: string;
  programAfter: string;
  selectivity: number;
  uniqueId: string;
}

export const positionsFreeArray: PreferredPosition[] = [
  preferredPositionFirstInBlock,
  preferredPositionSecondInBlock,
  preferredPositionBeforeLastInBlock,
  preferredPositionLastInBlock,
];

export const positionsFree = (
  positionsFreeString: string
): PreferredPosition[] => {
  const positionsFreeSet = new Set<PreferredPosition>();
  if (!positionsFreeString || positionsFreeString === "NNNN") {
    return [];
  }
  for (let i = 0; i < positionsFreeString.length; i += 1) {
    if (positionsFreeString[i] === "J") {
      positionsFreeSet.add(positionsFreeArray[i]);
    }
  }
  return Array.from<PreferredPosition>(positionsFreeSet);
};

export interface OpenOrderRequest {
  id: string;
  advertiser: Advertiser;
  startDate: Date;
  endDate: Date;
  campaignName: string;
}

export interface OpenOrderRequests {
  numberOfRequests: number;
  orderRequests: OpenOrderRequest[];
}

export interface TargetPeriod {
  startDate: Date;
  endDate: Date;
}

export interface CopyOrderRequestResponse {
  id: string;
  targetPeriod: TargetPeriod;
}
