import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Input, Select } from "antd";
import React, { memo, useCallback } from "react";

import { getAdvertiserName } from "../../utils";
import { advertiserIdField, advertiserNameField } from "./constants";
import { ProductAdvertiserSelectProps } from "./models";

const ProductAdvertiserSelect = memo(
  ({
    form: { setFieldsValue },
    loadingAdvertisers,
    advertisers,
  }: ProductAdvertiserSelectProps) => {
    const { i18n } = useLingui();

    const handleAdvertiserSelect = useCallback(
      (advertiserId: string) => {
        setFieldsValue({
          [advertiserIdField]: advertiserId,
          [advertiserNameField]: getAdvertiserName(advertisers, advertiserId),
        });
      },
      [advertisers, setFieldsValue]
    );

    return (
      <>
        <Form.Item name={advertiserNameField} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name={advertiserIdField}
          label={i18n._(t`Adverteerder`)}
          help={i18n._(
            t`Selecteer de adverteerder waarvoor je een nieuw product wilt aanvragen`
          )}
          rules={[{ required: true, message: "Adverteerder is verplicht." }]}
        >
          <Select
            loading={loadingAdvertisers}
            showSearch
            optionFilterProp="title"
            onChange={handleAdvertiserSelect}
          >
            {advertisers.map((a) => {
              const title = getAdvertiserName(advertisers, a.id);
              return (
                <Select.Option key={a.id} value={a.id} title={title}>
                  {title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </>
    );
  }
);

export default ProductAdvertiserSelect;
