import { Row, Typography } from "antd";
import React, { ReactNode } from "react";

interface Props {
  title: ReactNode;
  children: ReactNode;
  style?: React.CSSProperties;
}

const DashboardDataContainer = ({ title, children, style }: Props) => (
  <div style={{ marginBottom: 16, ...style }}>
    <Typography.Title>{title}</Typography.Title>

    <Row gutter={[16, 16]}>{children}</Row>
  </div>
);

export default DashboardDataContainer;
