import {
  IdcardOutlined,
  ReconciliationOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import React, { ReactElement } from "react";

import {
  fieldBudgetName,
  fieldDaysOfWeekName,
  fieldGrpsName,
  fieldOperatorName,
  fieldPackageName,
  fieldProductName,
  fieldRemarksName,
  fieldSpotLengthsName,
  fieldSpotsName,
  fieldSubOrderPeriodName,
  fieldTargetGroupName,
} from "../../../../models/Campaigns/Requests/models";
import { Step } from "./models";

export const formName = "CreateSuborder";

export const stepFieldsMap: { [step in Step]: string[] } = {
  0: [fieldOperatorName],
  1: [fieldProductName],
  2: [
    fieldSubOrderPeriodName,
    fieldPackageName,
    fieldTargetGroupName,
    fieldSpotLengthsName,
    fieldDaysOfWeekName,
  ],
  3: [fieldSpotsName, fieldBudgetName, fieldGrpsName],
  4: [fieldRemarksName],
};

export const stepIcons: { [step in Step]: ReactElement } = {
  0: <IdcardOutlined />,
  1: <ShoppingOutlined />,
  2: <UnorderedListOutlined />,
  3: <ReconciliationOutlined />,
  4: <SolutionOutlined />,
};

export const ornPackageCodeGrps = "GRP";
export const ornPackageCodeSpecific = "Specific";
