import moment, { Moment } from "moment";

import { DayOfWeek } from "../models/DayOfWeek";

/**
 * Method to format a Moment-date object to a valid string
 * for a query-string parameter with NL-timezone
 * @param momentObj
 */
// eslint-disable-next-line import/prefer-default-export
export const formatDateForUri = (momentObj: Moment): string =>
  encodeURIComponent(momentObj.toISOString(true));

/**
 * Method to create a period query-string to use in an URL
 * @param dateFrom
 * @param dateTo
 */
export const getPeriodQueryString = (
  dateFrom: Moment,
  dateTo: Moment
): string =>
  `dateFrom=${formatDateForUri(
    dateFrom.startOf("day")
  )}&dateTo=${formatDateForUri(dateTo.endOf("day"))}`;

/**
 * Method to format a date string
 * @param date
 */
// eslint-disable-next-line import/prefer-default-export
export const shortDateFormat = (date: Date): string =>
  // eslint-disable-next-line no-undef
  moment(date).format("D MMM");

/**
 * Method to get the first day of a new order request; if tomorrow is saturday,
 * sunday or monday, the next tuesday needs to be returned.
 */
export const getFirstBookingDay = (): Moment => {
  const tomorrow = moment().add(1, "day").startOf("day");

  switch (tomorrow.isoWeekday()) {
    case 6: // zaterdag
      return tomorrow.add(3, "day");
    case 7: // zondag
      return tomorrow.add(2, "day");
    case 1: // maandag
      return tomorrow.add(1, "day");
    default:
      return tomorrow;
  }
};

export const getDayOfWeek = (
  dayOfWeek: DayOfWeek,
  type: "short" | "full" = "short"
): string => {
  const locals =
    type === "short" ? moment.weekdaysShort(true) : moment.weekdays(true);

  switch (dayOfWeek) {
    case DayOfWeek.Tuesday:
      return locals[1];
    case DayOfWeek.Wednesday:
      return locals[2];
    case DayOfWeek.Thursday:
      return locals[3];
    case DayOfWeek.Friday:
      return locals[4];
    case DayOfWeek.Saturday:
      return locals[5];
    case DayOfWeek.Sunday:
      return locals[6];
    case DayOfWeek.Monday:
    default:
      return locals[0];
  }
};

export interface DatePeriod {
  from: Moment;
  to: Moment;
}

export const toPeriod = (from: Date, to: Date): DatePeriod => ({
  from: moment(from),
  to: moment(to),
});

/**
 * Creates a `DatePeriod` object
 * @param from The start of the period as `Moment`
 * @param to The end of the period as `Moment`
 */
const createPeriod = (from: Moment, to: Moment): DatePeriod => ({
  from: from.clone(),
  to: to.clone(),
});

/**
 * Get an array of `DatePeriod` object which are available periods
 * @param source The period to check for availability
 * @param occupied Already occupied periods
 */
export const getAvailablePeriods = (
  source: DatePeriod,
  occupied: DatePeriod[]
): DatePeriod[] => {
  const result: DatePeriod[] = [];
  let period: DatePeriod | null = null;
  if (occupied.length === 0) {
    // nothing occupied
    return [source];
  }

  for (
    const m = source.from.clone();
    m.diff(source.to, "days") <= 0;
    m.add(1, "days")
  ) {
    // check for each day inside the source period if its occupied
    const isAvailable = !occupied.some((o) =>
      m.isBetween(o.from, o.to, "day", "[]")
    );

    if (!isAvailable) {
      if (period !== null) {
        // period ended
        result.push(createPeriod(period.from, m.clone().subtract(1, "day")));
      }

      period = null;
    } else {
      period = createPeriod(period?.from ?? m, m);

      if (source.to.isSame(m, "day")) {
        // period ended
        result.push(period);
        period = null;
      }
    }
  }

  return result;
};
