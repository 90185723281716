import { Form, Input } from "antd";
import React, { ReactElement } from "react";

import User from "../../../models/User";
import propertyOf from "../../../utils/properties";

const idField = propertyOf<User>("id");

interface UserFormItemIdProps {
  value: string;
}

const UserFormItemId = ({ value }: UserFormItemIdProps): ReactElement => (
  <Form.Item initialValue={value} name={idField} noStyle>
    <Input type="hidden" />
  </Form.Item>
);

export default UserFormItemId;
