import { Form } from "antd";
import React, { memo } from "react";

import ProductPicker from "../fields/ProductPicker";
import {
  ProductStepReferenceValues,
  ProductStepStoreValues,
  StepProps,
} from "./models";

const ProductStep = memo(
  ({
    enabled,
    form,
    initialValues,
    onFinish,
    referenceValues: { products, selectedOperator },
  }: StepProps<
    undefined,
    ProductStepReferenceValues,
    ProductStepStoreValues
  >) => (
    <Form form={form} initialValues={initialValues} onFinish={onFinish}>
      <ProductPicker
        enabled={enabled}
        form={form}
        products={products}
        selectedOperator={selectedOperator}
      />
    </Form>
  )
);

export default ProductStep;
