import { ZoomInOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Button } from "antd";
import React, {
  FunctionComponent,
  MouseEventHandler,
  memo,
  useCallback,
} from "react";

import ItemActionButtonProps from "./ItemActionButtonProps";

/**
 * Renders a button for showing more details.
 * @constructor Initializes a new instance of ShowMoreButton.
 * @return {ReactElement<any, any> | null} The rendered component.
 */
const ShowMoreButton: FunctionComponent<ItemActionButtonProps> = ({
  itemId,
  onClick,
}) => {
  const handleClick = useCallback<MouseEventHandler>(
    (event) => {
      if (itemId && onClick) {
        onClick(itemId, event);
      }
    },
    [itemId, onClick]
  );

  return (
    <Button type="link" icon={<ZoomInOutlined />} onClick={handleClick}>
      {" "}
      <Trans>Tonen</Trans>
    </Button>
  );
};

export default memo(ShowMoreButton);
