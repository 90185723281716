import { Messages, setupI18n } from "@lingui/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { PluralCategory } from "make-plural";
import React, { memo, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
// eslint-disable-next-line import/no-unresolved
import { NavigateOptions } from "react-router/dist/lib/context";

import AppLayout from "./components/AppLayout";
import catalogEn from "./locale/en-US/messages";
import catalogNl from "./locale/nl/messages";
import store from "./store";
import LocalizationProvider from "./store/localization/LocalizationProvider";
import { StoreModel } from "./store/models";
import { defaultCulture, preferredCultureKey } from "./utils/hooks";
import { initZendeskSuggestions } from "./utils/zendesk";
import { isGAEnabled } from "./views/utils/analytics";

const plurals =
  (locale: string) =>
  (count: number, ordinal?: boolean): PluralCategory =>
    new Intl.PluralRules(locale, {
      type: ordinal ? "ordinal" : "cardinal",
    }).select(count);

const enUSMessages = catalogEn.messages as unknown as Messages;
const enUSPlurals = plurals("en-us");

export const i18nSetup = setupI18n({
  locale: "nl",
  localeData: {
    "en-us": {
      plurals: enUSPlurals,
    },
    "en-US": {
      plurals: enUSPlurals,
    },
    nl: {
      plurals: plurals("nl"),
    },
  },
  locales: ["nl", "en-us", "en-US"],
  messages: {
    nl: catalogNl.messages as unknown as Messages,
    "en-us": enUSMessages,
    "en-US": enUSMessages,
  },
});

const Main = memo(() => {
  const token = useSelector(
    ({
      tokens: {
        refresh: { value },
      },
    }: StoreModel): string | undefined => value?.token
  );

  return <AppLayout refreshToken={token} />;
});

/* Workaround for missing `history` since react-router v6 */
export const history = {
  navigate: undefined,
  push: (page, ...rest) => history.navigate?.(page, ...rest),
} as {
  navigate?: NavigateFunction;
  push: (to: string, options?: NavigateOptions) => void;
};

const NavigateSetter = () => {
  const location = useLocation();

  useEffect(() => {
    const pageUri = `${location.pathname}${location.search}${location.hash}`;

    if (isGAEnabled) {
      // Record a pageview for the given page
      TagManager.dataLayer({
        dataLayer: {
          event: "page_view",
          page: pageUri,
          url: location.pathname,
        },
      });
    }

    const preferredCulture =
      localStorage.getItem(preferredCultureKey) ||
      window.tipBrowserCulture ||
      defaultCulture;
    initZendeskSuggestions(pageUri, preferredCulture);
  }, [location]);

  history.navigate = useNavigate();
  return null;
};

const App = memo(() => (
  <>
    <Helmet titleTemplate={`%s - ${window.document.title}`} />
    <BrowserRouter>
      <NavigateSetter />
      <Provider store={store}>
        <LocalizationProvider>
          <Main />
        </LocalizationProvider>
      </Provider>
    </BrowserRouter>
  </>
));

export default App;
