import { Select } from "antd";
import React, { memo, useCallback } from "react";

import { SubModule, subModules } from "../../../../models/AuthorizationRule";

const { Option } = Select;

interface SubModulesSelectProps {
  defaultValue: SubModule[];
  onSelect(key: string, value: SubModule[]): void;
  rowKey: string;
}

const SubModulesSelect = ({
  defaultValue,
  onSelect,
  rowKey,
}: SubModulesSelectProps) => {
  const handleChange = useCallback(
    (newValue: SubModule[]) => {
      onSelect(rowKey, newValue);
    },
    [rowKey, onSelect]
  );

  return (
    <Select
      allowClear
      defaultValue={defaultValue}
      mode="multiple"
      onChange={handleChange}
    >
      {subModules.map((sm) => (
        <Option key={sm} value={sm}>
          {sm}
        </Option>
      ))}
    </Select>
  );
};

export default memo(SubModulesSelect);
