import React, { CSSProperties, ReactNode, useRef } from "react";

interface Props {
  enabled?: boolean;
  children?: ReactNode;
}
const MediaCover = ({ enabled, children }: Props) => {
  const div = useRef<HTMLDivElement>(null);

  return (
    <div
      data-media-cover={enabled || null}
      ref={div}
      style={
        {
          "--mc-width": `${div.current?.offsetWidth}px`,
          "--mc-height": `${div.current?.offsetHeight}px`,
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
};

export default MediaCover;
