import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Card, Empty, Form, Spin, message } from "antd";
import { startsWith } from "lodash";
import { phone } from "phone";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChangePasswordRequest } from "../../models/User";
import { changePasswordCell } from "../../store/authentication/cells";
import { StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { userProfileCell } from "../../store/users/cells";
import MainContent from "../../views/layout/MainContent";
import UserPasswordForm from "../../views/user/UserPasswordForm";
import UserProfileForm, {
  UserProfileFormInput,
} from "../../views/user/UserProfileForm";

const UserProfile = () => {
  const { i18n } = useLingui();
  const [form] = Form.useForm<UserProfileFormInput>();
  const dispatch = useDispatch();

  const userStore = useSelector(
    ({ users: { current } }: StoreModel) => current
  );
  const userProfileStore = useSelector(userProfileCell.select);

  const handleProfileFinish = useCallback(
    ({ name, phoneNumber }: UserProfileFormInput) => {
      let cleanedPhoneNumber = phoneNumber;
      if (phoneNumber) {
        const international = startsWith(phoneNumber, "+");
        const parsed = phone(
          phoneNumber,
          !international ? { country: "NL" } : undefined
        );

        cleanedPhoneNumber = parsed.phoneNumber;
      }

      dispatch(
        userProfileCell.require(
          { name, phoneNumber: cleanedPhoneNumber },
          {
            onSuccess: () => {
              message.success(i18n._(t`Profielgegevens zijn opgeslagen.`));
              dispatch({ type: sagaTypes.users.current.request });

              // Set cleaned phone number in form to prevent validation errors
              form.setFieldsValue({ name, phoneNumber: cleanedPhoneNumber });
            },
            onFail: () => {
              message.error(i18n._(t`Er is iets misgegaan.`));
            },
          }
        )
      );
    },
    [dispatch, form, i18n]
  );

  const changePasswordStore = useSelector(changePasswordCell.select);
  const handlePasswordFinish = useCallback(
    ({
      currentPassword,
      newPassword,
      confirmPassword,
    }: ChangePasswordRequest) => {
      dispatch(
        changePasswordCell.require(
          { currentPassword, newPassword, confirmPassword },
          {
            onSuccess: () => {
              message.success(i18n._(t`Nieuwe wachtwoord is opgeslagen.`));
            },
            onFail: () => {
              message.error(
                i18n._(
                  t`Klopt je huidige wachtwoord wel? Jouw wachtwoord is niet aangepast.`
                )
              );
            },
          }
        )
      );
    },
    [dispatch, i18n]
  );

  return (
    <MainContent>
      <Card type="inner" title={i18n._(t`Profielgegevens`)}>
        <Spin
          spinning={userStore.status.loading || userProfileStore.status.loading}
        >
          {userStore.value ? (
            <UserProfileForm
              form={form}
              onFinish={handleProfileFinish}
              values={{
                name: userStore.value?.name,
                emailAddress: userStore.value?.emailAddress,
                organisation: userStore.value?.organisation?.name ?? "",
                phoneNumber: userStore.value?.phoneNumber,
              }}
              loading={
                userStore.status.loading || userProfileStore.status.loading
              }
            />
          ) : (
            <Empty />
          )}
        </Spin>
      </Card>

      <Card type="inner" title={i18n._(t`Wachtwoord`)}>
        <Spin spinning={changePasswordStore.status.loading}>
          <UserPasswordForm
            onFinish={handlePasswordFinish}
            loading={changePasswordStore.status.loading}
          />
        </Spin>
      </Card>
    </MainContent>
  );
};

export default UserProfile;
