/* eslint-disable jsx-a11y/anchor-is-valid, react/jsx-no-script-url, no-script-url */
import { CalendarOutlined } from "@ant-design/icons";
import { useLingui } from "@lingui/react";
import { Modal } from "antd";
import React, { MouseEvent, useCallback } from "react";

import { CalendarEvent } from "../../models/Calendar";
import CalendarEventContent from "./CalendarEventContent";

const CalendarEventLink = ({ event }: { event: CalendarEvent }) => {
  const { i18n } = useLingui();
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      Modal.info({
        title: event.title,
        icon: <CalendarOutlined />,
        content: <CalendarEventContent event={event} i18n={i18n} />,
      });
    },
    [event, i18n]
  );

  return (
    <a
      className="calendarEventLink"
      href="javascript:void(0);"
      onClick={handleClick}
    >
      <CalendarOutlined />
      <span style={{ margin: "0 8px" }}>{event.title}</span>
    </a>
  );
};

export default CalendarEventLink;
