import { Col, Row } from "antd";
import React, { Children, ReactNode, memo } from "react";

interface OrganisationsContentProps {
  children?: ReactNode;
}

const OrganisationsContent = memo(({ children }: OrganisationsContentProps) => (
  <section className="organisationsContent">
    {children &&
      Children.map(children, (child) => (
        <Row>
          <Col span={24}>{child}</Col>
        </Row>
      ))}
  </section>
));

export default OrganisationsContent;
