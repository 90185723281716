import { map } from "lodash/fp";
import React, { ReactNode } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { COLORS } from "./ChartDot";
import ChartTooltip from "./ChartTooltip";

export interface BudgetPieChartProps<TData> {
  data: TData[];
  labelGetter: (data: TData) => string;
  nameGetter: (data: TData) => string;
  valueGetter: (data: TData) => number;
  renderValue: (value: number) => ReactNode;
}

const RADIAN = Math.PI / 180;

const BudgetPieChart = <TData,>({
  data,
  labelGetter,
  nameGetter,
  valueGetter,
  renderValue,
}: BudgetPieChartProps<TData>) => {
  const total = data.map(valueGetter).reduce((acc, curr) => acc + curr, 0);
  return (
    <ResponsiveContainer aspect={1}>
      <PieChart>
        <Pie
          data={data
            .map((d: TData) => ({
              name: labelGetter(d),
              value: valueGetter(d),
            }))
            .filter(({ value }) => value >= total / 100)}
          cx="50%"
          cy="50%"
          labelLine={false}
          dataKey="value"
          label={({ cx, cy, innerRadius, outerRadius, midAngle, percent }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
              <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
              >
                {`${(percent * 100).toFixed(0)}%`}
              </text>
            );
          }}
        >
          {map((d: TData) => {
            const name = nameGetter(d);
            return <Cell key={`cell-${name}`} fill={COLORS[name]} />;
          })(data)}
        </Pie>
        <Tooltip content={<ChartTooltip renderValue={renderValue} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default BudgetPieChart;
