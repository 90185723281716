import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { Key } from "react";

import { CommercialInstructionRotation } from "../../models/Instructions";

export interface RotationsTableProps {
  columns: ColumnsType<CommercialInstructionRotation>;
  rotations: CommercialInstructionRotation[];
  loading: boolean;
  tagonsRender: (
    rotation: CommercialInstructionRotation,
    index: number,
    indent: number,
    expanded: boolean
  ) => React.ReactNode;
  expandedRowKeys: Key[] | undefined;
  onExpandedRowsChange: (keys: readonly Key[]) => void;
}

const RotationsTable = ({
  columns,
  rotations,
  loading,
  tagonsRender,
  expandedRowKeys,
  onExpandedRowsChange,
}: RotationsTableProps): JSX.Element => (
  <Table
    bordered
    columns={columns}
    dataSource={rotations}
    size="small"
    pagination={false}
    loading={loading}
    rowKey={({
      tagons: _,
      ...rotationProps
    }: CommercialInstructionRotation): string =>
      JSON.stringify({ ...rotationProps })
    }
    expandedRowKeys={expandedRowKeys}
    expandable={{
      onExpandedRowsChange,
      rowExpandable: ({ tagons }) => tagons?.length > 0,
      expandIcon: ({ expanded, onExpand, record }) => {
        if (!record.tagons || record.tagons.length === 0) {
          return null;
        }

        return (
          <Space style={{ marginRight: 16 }}>
            {expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            )}
          </Space>
        );
      },
      expandedRowRender: tagonsRender,
    }}
  />
);

export default RotationsTable;
