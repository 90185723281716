import { combineReducers } from "redux";

import { asyncIdReducer, asyncReducer } from "../lifecycle";
import { RequestAction } from "../models";
import sagaTypes from "../sagaTypes";
import analyzedOrdersReducer from "./analyzed/reducer";
import FetchOrdersFilteredResponse from "./FetchOrdersFilteredResponse";
import FilterModel from "./FilterModel";
import OrderWithSpotsResponse from "./OrderWithSpotsResponse";
import SelectedFilterModel, { emptyFilter } from "./SelectedFilterModel";

const filterReducer = (
  state: SelectedFilterModel | undefined,
  { type, payload }: RequestAction<SelectedFilterModel, unknown>
): SelectedFilterModel => {
  switch (type) {
    case sagaTypes.campaigns.filters.clear:
      return emptyFilter;
    case sagaTypes.campaigns.updateFilter:
      return { ...state, ...payload };
    case sagaTypes.campaigns.fetchOrdersFiltered.request:
      return payload;
    default:
      return state || emptyFilter;
  }
};

export default combineReducers({
  filters: asyncReducer<unknown, FilterModel>(sagaTypes.campaigns.filters),
  selectedFilter: filterReducer,
  orders: asyncReducer<unknown, FetchOrdersFilteredResponse>(
    sagaTypes.campaigns.fetchOrdersFiltered
  ),
  details: asyncIdReducer<unknown, OrderWithSpotsResponse>(
    sagaTypes.campaigns.spotsPerOrder
  ),
  analyzed: analyzedOrdersReducer,
});
