import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Form, Select, Typography } from "antd";
import React, { memo } from "react";

import { fieldPackageName } from "../../../../../models/Campaigns/Requests/models";
import { Package } from "../../../../../models/packages";
import PackageOptionView from "../../../../../views/campaigns/requests/subOrders/PackageOptionView";
import { labelCol, wrapperColShallow } from "../../../../../views/layout/Form";
import { PackagePickerProps } from "../models";

/**
 * Voor RTL kan helaas nog niet ieder pakket aangevraagd worden via de
 * huidige B2B; daarom de wens om hiervoor in de frontend een waarschuwing
 * te tonen bij de pakketten die niet de onderstaande zenders bedienen.
 */
const rtlChannelsEnabled = [
  0, // RTL
  61, // RTL4
  62, // RTL5
  57, // RTL7
  368, // RTL8
  463, // RTL Lounge
  502, // RTL Crime
  554, // RTLZ
  571, // ZiggoSport
  378, // History
];

const isPackageEnabled = (pkg: Package): boolean => {
  if (pkg.operator !== "Rtl") {
    return true;
  }

  if (pkg.details?.channels) {
    const channelCodes = pkg.details.channels.map((c) =>
      parseInt(c.intomartCode ?? "0", 10)
    );

    if (!channelCodes || channelCodes.length === 0) {
      return true;
    }

    return channelCodes.every((code) => rtlChannelsEnabled.includes(code));
  }

  return true;
};

const PackagePicker = memo(
  ({ enabled, onChange, packages, selectedOperator }: PackagePickerProps) => {
    const { i18n } = useLingui();

    return selectedOperator ? (
      <Form.Item
        label={i18n._(t`Pakket`)}
        labelCol={labelCol}
        name={fieldPackageName}
        rules={[
          {
            required: true,
            message: i18n._(t`Pakket is verplicht.`),
          },
        ]}
        wrapperCol={wrapperColShallow}
      >
        <Select
          disabled={!enabled}
          onChange={onChange}
          optionFilterProp="title"
          showSearch
        >
          {packages
            .filter((pkg) => pkg.operator === selectedOperator)
            .map((pkg) => {
              const isEnabled = isPackageEnabled(pkg);
              return (
                <Select.Option
                  key={pkg.code}
                  value={pkg.code}
                  title={pkg.description}
                  disabled={!isEnabled}
                >
                  {isEnabled ? (
                    <PackageOptionView pkg={pkg} />
                  ) : (
                    <PackageOptionView
                      pkg={pkg}
                      remarks={
                        <Typography.Text type="warning">
                          <Trans>
                            Dit pakket kan niet worden aangevraagd. Neem contact
                            op met Ad Alliance voor meer informatie.
                          </Trans>
                        </Typography.Text>
                      }
                    />
                  )}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
    ) : (
      <Alert
        message={i18n._(t`Selecteer eerst een exploitant.`)}
        type="warning"
      />
    );
  }
);

export default PackagePicker;
