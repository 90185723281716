import "@ant-design/compatible/assets/index.css";

import { Form } from "@ant-design/compatible";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Checkbox, Modal } from "antd";
import React, { memo, useCallback } from "react";

import propertyOf from "../../../utils/properties";
import {
  OrderRequestSubmissionModalFormValues,
  OrderRequestSubmissionModalProps,
} from "./models";

const formName = "SubmissionConfirmationForm";
const sendConfirmationFieldName =
  propertyOf<OrderRequestSubmissionModalFormValues>("sendConfirmation");

const OrderRequestSubmissionModalContainer = memo(
  ({
    form: { getFieldDecorator, getFieldValue, validateFields },
    loading,
    onCancel,
    onConfirm,
    visible,
  }: OrderRequestSubmissionModalProps) => {
    const { i18n } = useLingui();

    const sendConfirmationChecked = getFieldValue(sendConfirmationFieldName);

    const handleOk = useCallback(() => {
      validateFields((errors, { sendConfirmation }) => {
        if (!errors) {
          onConfirm(sendConfirmation);
        }
      });
    }, [onConfirm, validateFields]);

    return (
      <Modal
        confirmLoading={loading}
        onCancel={onCancel}
        onOk={handleOk}
        okText={i18n._(t`Indienen`)}
        title={i18n._(t`Aanvraag indienen`)}
        open={visible}
        maskClosable={false}
        keyboard={false}
      >
        <Form>
          <Form.Item>
            {getFieldDecorator(sendConfirmationFieldName, {
              initialValue: false,
            })(
              <Checkbox checked={sendConfirmationChecked}>
                <Trans>Stuur een bevestiging van de aanvraag per e-mail</Trans>
              </Checkbox>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default Form.create<OrderRequestSubmissionModalProps>({
  name: formName,
})(OrderRequestSubmissionModalContainer);
