import User from "../../models/User";
import { jsonGetter, jsonPoster } from "../fetch";
import {
  ApiPromise,
  RequestResetUserPassword,
  ResetVerifyRequest,
  TokenApiPromise,
} from "../models";

export const getCurrentUser = (): TokenApiPromise<User> =>
  jsonGetter<User, User>("/api/user");

export const requestResetPassword =
  (request: RequestResetUserPassword): TokenApiPromise<string> =>
  (): ApiPromise<string> =>
    jsonPoster<string, RequestResetUserPassword>("/api/user/password/reset")(
      request
    );

export const resetVerifyPassword =
  (verify: ResetVerifyRequest): TokenApiPromise<string> =>
  (): ApiPromise<string> =>
    jsonPoster<string, ResetVerifyRequest>("/api/user/password/verify")(verify);
