import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Input, Modal, Tooltip, notification } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { Operator } from "../../../models/Operator";
import {
  organisationSetPasswordCell,
  organisationsCell,
} from "../../../store/organisations/cells";
import OrganisationOperatorHasPasswordIcon from "./OrganisationOperatorHasPasswordIcon";

interface OrganisationUpdatePasswordProps {
  disabled?: boolean;
  organisationId: string;
  operator: Operator;
  hasPassword: boolean;
}

type OrganisationUpdatePasswordDialogProps = Omit<
  OrganisationUpdatePasswordProps,
  "disabled" | "hasPassword"
> & {
  open: boolean;
  onClose: () => void;
};

const OrganisationSetPasswordDialog = (
  props: OrganisationUpdatePasswordDialogProps
) => {
  const { organisationId, operator, open, onClose } = props;
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState<string | undefined>();
  const { i18n } = useLingui();

  const handleCancel = useCallback(() => {
    setPassword(undefined);
    onClose();
  }, [onClose]);

  const handleOk = useCallback(() => {
    setPassword(undefined);
    setDisabled(true);
    dispatch(
      organisationSetPasswordCell.require(
        {
          organisationId,
          operator,
          password,
        },
        {
          onSuccess: () => {
            onClose();
            dispatch({ type: organisationsCell.events.invalidate });
            dispatch(organisationsCell.require());
          },
          onFail: () => {
            notification.error({
              message: i18n._("Geen toegang met dit wachtwoord."),
            });
            setDisabled(false);
          },
        }
      )
    );
  }, [dispatch, i18n, onClose, operator, organisationId, password]);

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      title={i18n._(t`Wachtwoord instellen`)}
    >
      <Input.Password
        disabled={disabled}
        onChange={(e) => setPassword(e.currentTarget.value)}
        value={password}
      />
    </Modal>
  );
};

const OrganisationUpdatePassword = (props: OrganisationUpdatePasswordProps) => {
  const { disabled, organisationId, operator, hasPassword } = props;
  const [open, setOpen] = useState(false);

  const { i18n } = useLingui();

  if (!(["Rtl", "Talpa"] as Operator[]).includes(operator)) {
    return null;
  }

  return (
    <>
      <Tooltip title={i18n._(t`Wachtwoord`)}>
        <Button disabled={disabled} onClick={() => setOpen(true)}>
          <OrganisationOperatorHasPasswordIcon hasPassword={hasPassword} />
        </Button>
      </Tooltip>
      {!disabled && (
        <OrganisationSetPasswordDialog
          organisationId={organisationId}
          operator={operator}
          onClose={() => setOpen(false)}
          open={open}
        />
      )}
    </>
  );
};

export default OrganisationUpdatePassword;
