import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { ReactNode, memo } from "react";

interface BackButtonProps {
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  onClick: () => void;
}

const BackButton = memo(
  ({ children, disabled, loading, onClick, title }: BackButtonProps) => (
    <div className="backButton">
      <Button
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        title={title}
        type="link"
        icon={<LeftOutlined />}
      >
        {children}
      </Button>
    </div>
  )
);

export default BackButton;
