import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Col, Form, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { saveAs } from "file-saver";
import moment, { Moment } from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldData, InternalNamePath } from "rc-field-form/lib/interface";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  FocusEvent,
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import Advertiser from "../../../../models/Advertiser";
import { CampaignSubOrderValues } from "../../../../models/Campaigns/Requests/models";
import { Operator } from "../../../../models/Operator";
import { Package } from "../../../../models/packages";
import { Period } from "../../../../models/periods";
import { Product } from "../../../../models/products";
import SpotLengthIndex from "../../../../models/SpotLengthIndex";
import { ExportConceptRequest } from "../../../../store/exports/models";
import { HttpStatusCode } from "../../../../store/fetch";
import { StoreModel } from "../../../../store/models";
import sagaTypes from "../../../../store/sagaTypes";
import { handleFailWithProblem } from "../../../../utils";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../../../views/layout/Form";
import {
  orderRequestDownloadCategory,
  triggerAnalyticsEvent,
} from "../../../../views/utils/analytics";
import PreviousNextButtons from "../../../actions/PreviousNextButtons";
import {
  createStateNumeric,
  fieldAdvertiserId,
  fieldEndDateName,
  fieldIdName,
  fieldStartDateName,
  fieldSubOrdersName,
  formName,
} from "../constants";
import { OrderRequestWizardState, SubOrderCallbackProps } from "../models";
import SubOrdersTable from "../subOrders/SubOrdersTable";
import CampaignAdvertiserPicker from "./CampaignAdvertiserPicker";
import CampaignNameField from "./CampaignNameField";
import CampaignPeriodPicker from "./CampaignPeriodPicker";

interface CampaignFormProps {
  advertiserHasPackages: boolean;
  advertiserHasProducts: boolean;
  advertiserOnlyHasSpecificPackage: boolean;
  advertisers: Advertiser[];
  advertisersLoading: boolean;
  fieldsCampaignEnabled: boolean;
  form: FormInstance;
  hasConcepts: boolean;
  onAdvertiserIdChanged: (advertiserId: string) => void;
  onCampaignNameBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onFinishRequest: () => void;
  onNext: () => void;
  onPeriodChanged: (period: RangeValue<Moment>) => void;
  operators: Operator[];
  orderRequestEnablesEditing: boolean;
  packages: Package[];
  periods: Period[];
  periodsLoading: boolean;
  products: Product[];
  spotLengthIndices: SpotLengthIndex[];
  subOrders: CampaignSubOrderValues[];
  wizardState: OrderRequestWizardState;
}

const CampaignForm: FunctionComponent<
  CampaignFormProps & SubOrderCallbackProps
> = ({
  advertisers,
  advertisersLoading,
  advertiserHasPackages,
  advertiserHasProducts,
  advertiserOnlyHasSpecificPackage,
  fieldsCampaignEnabled,
  form,
  hasConcepts,
  onAddSubOrder,
  onAdvertiserIdChanged,
  onDeleteSubOrder,
  onCampaignNameBlur,
  onDelete,
  onDuplicateSubOrder,
  onEditSubOrder,
  onFinishRequest,
  onNext,
  onPeriodChanged,
  operators,
  orderRequestEnablesEditing,
  packages,
  periods,
  periodsLoading,
  products,
  spotLengthIndices,
  subOrders,
  wizardState,
}: CampaignFormProps & SubOrderCallbackProps) => {
  const { getFieldValue, setFieldsValue } = form;

  const enableEdit = useMemo(
    /**
     * Determines whether the order request may be edited.
     */
    () => wizardState === "ComposeOrder" && orderRequestEnablesEditing,
    [wizardState, orderRequestEnablesEditing]
  );

  const loading = useMemo(
    /**
     * Determines whether data is loading.
     */
    () => advertisersLoading || periodsLoading,
    [advertisersLoading, periodsLoading]
  );

  const handleFieldsChange = useCallback(
    /**
     * Handles a change in the form's fields.
     * @param changedFields The changed fields.
     */
    (changedFields: FieldData[]) => {
      const advertiserIdField = changedFields.find(
        (f) =>
          Array.isArray(f.name) &&
          (f.name as InternalNamePath)[0] === fieldAdvertiserId
      );
      const periodField = changedFields.find(
        (f) =>
          Array.isArray(f.name) &&
          (f.name as InternalNamePath)[0] === fieldStartDateName
      );
      if (advertiserIdField) {
        onAdvertiserIdChanged(advertiserIdField.value);
      }
      if (periodField) {
        const startDate = getFieldValue(fieldStartDateName);
        const endDate = getFieldValue(fieldEndDateName);
        if (startDate && endDate) {
          onPeriodChanged([moment(startDate), moment(endDate)]);
        }
      }
    },
    [getFieldValue, onAdvertiserIdChanged, onPeriodChanged]
  );

  useEffect(
    /**
     * Whenever the suborders change externally, update the value of the form.
     */
    () =>
      subOrders
        ? (): void => {
            setFieldsValue({ [fieldSubOrdersName]: subOrders });
          }
        : (): void => {
            /* do nothing */
          },
    [setFieldsValue, subOrders]
  );

  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const isConceptLoading = useSelector(
    ({
      exports: {
        byConceptExcel: { status },
      },
    }: StoreModel) => status.loading
  );

  const handleExportConcept = useCallback(() => {
    const orderRequestId = getFieldValue(fieldIdName);
    if (orderRequestId) {
      dispatch({
        type: sagaTypes.exports.conceptExcel.request,
        payload: { orderRequestId } as ExportConceptRequest,
        onFail: handleFailWithProblem(i18n._(t`Er is iets misgegaan.`)),
        onSuccess: (_statusCode?: HttpStatusCode, response?: Blob) => {
          if (!response) {
            return;
          }

          const filename = `Concept_Order_${orderRequestId}.xlsx`;
          triggerAnalyticsEvent(orderRequestDownloadCategory, orderRequestId);
          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(response, filename);
          } else {
            saveAs(response, filename);
          }
        },
      });
    }
  }, [dispatch, getFieldValue, i18n]);

  return (
    <Form
      form={form}
      initialValues={{
        [fieldIdName]: "",
        [fieldSubOrdersName]: [],
      }}
      name={formName}
      onFieldsChange={handleFieldsChange}
      labelCol={labelCol}
      wrapperCol={wrapperColShallow}
    >
      <Form.Item name={fieldIdName} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Row>
        <Col span={24}>
          <CampaignPeriodPicker
            enabled={fieldsCampaignEnabled}
            form={form}
            periods={periods}
            periodsLoading={periodsLoading}
          />
          <CampaignAdvertiserPicker
            advertiserHasProducts={advertiserHasProducts}
            advertiserHasPackages={advertiserHasPackages}
            advertiserOnlyHasSpecificPackage={advertiserOnlyHasSpecificPackage}
            advertisers={advertisers}
            advertisersLoading={advertisersLoading}
            enabled={fieldsCampaignEnabled}
          />
          <CampaignNameField
            enabled={orderRequestEnablesEditing}
            onBlur={onCampaignNameBlur}
          />
          {wizardState === "SelectCampaign" && (
            <Form.Item wrapperCol={wrapperColNoLabel}>
              <PreviousNextButtons
                enableNext={advertiserHasProducts && advertiserHasPackages}
                onNext={onNext}
                showPrevious={false}
                showNext
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      {createStateNumeric[wizardState] >= createStateNumeric.ComposeOrder && (
        <Row>
          <Col span={24}>
            <SubOrdersTable
              enableEdit={enableEdit}
              hasConcepts={hasConcepts}
              loading={loading}
              onAddSubOrder={onAddSubOrder}
              onDeleteSubOrder={onDeleteSubOrder}
              onDuplicateSubOrder={onDuplicateSubOrder}
              onEditSubOrder={onEditSubOrder}
              onDeleteRequest={onDelete}
              onFinishRequest={onFinishRequest}
              operators={operators}
              products={products}
              packages={packages}
              spotLengthIndices={spotLengthIndices}
              onExport={handleExportConcept}
              isDownloading={isConceptLoading}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default memo(CampaignForm);
