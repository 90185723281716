import { SearchOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Modal, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import {
  ColumnFilterItem,
  FilterDropdownProps,
  TableRowSelection,
} from "antd/lib/table/interface";
import moment from "moment";
import React, {
  FunctionComponent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  OrderRequestSpot,
  fieldGrpsName,
  fromBreakWithPreferredPosition,
} from "../../../../../models/Campaigns/Requests/models";
import { OperatorSter } from "../../../../../models/Operator";
import { PreferredPosition } from "../../../../../models/Spot";
import {
  Break,
  positionsFree,
} from "../../../../../store/campaigns/requests/models";
import { StoreModel } from "../../../../../store/models";
import sagaTypes from "../../../../../store/sagaTypes";
import BlocksPickerTimeWindowFilter, {
  momentTime,
  timeWindows,
} from "../../../../../views/bookspot/BlocksPickerTimeWindowFilter";
import ColumnSearchFilter from "../../../../../views/campaigns/requests/subOrders/ColumnSearchFilter";
import Ellipsis from "../../../../../views/Ellipsis";
import Grps from "../../../../../views/Grps";
import {
  BlocksPickerContainerProps,
  SelectedPreferredPositionDictionary,
} from "../models";
import BlocksPicker from "./BlocksPicker";
import PreferredPositionPicker from "./PreferredPositionPicker";

export type SelectionFilterValue = "OnlySelected" | "All";

const emptyAvailableBreaks: Break[] = [];

const emptyPreferredPositions: PreferredPosition[] = [];

const BlocksPickerContainer: FunctionComponent<BlocksPickerContainerProps> = ({
  enabled,
  enablePreferredPositionSelection,
  fieldName,
  form,
  operator,
  packageCode,
  period,
  preferredPositions,
  productId,
  required = true,
  selectedBlocks,
  spotLengths,
  targetGroupId,
  targetGroupIntomartId,
  realGrpPrice,
}) => {
  const { i18n } = useLingui();
  const { setFieldsValue, getFieldValue } = form;
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPreferredPositions, setSelectedPreferredPositions] =
    useState<SelectedPreferredPositionDictionary>(preferredPositions);

  const [selectedRowKeys, updateSelectedRowKeys] = useState(
    (getFieldValue(fieldName) ?? emptyAvailableBreaks).map(
      (brk: Break) => brk.uniqueId
    )
  );
  const [selectionFilterValue, setSelectionFilterValue] =
    useState<SelectionFilterValue>("All");
  const handleSelectionFilter = useCallback(
    /**
     * Handles the activation of a different selection filter.
     * @param value The new value.
     */
    (value: SelectionFilterValue) => {
      setSelectionFilterValue(value);
    },
    []
  );

  const handleSelectBlocksClick = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (modalVisible) {
      dispatch({
        type: sagaTypes.campaigns.requests.availableBreaks.request,
        payload: {
          dateFrom: (period && period[0]) || moment(),
          dateTo: (period && period[1]) || moment(),
          spotLengths,
          packageCode: packageCode || "",
          targetGroupId: targetGroupId || "",
          intomartId: targetGroupIntomartId || "",
          productId: operator === OperatorSter ? productId : undefined,
          orderId: operator !== OperatorSter ? productId : undefined,
        },
        onSuccess: () => {
          if (selectedBlocks) {
            updateSelectedRowKeys(
              selectedBlocks.map(({ uniqueId }) => uniqueId)
            );
          }
        },
      });
    }
  }, [
    dispatch,
    getFieldValue,
    modalVisible,
    operator,
    packageCode,
    period,
    productId,
    selectedBlocks,
    spotLengths,
    targetGroupId,
    targetGroupIntomartId,
  ]);

  const { availableBreaks, loading } = useSelector(
    ({
      requests: { availableBreaks: availableBreaksFromStore },
    }: StoreModel) => ({
      availableBreaks: availableBreaksFromStore.value,
      loading: availableBreaksFromStore.status.loading,
    })
  );

  const channels: ColumnFilterItem[] = useMemo(
    () =>
      [
        ...new Set(
          (availableBreaks ?? emptyAvailableBreaks).map(
            ({ channel }) => channel.description
          )
        ),
      ]
        .filter((value) => value && value !== "")
        .sort()
        .map((value) => ({ text: value, value })),
    [availableBreaks]
  );

  const handlePreferredPositionChange = useCallback(
    (uid: string, newValues: PreferredPosition[]) => {
      setSelectedPreferredPositions({
        ...selectedPreferredPositions,
        [uid]: newValues,
      });
    },
    [selectedPreferredPositions, setSelectedPreferredPositions]
  );

  const handleRowSelectionChange = useCallback(
    (rowKeys: string[]) => {
      updateSelectedRowKeys(rowKeys);
      const preferredPositionKeys = Object.keys(selectedPreferredPositions);
      const preferredPositionKeysMissing = preferredPositionKeys.filter(
        (key) => !rowKeys.some((s) => s === key)
      );
      preferredPositionKeysMissing.forEach((value) => {
        setSelectedPreferredPositions({
          ...selectedPreferredPositions,
          [value]: undefined,
        });
      });
    },
    [selectedPreferredPositions]
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = useMemo(
    () =>
      ({
        columnWidth: 60,
        fixed: true,
        getCheckboxProps: (brk: Break) => ({
          name: brk.uniqueId,
        }),
        onChange: handleRowSelectionChange,
        selectedRowKeys,
      } as TableRowSelection<Break>),
    [handleRowSelectionChange, selectedRowKeys]
  );

  const columns: ColumnProps<Break>[] = useMemo(
    () => [
      {
        fixed: "left",
        key: "scheduledDate",
        render: (_, { scheduledDate }): ReactNode => (
          <>{moment(scheduledDate).format("ll")}</>
        ),
        showSorterTooltip: false,
        sorter: (a, b): number =>
          moment(a.scheduledDate).toDate().getTime() -
          moment(b.scheduledDate).toDate().getTime(),
        title: i18n._(`Datum`),
        width: 110,
      },
      {
        filterDropdown: ({
          clearFilters,
          confirm,
          prefixCls,
          selectedKeys,
          setSelectedKeys,
          visible: timeWindowFilterVisible,
        }: FilterDropdownProps): ReactNode => (
          <BlocksPickerTimeWindowFilter
            clearFilters={clearFilters}
            confirm={confirm}
            prefixCls={prefixCls}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            visible={timeWindowFilterVisible}
          />
        ),
        filterIcon: <SearchOutlined />,
        fixed: "left",
        key: "scheduledStartTime",
        onFilter: (value, { scheduledStartTime }): boolean => {
          const time = momentTime(value as string);
          const timeWindow = timeWindows.find((tw) =>
            tw.startTime.isSame(time)
          );

          if (!timeWindow) {
            return false;
          }

          const windowStart = moment(scheduledStartTime).set({
            hour: timeWindow.startTime.hour(),
            minute: timeWindow.startTime.minute(),
            seconds: 0,
          });
          const windowEnd = moment(scheduledStartTime).set({
            hour: timeWindow.endTime.hour(),
            minute: timeWindow.endTime.minute(),
            seconds: 0,
          });

          return (
            windowStart.isSameOrBefore(scheduledStartTime) &&
            windowEnd.isSameOrAfter(scheduledStartTime)
          );
        },
        render: (_, { scheduledStartTime }): ReactNode => (
          <Tooltip title={moment(scheduledStartTime).format("LLL")}>
            <span>{moment(scheduledStartTime).format("HH:mm")}</span>
          </Tooltip>
        ),
        showSorterTooltip: false,
        sorter: (a, b): number =>
          moment(a.scheduledStartTime).toDate().getTime() -
          moment(b.scheduledStartTime).toDate().getTime(),
        title: i18n._(t`Tijd`),
        width: 64,
      },
      {
        dataIndex: "breakId",
        fixed: "left",
        key: "breakId",
        showSorterTooltip: false,
        sorter: (a, b): number => `${a.breakId}`.localeCompare(b.breakId),
        title: i18n._(t`Blok`),
        width: 115,
      },
      {
        filters: channels,
        key: "channel",
        onFilter: (value, { channel }): boolean =>
          channel && channel.description === value,
        render: (_, { channel: { description } }): ReactNode => (
          <Ellipsis text={description} />
        ),
        showSorterTooltip: false,
        sorter: (a, b): number => {
          const aChannel = a.channel.intomartCode ?? a.channel.description;
          const bChannel = b.channel.intomartCode ?? b.channel.description;
          return aChannel.localeCompare(bChannel);
        },
        title: i18n._(t`Zender`),
        width: 115,
      },
      {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps): ReactNode => (
          <ColumnSearchFilter
            text={
              selectedKeys && selectedKeys[0] ? selectedKeys[0].toString() : ""
            }
            setSelectedKeys={setSelectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        filterIcon: <SearchOutlined />,
        key: "programBefore",
        onFilter: (value, { programBefore }): boolean =>
          new RegExp(`${`${value}`.toLowerCase()}`).test(
            programBefore.toLowerCase()
          ),
        render: (_, { programBefore }): ReactNode => (
          <Ellipsis text={programBefore} />
        ),
        showSorterTooltip: false,
        sorter: (a, b): number =>
          a.programBefore.localeCompare(b.programBefore),
        title: i18n._(t`Programma voor`),
        width: 150,
      },
      {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps): ReactNode => (
          <ColumnSearchFilter
            text={
              selectedKeys && selectedKeys[0] ? selectedKeys[0].toString() : ""
            }
            setSelectedKeys={setSelectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        filterIcon: <SearchOutlined />,
        key: "programAfter",
        onFilter: (value, { programAfter }): boolean =>
          new RegExp(`${`${value}`.toLowerCase()}`).test(
            programAfter.toLowerCase()
          ),
        render: (_, { programAfter }): ReactNode => (
          <Ellipsis text={programAfter} />
        ),
        showSorterTooltip: false,
        sorter: (a, b): number => a.programAfter.localeCompare(b.programAfter),
        title: i18n._(t`Programma na`),
        width: 150,
      },
      {
        align: "right",
        key: "predictedRating",
        render: (_, { predictedRating = 0 }): ReactNode => (
          <Grps amount={predictedRating} standalone={false} />
        ),
        showSorterTooltip: false,
        sorter: (a, b): number => a.predictedRating - b.predictedRating,
        title: (
          <Tooltip
            placement="bottom"
            title={i18n._(
              t`De verwachte kijkdichtheid van het reclameblok binnen de geselecteerde doelgroep`
            )}
          >
            <span>Prognose</span>
          </Tooltip>
        ),
        width: 115,
      },
      {
        align: "right",
        key: "selectivity",
        render: (_, { selectivity }): ReactNode => (
          <Grps amount={selectivity} standalone={false} />
        ),
        showSorterTooltip: false,
        sorter: (a, b): number => a.selectivity - b.selectivity,
        title: (
          <Tooltip
            placement="bottom"
            title={i18n._(
              t`Index berekend als de verwachte kijkdichtheid van de geselecteerde doelgroep gedeeld op de kijkdichtheid van de basis doelgroep van de exploitant. Ad Alliance kent een basis doelgroep per zender.`
            )}
          >
            <span>Selectiviteit</span>
          </Tooltip>
        ),
        width: 125,
      },
      {
        align: "right",
        key: "PreferredPosition",
        render: (_, breakObj): ReactNode => {
          const breakUid = breakObj.uniqueId;
          const enabledVkp =
            enablePreferredPositionSelection &&
            selectedRowKeys.indexOf(breakUid) > -1;
          return (
            <PreferredPositionPicker
              enabled={enabledVkp}
              mode="Multiple"
              onChange={handlePreferredPositionChange}
              preferredPositionsFree={positionsFree(
                breakObj.positionFreeString
              )}
              uniqueId={breakUid}
              value={selectedPreferredPositions[breakUid] || []}
            />
          );
        },
        title: i18n._(t`Voorkeurspositie`),
        width: 200,
      },
    ],
    [
      channels,
      enablePreferredPositionSelection,
      handlePreferredPositionChange,
      i18n,
      selectedPreferredPositions,
      selectedRowKeys,
    ]
  );

  const handleOk = useCallback(() => {
    const filteredPositions = Object.keys(selectedPreferredPositions).filter(
      (key) =>
        (selectedPreferredPositions[key] ?? emptyPreferredPositions).length > 0
    );

    if (
      operator === "Ster" &&
      enablePreferredPositionSelection &&
      selectedRowKeys.length !== filteredPositions.length
    ) {
      Modal.error({
        title: i18n._(t`Selectie niet compleet`),
        content: i18n._(
          t`Voor ieder geselecteerd blok is het verplicht een voorkeurspositie op te geven.`
        ),
      });

      return;
    }

    const selection = (availableBreaks ?? emptyAvailableBreaks).filter(
      (brk) => selectedRowKeys.indexOf(brk.uniqueId) > -1
    );

    const grps = selection
      .map((brk: Break) => brk.predictedRating)
      .reduce((prev, cur) => prev + cur, 0);
    const grpsFilled = getFieldValue(fieldGrpsName);
    // if selection has GRP's => set in form
    if (grps > 0 && (!grpsFilled || grpsFilled === 0)) {
      setFieldsValue({ [fieldGrpsName]: grps });
    }

    const selectionWithPreferredPosition = selection.map(
      (s): OrderRequestSpot => {
        const uid = s.uniqueId;
        const preferredPosition = (selectedPreferredPositions[uid] || []).join(
          ","
        );

        return fromBreakWithPreferredPosition(s, preferredPosition);
      }
    );

    setFieldsValue({ [fieldName]: selectionWithPreferredPosition });
    setModalVisible(false);
  }, [
    availableBreaks,
    enablePreferredPositionSelection,
    fieldName,
    getFieldValue,
    i18n,
    operator,
    selectedPreferredPositions,
    selectedRowKeys,
    setFieldsValue,
  ]);

  const filteredAvailableBreaks = useMemo(
    /**
     * If applicable, a filtered list of available breaks.
     */
    () =>
      selectionFilterValue === "All"
        ? availableBreaks
        : availableBreaks?.filter(
            ({ uniqueId }) =>
              rowSelection.selectedRowKeys?.includes(uniqueId) || false
          ),
    [availableBreaks, rowSelection.selectedRowKeys, selectionFilterValue]
  );

  return (
    <BlocksPicker
      availableBreaks={filteredAvailableBreaks}
      columns={columns}
      enabled={enabled}
      fieldName={fieldName}
      handleCancel={handleCancel}
      handleOk={handleOk}
      handleSelectBlocksClick={handleSelectBlocksClick}
      loading={loading}
      modalVisible={modalVisible}
      selectionFilter={selectionFilterValue}
      onSelectionFilter={handleSelectionFilter}
      operator={operator}
      packageCode={packageCode}
      productId={productId}
      required={required}
      rowSelection={rowSelection}
      selectedBlocks={selectedBlocks}
      spotLengths={spotLengths}
      targetGroupId={targetGroupId}
      targetGroupIntomartId={targetGroupIntomartId}
      realGrpPrice={realGrpPrice}
      selectedRowKeys={selectedRowKeys}
    />
  );
};

export default memo(BlocksPickerContainer);
