import { AsyncResponse } from "async-lifecycle-saga";

import { getPeriodQueryString } from "../../../utils/date";
import { getter } from "../../fetch";
import { AnalyzeOrdersRequest, AnalyzedOrder } from "./models";

// eslint-disable-next-line import/prefer-default-export
export const analyzedOrdersGetter = ({
  orderIds,
  dateFrom,
  dateTo,
  skoTargetGroupId,
}: AnalyzeOrdersRequest): Promise<AsyncResponse<AnalyzedOrder[]>> =>
  getter<AnalyzedOrder[]>(
    `/api/campaigns/analyze?${getPeriodQueryString(
      dateFrom,
      dateTo
    )}&orderId=${orderIds
      .map((oid) => encodeURIComponent(oid))
      .join("&orderId=")}&skoTargetGroupId=${skoTargetGroupId}`
  );
