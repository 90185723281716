import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Form, Select, Typography } from "antd";
import React, { memo } from "react";
import { Link } from "react-router-dom";

import { getAdvertiserName } from "../../../../utils";
import navigationPaths from "../../../../utils/navigation";
import { wrapperColNoLabel } from "../../../../views/layout/Form";
import { fieldAdvertiserId } from "../constants";
import { CampaignAdvertiserPickerProps } from "../models";

const CampaignAdvertiserPicker = memo(
  ({
    advertiserHasProducts,
    advertiserHasPackages,
    advertiserOnlyHasSpecificPackage,
    advertisers,
    advertisersLoading,
    enabled,
  }: CampaignAdvertiserPickerProps) => {
    const { i18n } = useLingui();

    return (
      <>
        <Form.Item
          label={i18n._(t`Adverteerder`)}
          name={fieldAdvertiserId}
          rules={[
            {
              required: true,
              message: i18n._(t`Adverteerder is verplicht.`),
            },
          ]}
        >
          {/* TODO : Adverteerder-ID doorgeven aan subOrder, indien nodig */}
          <Select
            showSearch
            optionFilterProp="title"
            disabled={!enabled || advertisers.length === 0}
            loading={advertisersLoading}
          >
            {advertisers.map((a) => (
              <Select.Option
                key={a.id}
                value={a.id}
                title={getAdvertiserName(advertisers, a.id)}
              >
                {getAdvertiserName(advertisers, a.id)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {!advertisersLoading &&
          advertisers &&
          advertisers.length > 0 &&
          !advertiserHasProducts && (
            <Form.Item wrapperCol={wrapperColNoLabel}>
              <Alert
                message={
                  <Trans>
                    Deze adverteerder heeft geen producten. Zonder product kan
                    geen aanvraag gedaan worden. Kies indien mogelijk
                    alsjeblieft een andere adverteerder of{" "}
                    <Link to={navigationPaths.ProductRequest}>
                      vraag een nieuw product aan
                    </Link>
                    .
                  </Trans>
                }
                type="warning"
              />
            </Form.Item>
          )}
        {!advertisersLoading &&
          advertisers &&
          advertisers.length > 0 &&
          !advertiserHasPackages && (
            <Form.Item wrapperCol={wrapperColNoLabel}>
              <Alert
                message={
                  <Trans>
                    Deze adverteerder heeft geen pakketten. Zonder pakket kan
                    geen aanvraag gedaan worden. Kies indien mogelijk
                    alsjeblieft een andere adverteerder of neem contact op met
                    de exploitant.
                  </Trans>
                }
                type="warning"
              />
            </Form.Item>
          )}
        {advertiserOnlyHasSpecificPackage && (
          <Form.Item wrapperCol={wrapperColNoLabel}>
            <Alert
              message={
                <Typography.Text>
                  <Trans>
                    Voor de inkoop van GRPs is een jaarcontract nodig. Neem
                    contact op met de exploitant als je hier nog niet over
                    beschikt.
                  </Trans>
                </Typography.Text>
              }
              type="warning"
            />
          </Form.Item>
        )}
      </>
    );
  }
);

export default CampaignAdvertiserPicker;
