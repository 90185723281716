import { combineReducers } from "redux";

import { asyncReducer } from "../lifecycle";
import sagaTypes from "../sagaTypes";
import {
  DeleteExternalApiClientAuthorizationRequest,
  GetExternalApiClientsResponse,
  PostExternalApiClientAuthorizationRequest,
} from "./models";

export default combineReducers({
  authorizationAdd: asyncReducer<
    PostExternalApiClientAuthorizationRequest,
    unknown
  >(sagaTypes.externalApi.authorizationAdd),
  authorizationDelete: asyncReducer<
    DeleteExternalApiClientAuthorizationRequest,
    unknown
  >(sagaTypes.externalApi.authorizationDelete),
  clients: asyncReducer<unknown, GetExternalApiClientsResponse>(
    sagaTypes.externalApi.clients
  ),
});
