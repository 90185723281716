import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Input, Select, Table } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnProps } from "antd/lib/table";
import React, {
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import Advertiser from "../../models/Advertiser";
import AdvertiserGroup from "../../models/AdvertiserGroup";
import User from "../../models/User";
import { getAdvertiserName } from "../../utils";
import propertyOf from "../../utils/properties";
import NormalisationContent from "../../views/normalisation/NormalisationContent";
import {
  normalisationAddCategory,
  normalisationDeleteCategory,
  normalisationEditCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import DeleteButton from "../actions/DeleteButton";
import EditButton from "../actions/EditButton";

const emptyId = " ";
const idField = propertyOf<AdvertiserGroup>("id");
const nameField = propertyOf<AdvertiserGroup>("name");
const advertiserIdsField = propertyOf<AdvertiserGroup>("advertiserIds");
const emptyAdvertiserGroup: AdvertiserGroup = {
  id: emptyId,
  name: "",
  advertiserIds: [],
};

interface NormalisationProps {
  allAdvertisers: Advertiser[];
  allAdvertisersLoading: boolean;
  deleteLoading: boolean;
  form: FormInstance;
  onDelete: (id: string) => void;
  onLoadAdvertisers: () => void;
  resetForm: boolean;
  setReset: (reset: boolean) => void;
  user?: User;
  userLoading: boolean;
}

const Normalisation = memo(
  ({
    allAdvertisers,
    allAdvertisersLoading,
    deleteLoading,
    form,
    onDelete,
    onLoadAdvertisers,
    resetForm,
    setReset,
    user,
    userLoading,
  }: NormalisationProps) => {
    const { i18n } = useLingui();
    const { resetFields, setFieldsValue, getFieldsError } = form;
    const [editing, setEditing] = useState<string | undefined>(undefined);
    const [selectedAdvertiserIds, setSelectedAdvertiserIds] = useState<
      string[]
    >([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

    const userAdvertiserGroups: AdvertiserGroup[] = useMemo(
      /**
       * Determine the existing advertiser groups.
       * If a new advertiser group is being created, prepend an empty one.
       */
      () => {
        const advertiserGroups =
          user && user.advertiserGroups ? user.advertiserGroups : [];

        return (
          editing === emptyId
            ? [emptyAdvertiserGroup, ...advertiserGroups]
            : advertiserGroups
        ).map((a) => ({ ...a, key: a.id }));
      },
      [editing, user]
    );

    const normalisedAdvertisers: string[] = useMemo(
      /**
       * Render a list of all normalised advertisers.
       */
      () =>
        userAdvertiserGroups
          .map((g) => g.advertiserIds)
          .reduce((output, input) => output.concat(input), []),
      [userAdvertiserGroups]
    );

    const handleAdvertiserIdsChange = useCallback(
      /**
       * Handles a change of the selected advertiser ids.
       * @param value The new value of selected advertiser ids.
       */
      (value: string[]) => {
        if (value && Array.isArray(value) && allAdvertisers) {
          const firstAdvertiserId = value[0];
          const firstAdvertiser = allAdvertisers.find(
            (advertiser) => advertiser.id === firstAdvertiserId
          );
          if (firstAdvertiser) {
            setFieldsValue({ [nameField]: firstAdvertiser.name });
          }
        }

        const fieldErrorList = getFieldsError();
        setSaveButtonDisabled(
          !value ||
            (fieldErrorList &&
              fieldErrorList.some((errorList) => errorList.errors.length > 0))
        );

        setSelectedAdvertiserIds(value);
      },
      [allAdvertisers, getFieldsError, setFieldsValue]
    );

    const handleAdd = useCallback(
      /**
       * Handles a request to add a new advertiser group.
       */
      () => {
        triggerAnalyticsEvent(normalisationAddCategory);
        setEditing(emptyId);
        setFieldsValue(emptyAdvertiserGroup);
        setSelectedAdvertiserIds([]);
      },
      [setFieldsValue]
    );

    const handleCancel = useCallback(
      /**
       * Handles a request to cancel the pending changes.
       */
      () => {
        setEditing(undefined);
        resetFields();
        setSaveButtonDisabled(true);
      },
      [resetFields]
    );

    const handleEdit = useCallback(
      /**
       * Handles a request to edit an existing advertiser group.
       */
      (groupId?: string) => {
        if (!groupId) {
          return;
        }
        const advertiserGroup = userAdvertiserGroups.find(
          (ag) => ag.id === groupId
        );
        triggerAnalyticsEvent(normalisationEditCategory, groupId);
        setEditing(groupId);
        setFieldsValue(advertiserGroup || emptyAdvertiserGroup);
        setSelectedAdvertiserIds(advertiserGroup?.advertiserIds || []);
        setSaveButtonDisabled(false);
      },
      [userAdvertiserGroups, setFieldsValue]
    );

    const handleDelete = useCallback(
      /**
       * Handles a request to delete an existing advertiser group.
       * @param id the unique identifier of the advertiser group to delete.
       */
      (id?: string) => {
        if (!id) {
          return;
        }
        triggerAnalyticsEvent(normalisationDeleteCategory, id);
        onDelete(id);
      },
      [onDelete]
    );

    const columns: ColumnProps<AdvertiserGroup>[] = useMemo(
      (): ColumnProps<AdvertiserGroup>[] => [
        {
          key: "advertiserIds",
          dataIndex: "advertiserIds",
          title: i18n._(t`Adverteerders`),
          render: (_, { id: currentGroupId, advertiserIds = [] }): ReactNode =>
            editing === currentGroupId ? (
              <Form.Item
                name={advertiserIdsField}
                rules={[
                  {
                    required: true,
                    message: i18n._(t`Selecteer een adverteerder!`),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder={i18n._(t`Selecteer een adverteerder`)}
                  showArrow
                  onChange={handleAdvertiserIdsChange}
                  optionFilterProp="title"
                >
                  {allAdvertisers
                    /* niet genormaliseerd zijn en niet in de huidige normalisatielijst */
                    .filter(
                      ({ id: advertiserId }) =>
                        !normalisedAdvertisers
                          .filter((id) => {
                            if (!currentGroupId) {
                              // nieuwe groep aanmaken
                              return true;
                            }

                            // bewerken; dus haal originele advertiserId's van de groep op
                            // en gebruik deze voor het filter
                            const currentGroup = userAdvertiserGroups.find(
                              ({ id: groupId }) => groupId === currentGroupId
                            );

                            return !currentGroup
                              ? true
                              : !currentGroup.advertiserIds.includes(id);
                          })
                          .includes(advertiserId)
                    )
                    .map(
                      ({
                        id: advertiserId,
                        operator,
                      }: Advertiser): ReactElement => {
                        const title = getAdvertiserName(
                          allAdvertisers,
                          advertiserId
                        );
                        return (
                          <Select.Option
                            key={advertiserId}
                            value={advertiserId}
                            title={title}
                            disabled={selectedAdvertiserIds.some(
                              (advTipId) =>
                                /* er mag maar 1 adverteerder per exploitant gekoppeld worden */
                                advTipId.startsWith(operator) &&
                                advTipId !== advertiserId
                            )}
                          >
                            {title}
                          </Select.Option>
                        );
                      }
                    )}
                </Select>
              </Form.Item>
            ) : (
              <>
                {advertiserIds
                  .map((i) => getAdvertiserName(allAdvertisers, i))
                  .join(", ")}
              </>
            ),
        },
        {
          key: "name",
          dataIndex: "name",
          title: i18n._(t`Samengevoegd als`),
          width: 300,
          render: (_, { id, name }): ReactNode =>
            editing === id ? (
              <Form.Item
                name={nameField}
                rules={[
                  { required: true, message: i18n._(t`Naam is verplicht!`) },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              <>{name}</>
            ),
        },
        {
          key: "actions",
          title: i18n._(t`Acties`),
          width: 300,
          render: (_, { id }): ReactNode =>
            editing === id ? (
              <>
                <Form.Item name={idField} noStyle>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item>
                  <Button
                    key="saveButton"
                    type="link"
                    htmlType="submit"
                    disabled={saveButtonDisabled}
                  >
                    <Trans>Opslaan</Trans>
                  </Button>
                  <Button key="cancelButton" type="link" onClick={handleCancel}>
                    <Trans>Annuleren</Trans>
                  </Button>
                </Form.Item>
              </>
            ) : (
              <>
                <EditButton
                  itemId={id}
                  onClick={handleEdit}
                  disabled={Boolean(editing)}
                />
                <DeleteButton
                  itemId={id}
                  onClick={handleDelete}
                  disabled={deleteLoading || Boolean(editing)}
                />
              </>
            ),
        },
      ],
      [
        i18n,
        editing,
        handleAdvertiserIdsChange,
        allAdvertisers,
        normalisedAdvertisers,
        userAdvertiserGroups,
        selectedAdvertiserIds,
        saveButtonDisabled,
        handleCancel,
        handleEdit,
        handleDelete,
        deleteLoading,
      ]
    );

    const loading = useMemo(
      () =>
        allAdvertisers.length === 0 && (userLoading || allAdvertisersLoading),
      [allAdvertisers.length, allAdvertisersLoading, userLoading]
    );

    useEffect(
      /**
       * Whenever we know the user's organisation, we may load the available advertisers.
       */
      () => {
        if (user && user.organisation) {
          onLoadAdvertisers();
        }
      },
      [onLoadAdvertisers, user]
    );

    useEffect(
      /**
       * Make sure each time this component is (re-)rendered, the form's fields are reset.
       * After creating a new normalisation, or editing an existing one, we do not want the values to remain in the form.
       */
      () => {
        if (resetForm) {
          resetFields();
          setEditing(undefined);
          setReset(false);
        }
      },
      [resetFields, resetForm, setReset]
    );

    return (
      <NormalisationContent>
        <Button type="primary" onClick={handleAdd} disabled={Boolean(editing)}>
          <Trans>Toevoegen</Trans>
        </Button>
        <Table
          dataSource={userAdvertiserGroups}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </NormalisationContent>
    );
  }
);

export default Normalisation;
