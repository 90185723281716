import {
  HealthCheckReponse,
  IndividualHealthCheckResponse,
} from "../../store/health/models";

export const emptyHealthChecks: IndividualHealthCheckResponse[] = [];
export const initialHealth: HealthCheckReponse = {
  status: "Healthy",
  modified: new Date(),
  healthChecks: {
    backoffice: emptyHealthChecks,
    commercialdelivery: emptyHealthChecks,
    sko: emptyHealthChecks,
  },
};
