import classNames from "classnames";
import React, { memo } from "react";

import { UserStatus } from "../../../models/User";

interface StatisticsUserNameViewProps {
  name: string;
  userStatus: UserStatus;
}

const StatisticsUserNameView = memo(
  (statistics: StatisticsUserNameViewProps) => {
    const viewClassName = "tip-statistics-username-view";
    if (!statistics) {
      return <span className={viewClassName}>&nbsp;</span>;
    }
    const { name, userStatus } = statistics;

    return (
      <span
        className={classNames(
          viewClassName,
          userStatus === "Deleted" ? "tip-statistics-username-view-deleted" : ""
        )}
      >
        {name}
      </span>
    );
  }
);

export default StatisticsUserNameView;
