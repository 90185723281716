import { HTMLProps, ReactNode, createElement, memo } from "react";

interface SmallScreenProps {
  children?: ReactNode;
  type: "placeholder" | "paragraph" | "label" | "text";
}

const SmallScreen = memo(({ children, type }: SmallScreenProps) => {
  const props: HTMLProps<HTMLElement> = {
    className: "tip-smallScreen",
    children,
  };
  let htmlElement = "span";
  switch (type) {
    case "paragraph":
      htmlElement = "p";
      break;
    case "placeholder":
      htmlElement = "div";
      break;
    case "label":
      htmlElement = "label";
      break;
    case "text":
    default:
      htmlElement = "span";
      break;
  }
  return createElement(htmlElement, props);
});

export default SmallScreen;
