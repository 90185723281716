import { CalendarOutlined, NotificationOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Badge,
  Button,
  Divider,
  Popover,
  Tooltip,
  Typography,
  notification as antNotification,
} from "antd";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { CalendarEvent } from "../../models/Calendar";
import { NotificationResponse } from "../../models/notifications";
import { lastNotificationCheckKey } from "../../utils";
import { CalendarEventDate } from "../calendar/CalendarEventContent";

const NotificationButton = ({
  notification,
  events,
  lastCheckDate,
  setLastCheckDate,
}: {
  notification?: NotificationResponse;
  events: CalendarEvent[];
  lastCheckDate: Moment;
  setLastCheckDate: (date: Moment) => void;
}): JSX.Element => {
  const { i18n } = useLingui();
  const [popup, setPopup] = useState(false);

  const toggleNotification = useCallback(() => {
    if (popup) {
      // close popup
      const now = moment();
      window.localStorage.setItem(lastNotificationCheckKey, now.format());
      setLastCheckDate(now);
      setPopup(false);
    } else {
      // open popup
      setPopup(true);
    }
  }, [popup, setLastCheckDate]);

  const eventsTodayCount = events.filter((e) =>
    moment(e.from).isSame(moment(), "day")
  ).length;
  const hasNewMessage =
    Boolean(notification?.notification) &&
    moment(notification?.notification?.modified).isSameOrAfter(lastCheckDate);
  const badgeCount = eventsTodayCount + (hasNewMessage ? 1 : 0);

  useEffect(() => {
    if (badgeCount) {
      antNotification.info({
        placement: "bottomRight",
        message: i18n._(t`Notificatie`),
        description: (
          <Typography.Paragraph>
            {i18n._(t`Er is een nieuwe mededeling en/of evenement.`)}
          </Typography.Paragraph>
        ),
        key: `notification-msg-${moment(
          notification?.notification?.modified ?? 0
        ).valueOf()}`,
      });
    }
  }, [
    i18n,
    badgeCount,
    notification?.notification?.id,
    notification?.notification?.modified,
  ]);

  return (
    <div>
      <Popover
        title={i18n._(t`Mededeling en/of evenementen`)}
        content={
          <>
            {events.length > 0 && (
              <ul style={{ margin: 8 }}>
                {events.map((e) => (
                  <li
                    style={{ display: "flex", alignItems: "center" }}
                    key={e.id}
                  >
                    <CalendarOutlined />
                    <Tooltip
                      title={<CalendarEventDate event={e} i18n={i18n} />}
                    >
                      <Typography.Text ellipsis style={{ margin: "0 8px" }}>
                        {e.title}
                      </Typography.Text>
                    </Tooltip>
                  </li>
                ))}
              </ul>
            )}
            {events.length > 0 && notification?.notification?.id && (
              <Divider style={{ margin: 0 }} />
            )}
            {notification?.notification?.id && (
              <div
                className="overflow-y-auto overflow-x-hidden flex-grow p-4"
                style={{ maxHeight: 320 }}
              >
                <ReactMarkdown linkTarget="_blank">
                  {notification?.notification.text ?? ""}
                </ReactMarkdown>
              </div>
            )}
            <div className="text-center flex-grow-0 p-4">
              <Button type="primary" onClick={toggleNotification} size="small">
                {i18n._(t`Sluiten`)}
              </Button>
            </div>
          </>
        }
        placement="bottomRight"
        trigger="click"
        overlayClassName="tip-notification-overlay"
        open={popup}
        destroyTooltipOnHide
      >
        <Badge count={badgeCount}>
          <Tooltip title={i18n._(t`Bekijk de mededeling en/of evenementen`)}>
            <Button
              onClick={toggleNotification}
              icon={<NotificationOutlined />}
              shape="circle"
              type={popup ? "primary" : "default"}
              className="tip-notification-button"
            />
          </Tooltip>
        </Badge>
      </Popover>
    </div>
  );
};

export default NotificationButton;
