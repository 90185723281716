import { Calendar } from "antd";
import moment, { Moment } from "moment/moment";
import React from "react";

import { CalendarEvent } from "../../models/Calendar";
import CalendarEventLink from "./CalendarEventLink";

export interface CalendarContentProps {
  period: [Moment, Moment];
  events: CalendarEvent[];
}

const CalendarContent = ({ period, events }: CalendarContentProps) => (
  <Calendar
    style={{ padding: "0 16px" }}
    validRange={period}
    dateCellRender={(value: Moment) => {
      const start = value.clone().startOf("day");
      const end = value.clone().endOf("day");

      return (
        <ul>
          {events
            .filter(
              (e: CalendarEvent) =>
                moment(e.to).isSameOrAfter(start) &&
                moment(e.from).isSameOrBefore(end)
            )
            .map((e) => (
              <li key={e.id}>
                <CalendarEventLink event={e} />
              </li>
            ))}
        </ul>
      );
    }}
    monthCellRender={(value: Moment) => {
      const start = value.clone().startOf("month");
      const end = value.clone().endOf("month");

      return (
        <ul>
          {events
            .filter(
              (e: CalendarEvent) =>
                moment(e.to).isSameOrAfter(start) &&
                moment(e.from).isSameOrBefore(end)
            )
            .map((e) => (
              <li key={e.id}>
                <CalendarEventLink event={e} />
              </li>
            ))}
        </ul>
      );
    }}
  />
);

export default CalendarContent;
