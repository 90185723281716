import { combineReducers } from "redux";

import { asyncReducer } from "../lifecycle";
import { AdBlockCheck } from "../models";
import sagaTypes from "../sagaTypes";

export default combineReducers({
  campaigns: combineReducers({
    selectOrder: (state, { type, payload }) =>
      type === sagaTypes.ui.campaigns.selectOrder ? payload : state || null,
  }),
  adBlocker: asyncReducer<unknown, AdBlockCheck>(sagaTypes.ui.adBlocker),
});
