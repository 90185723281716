import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnProps } from "antd/lib/table";
import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Operator,
  OperatorsAll,
  operatorLabels,
} from "../../../models/Operator";
import Organisation, {
  OrganisationType,
  organisationTypeLabels,
} from "../../../models/Organisation";
import propertyOf from "../../../utils/properties";
import Ellipsis from "../../../views/Ellipsis";
import OrganisationsContent from "../../../views/management/organisations/OrganisationsContent";
import CancelButton from "../../actions/CancelButton";
import DeleteButton from "../../actions/DeleteButton";
import EditButton from "../../actions/EditButton";
import SaveButton from "../../actions/SaveButton";
import OrganisationUpdatePassword from "./OrganisationUpdatePassword";

const idField = propertyOf<Organisation>("id");
const codeField = propertyOf<Organisation>("code");
const advertiserCodeField = propertyOf<Organisation>("advertiserCode");
const nameField = propertyOf<Organisation>("name");
const loginsField = propertyOf<Organisation>("logins");
const isKnownByOperatorsField = propertyOf<Organisation>("isKnownByOperators");
const typeField = propertyOf<Organisation>("type");

const emptyId = "";
export const emptyOrganisation: Organisation = {
  id: emptyId,
  code: "",
  name: "",
  logins: [],
  isKnownByOperators: true,
  type: "Advertiser",
  advertiserCode: "",
};

interface OrganisationsProps {
  form: FormInstance;
  onDelete: (organisation: Organisation) => void;
  organisations?: Organisation[];
  organisationsLoading: boolean;
  resetForm: boolean;
  setReset: (reset: boolean) => void;
  saveEnabled: boolean;
  onTypeChange: (type: OrganisationType) => void;
}

const Organisations = memo(
  ({
    form: { resetFields, getFieldValue, setFieldsValue },
    onDelete,
    organisations,
    organisationsLoading,
    resetForm,
    setReset,
    saveEnabled,
    onTypeChange,
  }: OrganisationsProps) => {
    const { i18n } = useLingui();
    const [editing, setEditing] = useState<string | null>(null);

    const handleAddClick = useCallback(
      /**
       * Adds a new organisation to the backing field that contains mutable organisations.
       */
      () => {
        setEditing(emptyId);
        setFieldsValue(emptyOrganisation);
      },
      [setFieldsValue]
    );

    const handleCancelClick = useCallback(
      /**
       * Handles a click on the "Cancel" button.
       */
      (): void => {
        setEditing(null);
        resetFields();
      },
      [resetFields]
    );

    const handleDeleteClick = useCallback(
      /**
       * Handles a click on the "Delete" button.
       * @param itemId The unique identifier of the organisation that needs to be deleted.
       */
      (itemId?: string) => {
        if (!organisations || !itemId) {
          return;
        }
        const organisation = organisations.find((o) => o.id === itemId);
        if (!organisation) {
          return;
        }
        onDelete(organisation);
      },
      [onDelete, organisations]
    );

    const handleEditClick = useCallback(
      /**
       * Handles a click on the "Edit" button.
       * @param itemId The unique identifier of the organisation that needs to be edited.
       */
      (itemId?: string) => {
        if (!organisations || !itemId) {
          return;
        }
        const organisation = organisations.find((o) => o.id === itemId);
        if (!organisation) {
          return;
        }
        setEditing(itemId);
        setFieldsValue({
          ...organisation,
          [loginsField]: organisation.logins.map((l) => l.operator),
        });
      },
      [organisations, setFieldsValue]
    );

    const organisationsFilter = useMemo(
      () =>
        [...new Set(organisations?.map(({ code }) => code).sort())].map(
          (code) => ({ text: code, value: code })
        ),
      [organisations]
    );

    const handleTypeChange = useCallback(
      (value: string) => {
        const organisationType = value as OrganisationType;
        onTypeChange(organisationType);
        if (organisationType !== "Coview") {
          setFieldsValue({ advertiserCode: "" });
        }
      },
      [onTypeChange, setFieldsValue]
    );

    const handleAdvertiserCodeChange = useCallback(() => {
      const organisationType = getFieldValue("type") as OrganisationType;
      if (organisationType !== "Coview") {
        setFieldsValue({ advertiserCode: "" });
      }
    }, [getFieldValue, setFieldsValue]);

    const columns = useMemo(
      /**
       * The table columns.
       */
      (): ColumnProps<Organisation>[] => [
        {
          title: i18n._(t`Naam`),
          dataIndex: nameField,
          key: nameField,
          width: 200,
          ellipsis: true,
          showSorterTooltip: false,
          sorter: (a, b): number => b.name.localeCompare(a.name),
          render: (_text, { id, name }): ReactNode =>
            editing === id ? (
              <Form.Item
                name={nameField}
                rules={[
                  { required: true, message: i18n._(t`Naam is verplicht.`) },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
            ) : (
              <Ellipsis text={name} />
            ),
        },
        {
          title: i18n._(t`Code`),
          dataIndex: codeField,
          key: codeField,
          width: 100,
          showSorterTooltip: false,
          sorter: (a, b): number => b.code.localeCompare(a.code),
          render: (_text, { id, code }): ReactNode =>
            editing === id ? (
              <Form.Item
                name={codeField}
                rules={[
                  { required: true, message: i18n._(t`Code is verplicht!`) },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              <Typography.Text>{code}</Typography.Text>
            ),
          filters: organisationsFilter,
          onFilter: (value, { code }) => code === value,
        },
        {
          title: i18n._(t`Adverteerder`),
          dataIndex: advertiserCodeField,
          key: advertiserCodeField,
          width: 100,
          showSorterTooltip: false,
          sorter: (a, b): number =>
            (b.advertiserCode ?? "").localeCompare(a.advertiserCode ?? ""),
          render: (_text, { id, advertiserCode }): ReactNode =>
            editing === id ? (
              <Form.Item name={advertiserCodeField}>
                <Input
                  onChange={handleAdvertiserCodeChange}
                  value={getFieldValue("advertiserCode")}
                />
              </Form.Item>
            ) : (
              <Typography.Text>{advertiserCode}</Typography.Text>
            ),
        },
        {
          title: i18n._(t`Type`),
          dataIndex: typeField,
          key: typeField,
          width: 150,
          render: (_text, { id, type }): ReactNode =>
            editing === id ? (
              <Form.Item
                name={typeField}
                rules={[
                  { required: true, message: i18n._(t`Type is verplicht!`) },
                ]}
              >
                <Select onSelect={handleTypeChange}>
                  <Select.Option value="Other">
                    {i18n._(organisationTypeLabels.Other)}
                  </Select.Option>
                  <Select.Option value="Advertiser">
                    {i18n._(organisationTypeLabels.Advertiser)}
                  </Select.Option>
                  <Select.Option value="MediaAgency">
                    {i18n._(organisationTypeLabels.MediaAgency)}
                  </Select.Option>
                  <Select.Option value="ProductionCompany">
                    {i18n._(organisationTypeLabels.ProductionCompany)}
                  </Select.Option>
                  <Select.Option value="Coview">
                    {i18n._(organisationTypeLabels.Coview)}
                  </Select.Option>
                </Select>
              </Form.Item>
            ) : (
              <Typography.Text>
                {i18n._(organisationTypeLabels[type])}
              </Typography.Text>
            ),
        },
        {
          title: (
            <Tooltip
              title={i18n._(
                t`Is deze organisatie bekend bij een exploitant en een inkopende/plannende partij?`
              )}
            >
              {i18n._(t`Bekend?`)}
            </Tooltip>
          ),
          dataIndex: isKnownByOperatorsField,
          align: "center",
          key: isKnownByOperatorsField,
          width: 100,
          render: (_text, { id, isKnownByOperators }): ReactNode =>
            editing === id ? (
              <Form.Item name={isKnownByOperatorsField} valuePropName="checked">
                <Checkbox />
              </Form.Item>
            ) : (
              <Checkbox checked={isKnownByOperators} disabled />
            ),
        },
        {
          title: i18n._(t`Exploitanten`),
          key: "Exploitanten",
          width: 300,
          render: (_text, { id, logins }): ReactNode =>
            editing === id ? (
              <>
                <Form.Item name={loginsField} noStyle />
                <Form.Item shouldUpdate>
                  {({ getFieldValue: gfv, setFieldsValue: sfv }) => (
                    <Checkbox.Group
                      onChange={(values) => {
                        sfv({ logins: values });
                      }}
                      value={gfv(loginsField) as Operator[]}
                    >
                      {OperatorsAll.map((operator) => (
                        <Checkbox key={operator} value={operator}>
                          <OrganisationUpdatePassword
                            disabled={
                              !(gfv(loginsField) as Operator[]).some(
                                (o) => o === operator
                              )
                            }
                            organisationId={id}
                            operator={operator}
                            hasPassword={
                              logins.find((l) => l.operator === operator)
                                ?.hasPassword ?? false
                            }
                          />
                          {operatorLabels[operator]}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </>
            ) : (
              <Checkbox.Group value={logins.map((l) => l.operator)}>
                {OperatorsAll.map((operator) => (
                  <Checkbox key={operator} value={operator} disabled>
                    {operatorLabels[operator]}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            ),
        },
        {
          title: i18n._(t`Acties`),
          key: "Acties",
          width: 200,
          render: (_text, { id }): ReactNode =>
            editing === id ? (
              <>
                <Form.Item name={idField} noStyle>
                  <Input type="hidden" />
                </Form.Item>
                <SaveButton
                  disabled={!saveEnabled}
                  loading={organisationsLoading}
                />
                <CancelButton itemId={id} onClick={handleCancelClick} />
              </>
            ) : (
              <>
                <EditButton
                  itemId={id}
                  onClick={handleEditClick}
                  disabled={editing !== null}
                />
                <DeleteButton
                  itemId={id}
                  onClick={handleDeleteClick}
                  disabled={editing !== null}
                />
              </>
            ),
        },
      ],
      [
        editing,
        getFieldValue,
        handleAdvertiserCodeChange,
        handleCancelClick,
        handleDeleteClick,
        handleEditClick,
        handleTypeChange,
        i18n,
        organisationsFilter,
        organisationsLoading,
        saveEnabled,
      ]
    );

    const organisationsDataSource = useMemo(
      /**
       * If we are creating a new organisation, an empty organisations must be prepended to the existing collection.
       */
      () =>
        editing === emptyId && organisations
          ? [emptyOrganisation, ...organisations]
          : organisations,
      [editing, organisations]
    );

    useEffect(
      /**
       * Whenever 'resetForm' is set to 'true', the container signals the form has finished a request and the form should be reset to its latent state.
       */
      () => {
        if (resetForm) {
          resetFields();
          setEditing(null);
          setReset(false);
        }
      },
      [resetFields, resetForm, setReset]
    );

    return (
      <OrganisationsContent>
        <Button
          type="primary"
          disabled={Boolean(editing)}
          onClick={handleAddClick}
        >
          <Trans>Organisatie toevoegen</Trans>
        </Button>
        <Table
          dataSource={organisationsDataSource}
          columns={columns}
          loading={organisationsLoading}
          pagination={false}
          rowKey={(organisation): string => organisation.id}
        />
      </OrganisationsContent>
    );
  }
);

export default Organisations;
