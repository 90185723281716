import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AuthorizationRule from "../../../models/AuthorizationRule";
import Organisation from "../../../models/Organisation";
import User from "../../../models/User";
import { advertisersByOrganisationCell } from "../../../store/advertisers/cells";
import { StoreModel } from "../../../store/models";
import { organisationsCell } from "../../../store/organisations/cells";
import { handleAsyncFailWithProblem } from "../../../utils";
import propertyOf from "../../../utils/properties";
import FullScreenModal from "../../../views/FullScreenModal";
import { labelCol, wrapperColShallower } from "../../../views/layout/Form";
import UsersButtonFormView from "../../../views/management/users/UsersButtonFormView";
import UserFormItemAuthorizationRules, {
  EditingRule,
} from "./authorization/UserFormItemAuthorizationRules";
import UserFormItemConfirmButton from "./UserFormItemConfirmButton";
import UserFormItemEmail from "./UserFormItemEmail";
import UserFormItemId from "./UserFormItemId";
import UserFormItemName from "./UserFormItemName";
import UserFormItemOrganisation from "./UserFormItemOrganisation";

interface UserFormModalProps {
  onClose: () => void;
  onSubmit: (user: User) => void;
  title: string;
  value: User;
  visible: boolean;
}

const emptyAuthorizations: AuthorizationRule[] = [];
const emptyOrganisations: Organisation[] = [];
const emptyString = "";

const authorizationRulesField = propertyOf<User>("authorizations");

const UserFormModal = ({
  onClose,
  onSubmit,
  title,
  value,
  visible,
}: UserFormModalProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [form] = useForm<User>();

  const [isKnownByOperators, setIsKnownByOperators] = useState<boolean>(false);

  /**
   * Get the organisations from the store.
   */
  const {
    value: organisations = emptyOrganisations,
    status: { loading: organisationsLoading },
  } = useSelector(({ organisations: all }: StoreModel) => all);

  useEffect(() => {
    dispatch(
      organisationsCell.require({
        onFail: () =>
          message.error(
            i18n._(t`Er is iets misgegaan bij het ophalen van de organisaties.`)
          ),
      })
    );
  }, [dispatch, i18n]);

  const loadAdvertisersForOrganisation = useCallback(
    (organisationId: string) => {
      const organisation = organisations.find(
        (org) => org.id === organisationId
      );
      const orgIsKnownByOperators = organisation?.isKnownByOperators || false;
      setIsKnownByOperators(orgIsKnownByOperators);
      if (orgIsKnownByOperators) {
        dispatch(
          advertisersByOrganisationCell.require(
            { organisationId },
            {
              onFail: handleAsyncFailWithProblem(
                i18n._(
                  t`Er is iets misgegaan bij het ophalen van de adverteerders.`
                )
              ),
            }
          )
        );
      }
    },
    [dispatch, i18n, organisations]
  );

  useEffect(() => {
    if (value.id && value.organisation?.id) {
      loadAdvertisersForOrganisation(value.organisation.id);
    }
  }, [loadAdvertisersForOrganisation, value]);

  const handleOrganisationSelect = useCallback(
    (organisationId: string) => {
      form.setFieldsValue({ [authorizationRulesField]: emptyAuthorizations });
      loadAdvertisersForOrganisation(organisationId);
    },
    [form, loadAdvertisersForOrganisation]
  );

  const [editingRule, setEditingRule] = useState<EditingRule | null>(null);

  const handleFinish = useCallback(
    (user: User) => {
      onSubmit({
        ...user,
        organisation: organisations.find(
          (org) => org.id === user.organisationId
        ),
      });
    },
    [onSubmit, organisations]
  );

  return (
    <FullScreenModal
      footer={[
        <Button key="cancelButton" onClick={onClose} type="default">
          <Trans>Annuleren</Trans>
        </Button>,
        <UsersButtonFormView
          form={form}
          key="usersButtonFormView"
          onFinish={handleFinish}
        >
          <UserFormItemConfirmButton
            disabled={editingRule?.key !== undefined}
          />
        </UsersButtonFormView>,
      ]}
      onCancel={onClose}
      title={title}
      visible={visible}
    >
      <Form form={form} labelCol={labelCol} wrapperCol={wrapperColShallower}>
        <UserFormItemId value={value.id || emptyString} />
        <UserFormItemName initialValue={value.name} />
        <UserFormItemEmail initialValue={value.emailAddress} />
        <UserFormItemOrganisation
          initialValue={value.organisation?.id}
          onSelect={handleOrganisationSelect}
          organisations={organisations}
          organisationsLoading={organisationsLoading}
        />
        <UserFormItemAuthorizationRules
          form={form}
          initialValue={value.authorizations}
          isKnownByOperators={isKnownByOperators}
          editingRule={editingRule}
          setEditingRule={setEditingRule}
        />
      </Form>
    </FullScreenModal>
  );
};

export default memo(UserFormModal);
