import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { emptyAdvertisers } from "../../models/Advertiser";
import AdvertiserGroup from "../../models/AdvertiserGroup";
import User from "../../models/User";
import { advertisersAllCell } from "../../store/advertisers/cells";
import { AsyncValue, StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { handleAsyncFailWithProblem, handleFailWithProblem } from "../../utils";
import {
  normalisationSavedCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import Normalisation from "./Normalisation";

const NormalisationContainer = memo(() => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [resetForm, setResetForm] = useState(false);

  /**
   * Monitors whether the current user is being loaded.
   */
  const {
    status: { loading: userLoading },
    value: user,
  } = useSelector(
    ({ users: { current } }: StoreModel): AsyncValue<User> => current
  );

  /**
   * Monitors whether all advertisers are loading and which advertisers have been loaded.
   */
  const {
    status: { loading: allAdvertisersLoading },
    value: allAdvertisersResponse,
  } = useSelector(({ advertisers: { all } }: StoreModel) => all);
  const allAdvertisersSafe = useMemo(
    () => allAdvertisersResponse?.advertisers || emptyAdvertisers,
    [allAdvertisersResponse]
  );

  /**
   * Monitors whether an advertiser group is being deleted.
   */
  const {
    status: { loading: deleteLoading },
  } = useSelector(
    ({ normalisation: { deleteResult } }: StoreModel) => deleteResult
  );

  const loadAdvertisers = useCallback(
    /**
     * Handles a request to load all available advertisers.
     */
    () => {
      dispatch(
        advertisersAllCell.require({
          onFail: handleAsyncFailWithProblem(
            i18n._(
              t`Er is iets misgegaan bij het ophalen van de adverteerders.`
            )
          ),
        })
      );
    },
    [dispatch, i18n]
  );

  const handleDelete = useCallback(
    /**
     * Handles the deletion of an existing advertiser group.
     * @param id The unique identifier of the advertiser group.
     */
    (id: string) => {
      dispatch({
        type: sagaTypes.normalisation.deleteAdvertiserGroup.request,
        payload: { id },
        onSuccess: () => {
          dispatch({ type: sagaTypes.users.current.request });
          dispatch({ type: sagaTypes.campaigns.fetchOrdersFiltered.clear });
          dispatch({ type: sagaTypes.campaigns.filters.clear });
          message.success(i18n._(t`Normalisatie verwijderd.`));
        },
        onFail: handleFailWithProblem(
          i18n._(t`Er is iets misgegaan met het verwijderen.`)
        ),
      });
    },
    [dispatch, i18n]
  );

  const handleFinish = useCallback(
    /**
     * Handles finishing the advertiser group form.
     * @param values The submitted values of the advertiser group.
     */
    (values: Store) => {
      if (!values) {
        return;
      }
      dispatch({
        type: sagaTypes.normalisation.saveAdvertiserGroup.request,
        payload: values as AdvertiserGroup,
        onFail: handleFailWithProblem(
          i18n._(t`Er is iets misgegaan met het opslaan.`)
        ),
        onSuccess: () => {
          triggerAnalyticsEvent(normalisationSavedCategory, values.id);
          dispatch({
            type: sagaTypes.campaigns.fetchOrdersFiltered.clear,
          });
          dispatch({ type: sagaTypes.campaigns.filters.clear });
          dispatch({ type: sagaTypes.users.current.request });
          setResetForm(true);
        },
      });
    },
    [dispatch, i18n]
  );

  return (
    <Form form={form} name="Normalisations" onFinish={handleFinish}>
      <Normalisation
        allAdvertisers={allAdvertisersSafe}
        allAdvertisersLoading={allAdvertisersLoading}
        deleteLoading={deleteLoading}
        form={form}
        onDelete={handleDelete}
        onLoadAdvertisers={loadAdvertisers}
        resetForm={resetForm}
        setReset={setResetForm}
        user={user}
        userLoading={userLoading}
      />
    </Form>
  );
});

export default NormalisationContainer;
