import { Trans } from "@lingui/macro";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React from "react";

import { Channel } from "../../models/Instructions";

export interface ChannelSelectProps extends SelectProps<string> {
  channels: Channel[];
}

const ChannelSelect = ({
  channels,
  value,
  ...selectProps
}: ChannelSelectProps): JSX.Element => {
  if (channels.length === 0) {
    return <Trans>Alle zenders</Trans>;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select {...selectProps} value={!value ? "" : value}>
      <Select.Option value="">Alle zenders</Select.Option>
      {channels.map((c) => (
        <Select.Option
          key={c.intomartCode ?? c.description}
          value={c.intomartCode}
        >
          {c.description}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ChannelSelect;
