import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Select } from "antd";
import React, { ReactElement, useCallback } from "react";

import Organisation from "../../../models/Organisation";
import User from "../../../models/User";
import propertyOf from "../../../utils/properties";

interface UserFormItemOrganisationProps {
  initialValue?: string;
  onSelect: (value: string) => void;
  organisations: Organisation[];
  organisationsLoading: boolean;
}

const organisationIdField = propertyOf<User>("organisationId");

const UserFormItemOrganisation = ({
  initialValue,
  onSelect,
  organisations,
  organisationsLoading,
}: UserFormItemOrganisationProps): ReactElement => {
  const { i18n } = useLingui();

  const handleSelect = useCallback(
    (value: string) => {
      onSelect(value);
    },
    [onSelect]
  );

  return (
    <Form.Item
      initialValue={initialValue}
      label={i18n._(t`Organisatie`)}
      name={organisationIdField}
      rules={[
        {
          message: i18n._(t`Organisatie is verplicht!`),
          required: true,
        },
      ]}
    >
      <Select
        loading={organisationsLoading}
        onSelect={handleSelect}
        optionFilterProp="title"
        showSearch
      >
        {organisations.map(({ id, name }) => (
          <Select.Option key={id} title={name} value={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default UserFormItemOrganisation;
