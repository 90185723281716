import { FileExcelOutlined, PrinterOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Button, Tooltip } from "antd";
import React, { FunctionComponent, ReactNode, memo } from "react";

import ExportActionButtonProps, { ExportType } from "./ExportActionButtonProps";

const exportIcons: { [type in ExportType]: ReactNode } = {
  Excel: <FileExcelOutlined />,
  PDF: <PrinterOutlined />,
};

/**
 * Renders a button for exporting data.
 * @constructor Initializes a new instance of ExportButton.
 * @returns {ReactElement<any, any> | null} The rendered component.
 */
const ExportButton: FunctionComponent<ExportActionButtonProps> = ({
  children,
  buttonType = "link",
  disabled,
  loading,
  title,
  iconType,
  onClick,
}) => {
  const button = (
    <Button
      icon={exportIcons[iconType]}
      type={buttonType}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {children || <Trans>Exporteren</Trans>}
    </Button>
  );

  return title ? <Tooltip title={title}>{button}</Tooltip> : button;
};

export default memo(ExportButton);
