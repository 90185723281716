import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
/* eslint-disable react/jsx-no-target-blank */
import { Card, Form, Spin, message } from "antd";
import { RcFile } from "antd/lib/upload";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from "rc-field-form/lib/interface";
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { emptyAdvertisers } from "../../models/Advertiser";
import { emptyOperators } from "../../models/Operator";
import User from "../../models/User";
import { dashboardRoleName, readerRoleName } from "../../models/UserRole";
import { advertisersAllCell } from "../../store/advertisers/cells";
import { AsyncValue, StoreModel } from "../../store/models";
import sagaTypes from "../../store/sagaTypes";
import { handleAsyncFailWithProblem, hasRole } from "../../utils";
import { labelCol, wrapperColShallow } from "../../views/layout/Form";
import MainContent from "../../views/layout/MainContent";
import {
  commercialUploadCategory,
  triggerAnalyticsEvent,
} from "../../views/utils/analytics";
import {
  filenameField,
  phonenumberField,
  productionCompanyField,
  versionField,
} from "./constants";
import DeliveryForm from "./DeliveryForm";

const emptyFileList: RcFile[] = [];

const DeliveryFormContainer: FunctionComponent = () => {
  const { i18n } = useLingui();
  const [form] = Form.useForm();
  const [fileList, updateFileList] = useState(emptyFileList);

  const dispatch = useDispatch();

  const {
    status: { loading: loadingUser },
    value: user,
  } = useSelector(
    ({ users: { current } }: StoreModel): AsyncValue<User> => current
  );

  const isReader = useMemo(
    () => hasRole(readerRoleName)(user) || hasRole(dashboardRoleName)(user),
    [user]
  );

  // haal alle adverteerders op, indien nodig
  useEffect(() => {
    if (user && user.organisation && isReader) {
      dispatch(
        advertisersAllCell.require({
          onFail: handleAsyncFailWithProblem(
            i18n._(
              t`Er is iets misgegaan bij het ophalen van de adverteerders.`
            )
          ),
        })
      );
    }
  }, [dispatch, user, isReader, i18n]);

  const { loading: loadingAdvertisers, advertisers } = useSelector(
    ({ advertisers: { all } }: StoreModel) => ({
      advertisers: all.value?.advertisers ?? emptyAdvertisers,
      loading: all.status.loading,
    })
  );

  // haal alle aangezette zenders op
  useEffect(() => {
    if (user && user.organisation) {
      dispatch({
        type: sagaTypes.commercials.operators.request,
      });
    }
  }, [dispatch, user]);

  const { loading: loadingOperators, operators } = useSelector(
    ({ commercials: { operators: operatorsFromStore } }: StoreModel) => ({
      operators: operatorsFromStore.value ?? emptyOperators,
      loading: operatorsFromStore.status.loading,
    })
  );

  const { resetFields } = form;
  const handleUpload = useCallback(
    (values: Store) => {
      const formData = new FormData();
      formData.append("Extra", "Data");
      fileList.forEach((file) => {
        formData.append("upload", file, file.name);
      });

      Object.keys(values).forEach((key) => {
        if (key === filenameField) {
          const upload = values[filenameField];
          const filename = upload.file.name;
          formData.append(key, filename);
        } else if (values[key]) {
          if (values[key] instanceof Array) {
            for (let i = 0; i < values[key].length; i += 1) {
              formData.append(`${key}[${i}]`, values[key][i]);
            }
          } else {
            formData.append(key, values[key]);
          }
        }
      });

      dispatch({
        type: sagaTypes.commercials.delivery.request,
        payload: formData,
        onFail: () => {
          message.error(
            i18n._(
              t`Er is iets misgegaan met het aanleveren van het MXF-bestand.`
            )
          );
        },
        onSuccess: () => {
          resetFields();
          updateFileList(emptyFileList);
          triggerAnalyticsEvent(
            commercialUploadCategory,
            `${values.commercialTitle} (${values.filename})`
          );
          message.success(
            i18n._(t`Het aanleveren van het MXF-bestand is gelukt.`)
          );
        },
      });
    },
    [dispatch, fileList, i18n, resetFields]
  );

  const handleUploadFailed = useCallback(() => {
    message.error(i18n._(t`Er is iets misgegaan met het uploaden`));
  }, [i18n]);

  const { loading: loadingUpload } = useSelector(
    ({ commercials: { delivery } }: StoreModel) => ({
      loading: delivery.status.loading,
    })
  );

  const loading =
    loadingUser || loadingAdvertisers || loadingUpload || loadingOperators;

  return (
    <MainContent>
      {operators && operators.length > 0 && (
        <Spin spinning={loading}>
          <Card type="inner" title={i18n._(t`Aanleveren`)}>
            {user?.id && (
              <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperColShallow}
                onFinish={handleUpload}
                onFinishFailed={handleUploadFailed}
                initialValues={{
                  [productionCompanyField]:
                    user.organisation?.type === "ProductionCompany"
                      ? user.organisation.name
                      : "",
                  [phonenumberField]: user?.phoneNumber,
                  [versionField]: 1,
                }}
              >
                <DeliveryForm
                  form={form}
                  advertisers={advertisers.filter(
                    ({ operator }) => operators.indexOf(operator) > -1
                  )}
                  operators={operators}
                  loadingAdvertisers={loadingAdvertisers}
                  loadingUpload={loadingUpload}
                  fileList={fileList}
                  setFileList={updateFileList}
                />
              </Form>
            )}
          </Card>
        </Spin>
      )}
    </MainContent>
  );
};

export default memo(DeliveryFormContainer);
