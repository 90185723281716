import moment from "moment";
import React, { useMemo } from "react";

import { Period } from "../../models/periods";
import { TargetPeriod } from "../../store/campaigns/requests/models";
import MultiPeriod from "./copy/MultiPeriod";
import SinglePeriod from "./copy/SinglePeriod";

export interface CopyButtonProps {
  disabled?: boolean;
  periods: Period[];
  onCopy: (targetPeriod: TargetPeriod) => void;
}

const endOfMonth = moment().endOf("month").endOf("day");
export const monthFormat = "MMMM YYYY";

const CopyButton = ({
  disabled,
  periods,
  onCopy,
}: CopyButtonProps): JSX.Element => {
  const filteredPeriods = useMemo(
    () =>
      periods.filter(
        (p) => p.canReceiveRequests && moment(p.startDate).isAfter(endOfMonth)
      ),
    [periods]
  );

  if (filteredPeriods.length > 1) {
    return (
      <MultiPeriod
        periods={filteredPeriods}
        disabled={disabled}
        onCopy={onCopy}
      />
    );
  }

  return (
    <SinglePeriod
      periods={filteredPeriods}
      disabled={disabled}
      onCopy={onCopy}
    />
  );
};

export default CopyButton;
