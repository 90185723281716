import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Empty, Form, FormInstance, Space, Spin, Typography } from "antd";
import moment from "moment/moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardTileInput } from "../../models/Dashboard";
import { OnBlobDownloadFunc } from "../../models/OnBlobDownloadFunc";
import { commercialMatchCell } from "../../store/dashboard/cell";
import { isNonEmptyArray } from "../../utils";
import CommercialSelect from "../../views/dashboard/CommercialSelect";

const DashboardCommercialForm = ({
  form,
  onDownload,
}: {
  form: FormInstance<DashboardTileInput>;
  onDownload: OnBlobDownloadFunc;
}) => {
  const { i18n } = useLingui();

  const dispatch = useDispatch();
  const commercialsFromStore = useSelector(commercialMatchCell.select);

  const { name: advertiserName, campaignIds } = form.getFieldsValue();

  useEffect(() => {
    const today = moment();
    const dateFrom = today
      .clone()
      .subtract(6, "month")
      .startOf("month")
      .toDate();
    const dateTo = today.clone().add(1, "month").endOf("month").toDate();
    dispatch(
      commercialMatchCell.require({
        advertiserName,
        campaignIds,
        dateFrom,
        dateTo,
      })
    );
  }, [advertiserName, campaignIds, dispatch]);

  return (
    <Spin spinning={commercialsFromStore.status.loading}>
      <Space direction="vertical">
        <Typography.Paragraph>
          {i18n._(
            t`Selecteer één of meerdere commercials die je wilt koppelen aan de dashboard tegel.`
          )}
        </Typography.Paragraph>

        {commercialsFromStore.value &&
        isNonEmptyArray(commercialsFromStore.value) ? (
          <Form.Item
            name="commercialDeliveryIds"
            rules={[{ required: false }]}
            wrapperCol={{ span: 24 }}
          >
            <CommercialSelect
              commercials={commercialsFromStore.value}
              onDownload={onDownload}
            />
          </Form.Item>
        ) : (
          <>
            <Empty
              description={i18n._(
                t`Er zijn geen commercials gevonden die horen bij jouw orderselectie.`
              )}
            />
            <Form.Item name="commercialDeliveryIds" hidden />
          </>
        )}

        <Form.Item name="id" hidden />
        <Form.Item name="name" rules={[{ required: true }]} hidden />
        <Form.Item name="campaignIds" rules={[{ required: true }]} hidden />
        <Form.Item
          name="skoTargetGroupId"
          rules={[{ required: true }]}
          hidden
        />
      </Space>
    </Spin>
  );
};

export default DashboardCommercialForm;
