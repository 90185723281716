import { ZoomInOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Popover, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useMemo } from "react";

import { SpotRatingRegio } from "../../models/Spot";

interface SpotZoomPopupProps {
  spotRatingsRegio: SpotRatingRegio[];
}

const SpotZoomPopup = (props: SpotZoomPopupProps) => {
  const { spotRatingsRegio } = props;

  const { i18n } = useLingui();

  const columns: ColumnsType<SpotRatingRegio> = useMemo(
    () => [
      {
        title: i18n._(t`Intomart doelgroep`),
        dataIndex: "intomartTargetGroupId",
      },
      {
        title: i18n._(t`Doelgroep`),
        dataIndex: "targetGroupId",
      },
      {
        title: i18n._(t`Kanaal`),
        dataIndex: "channelDescription",
      },
      {
        title: i18n._(t`Starttijd`),
        render: ({ actualStartTime }: SpotRatingRegio) =>
          moment(actualStartTime).format("HH:mm:ss"),
        showSorterTooltip: false,
        sortDirections: ["ascend"],
        sorter: (a: SpotRatingRegio, b: SpotRatingRegio) =>
          moment(a.actualStartTime).diff(b.actualStartTime),
        sortOrder: "ascend",
      },
      {
        title: i18n._(t`Voorspelde rating`),
        dataIndex: "predictedRating",
      },
      {
        title: i18n._(t`Behaalde rating`),
        dataIndex: "achievedRating",
      },
    ],
    [i18n]
  );

  return (
    <Popover
      content={<Table columns={columns} dataSource={spotRatingsRegio} />}
      placement="left"
    >
      <Button type="link" icon={<ZoomInOutlined />} shape="circle" />
    </Popover>
  );
};

export default SpotZoomPopup;
