import {
  AsyncResponse,
  toAsyncResponse,
  toAsyncResponseWith,
} from "async-lifecycle-saga";
import { Details } from "async-lifecycle-saga/dist/models";
import fetch from "isomorphic-fetch";
import moment from "moment";

import {
  AccessRequest,
  AccessTokenModel,
  AdBlockCheck,
  ApiPromise,
  TokenModel,
} from "./models";
import {
  accessTokenKey,
  getToken,
  getUsername,
  refreshTokenKey,
  setToken,
} from "./token";

const jsonConfig: RequestInit = {
  credentials: "same-origin",
};

export const jsonHeaders: HeadersInit = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export type HttpStatusCode =
  | 200
  | 201
  | 204
  | 401
  | 403
  | 404
  | 409
  | 410
  | 500;

export type HttpStatusCodeSuccessDictionary = {
  [statusCode in HttpStatusCode]: boolean;
};

export const httpOk: HttpStatusCode = 200;
export const httpCreated: HttpStatusCode = 201;
export const httpNoContent: HttpStatusCode = 204;
export const httpUnauthorized: HttpStatusCode = 401;
export const httpForbidden: HttpStatusCode = 403;
export const httpNotFound: HttpStatusCode = 404;
export const httpConflict: HttpStatusCode = 409;
export const httpGone: HttpStatusCode = 410;
export const httpInternalServerError: HttpStatusCode = 500;

export const httpStatusCodeSuccess: HttpStatusCodeSuccessDictionary = {
  [httpOk]: true,
  [httpCreated]: true,
  [httpNoContent]: true,
  [httpUnauthorized]: false,
  [httpForbidden]: false,
  [httpNotFound]: false,
  [httpConflict]: false,
  [httpGone]: false,
  [httpInternalServerError]: false,
};

export interface ProblemDetails extends Details {
  extensions?: { problems?: ProblemDetails[] };
}

export interface Response<T> {
  exception?: string;
  payload?: T;
  statusCode?: HttpStatusCode;
  problem?: ProblemDetails;
}

type HttpMethod = "get" | "post" | "put" | "delete";

export type MapBodyFunc<T, U> = (body: T) => U;

const fetcher = async <T, U = T>(
  method: HttpMethod,
  pathAndQuery: string,
  body?: string | FormData,
  anonymous?: boolean,
  downloadFile?: boolean,
  headersOverride?: HeadersInit,
  map?: MapBodyFunc<T, U>
): Promise<AsyncResponse<U>> => {
  let headers = headersOverride ?? jsonHeaders;
  if (!anonymous) {
    headers = { ...headers, Authorization: `Bearer ${await getAccessToken()}` };
  }

  const initOptions: RequestInit = {
    headers,
    method,
    body,
    ...jsonConfig,
  };

  if (map) {
    return fetch(pathAndQuery, initOptions).then(toAsyncResponseWith(map));
  }

  return fetch(pathAndQuery, initOptions).then(toAsyncResponse);
};

const getAccessToken = (): Promise<string> => {
  const token = getToken(accessTokenKey);
  if (token && moment.utc().isSameOrBefore(moment.utc(token.expires))) {
    return Promise.resolve(token.token);
  }

  const refreshToken = getToken(refreshTokenKey);
  if (
    !refreshToken ||
    (refreshToken && moment.utc().isAfter(moment.utc(refreshToken.expires)))
  ) {
    return Promise.reject(new Error("Not authenticated"));
  }

  const accessRequest: AccessRequest = {
    username: getUsername() || "",
    refreshToken: refreshToken.token,
  };

  const requestInit: RequestInit = {
    headers: jsonHeaders,
    method: "post",
    body: JSON.stringify(accessRequest),
    ...jsonConfig,
  };

  return fetch("/api/user/access", requestInit)
    .then((response) => response.json())
    .then((response: AccessTokenModel) => {
      const receivedToken: TokenModel = {
        token: response.accessToken,
        result: response.result,
        expires: new Date(response.expires),
      };

      setToken(accessTokenKey, receivedToken);

      return receivedToken
        ? receivedToken.token
        : Promise.reject(new Error("Not authenticated"));
    });
};

const jsonFetch = async <T, U = T>(
  method: HttpMethod,
  pathAndQuery: string,
  body?: string | FormData,
  token?: string,
  downloadFile?: boolean,
  headersOverride?: HeadersInit
): Promise<Response<U>> => {
  try {
    const headersInit = headersOverride ?? jsonHeaders;
    const headers = token
      ? { ...headersInit, Authorization: `Bearer ${token}` }
      : headersInit;
    const initOptions: RequestInit = {
      headers,
      method,
      body,
      ...jsonConfig,
    };

    const response = await fetch(pathAndQuery, initOptions);
    switch (response.status) {
      case httpOk:
      case httpCreated: {
        const payload = downloadFile
          ? await response.blob()
          : await response.json();
        return { payload, statusCode: response.status };
      }
      case httpConflict: {
        const payload = await response.json();
        return { payload, statusCode: response.status };
      }
      case httpNoContent:
      case httpUnauthorized: {
        return { statusCode: response.status };
      }
      default: {
        const problem = await response.json();
        return {
          exception: `Response status ${response.status}`,
          statusCode: response.status as HttpStatusCode,
          problem,
        };
      }
    }
  } catch (e) {
    return { exception: e as string };
  }
};

/**
 * Downloads a binary file from the specified path.
 * @param pathAndQuery The path and query to the file.
 * @param token An optional access token.
 */
const jsonDownload = <T, U = T>(
  pathAndQuery: string,
  token?: string
): Promise<Response<U>> =>
  jsonFetch<T, U>("get", pathAndQuery, undefined, token, true);

type DownloadFunction<T> = (token?: string) => ApiPromise<T>;

/**
 * Downloads a binary file from the specified path.
 * @param pathAndQuery The path and query to the file.
 */
export const jsonDownloader =
  <T, U = T>(pathAndQuery: string): DownloadFunction<U> =>
  (token?: string): ApiPromise<U> =>
    jsonDownload<T, U>(pathAndQuery, token);

/**
 * Executes an AdBlocker request
 */
export const adBlockChecker = (): Promise<Response<AdBlockCheck>> => {
  try {
    return fetch(
      new Request("/api/advertiser/block/?advertiserId=123", {
        method: "HEAD",
        mode: "no-cors",
      })
    )
      .then(() =>
        // Google Ads request succeeded, so likely no ad blocker
        ({ payload: { isBlocked: false }, statusCode: httpOk })
      )
      .catch(() =>
        // Request failed, likely due to ad blocker
        ({ payload: { isBlocked: true }, statusCode: httpOk })
      );
  } catch (error) {
    // fetch API error; possible fetch not supported (old browser)
    // Marking as a blocker since there was an error and so
    // we can prevent continued requests when this function is run
    return Promise.resolve({
      payload: { isBlocked: true },
      statusCode: httpOk,
    });
  }
};

/**
 * Method to send a GET fetch to the server
 * @param pathAndQuery
 * @param token
 */
const jsonGet = <T, U = T>(
  pathAndQuery: string,
  token?: string
): Promise<Response<U>> =>
  jsonFetch<T, U>("get", pathAndQuery, undefined, token);

export const getter = <T, U = T>(
  pathAndQuery: string,
  anonymous?: boolean,
  map?: (body: T) => U
): Promise<AsyncResponse<U>> =>
  fetcher<T, U>(
    "get",
    pathAndQuery,
    undefined,
    anonymous,
    undefined,
    undefined,
    map
  );

export const poster = <T, TBody, U = T>(
  pathAndQuery: string,
  body: TBody | null,
  anonymous?: boolean,
  headersOverride?: HeadersInit,
  map?: MapBodyFunc<T, U>
): Promise<AsyncResponse<U>> => {
  // eslint-disable-next-line no-nested-ternary
  const postBody = body
    ? body instanceof FormData
      ? body
      : JSON.stringify(body)
    : undefined;

  return fetcher<T, U>(
    "post",
    pathAndQuery,
    postBody,
    anonymous,
    undefined,
    headersOverride,
    map
  );
};

export const putter = <T, TBody, U = T>(
  pathAndQuery: string,
  body: TBody | null,
  anonymous?: boolean,
  headersOverride?: HeadersInit,
  map?: MapBodyFunc<T, U>
): Promise<AsyncResponse<U>> => {
  // eslint-disable-next-line no-nested-ternary
  const putBody = body
    ? body instanceof FormData
      ? body
      : JSON.stringify(body)
    : undefined;

  return fetcher<T, U>(
    "put",
    pathAndQuery,
    putBody,
    anonymous,
    undefined,
    headersOverride,
    map
  );
};

export const deleter = <T, U = T>(
  pathAndQuery: string,
  anonymous?: boolean,
  map?: MapBodyFunc<T, U>
): Promise<AsyncResponse<U>> =>
  fetcher<T, U>(
    "delete",
    pathAndQuery,
    undefined,
    anonymous,
    undefined,
    undefined,
    map
  );

type GetFunction<T> = (token?: string) => Promise<Response<T>>;

export const jsonGetter =
  <T, U = T>(pathAndQuery: string): GetFunction<U> =>
  (token?: string): Promise<Response<U>> =>
    jsonGet<T, U>(pathAndQuery, token);

export const jsonPost = <TResponse, TBody>(
  pathAndQuery: string,
  body: TBody | null,
  token?: string,
  headersOverride?: HeadersInit
): ApiPromise<TResponse> => {
  // eslint-disable-next-line no-nested-ternary
  const postBody = body
    ? body instanceof FormData
      ? body
      : JSON.stringify(body)
    : undefined;
  return jsonFetch<TResponse>(
    "post",
    pathAndQuery,
    postBody,
    token,
    undefined,
    headersOverride
  );
};

type PostFunction<TResponse, TBody> = (
  body: TBody | null,
  token?: string,
  headersOverride?: HeadersInit
) => ApiPromise<TResponse>;

/**
 * Method to send a POST fetch to the server
 * @param pathAndQuery

 */
export const jsonPoster =
  <TResponse, TBody>(pathAndQuery: string): PostFunction<TResponse, TBody> =>
  (
    body: TBody | null,
    token?: string,
    headersOverride?: HeadersInit
  ): Promise<Response<TResponse>> =>
    jsonPost<TResponse, TBody>(pathAndQuery, body, token, headersOverride);

/**
 * HTTP PUT
 * @param pathAndQuery The path and query to the API endpoint.
 * @param body The request body.
 * @param token An optional access token.
 */
export const jsonPut = <TResponse, TBody>(
  pathAndQuery: string,
  body: TBody | null,
  token?: string
): Promise<Response<TResponse>> =>
  jsonFetch<TResponse>(
    "put",
    pathAndQuery,
    body ? JSON.stringify(body) : undefined,
    token
  );

type PutFunction<TResponse, TBody> = (
  body: TBody | null,
  token?: string
) => ApiPromise<TResponse>;

/**
 * Sends an HTTP PUT request to the API using the provided path and query.
 * Additionally, a request body and access token may be provided.
 * @param pathAndQuery The path and query to the API endpoint.
 */
export const jsonPutter =
  <TResponse, TBody>(pathAndQuery: string): PutFunction<TResponse, TBody> =>
  (body: TBody | null, token?: string): ApiPromise<TResponse> =>
    jsonPut(pathAndQuery, body, token);

const jsonDelete = <T>(
  pathAndQuery: string,
  token?: string
): Promise<Response<T>> =>
  jsonFetch<T>("delete", pathAndQuery, undefined, token);

type DeleteFunction<T> = (token?: string) => Promise<Response<T>>;

/**
 * Method to send a DELETE fetch to the server
 * @param pathAndQuery
 */
export const jsonDeleter =
  <TResponse>(pathAndQuery: string): DeleteFunction<TResponse> =>
  (token?: string): Promise<Response<TResponse>> =>
    jsonDelete<TResponse>(pathAndQuery, token);
