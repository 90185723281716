import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import React from "react";

import { colorError, colorOk } from "../../views/colors";

const HealthStatusIcon = ({ healthy }: { healthy: boolean }): JSX.Element =>
  healthy ? (
    <CheckCircleTwoTone twoToneColor={colorOk} />
  ) : (
    <ExclamationCircleTwoTone twoToneColor={colorError} />
  );

export default HealthStatusIcon;
